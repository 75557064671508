import React from 'react';
import Icon from 'components/icons';
import { useTranslation } from 'react-i18next';
import { applies, employer, not, reviewed, total } from 'tools/i18n/constants/i18n';

interface AppliesProps {
  totalCount?: number;
  reviewedCount?: number;
  notReviewedCount?: number;
}
export default function Applies(props: AppliesProps) {
  const { totalCount = 0, notReviewedCount = 0, reviewedCount = 0 } = props;
  const { t } = useTranslation();
  return (
    <div className="bg-white mb-2 rounded">
      <div className="p-3 border-bottom">
        <div className="d-flex align-items-center">
          <Icon Name="FaItunesNote" />
          <h6 className="mb-0 ml-2">{t(applies)}</h6>
        </div>
      </div>
      <div className="p-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <Icon Name="FaChartLine" />
            <span className="ml-2">{`${t(total)} ${t(applies)}: ${totalCount}`}</span>
          </div>

          <div className="d-flex align-items-center">
            <Icon Name="FiCheck" />
            <span className="ml-2">{`${t(employer)} ${t(reviewed)}: ${reviewedCount}`}</span>
          </div>

          <div className="d-flex align-items-center">
            <Icon Name="FaChartLine" />
            <span className="ml-2">{`${t(not)} ${t(reviewed)}: ${notReviewedCount}`}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

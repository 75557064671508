import { ApolloError } from '@apollo/client';
import {
  TreeBrunchSectionDataObj,
  TreeDataObj,
  TreeDataSectionsObj,
  TreeName,
  TreeSubCategoryChildObj,
  TreeSubCategoryDataObj,
} from 'components/treeSelect';
import SidebarRoutes from 'layout/sidebar/menu';
import { find } from 'lodash';
import { TreeData } from 'react-dropdown-tree-select';
import AppRoutes from 'route/Routes';
import { dispatch } from 'store';
import { d_logOut } from 'store/user/user.dispatchs';
import {
  APP_HELPER,
  APP_ROUTE_PARAM_NAME,
  EDUCATION_GRADE,
  EDUCATION_GRADE_TEXT,
  Permission,
  ROLES,
  ROLES_BASED_BACKEND,
} from 'tools/enums';
import { Obj, Primitive, numberish, stateActionArg, stringish, t_countryKeys, t_storageKeys } from 'tools/types';
import { v4 } from 'uuid';

export const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

export const getCookie = (name: string) => localStorage.getItem(name);

export const setCookie = (arg: { name: string; value: any }) => localStorage.setItem(arg.name, arg.value);
export const expireNow = () => new Date(new Date().getTime() + 60 * 24);
export const deleteCookie = (arg: string) => {
  localStorage.removeItem(arg);
};
export function errorFinder(error: any) {
  if (typeof error === 'string') return error;
  const errorText =
    error.graphQLErrors?.[0]?.extensions?.exception?.stacktrace?.[0] ||
    error.graphQLErrors?.[0]?.extensions?.exception?.response?.clientMessage ||
    error.graphQLErrors?.[0]?.extensions?.exception?.response?.devMessage ||
    error.graphQLErrors?.[0]?.extensions?.response?.message?.[0] ||
    'Error Happened';
  return errorText;
}

export const toDate = (time?: stringish | Date, withHour = true) => {
  if (time) {
    const date = new Date(time);
    if (withHour) {
      return date.toLocaleString('tr-TR', { timeZone: 'Europe/Istanbul' });
    } else {
      return date.toDateString();
    }
  } else return '';
};
export const convertDate = (time?: stringish) => {
  if (time) {
    const dateObject = new Date(time);
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1;
    const day = dateObject.getDate();
    return `${year}-${month}-${day}`;
  } else return '';
};

export const getMonth = (time?: Date): string => {
  if (time) {
    const date = new Date(time);
    return `${
      date.getUTCMonth() + 1 > 12
        ? '01'
        : date.getUTCMonth() + 1 < 10
        ? `0${date.getUTCMonth() + 1}`
        : date.getUTCMonth() + 1
    }`;
  } else return '';
};

export const getYear = (time?: Date): string => {
  if (time) {
    const date = new Date(time);
    return `${date.getUTCFullYear()}`;
  } else return '';
};

/*
export function debounce<Args extends unknown[]>(fn: (...args: Args) => void, delay: number) {
  let lastArgs: Args | undefined;
  let timeoutID: number | undefined;

  const run = () => {
    if (lastArgs) {
      fn(...lastArgs);
      lastArgs = undefined;
    }
  };

  console.log(timeoutID);
  return function (...args: Args) {
    clearTimeout(timeoutID);
    lastArgs = args;
    timeoutID = window.setTimeout(run, delay);
  };
}
*/

let timer: number | NodeJS.Timeout | undefined = undefined;
export function debounce(func: VoidFunction, timeout = 300) {
  return (...args: any) => {
    clearTimeout(timer);
    timer = window.setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export function isNumber(key: string): boolean {
  return key.toString().startsWith('+') || !isNaN(parseInt(key[1]));
}
export function isValidEmail(email: string | number): boolean {
  return /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(email.toString());
}

export function getStorages(key: t_storageKeys) {
  return localStorage.getItem(key);
}
export function saveStorage(key: t_storageKeys, value: NonNullable<Primitive>) {
  return localStorage.setItem(key, value.toString());
}
export function removeStorage(key: t_storageKeys) {
  return localStorage.removeItem(key);
}
export function removeAllStorage() {
  return localStorage.clear();
}
export function configRootElement(attributeName: string, attributeValue: any) {
  // @ts-ignore

  document.documentElement[attributeName] = attributeValue;
}
export function getRootElementAttrValue(attributeName: string) {
  //@ts-ignore
  return document.documentElement[attributeName];
}

export function checkSize(size: number, limit: number, successCallback: VoidFunction, errorCallback: VoidFunction) {
  if (size <= limit) {
    successCallback();
    return true;
  } else {
    errorCallback();
    return false;
  }
}

export function isImage(fileType: string): boolean {
  const validPng = 'image/png';
  const validJpg = 'image/jpg';
  const validJpeg = 'image/jpeg';
  return fileType === validPng || fileType === validJpg || fileType === validJpeg;
}

export function parsePhoneNumber(input: string, country?: t_countryKeys) {
  return phoneUtil.parseAndKeepRawInput(input, country?.toUpperCase() || 'TR')['values_']['2'];
}
export function toURLParam(arg: Record<Obj, unknown>) {
  return Object.entries(arg)
    .map(([key, val]) => `${key}=${val}`)
    .join('&');
}

export function goBack() {
  window.history.back();
}

export function makeTree(data: TreeDataObj[]): TreeData {
  const makeChild = (dataItem: TreeDataObj): TreeData => {
    let convertedDataObj: TreeData = [];
    if (dataItem.child) {
      convertedDataObj = dataItem.child.map((child) => {
        if (child.child) {
          return {
            ...child,
            label: child.title,
            children: makeChild(child),
            dataset: {
              icon: child.icon || APP_HELPER.defaultBazaarAndJobIcon,
            },
          };
        }
        return {
          ...child,
          label: child.title,
          children: child?.child || [],
          dataset: {
            icon: child.icon || APP_HELPER.defaultBazaarAndJobIcon,
          },
        };
      });
      return convertedDataObj;
    } else
      return {
        ...dataItem,
        children: dataItem?.child || [],
        dataset: {
          icon: dataItem.icon || APP_HELPER.defaultBazaarAndJobIcon,
        },
        label: dataItem.title,
      };
  };
  return data.map((dataItem) => {
    return {
      ...dataItem,
      dataset: {
        icon: dataItem.icon || APP_HELPER.defaultBazaarAndJobIcon,
      },
      label: dataItem.title,
      children: dataItem.child ? makeChild(dataItem) : [],
    };
  });
}
export function makeSubCategoryTree(
  data: TreeSubCategoryDataObj[],
  defaultIcon: APP_HELPER | string | undefined = undefined
): TreeData {
  const makeChild = (dataItem: TreeSubCategoryChildObj | TreeSubCategoryDataObj) => {
    let convertedDataObj: TreeData = [];
    if (dataItem.subCategories) {
      convertedDataObj = dataItem.subCategories.map((child) => {
        let makeItem = {};
        if (child.subCategories) {
          makeChild(child);
          makeItem['children'] = makeChild(child);
        }
        return (makeItem = {
          ...child,
          ...makeItem,
          dataset: {
            icon: child.imageUrl || defaultIcon || APP_HELPER.defaultBazaarAndJobIcon,
          },
          label: child.originalName,
          id: child._id,
        });
      });
      return convertedDataObj;
    } else {
      return undefined;
    }
  };
  return data.map((dataItem) => {
    return {
      ...dataItem,
      dataset: {
        icon: dataItem.imageUrl || defaultIcon || APP_HELPER.defaultBazaarAndJobIcon,
      },
      label: dataItem.originalName,
      children: makeChild(dataItem),
      id: dataItem._id,
    };
  });
}

export function makeTreeName(data: TreeName[], defaultIcon?: APP_HELPER): TreeData {
  return data.map((item) => {
    return {
      ...item,
      id: item?._id || item?.id,
      label: item?.name || item?.originalText || item?.reason,
      dataset: {
        icon: item?.logo || defaultIcon || APP_HELPER.defaultBazaarAndJobIcon,
      },
    };
  });
}

export function makeBrunchSectionTree(data: TreeBrunchSectionDataObj[]): TreeData {
  const makeChild = (dataItem: TreeBrunchSectionDataObj) => {
    let convertedDataObj: TreeData = [];
    if (dataItem && dataItem?.sections) {
      convertedDataObj = dataItem.sections.map((child: TreeDataSectionsObj) => {
        let makeItem = {};
        /*     if (child.sections) {
          makeChild(child);
          makeItem['children'] = makeChild(child);
        }*/
        return (makeItem = {
          ...child,
          ...makeItem,
          dataset: {
            icon: child.icon || APP_HELPER.defaultBazaarAndJobIcon,
          },
          label: child.originalName,
          id: child._id,
        });
      });
      return convertedDataObj;
    } else {
      return undefined;
    }
  };
  return data.map((dataItem) => {
    return {
      ...dataItem,
      dataset: {
        icon: dataItem.imageUrl || APP_HELPER.defaultBazaarAndJobIcon,
      },
      label: dataItem.originalName,
      children: makeChild(dataItem),
      id: dataItem._id,
    };
  });
}
export function pushState({ data = undefined, unused = '', url }: stateActionArg) {
  window.history.pushState(data, unused, url);
}

export function replaceState({ data = undefined, unused = '', url }: stateActionArg) {
  window.history.replaceState(data, unused, url);
}
export function isValidPass(password: string) {
  return password.length < 8 || !/(?=.*[A-Z])/.test(password);
}

export function isTrophy(expression: any) {
  if (expression instanceof Array) {
    return !!expression.length;
  }
  if (typeof expression === 'object') {
    const keys = Object.keys(expression);
    return !!keys.length;
  }
  return !!expression;
}

export function copyToClipboard(content: string | number) {
  try {
    return window.navigator.clipboard.writeText(content.toString());
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}

export function toBreadCrumb(url: string) {
  return url.replaceAll('/', ' ').split(' ').filter(Boolean);
}

export function isNew(type?: string) {
  return type ? type === APP_ROUTE_PARAM_NAME.new : true;
}
export function getLocation() {
  return window.location;
}

export const makeGrade = () => [
  {
    label: EDUCATION_GRADE_TEXT.ART_SCHOOL,
    value: EDUCATION_GRADE.ART_SCHOOL,
  },
  {
    label: EDUCATION_GRADE_TEXT.HIGH_SCHOOL,
    value: EDUCATION_GRADE.HIGH_SCHOOL,
  },
  {
    label: EDUCATION_GRADE_TEXT.MASTERS_DEGREE,
    value: EDUCATION_GRADE.MASTERS_DEGREE,
  },
  {
    label: EDUCATION_GRADE_TEXT.MIDDLE_SCHOOL,
    value: EDUCATION_GRADE.MIDDLE_SCHOOL,
  },
  {
    label: EDUCATION_GRADE_TEXT.PRIMARY_SCHOOL,
    value: EDUCATION_GRADE.PRIMARY_SCHOOL,
  },
  {
    label: EDUCATION_GRADE_TEXT.VOCATIONAL_HIGH_SCHOOL,
    value: EDUCATION_GRADE.VOCATIONAL_HIGH_SCHOOL,
  },
  {
    label: EDUCATION_GRADE_TEXT.UNIVERSITY,
    value: EDUCATION_GRADE.UNIVERSITY,
  },

  {
    label: EDUCATION_GRADE_TEXT.NO_EDUCATED,
    value: EDUCATION_GRADE.NO_EDUCATED,
  },
];

export function MAKE_GRADE_FILTER() {
  return [
    {
      label: EDUCATION_GRADE_TEXT.PRIMARY_SCHOOL,
      value: EDUCATION_GRADE.PRIMARY_SCHOOL,
    },
    {
      label: EDUCATION_GRADE_TEXT.MIDDLE_SCHOOL,
      value: EDUCATION_GRADE.MIDDLE_SCHOOL,
    },
    {
      label: EDUCATION_GRADE_TEXT.HIGH_SCHOOL,
      value: EDUCATION_GRADE.HIGH_SCHOOL,
    },
    {
      label: EDUCATION_GRADE_TEXT.ART_SCHOOL,
      value: EDUCATION_GRADE.ART_SCHOOL,
    },
    {
      label: EDUCATION_GRADE_TEXT.UNIVERSITY,
      value: EDUCATION_GRADE.UNIVERSITY,
    },
  ];
}

export function toUpperCase(str?: stringish) {
  return str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : '';
}

export function logout() {
  deleteCookie('token');
  deleteCookie('token');
  deleteCookie('refreshToken');
  deleteCookie('refreshToken');
  dispatch(d_logOut());
}

export function decode(token?: string) {
  if (token) {
    const base64Url = token?.split('.')?.[1];
    const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        ?.atob(base64)
        ?.split('')
        ?.map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        ?.join('')
    );
    return JSON.parse(jsonPayload);
  } else return '';
}

export function findRoutesByRole(role: ROLES, userPermission: Permission[]): void | any[] {
  try {
    if (role && userPermission.length && AppRoutes?.[role]) {
      return AppRoutes[role].filter((route) => {
        return route.permissionKeys?.length ? route?.permissionKeys?.every((r) => userPermission?.includes(r)) : true;
      });
    } else {
      throw new Error();
    }
  } catch (e) {
    console.error(e);
    logout();
  }
}
export function findSidebarRouteByRole(role: ROLES, userPermission: Permission[]) {
  function loopNestedArray(arr: any) {
    return (arr || arr.children)?.map((route: any) => {
      if (route.children?.length) {
        return { ...route, children: loopNestedArray(route.children) }; // recursively call the function for nested arrays
      } else {
        if (route?.permissionKeys?.length === 0 || !route?.permissionKeys) return route;
        const isIncludes = route.permissionKeys?.every((r: any) => userPermission.includes(r));
        if (isIncludes) {
          return route;
        } else return {};
      }
    });
  }
  const sideRoutes = loopNestedArray(SidebarRoutes[ROLES_BASED_BACKEND[role]][0].items);
  return [{ items: sideRoutes }];
}

export function generateNewId() {
  return v4();
}

export function trimString(text: any) {
  if (typeof text === 'string') {
    return text.trim();
  }
  return text;
}

export function toNull<T = any>(data: T): T | null {
  if (typeof data !== 'boolean') {
    return data || null;
  }
  return data;
}

export function toUndefined<T = any>(arg?: T): T | undefined {
  if (typeof arg !== 'boolean') {
    return arg || undefined;
  }
  return arg;
}

export function findTreeDefaultValue(dataTree: any, defaultValue?: string) {
  let f = undefined;
  const allData = dataTree.map((t: any) => {
    console.log(t);
    const isHere = (t?._id || t.id) === defaultValue;
    if (isHere) {
      f = { ...t, expanded: true, checked: true };
      return { ...t, expanded: true, checked: true };
    } else {
      const findChild = (children: any) => {
        if (children.children?.length) {
          const isHere = children.find((t: any) => (t?._id || t.id) === defaultValue);
          if (isHere) {
            f = { ...t, expanded: true, checked: true };
            return { ...t, expanded: true, checked: true };
          } else {
            findChild(children.children);
          }
        } else {
          const isHere = (children?._id || children.id) === defaultValue;
          if (isHere) {
            f = { ...t, expanded: true, checked: true };
            return { ...t, expanded: true, checked: true };
          } else return t;
        }
      };
      if (t.children?.length) {
        const isHere = t.children.find((t: any) => (t?._id || t.id) === defaultValue);
        if (isHere) {
          f = { ...t, expanded: true, checked: true };
          return { ...t, expanded: true, checked: true };
        } else {
          return findChild(t.children);
        }
      } else {
        const isHere = (t?._id || t.id) === defaultValue;
        if (isHere) {
          f = { ...t, expanded: true, checked: true };
          return { ...t, expanded: true, checked: true };
        } else {
          return t;
        }
      }
    }
  });
  return { f, allData };
}

export function findTreeDefaultValues(dataTree: any, defaultValue?: string[]) {
  return dataTree.map((t: any) => {
    const isHere = defaultValue?.includes(t?._id || t.id);
    if (isHere) {
      return {
        ...t,
        isDefaultValue: true,
        children: t?.children?.length ? findTreeDefaultValues(t?.children, defaultValue) : [],
      };
    } else {
      if (t?.children?.length) {
        const findChild = (children: any) => {
          if (children.children?.length) {
            const isHere = find(children, function (o) {
              return defaultValue?.includes(o?.id || o._id);
            });
            if (isHere) {
              return {
                ...t,
                isDefaultValue: true,
                children: t?.children?.length ? findTreeDefaultValues(t?.children, defaultValue) : [],
              };
            } else {
              findChild(children.children);
            }
          } else {
            const isHere = defaultValue?.includes(children?._id || children.id);
            if (isHere) {
              return {
                ...t,
                isDefaultValue: true,
                children: t?.children?.length ? findTreeDefaultValues(t?.children, defaultValue) : [],
              };
            } else
              return {
                ...t,
                isDefaultValue: false,
                checked: false,
                expanded: false,
                value: false,
              };
          }
        };
        if (t.children?.length) {
          const isHere = find(t.children, function (o) {
            return defaultValue?.includes(o?.id || o._id);
          });
          if (isHere) {
            return {
              ...t,
              isDefaultValue: true,
              children: t?.children?.length ? findTreeDefaultValues(t?.children, defaultValue) : [],
            };
          } else {
            return findChild({
              ...t.children,
              isDefaultValue: false,
              checked: false,
              expanded: false,
              value: false,
            });
          }
        } else {
          const isHere = defaultValue?.includes(t?._id || t.id);
          if (isHere) {
            return {
              ...t,
              isDefaultValue: true,
              children: t?.children?.length ? findTreeDefaultValues(t?.children, defaultValue) : [],
            };
          } else {
            return { ...t, isDefaultValue: false, checked: false, expanded: false, value: false };
          }
        }
      } else {
        const isHere = defaultValue?.includes(t?._id || t.id);
        if (isHere) {
          return { ...t, isDefaultValue: true };
        } else {
          return { ...t, isDefaultValue: false, checked: false, expanded: false, value: false };
        }
      }
    }
  });
}

export function toArray<T>(data: T | null | undefined): T | [] {
  if (!Array.isArray(data)) return [];
  return data;
}
export function toString(content: stringish | numberish | undefined) {
  return typeof content !== 'string' && typeof content !== 'number' ? APP_HELPER.emptyDescription : content;
}
/*
export function findDevMessage(error : ApolloError | ApolloQueryResult<any>){

}*/

export function removeCityFromCounty(county: stringish) {
  if (county) return county.substring(county.indexOf('_') + 1, county.length);
  return '';
}
export const getGraphError = (error: any) => {
  const status =
    error?.graphQLErrors[0]?.extensions?.httpStatus ||
    error?.graphQLErrors[0]?.extensions?.exception?.status ||
    error?.graphQLErrors[0]?.extensions?.response?.statusCode;
  const message = error?.graphQLErrors[0]?.extensions?.exception?.message;
  const devMessage = error?.graphQLErrors[0]?.extensions?.exception?.response?.devMessage;
  return { status, devMessage, message };
};

export function reverse(value: string, seperator: string) {
  return value.split(seperator).reverse().join(seperator);
}

export function concatSocialLink(type: string, link: string): string {
  switch (type) {
    case 'YoutubeChannel':
      return `https://www.youtube.com/${link}`;
    case 'Youtube':
      return `https://www.youtube.com/${link}`;
    case 'Twitter':
      return `https://twitter.com/${link}`;
    case 'Facebook':
      return `https://www.facebook.com/${link}`;
    case 'Whatsapp':
      return `https://wa.me/${link}`;
    case 'LinkedIn':
      return `https://www.linkedin.com/in/${link}`;
    case 'Instagram':
      return `https://instagram.com/${link}`;
    default:
      return '';
  }
}

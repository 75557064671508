import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isNew } from 'tools/methods';
import { useToggle } from 'hooks';
import PagesLayout from 'pages/components/layout';
import { useTranslation } from 'react-i18next';
import {
  additionalSchoolInformation,
  assignAdminOrQrCode,
  basicSchoolInformation,
  created,
  grade,
  mustBe,
  school,
  schools,
  successfully,
  unableToUploadIcon,
  updated,
} from 'tools/i18n/constants/i18n';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { APP_ROUTE_NAME, EDUCATION_GRADE, EDUCATION_GRADE_TEXT } from 'tools/enums';
import { AccordionTitle } from 'components/accordion';
import { useForm } from 'react-hook-form';
import { CREATE_SCHOOL, UPDATE_SCHOOL } from 'services/graphql/queries/school';
import { Loading } from 'components';
import { toast } from 'react-toastify';
import FetchApi from 'services/api/axios';
import { DEFAULT_COUNTRY } from 'tools/constants';
import useMutation from 'hooks/useMutation';
import useSchoolDetail from 'pages/school/useSchoolDetail';
import BasicForm from 'pages/school/forms/basicForm';
import AdditionalForm from 'pages/school/forms/additionalForm';
import AssignAdmin from 'pages/school/forms/assignAdminForm';

export type user = {
  firstName: string;
  lastName: string;
  id: string | number;
  mail: string;
  phoneNumber: string;
} | null;

export type t_defaultValues = {
  customError?: string;
  name: string | null | undefined;
  county: string | null | undefined;
  city: string | null | undefined;
  grade: EDUCATION_GRADE | null | undefined;
  _id: string | null | undefined;
  logo: File | string | null | undefined;
  postalCode: string | null | undefined;
  fullAddress: string | null | undefined;
  bio: string | null | undefined;
  contactPhoneCode: string | null | undefined;
  contactPhoneNumber: string | null | undefined;
  contactEmail: string | null | undefined;
  websiteUrl: string | null | undefined;
  studentCountCapacity: string | null | undefined;
  uniqueCode: string | null | undefined;
  user: user | undefined;
  isRegistered: boolean | undefined;
  isVisible: boolean | undefined;
  isActive: boolean | undefined;
};

type SchoolFormDetailProps = {
  isNew: boolean;
  defaultValue?: t_defaultValues;
  refetch?: any;
};
export type t_steps = 1 | 2 | 3;
export default function SchoolDetail() {
  const { type } = useParams();
  const { t } = useTranslation();
  const isNewSchool = isNew(type);
  return (
    <PagesLayout backLink="schools" backLinkParam="/" backLinkTitle={t(schools)}>
      {isNewSchool ? <NewSchool /> : <UpdateSchool />}
    </PagesLayout>
  );
}

function UpdateSchool() {
  const { data, loading, refetch } = useSchoolDetail();
  return (
    <Loading loading={loading}>
      <SchoolForm isNew={false} defaultValue={data} refetch={refetch} />
    </Loading>
  );
}

function NewSchool() {
  return <SchoolForm isNew={true} />;
}

function SchoolForm(props: SchoolFormDetailProps) {
  const { defaultValue, isNew } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  //@ts-ignore

  const [defaultValues, setDefaultValues] = useState<t_defaultValues | undefined>({
    ...defaultValue,
    contactPhoneCode: defaultValue?.contactPhoneCode || DEFAULT_COUNTRY.value,
  } as t_defaultValues | undefined);
  const DEFAULT_VALUE: t_defaultValues = useMemo(() => {
    const isActive = defaultValue?._id ? (defaultValue?.isRegistered ? defaultValue?.isActive : true) : true;
    const isVisible = defaultValue?._id
      ? defaultValue?.isRegistered
        ? !defaultValue?.isActive
          ? false
          : defaultValue?.isVisible
        : true
      : true;
    return {
      name: defaultValue?.name,
      city: defaultValue?.city,
      county: defaultValue?.county,
      grade: defaultValue?.grade,
      _id: defaultValue?._id,
      logo: defaultValue?.logo,
      postalCode: defaultValue?.postalCode,
      fullAddress: defaultValue?.fullAddress,
      bio: defaultValue?.bio,
      contactPhoneCode: defaultValue?.contactPhoneCode || DEFAULT_COUNTRY.value,
      contactPhoneNumber: defaultValue?.contactPhoneNumber,
      contactEmail: defaultValue?.contactEmail,
      websiteUrl: defaultValue?.websiteUrl,
      studentCountCapacity: defaultValue?.studentCountCapacity,
      user: defaultValue?.user,
      uniqueCode: defaultValue?.uniqueCode,
      isRegistered: !!defaultValue?.isRegistered,
      isVisible,
      isActive,
    };
  }, [defaultValue, isNew, defaultValues]);
  const [steps, setSteps] = useState<t_steps>(defaultValue ? (defaultValues?.isRegistered ? 3 : 2) : 1);
  const [createExecute, { loading }] = useMutation(CREATE_SCHOOL);
  const [updateExecute, { loading: updateLoading }] = useMutation(UPDATE_SCHOOL);
  const [isRegistered, toggleRegistered] = useToggle((defaultValue?.isRegistered as boolean) || false);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
  } = useForm<t_defaultValues>({
    defaultValues: DEFAULT_VALUE,
  });

  const handleUploadIcon = useCallback(
    (file: File, createdId?: string, cb?: VoidFunction) => {
      const formData = new FormData();
      formData.append('id', createdId || (defaultValues?._id as string));
      formData.append('file', file);
      FetchApi({
        method: 'PATCH',
        url: 'SCHOOL_LOGO',
        data: formData,
      })
        .then((res) => {
          if (!res.data) {
            setValue('customError', t(unableToUploadIcon));
          } else {
            cb?.();
          }
        })
        .catch(console.error);
    },
    [defaultValue]
  );
  const handleUpdate = (data: t_defaultValues) => {
    console.log(data);
    setValue('customError', '');
    if (isRegistered) {
      // @ts-ignore
      delete data.grade;
    }
    const dataKeys = Object.keys(data);
    dataKeys.forEach((key) => {
      if (data[key] === undefined || data[key] === null) {
        delete data[key];
      }
    });
    updateExecute({
      variables: {
        ...data,
        id: data._id,
        isRegistered: !!data.fullAddress,
        contactPhoneCode: data.contactPhoneCode || DEFAULT_COUNTRY.value,
      },
    })
      .then(async (res) => {
        if (res.data.EmploymentUpdateSchool) {
          toast.success(`${t(school)} ${t(successfully)} ${t(updated)}`);
          setDefaultValues(res.data?.EmploymentUpdateSchool);
          setSteps(res.data.EmploymentUpdateSchool.isRegistered ? 3 : 2);
        } else {
          //@ts-ignore
          setValue('customError', res?.errors?.message || 'Something Went Wrong');
        }
      })
      .catch(console.error);
  };

  const handleToggleISNew = (id: string) => {
    navigate(`/${APP_ROUTE_NAME.dashboard}/${APP_ROUTE_NAME.schools}/${id}`);
  };
  const handleCreate = useCallback((data: t_defaultValues) => {
    setValue('customError', '');
    if (data.grade !== EDUCATION_GRADE.UNIVERSITY && !data.county) {
      setError('county', { message: 'Select an county' });
      return;
    } else {
      setError('county', { message: undefined });
    }
    createExecute({
      variables: {
        ...data,
      },
    })
      .then((res) => {
        const result = res.data?.EmploymentCreateSchool;
        if (result) {
          if (data.logo) {
            handleUploadIcon(data.logo as File, result._id, () => {
              handleToggleISNew(result?._id);
              toast.success(`${t(school)} ${t(successfully)} ${t(created)}`);
              setDefaultValues(result);
              setSteps(2);
            });
          } else {
            handleToggleISNew(result?._id);
            toast.success(`${t(school)} ${t(successfully)} ${t(created)}`);
            setDefaultValues(result);
            setSteps(2);
          }
        }
      })
      .catch(console.error);
  }, []);

  const handleIsRegistered = () => {
    const getGrade = getValues('grade');
    if (getGrade !== EDUCATION_GRADE.ART_SCHOOL && getGrade !== EDUCATION_GRADE.UNIVERSITY) {
      setError('grade', {
        message: `${t(school)} ${t(grade)} ${t(mustBe)} 
      ${EDUCATION_GRADE_TEXT.ART_SCHOOL}`,
      });
    } else {
      toggleRegistered();
    }
  };
  const basicInfo = {
    title: <AccordionTitle isRequired> {t(basicSchoolInformation)} </AccordionTitle>,
    needRegistration: false,
    name: 'basic',
    required: true,
    element: (
      <BasicForm
        errors={errors}
        steps={steps}
        onSubmit={defaultValue ? handleUpdate : handleCreate}
        handleSubmit={handleSubmit}
        control={control}
        toggleRegistered={handleIsRegistered}
        defaultValue={defaultValues}
        isRegistered={isRegistered}
        loading={loading || updateLoading}
        handleUploadIcon={handleUploadIcon}
        isNew={isNew}
      />
    ),
  };
  const additionalInfo = {
    title: <AccordionTitle isRequired> {t(additionalSchoolInformation)} </AccordionTitle>,
    needRegistration: true,
    basic: 'additional',
    required: false,
    element: (
      <AdditionalForm
        errors={errors}
        isRegistered={isRegistered}
        control={control}
        steps={steps}
        setValue={setValue}
        onSubmit={handleUpdate}
        handleSubmit={handleSubmit}
        defaultValue={DEFAULT_VALUE}
        loading={updateLoading}
      />
    ),
  };
  const assignAdmin = {
    title: <h6> {t(assignAdminOrQrCode)} </h6>,
    needRegistration: true,
    basic: 'additional',
    required: false,
    element: (
      <AssignAdmin
        errors={errors}
        steps={steps}
        control={control}
        onSubmit={handleUpdate}
        handleSubmit={handleSubmit}
        defaultValue={defaultValues}
      />
    ),
  };

  const isRenderAssignAdmin = useMemo(() => isRegistered && steps === 3, [isRegistered, steps]);
  const isRenderAdditionalInfo = useMemo(() => isRegistered && steps !== 1, [isRegistered, steps]);
  return (
    <>
      {!!getValues('customError') && (
        <div className="p-2 mb-2 rounded shadow-bg-danger">{getValues('customError')}</div>
      )}
      <UI title={basicInfo.title} element={basicInfo.element} />

      {isRenderAdditionalInfo && <UI title={additionalInfo.title} element={additionalInfo.element} />}
      {isRenderAssignAdmin && <UI title={assignAdmin.title} element={assignAdmin.element} />}
    </>
  );
}
interface UIProps {
  title: ReactNode;
  element: ReactNode;
}
function UI({ title, element }: UIProps) {
  return (
    <Card>
      <CardHeader className="py-3">{title}</CardHeader>
      <CardBody>{element}</CardBody>
    </Card>
  );
}

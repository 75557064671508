import { useQuery, useToggle, useUpdateEffect } from 'hooks';
import {
  queryVar,
  slider,
  sliderLocationEnum,
  sliderStatistics,
  sliderStatusEnum,
  sliderUserTypeEnum,
  t_sliderFilter,
} from 'pages/content-management/slider/types';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GET_ALL_SLIDERS } from 'services/graphql/queries/sliders';
import { isTrophy } from 'tools/methods';
import { UserTypeEnum } from '../../../tools/enums';

export default function useSliders() {
  const [searchParams] = useSearchParams();
  const [isSortChanged, toggleSortChanged] = useToggle(false);
  const [isEmployer, toggleIsEmployer] = useToggle(
    searchParams.get('userType') ? searchParams.get('userType') === sliderUserTypeEnum.EMPLOYER : true
  );
  const [currentLocation, setCurrentLocation] = useState<sliderLocationEnum>(
    (searchParams.get('location') as sliderLocationEnum) || sliderLocationEnum.HOME_PAGE_TOP
  );

  const [filterLoading, toggleFitlerLoading] = useToggle(false);
  const navigate = useNavigate();

  const [sliderList, setSliderList] = useState<{ statistics: sliderStatistics; result: slider[] }>({
    result: [],
    statistics: {
      employeeCount: 0,
      employerCount: 0,
      activeSlider: 0,
      allSlider: 0,
      deactivateSlider: 0,
      expireSlider: 0,
    },
  });

  useEffect(() => {
    toggleIsEmployer(
      searchParams.get('userType') ? searchParams.get('userType') === sliderUserTypeEnum.EMPLOYER : true
    );
  }, [searchParams.get('userType')]);

  useEffect(() => {
    if (!searchParams.get('userType') && !searchParams.get('location')) {
      navigate(
        `?userType=${
          isEmployer ? sliderUserTypeEnum.EMPLOYER : sliderUserTypeEnum.EMPLOYEE
        }&location=${currentLocation}`
      );
    }
  }, []);

  const vars = useMemo(() => {
    return {
      location: searchParams.get('location') || sliderLocationEnum.HOME_PAGE_TOP,
      // userType: searchParams.get('userType')
      //   ? searchParams.get('userType') === sliderUserTypeEnum.EMPLOYER
      //     ? sliderUserTypeEnum.EMPLOYER
      //     : sliderUserTypeEnum.EMPLOYEE
      //   : sliderUserTypeEnum.EMPLOYER,
      userType: isEmployer ? sliderUserTypeEnum.EMPLOYER : sliderUserTypeEnum.EMPLOYEE,
      sliderStatus: sliderStatusEnum.ALL,
    };
  }, []);
  const { data, refetch, loading, error, fetchMore } = useQuery<
    {
      getSliderDetailsForAdmin: {
        statistics: {
          employerCount: number;
          employeeCount: number;
          allSlider: number;
          deactivateSlider: number;
          activeSlider: number;
          expireSlider: number;
        };
        result: slider[];
      };
    },
    queryVar
  >(GET_ALL_SLIDERS, {
    variables: vars as queryVar,
  });
  useMemo(() => {
    if (isTrophy(data?.getSliderDetailsForAdmin)) {
      const realData = {
        statistics: data?.getSliderDetailsForAdmin.statistics,
        result: data?.getSliderDetailsForAdmin.result.map((s, i) => ({ ...s, sortIndex: i + 1 })),
      };
      // @ts-ignore
      setSliderList(realData);
      return realData;
    } else {
      const realData = {
        statistics: { employeeCount: 0, employerCount: 0, activeSlider: 0, allSlider: 0, deactivateSlider: 0 },
        result: [],
      };
      // @ts-ignore
      setSliderList(realData);
      return realData;
    }
  }, [loading]);
  const [filterData, setFilterData] = useState<Required<t_sliderFilter>>({
    location: (searchParams.get('location') as any) || sliderLocationEnum.HOME_PAGE_TOP,
    sliderStatus: sliderStatusEnum.ALL,
  });
  const onFilter = (filterData: t_sliderFilter, isEmployerC?: boolean) => {
    setFilterData(filterData);
    toggleFitlerLoading(true);
    setCurrentLocation(filterData.location as sliderLocationEnum);
    if (isEmployerC || isEmployerC === false) toggleIsEmployer(isEmployerC);
    fetchMore({
      variables: {
        ...filterData,
        userType:
          isEmployerC || isEmployerC === false
            ? isEmployerC
              ? UserTypeEnum.employer
              : UserTypeEnum.employee
            : isEmployer
            ? UserTypeEnum.employer
            : UserTypeEnum.employee,
      },
    })
      .then((result) => {
        if (result.data.getSliderDetailsForAdmin) {
          setSliderList({
            statistics: result.data.getSliderDetailsForAdmin.statistics,
            result: result.data.getSliderDetailsForAdmin.result.map((s, i) => ({ ...s, sortIndex: i + 1 })),
          });
        }
      })
      .finally(() => {
        toggleFitlerLoading(false);
      });
  };
  // useUpdateEffect(() => {
  //   onFilter({
  //     ...(filterData as t_sliderFilter),
  //   });
  // }, [isEmployer]);

  useUpdateEffect(() => {
    navigate(
      `?userType=${isEmployer ? sliderUserTypeEnum.EMPLOYER : sliderUserTypeEnum.EMPLOYEE}&location=${currentLocation}`
    );
  }, [currentLocation, isEmployer]);

  const currentParams = `?userType=${
    isEmployer ? sliderUserTypeEnum.EMPLOYER : sliderUserTypeEnum.EMPLOYEE
  }&location=${currentLocation}`;
  useUpdateEffect(() => {
    toggleSortChanged(false);
  }, [filterData]);
  return {
    data: sliderList,
    statistics: sliderList.statistics,
    isSortChanged,
    toggleSortChanged,
    setSliderList,
    filterData,
    currentLocation,
    loading,
    searchParams,
    error,
    defaultLocation: searchParams.get('location') || undefined,
    refetch,
    isEmployer,
    toggleIsEmployer,
    currentParams,
    onFilter,
    filterLoading,
  };
}

import { Loading } from 'components';
import SubmitButton from 'components/buttons/submitButton';
import CityDropdown from 'components/dropdown/cityDropdown';
import CountryDropdown from 'components/dropdown/countryDropdown';
import { WebsiteInput } from 'components/inputs';
import OnlyNumberInput from 'components/inputs/onlyNumber';
import Link from 'components/link';
import UploadProfileImage from 'components/uploadProfileImage';
import { useToggle } from 'hooks';
import useMutation from 'hooks/useMutation';
import PagesLayout from 'pages/components/layout';
import useDetail from 'pages/fuar/hotel/useDetail';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Control, Controller, useForm } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Col, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import FetchApi from 'services/api/axios';
import { CREATE_HOTEL, UPDATE_HOTEL } from 'services/graphql/queries/hotel/hotel';
import { LANGUAGES_DROPDOWN } from 'tools/constants';
import { Location } from 'tools/i18n/constants';
import {
  address,
  city,
  country,
  created,
  description,
  enter,
  first,
  hotel,
  information,
  language,
  logo,
  name,
  please,
  remove,
  save,
  select,
  successfully,
  update,
  updated,
  website,
} from 'tools/i18n/constants/i18n';
import { goBack, isNew } from 'tools/methods';
import { t_city, t_countryList, t_errors, t_languageKeys } from 'tools/types';

export default function HotelDetail() {
  const { t } = useTranslation();
  // const data = Fake_DATA;
  const { type } = useParams();
  const backLinkTitle = `${t(hotel)}`;
  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <PagesLayout backLinkTitle={backLinkTitle}>
        <Card>
          <Header isNew={isNew(type)} />
          <CardBody>{isNew(type) ? <NewHotel /> : <UpdateHotel />}</CardBody>
        </Card>
      </PagesLayout>
    </Col>
  );
}

function NewHotel() {
  return <HotelForm isNew={true} />;
}

function UpdateHotel() {
  const { data, loading } = useDetail();
  return (
    <Loading loading={loading}>
      <HotelForm data={data} isNew={false} />
    </Loading>
  );
}

interface HotelFormProps {
  isNew: boolean;
  data?: any;
}

type t_customLang = { language: t_languageKeys; description: string; id?: number };

type t_defaultValue = {
  id: undefined | number;
  name: undefined | string;
  lat: undefined | number | string;
  lon: undefined | number | string;
  location: undefined | string;
  webSite: undefined | string;
  country: undefined | t_countryList;
  city: undefined | t_city;
  language?: t_customLang[];
  logo?: string | File;
  descriptionInputTr?: string;
  descriptionInputEn?: string;
};

function HotelForm(props: HotelFormProps) {
  const { isNew, data } = props;
  const { t } = useTranslation();
  const [realLoading, toggleRealLoading] = useToggle();
  const [execute] = useMutation<{ createHotel: t_defaultValue }>(CREATE_HOTEL);
  const [updateExecute] = useMutation<{ updateHotel: t_defaultValue }>(UPDATE_HOTEL);
  const DEFAULT_VALUES: t_defaultValue = useMemo(
    () => ({
      id: data?.id,
      name: data?.name,
      lat: data?.lat,
      lon: data?.lon,
      location: data?.location,
      webSite: data?.webSite,
      country: data?.country,
      city: data?.city,
      logo: data?.logo,
      language: data?.language,
    }),
    [data]
  );
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<t_defaultValue>({
    defaultValues: DEFAULT_VALUES,
  });
  const onSubmit = (data = DEFAULT_VALUES) => {
    toggleRealLoading(true);
    const updatedData = {
      ...data,
      lat: parseFloat(data.lat as string),
      lon: parseFloat(data.lon as string),
      cityId: data?.city?.id || data?.city,
      countryId: data?.country?.id || data?.city,
    };
    execute({
      variables: updatedData,
    })
      .then((res) => {
        if (data.logo) {
          const id = res.data?.createHotel?.id;
          if (id) {
            const formData = new FormData();
            formData.append('id', id.toString());
            formData.append('image', data.logo);
            FetchApi({
              url: 'HOTEL_LOGO',
              data: formData,
              method: 'PATCH',
            })
              .then(() => {
                toggleRealLoading(false);
                toast.success(`${t(hotel)} ${t(successfully)} ${t(created)}`);
                goBack();
              })
              .catch((e) => {
                console.error(e);
                toggleRealLoading(false);
              });
          }
        } else {
          toggleRealLoading(false);
          toast.success(`${t(hotel)} ${t(successfully)} ${t(created)}`);
          goBack();
        }
      })
      .catch((e) => {
        console.error(e);
        toggleRealLoading(false);
      });
  };

  const onUpdate = (data = DEFAULT_VALUES) => {
    toggleRealLoading(true);
    const updatedValue = {
      ...data,
      lat: parseFloat(data.lat as string),
      lon: parseFloat(data.lon as string),
      cityId: data?.city?.id || data?.city,
      countryId: data?.country?.id || data?.city,
      language: data?.language?.map((lang) => ({ language: lang.language, description: lang.description })),
    };
    updateExecute({
      variables: updatedValue,
    })
      .then(() => {
        if (data.logo && data.logo !== DEFAULT_VALUES.logo) {
          if (data.id) {
            const formData = new FormData();
            formData.append('id', data.id.toString());
            formData.append('image', data.logo);
            FetchApi({
              url: 'HOTEL_LOGO',
              data: formData,
              method: 'PATCH',
            })
              .then(() => {
                toggleRealLoading(false);
                toast.success(`${t(hotel)} ${t(successfully)} ${t(updated)}`);
                goBack();
              })
              .catch((e) => {
                console.error(e);
                toggleRealLoading(false);
              });
          }
        } else {
          toggleRealLoading(false);
          toast.success(`${t(hotel)} ${t(successfully)} ${t(updated)}`);
          goBack();
        }
      })
      .catch((e) => {
        console.error(e);
        toggleRealLoading(false);
      });
  };

  const ple = `${t(please)} ${t(enter)} ${t(hotel)}`;
  return (
    <Form onSubmit={handleSubmit(isNew ? onSubmit : onUpdate, console.error)}>
      <FormGroup className=" bg-gray rounded p-3">
        <FormGroup>
          <div className="d-flex align-items-center">
            <Controller
              name="logo"
              defaultValue={DEFAULT_VALUES.logo}
              rules={{ required: `${ple} ${t(logo)}` }}
              control={control}
              render={({ field: { onChange } }) => {
                return (
                  <UploadProfileImage
                    avatarSize={'80'}
                    defaultImage={DEFAULT_VALUES.logo as string}
                    onSelect={onChange}
                  />
                );
              }}
            />

            <span className="ml-4">{` ${t(hotel)} ${t(logo)}`}</span>
          </div>
          <FormFeedback>{errors?.logo?.message}</FormFeedback>
        </FormGroup>
        <FormGroup className="mt-4">
          <Label>{`${t(hotel)} ${t(name)}`}</Label>
          <Controller
            name="name"
            defaultValue={DEFAULT_VALUES.name}
            rules={{ required: `${ple} ${t(name)}` }}
            control={control}
            render={({ field: { onChange } }) => {
              return (
                <Input name="name" defaultValue={DEFAULT_VALUES.name} onBlur={onChange} placeholder="Istanbul Hotel" />
              );
            }}
          />
          <FormFeedback>{errors?.name?.message}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>{`${t(hotel)} ${t(website)}`}</Label>
          <WebsiteInput
            isRequired
            placeholder={'www.abc.com'}
            defaultValue={DEFAULT_VALUES.webSite}
            control={control}
            name="webSite"
            requiredErrorText={`${ple} ${t(website)}`}
          />
          <FormFeedback>{errors?.webSite?.message}</FormFeedback>
        </FormGroup>
      </FormGroup>
      <FormGroup className="bg-gray rounded p-3">
        <Label>{`${t(hotel)} ${t(address)}`}</Label>
        <FormGroup className="d-flex">
          <FormGroup className="flex-grow-1 mr-1">
            <OnlyNumberInput
              requiredErrorText={`${ple} lat`}
              className="flex-grow-1 mr-1"
              placeholder="41.015137"
              defaultValue={DEFAULT_VALUES.lat}
              //@ts-ignore
              control={control}
              name="lat"
              isRequired
              isSpecialCharacterAllowed
            />
            <FormFeedback>{errors?.lat?.message}</FormFeedback>
          </FormGroup>
          <FormGroup className="flex-grow-1">
            <OnlyNumberInput
              requiredErrorText={`${ple} lon`}
              className="flex-grow-1"
              placeholder="28.979530"
              defaultValue={DEFAULT_VALUES.lon}
              //@ts-ignore
              control={control}
              name="lon"
              isRequired
              isSpecialCharacterAllowed
            />
            <FormFeedback>{errors?.lon?.message}</FormFeedback>
          </FormGroup>
        </FormGroup>
        <FormGroup>
          <CityAndCountry
            control={control}
            country={DEFAULT_VALUES.country}
            errors={errors}
            city={DEFAULT_VALUES.city}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="location"
            defaultValue={DEFAULT_VALUES.location}
            rules={{ required: `${ple} ${t(Location)}` }}
            control={control}
            render={({ field: { onChange } }) => {
              return (
                <Input
                  defaultValue={DEFAULT_VALUES.location}
                  onBlur={onChange}
                  className="flex-grow-1"
                  placeholder="Örnek Köyü,27, 53340, Pazar/Istanbul, Turkey"
                />
              );
            }}
          />
          <FormFeedback>{errors?.location?.message}</FormFeedback>
        </FormGroup>
      </FormGroup>
      <MultipleLang control={control} setValue={setValue} defaultValue={DEFAULT_VALUES.language} errors={errors} />
      <FormGroup className="mt-2">
        <SubmitButton loading={realLoading}>{t(isNew ? save : update)}</SubmitButton>
      </FormGroup>
    </Form>
  );
}

interface HeaderProps {
  isNew: boolean;
}

const Header: FC<HeaderProps> = ({ isNew }) => {
  const { t } = useTranslation();
  return (
    <CardHeader className="py-3">
      <div className="d-flex align-items-center justify-content-between">
        <h5>{`${t(hotel)} ${t(information)}`}</h5>
        {!isNew && (
          <Button disabled className="rounded" color="danger">
            <Link to="delete">{t(remove)}</Link>
          </Button>
        )}
      </div>
    </CardHeader>
  );
};

interface MultipleLang {
  setValue: UseFormSetValue<t_defaultValue>;
  defaultValue?: t_customLang[];
  errors?: t_errors<t_defaultValue>;
  control: Control<t_defaultValue>;
}

const MultipleLang: FC<MultipleLang> = (props) => {
  const { setValue, defaultValue = [], errors, control } = props;
  const { t } = useTranslation();
  const [value, setValues] = useState(defaultValue);
  const handleChangeValue = (l: t_languageKeys, val: string) => {
    setValues((prev) => {
      const isSame = prev?.find((lang) => lang.language === l);
      if (isSame) {
        const findUnMatch = prev.filter((lang) => lang.language !== l);
        return [...findUnMatch, { ...isSame, description: val }];
      } else {
        //const findUnMatch = prev.filter(lang => lang.language !== l)
        return [...prev, { language: l, description: val }];
      }
    });
  };
  useEffect(() => {
    if (value) {
      setValue('language', value);
    }
  }, [value]);
  return (
    <div className="d-flex justify-content-between flex-wrap">
      <FormGroup className={`p-4 mr-3 flex-grow-1 rounded bg-gray position-relative mb-2`}>
        <FormGroup>
          <Label>{t(language)}</Label>
          <Select isDisabled defaultValue={LANGUAGES_DROPDOWN[0]} options={[LANGUAGES_DROPDOWN[0]]} />
        </FormGroup>
        <FormGroup>
          <Label>{t(description)}</Label>
          <Controller
            name="descriptionInputTr"
            defaultValue={
              defaultValue
                ? defaultValue?.find((item) => item.language === LANGUAGES_DROPDOWN[0].value)?.description
                : undefined
            }
            control={control}
            rules={{ required: `${t(please)} ${t(enter)} ${t(description)}` }}
            render={({ field: { onChange } }) => {
              return (
                <Input
                  type="textarea"
                  defaultValue={
                    defaultValue
                      ? defaultValue?.find((item) => item.language === LANGUAGES_DROPDOWN[0].value)?.description
                      : undefined
                  }
                  onBlur={(e) => {
                    onChange(e);
                    handleChangeValue(LANGUAGES_DROPDOWN[0].value, e.target.value);
                  }}
                  name="description"
                />
              );
            }}
          />
        </FormGroup>
        <FormFeedback>{errors?.language?.message || errors?.descriptionInputTr?.message}</FormFeedback>
      </FormGroup>

      <FormGroup className={`p-4 flex-grow-1 rounded bg-gray position-relative mb-2`}>
        <FormGroup>
          <Label>{t(language)}</Label>
          <Select isDisabled defaultValue={LANGUAGES_DROPDOWN[1]} options={[LANGUAGES_DROPDOWN[1]]} />
        </FormGroup>
        <FormGroup>
          <Label>{t(description)}</Label>
          <Controller
            defaultValue={
              defaultValue
                ? defaultValue?.find((item) => item.language === LANGUAGES_DROPDOWN[1].value)?.description
                : undefined
            }
            rules={{ required: `${t(please)} ${t(enter)} ${t(description)}` }}
            name="descriptionInputEn"
            control={control}
            render={({ field: { onChange } }) => {
              return (
                <Input
                  type="textarea"
                  defaultValue={
                    defaultValue
                      ? defaultValue?.find((item) => item.language === LANGUAGES_DROPDOWN[1].value)?.description
                      : undefined
                  }
                  onBlur={(e) => {
                    onChange(e);
                    handleChangeValue(LANGUAGES_DROPDOWN[1].value, e.target.value);
                  }}
                  name="descriptionInputEn"
                />
              );
            }}
          />
        </FormGroup>
        <FormFeedback>{errors?.language?.message || errors?.descriptionInputEn?.message}</FormFeedback>
      </FormGroup>
    </div>
  );
};

interface CityAndCountryProps {
  control: Control<t_defaultValue>;
  errors?: t_errors<t_defaultValue>;
  country?: t_countryList;
  city?: t_city;
}

const CityAndCountry: FC<CityAndCountryProps> = (props) => {
  const { control, errors, country: defaultCountry, city: defaultCity } = props;
  const { t } = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);
  console.log(defaultCity);
  return (
    <FormGroup>
      <Label>{`${t(hotel)} ${t(address)}`}</Label>
      <div className="d-flex">
        <div className="d-flex flex-column flex-grow-1">
          <Controller
            control={control}
            defaultValue={selectedCountry}
            rules={{ required: `${t(please)} ${t(enter)} ${t(country)}` }}
            name="country"
            render={({ field: { onChange } }) => {
              return (
                <CountryDropdown
                  defaultValue={
                    defaultCountry
                      ? {
                          value: defaultCountry?.id?.toString(),
                          label: defaultCountry?.name,
                        }
                      : undefined
                  }
                  onSelect={(val: any) => {
                    onChange(val);
                    setSelectedCountry(val);
                  }}
                  className="flex-grow-1 mr-1"
                  placeholder={t(country)}
                />
              );
            }}
          />
          <FormFeedback>{errors?.country?.message}</FormFeedback>
        </div>
        <div className="d-flex flex-column flex-grow-1">
          <Controller
            control={control}
            name="city"
            defaultValue={defaultCity}
            rules={{ required: `${t(please)} ${t(enter)} ${t(city)}` }}
            render={({ field: { onChange } }) => {
              return (
                <CityDropdown
                  selectedCountry={selectedCountry}
                  // @ts-ignore
                  defaultValue={
                    defaultCity
                      ? {
                          value: defaultCity?.id?.toString(),
                          label: defaultCity?.name,
                        }
                      : undefined
                  }
                  isDisabled={defaultCity ? false : !selectedCountry}
                  onSelect={(val: any) => {
                    onChange(val);
                  }}
                  className="flex-grow-1"
                  placeholder={!selectedCountry ? `${t(please)} ${t(select)} ${t(country)} ${t(first)}` : t(city)}
                />
              );
            }}
          />
          <FormFeedback>{errors?.city?.message}</FormFeedback>
        </div>
      </div>
    </FormGroup>
  );
};

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SCHOOL_ADMIN_APP_ROUTES } from 'route/appRoutes';

export default function StudentDashboard() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(`/schoolAdmin/${SCHOOL_ADMIN_APP_ROUTES.studentManagement}`);
  }, []);
  return (
    <>
      <div className="mx-auto">Welcome To School Dashboard</div>;
    </>
  );
}

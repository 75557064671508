import React from 'react';
import Icon from 'components/icons';
import { ad, history, no, status } from 'tools/i18n/constants/i18n';
import { useTranslation } from 'react-i18next';
import { t_dataTypes } from 'pages/ads/bazzarAds/useBazzarAds';
import { Date } from 'tools/i18n/constants';
import { toDate } from 'tools/methods';

interface BazaarAdsHistoryProps {
  data: t_dataTypes['statusActivity'];
}

export default function BazaarAdHistory<T extends BazaarAdsHistoryProps>(props: T) {
  const { t } = useTranslation();
  const { data } = props;
  return (
    <div className="p-2 rounded bg-white">
      <div className="d-flex flex-column">
        <div className="p-2 border-bottom">
          <div className="d-flex align-items-center mb-2 ml-2">
            <Icon Name="FiClock" />
            <h6 className="ml-2 mb-0">{`${t(ad)} ${t(history)}`}</h6>
          </div>
        </div>
        {data?.length ? (
          <>
            <div className="p-3 border-bottom">
              <div className="d-flex">
                <div className="d-flex flex-grow-1">
                  <Icon Name="FiActivity" />
                  <span className="ml-2">{t(status)}</span>
                </div>
                <div className="d-flex flex-grow-1">
                  <Icon Name="AiFillCalendar" />
                  <span className="ml-2">{t(Date)}</span>
                </div>
              </div>
            </div>
            {data.map((activity) => {
              return (
                <div className="d-flex w-100" key={activity.id}>
                  <span className="flex-grow-1">{activity.status}</span>
                  <span className="flex-grow-1">{toDate(activity.createdAt)}</span>
                </div>
              );
            })}
          </>
        ) : (
          <h6 className="mx-auto mt-3">{`${t(no)} ${t(history)}`}</h6>
        )}
      </div>
    </div>
  );
}

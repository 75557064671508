import { Loading } from 'components';
import SubmitButton from 'components/buttons/submitButton';
import OnlyCharInput from 'components/inputs/onlyCharacter';
import useMutation from 'hooks/useMutation';
import PagesLayout from 'pages/components/layout';
import UpdateAddHeader from 'pages/components/updateAddHeader';
import useCity from 'pages/fuar/location/useCity';
import React, { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardBody, Form, FormFeedback, FormGroup, Label } from 'reactstrap';
import { CREATE_CITY, UPDATE_CITY } from 'services/graphql/queries/location';
import {
  New,
  add,
  city,
  country,
  created,
  enter,
  information,
  name,
  please,
  save,
  successfully,
  update,
  updated,
} from 'tools/i18n/constants/i18n';
import { goBack, isNew } from 'tools/methods';

export default function CityDetail() {
  const { t } = useTranslation();
  const { cityType } = useParams();
  const backLinkTitle = `${t(country)}`;
  return (
    <PagesLayout backLinkTitle={backLinkTitle}>
      <Card>
        <Header isNew={isNew(cityType)} />
        <CardBody>{isNew(cityType) ? <NewCity /> : <UpdateCity />}</CardBody>
      </Card>
    </PagesLayout>
  );
}

function NewCity() {
  return <CityForm isNew={true} />;
}

function UpdateCity() {
  const { loading, data } = useCity();

  return (
    <Loading loading={loading}>
      <CityForm data={data} isNew={false} />
    </Loading>
  );
}

interface CityFormProps {
  isNew: boolean;
  data?: any;
}

function CityForm(props: CityFormProps) {
  const { t } = useTranslation();
  const { isNew, data } = props;
  const { type } = useParams();
  const [execute, { loading: createLoading }] = useMutation(CREATE_CITY);
  const [updateExecute, { loading: updateLoading }] = useMutation(UPDATE_CITY);
  const DEFAULT_VALUES = useMemo(
    () => ({
      id: data?.id,
      name: data?.name,
      countryId: parseInt(type as string),
    }),
    []
  );
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: DEFAULT_VALUES,
  });

  const onSubmit = (data = DEFAULT_VALUES) => {
    execute({
      variables: data,
    })
      .then(() => {
        toast.success(`${t(city)} ${t(successfully)} ${t(created)}`);
        goBack();
      })
      .catch(console.error);
  };

  const onUpdate = (data = DEFAULT_VALUES) => {
    updateExecute({
      variables: data,
    })
      .then(() => {
        toast.success(`${t(city)} ${t(successfully)} ${t(updated)}`);
        goBack();
      })
      .catch(console.error);
  };

  return (
    <Form className="px-5" onSubmit={handleSubmit(isNew ? onSubmit : onUpdate, console.error)}>
      <FormGroup className=" bg-gray rounded p-3">
        <Label>{`${t(city)} ${t(name)}`}</Label>
        <OnlyCharInput
          isRequired
          isEveryCharacterAllowed
          requiredErrorText={`${t(please)} ${t(enter)} ${t(city)} ${t(name)}`}
          control={control}
          defaultValue={data?.name}
          name="name"
          placeholder="Istanbul"
        />

        <FormFeedback>
          <>{errors?.name?.message}</>
        </FormFeedback>
      </FormGroup>
      <SubmitButton loading={createLoading || updateLoading}>{t(isNew ? save : update)}</SubmitButton>
    </Form>
  );
}

interface HeaderProps {
  isNew: boolean;
}

const Header: FC<HeaderProps> = ({ isNew }) => {
  const { t } = useTranslation();
  const title = isNew ? `${t(add)} ${t(New)} ${t(city)}` : `${t(update)} ${information}`;
  return <UpdateAddHeader isNew={isNew} title={title} />;
};

import { dataToTree } from 'components/tree-select-checkbox/utils';
import { useUpdateEffect } from 'hooks';
import useQuery from 'hooks/useQuery';
import { useMemo } from 'react';
import { GET_ALL_WORK_EXPERIENCE_REJECTION } from 'services/graphql/queries/workExperienceRejection';
import { d_getTreeData } from 'store/tree/tree.dispatchs';
import { APP_HELPER } from 'tools/enums';
import { isTrophy, toArray } from 'tools/methods';
import { t_workRejection } from './types';

export default function useExperienceExpection() {
  const { data, loading, error } = useQuery<{ employmentWorkExperienceRejectReasonsList: t_workRejection[] }>(
    GET_ALL_WORK_EXPERIENCE_REJECTION,
    {
      variables: {
        page: 1,
        size: APP_HELPER.treeMaxSize,
      },
    }
  );
  const isListEmpty: boolean = useMemo(() => isTrophy(data?.employmentWorkExperienceRejectReasonsList?.length), [data]);
  const updatedData = useMemo(
    () =>
      isListEmpty
        ? dataToTree(toArray(data?.employmentWorkExperienceRejectReasonsList), {
            id: '_id',
            labelKey: 'originalText',
            iconKey: 'imageUrl',
            childKey: 'sections',
          })
        : [],
    [data, isListEmpty]
  );

  useUpdateEffect(() => {
    if (isTrophy(updatedData)) {
      d_getTreeData(updatedData);
    }
  }, [updatedData]);
  return { isListEmpty, data: updatedData, error, loading };
}

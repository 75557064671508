import { Loading } from 'components';
import PagesLayout from 'pages/components/layout';
import DataTables, { TableSectionTitle } from 'components/tables/dataTable';
import React from 'react';
import useDeleteReasons, { t_deleteReason } from 'pages/user-management/deleteReasons/useDeleteReasons';
import { ApolloError } from '@apollo/client';
import Link from 'components/link';
import { Button, Col } from 'reactstrap';
import { detail, reasons } from 'tools/i18n/constants/i18n';
import { useTranslation } from 'react-i18next';

export default function DeleteReasons() {
  const { error, data, loading, fetchMore, refetch, addButtonText, tableTitle } = useDeleteReasons();
  return (
    <Col xs={12} xl={0} xxl={10} className="mx-auto">
      <Loading loading={loading}>
        <DeleteReasonTable
          tableTitle={tableTitle}
          refetch={refetch}
          addButtonText={addButtonText}
          fetchMore={fetchMore}
          data={data}
          error={error}
        />
      </Loading>
    </Col>
  );
}

interface DeleteReasonTableProps {
  error?: ApolloError;
  data: t_deleteReason[];
  fetchMore: any;
  refetch: any;
  addButtonText: string;
  tableTitle: string;
}

function DeleteReasonTable<T extends DeleteReasonTableProps>(props: T): JSX.Element {
  const { addButtonText, data, error, tableTitle } = props;
  const { t } = useTranslation();
  const tableColumn = [
    {
      name: <TableSectionTitle name="FiUser" title={t(reasons)} />,
      center: false,
      cell: (r: t_deleteReason) => {
        return r.language?.find((item) => item.language === 'tr')?.content;
      },
    },
    {
      name: '',
      cell: (r: t_deleteReason) => {
        return (
          <Link className="text-white ml-auto" to="deleteReasonsDetail" param={r?.id?.toString()}>
            <Button color="primary">{t(detail)}</Button>
          </Link>
        );
      },
    },
  ];
  return (
    <PagesLayout newSectionButtonLabel={addButtonText}>
      <DataTables columns={tableColumn} data={data} headerTitle={tableTitle} disabled={!!error} />
    </PagesLayout>
  );
}

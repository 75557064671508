import TreeSelectCheckbox from 'components/tree-select-checkbox';
import { t_requiredFields } from 'components/tree-select-checkbox/type';
import SectionLayout from 'pages/ads/jobAds/components/layout/sectionLayout';
import { FormProps } from 'pages/ads/jobAds/index';
import { t_jobCategory } from 'pages/content-management/employment/jobCategories/types';
import useJobCategory from 'pages/content-management/employment/jobCategories/useJobCategory';
import React, { FC, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { specialities } from 'tools/i18n/constants/i18n';
type SpecialityProps = FormProps;

type dataFieldType = t_requiredFields<t_jobCategory>;

function Speciality(props: SpecialityProps) {
  const { t } = useTranslation();
  const { data } = useJobCategory();
  const { onChange } = props;
  const setSelectedNodeId = useState<string | undefined>(undefined);

  const handleChange = useCallback((node: dataFieldType) => {
    setSelectedNodeId[1]((prev) => {
      if (node.id === prev) {
        onChange?.('speciality', undefined);
        return undefined;
      } else {
        const obj = node?._parent
          ? {
              branchId: node?._parent,
              sectionId: node?._id,
            }
          : {
              branchId: node?._id,
            };
        onChange?.('speciality', obj);
        return node.id as string;
      }
    });
  }, []);
  return (
    <SectionLayout title={t(specialities)} icon="FiGrid">
      <div className="d-flex flex-column">
        <TreeSelectComponent data={data} onChange={handleChange} />
      </div>
    </SectionLayout>
  );
}
export default memo(Speciality);

interface TreeSelectComponentProps {
  onChange: (node: dataFieldType) => void;
  data: dataFieldType[];
}

// eslint-disable-next-line react/display-name
const TreeSelectComponent: FC<TreeSelectComponentProps> = memo((props) => {
  const { onChange, data } = props;
  return (
    <TreeSelectCheckbox
      style={{ maxHeight: '400px', overflowY: 'auto', zIndex: '1' }}
      data={data}
      checkbox
      onSelect={onChange}
    />
  );
});

import { useState } from 'react';

export type t_toggleValueFunc = (replaceVal?: boolean | ((replaceVal: boolean) => boolean) | undefined) => void;
export type useToggleReturn = ReturnType<() => [boolean, t_toggleValueFunc]>;

export function useToggle(defaultValue = false): useToggleReturn {
  const [value, setValue] = useState(defaultValue);
  const toggleValue: t_toggleValueFunc = (replaceVal) => {
    if (typeof replaceVal === 'function') {
      setValue((prev) => replaceVal(prev));
    } else {
      setValue((prev) => (typeof replaceVal === 'boolean' ? replaceVal : !prev));
    }
  };
  return [value, toggleValue];
}

export default useToggle;

import { ApolloError } from '@apollo/client';
import { PasswordInput, PhoneNumberInput, PhoneNumberInputProps } from 'components/inputs';
import ShowError from 'components/showError';
import { useToggle, useUpdateEffect } from 'hooks';
import useMutation from 'hooks/useMutation';
import PagesLayout from 'pages/components/layout';
import React, { ChangeEvent, FC, useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import { Control, Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Col, Form, FormFeedback, Input, Label } from 'reactstrap';
import { ADD_NEW_ADMIN } from 'services/graphql/queries/users/users.geaphql';
import { DEFAULT_COUNTRY, EMAIL_REGEXP, ROLES_LIST } from 'tools/constants';
import { ROLES } from 'tools/enums';
import {
  added,
  admin,
  create,
  description,
  email,
  enter,
  first,
  input,
  last,
  name,
  password,
  phoneNumber,
  please,
  role,
  select,
  successfully,
  update,
  valid,
} from 'tools/i18n/constants/i18n';
import { goBack, isTrophy, replaceState } from 'tools/methods';
import { t_errors, t_userInfo } from 'tools/types';

type formDefaultValue = {
  countryCode?: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  role: string[] | { label: string; value: string }[];
  password?: string;
  adminDescription?: string;
  mail?: string;
  customError?: string;
};

interface PasswordProps {
  onChange: (value: string) => void;
  defaultValue?: string;
  name: string;
  error?: string;
  disabled?: boolean;
  placeholder?: string;
}

interface EditFormProps {
  isNew: boolean;
  toggleIsNew(): void;
  fetchLoading?: boolean;
  fetchError?: any;
  fetchData?: t_userInfo;
}

interface TextInputProps {
  label: string;
  placeholder: string;
  name: string;
  defaultValue?: string;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  disabled?: boolean;
  type?: any;
}

export default function NewAdmin() {
  const { id } = useParams();
  const [isNew, toggleIsNew] = useToggle(id === 'new'); // false flag = edit , true flag = new
  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <PagesLayout backLink="admins" backLinkParam="/" backLinkTitle="Admins">
        <div className="bg-white rounded-md p-3">
          <EditForm
            fetchLoading={false}
            fetchError={undefined}
            fetchData={undefined}
            isNew={isNew}
            toggleIsNew={toggleIsNew}
          />
        </div>
      </PagesLayout>
    </Col>
  );
}

const INITIAL_VALUES = {
  countryCode: DEFAULT_COUNTRY.value,
  firstName: undefined,
  lastName: undefined,
  phoneNumber: undefined,
  role: undefined,
  adminDescription: undefined,
  password: undefined,
  mail: undefined,
};

const EditForm: FC<EditFormProps> = (props) => {
  const { isNew, fetchLoading, fetchData, toggleIsNew } = props;
  const [execute, { data, loading }] = useMutation(ADD_NEW_ADMIN);
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<formDefaultValue>({
    defaultValues: INITIAL_VALUES,
  });
  const onSubmit = async (data: formDefaultValue) => {
    setValue('customError', '');
    console.log(data);
    const updatedData = {
      fn: data.firstName,
      ln: data.lastName,
      pc: data?.countryCode,
      pn: data?.phoneNumber?.toString(),
      mail: data.mail,
      password: data.password,
      roleSlugs: data.role.map((r) => (typeof r === 'string' ? r : r.value)),
    };
    await execute({
      variables: updatedData,
    })
      .then((res) => {
        console.log(res);
        if (res.data?.createNewAdmin) {
          toast.success(`${t(admin)} ${t(t(successfully))} ${t(t(added))}`);
          goBack();
        } else {
          const message =
            // @ts-ignore
            res?.errors?.graphQLErrors?.[0].extensions?.exception?.response?.devMessage || 'Error Happened';
          setValue('customError', message);
        }
      })
      .catch((err: ApolloError) => {
        // @ts-ignore
        const message = err.graphQLErrors?.[0].extensions?.response?.message?.[0];

        setValue('customError', message || err.message);
      });
  };
  useUpdateEffect(() => {
    const id = data?.createNewUser?.id;
    if (id && isNew) {
      replaceState({ data: id, unused: 'new', url: id });
      toggleIsNew();
    }
  }, [data]);

  const disabled = fetchLoading;
  const DDOptions = fetchData
    ? {
        value: fetchData.role as string,
        label: fetchData.role as string,
      }
    : undefined;
  return (
    <Form autoComplete="off" onSubmit={handleSubmit(onSubmit, console.error)}>
      <input autoComplete="false" name="hidden" type="text" className="d-none" />
      {!!getValues('customError') && (
        <FormGroup className="mb-3">
          <ShowError>{getValues('customError')}</ShowError>
        </FormGroup>
      )}
      <FormGroup>
        <Controller
          name="firstName"
          control={control}
          rules={{
            required: `${t(please)} ${t(enter)} ${t(valid)} ${t(name)}`,
          }}
          defaultValue={fetchData ? fetchData?.firstName : undefined}
          render={({ field: { onChange } }) => {
            return (
              <TextInput
                disabled={disabled}
                name="firstName"
                data-testid="firstName"
                defaultValue={fetchData ? fetchData?.firstName : undefined}
                label={`${t(first)} ${t(name)}`}
                onChange={onChange}
                error={
                  errors?.firstName?.message ||
                  (errors?.firstName ? `${t(please)} ${t(enter)} ${t(valid)} ${t(input)}` : '')
                }
                placeholder={`${t(first)} ${t(name)}`}
              />
            );
          }}
        />
      </FormGroup>

      <FormGroup>
        <Controller
          name="lastName"
          control={control}
          rules={{
            required: `${t(please)} ${t(enter)} ${t(valid)} ${t(name)}`,
          }}
          defaultValue={fetchData ? fetchData?.lastName : undefined}
          render={({ field: { onChange } }) => {
            return (
              <TextInput
                disabled={disabled}
                name="lastName"
                data-testid="lastName"
                defaultValue={fetchData ? fetchData?.lastName : undefined}
                label={`${t(last)} ${t(name)}`}
                onChange={onChange}
                error={
                  errors?.lastName?.message ||
                  (errors?.lastName ? `${t(please)} ${t(enter)} ${t(valid)} ${t(input)}` : '')
                }
                placeholder={`${t(last)} ${t(name)}`}
              />
            );
          }}
        />
      </FormGroup>

      <FormGroup>
        <Controller
          name="adminDescription"
          control={control}
          rules={{
            required: `${t(please)} ${t(enter)} ${t(valid)} ${t(description)}`,
          }}
          defaultValue={fetchData ? fetchData?.adminDescription : undefined}
          render={({ field: { onChange } }) => {
            return (
              <TextInput
                disabled={disabled}
                name="adminDescription"
                data-testid="adminDescription"
                defaultValue={fetchData ? fetchData?.adminDescription : undefined}
                label={t(description)}
                onChange={onChange}
                error={
                  errors?.adminDescription?.message ||
                  (errors?.adminDescription ? `${t(please)} ${t(enter)} ${t(valid)} ${t(input)}` : '')
                }
                placeholder={`${t(description)}`}
                type="textarea"
              />
            );
          }}
        />
      </FormGroup>

      <Roles errors={errors} control={control} DDOptions={DDOptions} />

      <Controller
        name="phoneNumber"
        control={control}
        rules={{
          required: `${t(please)} ${t(enter)} ${t(valid)} ${t(phoneNumber)}`,
        }}
        render={({ field: { onChange } }) => {
          return (
            <TelInput
              disabled={disabled}
              onCountryChange={(val) => setValue('countryCode', val.value)}
              onPhoneNumberChange={onChange}
              name="phoneNumber"
              data-testid="phoneNumber"
              defaultValue={{
                country: DEFAULT_COUNTRY.value,
                phoneNumber: fetchData?.phoneNumber as string,
              }}
              errorMessage={errors?.phoneNumber?.message}
              label={`${t(phoneNumber)}`}
            />
          );
        }}
      />

      <FormGroup>
        <Controller
          name="mail"
          control={control}
          rules={{
            required: `${t(please)} ${t(enter)} ${t(email)}`,
            pattern: {
              value: EMAIL_REGEXP,
              message: `${t(please)} ${t(enter)} ${t(valid)} ${t(email)}`,
            },
          }}
          defaultValue={fetchData ? fetchData?.mail : undefined}
          render={({ field: { onChange } }) => {
            return (
              <TextInput
                disabled={disabled}
                name="mail"
                data-testid="mail"
                defaultValue={fetchData ? fetchData?.mail : undefined}
                label={`${t(email)}`}
                onChange={onChange}
                error={
                  errors?.mail?.message || (errors?.mail ? `${t(please)} ${t(enter)} ${t(valid)} ${t(input)}` : '')
                }
                placeholder={t(email)}
                type="email"
              />
            );
          }}
        />
      </FormGroup>

      <Controller
        control={control}
        name="password"
        defaultValue={fetchData?.password}
        rules={{
          required: `${t(please)} ${t(enter)} ${t(password)}`,
          /* pattern: {
            value: PASSWORD_REGEXP,
            message: `${t(please)} ${t(enter)} ${t(valid)} ${t(password)}`,
          },*/
        }}
        render={({ field: { onChange, name } }) => {
          return (
            <FormGroup>
              <Label>{t(password)}</Label>
              <Password
                disabled={disabled}
                defaultValue={fetchData?.password}
                error={errors?.password?.message}
                name={name}
                data-testid={name}
                onChange={onChange}
                placeholder={t(password)}
              />
              <FormFeedback>
                <>
                  {errors?.password?.message ||
                    (errors.password ? `${t(please)} ${t(enter)} ${t(valid)} ${t(password)}` : '')}
                </>
              </FormFeedback>
            </FormGroup>
          );
        }}
      />

      <Button disabled={disabled || loading} color="primary" type="submit" className="d-block w-100 p-2 mt-5">
        {t(isNew ? create : update)}
      </Button>
    </Form>
  );
};

interface RolesProps {
  control: Control<formDefaultValue>;
  DDOptions: any;
  errors: t_errors<formDefaultValue>;
}
type selectOptionType = { label: string; value: ROLES };

const Roles: FC<RolesProps> = (props) => {
  const { t } = useTranslation();
  const { control, DDOptions, errors } = props;
  const [selectedRoles, setSelectedRoles] = useState(ROLES_LIST.map((item) => ({ ...item, isDisabled: false })));
  const handleSelectRole = (role: selectOptionType[]) => {
    console.log(role);
    if (role.length === 0) {
      setSelectedRoles([
        { ...ROLES_LIST[0], isDisabled: false },
        { ...ROLES_LIST[1], isDisabled: false },
        { ...ROLES_LIST[2], isDisabled: false },
      ]);
      return;
    }
    if (role[0].value === ROLES.SCHOOL_ADMIN) {
      setSelectedRoles([
        { ...ROLES_LIST[0], isDisabled: true },
        { ...ROLES_LIST[1], isDisabled: true },
        { ...ROLES_LIST[2], isDisabled: false },
      ]);
    } else {
      setSelectedRoles([
        { ...ROLES_LIST[0], isDisabled: false },
        { ...ROLES_LIST[1], isDisabled: false },
        { ...ROLES_LIST[2], isDisabled: true },
      ]);
    }
  };
  return (
    <FormGroup>
      <Label>{t(role)}</Label>
      <div className="border rounded">
        <Controller
          name={'role'}
          control={control}
          rules={{ required: `${t(please)} ${t(select)} ${t(valid)} ${t(role)}` }}
          render={({ field: { onChange } }) => {
            return (
              <Select
                value={
                  isTrophy(selectedRoles.filter((r) => !r.isDisabled))
                    ? undefined
                    : selectedRoles.filter((r) => r.isDisabled)
                }
                options={selectedRoles}
                isMulti
                defaultValue={DDOptions}
                onChange={(value) => {
                  handleSelectRole(value as selectOptionType[]);
                  onChange(value);
                }}
              />
            );
          }}
        />
      </div>
      <FormFeedback>{errors?.role?.message}</FormFeedback>
    </FormGroup>
  );
};

const TextInput: FC<TextInputProps> = (props) => {
  const { label, placeholder, name, defaultValue, onChange, error, disabled, type } = props;
  return (
    <FormGroup>
      <Label>{label}</Label>
      <Input
        aria-invalid={!!error}
        onBlur={onChange}
        defaultValue={defaultValue}
        placeholder={placeholder}
        name={name}
        disabled={disabled}
        autoComplete={name}
        role="presentation"
        type={type}
        className="bg-image-none"
      />
      <FormFeedback aria-invalid={!!error}>{error}</FormFeedback>
    </FormGroup>
  );
};

const Password: FC<PasswordProps> = (props) => {
  return <PasswordInput {...props} copyable needCheckBox />;
};

const TelInput: FC<PhoneNumberInputProps> = (props) => {
  return <PhoneNumberInput {...props} />;
};

import { dataToTreeV2 } from 'components/tree-select-checkbox/utils';
import { useGetStore, useMutation, useQuery, useUpdateEffect } from 'hooks';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  CREATE_COMPANY_CATEGORIES,
  DELETE_COMPANY_CATEGORIES,
  GET_COMPANY_CATEGORIES_TREE,
  UPDATE_COMPANY_CATEGORIES,
} from 'services/graphql/queries/companyCategories';
// import { d_getTreeData } from 'store/tree/tree.dispatchs';
import { APP_HELPER } from 'tools/enums';
import { isNew, isTrophy, toArray } from 'tools/methods';
import { t_companyCategory } from './types';

export default function useJobCategoryDetail() {
  const { type } = useParams();
  const [createExecute] = useMutation(CREATE_COMPANY_CATEGORIES);
  const [updateExecute] = useMutation(UPDATE_COMPANY_CATEGORIES);
  const [deleteExecute, { loading }] = useMutation(DELETE_COMPANY_CATEGORIES);

  // const { data } = useQuery<{ employmentEmployerCategoryList: t_companyCategory[] }>(GET_COMPANY_CATEGORIES_TREE, {
  //   variables: {
  //     page: 1,
  //     size: APP_HELPER.treeMaxSize,
  //   },
  // });
  // const isListEmpty: boolean = useMemo(() => isTrophy(data?.employmentEmployerCategoryList?.length), [data]);
  // const updatedData = useMemo(
  //   () => (isListEmpty ? toArray(data?.employmentEmployerCategoryList) : []),
  //   [data, isListEmpty]
  // );

  // useUpdateEffect(() => {
  //   if (isTrophy(updatedData)) {
  //     d_getTreeData(updatedData);
  //   }
  // }, [updatedData]);

  const selectedNode = useGetStore().Tree.selectedNode;
  const treeData = useGetStore().Tree.treeData;

  return {
    treeData: dataToTreeV2(
      treeData as any[],
      {
        childKey: 'subCategories',
        iconKey: 'imageUrl',
        id: '_id',
        labelKey: 'originalName',
      },
      undefined,
      { id: type, selectable: true }
    ),
    // treeData: data?.employmentEmployerCategoryList,
    selectedNode,
    isNew: isNew(type),
    type,
    createExecute,
    updateExecute,
    deleteExecute,
    deleteLoading: loading,
  };
}

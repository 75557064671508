import { Loading } from 'components';
import SubmitButton from 'components/buttons/submitButton';
import CityDropdown from 'components/dropdown/cityDropdown';
import CountryDropdown from 'components/dropdown/countryDropdown';
import Datepicker from 'components/forms/form-widget/datepicker';
import { WebsiteInput } from 'components/inputs';
import UploadCover from 'components/inputs/uploadCover';
import Modal from 'components/modal';
import UploadProfileImage from 'components/uploadProfileImage';
import { useToggle, useUpdateEffect } from 'hooks';
import useMutation from 'hooks/useMutation';
import useQuery from 'hooks/useQuery';
import PagesLayout from 'pages/components/layout';
import RemoveButton from 'pages/components/removeButton';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Image } from 'react-bootstrap';
import { Control, Controller, useForm } from 'react-hook-form';
import { UseFormClearErrors, UseFormSetError, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Col, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import FetchApi from 'services/api/axios';
import { CREATE_FUAR, DELETE_FUAR, GET_SINGLE_FUAR, UPDATE_FUAR } from 'services/graphql/queries/fuar';
import { GET_HOTEL } from 'services/graphql/queries/hotel/hotel';
import { LANGUAGES_DROPDOWN, WEBSITE_REGEXP } from 'tools/constants';
import { APP_ROUTE_NAME } from 'tools/enums';
import {
  Delete,
  Deleted,
  New,
  add,
  address,
  assign,
  banner,
  cancel,
  city,
  country,
  created,
  date,
  description,
  end,
  enter,
  exhibition,
  first,
  fuar,
  fullAddress,
  hotel,
  language,
  logo,
  magazine,
  name,
  please,
  save,
  select,
  start,
  successfully,
  title,
  update,
  updated,
  upload,
  valid,
  website,
} from 'tools/i18n/constants/i18n';
import { errorFinder, isNew, reverse } from 'tools/methods';
import { t_city, t_countryList, t_customLang, t_errors, t_languageKeys, t_languages_dropdown } from 'tools/types';

type hotel = {
  id: number;
  language: t_customLang[];
  lat: number;
  location: string;
  lon: number;
  name: string;
  website: string;
};

type t_defaultValue = {
  id?: number;
  city: undefined | t_city;
  country: undefined | t_countryList;
  endDate: undefined | string;
  startDate: undefined | string;
  language: undefined | t_customLang[];
  title: undefined | string;
  webSite: undefined | string;
  hotelIds: undefined | number[];
  hotel: hotel[];
  poster: string | File | undefined;
  logo: string | File | undefined;
  descriptionInputTr?: string;
  isActive: boolean | undefined;
  descriptionInputEn?: string;
};

export default function FuarDetail() {
  const { type } = useParams();
  const { t } = useTranslation();
  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <PagesLayout backLinkTitle={t(fuar)} backLink="exhibition" backLinkParam="/">
        <CardBody>{isNew(type) ? <NewFuar /> : <UpdateFuar />}</CardBody>
      </PagesLayout>
    </Col>
  );
}
const Header: FC<{ isNew: boolean; data?: any; onStatusChange: (isActive: boolean) => void }> = ({
  isNew,
  data,
  onStatusChange,
}) => {
  const { t } = useTranslation();
  const title = isNew ? `${t(add)} ${t(New)} ${t(fuar)}` : `${t(update)} ${t(fuar)}`;
  const [openModal, toggleModal] = useToggle(false);
  const { type } = useParams();
  const [isActive, toggleIsActive] = useToggle(data?.status === 'ACTIVE');
  const [execute, { loading }] = useMutation(DELETE_FUAR);
  const navigate = useNavigate();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    execute({
      variables: {
        id: parseInt(type as string),
      },
    })
      .then(() => {
        toast.success(`${t(magazine)} ${t(successfully)} ${t(Deleted)}`);
        navigate(`/${APP_ROUTE_NAME.dashboard}/${APP_ROUTE_NAME.exhibition}`);
      })
      .catch(console.error);
  };
  const handleChangeStatus = () => {
    toggleIsActive();
    onStatusChange(!isActive);
  };
  return (
    <>
      <Modal size="lg" isOpen={openModal} toggleOpen={toggleModal} title={`${t(Delete)} ${t(magazine)}`}>
        <Form onSubmit={(e) => onSubmit(e)} className="d-flex flex-column">
          <div className="d-flex align-items-center">
            <div>
              <Image
                className="border rounded-circle mr-3"
                src={data?.logo}
                style={{ minWidth: '80px', minHeight: '80px', maxHeight: '80px', maxWidth: '80px' }}
              />
            </div>
            <h6>{data?.title}</h6>
          </div>
          <div className="d-flex flex-column mt-3">
            <Button disabled={loading} color="danger" type="submit">
              {t(Delete)}
            </Button>
            <Button onClick={() => toggleModal()} disabled={loading} type="button" className="mt-2">
              {t(cancel)}
            </Button>
          </div>
        </Form>
      </Modal>
      <CardHeader className="py-3">
        <div className="d-flex align-items-center justify-content-between">
          <h5>{t(title)}</h5>
          <div className="d-flex align-items-center">
            {!isNew && (
              <FormGroup switch className="d-flex align-items-center mr-4">
                <Label check className="mr-5 mb-0" role="button" htmlFor="activeDeActive">
                  {isActive ? 'Active' : 'De Active'}
                </Label>
                <Input
                  role="button"
                  id="activeDeActive"
                  className=" mb-1"
                  style={{
                    padding: '10px',
                    width: '40px',
                  }}
                  type="switch"
                  size={64}
                  defaultChecked={isActive}
                  onClick={handleChangeStatus}
                />
              </FormGroup>
            )}
            {!isNew && <RemoveButton onClick={toggleModal} />}
          </div>
        </div>
      </CardHeader>
    </>
  );
};
function NewFuar() {
  return <FuarDetailForm isNew={true} />;
}

function UpdateFuar() {
  const { type } = useParams();

  const { data, loading } = useQuery(GET_SINGLE_FUAR, {
    variables: {
      id: parseInt(type as string),
    },
  });
  return (
    <Loading loading={loading}>
      <FuarDetailForm data={data?.findOneExhibition} isNew={false} />
    </Loading>
  );
}
const FuarDetailForm: FC<{ isNew: boolean; data?: any }> = ({ isNew, data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [realLoading, toggleRealLoading] = useToggle(false);
  const [createExecute] = useMutation(CREATE_FUAR);
  const [updateExecute] = useMutation(UPDATE_FUAR);

  const DEFAULT_VALUES: t_defaultValue = useMemo(
    () => ({
      id: data?.id,
      city: data?.city,
      country: data?.country,
      endDate: data?.endDate,
      startDate: data?.startDate,
      hotelIds: data?.hotelIds,
      hotel: data?.hotel,
      title: data?.title,
      webSite: data?.webSite,
      language: data?.language,
      logo: data?.logo,
      isActive: undefined,
      poster: data?.poster,
    }),
    [data]
  );
  const {
    control,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<t_defaultValue>({
    defaultValues: DEFAULT_VALUES,
  });
  const ple = `${t(please)} ${t(enter)} ${t(fuar)}`;
  const inputs = [
    {
      label: `${t(fuar)} ${t(title)}`,
      placeholder: `${ple} ${t(title)}`,
      type: 'text',
      name: 'title',
      specialRender: false,
      rules: { required: `${ple} ${t(title)}` },
    },
    {
      label: `${t(fuar)} ${t(date)}`,
      startPlaceholder: `${ple} ${t(start)} ${t(date)}`,
      endPlaceholder: `${ple} ${t(end)} ${t(date)}`,
      type: 'date-time',
      name: 'date',
      specialRender: true,
      startDateRules: { required: `${ple} ${t(start)} ${t(date)}` },
      endDateRules: { required: `${ple} ${t(end)} ${t(date)}` },
    },
    {
      label: `${t(fuar)} ${t(website)}`,
      placeholder: `${ple} ${t(website)} ${t(address)}`,
      type: 'text',
      name: 'webSite',
      specialRender: false,
      rules: { required: `${ple} ${t(website)}`, pattern: WEBSITE_REGEXP },
    },
    {
      label: `${t(fuar)} ${t(address)}`,
      name: 'fuarAddress',
      placeholder: `${ple} ${t(fullAddress)} `,
      type: 'address',
      specialRender: true,
    },
  ];

  function isYearFirst(date: string) {
    return date.split('-')[0].length === 4;
  }

  function reverseDate(date: string) {
    if (isYearFirst(date)) {
      return date;
    } else return date.split('-').reverse().join('-');
  }

  const onSubmit = (data = DEFAULT_VALUES) => {
    if (data.startDate && data.endDate) {
      const startDate = isNaN(new Date(reverse(data.startDate, '-')).getTime())
        ? new Date(data.startDate).getTime()
        : new Date(reverse(data.startDate, '-')).getTime();
      const endDate = isNaN(new Date(data.endDate.split('-').reverse().join('-')).getTime())
        ? new Date(data.endDate).getTime()
        : new Date(reverse(data.endDate, '-')).getTime();
      if (startDate > endDate) {
        setError('startDate', { message: 'Start Date Cannot be past than end date' });
        return;
      }
    }
    const updatedData: t_defaultValue = {
      ...data,
      // @ts-ignore
      cityId: data?.city?.id || data?.city,
      countryId: data?.country?.id || data?.city,
      startDate: reverseDate(data.startDate as string),
      endDate: reverseDate(data.endDate as string),
    };
    toggleRealLoading(true);
    createExecute({
      variables: updatedData,
    })
      .then((res) => {
        if (data.logo || data.poster) toggleRealLoading(true);
        else {
          toggleRealLoading(false);
          toast.success(`${t(exhibition)} ${t(successfully)} ${t(created)}`);
          navigate(`/${APP_ROUTE_NAME.dashboard}/${APP_ROUTE_NAME.exhibition}`);
        }
        if (data.logo && data.logo !== DEFAULT_VALUES.logo) {
          const id = res.data?.createExhibition?.id;
          if (id) {
            const formData = new FormData();
            formData.append('id', id);
            formData.append('image', data.logo);
            FetchApi({
              url: 'EXHIBITION_LOGO',
              data: formData,
              method: 'PATCH',
            })
              .then(() => {
                if (!data.poster) {
                  toggleRealLoading(false);
                  toast.success(`${t(exhibition)} ${t(successfully)} ${t(created)}`);
                  navigate(`/${APP_ROUTE_NAME.dashboard}/${APP_ROUTE_NAME.exhibition}`);
                }
              })
              .catch((e: any) => {
                console.error(e);
                toast.error(e?.message);
                toggleRealLoading(false);
              });
          }
        }
        if (data.poster && data.poster !== DEFAULT_VALUES.poster) {
          const id = res.data?.createExhibition?.id;
          if (id) {
            const formData = new FormData();
            formData.append('id', res.data.createExhibition.id);
            formData.append('image', data.poster);
            FetchApi({
              url: 'EXHIBITION_POSTER',
              data: formData,
              method: 'PATCH',
            })
              .then(() => {
                toast.success(`${t(exhibition)} ${t(successfully)} ${t(created)}`);
                navigate(`/${APP_ROUTE_NAME.dashboard}/${APP_ROUTE_NAME.exhibition}`);
              })
              .catch((e: any) => {
                console.error(e);
                toast.error(e?.message);
                toggleRealLoading(false);
              });
          }
        }
      })
      .catch((e) => {
        console.error(e);
        toast.error(errorFinder(e));
        toggleRealLoading(false);
      });
  };

  const handleChangeActive = (isActive: boolean) => {
    setValue('isActive', isActive);
  };

  const onUpdate = (data = DEFAULT_VALUES) => {
    if (data.startDate && data.endDate) {
      const startDate = isNaN(new Date(reverse(data.startDate, '-')).getTime())
        ? new Date(data.startDate).getTime()
        : new Date(reverse(data.startDate, '-')).getTime();
      const endDate = isNaN(new Date(data.endDate.split('-').reverse().join('-')).getTime())
        ? new Date(data.endDate).getTime()
        : new Date(reverse(data.endDate, '-')).getTime();
      if (startDate > endDate) {
        setError('startDate', { message: 'Start Date Cannot be past than end date' });
        return;
      }
    }
    const updatedData: t_defaultValue = {
      ...data,
      // @ts-ignore
      cityId: data?.city?.id || data?.city,
      countryId: data?.country?.id || data?.city,
      language: data.language?.map((lang) => ({ language: lang.language, description: lang.description })),
      startDate: reverseDate(data.startDate as string),
      endDate: reverseDate(data.endDate as string),
    };
    toggleRealLoading(true);
    updateExecute({
      variables: updatedData,
    })
      .then(() => {
        if (
          (!updatedData.logo || updatedData.logo === DEFAULT_VALUES.logo) &&
          (!updatedData.poster || updatedData.poster === DEFAULT_VALUES.poster)
        ) {
          toggleRealLoading(false);
          toast.success(`${t(exhibition)} ${t(successfully)} ${t(updated)}`);
          navigate(`/${APP_ROUTE_NAME.dashboard}/${APP_ROUTE_NAME.exhibition}`);
        }

        if (updatedData.logo && updatedData.logo !== DEFAULT_VALUES.logo) {
          const id = updatedData.id as number;
          const formData = new FormData();
          formData.append('id', id.toString());
          formData.append('image', updatedData.logo);
          FetchApi({
            url: 'EXHIBITION_LOGO',
            data: formData,
            method: 'PATCH',
          })
            .then(() => {
              if (!data.poster) {
                toggleRealLoading(false);
                toast.success(`${t(exhibition)} ${t(successfully)} ${t(created)}`);
                navigate(`/${APP_ROUTE_NAME.dashboard}/${APP_ROUTE_NAME.exhibition}`);
              }
            })
            .catch((e: any) => {
              console.error(e);
              toast.error(e?.message);
              toggleRealLoading(false);
            });
        }
        if (updatedData.poster && updatedData.poster !== DEFAULT_VALUES.poster) {
          const id = updatedData.id as number;
          const formData = new FormData();
          formData.append('id', id.toString());
          formData.append('image', updatedData.poster);
          FetchApi({
            url: 'EXHIBITION_POSTER',
            data: formData,
            method: 'PATCH',
          })
            .then(() => {
              toast.success(`${t(exhibition)} ${t(successfully)} ${t(updated)}`);
              navigate(`/${APP_ROUTE_NAME.dashboard}/${APP_ROUTE_NAME.exhibition}`);
            })
            .catch((e: any) => {
              console.error(e);
              toast.error(e?.message);
              toggleRealLoading(false);
            });
        }
      })
      .catch((err) => {
        toggleRealLoading(false);
        toast.error(errorFinder(err));
        console.error(err);
      });
  };
  return (
    <Card>
      <Header isNew={isNew} data={data} onStatusChange={handleChangeActive} />
      <Form className="p-3" onSubmit={isNew ? handleSubmit(onSubmit) : handleSubmit(onUpdate)}>
        <FormGroup className="bg-gray p-4 rounded">
          {inputs.map((input) => {
            return (
              <>
                {input.specialRender && input.type === 'address' && (
                  <>
                    <CityAndCountry
                      defaultCountry={DEFAULT_VALUES.country}
                      defaultCity={DEFAULT_VALUES.city}
                      control={control}
                      errors={errors}
                    />
                  </>
                )}
                {input.specialRender && input.type === 'date-time' ? (
                  <FuarDate
                    label={input.label}
                    startPlaceholder={input.endPlaceholder as string}
                    endPlaceholder={input.endPlaceholder as string}
                    control={control}
                    endDateRules={input.endDateRules}
                    startDateRules={input.startDateRules}
                    errors={errors}
                    defaultEndDate={DEFAULT_VALUES.endDate}
                    defaultStartDate={DEFAULT_VALUES.startDate}
                    setError={setError}
                    clearErrors={clearErrors}
                  />
                ) : (
                  <>
                    {!input.specialRender && (
                      <>
                        {input.name === 'webSite' ? (
                          <FormGroup>
                            <Label>{input.label}</Label>
                            <WebsiteInput
                              isRequired
                              control={control}
                              defaultValue={DEFAULT_VALUES[input.name]}
                              name={input.name}
                              placeholder={input.placeholder}
                            />
                            <FormFeedback>
                              {errors?.[input.name] ? `${t(please)} ${t(enter)} ${t(valid)} ${t(input.name)}` : ''}
                            </FormFeedback>
                          </FormGroup>
                        ) : (
                          <FormGroup>
                            <Label>{input.label}</Label>
                            <Controller
                              control={control}
                              rules={input.rules}
                              defaultValue={DEFAULT_VALUES[input.name]}
                              name={input.name as any}
                              render={({ field: { onChange } }) => (
                                <Input
                                  defaultValue={DEFAULT_VALUES[input.name]}
                                  onBlur={onChange}
                                  name={input.name}
                                  placeholder={input.placeholder}
                                />
                              )}
                            />
                            <FormFeedback>
                              {errors?.[input.name] ? `${t(please)} ${t(enter)} ${t(valid)} ${t(input.name)}` : ''}
                            </FormFeedback>
                          </FormGroup>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            );
          })}
        </FormGroup>
        <MultipleLang control={control} setValue={setValue} defaultValue={DEFAULT_VALUES.language} errors={errors} />

        <FormGroup className="p-4 rounded bg-gray d-flex">
          <FormGroup className="mr-3">
            <Label>{`${t(upload)} ${t(fuar)} ${t(banner)}`}</Label>
            <Controller
              control={control}
              defaultValue={DEFAULT_VALUES.poster}
              rules={{ required: `${ple} ${t(banner)}` }}
              name="poster"
              render={({ field: { onChange } }) => (
                <UploadCover defaultImage={DEFAULT_VALUES.poster as string} onSelect={onChange} />
              )}
            />
            <FormFeedback>{errors?.poster?.message}</FormFeedback>
          </FormGroup>
          <FormGroup className="ml-5">
            <Label>{`${t(upload)} ${t(fuar)} ${t(logo)}`}</Label>
            <Controller
              defaultValue={DEFAULT_VALUES.logo}
              name="logo"
              rules={{ required: `${ple} ${t(logo)}` }}
              control={control}
              render={({ field: { onChange } }) => (
                <UploadProfileImage
                  avatarSize="75px"
                  defaultImage={DEFAULT_VALUES.logo as string}
                  onSelect={onChange}
                />
              )}
            />
            <FormFeedback>{errors?.logo?.message}</FormFeedback>
          </FormGroup>
        </FormGroup>
        <AssignHotel defaultValue={DEFAULT_VALUES.hotel} errors={errors} control={control} />

        <SubmitButton loading={realLoading}>{t(isNew ? save : update)}</SubmitButton>
      </Form>
    </Card>
  );
};

interface PublishDateProps {
  label: string;
  startPlaceholder: string;
  endPlaceholder: string;
  startDateRules: any;
  endDateRules: any;
  control: Control<t_defaultValue>;
  errors?: t_errors<t_defaultValue>;
  defaultStartDate?: string;
  defaultEndDate?: string;
  setError: UseFormSetError<t_defaultValue>;
  clearErrors: UseFormClearErrors<t_defaultValue>;
}

const FuarDate: FC<PublishDateProps> = (p) => {
  const { t } = useTranslation();
  const [fuarDate, setFuarDate] = useState({
    startDate: p.defaultStartDate,
    endDate: p.defaultEndDate,
  });
  const ple = `${t(please)} ${t(enter)} ${t(fuar)}`;

  return (
    <FormGroup>
      <Label>{p.label}</Label>
      <div className="d-flex">
        <div className="d-flex flex-column mr-1 flex-grow-1">
          <FormGroup>
            <Label>Start</Label>
            <Controller
              control={p.control}
              name="startDate"
              defaultValue={p.defaultStartDate}
              rules={{
                required: `${ple} ${t(start)} ${t(date)}`,
              }}
              render={({ field: { onChange } }) => (
                <>
                  <Datepicker
                    placeholder={` ${t(start)} ${t(date)}`}
                    onSelect={(val) => {
                      p.clearErrors('startDate');
                      setFuarDate({
                        endDate: fuarDate.endDate,
                        startDate: val?.split('-').reverse().join('-'),
                      });
                      onChange(val);
                    }}
                    defaultDate={p.defaultStartDate}
                  />
                </>
              )}
            />
            <FormFeedback>{p.errors?.startDate?.message}</FormFeedback>
          </FormGroup>
        </div>
        <div className="d-flex flex-column flex-grow-1">
          <FormGroup>
            <Label>End</Label>
            <Controller
              control={p.control}
              defaultValue={p.defaultEndDate}
              rules={{ required: `${ple} ${t(end)} ${t(date)}` }}
              name="endDate"
              render={({ field: { onChange } }) => (
                <>
                  <Datepicker
                    placeholder={` ${t(end)} ${t(date)}`}
                    onSelect={(val) => {
                      p.clearErrors('startDate');
                      setFuarDate({
                        endDate: val?.split('-').reverse().join('-'),
                        startDate: fuarDate.startDate,
                      });
                      onChange(val);
                    }}
                    defaultDate={p.defaultEndDate}
                  />
                </>
              )}
            />
            <FormFeedback>{p.errors?.endDate?.message}</FormFeedback>
          </FormGroup>
        </div>
      </div>
    </FormGroup>
  );
};

interface MultipleLang {
  increaseElement?: VoidFunction;
  options: any[];
  handleSelect: (val: t_languages_dropdown | null) => void;
  label: string;
  placeholder: string;
  inputName?: string;
  index: number;
  control: any;
}

interface MultipleLangProps {
  setValue: UseFormSetValue<t_defaultValue>;
  defaultValue?: t_customLang[];
  errors?: t_errors<t_defaultValue>;
  control: Control<t_defaultValue>;
}

const MultipleLang: FC<MultipleLangProps> = (props) => {
  const { setValue, defaultValue = [], errors, control } = props;
  const { t } = useTranslation();
  const [value, setValues] = useState(defaultValue);
  const handleChangeValue = (l: t_languageKeys, val: string) => {
    setValues((prev) => {
      const isSame = prev?.find((lang) => lang.language === l);
      if (isSame) {
        const findUnMatch = prev.filter((lang) => lang.language !== l);
        return [...findUnMatch, { ...isSame, description: val }];
      } else {
        //const findUnMatch = prev.filter(lang => lang.language !== l)
        return [...prev, { language: l, description: val }];
      }
    });
  };
  useEffect(() => {
    if (value) {
      setValue('language', value);
    }
  }, [value]);
  return (
    <div className="d-flex justify-content-between flex-wrap">
      <FormGroup className={`p-4 mr-3 flex-grow-1 rounded bg-gray position-relative mb-2`}>
        <FormGroup>
          <Label>{t(language)}</Label>
          <Select isDisabled defaultValue={LANGUAGES_DROPDOWN[0]} options={[LANGUAGES_DROPDOWN[0]]} />
        </FormGroup>
        <FormGroup>
          <Label>{t(description)}</Label>
          <Controller
            name="descriptionInputTr"
            control={control}
            rules={{ required: `${t(please)} ${t(enter)} ${t(description)}` }}
            defaultValue={
              defaultValue
                ? defaultValue?.find((item) => item.language === LANGUAGES_DROPDOWN[0].value)?.description
                : undefined
            }
            render={({ field: { onChange } }) => {
              return (
                <Input
                  type="textarea"
                  defaultValue={
                    defaultValue
                      ? defaultValue?.find((item) => item.language === LANGUAGES_DROPDOWN[0].value)?.description
                      : undefined
                  }
                  onBlur={(e) => {
                    onChange(e);
                    handleChangeValue(LANGUAGES_DROPDOWN[0].value, e.target.value);
                  }}
                  name="descriptionInputTr"
                />
              );
            }}
          />
        </FormGroup>
        <FormFeedback>{errors?.language?.message || errors?.descriptionInputTr?.message}</FormFeedback>
      </FormGroup>

      <FormGroup className={`p-4 flex-grow-1 rounded bg-gray position-relative mb-2`}>
        <FormGroup>
          <Label>{t(language)}</Label>
          <Select isDisabled defaultValue={LANGUAGES_DROPDOWN[1]} options={[LANGUAGES_DROPDOWN[1]]} />
        </FormGroup>
        <FormGroup>
          <Label>{t(description)}</Label>

          <Controller
            name="descriptionInputEn"
            control={control}
            rules={{ required: `${t(please)} ${t(enter)} ${t(description)}` }}
            defaultValue={
              defaultValue
                ? defaultValue?.find((item) => item.language === LANGUAGES_DROPDOWN[1].value)?.description
                : undefined
            }
            render={({ field: { onChange } }) => {
              return (
                <Input
                  type="textarea"
                  defaultValue={
                    defaultValue
                      ? defaultValue?.find((item) => item.language === LANGUAGES_DROPDOWN[1].value)?.description
                      : undefined
                  }
                  onBlur={(e) => {
                    onChange(e);
                    handleChangeValue(LANGUAGES_DROPDOWN[1].value, e.target.value);
                  }}
                  name="descriptionInputEn"
                />
              );
            }}
          />
        </FormGroup>
        <FormFeedback>{errors?.language?.message || errors?.descriptionInputEn?.message}</FormFeedback>
      </FormGroup>
    </div>
  );
};

interface AssignHotelProps {
  errors: t_errors<t_defaultValue>;
  control: Control<t_defaultValue>;
  defaultValue?: hotel[];
}

const AssignHotel: FC<AssignHotelProps> = (props) => {
  const { control, errors, defaultValue } = props;
  const { t } = useTranslation();
  const { data, loading, refetch } = useQuery(GET_HOTEL);
  const handleRefetch = async () => {
    if (!data.hotelList) {
      await refetch();
    }
  };
  const options = useMemo(
    () => data?.hotelList.map((item: any) => ({ ...item, label: item.name, value: item.id })),
    [data]
  );
  const defaultValuesId = defaultValue?.map((hotel) => hotel.id);
  const defaultValueToOption = defaultValue?.map((hotel) => ({ ...hotel, label: hotel.name, value: hotel.id }));
  return (
    <FormGroup className="bg-gray p-3 rounded">
      <Label>{`${t(assign)} ${t(hotel)}`}</Label>
      <Controller
        control={control}
        defaultValue={defaultValuesId}
        name="hotelIds"
        rules={{ required: `${t(please)} ${t(select)} ${t(hotel)}` }}
        render={({ field: { onChange } }) => {
          return (
            <Select
              defaultValue={defaultValueToOption}
              options={options}
              isLoading={loading}
              onFocus={handleRefetch}
              onChange={(val: any) => {
                onChange(val.map((i: any) => i.value));
              }}
              isMulti
              placeholder={`${t(please)} ${t(enter)} ${t(hotel)} ${t(name)}`}
            />
          );
        }}
      />
      <FormFeedback>{errors?.hotelIds?.message}</FormFeedback>
    </FormGroup>
  );
};

interface CityAndCountryProps {
  control: Control<t_defaultValue>;
  errors?: t_errors<t_defaultValue>;
  defaultCountry?: t_countryList;
  defaultCity?: t_city;
}

const CityAndCountry: FC<CityAndCountryProps> = (props) => {
  const { control, errors, defaultCity, defaultCountry } = props;
  const { t } = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState<any>(undefined);

  return (
    <FormGroup>
      <div className="d-flex">
        <div className="d-flex flex-column flex-grow-1">
          <Controller
            control={control}
            defaultValue={defaultCountry}
            rules={{ required: `${t(please)} ${t(enter)} ${t(country)}` }}
            name="country"
            render={({ field: { onChange } }) => {
              return (
                <CountryDropdown
                  defaultValue={
                    defaultCountry
                      ? {
                          label: defaultCountry.name,
                          value: defaultCountry?.id?.toString(),
                        }
                      : undefined
                  }
                  onSelect={(val: any) => {
                    onChange(val);
                    setSelectedCountry(val);
                  }}
                  className="flex-grow-1 mr-1"
                  placeholder={t(country)}
                />
              );
            }}
          />
          <FormFeedback>{errors?.country?.message}</FormFeedback>
        </div>
        <div className="d-flex flex-column flex-grow-1">
          <Controller
            control={control}
            name="city"
            defaultValue={defaultCity}
            rules={{ required: `${t(please)} ${t(enter)} ${t(city)}` }}
            render={({ field: { onChange } }) => {
              return (
                <CityDropdown
                  defaultValue={
                    defaultCity
                      ? {
                          label: defaultCity?.name,
                          value: defaultCity?.id?.toString(),
                        }
                      : undefined
                  }
                  isDisabled={defaultCity ? false : !selectedCountry}
                  selectedCountry={selectedCountry}
                  onSelect={(val: any) => onChange(val)}
                  className="flex-grow-1"
                  placeholder={!selectedCountry ? `${t(please)} ${t(select)} ${t(country)} ${t(first)}` : t(city)}
                />
              );
            }}
          />
          <FormFeedback>{errors?.city?.message}</FormFeedback>
        </div>
      </div>
    </FormGroup>
  );
};

import SubmitButton from 'components/buttons/submitButton';
import ShowError from 'components/showError';
import UploadProfileImage from 'components/uploadProfileImage';
import { useToggle } from 'hooks';
import { newComponentsProps } from 'pages/user-management/employer/index';
import React, { FC, memo, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Col, Form, FormFeedback, FormGroup } from 'reactstrap';
import FetchApi from 'services/api/axios';
import { icon, please, save, select, unableToUploadIcon, update, updated } from 'tools/i18n/constants/i18n';
import { toNull } from 'tools/methods';
import { stringish } from 'tools/types';
import { formsNameEnum } from './types';

type CompanyLogoProps = newComponentsProps;
type t_defaultValues = {
  customError?: string;
  logoUrl: stringish | File;
};

const CompanyLogo: FC<CompanyLogoProps> = (props) => {
  const { isActive, componentsValues, componentsResponse, handleChangeComponentState, state } = props;
  const { t } = useTranslation();
  const [loading, toggleLoading] = useToggle(false);
  const DEFAULT_VALUES = {
    logoUrl: toNull(componentsValues?.logoUrl),
  };
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<t_defaultValues>({
    defaultValues: DEFAULT_VALUES,
  });
  const onSubmit = (data: t_defaultValues) => {
    setValue('customError', '');
    if (isActive) {
      toggleLoading(true);
      const formData = new FormData();
      formData.append('userId', (componentsResponse?.userId as number)?.toString());
      formData.append('companyId', (componentsResponse?.companyId as number).toString());
      formData.append('file', (data.logoUrl as File) || undefined);
      FetchApi({
        url: 'COMPANY_LOGO',
        method: 'PATCH',
        data: formData,
      })
        .then((res: any) => {
          if (res.data) {
            if (state.isCompleted) {
              toast.success(`${t(updated)}`);
            }
            handleChangeComponentState(formsNameEnum.CompanyLogo, true);
            toggleLoading(false);
          } else {
            setValue('customError', t(unableToUploadIcon));
            toggleLoading(false);
          }
        })
        .catch((err: any) => {
          toggleLoading(false);
          setValue('customError', err.message);
        });
    }
  };
  useEffect(() => {
    if (componentsValues?.logoUrl) {
      handleChangeComponentState(formsNameEnum.CompanyLogo, true);
    }
  }, [componentsValues]);

  console.log(DEFAULT_VALUES);

  return (
    <Col xxl={7} className="p-2 pb-4 mx-auto d-flex flex-column">
      <Form onSubmit={handleSubmit(onSubmit)} aria-disabled={!isActive}>
        <FormGroup>{!!getValues('customError') && <ShowError>{getValues('customError')}</ShowError>}</FormGroup>

        <div className="d-flex flex-column align-items-center mx-auto">
          <FormGroup aria-disabled={!isActive}>
            <Controller
              control={control}
              name="logoUrl"
              rules={{ required: `${t(please)} ${t(select)} ${t(icon)}` }}
              render={({ field: { onChange } }) => {
                return (
                  <UploadProfileImage
                    onError={(e) => setValue('customError', e)}
                    onSelect={onChange}
                    defaultImage={(DEFAULT_VALUES.logoUrl as string) || undefined}
                    avatarSize="70px"
                    maxSize={500000}
                  />
                );
              }}
            />
            <FormFeedback>{errors?.logoUrl?.message}</FormFeedback>
          </FormGroup>
        </div>
        {isActive && (
          <SubmitButton className="w-100 mt-4" loading={loading}>
            {t(state.isCompleted ? update : save)}
          </SubmitButton>
        )}
      </Form>
    </Col>
  );
};
export default memo(CompanyLogo);

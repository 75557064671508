import React, { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  blue,
  company,
  detail,
  email,
  emptyList,
  isLeader,
  isStore,
  list,
  not,
  requested,
  tick,
} from 'tools/i18n/constants/i18n';
import PagesLayout from 'pages/components/layout';
import YourListIsEmpty from 'pages/components/emptyList';
import { Loading } from 'components';
import useCompany, { companyFilterQuery } from './useCompanies';
import DataTables, { TableSectionTitle } from 'components/tables/dataTable';
import { ApolloError } from '@apollo/client';
import { useGetStore, useToggle } from 'hooks';
import { toArray } from 'tools/methods';
import {
  APP_HELPER,
  BLUE_TICKET_STATUS,
  BLUE_TICKET_STATUS_UI,
  COMPANY_REHBER_STATUS,
  COMPANY_REHBER_STATUS_UI,
} from 'tools/enums';
import { t_company } from '../../../../tools/types/sectionTypes/company';
import { Badge, Button, Card, Input, Modal, ModalBody } from 'reactstrap';
import DeleteModal from './components/deleteModal';
import CompaniesFilter from 'pages/content-management/company/companies/components/companiesFilter';
import { useSearchParams } from 'react-router-dom';
import BanHistoryModal from 'pages/content-management/company/companies/components/banHistory';
import BanModal from 'pages/content-management/company/companies/components/banModal';
import ChangeInfoModal from 'pages/content-management/company/companies/components/changeInfoModal';
import UnbanModal from 'pages/content-management/company/companies/components/unbanModal';
import { ProfileImage } from 'pages/user-management/users/style';
import Avatar from 'react-avatar';
import companyDefaut from 'assets/images/buluro/dashboard/Company default.svg';
import Icon, { IconsName } from 'components/icons';
import Link from 'components/link';
import Dropdown from 'react-multilevel-dropdown';
import { Drawer } from 'antd';
import CompanyNotes from './components/companyNotes';
export default function Companies() {
  const { isEmptyList, t, fetchMore, loading, error, data, refetch, count } = useCompany();
  return (
    <PagesLayout>
      <Loading loading={loading} failed={!!error} onRetry={refetch}>
        {isEmptyList && !loading ? (
          <div className="d-flex flex-column w-100">
            <div className="w-100">
              <CompaniesTable
                refetch={refetch}
                count={count}
                loading={loading}
                fetchMore={fetchMore}
                error={error}
                data={data as unknown[]}
              />
            </div>
          </div>
        ) : (
          <div className="py-3">
            <YourListIsEmpty title={`${t(emptyList)}`} />
          </div>
        )}
      </Loading>
    </PagesLayout>
  );
}

interface CategoryTableProps {
  data: unknown[];
  fetchMore: any;
  refetch: any;
  loading: boolean;
  error?: ApolloError;
  count?: number;
}

interface ChildRef {
  refetch: () => void;
}

const CompaniesTable: FC<CategoryTableProps> = (props) => {
  const { data, error, loading, fetchMore, count, refetch } = props;
  const ref = useRef<ChildRef | null>(null);
  const [toggleLoading] = useToggle(loading);
  const [param, setParam] = useSearchParams();
  const [dataCount, setCount] = useState(count || 0);
  const [realData, setRealData] = useState<unknown[]>(data || []);
  const currentSearchKey = useRef<string | undefined>(undefined);
  const [noteCompanyId, setNoteCompanyId] = useState<number | null>(null);
  const currentFilter = useRef<companyFilterQuery | undefined>({
    query: undefined,
    sort: {
      sortBy: param.get('sortBy') || ('createdAt' as any),
      sortType: param.get('sortType') || ('DESC' as any),
    },
    blueTickStatus: (param.get('blueTickStatus') as any) || undefined,
    guideStatus: param.get('guideStatus') as any,
    isShop: param.get('isShop') as any,
  });
  const { User } = useGetStore();
  const currentCountPerRow = useRef<number | undefined>(APP_HELPER.tableDefaultSize);
  const { t } = useTranslation();
  const [deleteModal, setDeleteModal] = useState<{
    isOpen: boolean;
    user: t_company | undefined;
  }>({
    isOpen: false,
    user: undefined,
  });
  const [banModal, setBanModal] = useState<{
    isOpen: boolean;
    user: t_company | undefined;
  }>({
    isOpen: false,
    user: undefined,
  });
  const [unbanModal, setUnbanModal] = useState<{
    isOpen: boolean;
    user: t_company | undefined;
  }>({
    isOpen: false,
    user: undefined,
  });
  const [banHistoryModal, setBanHistoryModal] = useState<{
    isOpen: boolean;
    user: t_company | undefined;
  }>({
    isOpen: false,
    user: undefined,
  });
  const [changeInfoModal, setChangeInfoModal] = useState<{
    isOpen: boolean;
    user: t_company | undefined;
  }>({
    isOpen: false,
    user: undefined,
  });
  const handleOpenBanModal = (selectedUser: t_company) => {
    setBanModal({
      isOpen: true,
      user: selectedUser,
    });
  };
  const handleCloseModal = () => {
    setBanModal({
      isOpen: false,
      user: undefined,
    });
  };
  const onSearch = (key: string) => {
    currentSearchKey.current = key;
    const vars: any = {};
    if (key) vars['query'] = key;
    vars['size'] = currentCountPerRow.current;
    vars['sort'] = currentFilter.current?.sort;
    vars['blueTickStatus'] = currentFilter.current?.blueTickStatus;
    fetchMore({
      variables: vars,
    }).then((res: any) => {
      setRealData(toArray(res?.data.getCompanyListAndDetails?.listResponse?.data));
      setCount(res?.data.getCompanyListAndDetails?.listResponse?.count);
    });
  };
  const onPageChange = (page: number) => {
    const vars: any = {};

    if (currentSearchKey.current) {
      vars['query'] = currentSearchKey.current;
    }
    vars['size'] = currentCountPerRow.current;
    vars['page'] = page;
    vars['sort'] = currentFilter.current?.sort;
    vars['blueTickStatus'] = currentFilter.current?.blueTickStatus;
    vars['isShop'] = currentFilter.current?.isShop;
    vars['guideStatus'] = currentFilter.current?.guideStatus;
    setParam({
      page: page.toString(),
      ...(currentCountPerRow.current ? { size: currentCountPerRow.current?.toString() } : undefined),
      ...(vars.sort?.sortBy ? { sortBy: vars.sort.sortBy } : undefined),
      ...(vars.sort?.sortType ? { sortType: vars.sort.sortType } : undefined),
      ...(vars.blueTickStatus ? { blueTickStatus: vars.blueTickStatus } : undefined),
      ...(vars.isShop ? { isShop: vars.isShop } : vars.isShop === false ? { isShop: false } : undefined),
      ...(vars.guideStatus ? { guideStatus: vars.guideStatus } : undefined),
    });
    fetchMore({
      variables: vars,
    }).then((res: any) => {
      setRealData(toArray(res?.data.getCompanyListAndDetails?.listResponse?.data));
      setCount(res?.data.getCompanyListAndDetails?.listResponse?.count);
    });
  };

  const onChangeRowsPerPage = (currentPerRow: number, currentPage: number) => {
    currentCountPerRow.current = currentPerRow;
    const vars: any = {};
    if (currentSearchKey.current) vars['query'] = currentSearchKey.current;
    vars['size'] = currentPerRow;
    vars['page'] = currentPage;
    vars['sort'] = currentFilter.current?.sort;
    vars['blueTickStatus'] = currentFilter.current?.blueTickStatus;
    vars['isShop'] = currentFilter.current?.isShop;
    vars['guideStatus'] = currentFilter.current?.guideStatus;
    setParam({
      page: currentPage.toString(),
      size: currentPerRow.toString(),
      ...(vars.sort?.sortBy ? { sortBy: vars.sort.sortBy } : undefined),
      ...(vars.sort?.sortType ? { sortType: vars.sort.sortType } : undefined),
      ...(vars.blueTickStatus ? { blueTickStatus: vars.blueTickStatus } : undefined),
      ...(vars.isShop ? { isShop: vars.isShop } : vars.isShop === false ? { isShop: false } : undefined),
      ...(vars.guideStatus ? { guideStatus: vars.guideStatus } : undefined),
    });
    fetchMore({
      variables: vars,
    }).then((res: any) => {
      setRealData(toArray(res?.data.getCompanyListAndDetails?.listResponse?.data));
      setCount(res?.data.getCompanyListAndDetails?.listResponse?.count);
    });
  };
  const handleChangeCurrentFilter = (filter: companyFilterQuery) => {
    currentFilter.current = filter;
  };
  const handleOpenBanHistory = (selectedUser: t_company) => {
    setBanHistoryModal({
      isOpen: true,
      user: selectedUser,
    });
  };
  const handleCloseBanHistoryModal = () => {
    setBanHistoryModal({
      isOpen: false,
      user: undefined,
    });
  };
  const handleOpenDeleteModal = (selectedUser: t_company) => {
    setDeleteModal({
      isOpen: true,
      user: selectedUser,
    });
  };
  const handleOpenUnbanModal = (selectedUser: t_company) => {
    setUnbanModal({
      isOpen: true,
      user: selectedUser,
    });
  };
  const handleCloseUnbanModal = () => {
    setUnbanModal({
      isOpen: false,
      user: undefined,
    });
  };
  const handleCloseDeleteModal = () => {
    setDeleteModal({
      isOpen: false,
      user: undefined,
    });
  };
  const handleOpenChangeInfoModal = (selectedUser: t_company) => {
    setChangeInfoModal({
      isOpen: true,
      user: selectedUser,
    });
  };
  const handleCloseChangeInfoModal = () => {
    setChangeInfoModal({
      isOpen: false,
      user: undefined,
    });
  };

  const COLUMNS = [
    {
      name: <TableSectionTitle name={'FiAperture'} title={`${t(company)}`} />,
      center: false,
      maxWidth: '400px',
      minWidth: '250px',
      cell: (r: any) => {
        return (
          <div
            style={{ maxWidth: '100%' }}
            className="table-cell-container d-flex align-items-center py-3 overflow-hidden"
          >
            <ProfileImage
              className="d-inline-block mr-2"
              data-percent={r.profilePercentage ? `${r.profilePercentage}%` : '0%'}
            >
              <Avatar src={r?.logoUrl || companyDefaut} size="64px" />
            </ProfileImage>
            <Link
              style={{ maxWidth: '100%' }}
              className="text-black text-ellipsis d-inline"
              to={'companiesDetails'}
              param={r?.id?.toString()}
            >
              {r.companyName}
            </Link>
          </div>
        );
      },
    },
    {
      name: <TableSectionTitle name={'AiOutlineMail'} title={`${t(email)}`} />,
      center: false,
      cell: (r: any) => {
        return <span className="ml-2 whitespace-nowrap">{r.registrationEmail}</span>;
      },
    },
    {
      name: <TableSectionTitle name="FiType" title={`${t(blue)} ${t(tick)}`} />,
      selector: (r: any) => r.blueMark,
      width: '180px',
      cell: (r: any) => {
        const s = r?.blueMark?.status;
        const Item =
          s === BLUE_TICKET_STATUS.ACCEPTED
            ? {
                icon: 'FiCheck',
                className: 'shadow-bg-active',
                textColor: 'shadow-text-active',
                text: BLUE_TICKET_STATUS_UI.ACCEPTED,
              }
            : s === BLUE_TICKET_STATUS.REJECTED
            ? {
                icon: 'FiXOctagon',
                className: 'shadow-bg-danger',
                textColor: 'shadow-text-danger',
                text: BLUE_TICKET_STATUS_UI.REJECTED,
              }
            : s === BLUE_TICKET_STATUS.CANCELED
            ? {
                icon: 'FiX',
                className: 'shadow-bg-danger',
                textColor: 'shadow-text-danger',
                text: BLUE_TICKET_STATUS_UI.CANCELED,
              }
            : s === BLUE_TICKET_STATUS.NOT_REQUESTED
            ? {
                icon: 'FiMinus',
                className: 'shadow-bg-notActive',
                textColor: 'shadow-text-notActive',
                text: `${t(not)} ${t(requested)}`,
              }
            : {
                icon: 'FiLoader',
                className: 'shadow-bg-warning',
                textColor: 'shadow-text-warning',
                text: BLUE_TICKET_STATUS_UI.IN_PROGRESS,
              };
        return (
          <div className="d-flex align-items-center">
            <div
              style={{ width: '30px', height: '30px' }}
              className={`${Item.className} d-flex align-items-center justify-content-center p-1 rounded-circle mr-2`}
            >
              <Icon Name={Item.icon as IconsName} size="20px" />
            </div>
            <span className={`${Item.textColor} whitespace-nowrap`}>{Item.text}</span>
          </div>
        );
      },
    },
    {
      name: <TableSectionTitle name="FiAward" title={t(isLeader) as string} />,
      selector: (r: any) => r.guideStatus,
      // center: true,
      width: '120px',
      cell: (r: any) => {
        const s = r?.guideStatus;
        const Item =
          s === COMPANY_REHBER_STATUS.ACCEPTED
            ? {
                icon: 'FiCheck',
                className: 'shadow-bg-active',
                textColor: 'shadow-text-active',
                text: COMPANY_REHBER_STATUS_UI.ACCEPTED,
              }
            : s === COMPANY_REHBER_STATUS.REJECTED
            ? {
                icon: 'FiXOctagon',
                className: 'shadow-bg-danger',
                textColor: 'shadow-text-danger',
                text: COMPANY_REHBER_STATUS_UI.REJECTED,
              }
            : s === COMPANY_REHBER_STATUS.PENDING
            ? {
                icon: 'FiLoader',
                className: 'shadow-bg-warning',
                textColor: 'shadow-text-warning',
                text: COMPANY_REHBER_STATUS_UI.PENDING,
              }
            : {
                icon: 'FiMinus',
                className: 'shadow-bg-notActive',
                textColor: 'shadow-text-notActive',
                text: COMPANY_REHBER_STATUS_UI.NONE,
              };

        return (
          <div className="d-flex align-items-center">
            <div
              style={{ width: '30px', height: '30px' }}
              className={`${Item.className} d-flex align-items-center justify-content-center p-1 rounded-circle mr-2`}
            >
              <Icon Name={Item.icon as IconsName} size="20px" />
            </div>
            <span className={`${Item.textColor} whitespace-nowrap`}>{Item.text}</span>
          </div>
        );
      },
    },
    {
      name: <TableSectionTitle name="FiShoppingBag" title={t(isStore) as string} />,
      selector: (r: any) => r.isStore,
      center: true,
      width: '120px',
      cell: (r: any) => {
        return (
          <Input
            style={{ width: '18px', height: '18px' }}
            type="checkbox"
            readOnly
            aria-readonly={true}
            checked={!!r?.verifiedForShop}
          />
        );
      },
    },
    {
      name: <TableSectionTitle name="AiOutlineBlock" title="Status" />,
      width: '120px',
      cell: (r: { isBanned: boolean }) => {
        return (
          <Badge color={r.isBanned ? 'danger' : 'success'}>
            <span style={{ fontSize: '14px' }}>{r.isBanned ? 'Blocked' : 'Active'}</span>
          </Badge>
        );
      },
    },
    {
      name: '',
      center: false,
      cell: (r: t_company) => {
        return (
          <div className="d-flex align-items-center justify-content-end ml-auto">
            <Link
              className="text-white ml-auto whitespace-nowrap mr-2"
              to={'companiesDetails'}
              param={r?.id?.toString()}
            >
              <Button color="primary">{t(detail) as string}</Button>
            </Link>
            <Dropdown
              position="left"
              title={<Icon Name="AiOutlineMore" size="24px" />}
              buttonClassName="bg-transparent pointer border-0 shadow-0"
            >
              <Dropdown.Item onClick={() => handleOpenDeleteModal(r)}>
                <Icon Name="FaTrashAlt" />
                <span className="ml-2">Delete User</span>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  if (r.isBanned) {
                    handleOpenUnbanModal(r);
                  } else {
                    handleOpenBanModal(r);
                  }
                }}
              >
                <Icon Name={r.isBanned ? 'FiUser' : 'AiOutlineStop'} />
                <span className="ml-2">{r.isBanned ? 'Unblock' : 'Block'}</span>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleOpenBanHistory(r)}>
                <Icon Name="FaHistory" />
                <span className="ml-2">Block History</span>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleOpenChangeInfoModal(r)}>
                <Icon Name="FaEdit" />
                <span className="ml-2">Change Email</span>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setNoteCompanyId(r.id)}>
                <Icon Name="FaPen" />
                <span className="ml-2">Admins Notes</span>
              </Dropdown.Item>
            </Dropdown>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Drawer
        open={!!noteCompanyId}
        style={{ width: 'auto', overflowY: 'auto', zIndex: 10000, position: 'relative' }}
        className=" bg-white"
        onClose={() => setNoteCompanyId(null)}
        title={
          <div className="d-flex justify-content-between align-items-center">
            {t('Admins Notes')}
            <Button style={{ width: 30, height: 30, padding: 5 }} onClick={() => ref.current?.refetch()}>
              <Icon Name="FiRefreshCw" color="white" />
            </Button>
          </div>
        }
        classNames={{
          wrapper: 'p-0',
          body: 'pt-2',
        }}
        // direction="right"
      >
        {noteCompanyId && <CompanyNotes companyId={noteCompanyId} ref={ref} />}
      </Drawer>

      <Modal
        unmountOnClose
        style={{ width: '50vw', maxWidth: '50vw' }}
        isOpen={deleteModal.isOpen}
        toggle={handleCloseDeleteModal}
        onClosed={handleCloseDeleteModal}
        title={`Delete ${User?.userInfo?.companyName || User?.userInfo?.fullName}`}
      >
        <DeleteModal
          refetch={refetch}
          adminId={User?.userInfo?.userId as number}
          setData={setRealData}
          user={deleteModal.user as t_company}
          onClose={handleCloseDeleteModal}
          open={deleteModal.isOpen}
          setCount={setCount}
        />
      </Modal>
      <Modal
        unmountOnClose
        style={{ width: '50vw', maxWidth: '50vw' }}
        isOpen={banHistoryModal.isOpen}
        toggle={handleCloseBanHistoryModal}
        onClosed={handleCloseBanHistoryModal}
        title={`Block ${User?.userInfo?.companyName || User?.userInfo?.fullName} History`}
      >
        <ModalBody>
          <Card>
            <BanHistoryModal
              onClose={handleCloseBanHistoryModal}
              user={banHistoryModal.user as t_company}
              open={banHistoryModal.isOpen}
            />
          </Card>
        </ModalBody>
      </Modal>
      <Modal
        unmountOnClose
        style={{ width: '50vw', maxWidth: '50vw' }}
        isOpen={banModal.isOpen}
        toggle={handleCloseModal}
        onClosed={handleCloseModal}
        title={`Block ${User?.userInfo?.companyName || User?.userInfo?.fullName}`}
      >
        <BanModal
          refetch={refetch}
          setCount={setCount}
          setData={setRealData}
          adminId={User?.userInfo?.userId as number}
          user={banModal.user as t_company}
          onClose={handleCloseModal}
          open={banModal.isOpen}
        />
      </Modal>
      <Modal
        unmountOnClose
        style={{ width: '50vw', maxWidth: '50vw' }}
        isOpen={changeInfoModal.isOpen}
        toggle={handleCloseChangeInfoModal}
        onClosed={handleCloseChangeInfoModal}
        title={`Change info for ${User?.userInfo?.companyName || User?.userInfo?.fullName}`}
      >
        <ChangeInfoModal
          adminId={User?.userInfo?.userId as number}
          user={changeInfoModal.user as t_company}
          onClose={handleCloseChangeInfoModal}
          open={changeInfoModal.isOpen}
          refetch={refetch}
          setData={setRealData}
          setCount={setCount}
        />
      </Modal>

      <UnbanModal
        refetch={fetchMore}
        adminId={User?.userInfo?.userId as number}
        setCount={setCount}
        setData={setRealData}
        isOpen={unbanModal.isOpen}
        onClose={handleCloseUnbanModal}
        user={unbanModal.user as t_company}
      />
      <DataTables
        data={realData}
        filterable
        searchable
        customFilter={
          <CompaniesFilter
            handleChangeCurrentFilter={handleChangeCurrentFilter}
            setCount={setCount}
            fetchMore={fetchMore}
            setData={setRealData}
          />
        }
        headerTitle={`${t(company)} ${t(list)}`}
        columns={COLUMNS}
        onSearch={onSearch}
        disabled={toggleLoading || !!error}
        onChangePage={onPageChange}
        onChangeRowsPerPage={onChangeRowsPerPage}
        paginationTotalRows={dataCount}
      />
    </>
  );
};

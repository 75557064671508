import React, { FC } from 'react';
import { Button } from 'reactstrap';
import document from 'assets/images/document.svg';
import Link from 'components/link';
import Icon from 'components/icons';

interface YourListIsEmptyProps {
  title: string;
  addButtonTitle?: string;
  addButtonParams?: string;
  addNewButton?: boolean;
}

export const YourListIsEmpty: FC<YourListIsEmptyProps> = (props) => {
  const { title, addButtonTitle, addButtonParams, addNewButton } = props;
  return (
    <div className="mx-auto my-auto d-flex flex-column align-items-center justify-content-between">
      <img src={document} alt="empty list" />
      <div className="d-flex flex-column align-items-center mt-3">
        <p className="mb-3 h6">{title}</p>
        {addNewButton && (
          <Link to="new">
            <Button color="primary" className="d-flex">
              <Icon Name="FiPlusSquare" />
              <span className="ml-2">{addButtonTitle}</span>
            </Button>
          </Link>
        )}
        {!!addButtonTitle && !!addButtonParams && (
          <AddBazarButton title={addButtonTitle} addButtonParams={addButtonParams} />
        )}
      </div>
    </div>
  );
};

export default YourListIsEmpty;

const AddBazarButton: FC<{ title: string; addButtonParams: string }> = ({ title, addButtonParams }) => {
  return (
    <Link to="new" param={addButtonParams}>
      <Button color="primary" className="d-flex">
        <Icon Name="FiPlusSquare" />
        <span className="ml-2">{title}</span>
      </Button>
    </Link>
  );
};

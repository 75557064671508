import React, { ElementType, FC } from 'react';
import * as Ai from 'react-icons/ai';
import * as Fa from 'react-icons/fa';
import * as Fc from 'react-icons/fc';
import * as Fi from 'react-icons/fi';

export type IconsName = keyof typeof Fi | keyof typeof Fa | keyof typeof Fc | keyof typeof Ai;

interface IconProps {
  Name: IconsName;
  size?: string;
  color?: string;
  style?: React.CSSProperties;
}

const Icon: FC<IconProps> = ({ Name, size = '18px', style, color }) => {
  const Element = (Fa?.[Name as keyof typeof Fa] ||
    Fi?.[Name as keyof typeof Fi] ||
    Fc?.[Name as keyof typeof Fc] ||
    Ai?.[Name as keyof typeof Ai]) as ElementType;
  if (Element) {
    return <Element size={size} color={color} style={style} />;
  } else return <></>;
};

export default Icon;

import axios, { AxiosRequestConfig, Method } from 'axios';
import { API_ROUTE, updateRefreshToken } from '..';
import { getCookie } from 'tools/methods';

type t_fetch_param = AxiosRequestConfig & {
  method: Method;
  url: keyof typeof API_ROUTE;
  data?: any;
};

export const Axios = axios.create({});

export default function FetchApi(arg: t_fetch_param) {
  Axios.interceptors.request.use((config) => {
    config.headers = {
      authorization: `Bearer ${getCookie('token')}`,
    };
    return config;
  });
  Axios.interceptors.response.use(
    (config) => {
      return config;
    },
    async (err) => {
      try {
        const { status } = err;
        switch (status) {
          case 401: {
            const response = await updateRefreshToken();
            console.log('AXIOS REFRESH TOKEN : ', response);
            return FetchApi(arg);
          }
          default:
            await Promise.reject(err);
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
      throw err;
    }
  );

  return Axios({
    ...arg,
    url: `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_REST}` + API_ROUTE[arg.url],
    method: arg.method,
    data: arg.data,
  });
}

import React, { useState } from 'react';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import { childrenType, sliderLocationEnum } from '../types';
import { Controller } from 'react-hook-form';
import Dropzone from 'react-dropzone';
import { ImageContainer } from '../styles';
import { ErrorMessage } from '@hookform/error-message';
import FetchApi from 'services/api/axios';
import { toast } from 'react-toastify';
import { Loading } from 'components';

interface IProps {
  control: any;
  setError: any;
  watch: any;
  errors: any;
  childrenData: childrenType[] | [];
  slide: childrenType;
  index: number;
  language: 'en' | 'tr';
  uploadLoading: boolean;
  setChildren: (children: childrenType[]) => void;
  setUploadLoading: (loading: boolean) => void;
}

export default function UploadSlideImage({
  control,
  setError,
  watch,
  errors,
  childrenData,
  slide,
  index,
  language,
  uploadLoading,
  setChildren,
  setUploadLoading,
}: IProps) {
  const [loading, setLoading] = useState(false);

  const onUploadImage = async (file: File, callBack: (imageName: string) => void) => {
    const englishFormData = new FormData();
    // englishFormData.append('language', language);
    englishFormData.append('file', file);
    let res;
    try {
      setLoading(true);
      setUploadLoading(true);
      res = await FetchApi({
        url: 'SLIDER_IMAGE',
        method: 'POST',
        data: englishFormData,
        onUploadProgress: (progressEvent) => {
          console.log(progressEvent);
        },
      });
      if (!res?.data) {
        throw new Error('2');
      }
      callBack(res?.data?.data?.imageName);
    } catch {
      toast.error('Upload Failed');
    } finally {
      setLoading(false);
      setUploadLoading(false);
    }
  };

  return (
    <FormGroup className="flex-grow mr-1 relative" style={{ maxWidth: '50%' }}>
      <Label>
        {`${language === 'en' ? 'English' : 'Turkish'} Image (${
          watch('location.location') === sliderLocationEnum.HOME_PAGE_TOP
            ? 'Select an image with a 3:2 aspect ratio'
            : 'Select an image with a 16:9 aspect ratio'
        })`}
      </Label>
      <Controller
        defaultValue={slide.translations[language === 'en' ? 0 : 1].imageUrl}
        name={`children[${index}].translations[${language === 'en' ? 0 : 1}].image`}
        rules={{
          required: 'Please Select a Image',
        }}
        control={control}
        render={({ field: { onChange } }) => {
          return (
            <Dropzone
              accept={{
                'image/png': ['.png', '.jpg', '.jpeg'],
              }}
              disabled={loading || uploadLoading}
              onDrop={(event) => {
                if (event.length) {
                  setError(`children[${index}].translations.${language === 'en' ? 0 : 1}.image`, {
                    message: undefined,
                  });
                  onUploadImage(event[0], (imageName) => {
                    setChildren(
                      childrenData.map((item) => {
                        return item.sortIndex === slide.sortIndex
                          ? {
                              ...item,
                              translations: item.translations.map((item) =>
                                item.language === language
                                  ? {
                                      language: language,
                                      image: imageName,
                                      imageUrl: URL.createObjectURL(event[0]),
                                    }
                                  : item
                              ),
                            }
                          : item;
                      }) as childrenType[]
                    );
                    onChange(imageName);
                  });
                  // setFiles((p: any) => ({
                  //   ...p,
                  //   englishFile: URL.createObjectURL(event[0]),
                  // }));
                } else {
                  setError(`children[${index}].translations.${language === 'en' ? 0 : 1}.image`, {
                    message: 'Please Select Valid File',
                  });
                }
              }}
            >
              {({ getRootProps, getInputProps }) => {
                return (
                  <ImageContainer {...getRootProps()} style={{ overflow: 'hidden' }}>
                    <input type="file" {...getInputProps()} />
                    {!slide.translations[language === 'en' ? 0 : 1].imageUrl && (
                      <p>Drag & drop some files here, or click to select files</p>
                    )}
                    {!!slide.translations[language === 'en' ? 0 : 1].imageUrl && (
                      <img
                        alt="english"
                        className="max-w-full "
                        style={{ maxWidth: '100%' }}
                        src={slide.translations[language === 'en' ? 0 : 1].imageUrl}
                      />
                    )}
                    {loading ? (
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          height: '100%',
                          borderRadius: 20,
                          background: 'rgba(255, 255, 255, 0.5)',
                          zIndex: 2,
                        }}
                      >
                        <Loading loading={loading} color="#000" />
                      </div>
                    ) : (
                      <></>
                    )}
                  </ImageContainer>
                );
              }}
            </Dropzone>
          );
        }}
      />
      <ErrorMessage
        errors={errors}
        name={`children[${index}].translations[${language === 'en' ? 0 : 1}].image`}
        render={({ message }) => <FormFeedback>{message}</FormFeedback>}
      />
    </FormGroup>
  );
}

import useQuery from 'hooks/useQuery';
import { GET_COMPANY_LOGOS } from 'services/graphql/queries/companyLogos';
import { APP_HELPER } from 'tools/enums';

export default function useLogos() {
  const { data, loading, error, fetchMore } = useQuery(GET_COMPANY_LOGOS, {
    defaultOptions: {
      variables: {
        page: APP_HELPER.tableDefaultPage,
        size: APP_HELPER.tableDefaultSize,
      },
    },
  });
  console.log(data?.EmploymentEmployersLogoList.count);
  return {
    data: data?.EmploymentEmployersLogoList.data,
    count: data?.EmploymentEmployersLogoList.count,
    loading,
    error,
    fetchMore,
  };
}

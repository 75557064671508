import React from 'react';
import { FormCheck } from 'react-bootstrap';
import { ads, day, last, only } from 'tools/i18n/constants/i18n';
import { useTranslation } from 'react-i18next';
import { FormProps } from 'pages/ads/jobAds/index';
import { Controller } from 'react-hook-form';
type OnlyLastDayAdsProps = FormProps;

export default function OnlyLastDayAds(props: OnlyLastDayAdsProps) {
  const { t } = useTranslation();
  const { control } = props;
  return (
    <Controller
      control={control}
      name="onlyLastDay"
      render={({ field: { onChange, name } }) => {
        return <FormCheck onChange={onChange} name={name} label={`${t(only)} ${t(last)} ${t(day)} ${t(ads)}`} />;
      }}
    />
  );
}

// import { useMutation } from 'hooks';
import { ErrorMessage } from '@hookform/error-message';
import SubmitButton from 'components/buttons/submitButton';
import { useMutation, useQuery } from 'hooks';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { GET_DEFAULT_SETTING, UPDATE_DEFAULT_SETTING } from 'services/graphql/queries/sliders';

interface IProps {
  toggleOpen: () => void;
}
export default function SliderDefaultSettingForm({ toggleOpen }: IProps) {
  const [setExecute, createMeta] = useMutation(UPDATE_DEFAULT_SETTING);

  const { data, loading } = useQuery<{ getSlidersLocationDuration: { duration: number } }>(GET_DEFAULT_SETTING);

  const DEFAULT_VALUES = {
    duration: data?.getSlidersLocationDuration.duration,
  };
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError,
  } = useForm({
    defaultValues: DEFAULT_VALUES,
  });

  // useEffect(() => {
  //   setValue('duration', data?.getSlidersLocationDuration.duration);
  // }, [data?.getSlidersLocationDuration.duration]);

  const onSubmit = (data: { duration: number }) => {
    if (!data.duration) {
      setError('duration', { message: 'Please Enter Duration' });
      return;
    }

    console.log(errors);

    setExecute({
      variables: data,
    })
      .then((res) => {
        console.log(res);
        toast.success('Duration Updated Successfully');
        toggleOpen();
      })
      .catch((e) => {
        console.log(e);
        toggleOpen();
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup className="d-flex flex-column">
          <Label check className="mb-1 mb-0" role="button">
            Duration
          </Label>
          <Controller
            control={control}
            defaultValue={DEFAULT_VALUES?.duration}
            name="duration"
            rules={{
              required: 'Please Enter Duration',
              max: {
                value: 100,
                message: 'Duration Cannot be greater than 100',
              },
              min: {
                value: 1,
                message: 'Duration Cannot be less than 1 seconds',
              },
            }}
            render={({ field: { onChange } }) => {
              return (
                <Input
                  role="button"
                  id="activeDeActive"
                  className=" mb-1"
                  name="duration"
                  style={{
                    padding: '10px',
                  }}
                  type="number"
                  // onChange={(e) => {
                  //   if (Number(e.target.value) > 100) return;
                  //   setValue('duration', Number(e.target.value));
                  // }}
                  onChange={({ target: { value } }) => onChange(Number(value))}
                  // {...register('duration')}

                  defaultValue={data?.getSlidersLocationDuration.duration}
                  //   size={64}
                  //   checked={data?.isActive}
                  //   onClick={() => handleToggleSliderActive(data?.id as number, data?.isActive as boolean)}
                />
              );
            }}
          />
          <ErrorMessage
            errors={errors}
            name={`duration`}
            render={({ message }) => <FormFeedback>{message}</FormFeedback>}
          />
        </FormGroup>

        <SubmitButton loading={loading || createMeta.loading}>Save Change</SubmitButton>
      </form>
    </div>
  );
}

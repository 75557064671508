import { Loading } from 'components';
import PagesLayout from 'pages/components/layout';
import { EmlakAdsTable } from 'pages/content-management/emlak/ads/components';
import useEmlakAds from 'pages/content-management/emlak/ads/useEmlakAds';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';
import { add, ads, emlak } from 'tools/i18n/constants/i18n';
export default function EmlakAds() {
  const { data, loading, error, fetchMore } = useEmlakAds();
  const { t } = useTranslation();
  return (
    <Loading loading={loading}>
      <PagesLayout newSectionLink="new" newSectionButtonLabel={`${t(add)} ${t(emlak)} ${t(ads)}`}>
        <Card>
          <CardBody className="p-0">
            <EmlakAdsTable
              data={data?.data}
              count={data?.count}
              loading={loading}
              error={error}
              fetchMore={fetchMore}
            />
          </CardBody>
        </Card>
      </PagesLayout>
    </Loading>
  );
}

import React from 'react';
import Icon from 'components/icons';
import { ad, date, description, expired, history, status } from 'tools/i18n/constants/i18n';
import { useTranslation } from 'react-i18next';

export default function AdHistory() {
  const { t } = useTranslation();

  return (
    <div className="p-2 bg-white">
      <div className="d-flex flex-column">
        <div className="p-2 border-bottom">
          <div className="d-flex align-items-center mb-2 ml-2">
            <Icon Name="FiClock" />
            <h6 className="ml-2 mb-0">{`${t(ad)} ${t(history)}`}</h6>
          </div>
        </div>
        <div className="p-3 border-bottom">
          <div className="d-flex flex-column">
            <div className="d-flex mb-2 align-items-center text-left w-25">
              <Icon Name="FaRegCalendarPlus" />
              <b className="ml-2">{`${t(expired)} ${t(date)}:`}</b>
              <span className="ml-2"></span>
            </div>
            <div className="d-flex mb-2 align-items-center text-left w-25">
              <Icon Name="FiActivity" />
              <b className="ml-2">{`${t(status)}:`}</b>
            </div>
            <div className="d-flex mb-2 align-items-center text-left w-25">
              <Icon Name="FaTextWidth" />
              <b className="ml-2">{`${t(description)}:`}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

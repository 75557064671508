import useQuery from 'hooks/useQuery';
import { useParams } from 'react-router-dom';
import { GET_EMLAK_AD } from 'services/graphql/queries/emlak/ads';
import { emlakAd } from './types';

export default function useEmlakAdsDetail() {
  const { type } = useParams();
  const { data, error, loading, refetch } = useQuery<{ RealEstatePosterDetails: emlakAd }>(GET_EMLAK_AD, {
    variables: {
      id: parseInt(type as string),
    },
  });
  return { data: data?.RealEstatePosterDetails, error, loading, refetch };
}

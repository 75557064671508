import SubmitButton from 'components/buttons/submitButton';
import CityDropdown from 'components/dropdown/cityDropdown';
import CountyDropdown from 'components/dropdown/countyDropdown';
import Icon from 'components/icons';
import OnlyCharacter, { OnlyCharInput } from 'components/inputs/onlyCharacter';
import OnlyNumberInput from 'components/inputs/onlyNumber';
import ShowError from 'components/showError';
import useMutation from 'hooks/useMutation';
import { newComponentsProps } from 'pages/user-management/employer/index';
import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Col, Form, FormFeedback, FormGroup, Row } from 'reactstrap';
import { ADD_COMPANY_ADDRESS, DELETE_COMPANY_ADDRESS } from 'services/graphql/queries/companies/createCompany.graphql';
import {
  add,
  address,
  city,
  company,
  country,
  county,
  create,
  deleted,
  fullAddress,
  please,
  postalCode,
  select,
  title,
  updated,
} from 'tools/i18n/constants/i18n';
import { errorFinder, toNull } from 'tools/methods';
import { stringish, t_city } from 'tools/types';
import { t_address } from 'tools/types/sectionTypes/company';
import { formsNameEnum } from './types';

type CompanyAddressProps = newComponentsProps;

const CompanyAddress: FC<CompanyAddressProps> = (props) => {
  const { componentsValues, handleChangeComponentState, state, componentsResponse, isActive } = props;
  const { t } = useTranslation();
  const isAlerted = useRef(false);
  const [addresses, setAddresses] = useState(componentsValues?.addresses);
  const [executeDelete, { loading: deleteLoading }] = useMutation(DELETE_COMPANY_ADDRESS);

  useEffect(() => {
    if (!isAlerted.current) {
      setAddresses(componentsValues?.addresses);
      if (componentsValues?.addresses?.length) {
        if (state.isCompleted) {
          isAlerted.current = true;
          toast.success(`${t(updated)}`);
        }
        handleChangeComponentState(formsNameEnum.CompanyAddress, true);
      }
    }
  }, [componentsValues?.addresses]);

  const addAddress = (data: t_address) => {
    if (address?.length) {
      setAddresses((prev) => [...(prev?.filter((item) => item._id) || []), data]);
    } else {
      setAddresses([data]);
    }
  };

  const removeAddress = (id: string) => {
    setAddresses((prev) => prev?.filter((item) => item._id !== id));
  };
  const addNewAddress = () => {
    const newAddress: Partial<t_address> = {
      _id: undefined,
      city: undefined,
      county: undefined,
      fullAddress: undefined,
      postalCode: undefined,
      title: undefined,
    };
    setAddresses((prev) => [...(prev || []), newAddress]);
  };
  const onDelete = (addressId: string) => {
    if (isActive) {
      executeDelete({
        variables: {
          userId: componentsResponse.userId,
          companyId: componentsResponse.companyId,
          addressId,
        },
      })
        .then((result) => {
          if (result.data.EmploymentDeleteEmployerAddress) {
            removeAddress(addressId as string);
            toast.success(`${t(deleted)}`);
          } else {
            toast.error(errorFinder(result?.errors?.[0]?.message));
          }
        })
        .catch((err) => {
          toast.error(errorFinder(err));
          console.error(err);
        });
    }
  };
  const isLastItem = addresses?.length === 1;
  const isEveryItemHasId = addresses?.every((item) => item._id);
  const disableAdress = isLastItem || !isEveryItemHasId;

  return (
    <div className="p-3 d-flex flex-column">
      <Row>
        <Col xs={12} xl={9} xxl={8} className="mx-auto">
          {addresses?.map((address, index) => {
            return (
              <div key={address._id || index} className="d-flex flex-column flex-grow-1">
                <AddressForm
                  {...props}
                  currentLength={addresses?.length || 0}
                  addAddress={addAddress}
                  disableAdress={disableAdress}
                  deleteLoading={deleteLoading}
                  defaultValues={address}
                  onDelete={onDelete}
                />
              </div>
            );
          })}
          <div role="button" onClick={addNewAddress} className=" mt-2 text-primary underline mr-auto">
            <Icon Name="AiOutlinePlus" />
            <span className="ml-2 mb-2">{`${t(add)} ${t(address)}`}</span>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default memo(CompanyAddress);

type t_defaultValues = t_address & { city: stringish | t_city } & { customError?: string };

interface AddressFormProps extends Omit<CompanyAddressProps, 'componentsValues'> {
  defaultValues?: Partial<t_defaultValues>;
  addAddress: (address: t_address) => void;
  currentLength: number;
  disableAdress: boolean;
  onDelete: (id: string) => void;
  deleteLoading: boolean;
}

const AddressForm: FC<AddressFormProps> = (props) => {
  const {
    isActive,
    componentsResponse,
    state,
    defaultValues,
    addAddress,
    disableAdress,
    currentLength,
    onDelete,
    deleteLoading,
  } = props;
  const { t } = useTranslation();
  const [execute, { loading }] = useMutation(ADD_COMPANY_ADDRESS);
  const [selectedCity, setSelectedCity] = useState<t_city>();

  const DEFAULT_VALUES: Partial<t_defaultValues> = {
    city: toNull(defaultValues?.city),
    county: toNull(defaultValues?.county),
    fullAddress: toNull(defaultValues?.fullAddress),
    postalCode: defaultValues?.postalCode,
    title: toNull(defaultValues?.title),
    _id: defaultValues?._id,
  };

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<t_defaultValues>({
    defaultValues: DEFAULT_VALUES,
  });

  const onSubmit = (data: t_defaultValues) => {
    if (isActive && !deleteLoading) {
      setValue('customError', '');
      const updatedData = {
        ...componentsResponse,
        ...data,
        //@ts-ignore
        city: typeof data?.city === 'string' ? data.city : data?.city?.city,
      };
      execute({
        variables: updatedData,
      })
        .then((res) => {
          if (res.data?.EmploymentAddEmployerAddress) {
            if (state.isCompleted) {
              toast.success(`${t(updated)}`);
            }
            addAddress({ ...data, _id: res.data?.EmploymentAddEmployerAddress.id });
          } else {
            //@ts-ignore
            setValue('customError', res.errors?.message);
          }
        })
        .catch((err) => {
          setValue('customError', err?.message);
        });
    }
  };

  const handleDelete = () => {
    onDelete(DEFAULT_VALUES._id as string);
  };
  const isNew = useMemo(
    () => Object.keys(DEFAULT_VALUES).every((key) => !DEFAULT_VALUES[key]),
    [DEFAULT_VALUES, currentLength]
  );
  const disalbeCreateButton = useMemo(() => (isNew ? true : !isNew ? false : !disableAdress), [isNew]);
  const isEnableDeleteButton = useMemo(
    () => (isNew || currentLength === 1 ? false : !!DEFAULT_VALUES._id),
    [isNew, currentLength]
  );
  const disableField = useMemo(() => (isNew ? false : true), [isNew, disableAdress]);
  return (
    <div className="p-3 pb-4 mx-auto d-flex flex-column w-100 flex-grow-1 border rounded my-2">
      <Form onSubmit={handleSubmit(onSubmit)}>
        {!!getValues('customError') && (
          <FormGroup>
            <ShowError>{getValues('customError')}</ShowError>
          </FormGroup>
        )}
        <FormGroup>
          <h6>{`${t(company)} ${t(address)}`}</h6>
        </FormGroup>
        <FormGroup>
          <OnlyCharInput
            defaultValue={DEFAULT_VALUES.title}
            disabled={disableField}
            control={control}
            name="title"
            isEveryCharacterAllowed
            isNumberAllowed
            placeholder={`${t(address)} ${t(title)}`}
            isRequired
          />
          <FormFeedback>{errors?.title?.message}</FormFeedback>
        </FormGroup>
        <FormGroup className="d-flex">
          <div className="flex-grow-1 d-flex flex-column mr-1">
            <Controller
              control={control}
              name="city"
              defaultValue={DEFAULT_VALUES.city}
              rules={{ required: `${t(please)} ${t(select)} ${t(city)}` }}
              render={({ field: { onChange } }) => {
                return (
                  <CityDropdown
                    //@ts-ignore
                    defaultValue={
                      DEFAULT_VALUES?.city
                        ? {
                            label: DEFAULT_VALUES.city?.city || DEFAULT_VALUES.city,
                            value: DEFAULT_VALUES.city?.city || DEFAULT_VALUES?.city,
                          }
                        : undefined
                    }
                    placeholder={t(city)}
                    isDisabled={disableField}
                    onSelect={(val) => {
                      setSelectedCity(val);
                      onChange(val);
                    }}
                  />
                );
              }}
            />
            <FormFeedback>{errors?.city?.message}</FormFeedback>
          </div>
          <div className="flex-grow-1 d-flex flex-column mr-1">
            <Controller
              control={control}
              name="county"
              defaultValue={DEFAULT_VALUES?.county}
              rules={{ required: `${t(please)} ${t(select)} ${t(county)}` }}
              render={({ field: { onChange } }) => {
                return (
                  <CountyDropdown
                    isDisabled={disableField}
                    defaultValue={DEFAULT_VALUES?.county}
                    selectedCity={selectedCity}
                    placeholder={t(country)}
                    onSelect={onChange}
                  />
                );
              }}
            />
            <FormFeedback>{errors?.county?.message}</FormFeedback>
          </div>
          <div className="flex-grow-1 d-flex flex-column">
            <OnlyNumberInput
              disabled={disableField}
              validNumberAsString
              defaultValue={DEFAULT_VALUES?.postalCode}
              //@ts-ignore
              control={control}
              name="postalCode"
              placeholder={t(postalCode)}
              maxLength={5}
              rules={{
                maxLength: 5,
              }}
            />
            <FormFeedback>{errors?.postalCode?.message}</FormFeedback>
          </div>
        </FormGroup>
        <div className="d-flex flex-column">
          <FormGroup className="d-flex flex-column">
            <OnlyCharacter
              disabled={disableField}
              defaultValue={DEFAULT_VALUES?.fullAddress}
              isRequired
              control={control}
              isNumberAllowed
              isEveryCharacterAllowed
              placeholder={t(fullAddress)}
              name={'fullAddress'}
            />
          </FormGroup>

          <FormFeedback>{errors?.fullAddress?.message}</FormFeedback>
        </div>
        {isActive && (
          <div className="d-flex w-100">
            {disalbeCreateButton && (
              <SubmitButton loading={loading} color="primary" className="flex-grow-1">
                {t(create)}
              </SubmitButton>
            )}
            {isEnableDeleteButton && (
              <Button onClick={handleDelete} disabled={deleteLoading} type="button" color="danger" className="ml-auto">
                Delete
              </Button>
            )}
          </div>
        )}
      </Form>
    </div>
  );
};

import React, { useMemo, useRef, useState } from 'react';
import { Badge, Calendar, Modal, Popconfirm, message } from 'antd';
import { useMutation, useQuery, useToggle, useUpdateEffect } from 'hooks';
import { Card, CardBody, CardHeader, Input } from 'reactstrap';
import { formatDate } from './utils/helper';
import Icon from 'components/icons';
import { SelectInfo } from 'antd/es/calendar/generateCalendar';
import PagesLayout from 'pages/components/layout';
import { CREATE_HOLIDAY, GET_ALL_HOLIDAYS, UPDATE_HOLIDAY } from 'services/graphql/queries/holiday';
import { Loading } from 'components';
import { date, holiday } from './utils/types';
import { toast } from 'react-toastify';
import { errorFinder } from 'tools/methods';
import Dayjs from 'dayjs';

export default function HolidayManagement() {
  const {
    data = { getYearHolidays: [] },
    loading,
    refetch,
  } = useQuery<{ getYearHolidays: holiday[] }>(GET_ALL_HOLIDAYS, {
    variables: {
      year: Dayjs().get('year'),
    },
  });
  const handleChangeYear = async (year: number): Promise<holiday[]> => {
    return new Promise((resolve, reject) => {
      refetch({
        year,
      })
        .then((response) => {
          if (response.data.getYearHolidays) {
            resolve(response.data.getYearHolidays);
          } else {
            reject('ERROR');
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  return (
    <Loading loading={loading} onlyShowLoadingOnce>
      <HolidayCalendar
        calendarLoading={loading}
        allHolidays={data?.getYearHolidays}
        handleChangeYear={handleChangeYear}
      />
    </Loading>
  );
}

interface HolidayCalendarProps {
  allHolidays: holiday[];
  calendarLoading: boolean;
  handleChangeYear: (year: number) => Promise<holiday[]>;
}

function HolidayCalendar({ allHolidays, handleChangeYear }: HolidayCalendarProps) {
  const [holidays, setHolidays] = useState<holiday[]>(allHolidays);
  const [loading, toggleLoading] = useToggle(false);
  const [createExecute] = useMutation(CREATE_HOLIDAY);
  const [updateActiveExecute] = useMutation(UPDATE_HOLIDAY);
  const [open, toggleOpen] = useToggle(false);
  const inputRef = useRef<undefined | string>(undefined);
  const cellActionTarget = useRef<'NEW' | 'UPDATE' | undefined>(undefined);
  async function updateEvent(data: { id: number; title?: string; isActive?: boolean }) {
    toggleLoading(true);
    return new Promise((resolve, reject) => {
      updateActiveExecute({
        variables: {
          id: data.id,
          title: data.title,
          isActive: data.isActive,
        },
      })
        .then((response) => {
          if (response.data?.updateHoliday === 'done') {
            toast.success('Event Updated Successfully');
            resolve('done');
          } else {
            toast.error(errorFinder(response.extensions));
            reject('ERROR');
          }
        })
        .catch((error) => {
          toast.error(errorFinder(error));
          reject('ERROR');
        })
        .finally(() => {
          toggleLoading(false);
        });
    });
  }
  async function createEvent(data: { title: string; date: string }): Promise<number> {
    toggleLoading(true);
    return new Promise((resolve, reject) => {
      createExecute({
        variables: {
          title: data.title,
          date: data.date,
          isStatic: false,
        },
      })
        .then((response) => {
          if (typeof response.data?.createHoliday === 'number') {
            toast.success('Event Successfully Created');
            resolve(response.data?.createHoliday);
          } else {
            toast.error(errorFinder(response.extensions));
            Promise.reject('ERROR');
          }
        })
        .catch((error) => {
          toast.error(errorFinder(error));
          reject('ERROR');
        })
        .finally(() => {
          toggleLoading(false);
        });
    });
  }

  const onPanelChange = () => {
    let originalDate: string | undefined;
    let targetItem: holiday['holidays'][0] | undefined;
    let date: date = {
      year: undefined,
      day: undefined,
      month: undefined,
    };

    function setInfo(
      value: any,
      mode: { source: 'edit' | SelectInfo['source'] },
      selectedItem?: holiday['holidays'][0]
    ) {
      if (mode.source === 'month') return;
      if (mode.source == 'year') {
        const selectedYear = formatDate(value).year;
        message.loading(`Getting ${selectedYear} holidays`);
        handleChangeYear(selectedYear)
          .then((response) => {
            setHolidays(response);
          })
          .finally(() => {
            message.destroy();
          });
        return;
      }
      const parsedDate = value.format('YYYY-MM-DD') as string;
      originalDate = parsedDate;
      const splitedDate = parsedDate.split('-');
      const getYear = parseInt(splitedDate[0]);
      const getMonth = parseInt(splitedDate[1]);
      const getDay = parseInt(splitedDate[2]);
      date = {
        day: getDay,
        month: getMonth,
        year: getYear,
      };
      if (mode.source === 'edit') {
        cellActionTarget.current = 'UPDATE';
        targetItem = selectedItem;
        toggleOpen(true);
      } else if (!checkAvailableEvent()) {
        cellActionTarget.current = 'NEW';
        toggleOpen(true);
      }
    }
    function onCreate() {
      if (!inputRef.current) {
        message.warning('Please Enter Holiday Title');
        return;
      }
      createEvent({
        date: originalDate as string,
        title: inputRef.current as string,
      })
        .then((id) => {
          setHolidays((prev) => {
            const isFilledMonth = prev.find((m) => m.month === date.month);
            if (isFilledMonth) {
              return prev.map((mon) => {
                if (mon.month !== date.month) return mon;
                const isFilledDay = mon.holidays.find((d) => d.day === date.day);
                return {
                  ...mon,
                  holidays: isFilledDay
                    ? mon.holidays.map((day) => {
                        if (day.day !== date.day) return day;
                        return {
                          ...day,
                          id,
                          dayContent: {
                            ...day.dayContent,
                            id,
                            title: inputRef.current as string,
                          },
                        };
                      })
                    : [
                        ...mon.holidays,
                        {
                          day: date.day as number,
                          dayContent: { id, isActive: true, isStatic: false, title: inputRef.current as string },
                        },
                      ],
                };
              });
            } else {
              return [
                ...prev,
                {
                  month: date.month as number,
                  holidays: [
                    {
                      day: date.day as number,
                      dayContent: {
                        id,
                        title: inputRef.current as string,
                        isStatic: false,
                        isActive: true,
                      },
                    },
                  ],
                },
              ];
            }
          });
          Promise.resolve();
          toggleOpen(false);
        })
        .catch(() => {
          Promise.reject();
        })
        .finally(() => {
          toggleLoading(false);
          toggleOpen(false);
        });
    }

    function onUpdate() {
      if (!inputRef.current) {
        message.warning('Please Enter Holiday Title');
        return;
      }
      if (!targetItem) {
        message.error('Client Cannot Find Selected Holiday');
        return;
      }
      return updateEvent({
        id: targetItem.dayContent.id as number,
        title: inputRef.current,
      })
        .then(() => {
          setHolidays((prev) => {
            return prev.map((item) => {
              if (item.month !== date.month) return item;
              return {
                ...item,
                holidays: item.holidays.map((d) => {
                  if (d.day !== date.day) return d;

                  return { ...d, dayContent: { ...d.dayContent, title: inputRef.current as string } };
                }),
              };
            });
          });
          Promise.resolve();
        })
        .catch(() => {
          Promise.reject();
        })
        .finally(() => {
          toggleLoading(false);
          toggleOpen(false);
        });
    }
    function checkAvailableEvent() {
      const targetMonth = holidays.find((item) => item.month === date.month);
      const isAlreadyHasEvent = targetMonth?.holidays.find((day) => day.day === date.day);
      if (isAlreadyHasEvent?.dayContent.isStatic) {
        message.warning('There is already an holiday in this day');
      }
      return isAlreadyHasEvent;
    }
    return { setInfo, onCreate, onUpdate };
  };

  async function toggleActiveEvent({
    day,
    id,
    isActive,
    month,
  }: {
    id: number;
    month: number;
    day: number;
    isActive: boolean;
  }) {
    toggleLoading(true);

    return updateEvent({
      id,
      isActive,
    })
      .then(() => {
        setHolidays((prev) => {
          return prev.map((item) => {
            if (item.month !== month) return item;
            return {
              ...item,
              holidays: item.holidays.map((d) => {
                if (d.day !== day) return d;

                return { ...d, dayContent: { ...d.dayContent, isActive } };
              }),
            };
          });
        });
        Promise.resolve();
      })
      .catch(() => {
        Promise.reject();
      })
      .finally(() => {
        toggleLoading(false);
      });
  }
  const changeInstance = useMemo(() => onPanelChange(), [holidays]);
  const dateCellRender = (value: any) => {
    const month = formatDate(value).month;
    const listData = holidays.find((item) => item.month === month);
    return (
      <ul className="events">
        {listData?.holidays?.map((item) => {
          if (item.day === formatDate(value).day) {
            return (
              <Popconfirm
                key={item.day}
                title={item.dayContent.isActive ? 'De Active this holiday' : 'Active this holiday'}
                description={`Are you sure to ${item.dayContent.isActive ? 'De Active' : 'Active'} this holiday ?`}
                onConfirm={async (event) => {
                  event?.stopPropagation();
                  return toggleActiveEvent({
                    month,
                    day: item.day,
                    isActive: !item.dayContent.isActive,
                    id: item.dayContent.id as number,
                  });
                }}
                onCancel={(event) => event?.stopPropagation()}
                okText="Yes"
                cancelText="No"
                trigger="click"
              >
                {item.dayContent.isStatic && (
                  <div
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                    }}
                  >
                    <Badge className="official-badge" size="small" status="default" color="#16a1bf" text="Official" />
                  </div>
                )}

                <li
                  key={item.day}
                  onClick={(event) => {
                    if (item.dayContent.isStatic) {
                      event.preventDefault();
                      event.stopPropagation();
                    }
                  }}
                  className="holiday-cell border rounded-md p-1 d-flex align-items-center overflow-hidden text-ellipsis justify-content-between"
                >
                  <Badge status={item.dayContent.isActive ? 'success' : 'warning'} text={item.dayContent.title} />
                  {!item.dayContent.isStatic && (
                    <div
                      style={{ width: '24px', height: '24px' }}
                      role="button"
                      className="ml-1"
                      onClick={(event) => {
                        event.stopPropagation();
                        changeInstance.setInfo(value, { source: 'edit' }, item);
                        inputRef.current = item.dayContent.title;
                      }}
                    >
                      <Icon Name="FiEdit" />
                    </div>
                  )}
                </li>
              </Popconfirm>
            );
          } else return <></>;
        })}
      </ul>
    );
  };

  useUpdateEffect(() => {
    if (!open) {
      inputRef.current = undefined;
    }
  }, [open]);

  return (
    <PagesLayout>
      <Card>
        <CardHeader>
          <h4>Holidays Management</h4>
        </CardHeader>
        <CardBody>
          <Modal
            open={open}
            confirmLoading={loading}
            destroyOnClose
            onOk={() => {
              if (cellActionTarget.current === 'UPDATE') {
                changeInstance.onUpdate();
              } else {
                changeInstance.onCreate();
              }
            }}
            onCancel={() => toggleOpen(false)}
            title="Enter Holiday Title"
            okText={cellActionTarget.current === 'UPDATE' ? 'Update' : 'Create'}
          >
            <div>
              <Input
                placeholder="Title"
                disabled={loading}
                defaultValue={inputRef.current}
                onChange={(event) => {
                  inputRef.current = event.target.value;
                }}
              />
            </div>
          </Modal>
          <Calendar
            mode="month"
            className="calendar-header"
            onSelect={changeInstance.setInfo}
            cellRender={dateCellRender}
          />
        </CardBody>
      </Card>
    </PagesLayout>
  );
}

import DefaultIcon from 'assets/images/buluro/dashboard/default-bazzar.svg';
import WalletIcon from 'assets/images/buluro/dashboard/empty-wallet.svg';
import { Loading } from 'components';
import Icon from 'components/icons';
import OnlyCharInput from 'components/inputs/onlyCharacter';
import Modal from 'components/modal';
import { t_toggleValueFunc, useToggle } from 'hooks';
import useMutation from 'hooks/useMutation';
import PagesLayout from 'pages/components/layout';
import React, { FC, useEffect, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, CardHeader, Col, Form, FormFeedback, FormGroup, Label } from 'reactstrap';
import { EMLAK_ADS_ADMIN_BAN } from 'services/graphql/queries/emlak/ads';
import { default as Styled, default as styled } from 'styled-components';
import { History, Type } from 'tools/i18n/constants';
import {
  This,
  ad,
  admin,
  area,
  ban,
  cancel,
  date,
  description,
  emlakAds,
  enter,
  fullAddress,
  history,
  no,
  please,
  postalCode,
  publishedOn,
  reasons,
  reasonsFor,
  sponsored,
  title,
  unban,
} from 'tools/i18n/constants/i18n';
import { errorFinder, toDate } from 'tools/methods';
import { customError } from 'tools/types';
import { emlakAd } from './types';
import { STATUS } from './types/enum';
import useEmlakAdsDetail from './useEmlakAdDetail';

export default function EmlakAdsDetail() {
  const { data, loading, refetch, error } = useEmlakAdsDetail();
  const { t } = useTranslation();
  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <PagesLayout backLink="emlakAds" backLinkTitle={t(emlakAds)} backLinkParam="/">
        <Loading loading={loading} failed={!!error} onRetry={refetch}>
          <Card className="bg-transparent shadow-0">
            <Header data={data as emlakAd} refetch={refetch} />
            <Body data={data as emlakAd} />
          </Card>
        </Loading>
      </PagesLayout>
    </Col>
  );
}

interface HeaderProps {
  data: emlakAd;
  refetch: any;
}

const Header: FC<HeaderProps> = (props) => {
  const { t } = useTranslation();
  const { data, refetch } = props;
  const [modalOpen, toggleModalOpen] = useToggle(false);
  const [wantToBan, toggleWantToBan] = useToggle(false);
  const convertDate = useMemo(() => {
    const d = data?.publishedAt || '';
    return d ? toDate(data?.publishedAt as string) : '';
  }, [data?.publishedAt]);
  const handleClickUnban = () => {
    toggleModalOpen();
    toggleWantToBan(false);
  };
  const handleClickBan = () => {
    toggleModalOpen();
    toggleWantToBan(true);
  };

  const isBanAd = data?.status === STATUS.BAN;
  const isActiveAd = data?.status === STATUS.ACTIVE;

  return (
    <CardHeader className="bg-transparent shadow-0 p-2">
      <BanModal refetch={refetch} data={data} wantToBan={wantToBan} isOpen={modalOpen} toggleOpen={toggleModalOpen} />
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <Icon Name="FiClock" />
          <span className="ml-2">{`${t(publishedOn)}: ${convertDate}`}</span>
        </div>
        <div>
          {(isActiveAd || isBanAd) && (
            <Button
              type="button"
              color={isActiveAd ? 'danger' : 'success'}
              onClick={isActiveAd ? handleClickBan : handleClickUnban}
            >
              <div className="d-flex align-items-center">
                <Icon Name={isActiveAd ? 'FaRegTrashAlt' : 'FaCheck'} size="15" />
                <span className="ml-2">{`${t(isActiveAd ? ban : unban)} ${t(This)} ${t(ad)}`}</span>
              </div>
            </Button>
          )}
        </div>
      </div>
    </CardHeader>
  );
};

interface BodyProps {
  data: emlakAd;
}

const ImageContainer = Styled.div`
  width : 400px;
  height : 400px;
  img {
    width : 100%;
    height : 100%
   }
`;
const Body: FC<BodyProps> = (props) => {
  const { data } = props;
  const { t } = useTranslation();
  const adsStatusClassName =
    data?.status === STATUS.BAN
      ? 'shadow-bg-danger'
      : data?.status === STATUS.DE_ACTIVE || status === STATUS.DRAFT
      ? 'shadow-bg-notActive'
      : data?.status === STATUS.EXPIRED
      ? 'shadow-bg-warning'
      : 'shadow-bg-success';
  return (
    <>
      <CardBody className="bg-white rounded">
        <div className="d-flex">
          <ImageContainer className="mr-3">
            <img
              alt={data?.title?.toString()}
              src={data?.ownerInfo?.picture || DefaultIcon}
              className="rounded border"
            />
          </ImageContainer>
          <div className="d-fle flex-column flex-grow-1">
            <div className="d-flex justify-content-between mb-3">
              <div className={`${adsStatusClassName} p-1 d-flex align-items-center rounded`}>
                <Icon Name="FiActivity" />
                <b className="ml-2">
                  <em>{data?.status ? STATUS[data?.status] : ''}</em>
                </b>
              </div>
              {!!data?.boostActivity?.id && <div className="shadow-bg-warning p-1 rounded">{t(sponsored)}</div>}
            </div>
            <div className="p-3 rounded bg-gray">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center mb-3">
                  <Icon Name="FaRegCircle" />
                  <div className="d-flex ml-2">
                    <b className="mr-2">{`${t(Type)}: `}</b>
                    <span>{data.categoryType?.title}</span>
                  </div>
                </div>

                <div className="d-flex align-items-center mb-3">
                  <Icon Name="FiGrid" />
                  <div className="d-flex ml-2">
                    <b className="mr-2">{`${t(title)}: `}</b>
                    <span>{data?.title}</span>
                  </div>
                </div>
                {/* 
                <div className="d-flex align-items-center mb-3">
                  <Icon Name="FiType" />
                  <div className="d-flex ml-2">
                    <b className="mr-2">{`${t(model)}: `}</b>
                    <span>{data?.releaseDays}</span>
                  </div>
                </div> */}

                <div className="d-flex align-items-center">
                  <Icon Name="AiOutlineFileText" />
                  <div className="d-flex ml-2">
                    <b className="mr-2">{`${t(description)}: `}</b>
                    <span>{data?.description}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-3 rounded bg-gray mt-2">
              <div className="d-flex flex-column">
                <div className="d-flex mb-3">
                  <Icon Name="FiMapPin" />
                  <div className="d-flex ml-2">
                    <b className="mr-2">{`${t(area)}: `}</b>
                    <span>{`${data.address?.country?.name} - ${data.address?.city?.name} - ${data.address?.town?.name}`}</span>
                  </div>
                </div>

                <div className="d-flex mb-3">
                  <Icon Name="FaRegImage" />
                  <div className="d-flex ml-2">
                    <b className="mr-2">{`${t(fullAddress)}: `}</b>
                    <span>{data?.address?.location.type}</span>
                  </div>
                </div>

                <div className="d-flex">
                  <Icon Name="FaSearchLocation" />
                  <div className="d-flex ml-2">
                    <b className="mr-2">{`${t(postalCode)}: `}</b>
                    <span>{data?.address?.city.name}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardBody>
      <BanHistory data={data?.reportActivity} />
    </>
  );
};

interface BanHistoryProps {
  data: emlakAd['reportActivity'];
}

function BanHistory(props: BanHistoryProps) {
  const { t } = useTranslation();
  return (
    <Card className="mt-4">
      <CardHeader>
        <div className="d-flex">
          <Icon Name="FaRegClock" />
          <h6 className="ml-2">{`${t(ban)} ${t(History)}`}</h6>
        </div>
      </CardHeader>
      <CardBody className="p-2">
        <header className="border-bottom p-3">
          <div className="d-flex align-items-center">
            <div className="w-50 text-left">
              <div className="d-flex">
                <Icon Name="AiFillCalendar" />
                <span className="ml-2">{t(date)}</span>
              </div>
            </div>

            <div className="w-50 text-left">
              <div className="d-flex">
                <Icon Name="FaTextHeight" />
                <span className="ml-2">{t(description)}</span>
              </div>
            </div>
          </div>
        </header>
        <main className="mt-2">
          {/*   {data?.length ? (
            <>
              {data.map((item) => {
                return (
                  <div key={item.id} className="p-2 ml-2 border-bottom d-flex w-100">
                    <div className="w-50">{toDate(item.createdAt)}</div>
                    <div className="w-50">{item.description}</div>
                  </div>
                );
              })}
            </>
          ) : (
            <h6 className="mx-auto text-center mt-3">{`${t(no)} ${t(history)}`}</h6>
          )} */}
          <h6 className="mx-auto text-center mt-3">{`${t(no)} ${t(history)}`}</h6>
        </main>
      </CardBody>
    </Card>
  );
}

const StyledModalImage = styled.div`
  width: 150px;
  height: 150px;
`;
interface BanModalInterface {
  isOpen: boolean;
  toggleOpen: t_toggleValueFunc;
  data: emlakAd;
  wantToBan: boolean;
  refetch: any;
}
const iconSize = '18px';
type modalDefaultValues = {
  status: STATUS;
  description: string | undefined;
  id: number;
};
const BanModal: FC<BanModalInterface> = (props) => {
  const { toggleOpen, isOpen, data, wantToBan, refetch } = props;
  const { t } = useTranslation();
  const DEFAULT_VALUE: modalDefaultValues & customError = {
    customError: '',
    description: undefined,
    id: data?.id,
    status: wantToBan ? STATUS.BAN : STATUS.ACTIVE,
  };
  console.log(DEFAULT_VALUE);

  const [banExecute, { loading: banLoading }] = useMutation<
    { RealEstateChangePosterStatus: { done: boolean } },
    modalDefaultValues
  >(EMLAK_ADS_ADMIN_BAN);

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    clearErrors,
    unregister,
    formState: { errors, isDirty },
  } = useForm<modalDefaultValues & customError>({
    defaultValues: DEFAULT_VALUE,
  });
  const onSubmit: SubmitHandler<modalDefaultValues> = (data) => {
    setValue('customError', '');
    console.log(data);

    banExecute({
      variables: data,
    })
      .then((res) => {
        console.log(res);
        if (res.data?.RealEstateChangePosterStatus?.done) {
          refetch();
          toggleOpen();
        }
      })
      .catch((err) => {
        console.error(err);
        setValue('customError', `Error : ${errorFinder(err) || ''}`);
      });
  };
  const handleClose = () => {
    clearErrors();
    unregister('status');
    unregister('description');
    reset();
  };
  useEffect(() => {
    return () => {
      handleClose();
    };
  }, []);
  return (
    <Modal
      unmountOnClose
      title={`${t(admin)} ${t(ban)}`}
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      onClosed={handleClose}
      style={{ minWidth: '50vw' }}
    >
      <Card className="w-100">
        <CardBody className="p-0">
          {!!getValues('customError') && (
            <div className="w-100 rounded p-2 mb-3 shadow-bg-danger no-hover">{getValues('customError')}</div>
          )}
          <div className="bg-gray rounded mb-3 mt-1 p-3">
            <div className="d-flex mx-auto">
              <StyledModalImage style={{ width: '150px', height: '150px' }} className="rounded-md mr-2">
                <img
                  src={data?.images?.[0] || DefaultIcon}
                  alt={data?.title || undefined}
                  width="100%"
                  height="100%"
                  className="rounded-md"
                />
              </StyledModalImage>
              <div className="d-flex flex-column justify-content-between">
                <div className="d-flex mb-2">
                  <Icon size={iconSize} Name="AiOutlineApartment" />
                  <span className="ml-2">{data?.title}</span>
                </div>
                <div className="d-flex mb-2">
                  <Icon size={iconSize} Name="FiGrid" />
                  <span className="ml-2">{data?.category?.title}</span>
                </div>
                <div className="d-flex mb-2">
                  <Icon size={iconSize} Name="FaRegCircle" />
                  <span className="ml-2">{data?.status}</span>
                </div>
                <div className="d-flex">
                  <img src={WalletIcon} alt={data?.id?.toString() || undefined} width="20px" height="20px" />
                  <span className="ml-2 whitespace-nowrap">{data?.price}</span>
                </div>
              </div>
            </div>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <p className="bold">
              {`Please Enter the reasons for ${
                wantToBan ? t(ban) : t(unban)
              } so that the company will be informed through the notification`}
            </p>
            <Controller
              control={control}
              name="status"
              defaultValue={DEFAULT_VALUE.status}
              render={() => <input type="hidden" />}
            />
            <Controller
              control={control}
              name="id"
              defaultValue={DEFAULT_VALUE.id}
              render={() => <input type="hidden" />}
            />

            <FormGroup>
              <Label>{`${t(reasonsFor)} ${t(ban)}`}</Label>
              <OnlyCharInput
                isTextArea
                isNumberAllowed
                isEveryCharacterAllowed
                control={control}
                name="description"
                isRequired={wantToBan}
                requiredErrorText={`${t(please)} ${t(enter)} ${t(reasons)}`}
              />
              <FormFeedback>{errors?.description?.message}</FormFeedback>
            </FormGroup>
            <div className="d-flex flex-column">
              <Button
                disabled={!isDirty || banLoading}
                aria-disabled={!isDirty || banLoading}
                role="button"
                color=""
                className={`${wantToBan ? 'shadow-bg-danger' : 'shadow-bg-success'} border-0 mb-2`}
                type="submit"
              >
                {`${t(wantToBan ? ban : unban)} ${t(This)} ${t(ad)}`}
              </Button>
              <Button onClick={() => toggleOpen()} className="border" color="transparent" type="button">
                {t(cancel)}
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Modal>
  );
};

import { ApolloError } from '@apollo/client';
import { t_toggleValueFunc } from 'hooks';
import { FC, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cancel, confirm, enter, please, reasons, reject, update } from 'tools/i18n/constants/i18n';
import { t_blueTickStatus } from 'tools/types';
import useGuideReason from '../leaderReasons/useCompanyReasons';
import { Button, Form, FormFeedback, FormGroup, Label } from 'reactstrap';
import Modal from 'components/modal';

import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import FetchApi from 'services/api/axios';
import { toast } from 'react-toastify';
import React from 'react';
import SubmitButton from 'components/buttons/submitButton';
import { COMPANY_REHBER_STATUS } from 'tools/enums';
import clsx from 'clsx';

interface ModalHandlerProps {
  status: COMPANY_REHBER_STATUS | undefined;
  activeStatus: COMPANY_REHBER_STATUS | undefined;
  isOpen: boolean;
  toggleOpen: t_toggleValueFunc;
  companyId: number;
  refetch: () => void;
}

const GuideStatusModalHandler: FC<ModalHandlerProps> = (props) => {
  const { status, activeStatus, companyId, isOpen, toggleOpen, refetch } = props;
  const { data: reasonData, loading: reasonLoading } = useGuideReason();

  const [loading, setLoading] = useState(false);

  const DEFAULT_VALUES = {
    companyId: companyId,
    reason: undefined,
    status: activeStatus,
    reasonId: undefined,
  };
  const { t } = useTranslation();
  const errorText = `${t(please)} ${t(enter)} ${t(reasons)}`;
  const {
    handleSubmit,
    register,
    setError,
    watch,
    control,
    formState: { errors },
  } = useForm<{ reason?: string | undefined; reasonId: number | undefined; status: string }>({
    defaultValues: DEFAULT_VALUES,
  });
  const onSubmit = (data: any) => {
    const finalData = {
      ...data,
      // status: activeStatus === COMPANY_REHBER_STATUS.ACCEPTED || activeStatus === COMPANY_REHBER_STATUS.ACCEPTED
      reason:
        data.reasonId && reasonData?.find((i) => data.reasonId === i.id)?.needToInsertCustomText
          ? data?.reason
          : undefined,
    };
    setLoading(true);
    if (status) {
      FetchApi({
        url: 'LEADER_STATUS',
        method: 'PATCH',
        data: finalData,
      })
        .then((res: any) => {
          if (res.data) {
            toast.success(t('update successful'));
            toggleOpen();
            refetch();
          } else {
            // toast.success(t('update successful'));
            // toggleOpen();
            // refetch();
          }
        })
        .catch((err: any) => {
          toast.error('company should complete its profile');
          toggleOpen();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      if (!data.reason) {
        setError('reason', { type: 'required', message: errorText });
      }
    }
  };

  return (
    <Modal
      header
      toggleOpen={toggleOpen}
      isOpen={isOpen}
      //   style={{ top: '20%' }}
      size="lg"
      title={t('Change Rehber Status')}
    >
      <Form className="mt-3" onSubmit={handleSubmit(onSubmit, console.error)}>
        {activeStatus === COMPANY_REHBER_STATUS.REJECTED ? (
          <Controller
            control={control}
            defaultValue={DEFAULT_VALUES.reasonId}
            name="reasonId"
            rules={{
              required: 'Please Enter Reason',
            }}
            render={({ field: { onChange } }) => {
              return (
                <FormGroup>
                  <Label>{t('Reason')}</Label>
                  <ReactSelect
                    options={reasonData?.map((item) => ({
                      label: item.language?.find((e) => e.language === 'tr')?.content,
                      value: item.id,
                    }))}
                    isLoading={reasonLoading}
                    onChange={(e) => onChange(e?.value)}
                  />
                  <FormFeedback>{errors?.reasonId?.message}</FormFeedback>
                </FormGroup>
              );
            }}
          />
        ) : (
          <></>
        )}

        {activeStatus === COMPANY_REHBER_STATUS.REJECTED &&
        reasonData?.find((i) => watch('reasonId') === i.id)?.needToInsertCustomText ? (
          <Controller
            control={control}
            defaultValue={DEFAULT_VALUES.reasonId}
            name="reason"
            rules={{
              required: 'Please Enter Custom Text',
              minLength: {
                value: 10,
                message: 'Please enter more than 10 characters',
              },
            }}
            render={({ field: { onChange } }) => (
              <FormGroup>
                <Label>{t('Custom')}</Label>
                <textarea
                  className="form-control"
                  onChange={(e) => onChange(e.target.value)}
                  // {...register('reason')}
                />
                <FormFeedback>{errors?.reason?.message}</FormFeedback>
              </FormGroup>
            )}
          />
        ) : (
          <></>
        )}

        <FormGroup className="d-flex flex-column">
          <SubmitButton
            loading={loading}
            color="transparent"
            className={clsx(
              `mb-2`,
              activeStatus === COMPANY_REHBER_STATUS.ACCEPTED ? 'shadow-bg-success' : 'shadow-bg-danger'
            )}
          >
            {activeStatus === COMPANY_REHBER_STATUS.ACCEPTED ? t(confirm) : t(reject)}
          </SubmitButton>
          <Button
            disabled={loading}
            aria-disabled={loading}
            type="button"
            color=""
            onClick={() => toggleOpen()}
            className="border rounded mt-1 flex-grow-1"
          >
            {t(cancel)}
          </Button>
        </FormGroup>
      </Form>
    </Modal>
  );
};

export default GuideStatusModalHandler;

import { DocumentNode } from 'graphql/language';
import React from 'react';
import { numberish, t_languageKeys } from 'tools/types';
export enum E_ACTION_DROPDOWN {
  OpenProfile = 'OpenProfile',
  OpenBazzar = 'OpenBazzar',
  URL = 'URL',
  OpenStore = 'OpenStore',
  OpenEmlak = 'OpenEmlak',
  OpenExchange = 'OpenExchange',
}

export type t_sliderEmployer = {
  id: number;
  image: string;
  isActive: boolean;
  softDelete: boolean;
  content: t_sliderLanguage[];
  destination: {
    id: number;
    action: E_ACTION_DROPDOWN;
    stringPayload: string;
    numberPayload: number;
    url: string;
  };
};

export type t_slider = {
  id: number;
  orderPosition: number;
  employerSliderId: numberish;
  employeeSliderId: numberish;
  employer: null | t_sliderEmployer;
  employee: null | t_sliderEmployer;
};
export type t_eventArg = {
  event: React.DragEvent<HTMLDivElement>;
  orderPosition: number;
  employer: null | t_slider['employer'];
  employee: null | t_slider['employee'];
  id: number;
};

export type t_sliderLanguage = {
  language: t_languageKeys;
  title?: string;
  description?: string;
  id?: number;
};

export type t_sliderDetail = {
  customError?: string;
  id: number | undefined;
  destinationId: number | undefined;
  content: t_sliderLanguage[] | undefined;
  image: string | File | undefined | null;
  action: E_ACTION_DROPDOWN | undefined;
  url: string | undefined | null;
  stringPayload: string | undefined;
  numberPayload: number | undefined;
  isActive: boolean;
};

export type t_shopCompany = {
  id: number;
  companyName: string;
};

export enum e_sliderLocation {
  HOME_PAGE_TOP = 'HOME_PAGE_TOP',
  JOB_SEARCH_PAGE_TOP = 'JOB_SEARCH_PAGE_TOP',
  EXCHANGE_DETAILS_PAGE_BOTTOM = 'EXCHANGE_DETAILS_PAGE_BOTTOM',
  EQUIPMENT_SEARCH_PAGE = 'EQUIPMENT_SEARCH_PAGE',
  FAIRS_LIST_PAGE = 'FAIRS_LIST_PAGE',
  MAGAZINES_LIST_PAGE = 'MAGAZINES_LIST_PAGE',
  CONTENT_SEARCH_PAGE = 'CONTENT_SEARCH_PAGE',
  REAL_ESTATE_ADS_LIST_TOP = 'REAL_ESTATE_ADS_LIST_TOP',
}

export type sliderTranslations = {
  id: number;
  image: string;
  title: string;
  description: string;
  language: string;
  createdAt: string;
  updatedAt: string;
};
export type sliderDestination = {
  id: number;
  action: string;
  linkType: string;
  link: string;
  stringPayload: string;
  numberPayload: string;
};
export type sliderLocation = {
  id: number;
  location: string;
  locationCategoryNumberId: number;
  locationCategoryStringId: string;
};
export type sliderSlot = {
  id: number;
  orderPosition: number;
  isEmployerSlider: boolean;
  isEmployeeSlider: boolean;
  startTime: string;
  endTime: string;
};
export type slider = {
  id: number;
  sortIndex?: number;
  isActive: boolean;
  softDelete: boolean;
  createdAt: string;
  updatedAt: string;
  translations: sliderTranslations[];
  isShuffledChildren: boolean;
  children: {
    id?: number;
    link?: string;
    action?: SliderActionsEnum;
    stringPayload?: string;
    numberPayload?: number;
    linkType?: actionTypeEnum | '';
    sortIndex?: number;
    childTranslations: {
      id: number;
      image: string;
      imageName: string;
      language: 'tr' | 'en';
    }[];
  }[];
  location: sliderLocation;
  slot: sliderSlot;
  duration: number;
};

export type sliderStatistics = {
  employerCount: number;
  employeeCount: number;
  activeSlider: number;
  deactivateSlider: number;
  allSlider: number;
  expireSlider: number;
};
export enum sliderUserTypeEnum {
  ALL = 'ALL',
  EMPLOYEE = 'EMPLOYEE',
  EMPLOYER = 'EMPLOYER',
}

export enum sliderLocationEnum {
  HOME_PAGE_TOP = 'HOME_PAGE_TOP',
  JOB_SEARCH_PAGE_TOP = 'JOB_SEARCH_PAGE_TOP',
  EXCHANGE_DETAILS_PAGE_BOTTOM = 'EXCHANGE_DETAILS_PAGE_BOTTOM',
  EQUIPMENT_SEARCH_PAGE = 'EQUIPMENT_SEARCH_PAGE',
  FAIRS_LIST_PAGE = 'FAIRS_LIST_PAGE',
  MAGAZINES_LIST_PAGE = 'MAGAZINES_LIST_PAGE',
  CONTENT_SEARCH_PAGE = 'CONTACT_SEARCH_PAGE',
  REAL_ESTATE_ADS_LIST_TOP = 'REAL_ESTATE_ADS_LIST_TOP',
}
export enum sliderStatusEnum {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  DEACTIVE = 'DEACTIVATE',
}
export type t_sliderFilter = {
  sliderStatus: keyof typeof sliderStatusEnum;
  location: keyof typeof sliderLocationEnum;
};

export type t_sliderFiles = {
  englishFile: string | undefined;
  turkishFile: string | undefined;
};

export enum actionTypeEnum {
  NO_ACTION = 'NO_ACTION',
  INTERNAL_LINK = 'INTERNAL_LINK',
  EXTERNAL_LINK = 'EXTERNAL_LINK',
}

export type queryVar = {
  location: sliderLocationEnum;
  userType: sliderUserTypeEnum;
  sliderStatus: sliderStatusEnum;
};
export type t_sliderTranslation = {
  image: File | string | undefined;
  title: string | undefined;
  description: string | undefined;
  language: t_languageKeys | string;
  id?: number;
};

export type t_updateSlider = {
  id: number | undefined;
  isActive: boolean | undefined;
};
export type t_newSlider = {
  translations: t_sliderTranslation[] | undefined;
  // destination:
  //   | {
  //       id?: number;
  //       linkType: actionTypeEnum | undefined;
  //       action: SliderActionsEnum | undefined;
  //       link: string | undefined;
  //       stringPayload: string | undefined;
  //       numberPayload: string | undefined;
  //     }
  //   | undefined;
  duration?: number;
  isShuffledChildren: boolean;
  location:
    | {
        id?: number;
        location: sliderLocationEnum | undefined;
        locationCategoryNumberId: number | undefined;
        locationCategoryStringId: string | undefined;
      }
    | undefined;
  slot:
    | {
        id?: number;
        startTime: string | undefined;
        endTime: string | undefined;
        userType: sliderUserTypeEnum | undefined;
      }
    | undefined;
  children: {
    id?: number;
    linkType: actionTypeEnum | undefined;
    action: SliderActionsEnum | undefined;
    link: string | undefined;
    stringPayload: string | undefined;
    numberPayload: string | undefined;
  }[];
};

export type childrenType = {
  id?: number;
  link?: string;
  action?: SliderActionsEnum;
  stringPayload?: string;
  numberPayload?: number;
  linkType?: actionTypeEnum | '';
  sortIndex: number;
  translations: [
    {
      image: string;
      language: 'en';
      imageUrl?: string;
    },
    {
      image: string;
      language: 'tr';
      imageUrl?: string;
    }
  ];
};

export enum SliderActionsEnum {
  NONE = 'NONE',
  EXTERNAL_LINK = 'EXTERNAL_LINK',
  OPEN_JOB_ADS_LIST = 'OPEN_JOB_ADS_LIST',
  OPEN_STUDENTS_SEARCH_PAGE = 'OPEN_STUDENTS_SEARCH_PAGE',
  OPEN_CREATE_NEW_JOB_PAGE = 'OPEN_CREATE_NEW_JOB_PAGE',
  OPEN_JOB_AD_DETAILS_PAGE = 'OPEN_JOB_AD_DETAILS_PAGE',
  OPEN_EQUIPMENT_VITRINE_PAGE = 'OPEN_EQUIPMENT_VITRIN_PAGE',
  OPEN_EQUIPMENT_SEARCH_PAGE = 'OPEN_EQUIPMENT_SEARCH_PAGE',
  OPEN_CREATE_NEW_EQUIPMENT_AD_PAGE = 'OPEN_CREATE_NEW_EQUIPMENT_AD_PAGE',
  OPEN_EQUIPMENT_AD_DETAILS_PAGE = 'OPEN_EQUIPMENT_AD_DETAILS_PAGE',
  OPEN_STORE_ADS_LIST_PAGE = 'OPEN_STORE_ADS_LIST_PAGE',
  OPEN_MAGAZINES_LIST_PAGE = 'OPEN_MAGAZINES_LIST_PAGE',
  OPEN_MAGAZINE_DETAILS_PAGE = 'OPEN_MAGAZINE_DETAILS_PAGE',
  OPEN_FAIRS_LIST_PAGE = 'OPEN_FAIRS_LIST_PAGE',
  OPEN_FAIR_DETAILS_PAGE = 'OPEN_FAIR_DETAILS_PAGE',
  OPEN_EXCHANGE_HOME_PAGE = 'OPEN_EXCHANGE_HOME_PAGE',
  OPEN_REAL_ESTATE_ADS_LIST_PAGE = 'OPEN_REAL_ESTATE_ADS_LIST_PAGE',
  OPEN_REAL_ESTATE_SEARCH_PAGE = 'OPEN_REAL_ESTATE_SEARCH_PAGE',
  OPEN_REAL_ESTATE_CREATE_NEW_AD_PAGE = 'OPEN_REAL_ESTATE_CREATE_NEW_AD_PAGE',
  OPEN_REAL_ESTATE_AD_DETAILS_PAGE = 'OPEN_REAL_ESTATE_AD_DETAILS_PAGE',
  OPEN_CONTACT_COMPANIES_LIST_PAGE = 'OPEN_CONTACT_COMPANIES_LIST_PAGE',
  OPEN_CONTACT_SEARCH_PAGE = 'OPEN_CONTACT_SEARCH_PAGE',
  OPEN_CONTACT_COMPANY_DETAILS_PAGE = 'OPEN_CONTACT_COMPANY_DETAILS_PAGE',
  OPEN_PROFILE_PAGE = 'OPEN_PROFILE_PAGE',
  OPEN_PROFILE_BLUE_TICK_PAGE = 'OPEN_PROFILE_BLUE_TICK_PAGE',
}

export enum e_actionDetailsListCategory {
  NONE = 'NONE',
  EXTERNAL = 'EXTERNAL',
  JOB = 'JOB',
  EQUIPMENT = 'EQUIPMENT',
  MAGAZINE = 'MAGAZINE',
  FAIR = 'FAIR',
  EXCHANGE = 'EXCHANGE',
  REAL_ESTATE = 'REAL_ESTATE',
  CONTACT = 'CONTACT',
  PROFILE = 'PROFILE',
}

export type t_actionDetailList = {
  category: e_actionDetailsListCategory;
  description: string;
  enum: SliderActionsEnum;
  is_external_link: boolean;
  is_internal_link: boolean;
  is_employee: boolean;
  is_employer: boolean;
  is_common: boolean;
  is_string_payload: boolean;
  is_integer_payload: boolean;
  is_link_payload: boolean;
  query: DocumentNode | undefined;
  onDataLoad?: any;
  vars?: Record<string, string | number | Record<string, any>>;
};

export type t_magazineEditions = {
  id: number;
  name: string;
  logo: string;
  edition: {
    id: number;
    title: string;
  }[];
};

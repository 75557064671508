import { GET_ADS_LIST } from '../../../../services/graphql/queries/ads';
import { GET_BAZZAR_CATEGORIES_SLIDER } from '../../../../services/graphql/queries/bazzar';
import { GET_ALL_BAZZAR_ADS } from '../../../../services/graphql/queries/bazzarAds';
import { GET_COMPANY_LIST } from '../../../../services/graphql/queries/companies';
import { GET_COMPANY_CATEGORIES_TREE_SLIDER } from '../../../../services/graphql/queries/companyCategories';
import { GET_FUAR_FILTERED_LIST } from '../../../../services/graphql/queries/fuar';
import { GET_MAGAZINE_EDITIONS } from '../../../../services/graphql/queries/magazine';
import { GET_SHOP_COMPANIES } from '../../../../services/graphql/queries/sliders';
import { t_dataTypes } from '../../../ads/bazzarAds/useBazzarAds';
import { jobAds } from '../../../ads/jobAds/types';
import { fuar } from '../../../fuar/fuar/useFuar';
import { companyItem } from '../../company/companies/useCompanies';
import { t_jobCategory } from '../../employment/jobCategories/types';
import {
  E_ACTION_DROPDOWN,
  SliderActionsEnum,
  actionTypeEnum,
  e_actionDetailsListCategory,
  sliderLocationEnum,
  sliderStatusEnum,
  sliderUserTypeEnum,
  t_actionDetailList,
  t_magazineEditions,
  t_newSlider,
  t_sliderFilter,
} from '../types';

export const ACTION_DROPDOWN = [
  {
    label: 'Open Profile',
    value: E_ACTION_DROPDOWN.OpenProfile,
  },
  {
    label: 'Open Bazaar',
    value: E_ACTION_DROPDOWN.OpenBazzar,
  },
  {
    label: 'Url',
    value: E_ACTION_DROPDOWN.URL,
  },
  {
    label: 'Open Store',
    value: E_ACTION_DROPDOWN.OpenStore,
  },
  {
    label: 'Open Emlak',
    value: E_ACTION_DROPDOWN.OpenEmlak,
  },
  {
    label: 'Open Exchange',
    value: E_ACTION_DROPDOWN.OpenExchange,
  },
];

export const FILTER_DEFAULT_VALUES: t_sliderFilter = {
  location: 'HOME_PAGE_TOP',
  sliderStatus: 'ALL',
};

export const USER_TYPE_OPTIONS = [
  {
    value: sliderUserTypeEnum.EMPLOYER,
    label: 'Kurumsal (İşveren)',
  },
  {
    value: sliderUserTypeEnum.EMPLOYEE,
    label: 'Bireysel (İşçi)',
  },
];

export const SLIDER_STATUS = [
  {
    value: sliderStatusEnum.ALL,
    label: 'All',
  },
  {
    value: sliderStatusEnum.ACTIVE,
    label: 'Active',
  },
  {
    value: sliderStatusEnum.DEACTIVE,
    label: 'De Active',
  },
];

export const LOCATION_OPTIONS = [
  {
    value: sliderLocationEnum.HOME_PAGE_TOP,
    label: 'Home Page',
  },
  {
    value: sliderLocationEnum.CONTENT_SEARCH_PAGE,
    label: 'Contact Search',
  },
  {
    value: sliderLocationEnum.EQUIPMENT_SEARCH_PAGE,
    label: 'Equipment Search Page',
  },
  {
    value: sliderLocationEnum.FAIRS_LIST_PAGE,
    label: 'Fairs List Page',
  },
  {
    value: sliderLocationEnum.JOB_SEARCH_PAGE_TOP,
    label: 'Job Search Page',
  },
  {
    value: sliderLocationEnum.EXCHANGE_DETAILS_PAGE_BOTTOM,
    label: 'Bottom Exchange Details',
  },
  {
    value: sliderLocationEnum.MAGAZINES_LIST_PAGE,
    label: 'Magazine List',
  },
];

export const ACIONS_TYPE_OPTION = [
  {
    label: 'No Action',
    value: 'NO_ACTION',
  },
  {
    label: 'Internal',
    value: actionTypeEnum.INTERNAL_LINK,
  },
  {
    label: 'External',
    value: actionTypeEnum.EXTERNAL_LINK,
  },
];

export const NEW_SLIDER_DEFAULT_VALUES: t_newSlider = {
  slot: undefined,
  children: [],
  location: undefined,
  translations: undefined,
};

export const LOCATION_ACTION_DETAILS_LIST = [
  {
    location: sliderLocationEnum.EQUIPMENT_SEARCH_PAGE,
    is_external_link: false,
    is_internal_link: false,
    is_employee: false,
    is_employer: false,
    is_common: false,
    is_string_payload: false,
    is_integer_payload: true,
    is_link_payload: false,
    query: GET_BAZZAR_CATEGORIES_SLIDER,
    onDataLoad(data: { findAllBazaarCategories: any[] }) {
      return data.findAllBazaarCategories
        .filter((item) => item?.child?.length)
        .map((a) => ({ label: a.title, value: a.id }));
    },
    vars: {
      page: 1,
      size: 1000,
    },
  },
  {
    location: sliderLocationEnum.CONTENT_SEARCH_PAGE,
    is_external_link: false,
    is_internal_link: false,
    is_employee: false,
    is_employer: false,
    is_common: false,
    is_string_payload: true,
    is_integer_payload: false,
    is_link_payload: false,
    query: GET_COMPANY_CATEGORIES_TREE_SLIDER,
    onDataLoad(data: { employmentEmployerCategoryList: t_jobCategory[] }) {
      return data.employmentEmployerCategoryList.map((a) => ({ label: a.originalName, value: a._id }));
    },
    vars: {
      page: 1,
      size: 1000,
    },
  },
];

export const ACTION_DETAILS_LIST: t_actionDetailList[] = [
  /* {
    category: e_actionDetailsListCategory.NONE,
    description: 'None',
    enum: SliderActionsEnum.NONE,
    is_external_link: false,
    is_internal_link: false,
    is_employee: false,
    is_employer: false,
    is_common: true,
    is_string_payload: false,
    is_integer_payload: false,
    is_link_payload: false,
    query: undefined,
  },*/
  {
    category: e_actionDetailsListCategory.JOB,
    description: 'Open job ads list',
    enum: SliderActionsEnum.OPEN_JOB_ADS_LIST,
    is_external_link: false,
    is_internal_link: true,
    is_employee: true,
    is_employer: false,
    is_common: false,
    is_string_payload: false,
    is_integer_payload: false,
    is_link_payload: false,
    query: undefined,
  },
  {
    category: e_actionDetailsListCategory.JOB,
    enum: SliderActionsEnum.OPEN_STUDENTS_SEARCH_PAGE,
    description: 'Open Student search',
    is_external_link: false,
    is_internal_link: true,
    is_employee: false,
    is_employer: true,
    is_common: false,
    is_string_payload: false,
    is_integer_payload: false,
    is_link_payload: false,
    query: undefined,
  },
  {
    category: e_actionDetailsListCategory.JOB,
    description: 'Open create new job ad',
    enum: SliderActionsEnum.OPEN_CREATE_NEW_JOB_PAGE,
    is_external_link: false,
    is_internal_link: true,
    is_employee: false,
    is_employer: true,
    is_common: false,
    is_string_payload: false,
    is_integer_payload: false,
    is_link_payload: false,
    query: undefined,
  },
  {
    category: e_actionDetailsListCategory.JOB,
    description: 'Open a job ad details page',
    enum: SliderActionsEnum.OPEN_JOB_AD_DETAILS_PAGE,
    is_external_link: false,
    is_internal_link: true,
    is_employee: true,
    is_employer: false,
    is_common: false,
    is_string_payload: true,
    is_integer_payload: false,
    is_link_payload: false,
    query: GET_ADS_LIST,
    onDataLoad(data: { EmploymentAdsList: { data: jobAds[] } }) {
      return data.EmploymentAdsList?.data?.map((a) => ({
        label: `${a.company.name}/${a?.branch?.originalName || ''}${
          a?.section?.originalName ? `/ ${a?.section?.originalName}` : ''
        }`,
        value: a._id,
      }));
    },
    vars: {
      page: 1,
      size: 1000,
      lang: 'tr',
      filters: {
        statusesMustBeShown: ['ACTIVE'],
      },
    },
  },
  {
    category: e_actionDetailsListCategory.EQUIPMENT,
    description: 'Open Vitrin page',
    enum: SliderActionsEnum.OPEN_EQUIPMENT_VITRINE_PAGE,
    is_external_link: false,
    is_internal_link: true,
    is_employee: false,
    is_employer: false,
    is_common: true,
    is_string_payload: false,
    is_integer_payload: false,
    is_link_payload: false,
    query: undefined,
  },
  {
    category: e_actionDetailsListCategory.EQUIPMENT,
    description: 'Open equipment search page',
    enum: SliderActionsEnum.OPEN_EQUIPMENT_SEARCH_PAGE,
    is_external_link: false,
    is_internal_link: true,
    is_employee: false,
    is_employer: false,
    is_common: true,
    is_string_payload: false,
    is_integer_payload: false,
    is_link_payload: false,
    query: undefined,
  },
  {
    category: e_actionDetailsListCategory.EQUIPMENT,
    description: 'Open create new equipment ad page',
    enum: SliderActionsEnum.OPEN_CREATE_NEW_EQUIPMENT_AD_PAGE,
    is_external_link: false,
    is_internal_link: true,
    is_employee: false,
    is_employer: false,
    is_common: true,
    is_string_payload: false,
    is_integer_payload: false,
    is_link_payload: false,
    query: undefined,
  },
  {
    category: e_actionDetailsListCategory.EQUIPMENT,
    description: 'Open a equipment ad details page',
    enum: SliderActionsEnum.OPEN_EQUIPMENT_AD_DETAILS_PAGE,
    is_external_link: false,
    is_internal_link: true,
    is_employee: false,
    is_employer: false,
    is_common: true,
    is_string_payload: false,
    is_integer_payload: true,
    is_link_payload: false,
    query: GET_ALL_BAZZAR_ADS,
    onDataLoad(data: { findAllBazaarPosters: { data: t_dataTypes[] } }) {
      return data.findAllBazaarPosters.data.map((item) => ({
        label: `${item.ownerInfo.name}/${item.title}`,
        value: item.id as number,
      }));
    },
    vars: {
      skip: 0,
      take: 1000,
      filters: {
        statusesMustBeShown: ['ACTIVE'],
      },
    },
  },
  {
    category: e_actionDetailsListCategory.EQUIPMENT,
    description: 'Open a store ads lists page',
    enum: SliderActionsEnum.OPEN_STORE_ADS_LIST_PAGE,
    is_external_link: false,
    is_internal_link: true,
    is_employee: false,
    is_employer: false,
    is_common: true,
    is_string_payload: false,
    is_integer_payload: true,
    is_link_payload: false,
    query: GET_SHOP_COMPANIES,
    onDataLoad(data: {
      getVerifiedForShopCompanyListAndDetails: { listResponse: { data: { id: number; companyName: string }[] } };
    }) {
      return data.getVerifiedForShopCompanyListAndDetails.listResponse.data.map((item) => ({
        label: item.companyName,
        value: item.id,
      }));
    },
  },
  {
    category: e_actionDetailsListCategory.MAGAZINE,
    description: 'Open Magazines Page',
    enum: SliderActionsEnum.OPEN_MAGAZINES_LIST_PAGE,
    is_external_link: false,
    is_internal_link: true,
    is_employee: false,
    is_employer: false,
    is_common: true,
    is_string_payload: false,
    is_integer_payload: false,
    is_link_payload: false,
    query: undefined,
  },
  {
    category: e_actionDetailsListCategory.MAGAZINE,
    description: 'Open a magazine details page',
    enum: SliderActionsEnum.OPEN_MAGAZINE_DETAILS_PAGE,
    is_external_link: false,
    is_internal_link: true,
    is_employee: false,
    is_employer: false,
    is_common: true,
    is_string_payload: false,
    is_integer_payload: true,
    is_link_payload: false,
    query: GET_MAGAZINE_EDITIONS,
    onDataLoad: (data: { MagazineAndEditionList: { count: number; result: t_magazineEditions[] } }) => {
      return data.MagazineAndEditionList.result.map((mag) => ({
        label: mag.name,
        options: mag.edition.map((edit) => ({ label: edit.title, value: edit.id })),
      }));
    },
    vars: {
      page: 1,
      size: 1000,
    },
  },
  {
    category: e_actionDetailsListCategory.FAIR,
    description: 'Open Fairs List page',
    enum: SliderActionsEnum.OPEN_FAIRS_LIST_PAGE,
    is_external_link: false,
    is_internal_link: true,
    is_employee: false,
    is_employer: false,
    is_common: true,
    is_string_payload: false,
    is_integer_payload: false,
    is_link_payload: false,
    query: undefined,
  },
  {
    category: e_actionDetailsListCategory.FAIR,
    description: 'Open Fairs Details page',
    enum: SliderActionsEnum.OPEN_FAIR_DETAILS_PAGE,
    is_external_link: false,
    is_internal_link: true,
    is_employee: false,
    is_employer: false,
    is_common: true,
    is_string_payload: false,
    is_integer_payload: true,
    is_link_payload: false,
    query: GET_FUAR_FILTERED_LIST,
    onDataLoad(data: { activeExhibitionList: fuar[] }) {
      console.log(data);
      return data.activeExhibitionList.map((item) => ({ label: item.title, value: item.id }));
    },
  },
  {
    category: e_actionDetailsListCategory.EXCHANGE,
    description: 'Open Exchange page',
    enum: SliderActionsEnum.OPEN_EXCHANGE_HOME_PAGE,
    is_external_link: false,
    is_internal_link: true,
    is_employee: false,
    is_employer: false,
    is_common: true,
    is_string_payload: false,
    is_integer_payload: false,
    is_link_payload: false,
    query: undefined,
  },
  {
    category: e_actionDetailsListCategory.CONTACT,
    description: 'Open contact companies list page',
    enum: SliderActionsEnum.OPEN_CONTACT_COMPANIES_LIST_PAGE,
    is_external_link: false,
    is_internal_link: true,
    is_employee: false,
    is_employer: false,
    is_common: true,
    is_string_payload: false,
    is_integer_payload: false,
    is_link_payload: false,
    query: undefined,
  },
  {
    category: e_actionDetailsListCategory.CONTACT,
    description: 'Open contact search page',
    enum: SliderActionsEnum.OPEN_CONTACT_SEARCH_PAGE,
    is_external_link: false,
    is_internal_link: true,
    is_employee: false,
    is_employer: false,
    is_common: true,
    is_string_payload: false,
    is_integer_payload: false,
    is_link_payload: false,
    query: undefined,
  },
  {
    category: e_actionDetailsListCategory.CONTACT,
    description: 'Open a company details page on guide side',
    enum: SliderActionsEnum.OPEN_CONTACT_COMPANY_DETAILS_PAGE,
    is_external_link: false,
    is_internal_link: true,
    is_employee: false,
    is_employer: false,
    is_common: true,
    is_string_payload: false,
    is_integer_payload: true,
    is_link_payload: false,
    query: GET_COMPANY_LIST,
    onDataLoad(data: { getCompanyListAndDetails: { listResponse: { data: companyItem[] } } }) {
      return data.getCompanyListAndDetails.listResponse.data.map((item) => ({
        label: item.companyName,
        value: item.id,
      }));
    },
    vars: {
      page: 1,
      size: 1000,
    },
  },
  {
    category: e_actionDetailsListCategory.PROFILE,
    description: 'Open profile page',
    enum: SliderActionsEnum.OPEN_PROFILE_PAGE,
    is_external_link: false,
    is_internal_link: true,
    is_employee: false,
    is_employer: false,
    is_common: true,
    is_string_payload: false,
    is_integer_payload: false,
    is_link_payload: false,
    query: undefined,
  },
  {
    category: e_actionDetailsListCategory.PROFILE,
    description: 'Open Blue Tick Page',
    enum: SliderActionsEnum.OPEN_PROFILE_BLUE_TICK_PAGE,
    is_external_link: false,
    is_internal_link: true,
    is_employee: false,
    is_employer: true,
    is_common: false,
    is_string_payload: false,
    is_integer_payload: false,
    is_link_payload: false,
    query: undefined,
  },
];

export const DEFAULT_STEP = [
  {
    index: 1,
    error: false,
    percent: 0,
    pending: false,
    completed: false,
    fileName: '',
  },
  {
    index: 2,
    error: false,
    fileName: '',
    percent: 0,
    pending: false,
    completed: false,
  },
  {
    index: 3,
    error: false,
    fileName: '',
    percent: 0,
    pending: false,
    completed: false,
  },
];

export function isExpired(date?: string) {
  if (!date) return false;
  const todayDate = new Date();
  const parsedDate = new Date(date).getTime();
  const todayToTurkeyDate = todayDate.setUTCHours(todayDate.getUTCHours() + 3);
  const todayTurkeyTime = new Date(todayToTurkeyDate).getTime();
  return todayTurkeyTime > parsedDate;
}

export function addTimeToDate(time: string, date: string) {
  if (time) {
    const splitTime = time.split(':');
    const hour = parseInt(splitTime[0]) < 10 ? `0${parseInt(splitTime[0])}` : splitTime[0];
    const min =
      parseInt(splitTime[1]) < 10 && parseInt(splitTime[1]) !== 0 ? `0${parseInt(splitTime[1])}` : splitTime[1];
    const splitDate = date.includes('T') ? date.split('T')[0].split('-') : date.split('-');
    const year = parseInt(splitDate[0]);
    const month = parseInt(splitDate[1]) < 10 ? `0${parseInt(splitDate[1])}` : parseInt(splitDate[1]);
    const day = parseInt(splitDate[2]) < 10 ? `0${parseInt(splitDate[2])}` : parseInt(splitDate[2]);
    return `${year}-${month}-${day}T${hour}:${min}:00`;
  }
  return date;
}
export const DEFAULT_START_TIME = '00:00';
export const DEFAULT_END_TIME = '23:59';

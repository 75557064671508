import React, { CSSProperties, FC, Fragment, memo, useEffect, useState } from 'react';
import Styled from 'styled-components';
import { t_toggleValueFunc, useToggle, useUpdateEffect } from 'hooks';
import { Button, Label } from 'reactstrap';
import Icon from 'components/icons';
import { FormCheck } from 'react-bootstrap';
import { t_emlakCategory, t_emlakCategoryChild } from 'pages/content-management/emlak/categories/type';
import { detail } from 'tools/i18n/constants/i18n';
import { useTranslation } from 'react-i18next';
import DEFAULT_ICON from 'assets/images/buluro/icon/wallet.svg';

interface ITreeCommonProps {
  defaultExpandedIds?: number[];
  onSelect: (node: t_emlakCategory | t_emlakCategoryChild) => void;
  isMultiSelect?: boolean;
  checkbox?: boolean;
  detailButton?: boolean;
  onDetailButton?: (node: t_emlakCategory | t_emlakCategoryChild) => void;
  itemHeight?: string;
}

const TreeContainer = Styled.div``;

interface EmlakTypeTreeProps extends ITreeCommonProps {
  data: t_emlakCategory[];
  disabled?: boolean;
  style?: CSSProperties;
}
function EmlakCategoryTree(props: EmlakTypeTreeProps) {
  const { data, onSelect, defaultExpandedIds, isMultiSelect, style, checkbox, onDetailButton, detailButton } = props;
  const [focus] = useToggle(true);
  const [selectedNode, setSelectedNode] = useState<t_emlakCategory | t_emlakCategoryChild | undefined>(undefined);
  /* const handleFocus = () => {
    toggleFocus(true);
  };*/

  const handleSelect = (node: t_emlakCategory | t_emlakCategoryChild) => {
    if (!isMultiSelect) {
      setSelectedNode(node);
      onSelect(node);
    } else {
      onSelect(node);
    }
  };
  return (
    <TreeContainer style={style}>
      {/* <div>
        <Input disabled={disabled} aria-disabled={disabled} onFocus={handleFocus} />
      </div>*/}
      {focus && (
        <div className="px-3 bg-white rounded-md py-2 mt-2">
          {data?.map((tree) => {
            return (
              <Fragment key={tree.id}>
                <TreeItemContainer
                  selectedNode={selectedNode}
                  onSelect={handleSelect}
                  onDetailButton={onDetailButton}
                  checkbox={checkbox}
                  detailButton={detailButton}
                  isMultiSelect={isMultiSelect}
                  item={tree}
                  defaultExpandedIds={defaultExpandedIds}
                />
              </Fragment>
            );
          })}
        </div>
      )}
    </TreeContainer>
  );
}

export default memo(EmlakCategoryTree);

const StyledTreeItemContainer = Styled.div<{ isExpanded: boolean; itemHeight?: string; bordered?: boolean }>`
  max-height : ${(p) => (p.isExpanded ? 'auto' : p.itemHeight || '35px')}
  height : ${(p) => p?.itemHeight}
`;
const StyledCheckbox = Styled(FormCheck)`
  input {
  margin : 0;
    width : 20px;
    height : 20px;  
  }
`;

interface TreeItemContainerProps extends ITreeCommonProps {
  item: t_emlakCategory;
  selectedNode: t_emlakCategoryChild | t_emlakCategory | undefined;
}
// eslint-disable-next-line react/display-name
const TreeItemContainer: FC<TreeItemContainerProps> = memo((props) => {
  const {
    item,
    itemHeight,
    defaultExpandedIds,
    onSelect,
    isMultiSelect,
    selectedNode,
    checkbox,
    onDetailButton,
    detailButton,
  } = props;
  const { t } = useTranslation();

  const [expanded, toggleExpanded] = useToggle(false);
  const [checked, toggleChecked] = useToggle(false);
  const handleToggleExpanded = () => {
    toggleExpanded();
  };
  const handleClick = () => {
    onSelect(item);
    toggleChecked((p) => !p);
  };
  const handleFindIncludedChild = (type: t_emlakCategoryChild) => {
    type.child.forEach((i) => {
      if (defaultExpandedIds?.includes(i.id)) toggleExpanded(true);
      else if (i.child) handleFindIncludedChild(i);
    });
  };
  useEffect(() => {
    if (defaultExpandedIds) {
      const isIncludes = defaultExpandedIds.includes(item.id);
      console.log('item :', item.id, 'includes : ', isIncludes);
      if (isIncludes) {
        toggleExpanded(true);
        toggleChecked(true);
      } else {
        if (item.child) {
          item.child.forEach((i) => {
            if (defaultExpandedIds.includes(i.id)) toggleExpanded(true);
            else if (i.child) handleFindIncludedChild(i);
          });
        }
      }
    }
  }, [defaultExpandedIds]);
  const haveChild = !!item?.child?.length;
  useUpdateEffect(() => {
    if (!isMultiSelect) {
      if (selectedNode?.id !== item?.id) {
        toggleChecked(false);
      }
    }
  }, [selectedNode?.id]);
  return (
    <StyledTreeItemContainer
      className={`p-2 ${checkbox ? '' : 'border-bottom border-gray'}`}
      isExpanded={expanded}
      itemHeight={itemHeight}
    >
      <div className="d-flex align-items-center">
        <div className="mr-2" role="button" onClick={handleToggleExpanded}>
          <Icon Name={expanded ? 'FiChevronDown' : 'FiChevronRight'} size="15px" />
        </div>
        <div
          role="button"
          className="mr-3 have-cover make-center position-relative d-flex align-items-center"
          onClick={handleClick}
        >
          {checkbox ? (
            <StyledCheckbox
              className="user-unselect m-0"
              readOnly
              aria-readonly
              checked={checked}
              defaultChecked={checked}
              role="button"
              id={item.id.toString()}
            />
          ) : (
            <img src={item?.icon || DEFAULT_ICON} alt={item.title} width="30px" height="30px" />
          )}
        </div>
        <div
          className={`${checkbox ? '' : 'flex-grow-1 w-100'} ${
            detailButton ? 'd-flex align-items-center justify-content-between' : ''
          }`}
        >
          <Label className="mb-0" htmlFor={item.id.toString()}>
            {item.title || 'label'}
          </Label>
          {detailButton && (
            <Button className="px-3 py-1" color="primary" onClick={() => onDetailButton?.(item)}>
              {t(detail)}
            </Button>
          )}
        </div>
      </div>
      {haveChild && expanded && (
        <>
          {item.child?.map((child, index) => {
            return (
              <Fragment key={child.id}>
                <TreeItemChildContainer
                  parentToggleExpand={toggleExpanded}
                  onSelect={onSelect}
                  item={child}
                  index={index}
                  defaultExpandedIds={defaultExpandedIds}
                  isMultiSelect={isMultiSelect}
                  selectedNode={selectedNode}
                  checkbox={checkbox}
                  onDetailButton={onDetailButton}
                  detailButton={detailButton}
                  itemHeight={itemHeight}
                />
              </Fragment>
            );
          })}
        </>
      )}
    </StyledTreeItemContainer>
  );
});

interface TreeItemChildContainerProps extends ITreeCommonProps {
  item: t_emlakCategoryChild;
  index: number;
  parentToggleExpand: t_toggleValueFunc;
  selectedNode: t_emlakCategoryChild | t_emlakCategory | undefined;
}

const StyledChildContainer = Styled.div<{ childIndex: number }>`
  margin-left : ${(p) => `${p.childIndex + 10}px`};
  margin-top : 15px;
`;

// eslint-disable-next-line react/display-name
const TreeItemChildContainer: FC<TreeItemChildContainerProps> = memo((props) => {
  const {
    item,
    index,
    defaultExpandedIds,
    parentToggleExpand,
    onSelect,
    selectedNode,
    isMultiSelect,
    checkbox,
    onDetailButton,
    detailButton,
  } = props;
  const { t } = useTranslation();
  const [expanded, toggleExpanded] = useToggle(false);
  const [checked, toggleChecked] = useToggle(false);
  const handleToggleExpanded = () => {
    toggleExpanded();
  };

  useEffect(() => {
    if (defaultExpandedIds) {
      const isIncludes = defaultExpandedIds.includes(item.id);
      if (isIncludes) {
        parentToggleExpand(true);
        toggleExpanded(true);
        toggleChecked(true);
      }
    }
  }, [defaultExpandedIds]);
  useEffect(() => {
    if (expanded) {
      parentToggleExpand(true);
    }
  }, [expanded]);
  useUpdateEffect(() => {
    if (!isMultiSelect) {
      if (selectedNode?.id !== item?.id) {
        toggleChecked(false);
        toggleExpanded(false);
      } else {
        toggleChecked(true);
        toggleExpanded(true);
      }
    }
  }, [selectedNode?.id]);
  const haveChild = !!item.child?.length;
  return (
    <StyledChildContainer childIndex={index}>
      <div className="d-flex align-items-center">
        <div className="mr-2" role="button" onClick={handleToggleExpanded}>
          <Icon Name={expanded ? 'FiChevronDown' : 'FiChevronRight'} size="15px" />
        </div>
        <div
          role="button"
          className="mr-3 have-cover make-center position-relative d-flex align-items-center"
          onClick={() => {
            onSelect(item);
            toggleChecked((prev) => !prev);
          }}
        >
          {checkbox ? (
            <StyledCheckbox
              className="user-unselect m-0"
              readOnly
              aria-readonly
              checked={checked}
              defaultChecked={checked}
              role="button"
              id={item.id.toString()}
            />
          ) : (
            <img src={item?.icon || DEFAULT_ICON} alt={item.title} width="30px" height="30px" />
          )}
        </div>
        <div
          className={`${checkbox ? '' : 'flex-grow-1 w-100'} ${
            detailButton ? 'd-flex align-items-center justify-content-between' : ''
          }`}
        >
          <Label className="mb-0" htmlFor={item.id.toString()}>
            {item.title || 'label'}
          </Label>
          {detailButton && (
            <Button color="primary" className="px-3 py-1" onClick={() => onDetailButton?.(item)}>
              {t(detail)}
            </Button>
          )}
        </div>
      </div>
      {haveChild && expanded && (
        <>
          {item.child?.map((child, index) => {
            return (
              <Fragment key={child.id}>
                <TreeItemChildContainer
                  parentToggleExpand={parentToggleExpand}
                  onSelect={onSelect}
                  item={child}
                  index={index}
                  selectedNode={selectedNode}
                  isMultiSelect={isMultiSelect}
                  defaultExpandedIds={defaultExpandedIds}
                  detailButton={detailButton}
                  onDetailButton={onDetailButton}
                  checkbox={checkbox}
                />
              </Fragment>
            );
          })}
        </>
      )}
    </StyledChildContainer>
  );
});

const actions = {
  GET_TREE_DATA: 'GET_TREE_DATA',
  CLEAR_TREE_DATA: 'CLEAR_TREE_DATA',
  SELECTED_NODE: 'SELECTED_NODE',
  SECTION_NAME: 'SECTION_NAME',
};

export type t_userActions = keyof typeof actions;

export default actions;

import { APP_LINK_KEY, APP_ROUTE_NAME, APP_ROUTE_PARAM_NAME, ROUTE_PREFIXES, SCHOOL_APP_ROUTE_NAME } from 'tools/enums';
import { t_dashboardAppLinkKey, t_dashboardAppRouteObjKey, t_schoolDashboardAppRouteObjKey } from 'tools/types';

/*export const PRIVATE_APP_ROUTES = {
  login: `login`,
  forgotPassword: `forgotPassword`,
};*/
const D = '/';
const DUM = `${ROUTE_PREFIXES.userManagement}`;
const DCM = `${ROUTE_PREFIXES.contentManagement}`;
const DCME = `${ROUTE_PREFIXES.contentManagement}/${ROUTE_PREFIXES.employment}`;
const TYPE = APP_ROUTE_PARAM_NAME.type; // first parameter
const ID = APP_ROUTE_PARAM_NAME.id; // second parameter
//const SECTION = APP_ROUTE_PARAM_NAME.section;
export const DASHBOARD_APP_ROUTES: t_dashboardAppRouteObjKey = {
  dashboard: D,
  //users management
  admins: `${DUM}/${APP_ROUTE_NAME.admins}`,
  adminDetail: `${DUM}/${APP_ROUTE_NAME.adminDetail}/:${TYPE}`,
  users: `${DUM}/${APP_ROUTE_NAME.users}`,
  deletedUsers: `${DUM}/${APP_ROUTE_NAME.deletedUsers}`,
  deleteReasons: `${DUM}/${APP_ROUTE_NAME.deleteReasons}`,
  deleteReasonsDetail: `${DUM}/${APP_ROUTE_NAME.deleteReasonsDetail}/:${TYPE}`,
  banReasons: `${DUM}/${APP_ROUTE_NAME.banReasons}`,
  banReasonDetail: `${DUM}/${APP_ROUTE_NAME.banReasonDetail}/:${TYPE}`,
  usersDetails: `${DUM}/${APP_ROUTE_NAME.admins}/:${ID}`,
  employer: `${DUM}/${APP_ROUTE_NAME.users}/${APP_ROUTE_NAME.employer}`,
  employee: `${DUM}/${APP_ROUTE_NAME.users}/${APP_ROUTE_NAME.employee}`,

  employeeDetail: `${DUM}/${APP_ROUTE_NAME.users}/${APP_ROUTE_NAME.employee}/:${TYPE}`,
  // content management re usable Routes

  // content management

  // employment
  jobCategories: `${DCME}/${APP_ROUTE_NAME.jobCategories}`,
  jobCategoryDetails: `${DCME}/${APP_ROUTE_NAME.jobCategoryDetails}/:${TYPE}`,

  experienceExpectation: `${DCME}/${APP_ROUTE_NAME.experienceExpectation}`,
  experienceExpectationDetails: `${DCME}/${APP_ROUTE_NAME.experienceExpectationDetails}/:${TYPE}`,

  workRejectionReasons: `${DCME}/${APP_ROUTE_NAME.workRejectionReasons}`,
  workRejectionReasonsDetails: `${DCME}/${APP_ROUTE_NAME.workRejectionReasonsDetails}/:${TYPE}`,

  ageRange: `${DCME}/${APP_ROUTE_NAME.ageRange}`,
  ageRangeDetail: `${DCME}/${APP_ROUTE_NAME.ageRangeDetail}/:${TYPE}`,

  universities: `${DCME}/${APP_ROUTE_NAME.universities}`,
  universitiesDetails: `${DCME}/${APP_ROUTE_NAME.universitiesDetails}/:${TYPE}`,

  universitySections: `${DCME}/${APP_ROUTE_NAME.universitySections}`,
  universitySectionsDetails: `${DCME}/${APP_ROUTE_NAME.universitySectionsDetails}/:${TYPE}`,

  softwares: `${DCME}/${APP_ROUTE_NAME.softwares}`,
  softwaresDetails: `${DCME}/${APP_ROUTE_NAME.softwaresDetails}/:${TYPE}`,

  languages: `${DCME}/${APP_ROUTE_NAME.languages}`,
  languagesDetails: `${DCME}/${APP_ROUTE_NAME.languagesDetails}/:${TYPE}`,

  // end employment

  // companies
  companies: `${DCM}/${APP_ROUTE_NAME.companies}`,
  companiesDetails: `${DCM}/${APP_ROUTE_NAME.companiesDetails}/:${TYPE}`,

  companyCategories: `${DCM}/${APP_ROUTE_NAME.companyCategories}`,
  companyCategoriesV2: `${DCM}/${APP_ROUTE_NAME.companyCategoriesV2}`,
  companyCategoryDetail: `${DCM}/${APP_ROUTE_NAME.companyCategoryDetail}/:${TYPE}`,
  companyCategoryDetailV2: `${DCM}/${APP_ROUTE_NAME.companyCategoryDetailV2}/:${TYPE}`,

  companySizes: `${DCM}/${APP_ROUTE_NAME.companySizes}`,
  companySizeDetail: `${DCM}/${APP_ROUTE_NAME.companySizeDetail}/:${TYPE}`,

  companyLogos: `${DCM}/${APP_ROUTE_NAME.companyLogos}`,

  documentTypes: `${DCM}/${APP_ROUTE_NAME.documentTypes}`,
  documentTypeDetail: `${DCM}/${APP_ROUTE_NAME.documentTypeDetail}/:${TYPE}`,
  documentRejectionReasons: `${DCM}/${APP_ROUTE_NAME.documentRejectionReasons}`,
  documentRejectionReasonsDetail: `${DCM}/${APP_ROUTE_NAME.documentRejectionReasonsDetail}/:${TYPE}`,

  companyLeaderReasons: `${DCM}/${APP_ROUTE_NAME.companyLeaderReasons}`,
  companyLeaderReasonsDetail: `${DCM}/${APP_ROUTE_NAME.companyLeaderReasonsDetail}/:${TYPE}`,
  // end companies

  // bazaar
  bazaarCategories: `${DCM}/${APP_ROUTE_NAME.bazaarCategories}`,
  bazaarCategoriesDetails: `${DCM}/${APP_ROUTE_NAME.bazaarCategoriesDetails}/:${TYPE}`,
  // end bazaar

  // slider
  slider: `${DCM}/${APP_ROUTE_NAME.slider}`,
  sliderDetail: `${DCM}/${APP_ROUTE_NAME.sliderDetail}/:${TYPE}`,
  // end slider

  // emlak
  specialLocation: APP_ROUTE_NAME.specialLocation,
  specialLocationDetail: `${APP_ROUTE_NAME.specialLocationDetail}/:${TYPE}`,

  emlakTypes: APP_ROUTE_NAME.emlakTypes,
  emlakTypesDetails: `${APP_ROUTE_NAME.emlakTypesDetails}/:${TYPE}`,

  emlakCategories: `${APP_ROUTE_NAME.emlakCategories}`,
  emlakCategoryDetail: `${APP_ROUTE_NAME.emlakCategoryDetail}/:${TYPE}`,

  emlakAds: `${APP_ROUTE_NAME.emlakAds}`,
  emlakAdsDetail: `${APP_ROUTE_NAME.emlakAdsDetail}/:${TYPE}`,

  emlakAdsReportReasons: `${APP_ROUTE_NAME.emlakAdsReportReasons}`,
  emlakAdsReportReasonsDetail: `${APP_ROUTE_NAME.emlakAdsReportReasonsDetail}/:${TYPE}`,
  // end emlak

  // fuar

  // exhibition
  exhibition: APP_ROUTE_NAME.exhibition,
  exhibitionDetails: `${APP_ROUTE_NAME.exhibitionDetails}/:${TYPE}`,
  // end exhibition

  // hotel
  hotel: `${APP_ROUTE_NAME.hotel}`,
  hotelDetail: `${APP_ROUTE_NAME.hotelDetail}/:${TYPE}`,
  // end hotel

  // location
  location: `${APP_ROUTE_NAME.location}`,
  locationDetail: `${APP_ROUTE_NAME.locationDetail}/:${TYPE}`,
  locationCity: `${APP_ROUTE_NAME.location}/:${TYPE}/:${APP_ROUTE_PARAM_NAME.cityType}`,
  // end location

  // address
  address: `${DCM}/${APP_ROUTE_NAME.address}`,
  // end address

  // end fuar

  // analytics
  analytics: APP_ROUTE_NAME.analytics,
  //end analytics

  // school
  schools: `${APP_ROUTE_NAME.schools}`,
  schoolDetail: `${APP_ROUTE_NAME.schoolDetail}/:${TYPE}`,
  // end school

  // ads
  jobAdsDetail: `${APP_ROUTE_NAME.jobAdsDetail}/:${TYPE}`,
  jobApplyListDetails: `${APP_ROUTE_NAME.jobApplyListDetails}/:${TYPE}/:${ID}`,
  bazaarAdsReport: `${APP_ROUTE_NAME.bazaarAdsReport}`,
  bazaarAdsReportDetail: `${APP_ROUTE_NAME.bazaarAdsReportDetail}/:${TYPE}`,
  jobAdsReport: `${APP_ROUTE_NAME.jobAdsReport}`,
  jobAdsReportDetail: `${APP_ROUTE_NAME.jobAdsReportDetail}/:${TYPE}`,
  bazzarAds: `${APP_ROUTE_NAME.bazzarAds}`,
  jobAds: `${APP_ROUTE_NAME.jobAds}`,
  bazzarAdsApplyList: `${APP_ROUTE_NAME.bazzarAdsApplyList}/:${TYPE} }/:${ID}`,
  bazzarAdsDetail: `${APP_ROUTE_NAME.bazzarAdsDetail}/:${TYPE}`,
  // end ads

  // magazine
  magazine: `${APP_ROUTE_NAME.magazine}`,
  magazineDetail: `${APP_ROUTE_NAME.magazineDetail}/:${TYPE}`,
  magazineDetailEdition: `${APP_ROUTE_NAME.magazineDetailEdition}/:${TYPE}/:${ID}`,
  //end magazine

  // notification
  notification: APP_ROUTE_NAME.notification,
  notificationDetail: `${APP_ROUTE_NAME.notificationDetail}/:${TYPE}`,
  // end notification

  // exchange
  holidyManagement: APP_ROUTE_NAME.holidyManagement,
  // end exchange

  // setting
  setting: APP_ROUTE_NAME.setting,
  mobileAppOsVersions: APP_ROUTE_NAME.mobileAppOsVersions,
  // end setting

  logOut: APP_ROUTE_NAME.logOut,
};

export const SCHOOL_ADMIN_APP_ROUTES: t_schoolDashboardAppRouteObjKey = {
  [SCHOOL_APP_ROUTE_NAME.schoolAdmin]: D,
  [SCHOOL_APP_ROUTE_NAME.studentManagement]: SCHOOL_APP_ROUTE_NAME.studentManagement,
  [SCHOOL_APP_ROUTE_NAME.studentDetail]: `${SCHOOL_APP_ROUTE_NAME.studentManagement}/:${TYPE}`,
  [SCHOOL_APP_ROUTE_NAME.analytics]: SCHOOL_APP_ROUTE_NAME.analytics,
};
/*export const SUPER_ADMIN_APP_ROUTES: t_superDashboardAppRouteObjKey = {
  dashboard: D,
};*/
export const DASHBOARD_APP_LINK: t_dashboardAppLinkKey = {
  dashboard: () => D,
  new: (param) => (param ? `${APP_LINK_KEY.new}/${param}` : APP_LINK_KEY.new),
  edit: (arg) => (arg ? `${arg}${APP_LINK_KEY.edit}` : APP_LINK_KEY.edit),
  delete: (section_id) => (section_id ? `${APP_LINK_KEY.delete}/${section_id}` : APP_LINK_KEY.delete),
  // user management
  admins: (arg) => (arg ? `${arg}${APP_ROUTE_NAME.dashboard}/${DUM}/${APP_ROUTE_NAME.admins}` : APP_ROUTE_NAME.admins),
  adminDetail: (arg) => (arg ? arg : APP_ROUTE_NAME.adminDetail),
  users: () => APP_ROUTE_NAME.users,
  deletedUsers: () => APP_ROUTE_NAME.deletedUsers,
  deleteReasons: () => APP_ROUTE_NAME.deleteReasons,
  deleteReasonsDetail: (arg) => (arg ? arg : APP_ROUTE_NAME.deleteReasonsDetail),
  banReasons: () => APP_ROUTE_NAME.banReasons,
  banReasonDetail: (arg) => (arg ? arg : APP_ROUTE_NAME.banReasonDetail),
  usersDetails: (id: string) => id,
  employee: () => APP_ROUTE_NAME.employee,
  employer: () => APP_ROUTE_NAME.employer,
  employeeDetail: (arg) => (arg ? `${APP_ROUTE_NAME.employee}/${arg}` : APP_ROUTE_NAME.employee),
  // end user management

  // content management

  // employment
  jobCategories: (arg) => (arg ? `${arg}${APP_ROUTE_NAME.jobCategories}` : APP_ROUTE_NAME.jobCategories),
  jobCategoryDetails: (arg) => (arg ? `${arg}` : APP_ROUTE_NAME.jobCategoryDetails),
  universitySections: (arg) => (arg ? `${arg}${APP_ROUTE_NAME.universitySections}` : APP_ROUTE_NAME.universitySections),
  universitySectionsDetails: (arg) => (arg ? `${arg}` : APP_ROUTE_NAME.universitySectionsDetails),
  universities: (arg) => (arg ? `${arg}${APP_ROUTE_NAME}` : APP_ROUTE_NAME.universities),
  universitiesDetails: (arg) => (arg ? `${arg}` : APP_ROUTE_NAME.universitiesDetails),
  experienceExpectation: (arg) =>
    arg ? `${arg}${APP_ROUTE_NAME.experienceExpectation}` : APP_ROUTE_NAME.experienceExpectation,
  experienceExpectationDetails: (arg) => (arg ? `${arg}` : APP_ROUTE_NAME.experienceExpectationDetails),
  workRejectionReasons: (arg) =>
    arg ? `${arg}${APP_ROUTE_NAME.workRejectionReasons}` : APP_ROUTE_NAME.workRejectionReasons,
  workRejectionReasonsDetails: (arg) => (arg ? `${arg}` : APP_ROUTE_NAME.workRejectionReasonsDetails),
  ageRange: (arg) =>
    arg ? `/${APP_ROUTE_NAME.dashboard}/${DCME}/${APP_ROUTE_NAME.ageRange}` : APP_ROUTE_NAME.ageRange,
  ageRangeDetail: (arg) => (arg ? `${arg}` : APP_ROUTE_NAME.ageRange),
  softwares: (arg) => (arg ? `${arg}${APP_ROUTE_NAME.softwares}` : APP_ROUTE_NAME.softwares),
  softwaresDetails: (arg) => (arg ? `${arg}` : APP_ROUTE_NAME.softwaresDetails),
  languages: (arg) => (arg ? `${arg}${APP_ROUTE_NAME.languages}` : APP_ROUTE_NAME.languages),
  languagesDetails: (arg) => (arg ? `${arg}` : APP_ROUTE_NAME.languagesDetails),
  // end employment

  // companies
  companies: (arg) => (arg ? `${arg}${APP_ROUTE_NAME.companies}` : APP_ROUTE_NAME.companies),
  companiesDetails: (arg) => (arg ? arg : APP_ROUTE_NAME.companiesDetails),

  companySizes: (arg) =>
    arg ? `/${APP_ROUTE_NAME.dashboard}/${DCM}/${APP_ROUTE_NAME.companySizes}` : APP_ROUTE_NAME.companySizes,
  companySizeDetail: (arg) => (arg ? arg : APP_ROUTE_NAME.companySizeDetail),

  companyCategories: (arg) => (arg ? `${arg}${APP_ROUTE_NAME.companyCategories}` : APP_ROUTE_NAME.companyCategories),
  companyCategoriesV2: (arg) =>
    arg ? `${arg}${APP_ROUTE_NAME.companyCategoriesV2}` : APP_ROUTE_NAME.companyCategoriesV2,
  companyCategoryDetail: (arg) => (arg ? arg : APP_ROUTE_NAME.companyCategoryDetail),
  companyCategoryDetailV2: (arg) => (arg ? arg : APP_ROUTE_NAME.companyCategoryDetailV2),

  documentTypes: (arg) =>
    arg ? `/${APP_ROUTE_NAME.dashboard}/${DCM}/${APP_ROUTE_NAME.documentTypes}` : APP_ROUTE_NAME.documentTypes,
  documentTypeDetail: (arg) => (arg ? arg : APP_ROUTE_NAME.documentTypeDetail),
  companyLogos: (arg) => (arg ? `${arg}${APP_ROUTE_NAME.companyLogos}` : APP_ROUTE_NAME.companyLogos),

  documentRejectionReasons: (arg) =>
    arg
      ? `/${APP_ROUTE_NAME.documentRejectionReasons}/${DCM}/${APP_ROUTE_NAME.documentRejectionReasons}`
      : APP_ROUTE_NAME.documentRejectionReasons,
  documentRejectionReasonsDetail: (arg) => (arg ? arg : APP_ROUTE_NAME.documentRejectionReasonsDetail),

  companyLeaderReasons: (arg) =>
    arg
      ? `/${APP_ROUTE_NAME.companyLeaderReasons}/${DCM}/${APP_ROUTE_NAME.companyLeaderReasons}`
      : APP_ROUTE_NAME.companyLeaderReasons,
  companyLeaderReasonsDetail: (arg) => (arg ? arg : APP_ROUTE_NAME.companyLeaderReasonsDetail),
  // end company

  // bazaar
  bazaarCategories: (arg) => (arg ? `${arg}${APP_ROUTE_NAME.bazaarCategories}` : APP_ROUTE_NAME.bazaarCategories),
  bazaarCategoriesDetails: (arg) => (arg ? arg : APP_ROUTE_NAME.bazaarCategoriesDetails),
  // end bazaar

  // slider
  slider: (arg) =>
    arg
      ? `/${APP_ROUTE_NAME.dashboard}/${ROUTE_PREFIXES.contentManagement}${arg}${APP_ROUTE_NAME.slider}`
      : APP_ROUTE_NAME.slider,
  sliderDetail: (arg) => (arg ? arg : APP_ROUTE_NAME.sliderDetail),
  // end slider

  // emlak
  specialLocation: (arg) =>
    arg ? `/${APP_ROUTE_NAME.dashboard}${arg}${APP_ROUTE_NAME.specialLocation}` : APP_ROUTE_NAME.specialLocation,
  specialLocationDetail: (arg) => (arg ? arg : APP_ROUTE_NAME.specialLocationDetail),
  emlakTypes: (arg) =>
    arg ? `/${APP_ROUTE_NAME.dashboard}${arg}${APP_ROUTE_NAME.emlakTypes}` : APP_ROUTE_NAME.emlakTypes,
  emlakTypesDetails: (arg) => (arg ? arg : APP_ROUTE_NAME.emlakTypesDetails),
  emlakCategories: (arg) =>
    arg ? `/${APP_ROUTE_NAME.dashboard}${arg}${APP_ROUTE_NAME.emlakCategories}` : APP_ROUTE_NAME.emlakCategories,
  emlakCategoryDetail: (arg) => (arg ? arg : APP_ROUTE_NAME.emlakCategoryDetail),
  emlakAds: (arg) => (arg ? `/${APP_ROUTE_NAME.dashboard}${arg}${APP_ROUTE_NAME.emlakAds}` : APP_ROUTE_NAME.emlakAds),
  emlakAdsDetail: (arg) => (arg ? arg : APP_ROUTE_NAME.emlakAdsDetail),
  emlakAdsReportReasons: (arg) =>
    arg
      ? `/${APP_ROUTE_NAME.dashboard}${arg}${APP_ROUTE_NAME.emlakAdsReportReasons}`
      : APP_ROUTE_NAME.emlakAdsReportReasons,
  emlakAdsReportReasonsDetail: (arg) => (arg ? arg : APP_ROUTE_NAME.emlakAdsReportReasonsDetail),
  // end emlak

  // end content management

  // fuar
  exhibition: (arg) =>
    arg ? `${arg}${APP_ROUTE_NAME.dashboard}/${APP_ROUTE_NAME.exhibition}` : APP_ROUTE_NAME.exhibition,
  exhibitionDetails: (arg) => (arg ? arg : APP_ROUTE_NAME.exhibitionDetails),
  hotelDetail: (arg) => (arg ? arg : APP_ROUTE_NAME.hotelDetail),
  locationDetail: (arg) => (arg ? arg : APP_ROUTE_NAME.locationDetail),
  locationCity: (arg) => (arg ? arg : APP_ROUTE_NAME.locationCity),
  address: (arg) => (arg ? `${arg}${APP_ROUTE_NAME.address}` : APP_ROUTE_NAME.address),
  // end fuar

  // analytics
  analytics: (arg) => (arg ? `${arg}${APP_ROUTE_NAME.analytics}` : APP_ROUTE_NAME.analytics),

  // end analytics

  // schools
  schools: (arg) => (arg ? `${arg}${APP_ROUTE_NAME.dashboard}/${APP_ROUTE_NAME.schools}` : APP_ROUTE_NAME.schools),
  schoolDetail: (id: string) => id,
  // end schools

  // ads
  bazzarAds: (arg) =>
    arg ? `/${APP_ROUTE_NAME.dashboard}${arg}${APP_ROUTE_NAME.bazzarAds}` : APP_ROUTE_NAME.bazzarAds,
  jobAdsDetail: (arg) => (arg ? `${arg}${APP_ROUTE_NAME.jobAdsDetail}` : APP_ROUTE_NAME.jobAdsDetail),
  jobAds: (arg) => (arg ? `/${APP_ROUTE_NAME.dashboard}${arg}${APP_ROUTE_NAME.jobAds}` : APP_ROUTE_NAME.jobAds),
  jobAdsReport: (arg) =>
    arg ? `/${APP_ROUTE_NAME.dashboard}${arg}${APP_ROUTE_NAME.jobAdsReport}` : APP_ROUTE_NAME.jobAdsReport,
  jobAdsReportDetail: (arg) => (arg ? `${arg}${APP_ROUTE_NAME.jobAdsReportDetail}` : APP_ROUTE_NAME.jobAdsReportDetail),
  bazaarAdsReportDetail: (arg) =>
    arg ? `${arg}${APP_ROUTE_NAME.bazaarAdsReportDetail}` : APP_ROUTE_NAME.bazaarAdsReportDetail,
  bazaarAdsReport: (arg) =>
    arg ? `/${APP_ROUTE_NAME.dashboard}${arg}${APP_ROUTE_NAME.bazaarAdsReport}` : APP_ROUTE_NAME.bazaarAdsReport,

  jobApplyListDetails: (arg) =>
    arg ? `${arg}${APP_ROUTE_NAME.jobApplyListDetails}` : APP_ROUTE_NAME.jobApplyListDetails,
  bazzarAdsDetail: (arg) => (arg ? `${arg}` : APP_ROUTE_NAME.bazzarAdsDetail),
  bazzarAdsApplyList: (arg) =>
    arg ? `${arg}${APP_ROUTE_NAME.dashboard}/${APP_ROUTE_NAME.bazzarAdsApplyList}` : APP_ROUTE_NAME.bazzarAdsApplyList,
  // end ads

  // magazine
  magazine: (arg) => (arg ? `${arg}${APP_ROUTE_NAME.dashboard}/${APP_ROUTE_NAME.magazine}` : APP_ROUTE_NAME.magazine),
  magazineDetail: (arg) => (arg ? arg : APP_ROUTE_NAME.magazineDetail),
  magazineDetailEdition: (arg) => (arg ? `${arg}` : APP_ROUTE_NAME.magazineDetailEdition),
  // end magazine

  // notification
  notification: (arg) =>
    arg ? `${arg}${APP_ROUTE_NAME.dashboard}/${APP_ROUTE_NAME.notification}` : APP_ROUTE_NAME.notification,
  notificationDetail: (arg) => (arg ? arg : APP_ROUTE_NAME.notificationDetail),
  // end notification

  // exchange
  holidyManagement: (arg) =>
    arg ? `${arg}${APP_ROUTE_NAME.dashboard}/${APP_ROUTE_NAME.holidyManagement}` : APP_ROUTE_NAME.holidyManagement,
  // end exchange

  // setting
  mobileAppOsVersions: () => APP_ROUTE_NAME.mobileAppOsVersions,
  setting: (arg) => (arg ? `${arg}${APP_ROUTE_NAME.setting}` : APP_ROUTE_NAME.setting),
  // end setting

  location: (arg) => (arg ? `${arg}${APP_ROUTE_NAME.dashboard}/${APP_ROUTE_NAME.location}` : APP_ROUTE_NAME.location),
  hotel: (arg) => (arg ? `${arg}${APP_ROUTE_NAME.dashboard}/${APP_ROUTE_NAME.hotel}` : APP_ROUTE_NAME.hotel),
  // school admin dashboard
  school: (arg) => (arg ? `${APP_LINK_KEY.school}` : APP_LINK_KEY.school),
  studentManagement: (arg) =>
    arg
      ? `${arg}${SCHOOL_ADMIN_APP_ROUTES.schoolAdmin}/${SCHOOL_ADMIN_APP_ROUTES.studentManagement}`
      : SCHOOL_ADMIN_APP_ROUTES.studentManagement,
  studentDetail: (id: string) => id,
  schoolAdmin: (arg) => (arg ? `${arg}${SCHOOL_ADMIN_APP_ROUTES.schoolAdmin}` : SCHOOL_ADMIN_APP_ROUTES.schoolAdmin),

  logOut: (arg) => (arg ? `${arg}${APP_ROUTE_NAME.logOut}` : APP_ROUTE_NAME.logOut),
};

export type t_dashboardRouteKey = keyof typeof DASHBOARD_APP_ROUTES;
export type t_dashboardLinkKey = keyof typeof DASHBOARD_APP_LINK;

/*export function getDashboardRoutes(key: t_dashboardRouteKey) {
  return DASHBOARD_APP_ROUTES[key];
}*/
export function getDashboardLink(key: t_dashboardLinkKey, param?: string) {
  return DASHBOARD_APP_LINK?.[key]?.(param as string);
}

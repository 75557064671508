import SubmitButton from 'components/buttons/submitButton';
import { useToggle } from 'hooks';
import PagesLayout from 'pages/components/layout';
import React, { useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { LANGUAGES, LANGUAGES_DROPDOWN } from 'tools/constants';
import { Add } from 'tools/i18n/constants';
import {
  create,
  created,
  enter,
  jobAds,
  jobAdsReport,
  language,
  please,
  report,
  successfully,
  translation,
  update,
  updated,
} from 'tools/i18n/constants/i18n';
import { errorFinder, goBack } from 'tools/methods';
import { t_jobAdsReport } from './types';
import useJobAdsReportDetails from './useJobAdsReportDetails';

export default function JobAdsReportDetail() {
  const { selectedNode, createExecute, updateExecute, isNew } = useJobAdsReportDetails();
  const [loading, toggleLoading] = useToggle(false);
  const { t } = useTranslation();
  const DEFAULT_VALUES: t_jobAdsReport = useMemo(() => {
    return {
      _id: selectedNode?._id,
      originalText: selectedNode?.originalName,
      translations: selectedNode?.translations?.map((item: any) => ({
        language: item.language,
        translate: item.translate,
      })),
    };
  }, [selectedNode]);
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<t_jobAdsReport>({
    defaultValues: DEFAULT_VALUES,
  });
  const onSubmit: SubmitHandler<t_jobAdsReport> = (data) => {
    console.log(data);
    toggleLoading(true);
    setValue('customError', '');
    createExecute({
      variables: {
        translations: data.translations,
      },
    })
      .then((res) => {
        if (res.data.employmentCreateAdReportReason) {
          toast.success(`${t(successfully)} ${t(created)}`);
          goBack();
        } else {
          setValue('customError', errorFinder(res?.errors?.[0]?.message));
          toggleLoading(false);
        }
      })
      .catch((err) => {
        setValue('customError', errorFinder(err));
        toggleLoading(false);
      });
  };
  const onUpdate: SubmitHandler<t_jobAdsReport> = (data) => {
    console.log(data);
    setValue('customError', '');
    toggleLoading(true);
    updateExecute({
      variables: {
        id: data._id,
        translations: data.translations,
      },
    })
      .then((res) => {
        if (res.data.employmentUpdateAdReportReason) {
          toast.success(`${t(successfully)} ${t(updated)}`);
          goBack();
        } else {
          setValue('customError', errorFinder(res?.errors?.[0]?.message));
          toggleLoading(false);
        }
      })
      .catch((err) => {
        setValue('customError', errorFinder(err));
        toggleLoading(false);
      });
  };

  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <PagesLayout backLinkTitle={t(jobAdsReport)}>
        <Card>
          <CardHeader className="py-3">
            <div className="d-flex align-items-center justify-content-between">
              <h5>{`${t(isNew ? Add : update)} ${t(jobAds)} ${t(report)}`}</h5>
              {/* <Button color="danger">{t(remove)}</Button> */}
            </div>
          </CardHeader>
          <CardBody>
            <Row className="mx-auto">
              <Col xxl="12" className="mx-auto">
                <Form onSubmit={handleSubmit(isNew ? onSubmit : onUpdate, console.error)}>
                  {!!getValues('customError') && (
                    <div className="rounded my-2 shadow-bg-danger p-2">{getValues('customError')}</div>
                  )}

                  <div className="d-flex align-items justify-content-between flex-wrap bg-gray p-3">
                    <FormGroup className={`mr-3 p-4 flex-grow-1 rounded bg-gray position-relative mb-2`}>
                      <Controller
                        control={control}
                        defaultValue={LANGUAGES[0].value}
                        name="translations.0.language"
                        render={() => {
                          return (
                            <FormGroup>
                              <Label>{t(language)}</Label>
                              <Select
                                options={LANGUAGES_DROPDOWN}
                                defaultValue={{ label: LANGUAGES[0].label, value: LANGUAGES[0].value }}
                                isDisabled
                              />
                            </FormGroup>
                          );
                        }}
                      />
                      <FormGroup>
                        <Label>{`${t(report)} ${t(translation)}`}</Label>
                        <Controller
                          name="translations.0.translate"
                          defaultValue={DEFAULT_VALUES.translations?.[0]?.translate}
                          rules={{ required: `${t(please)} ${t(enter)} ${t(translation)}` }}
                          control={control}
                          render={({ field: { onChange } }) => {
                            return (
                              <Input
                                defaultValue={DEFAULT_VALUES.translations?.[0]?.translate}
                                onChange={onChange}
                                placeholder="Translate"
                              />
                            );
                          }}
                        />
                        <FormFeedback>{errors?.translations?.[0]?.translate?.message}</FormFeedback>
                      </FormGroup>
                    </FormGroup>

                    <FormGroup className={`mr-3 p-4 flex-grow-1 rounded bg-gray position-relative mb-2`}>
                      <Controller
                        control={control}
                        defaultValue={LANGUAGES[1].value}
                        name="translations.1.language"
                        render={() => {
                          return (
                            <FormGroup>
                              <Label>{t(language)}</Label>
                              <Select
                                options={LANGUAGES_DROPDOWN}
                                defaultValue={{ label: LANGUAGES[1].label, value: LANGUAGES[1].value }}
                                isDisabled
                              />
                            </FormGroup>
                          );
                        }}
                      />
                      <FormGroup>
                        <Label>{`${t(report)} ${t(translation)}`}</Label>
                        <Controller
                          name="translations.1.translate"
                          rules={{ required: `${t(please)} ${t(enter)} ${t(translation)}` }}
                          control={control}
                          defaultValue={DEFAULT_VALUES.translations?.[1]?.translate}
                          render={({ field: { onChange } }) => {
                            return (
                              <Input
                                defaultValue={DEFAULT_VALUES.translations?.[1]?.translate}
                                onChange={onChange}
                                placeholder={'Translate'}
                              />
                            );
                          }}
                        />
                        <FormFeedback>{errors?.translations?.[1]?.translate?.message}</FormFeedback>
                      </FormGroup>
                    </FormGroup>
                  </div>
                  <SubmitButton loading={loading}>{t(isNew ? create : update)}</SubmitButton>
                </Form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </PagesLayout>
    </Col>
  );
}

import { dataToTree } from 'components/tree-select-checkbox/utils';
import { useUpdateEffect } from 'hooks';
import useQuery from 'hooks/useQuery';
import { t_jobCategory } from 'pages/content-management/employment/jobCategories/types';
import { useMemo } from 'react';
import { GET_BAZZAR_CATEGORIES } from 'services/graphql/queries/bazzar';
import { d_getTreeData } from 'store/tree/tree.dispatchs';
import { isTrophy, toArray } from 'tools/methods';

export default function useBazaarCategory() {
  const { data, loading, error } = useQuery<{ findAllBazaarCategories: t_jobCategory[] }>(GET_BAZZAR_CATEGORIES, {
    fetchPolicy: 'no-cache',
  });
  const isListEmpty: boolean = useMemo(() => isTrophy(data?.findAllBazaarCategories?.length), [data]);
  const updatedData = useMemo(
    () =>
      isListEmpty
        ? dataToTree(
            toArray(data?.findAllBazaarCategories),
            {
              id: 'id',
              labelKey: 'title',
              iconKey: 'icon',
              childKey: 'child',
            },
            true
          )
        : [],
    [data, isListEmpty]
  );

  useUpdateEffect(() => {
    if (isTrophy(updatedData)) {
      d_getTreeData(updatedData);
    }
  }, [updatedData]);
  return { isListEmpty, data: updatedData, error, loading };
}

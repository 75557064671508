import React, { FC, ReactNode, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import {
  accept,
  cancel,
  confirm,
  date,
  description,
  empty,
  history,
  list,
  reject,
  request,
  status,
} from 'tools/i18n/constants/i18n';
import { useTranslation } from 'react-i18next';
import { APP_HELPER, COMPANY_REHBER_STATUS, COMPANY_REHBER_STATUS_UI } from 'tools/enums';
import Icon, { IconsName } from 'components/icons';
import { toDate } from 'tools/methods';
import { useToggle } from 'hooks';
import { BLUE_TICK_ACTION } from 'services/graphql/queries/companies';
import useMutation from 'hooks/useMutation';
import GuideStatusModalHandler from './guideStatusModal';
import { t_company } from 'tools/types/sectionTypes/company';

interface CompanyGuideComponentsProps<T extends boolean> {
  refetch: () => void;
  data: t_company;
  companyId: number;
}

export default function CompanyLeaderTab({
  refetch,
  data,
}: {
  data: t_company;
  loading: boolean;
  id: number;
  refetch: () => void;
}) {
  const { type } = useParams();
  return <CompanyGuideComponents refetch={refetch} data={data} companyId={Number(type)} />;
}

const CompanyGuideComponents: FC<CompanyGuideComponentsProps<boolean>> = (props) => {
  const { data, companyId, refetch } = props;

  const [toggleModal, modalToggler] = useToggle(false);
  const [modalStatus, setModalStatus] = useState<COMPANY_REHBER_STATUS | undefined>(undefined);
  const { t } = useTranslation();

  const Item =
    data?.guideStatus === COMPANY_REHBER_STATUS.ACCEPTED
      ? {
          icon: 'FiCheck',
          className: 'shadow-bg-active',
          textColor: 'shadow-text-active',
          text: COMPANY_REHBER_STATUS_UI.ACCEPTED,
        }
      : data?.guideStatus === COMPANY_REHBER_STATUS.REJECTED
      ? {
          icon: 'FiXOctagon',
          className: 'shadow-bg-danger',
          textColor: 'shadow-text-danger',
          text: COMPANY_REHBER_STATUS_UI.REJECTED,
        }
      : data?.guideStatus === COMPANY_REHBER_STATUS.PENDING
      ? {
          icon: 'FiLoader',
          className: 'shadow-bg-warning',
          textColor: 'shadow-text-warning',
          text: COMPANY_REHBER_STATUS_UI.PENDING,
        }
      : {
          icon: 'FiMinus',
          className: 'shadow-bg-notActive',
          textColor: 'shadow-text-notActive',
          text: COMPANY_REHBER_STATUS_UI.NONE,
        };

  const handleChangeModalStatus = () => {
    setModalStatus(COMPANY_REHBER_STATUS.REJECTED);
    modalToggler(true);
  };
  const handleChangeModalConfirm = () => {
    setModalStatus(COMPANY_REHBER_STATUS.ACCEPTED);
    modalToggler(true);
  };
  // const handleModalConfirm = (reason?: string) => {
  //   execute({
  //     variables: {
  //       id: parseInt(id as string),
  //       status: modalStatus,
  //       description: reason || '',
  //     },
  //   }).then((result) => {
  //     console.log(result);
  //     // setCurrentBlueTickStatus(modalStatus);
  //     modalToggler();
  //     refetch();
  //   });

  // };
  // useUpdateEffect(() => {}, [data]);
  return (
    <>
      {toggleModal && (
        <GuideStatusModalHandler
          toggleOpen={modalToggler}
          activeStatus={modalStatus}
          status={data?.guideStatus}
          isOpen={true}
          companyId={companyId}
          refetch={() => refetch()}
        />
      )}

      <Card>
        <CardHeader className="py-3">
          <div className="d-flex align-items-center gap-2">
            <h5>{t('Rehber Status')}</h5>
            {/* {data?.guideStatus || ''} */}
            <div className="d-flex align-items-center">
              <div
                style={{ width: '30px', height: '30px' }}
                className={`${Item.className} d-flex align-items-center justify-content-center p-1 rounded-circle mr-2`}
              >
                <Icon Name={Item.icon as IconsName} size="20px" />
              </div>
              <span className={`${Item.textColor} whitespace-nowrap`}>{Item.text}</span>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <div className={`d-flex flex-column px-5`}>
            <div className={`d-flex mt-3 w-100 mx-auto`}>
              {data?.guideStatus === COMPANY_REHBER_STATUS.PENDING ||
              data?.guideStatus === COMPANY_REHBER_STATUS.REJECTED ||
              data?.guideStatus === COMPANY_REHBER_STATUS.NONE ? (
                <button
                  onClick={handleChangeModalConfirm}
                  type="button"
                  // disabled={data?.guideStatus === COMPANY_REHBER_STATUS.ACCEPTED}
                  className={`shadow-bg-success p-2 text-center rounded flex-grow-1 mr-2 border-none`}
                >
                  {data?.guideStatus === COMPANY_REHBER_STATUS.REJECTED ||
                  data?.guideStatus === COMPANY_REHBER_STATUS.NONE
                    ? 'Add to Rehber'
                    : t(accept)}
                </button>
              ) : (
                <></>
              )}
              {data?.guideStatus === COMPANY_REHBER_STATUS.ACCEPTED ||
              data?.guideStatus === COMPANY_REHBER_STATUS.PENDING ? (
                <button
                  onClick={handleChangeModalStatus}
                  type="button"
                  className={`shadow-bg-danger p-2 text-center rounded flex-grow-1 ml-2`}
                >
                  {data?.guideStatus === COMPANY_REHBER_STATUS.ACCEPTED ? t(cancel) : t(reject)}
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardHeader className="py-3">
          <h5 className="d-flex align-items-center">
            <Icon Name="FiClock" />
            <span className="ml-2">{`${t(request)} ${t(history)}`}</span>
          </h5>
        </CardHeader>
        <CardBody className="pt-2 px-0">
          <div className="d-flex flex-column">
            <CardHeader className="pt-0 pb-2 d-flex align-items-center justify-content-between">
              <div className="d-flex flex-grow-1 w-50">
                <Icon Name="FiCalendar" />
                <b className="ml-2">{t(date)}</b>
              </div>
              <div className="d-flex flex-grow-1 w-50">
                <Icon Name="FiActivity" />
                <b className="ml-2">{t(status)}</b>
              </div>
            </CardHeader>
            <CardBody className={`${!data.guideRequestHistory?.length ? 'pb-2' : ''}`}>
              {data.guideRequestHistory?.length ? (
                data.guideRequestHistory?.map((item: any) => {
                  const concatDate = toDate(item?.updatedAt || item.createdAt);
                  return (
                    <div key={item.id} className="d-flex flex-nowrap align-items-center justify-content-between mb-3">
                      <span className="w-50">{concatDate || APP_HELPER.emptyDescription}</span>
                      <span className="w-50 " style={{ textTransform: 'capitalize' }}>
                        {item.status?.toLowerCase()}
                      </span>
                    </div>
                  );
                })
              ) : (
                <h6>{`${t(empty)} ${t(request)} ${t(list)}`}</h6>
              )}
            </CardBody>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

import React, { FC } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { Loading } from 'components';
import Logo from 'assets/images/buluro/dashboard/default.svg';
import Icon, { IconsName } from 'components/icons';
import Accordion from 'components/accordion';
import { APP_HELPER } from 'tools/enums';
import { concatSocialLink, isTrophy, toDate, toUpperCase } from 'tools/methods';
import { detailType } from 'tools/types/sectionTypes/student';
import useEmployeeDetails from 'pages/user-management/employee/useEmployeeDetails';

export default function EmployeeDetails() {
  const { data, loading } = useEmployeeDetails();

  return (
    <Loading loading={loading}>
      <Card className="bg-transparent border-0 shadow-0">
        <CardHeader className="bg-transparent pt-0">
          <div className="d-flex align-items-center">
            <img
              width="80px"
              height="80px"
              className="rounded-circle"
              alt={`${data?.firstName || ''} ${data?.lastName || ''}`}
              //@ts-ignore
              src={data?.profilePhoto || Logo}
            />
            <div className="flex-column ml-3">
              <div className="make-center">
                <div className="h6 m-0">{`${data?.firstName || ''} ${data?.lastName || ''}`}</div>
              </div>
            </div>
          </div>
        </CardHeader>
        <CardBody className="bg-transparent py-0">
          <EmployeeProfile
            data={{
              bio: data?.bio,
              address: data?.address,
              certificates: data?.certificates,
              workExperience: data?.workExperiences,
              speciality: data?.speciality,
              softwares: data?.softwares,
              socialMedia: data?.socialMedias,
              personalInformation: {
                militaryStatus: data?.militaryStatus,
                gender: data?.gender,
                birthdate: data?.birthdate,
                drivingLicense: data?.drivingLicense,
                maritalStatus: data?.maritalStatus,
                nationality: data?.nationality,
              },
              language: data?.languages,
              educationStatus: data?.educationStatus,
              dontHaveAWorkExperience: data?.dontHaveAWorkExperience,
              dontHaveASoftware: data?.dontHaveASoftware,
              dontHaveACertificate: data?.dontHaveACertificate,
            }}
          />
        </CardBody>
      </Card>
    </Loading>
  );
}

interface ProfileProps {
  bio?: detailType['bio'];
  speciality?: detailType['speciality'];
  workExperience?: detailType['workExperiences'];
  address?: detailType['address'];
  educationStatus?: detailType['educationStatus'];
  personalInformation?: {
    gender?: detailType['gender'];
    birthdate?: detailType['birthdate'];
    militaryStatus?: detailType['militaryStatus'];
    nationality?: detailType['nationality'];
    drivingLicense?: detailType['drivingLicense'];
    maritalStatus?: detailType['maritalStatus'];
  };
  language?: detailType['languages'];
  softwares?: detailType['softwares'];
  socialMedia?: detailType['socialMedias'];
  certificates?: detailType['certificates'];
  dontHaveAWorkExperience?: detailType['dontHaveAWorkExperience'];
  dontHaveASoftware?: detailType['dontHaveASoftware'];
  dontHaveACertificate?: detailType['dontHaveACertificate'];
}
interface EmployeeProfileProps {
  data: ProfileProps;
}

const EmployeeProfile: FC<EmployeeProfileProps> = (props) => {
  const Title = (
    <div className="d-flex">
      <Icon Name="FaRegUser" />
      <span className="ml-2">Profile</span>
    </div>
  );
  return <Accordion items={[{ title: Title, element: <Profile {...props.data} />, expanded: true }]} />;
};

const Profile: FC<ProfileProps> = (props) => {
  const {
    bio,
    certificates,
    educationStatus,
    language,
    personalInformation,
    socialMedia,
    softwares,
    speciality,
    workExperience,
    address,
    dontHaveAWorkExperience,
    dontHaveASoftware,
    dontHaveACertificate,
  } = props;
  console.log('educationStatus : ', educationStatus);
  return (
    <div className="d-flex flex-column px-4 pb-4">
      <div className="d-flex flex-column mb-3">
        <div className="d-flex mb-2">
          <Icon Name="FaRegUser" />
          <span className="ml-2">Bio</span>
        </div>
        <div className="ml-4">
          <p className="m-0">{bio || APP_HELPER.emptyDescription}</p>
        </div>
      </div>

      <div className="d-flex flex-column mb-3">
        <div className="d-flex mb-2">
          <Icon Name="FiGrid" />
          <span className="ml-2">Speciality</span>
        </div>
        <div className="ml-4">
          {isTrophy(speciality) ? (
            <div className="d-flex flex-column">
              {speciality?.map((special) => {
                return (
                  <span key={special.branch._id} className="mb-1">
                    {`${special.branch.originalName}${
                      special?.section?.originalName ? `, ${special?.section?.originalName}` : ''
                    }`}
                  </span>
                );
              })}
            </div>
          ) : (
            APP_HELPER.emptyDescription
          )}
        </div>
      </div>

      <div className="d-flex flex-column mb-3">
        <div className="d-flex mb-2">
          <Icon Name="FiGrid" />
          <span className="ml-2">Work Experience</span>
        </div>
        <div className="ml-4">
          <div className="d-flex flex-column">
            {dontHaveAWorkExperience ? (
              `Doesn't Have Experiences`
            ) : isTrophy(workExperience) ? (
              <div className="d-flex flex-column">
                {workExperience?.map((experience) => {
                  return (
                    <span key={experience._id}>
                      {`${
                        experience?.company?.companyName || experience?.companyName || APP_HELPER.emptyDescription
                      } , ${experience.speciality?.branch?.originalName || APP_HELPER.emptyDescription}${
                        experience.speciality?.section?.originalName
                          ? `, ${experience.speciality?.section?.originalName || APP_HELPER.emptyDescription}`
                          : ''
                      }, ${experience?.startAt} - ${experience?.endAt || 'Continues'}`}
                    </span>
                  );
                })}
              </div>
            ) : (
              APP_HELPER.emptyDescription
            )}
          </div>
        </div>
      </div>

      <div className="d-flex flex-column mb-3">
        <div className="d-flex mb-2">
          <Icon Name="FiMapPin" />
          <span className="ml-2">Address</span>
        </div>
        <div className="ml-4">
          <p className="m-0">
            {address ? (
              <>{`${address?.city || APP_HELPER.emptyDescription}, ${address.county || APP_HELPER.emptyDescription}, ${
                address?.fullAddress
              }, ${address?.postalCode || APP_HELPER.emptyDescription}`}</>
            ) : (
              APP_HELPER.emptyDescription
            )}
          </p>
        </div>
      </div>

      <div className="d-flex flex-column mb-3">
        <div className="d-flex mb-2">
          <Icon Name="FiBook" />
          <span className="ml-2">Education status</span>
        </div>
        <div className="ml-4">
          <p className="m-0">
            {educationStatus ? (
              <>
                {`${educationStatus?.educationPlaceName || APP_HELPER.emptyDescription}, ${
                  educationStatus.educationSection?.originalName || APP_HELPER.emptyDescription
                } ${educationStatus.educationSchool?.name || APP_HELPER.emptyDescription}, ${
                  educationStatus.startAt || APP_HELPER.emptyDescription
                } ${educationStatus.endAt || APP_HELPER.emptyDescription}`}
              </>
            ) : (
              APP_HELPER.emptyDescription
            )}
          </p>
        </div>
      </div>

      <div className="d-flex flex-column mb-3">
        <div className="d-flex mb-2">
          <Icon Name="FiUsers" />
          <span className="ml-2">Personal Information</span>
        </div>
        <div className="ml-4">
          <div className="d-flex flex-column">
            <div className="d-flex mb-2">
              <span>Birthday:</span>
              <span className="ml-2">
                {toDate(personalInformation?.birthdate as string, false) || APP_HELPER.emptyDescription}
              </span>
            </div>
            <div className="d-flex mb-2">
              <span>Gender:</span>
              <span className="ml-2">{toUpperCase(personalInformation?.gender) || APP_HELPER.emptyDescription}</span>
            </div>
            <div className="d-flex mb-2">
              <span>Military Status:</span>
              <span className="ml-2">
                {toUpperCase(personalInformation?.militaryStatus?.replace('_', ' ')) || APP_HELPER.emptyDescription}
              </span>
            </div>
            <div className="d-flex mb-2">
              <span>Marital Status:</span>
              <span className="ml-2">
                {toUpperCase(personalInformation?.maritalStatus as string) || APP_HELPER.emptyDescription}
              </span>
            </div>
            <div className="d-flex mb-2">
              <span>Nationality:</span>
              <span className="ml-2">{personalInformation?.nationality || APP_HELPER.emptyDescription}</span>
            </div>
            <div className="d-flex mb-2">
              <span>Driver License:</span>
              <span className="ml-2">{personalInformation?.drivingLicense ? 'Have Licence' : `Doesn't Have  `}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column mb-3">
        <div className="d-flex mb-2">
          <Icon Name="FaLanguage" />
          <span className="ml-2">Language</span>
        </div>
        <div className="ml-4">
          <p className="m-0">
            {isTrophy(language) ? (
              <>
                {language?.map((lang) => (
                  <span className="ml-2" key={lang.language.name}>
                    {`${lang?.language?.name || APP_HELPER.emptyDescription} - ${
                      lang?.level || APP_HELPER.emptyDescription
                    }`}{' '}
                  </span>
                ))}
              </>
            ) : (
              APP_HELPER.emptyDescription
            )}
          </p>
        </div>
      </div>

      <div className="d-flex flex-column mb-3">
        <div className="d-flex mb-2">
          <Icon Name="FaLaptop" />
          <span className="ml-2">Software</span>
        </div>
        <div className="ml-4">
          <p className="m-0">
            {dontHaveASoftware ? (
              `Doesn't Know any Software`
            ) : isTrophy(softwares) ? (
              <>{softwares?.map((software) => software.name).join(', ')}</>
            ) : (
              APP_HELPER.emptyDescription
            )}
          </p>
        </div>
      </div>

      <div className="d-flex flex-column mb-3">
        <div className="d-flex mb-2">
          <Icon Name="FiFacebook" />
          <span className="ml-2">Social Network</span>
        </div>
        <div className="ml-4">
          <div className="d-flex">
            {isTrophy(socialMedia) ? (
              <>
                {socialMedia?.map((social) => {
                  const iconName = `Fa${toUpperCase(social.name as string)}` as IconsName;
                  const socialLink = concatSocialLink(social.name as string, social.url as string);
                  console.log(social.name);
                  return (
                    <div key={social.name} className="ml-4">
                      <a href={socialLink}>
                        <Icon Name={iconName} size="25px" />
                      </a>
                    </div>
                  );
                })}
              </>
            ) : (
              APP_HELPER.emptyDescription
            )}
          </div>
        </div>
      </div>

      <div className="d-flex flex-column">
        <div className="d-flex mb-2">
          <Icon Name="AiOutlineSafetyCertificate" />
          <span className="ml-2">Certificates</span>
        </div>
        <div className="ml-4">
          <p className="m-0">
            {dontHaveACertificate
              ? `Doesn't Have`
              : isTrophy(certificates)
              ? certificates?.join(', ')
              : APP_HELPER.emptyDescription}
          </p>
        </div>
      </div>
    </div>
  );
};

import { useQuery } from 'hooks';
import { GET_ALL_SPECIAL_REGIONS } from 'services/graphql/queries/emlak/specialRegions';
import { useMemo } from 'react';
import { isTrophy } from 'tools/methods';
import { d_getTreeData } from 'store/tree/tree.dispatchs';
import { t_specialRegion } from 'pages/content-management/emlak/specialLocation/types';

export default function useSpecialLocation() {
  const { data, loading, error, refetch, fetchMore } = useQuery(GET_ALL_SPECIAL_REGIONS);
  const realData = useMemo(() => {
    const TData = data?.realEstateFindAllSpecialRegions || [];
    d_getTreeData(TData);
    return TData;
  }, [data?.realEstateFindAllSpecialRegions]);
  const isEmptyList = !isTrophy(realData);
  return { data: realData as t_specialRegion[], error, isEmptyList, refetch, fetchMore, loading };
}

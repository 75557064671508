import React from 'react';
import Routers from 'route';
import { Provider } from 'react-redux';
import store from 'store';
import { ApolloProvider } from '@apollo/client';
import gelClient from 'services/graphql';
import { ToastContainer, Slide } from 'react-toastify';
import Initial from 'Initial';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from 'components/errorBoundaries';
import { ConfigProvider } from 'antd';
export default function App(): JSX.Element {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <div className="App">
          <Provider store={store}>
            <ConfigProvider>
              <Initial>
                <ApolloProvider client={gelClient}>
                  <ToastContainer
                    position="top-right"
                    containerId={`${process.env.REACT_APP_APP_NAME}-dashboard`}
                    transition={Slide}
                    hideProgressBar
                    autoClose={5000}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  />
                  <Routers />
                </ApolloProvider>
              </Initial>
            </ConfigProvider>
          </Provider>
        </div>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

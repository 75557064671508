//@ts-nocheck
import { DASHBOARD_APP_ROUTES } from 'route/appRoutes';
import {
  activeUsersList,
  ads,
  analytics,
  applicationUsers,
  banReasons,
  banReasonsList,
  bazar,
  bazzarAds,
  bazzarCategories,
  companies,
  company,
  companyCategories,
  companyLogos,
  companyReasons,
  companySizes,
  contentManagement,
  deletedByUserReasons,
  deletedUsers,
  deletedUsersList,
  deleteReasons,
  documentRejectionReasons,
  documentTypes,
  emlak,
  emlakAds,
  emlakCategories,
  emlakType,
  employment,
  exhibition,
  experienceExpectation,
  fuar,
  hotel,
  jobAds,
  jobCategories,
  list,
  Location,
  logOut,
  magazine,
  mobileAppVersions,
  notification,
  report,
  school,
  sections,
  setting,
  settings,
  slider,
  softwares,
  specialLocation,
  universities,
  university,
  usersManagement,
  workRejectionReasons,
} from 'tools/i18n/constants/i18n';
import { SCHOOL_ADMIN_APP_ROUTES } from '../../route/appRoutes';
import { ROUTE_CONFIG } from '../../route/config';
import { Permission } from '../../tools/enums';
import { ageRange, languages, statistics, studentManagement } from '../../tools/i18n/constants/i18n';
const ADMIN = [
  {
    items: [
      {
        title: usersManagement,
        icon: 'FiUsers',
        type: 'sub',
        active: false,
        children: [
          {
            title: `Admins`,
            active: false,
            type: 'link',
            icon: 'FiUsers',
            path: ROUTE_CONFIG.admins.path,
            permissionKeys: ROUTE_CONFIG.admins.permissionKeys,
          },
          {
            title: applicationUsers,
            active: false,
            icon: 'FiUser',
            type: 'sub',
            children: [
              {
                title: activeUsersList,
                active: false,
                icon: 'FiUser',
                type: 'link',
                path: ROUTE_CONFIG.users.path,
                permissionKeys: ROUTE_CONFIG.users.permissionKeys,
              },
              {
                title: deletedUsersList,
                active: false,
                icon: 'AiOutlineUserDelete',
                type: 'link',
                path: ROUTE_CONFIG.deletedUsers.path,
                permissionKeys: ROUTE_CONFIG.deletedUsers.permissionKeys,
              },
              {
                title: deletedByUserReasons,
                active: false,
                icon: 'FiTrash',
                type: 'link',
                path: ROUTE_CONFIG.deleteReasons.path,
                permissionKeys: ROUTE_CONFIG.deleteReasons.permissionKeys,
              },
              {
                title: banReasonsList,
                active: false,
                icon: 'FaBan',
                type: 'link',
                path: ROUTE_CONFIG.banReasons.path,
                permissionKeys: ROUTE_CONFIG.banReasons.permissionKeys,
              },
            ],
          },
        ],
      },
      {
        title: contentManagement,
        icon: 'FiFolder',
        type: 'sub',
        active: false,
        children: [
          {
            title: employment,
            icon: 'FiUser',
            active: false,
            type: 'sub',
            children: [
              {
                title: jobCategories,
                active: false,
                type: 'link',
                icon: 'FiTool',
                path: ROUTE_CONFIG.jobCategories.path,
                permissionKeys: ROUTE_CONFIG.jobCategories.permissionKeys,
              },
              {
                title: softwares,
                active: false,
                type: 'link',
                icon: 'FiCodepen',
                path: ROUTE_CONFIG.softwares.path,
                permissionKeys: ROUTE_CONFIG.softwares.permissionKeys,
              },
              {
                title: languages,
                active: false,
                type: 'link',
                icon: 'FaLanguage',
                path: ROUTE_CONFIG.languages.path,
                permissionKeys: ROUTE_CONFIG.languages.permissionKeys,
              },
              {
                title: experienceExpectation,
                active: false,
                type: 'link',
                icon: 'FiBriefcase',
                path: ROUTE_CONFIG.experienceExpectation.path,
                permissionKeys: ROUTE_CONFIG.experienceExpectation.permissionKeys,
              },
              {
                title: ageRange,
                active: false,
                type: 'link',
                icon: 'FiSliders',
                path: ROUTE_CONFIG.ageRange.path,
                permissionKeys: ROUTE_CONFIG.ageRange.permissionKeys,
              },
              {
                title: workRejectionReasons,
                active: false,
                type: 'link',
                icon: 'FaComments',
                path: ROUTE_CONFIG.workRejectionReasons.path,
                permissionKeys: ROUTE_CONFIG.workRejectionReasons.permissionKeys,
              },
            ],
          },
          {
            title: company,
            icon: 'FiBook',
            active: false,
            type: 'sub',
            children: [
              {
                title: companies,
                icon: 'FiBook',
                active: false,
                type: 'link',
                path: ROUTE_CONFIG.companies.path,
                permissionKeys: ROUTE_CONFIG.companies.permissionKeys,
              },
              // {
              //   title: companyCategories,
              //   icon: 'FiBookOpen',
              //   active: false,
              //   type: 'link',
              //   path: ROUTE_CONFIG.companyCategories.path,
              //   permissionKeys: ROUTE_CONFIG.companyCategories.permissionKeys,
              // },
              {
                title: 'Company Categories',
                icon: 'FiBookOpen',
                active: false,
                type: 'link',
                path: ROUTE_CONFIG.companyCategoriesV2.path,
                permissionKeys: ROUTE_CONFIG.companyCategoriesV2.permissionKeys,
              },
              {
                title: companySizes,
                icon: 'FiSliders',
                active: false,
                type: 'link',
                path: ROUTE_CONFIG.companySizes.path,
                permissionKeys: ROUTE_CONFIG.companySizes.permissionKeys,
              },
              {
                title: 'Logos List',
                icon: 'FiImage',
                active: false,
                type: 'link',
                path: ROUTE_CONFIG.companyLogos.path,
                permissionKeys: ROUTE_CONFIG.companyLogos.permissionKeys,
              },
              {
                title: documentTypes,
                icon: 'FiFolder',
                active: false,
                type: 'link',
                path: ROUTE_CONFIG.documentTypes.path,
                permissionKeys: ROUTE_CONFIG.documentTypes.permissionKeys,
              },
              {
                title: documentRejectionReasons,
                icon: 'FiFolder',
                active: false,
                type: 'link',
                path: ROUTE_CONFIG.documentRejectionReasons.path,
                permissionKeys: ROUTE_CONFIG.documentRejectionReasons.permissionKeys,
              },
              {
                title: companyReasons,
                icon: 'FiFolder',
                active: false,
                type: 'link',
                path: ROUTE_CONFIG.companyLeaderReasons.path,
                permissionKeys: ROUTE_CONFIG.companyLeaderReasons.permissionKeys,
              },
            ],
          },
          {
            title: bazar,
            icon: 'FiShoppingBag',
            active: false,
            type: 'sub',
            children: [
              {
                title: bazzarCategories,
                icon: 'FiSliders',
                active: false,
                type: 'link',
                path: ROUTE_CONFIG.bazaarCategories.path,
                permissionKeys: ROUTE_CONFIG.bazaarCategories.permissionKeys,
              },
            ],
          },
          {
            title: slider,
            active: false,
            type: 'link',
            icon: 'FaSlidersH',
            path: DASHBOARD_APP_ROUTES.slider,
          },
          {
            icon: 'FaBuilding',
            title: emlak,
            type: 'sub',
            active: false,
            children: [
              {
                path: ROUTE_CONFIG.specialLocation.path,
                permissionKeys: ROUTE_CONFIG.specialLocation.permissionKeys,
                icon: 'FaMapPin',
                title: specialLocation,
                type: 'link',
                active: false,
              },
              {
                path: ROUTE_CONFIG.emlakTypes.path,
                permissionKeys: ROUTE_CONFIG.emlakTypes.permissionKeys,
                icon: 'FiType',
                title: emlakType,
                type: 'link',
                active: false,
              },
              {
                path: ROUTE_CONFIG.emlakCategories.path,
                permissionKeys: ROUTE_CONFIG.emlakCategories.permissionKeys,
                icon: 'FiLayout',
                type: 'link',
                active: false,
                title: emlakCategories,
              },
              {
                permissionKeys: ROUTE_CONFIG.emlakAds.permissionKeys,
                icon: 'FiLayout',
                type: 'sub',
                active: false,
                title: emlakAds,
                children: [
                  {
                    path: ROUTE_CONFIG.emlakAds.path,
                    title: list,
                    active: false,
                    type: 'link',
                    icon: 'FiLayout',
                    permissionKeys: ROUTE_CONFIG.emlakAds.permissionKeys,
                  },
                  {
                    path: ROUTE_CONFIG.emlakAdsReportReasons.path,
                    title: report,
                    type: 'link',
                    icon: 'FiLayout',
                    active: false,
                    permissionKeys: ROUTE_CONFIG.emlakAdsReportReasons.permissionKeys,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        icon: 'FiShoppingBag',
        title: fuar,
        type: 'sub',
        active: false,
        children: [
          {
            path: ROUTE_CONFIG.exhibition.path,
            permissionKeys: ROUTE_CONFIG.exhibition.permissionKeys,
            title: exhibition,
            type: 'link',
            active: false,
            icon: 'FiShoppingBag',
          },
          {
            path: ROUTE_CONFIG.hotel.path,
            permissionKeys: ROUTE_CONFIG.hotel.permissionKeys,
            title: hotel,
            type: 'link',
            active: false,
            icon: 'FiHome',
          },
          {
            path: ROUTE_CONFIG.location.path,
            permissionKeys: ROUTE_CONFIG.location.permissionKeys,
            title: Location,
            type: 'link',
            active: false,
            icon: 'FiMapPin',
          },
        ],
      },
      {
        path: ROUTE_CONFIG.analytics.path,
        permissionKeys: ROUTE_CONFIG.analytics.permissionKeys,
        icon: 'FiCalendar',
        title: analytics,
        type: 'link',
        active: false,
      },

      {
        path: ROUTE_CONFIG.schools.path,
        permissionKeys: ROUTE_CONFIG.schools.permissionKeys,
        icon: 'FaSchool',
        title: school,
        type: 'sub',
        active: false,
        children: [
          {
            path: ROUTE_CONFIG.schools.path,
            permissionKeys: ROUTE_CONFIG.schools.permissionKeys,
            icon: 'FaSchool',
            title: list,
            type: 'link',
            active: false,
          },
          {
            title: sections,
            active: false,
            type: 'link',
            icon: 'FiArchive',
            path: ROUTE_CONFIG.universitySections.path,
            permissionKeys: ROUTE_CONFIG.universitySections.permissionKeys,
          },
        ],
      },
      {
        icon: 'FiShoppingBag',
        title: ads,
        type: 'sub',
        children: [
          {
            title: jobAds,
            active: false,
            type: 'sub',
            icon: 'FiCommand',
            children: [
              {
                title: ads,
                active: false,
                type: 'link',
                icon: 'FiCommand',
                path: ROUTE_CONFIG.jobAds.path,
                permissionKeys: ROUTE_CONFIG.jobAds.permissionKeys,
              },
              {
                title: report,
                active: false,
                type: 'link',
                icon: 'FiCommand',
                path: ROUTE_CONFIG.jobAdsReport.path,
                permissionKeys: ROUTE_CONFIG.jobAdsReport.permissionKeys,
              },
            ],
          },
          {
            title: bazzarAds,
            active: false,
            type: 'sub',
            icon: 'FiShoppingBag',
            children: [
              {
                title: ads,
                active: false,
                type: 'link',
                icon: 'FiShoppingBag',
                path: ROUTE_CONFIG.bazzarAds.path,
                permissionKeys: ROUTE_CONFIG.bazzarAds.permissionKeys,
              },
              {
                title: report,
                active: false,
                type: 'link',
                icon: 'FiShoppingBag',
                path: ROUTE_CONFIG.bazaarAdsReport.path,
                permissionKeys: ROUTE_CONFIG.bazaarAdsReport.permissionKeys,
              },
            ],
          },
        ],
        active: false,
      },
      {
        path: ROUTE_CONFIG.magazine.path,
        permissionKeys: ROUTE_CONFIG.magazine.permissionKeys,
        icon: 'FiMessageCircle',
        title: magazine,
        type: 'link',
        active: false,
      },
      {
        path: ROUTE_CONFIG.notification.path,
        permissionKeys: ROUTE_CONFIG.notification.permissionKeys,
        icon: 'FiGitPullRequest',
        title: notification,
        type: 'link',
        active: false,
      },
      {
        icon: 'FaCoins',
        type: 'sub',
        title: 'Exchange',
        active: false,
        children: [
          {
            path: ROUTE_CONFIG.holidyManagement.path,
            permissionKeys: ROUTE_CONFIG.holidyManagement.permissionKeys,
            icon: 'FaCalendarAlt',
            type: 'link',
            title: 'Holidays Management',
            active: false,
          },
        ],
      },

      {
        icon: 'FiClock',
        type: 'sub',
        title: settings,
        active: false,
        children: [
          {
            path: ROUTE_CONFIG.setting.path,
            permissionKeys: ROUTE_CONFIG.setting.permissionKeys,
            icon: 'FiClock',
            type: 'link',
            title: setting,
            active: false,
          },
          {
            path: ROUTE_CONFIG.mobileAppOsVersions.path,
            permissionKeys: ROUTE_CONFIG.mobileAppOsVersions.permissionKeys,
            icon: 'FaMobileAlt',
            type: 'link',
            title: mobileAppVersions,
            active: false,
          },
        ],
      },
      {
        path: DASHBOARD_APP_ROUTES['logOut'],
        icon: 'FiClock',
        type: 'btn',
        active: false,
        title: logOut,
      },
    ],
  },
];

const SCHOOL_ADMIN = [
  {
    items: [
      {
        title: studentManagement,
        active: false,
        type: 'link',
        icon: 'FiUsers',
        path: SCHOOL_ADMIN_APP_ROUTES['studentManagement'],
        permissionKeys: [Permission.EMPLOYMENT_READ_SCHOOL_STUDENT_LIST],
      },

      {
        path: DASHBOARD_APP_ROUTES['logOut'],
        icon: 'FiClock',
        type: 'btn',
        active: false,
        title: logOut,
      },
    ],
  },
];
const SUPER_ADMIN = ADMIN;
export default {
  ADMIN,
  SCHOOL_ADMIN,
  SUPER_ADMIN,
};

import FetchApi from 'services/api/axios';
import { getCookie, setCookie } from 'tools/methods';

export const APP_ROUTES = {
  LOGIN: 'LOGIN',
};
export const API_ROUTE = {
  CONTENT_MANAGEMENT_IMAGE: '/content-management/image',
  UPDATE_COMPANY_CATEGORIES_IMAGE: '/content-management/employment_employer_category',
  JOB_CATEGORY_BRANCH: '/content-management/employment_branch',
  UNIVERSITY: '/content-management/employment_university',
  LEADER_STATUS: '/company/rehberStatusToggle',
  SCHOOL_LOGO: '/employment/employment_school',
  MAGAZINE_LOGO: '/sideservices/magazine/uploadMagazineLogo',
  MAGAZINE_EDITION_COVER: '/sideservices/magazine/edition/uploadCoverImage',
  MAGAZINE_EDITION_FILE: '/sideservices/magazine/edition/uploadFile',
  EXHIBITION_LOGO: '/sideservices/exhibition/uploadExhibitionLogo',
  EXHIBITION_POSTER: '/sideservices/exhibition/uploadExhibitionPoster',
  HOTEL_LOGO: '/sideservices/exhibition/uploadHotelLogo',
  COMPANY_LOGO: '/employment/employment_employer_logo',
  COMPANY_PDF: '/company/document',
  CUSTOM_NOTIFICATION_BANNER: '/messaging/banner',
  EMLAK_TYPE_ICON: '/content-management/realestate_category_type',
  EMLAK_CATEGORY_ICON: '/content-management/realestate_category',
  EMLAK_SPECIAL_REGION_PICTURE: '/content-management/realestate_special_region',
  SLIDER_IMAGE: '/sideservices/slider/slider-image',
  REFRESH_TOKEN: '/authentication/refresh-token',
};

export async function updateRefreshToken() {
  const formData = new FormData();
  formData.append('refreshToken', getCookie('refreshToken') as string);
  return FetchApi({
    url: 'REFRESH_TOKEN',
    method: 'POST',
    data: formData,
  })
    .then((res) => {
      console.log(res);
      setCookie({ name: 'token', value: res.data.token });
    })
    .catch((err) => {
      console.error(err);
    });
}

import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormGroup, Input, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
  accepted,
  apply,
  blue,
  canceled,
  no,
  rejected,
  request,
  status,
  tick,
} from '../../../../../tools/i18n/constants/i18n';
import { APP_HELPER, BLUE_TICKET_STATUS } from '../../../../../tools/enums';
import SubmitButton from '../../../../../components/buttons/submitButton';
import Select from 'react-select';
import { Controller } from 'react-hook-form';
import { companyFilterQuery, filter } from '../useCompanies';
import { useToggle } from 'hooks';
import { toArray } from 'lodash';
import { useSearchParams } from 'react-router-dom';
// import { Pending } from 'tools/i18n/constants';
import { BLUE_TICKET_STATUS_LIST, FILTER_SELECT_ENUM, FILTER_SELECT_REHBER, SORT_BY, SORT_TYPE } from '../utils/data';
// import FilterSelectLocations from './filterSelectLocations';
// import FilterSelectSpeciality from './filterSelectSpeciality';
// import useCompanySizes from '../../companySizes/useCompanySizes';

export default function CompaniesFilter({
  fetchMore,
  setData,
  setCount,
  handleChangeCurrentFilter,
}: {
  setData: (data: unknown[]) => void;
  fetchMore: any;
  setCount: (value: number) => void;
  handleChangeCurrentFilter: (filter: companyFilterQuery) => void;
}) {
  const [param, setParam] = useSearchParams();
  // const { data: companySizeData, loading: companySizeLoading } = useCompanySizes();

  const DEFAULT_VALUES: filter = {
    blueTickStatus: (param.get('blueTickStatus') as filter['blueTickStatus']) || undefined,
    guideStatus: (param.get('guideStatus') as filter['guideStatus']) || undefined,
    sortBy: (param.get('sortBy') as filter['sortBy']) || 'createdAt',
    sortType: (param.get('sortType') as filter['sortType']) || 'DESC',
    cities: (param.get('cities') as filter['cities']) || [],
    counties: (param.get('counties') as filter['counties']) || [],
    category: (param.get('category') as filter['category']) || null,
    logo: (param.get('logo') as filter['logo']) || null,
    job: (param.get('job') as filter['job']) || null,
    equipment: (param.get('equipment') as filter['equipment']) || null,
  };

  const [loading, toggleLoading] = useToggle(false);
  const { handleSubmit, control, setValue, watch } = useForm<filter>({
    defaultValues: DEFAULT_VALUES,
  });
  const { t } = useTranslation();
  const onSubmit: SubmitHandler<filter> = (data) => {
    console.log(data);

    toggleLoading(true);
    let filter: companyFilterQuery & any = {
      sort: undefined,
      blueTickStatus: undefined,
      isShop: undefined,
      guideStatus: undefined,
      cities: undefined,
      counties: undefined,
      category: undefined,
      logo: undefined,
      job: undefined,
      equipment: undefined,
      query: undefined,
    };

    if (data.sortBy || data.sortType) {
      filter['sort'] = {
        sortBy: data.sortBy,
        sortType: data.sortType,
      };
    }
    if (data.cities) filter['cities'] = data.cities;
    if (data.counties) filter['counties'] = data.counties;
    if (data.category) filter['category'] = data.category;
    if (data.guideStatus) {
      filter['guideStatus'] = data.guideStatus;
    }
    if (data.isShop) {
      filter['isShop'] = data.isShop;
    } else if (data.isShop === false) filter['isShop'] = false;
    if (data.logo) {
      filter['logo'] = data.logo;
    } else if (data.logo === false) filter['logo'] = false;
    if (data.job) {
      filter['job'] = data.job;
    } else if (data.job === false) filter['job'] = false;
    if (data.job) {
      filter['equipment'] = data.equipment;
    } else if (data.equipment === false) filter['equipment'] = false;
    if (data.blueTickStatus) {
      filter['blueTickStatus'] = data.blueTickStatus;
      setParam({
        blueTickStatus: data.blueTickStatus,
      });
    }

    setParam({
      page: APP_HELPER.tableDefaultPage.toString(),
      size: APP_HELPER.tableDefaultSize.toString(),
      ...(filter.sort?.sortBy ? { sortBy: filter.sort.sortBy } : undefined),
      ...(filter.sort?.sortType ? { sortType: filter.sort.sortType } : undefined),
      ...(filter.blueTickStatus ? { blueTickStatus: filter.blueTickStatus } : undefined),
      ...(filter.category ? { category: filter.category } : undefined),
      ...(filter.cities?.length ? { cities: `${filter.cities?.map((item: string) => item)}` } : undefined),
      ...(filter.counties?.length ? { counties: `${filter.counties?.map((item: string) => item)}` } : undefined),
      ...(filter.isShop ? { isShop: filter.isShop } : filter.isShop === false ? { isShop: false } : undefined),
      ...(filter.logo ? { logo: filter.logo } : filter.logo === false ? { logo: false } : undefined),
      ...(filter.job ? { job: filter.job } : filter.job === false ? { job: false } : undefined),
      ...(filter.guideStatus ? { guideStatus: filter.guideStatus } : undefined),
      ...(filter.equipment
        ? { equipment: filter.equipment }
        : filter.equipment === false
        ? { equipment: false }
        : undefined),
    });
    handleChangeCurrentFilter(filter);
    fetchMore({
      variables: {
        ...filter,
        page: 1,
        size: 50,
      },
    })
      .then((res: any) => {
        setData(toArray(res?.data.getCompanyListAndDetails?.listResponse?.data));
        setCount(res?.data.getCompanyListAndDetails?.listResponse?.count);
      })
      .finally(() => {
        toggleLoading(false);
      });
  };

  const handleReset = () => {
    setValue('blueTickStatus', undefined);
    setValue('sortBy', 'createdAt');
    setValue('sortType', 'DESC');
    setValue('isShop', undefined);
    setValue('category', null);
    setValue('cities', null);
    setValue('counties', null);
    setValue('equipment', null);
    setValue('guideStatus', undefined);
    setValue('job', null);
    setValue('logo', null);
    param.delete('blueTickStatus');
    param.delete('sortBy');
    param.delete('sortType');
    param.delete('isShop');
    param.delete('logo');
    param.delete('category');
    param.delete('cities');
    param.delete('counties');
    param.delete('equipment');
    param.delete('guideStatus');
    param.delete('job');
  };

  return (
    <form className="d-flex flex-column" style={{ width: '500px' }} onSubmit={handleSubmit(onSubmit)}>
      {/* <FilterSelectLocations
        onChange={(cities, counties) => {
          setValue('cities', cities);
          setValue('counties', counties);
        }}
      />

      <Controller
        control={control}
        defaultValue={DEFAULT_VALUES.category}
        name="category"
        render={({ field: { onChange } }) => {
          return (
            <FilterSelectSpeciality
              onChange={(e) => {
                console.log(e);
                setValue('category', e?.branchId);
              }}
            />
          );
        }}
      /> */}

      <Controller
        control={control}
        defaultValue={DEFAULT_VALUES.blueTickStatus}
        name="blueTickStatus"
        render={({ field: { onChange } }) => {
          return (
            <FormGroup tag="fieldset">
              <Label>{`${t(blue)} ${t(tick)} ${t(status)}`}</Label>
              <Select
                options={BLUE_TICKET_STATUS_LIST}
                value={BLUE_TICKET_STATUS_LIST.find((l) => l.value === watch('blueTickStatus'))}
                onChange={(value) => {
                  if (value?.value) {
                    onChange(value.value);
                  } else {
                    onChange(undefined);
                  }
                }}
              />
            </FormGroup>
          );
        }}
      />
      {/* <Controller
        control={control}
        defaultValue={DEFAULT_VALUES.blueTickStatus}
        name="blueTickStatus"
        render={({ field: { onChange } }) => {
          return (
            <FormGroup tag="fieldset">
              <Label>{`${t('Çalışan Sayısı')}`}</Label>
              <Select
                options={companySizeData?.map((item) => ({ label: item.title, value: item.id })) as any}
                value={BLUE_TICKET_STATUS_LIST.find((l) => l.value === watch('blueTickStatus'))}
                onChange={(value) => {
                  if (value?.value) {
                    onChange(value.value);
                  } else {
                    onChange(undefined);
                  }
                }}
              />
            </FormGroup>
          );
        }}
      /> */}
      {/* <Controller
        control={control}
        defaultValue={DEFAULT_VALUES.status}
        name="status"
        render={({ field: { onChange } }) => {
          return (
            <div className="mb-3">
              <Label>{t('Rehber')}</Label>
              <FormGroup check className="d-flex align-items-center mb-2">
                <Input
                  onChange={() => onChange(true)}
                  type="radio"
                  name="status"
                  checked={watch('status') === true}
                  id="companystatus"
                  className="mb-1"
                />
                <Label htmlFor="companystatus" className="ml-2 mb-0">
                  {t('Rehberde Olanlar')}
                </Label>
              </FormGroup>

              <FormGroup check className="d-flex align-items-center mb-2">
                <Input
                  onChange={() => onChange(false)}
                  type="radio"
                  name="status"
                  checked={watch('status') === false}
                  id="witOutompanystatus"
                  className="mb-1"
                />
                <Label htmlFor="witOutompanystatus" className="ml-2 mb-0">
                  {t('Rehberde Olmayanlar')}
                </Label>
              </FormGroup>

              <FormGroup check className="d-flex align-items-center mb-2">
                <Input
                  onChange={() => onChange(null)}
                  type="radio"
                  name="status"
                  checked={watch('status') === null || watch('status') === undefined}
                  id="nullCompanystatus"
                  className="mb-1"
                />
                <Label htmlFor="nullCompanystatus" className="ml-2 mb-0">
                  {t('Hepsi')}
                </Label>
              </FormGroup>
            </div>
          );
        }}
      /> */}

      <Controller
        control={control}
        defaultValue={DEFAULT_VALUES.guideStatus}
        name="guideStatus"
        render={({ field: { onChange } }) => {
          return (
            <FormGroup tag="fieldset">
              <Label>{`${t('Rehber')}`}</Label>
              <Select
                options={FILTER_SELECT_REHBER}
                value={FILTER_SELECT_REHBER.find((l) => l.value === watch('guideStatus'))}
                onChange={(value) => {
                  onChange(value?.value);
                }}
              />
            </FormGroup>
          );
        }}
      />

      {/* <Controller
        control={control}
        defaultValue={DEFAULT_VALUES.isShop}
        name="isShop"
        render={({ field: { onChange } }) => {
          return (
            <div className="mb-3">
              <Label>{t('Mağaza')}</Label>
              <FormGroup check className="d-flex align-items-center mb-2">
                <Input
                  onChange={() => onChange(true)}
                  type="radio"
                  name="isShop"
                  checked={watch('isShop') === true}
                  id="companyIsShop"
                  className="mb-1"
                />
                <Label htmlFor="companyIsShop" className="ml-2 mb-0">
                  {t('Mağaza Olanlar')}
                </Label>
              </FormGroup>

              <FormGroup check className="d-flex align-items-center mb-2">
                <Input
                  onChange={() => onChange(false)}
                  type="radio"
                  name="isShop"
                  checked={watch('isShop') === false}
                  id="witOutompanyisShop"
                  className="mb-1"
                />
                <Label htmlFor="witOutompanyisShop" className="ml-2 mb-0">
                  {t('Mağaza Olmayanlar')}
                </Label>
              </FormGroup>

              <FormGroup check className="d-flex align-items-center mb-2">
                <Input
                  onChange={() => onChange(null)}
                  type="radio"
                  name="isShop"
                  checked={watch('isShop') === null || watch('isShop') === undefined}
                  id="nullCompanyIsShop"
                  className="mb-1"
                />
                <Label htmlFor="nullCompanyIsShop" className="ml-2 mb-0">
                  {t('Hepsi')}
                </Label>
              </FormGroup>
            </div>
          );
        }}
      /> */}

      <Controller
        control={control}
        defaultValue={DEFAULT_VALUES.isShop}
        name="isShop"
        render={({ field: { onChange } }) => {
          return (
            <FormGroup tag="fieldset">
              <Label>{`${t('Mağaza')}`}</Label>
              <Select
                options={FILTER_SELECT_ENUM}
                value={FILTER_SELECT_ENUM.find((l) => l.value === watch('isShop'))}
                onChange={(value) => {
                  onChange(value?.value);
                }}
              />
            </FormGroup>
          );
        }}
      />

      {/* <Controller
        control={control}
        defaultValue={DEFAULT_VALUES.logo}
        name="logo"
        render={({ field: { onChange } }) => {
          return (
            <FormGroup tag="fieldset">
              <Label>{`${t('Logo Durumu')}`}</Label>
              <Select
                options={FILTER_SELECT_ENUM}
                value={FILTER_SELECT_ENUM.find((l) => l.value === watch('logo'))}
                onChange={(value) => {
                  onChange(value?.value);
                }}
              />
            </FormGroup>
          );
        }}
      /> */}

      {/* <Controller
        control={control}
        defaultValue={DEFAULT_VALUES.job}
        name="job"
        render={({ field: { onChange } }) => {
          return (
            <FormGroup tag="fieldset">
              <Label>{`${t('İş İlanı Durumu')}`}</Label>
              <Select
                options={FILTER_SELECT_ENUM}
                value={FILTER_SELECT_ENUM.find((l) => l.value === watch('job'))}
                onChange={(value) => {
                  onChange(value?.value);
                }}
              />
            </FormGroup>
          );
        }}
      /> */}

      {/* <Controller
        control={control}
        defaultValue={DEFAULT_VALUES.equipment}
        name="equipment"
        render={({ field: { onChange } }) => {
          return (
            <FormGroup tag="fieldset">
              <Label>{`${t('İş İlanı Durumu')}`}</Label>
              <Select
                options={FILTER_SELECT_ENUM}
                value={FILTER_SELECT_ENUM.find((l) => l.value === watch('equipment'))}
                onChange={(value) => {
                  onChange(value?.value);
                }}
              />
            </FormGroup>
          );
        }}
      /> */}

      <FormGroup>
        <Label>Neye Göre Sırala</Label>
        <Controller
          control={control}
          defaultValue={DEFAULT_VALUES.sortBy}
          name="sortBy"
          render={({ field: { onChange } }) => {
            return (
              <Select
                options={SORT_BY}
                value={SORT_BY.find((l) => l.value === watch('sortBy'))}
                onChange={(value) => {
                  if (value?.value) {
                    onChange(value.value);
                  } else {
                    onChange(undefined);
                  }
                }}
              />
            );
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label>Nasıl Sırala</Label>
        <Controller
          control={control}
          defaultValue={DEFAULT_VALUES.sortType}
          name="sortType"
          render={({ field: { onChange } }) => {
            return (
              <Select
                options={SORT_TYPE}
                value={SORT_TYPE.find((l) => l.value === watch('sortType'))}
                onChange={(value) => {
                  if (value?.value) {
                    onChange(value.value);
                  } else {
                    onChange(undefined);
                  }
                }}
              />
            );
          }}
        />
      </FormGroup>
      <SubmitButton loading={loading}>{t(apply)}</SubmitButton>
      <SubmitButton
        loading={false}
        type="submit"
        onClick={handleReset}
        className="mt-3 w-25 underline ml-auto text-center"
        color="transparent"
      >
        Reset
      </SubmitButton>
    </form>
  );
}

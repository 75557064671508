import { gql } from '@apollo/client';

export const GET_ALL_COMPANY_REASONS = gql`
  query getAllGuideRequestRejectReason {
    getAllGuideRequestRejectReason {
      id
      needToInsertCustomText
      language {
        id
        language
        content
      }
    }
  }
`;

export const GET_COMPANY_REASON_BY_ID = gql`
  query getGuideRequestRejectReasonById($id: Float!) {
    getGuideRequestRejectReasonById(id: $id) {
      id
      needToInsertCustomText
      language {
        id
        language
        content
      }
    }
  }
`;

export const CREATE_COMPANY_REASON = gql`
  mutation createGuideRequestRejectReason(
    $needToInsertCustomText: Boolean!
    $language: [CreateDocumentRejectReasonLanguageDto!]!
  ) {
    createGuideRequestRejectReason(data: { needToInsertCustomText: $needToInsertCustomText, language: $language })
  }
`;

export const UPDATE_COMPANY_REASON = gql`
  mutation updateGuideRequestRejectReason(
    $needToInsertCustomText: Boolean!
    $language: [UpdateDocumentRejectReasonLanguageDto!]!
    $id: Int!
  ) {
    updateGuideRequestRejectReason(
      data: { needToInsertCustomText: $needToInsertCustomText, language: $language, id: $id }
    )
  }
`;

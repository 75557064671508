import SectionLayout from 'pages/ads/jobAds/components/layout/sectionLayout';
import { FormProps } from 'pages/ads/jobAds/index';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, Label } from 'reactstrap';
import { end, entrance, workingHours } from 'tools/i18n/constants/i18n';

type WorkingHoursProps = FormProps;

export default function WorkingHours(props: WorkingHoursProps) {
  const { t } = useTranslation();
  const { control } = props;
  return (
    <SectionLayout title={t(workingHours)} icon="FiClock">
      <div className="p-2">
        <div className="d-flex">
          <div className="flex-grow-1 mr-1">
            <Label>{t(entrance)}</Label>
            <Controller
              control={control}
              defaultValue={undefined}
              name="workingHours.startAt"
              render={({ field: { onChange } }) => {
                return <Input onBlur={onChange} placeholder={t(entrance)} />;
              }}
            />
          </div>
          <div className="flex-grow-1">
            <Label>{t(end)}</Label>
            <Controller
              control={control}
              defaultValue={undefined}
              name="workingHours.endAt"
              render={({ field: { onChange } }) => {
                return <Input onChange={onChange} placeholder={t(end)} />;
              }}
            />
          </div>
        </div>
      </div>
    </SectionLayout>
  );
}

import Icon from 'components/icons';
import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react';

const LeftBar = () => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const width = useWindowSize();

  function useWindowSize() {
    const [size, setSize] = useState(0);
    useLayoutEffect(() => {
      function updateSize() {
        // @ts-ignore
        setSize(window.innerWidth);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  useEffect(() => {
    const ignoreClick_On_Out_side_Element = document.getElementById('out_side_click');
    const ignoreClick_On_Main_Nav_Element = document.getElementById('sidebar-menu');
    document.addEventListener('click', function (event) {
      //@ts-ignore
      const isClickOutSideElement = ignoreClick_On_Out_side_Element?.contains(event.target);
      //@ts-ignore
      const isClickMainNavElement = ignoreClick_On_Main_Nav_Element?.contains(event.target);
      if (
        window.innerWidth <= 991 &&
        !isClickOutSideElement &&
        !isClickMainNavElement &&
        document.getElementById('sidebar-wrapper')
      ) {
        //Do something click is outside specified element
        // @ts-ignore
        document.querySelector('.page-header').className = 'page-header close_icon';
        // @ts-ignore
        document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper close_icon ';
      }
    });
  }, [width]);

  const responsive_openCloseSidebar = (toggle: boolean) => {
    if (width <= 991) {
      console.log(width);
      // @ts-ignore
      document.querySelector('.page-header').className = 'page-header ';
      // @ts-ignore
      document.querySelector('#sidebar-wrapper').classList.remove('close_icon');
    } else {
      if (toggle) {
        setSidebarToggle(!toggle);
        // @ts-ignore
        document.querySelector('.page-header').className = 'page-header close_icon';
        // @ts-ignore
        document.querySelector('.sidebar-wrapper').classList.add('close_icon');
        // @ts-ignore
        document.querySelector('.mega-menu-container').classList.remove('d-block');
      } else {
        setSidebarToggle(!toggle);
        // @ts-ignore
        document.querySelector('.page-header').className = 'page-header';
        // @ts-ignore
        document.querySelector('.sidebar-wrapper').classList.remove('close_icon');
      }
    }
  };
  return (
    <Fragment>
      <div className="header-logo-wrapper col-auto p-0" id="out_side_click">
        <div
          className="toggle-sidebar"
          onClick={() => responsive_openCloseSidebar(sidebarToggle)}
          style={window.innerWidth <= 991 ? { display: 'block' } : { display: 'none' }}
        >
          <Icon Name="FaBars" />
        </div>
      </div>
    </Fragment>
  );
};

export default LeftBar;

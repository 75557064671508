import { useMutation, useQuery } from 'hooks';
import { useParams } from 'react-router-dom';
import {
  CREATE_EMLAK_AD_REPORT_REASON,
  GET_ONE_EMLAK_AD_REPORT_REASON,
  UPDATE_EMLAK_AD_REPORT_REASON,
} from 'services/graphql/queries/emlak/adsReport';
import { isNew } from 'tools/methods';
import { createReportReasonVars, reportReason, updateReportReasonVars } from './types';

export default function useEmlakAdsReportReasonDetails() {
  const { type } = useParams();
  const { data, loading, error } = useQuery<{ realEstateFindOneReportReason: reportReason }>(
    GET_ONE_EMLAK_AD_REPORT_REASON,
    {
      skip: !isNew(type),
    }
  );
  const [createExecute] = useMutation<{ realEstateCreateReportReason: reportReason }, createReportReasonVars>(
    CREATE_EMLAK_AD_REPORT_REASON
  );
  const [updateExecute] = useMutation<{ realEstateUpdateReportReason: reportReason }, updateReportReasonVars>(
    UPDATE_EMLAK_AD_REPORT_REASON
  );

  return {
    data: data?.realEstateFindOneReportReason,
    loading,
    error,
    isNew: isNew(type),
    createExecute,
    updateExecute,
  };
}

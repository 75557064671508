import Icon from 'components/icons';
import { t_toggleValueFunc } from 'hooks';
import React, { FC, ReactNode } from 'react';
import { Button, Modal as M, ModalProps as MProps, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export type t_modal_action = {
  text: string;
  onClick: t_toggleValueFunc;
  status?: 'danger' | 'primary' | 'warning' | 'info';
  className?: string;
  disabled?: boolean;
};

interface ModalProps extends MProps {
  isOpen: boolean;
  toggleOpen: t_toggleValueFunc;
  children?: ReactNode;
  actions?: t_modal_action[];
  title?: string;
  footerBorderLess?: boolean;
}

export const Modal: FC<ModalProps> = (props) => {
  const { isOpen, actions, title, toggleOpen, footerBorderLess, children, ...rest } = props;
  const onToggle = () => {
    toggleOpen();
  };
  return (
    <M
      {...rest}
      className={footerBorderLess ? 'footer-borderLess' : ''}
      zIndex={99999}
      toggle={onToggle}
      isOpen={isOpen}
      backdrop
      keyboard
    >
      <ModalHeader className="w-100">
        <div className="w-100 d-flex align-items-center justify-content-between">
          {title}
          <div role="button" className="ml-auto" onClick={onToggle}>
            <Icon Name="FiXSquare" size="25px" />
          </div>
        </div>
      </ModalHeader>

      <ModalBody>{children}</ModalBody>
      {!!actions && (
        <ModalFooter className="bg-transparent">
          <div className="d-flex flex-column w-100">
            {actions?.map((act) => {
              return (
                <Button
                  className={`mt-2 flex-grow-1 ${act.className}`}
                  key={act.text}
                  color={act.status}
                  onClick={() => act.onClick()}
                >
                  {act.text}
                </Button>
              );
            })}
          </div>
        </ModalFooter>
      )}
    </M>
  );
};
export default Modal;

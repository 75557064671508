import useQuery from 'hooks/useQuery';
import { useSearchParams } from 'react-router-dom';
import { GET_ALL_BAZZAR_ADS } from 'services/graphql/queries/bazzarAds';
import { APP_HELPER, BAZAAR_ADS_OPERATOR, BAZAAR_ADS_STATUS, bazaarAdsEnum } from 'tools/enums';
import { boolish, numberish, stringish, t_languageKeys } from 'tools/types';

export type t_dataTypes = {
  id: numberish;
  title: stringish;
  description: stringish;
  contacts: {
    id: number;
    phoneCode: string;
    phoneNumber: string;
  }[];
  status: BAZAAR_ADS_STATUS | null;
  boost: string[] | null;
  pictures:
    | {
        fileName: stringish;
        fileUrl: stringish;
      }
    | { fileName: stringish; fileUrl: stringish }[];
  model: stringish;
  type: bazaarAdsEnum;
  createdAt: stringish;
  updatedAt: stringish;
  publishedAt: stringish;
  sortDate: stringish;
  price: numberish;
  category: {
    id: numberish;
    title: stringish;
    status: boolish;
    icon: stringish;
    picture: stringish;
    createdAt: stringish;
    updatedAt: stringish;
    requireModel: boolish;
    language: {
      language: t_languageKeys | null;
      translation: stringish;
    };
    child: {
      id: numberish;
      title: stringish;
      status: boolish;
      icon: stringish;
      picture: stringish;
      createdAt: stringish;
      updatedAt: stringish;
      requireModel: boolish;
      language: {
        language: t_languageKeys | null;
        translation: stringish;
      };
    };
  };
  currency: {
    id: numberish;
    name: stringish;
    slug: stringish;
    symbol: stringish;
    createdAt: stringish;
    updatedAt: stringish;
  };
  addresses: {
    id: numberish;
    title: stringish;
    city: stringish;
    county: stringish;
    postalCode: numberish;
    fullAddress: stringish;
  }[];
  activity: {
    id: numberish;
    userId: numberish;
    askedForNumber: boolish;
    called: boolish;
    copiedThePhoneNumber: boolish;
    isUnique: boolish;
    bookmarked: boolish;
    createdAt: stringish;
    updatedAt: stringish;
  };
  ownerInfo: {
    id: numberish;
    name: stringish;
    blueMark: boolish;
    isShop: boolish;
    picture: stringish;
    primaryPhone: string;
    primaryPhoneCode: string;
    secondaryPhone: string;
    secondaryPhoneCode: string;
  };
  statistics: {
    saved: numberish;
    called: numberish;
    copiedThePhoneNumber: numberish;
    askedForNumber: numberish;
    totalViews: numberish;
    uniqueViews: number;
  };
  statusActivity: {
    id: numberish;
    description: stringish;
    status: BAZAAR_ADS_STATUS | null;
    operator: BAZAAR_ADS_OPERATOR | null;
    createdAt: stringish;
    updatedAt: stringish;
  }[];
  banActivity: {
    id: numberish;
    description: stringish;
    status: BAZAAR_ADS_STATUS | null;
    operator: BAZAAR_ADS_OPERATOR | null;
    createdAt: stringish;
    updatedAt: stringish;
  }[];
};

export default function useBazaarAds() {
  const [param] = useSearchParams();
  const defaultPage = param.get('page') ? parseInt(param.get('page') as string) : 0;
  const defaultSize = param.get('size') ? parseInt(param.get('size') as string) : APP_HELPER.tableDefaultSize;
  const { data, loading, error, fetchMore } = useQuery(GET_ALL_BAZZAR_ADS, {
    variables: {
      skip: (defaultPage - 1) * defaultSize < 0 ? 0 : (defaultPage - 1) * defaultSize,
      take: defaultSize,
    },
  });

  return {
    loading,
    error,
    fetchMore,
    data: data?.findAllBazaarPosters as { data: t_dataTypes[]; count: number },
  };
}

import React, { useCallback, useMemo } from 'react';
import { Button, Form, FormFeedback, FormGroup, Label, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { t_user } from './type';
import { Controller, useForm } from 'react-hook-form';
import { UserTypeEnum } from 'tools/enums';
import { EmailInput, PhoneNumberInput } from 'components/inputs';
import SubmitButton from 'components/buttons/submitButton';
import { useToggle } from 'hooks';
import Counter from './counter';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { CHANGE_USER_EMAIL, CHANGE_USER_PHONE_NUMBER } from 'services/graphql/queries/users/users.geaphql';
import { errorFinder } from 'tools/methods';

interface ChangeInfoModalProps {
  open: boolean;
  user: t_user;
  adminId: number;
  onClose: VoidFunction;
  refetch: any;
}

export default function ChangeInfoModal({ onClose, refetch, user }: ChangeInfoModalProps) {
  const isEmployer = user?.userType === UserTypeEnum.employer;
  const [changeEmailExecute, { loading: emailLoading }] = useMutation(CHANGE_USER_EMAIL);
  const [changePhoneNumberExecute, { loading: phoneLoading }] = useMutation(CHANGE_USER_PHONE_NUMBER);
  const INITIAL_VALUES: { email: string | undefined; phoneNumber: string | undefined; phoneCode: string | undefined } =
    {
      email: undefined,
      phoneNumber: undefined,
      phoneCode: undefined,
    };
  const [isCounterOpen, toggleCounterOpen] = useToggle(false);
  const {
    handleSubmit,
    control,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<typeof INITIAL_VALUES>({
    defaultValues: INITIAL_VALUES,
  });
  const onSubmit = useCallback(() => {
    let submitData: typeof INITIAL_VALUES | undefined = undefined;
    function saveInfo(data: typeof INITIAL_VALUES) {
      if (data.phoneNumber?.startsWith('0')) {
        setError('phoneNumber', { message: 'Phone Number Cannot Start with 0' });
        return;
      }
      submitData = data;
      toggleCounterOpen(true);
    }
    function finalSubmit() {
      if (isEmployer) {
        changeEmailExecute({
          variables: {
            email: submitData?.email,
            userId: user?.userId,
          },
        })
          .then((result) => {
            if (result.data) {
              refetch();
              toast.success('Updated Successfully');
              onClose();
            } else {
              toast.error(errorFinder(result?.errors?.[0].message));
            }
          })
          .catch((error) => {
            toast.error(errorFinder(error));
          });
      } else {
        changePhoneNumberExecute({
          variables: {
            phoneNumber: submitData?.phoneNumber?.toString(),
            phoneCode: submitData?.phoneCode,
            userId: user?.userId,
          },
        })
          .then((result) => {
            if (result.data) {
              refetch();
              toast.success('Updated Successfully');
              onClose();
            } else {
              toast.error(errorFinder(result?.errors?.[0].message));
            }
          })
          .catch((error) => {
            toast.error(errorFinder(error));
          });
      }
    }
    return {
      saveInfo,
      finalSubmit,
    };
  }, [isEmployer]);
  const submitRef = useMemo(() => onSubmit(), [isEmployer]);
  return (
    <>
      <Counter
        saveOnCounter
        onFinishCounter={submitRef.finalSubmit}
        isOpen={isCounterOpen}
        toggleOpen={toggleCounterOpen}
      />
      <ModalHeader>
        <h5 className="mb-0">{`Edit ${user?.companyName || user?.fullName}'s Login ${
          !isEmployer ? 'Phone Number' : 'Email'
        }`}</h5>
        <h6 className="mt-4">
          {`By changing user login ${
            isEmployer ? 'Email' : 'Phone Number'
          }, user will not be able to login with the old ${!isEmployer ? 'Phone Number' : 'Email'} (${
            isEmployer ? user?.email : user?.phone
          }) anymore and all the active sessions of this user will be logged out.`}
        </h6>
      </ModalHeader>

      <Form onSubmit={handleSubmit(submitRef.saveInfo)}>
        <ModalBody>
          {!isEmployer ? (
            <FormGroup>
              <Label>Phone Number</Label>
              <Controller
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Please Enter Phone Number',
                  },
                  minLength: {
                    value: 10,
                    message: 'Phone Number Should be 10 digit',
                  },
                  maxLength: {
                    value: 10,
                    message: 'Phone Number Should be 10 digit',
                  },
                }}
                name="phoneNumber"
                render={({ field: { onChange } }) => {
                  return (
                    <PhoneNumberInput
                      onCountryChange={(value) => {
                        clearErrors('phoneCode');
                        setValue('phoneCode', value.dial_code);
                      }}
                      onPhoneNumberChange={(val) => onChange(val?.toString())}
                      placeholder="Phone Number"
                    />
                  );
                }}
              />
              <FormFeedback>{errors?.phoneNumber?.message || errors?.phoneCode?.message}</FormFeedback>
            </FormGroup>
          ) : (
            <FormGroup>
              <Label htmlFor="Email">Email</Label>
              <EmailInput
                required
                id="email"
                placeholder="Email"
                control={control}
                defaultValue={user?.email || undefined}
                name="email"
              />
              <FormFeedback>{errors?.email?.message}</FormFeedback>
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter>
          <div className="d-flex align-items-center">
            <SubmitButton loading={phoneLoading || emailLoading} className="mr-1">
              Save Changes
            </SubmitButton>
            <Button type="button" onClick={onClose} className="ml-1">
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </>
  );
}

import { gql } from '@apollo/client';

export const GET_MAGAZINE_LIST = gql`
  query getMagazineList {
    MagazineList {
      count
      result {
        id
        name
        logo
        sortIndex
        edition {
          file
          cover
          createdAt
          id
          language {
            description
            id
            language
          }
          pages
          publishDate
          size
          title
        }
      }
    }
  }
`;

export const GET_SINGLE_MAGAZINE = gql`
  query getSingleMagazine($id: Int!) {
    FindOneMagazine(findOneMagazineDto: { id: $id }) {
      id
      name
      logo
      createdAt
      updatedAt
      edition {
        id
        title
        file
        cover
        size
        pages
        publishDate
        softDelete
        createdAt
        updatedAt
        language {
          id
          language
          description
        }
      }
    }
  }
`;

export const GET_SINGLE_MAGAZINE_EDITION = gql`
  query getSingleMagazineEdition($id: Int!) {
    FindOneMagazineEdition(findOneMagazineEditionDto: { id: $id }) {
      id
      title
      file
      cover
      size
      pages
      publishDate
      softDelete
      createdAt
      updatedAt
      language {
        id
        language
        description
      }
    }
  }
`;

export const CREATE_MAGAZINE = gql`
  mutation createMagazine($name: String!) {
    CreateMagazine(createMagazineDto: { name: $name }) {
      id
    }
  }
`;

export const UPDATE_MAGAZINE = gql`
  mutation updateMagazine($name: String, $id: Int!) {
    UpdateMagazine(updateMagazineDto: { name: $name, id: $id }) {
      message
    }
  }
`;

export const CREATE_MAGAZINE_EDITION = gql`
  mutation createMagazineEdition(
    $magazineId: Float!
    $title: String!
    $year: Int!
    $month: Int!
    $size: Float
    $pages: Int
    $language: [MultiLangDescriptionDto!]!
  ) {
    CreateMagazineEdition(
      createMagazineEditionDto: {
        magazineId: $magazineId
        title: $title
        year: $year
        month: $month
        size: $size
        pages: $pages
        language: $language
      }
    ) {
      id
    }
  }
`;

export const UPDATE_MAGAZINE_EDITION = gql`
  mutation updateMagazineEdition(
    $title: String!
    $size: Float
    $pages: Int
    $id: Int!
    $year: Int!
    $month: Int!
    $language: [MultiLangDescriptionDto!]!
  ) {
    UpdateMagazineEdition(
      updateMagazineEditionDto: {
        title: $title
        size: $size
        pages: $pages
        year: $year
        month: $month
        id: $id
        language: $language
      }
    ) {
      message
    }
  }
`;
export const DELETE_MAGAZINE = gql`
  mutation deleteMagazine($id: Int!) {
    DeleteMagazine(deleteMagazineDto: { id: $id }) {
      message
    }
  }
`;

export const DELETE_MAGAZINE_EDITION = gql`
  mutation deleteMagazineEdition($id: Int!) {
    DeleteMagazineEdition(deleteMagazineEditionDto: { id: $id }) {
      message
    }
  }
`;

export const UPDATE_MAGAZINE_SORT = gql`
  mutation updateMagazineSort($arr: [ChangeMagazinesSortDto!]!) {
    changeMagazinesSort(changeMagazinesSortDto: { arr: $arr }) {
      id
      sortIndex
      name
      logo
      createdAt
      updatedAt
      edition {
        file
        cover
        createdAt
        id
        language {
          description
          id
          language
        }
        pages
        publishDate
        size
        title
      }
    }
  }
`;

export const GET_MAGAZINE_EDITIONS = gql`
  query GetMagazineAndEditions($page: Int, $size: Int) {
    MagazineAndEditionList(paginationDto: { page: $page, size: $size }) {
      count
      result {
        id
        name
        logo
        edition {
          id
          title
        }
      }
    }
  }
`;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AppLayout from 'components/layout';
import { useGetStore } from 'hooks';
import { findRoutesByRole } from 'tools/methods';
import { ROLES_BASED_BACKEND } from 'tools/enums';

export default function LayoutRoutes() {
  const currentUserRole = useGetStore().User.userInfo.role?.[0];
  const currentUserPermission = useGetStore().User.userInfo.permissions;
  const ROUTES = findRoutesByRole(ROLES_BASED_BACKEND[currentUserRole], currentUserPermission);
  return (
    <Routes>
      {ROUTES?.map(({ path, element }, i) => {
        return (
          <Route element={<AppLayout />} key={i}>
            <Route path={path} element={element} />
          </Route>
        );
      })}
    </Routes>
  );
}

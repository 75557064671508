import React, { FC, useMemo } from 'react';
import PagesLayout from 'pages/components/layout';
import { useTranslation } from 'react-i18next';
import { add, cities, country, detail, list, Location, New } from 'tools/i18n/constants/i18n';
import { TableSectionTitle } from 'components/tables';
import Link from 'components/link';
import { Button, Col } from 'reactstrap';
import DataTable from 'components/tables/dataTable';
import useLocation from 'pages/fuar/location/useLocation';
import { Loading } from 'components';
import { ApolloError } from '@apollo/client';

export default function LocationComp() {
  const { t } = useTranslation();
  const { loading, error, data } = useLocation();
  const addNewTitle = `${t(add)} ${t(New)} ${t(country)}`;
  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <Loading loading={loading}>
        <PagesLayout newSectionButtonLabel={addNewTitle}>
          <LocationHeader />
          <LocationTable error={error} data={data} />
        </PagesLayout>
      </Loading>
    </Col>
  );
}

const LocationHeader = () => {
  return <header></header>;
};

interface MagazineTableProps {
  data: unknown[];
  error?: ApolloError;
}

const LocationTable: FC<MagazineTableProps> = (props) => {
  const { data, error } = props;
  const { t } = useTranslation();
  const title = `${t(Location)} ${t(list)}`;

  const columnData = useMemo(() => {
    return [
      {
        name: <TableSectionTitle name="FiAperture" title={`${t(country)} ${t(list)}`} />,
        center: false,
        cell: (r: { name: string; id: number }) => {
          return (
            <Link className="text-black" to={'usersDetails'} param={(r?.id || '').toString()}>
              {r.name}
            </Link>
          );
        },
      },

      {
        name: <TableSectionTitle title={`${t(cities)}`} name="FiLayers" />,
        selector: (r: { city: any[] }) => r.city?.map((c) => c.name)?.join(', '),
      },
      {
        name: '',
        cell: (r: { id: any }) => {
          return (
            <Link className="text-white ml-auto" to={'locationDetail'} param={r.id.toString()}>
              <Button color="primary">{t(detail)}</Button>
            </Link>
          );
        },
      },
    ];
  }, []);
  return <DataTable responsive disabled={!!error} searchable headerTitle={title} columns={columnData} data={data} />;
};

import { gql } from '@apollo/client';
export const GET_COMPANY_LOGOS = gql`
  query GetCompanyLogos($page: Int!, $size: Int = 100, $keyword: String) {
    EmploymentEmployersLogoList(paginationKeywordDto: { page: $page, size: $size, keyword: $keyword }) {
      count
      data {
        logo
        companyId
        companyName
        blueTick
      }
    }
  }
`;

export const DELETE_COMPANY_LOGOS = gql`
  mutation DeleteCompanyLogos($companyId: Float!) {
    EmploymentDeleteEmployerLogo(deleteEmployerLogoDto: { companyId: $companyId })
  }
`;

import React, { FC, useMemo, useState } from 'react';
import PagesLayout from 'pages/components/layout';
import { ads, applies, detail, jobAds, statistics } from 'tools/i18n/constants/i18n';
import { useTranslation } from 'react-i18next';
import { Button, Col } from 'reactstrap';
import AdsDetail from 'pages/ads/jobAds/adsDetail';
import Statistics from 'pages/ads/jobAds/statistics';
import AdsApplyList from 'pages/ads/jobAds/adsAppluList';

type sections = 1 | 2 | 3;
export default function AdsController() {
  const { t } = useTranslation();
  const [currentSection, setCurrentSection] = useState<sections>(1);
  const Components: Record<sections, { Element: JSX.Element }> = useMemo(() => {
    return {
      '1': {
        Element: <AdsDetail />,
      },
      '2': {
        Element: <Statistics />,
      },
      '3': {
        Element: <AdsApplyList />,
      },
    };
  }, [currentSection]);
  return (
    <PagesLayout backLinkTitle={t(jobAds)} backLink="jobAds" backLinkParam="/">
      <div className="d-flex flex-column">
        <Header currentSection={currentSection} setCurrentSection={setCurrentSection} />
      </div>
      <main>
        <Col xs={12} xxl={11} className="mx-auto">
          {Components[currentSection].Element}
        </Col>
      </main>
    </PagesLayout>
  );
}
interface HeaderProps {
  currentSection: sections;
  setCurrentSection: React.Dispatch<React.SetStateAction<sections>>;
}
const Header: FC<HeaderProps> = ({ currentSection, setCurrentSection }) => {
  const { t } = useTranslation();
  return (
    <header className="mb-5">
      <div className="d-flex">
        <Button
          className="mr-1"
          onClick={() => setCurrentSection(1)}
          color={currentSection === 1 ? 'primary' : 'secondary'}
        >
          {`${t(ads)} ${t(detail)}`}
        </Button>
        <Button
          className="mr-1"
          onClick={() => setCurrentSection(2)}
          color={currentSection === 2 ? 'primary' : 'secondary'}
        >
          {t(statistics)}
        </Button>
        <Button
          className="mr-1"
          onClick={() => setCurrentSection(3)}
          color={currentSection === 3 ? 'primary' : 'secondary'}
        >
          {t(applies)}
        </Button>
      </div>
    </header>
  );
};

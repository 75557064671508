import { TFunction, useTranslation } from 'react-i18next';
import appDispatch from 'store/app/app.dispatch';
import { t_lang } from 'store/app/app.reducer';
import useGetStore from 'hooks/useGetStore';
import { configRootElement, saveStorage } from 'tools/methods';
import { t_country } from 'tools/types';
import { DEFAULT_COUNTRY } from 'tools/constants';

type useLanguageReturn = {
  t: TFunction<'translation', undefined>;
  currentLanguage: t_lang;
  defaultAppLanguage: t_country;
  changeLanguage: (key: t_lang) => void;
};

export function useLanguage(): useLanguageReturn {
  const { t, i18n } = useTranslation();
  const currentLanguage = useGetStore().App.lang;
  const defaultAppLanguage = DEFAULT_COUNTRY;
  const changeLanguage = (lang: t_lang) => {
    i18n.changeLanguage(lang).then();
    appDispatch('LANG', lang);
    configRootElement('lang', lang);
    configRootElement('xml:lang', lang);
    saveStorage('i18nextLng', lang);
  };
  return { t, currentLanguage, defaultAppLanguage, changeLanguage };
}

export default useLanguage;

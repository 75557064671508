import CloseSquare from 'assets/images/buluro/dashboard/close-square.svg';
import DownloadIcon from 'assets/images/buluro/dashboard/downloadIcon.svg';
import RetryIcon from 'assets/images/buluro/dashboard/retry.svg';
import SucessTick from 'assets/images/buluro/dashboard/success-tick.svg';
import Icon from 'components/icons';
import { useUpdateEffect } from 'hooks';
import React, { FC, Fragment, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader, Progress } from 'reactstrap';
import Styled from 'styled-components';
import {
  completed,
  failed,
  mayTakeTime,
  pleaseWait,
  status,
  tryAgain,
  upload,
  uploading,
  waiting,
  youAreUploading,
} from 'tools/i18n/constants/i18n';

const StepContainer = Styled.div`
  width : 300px; 
`;
const StepStatusText = Styled.span`
  max-width : 200px
`;

export type stepStatus = 'COMPLETED' | 'FAILED' | 'UPLOADING' | 'WAITING';

export type step = {
  index: number;
  title: ReactNode;
  fileName: string;
  status: stepStatus;
  onRetry: VoidFunction;
  progressConfig?: {
    percent: number;
    onFinish?: VoidFunction;
  };
};
export interface FormActionLoading {
  open: boolean;
  onClose: VoidFunction;
  steps: step[];
}

export function FormActionLoading(props: FormActionLoading): JSX.Element {
  const [steps, setSteps] = useState<step[]>(props.steps.sort((a, b) => a.index - b.index));
  const { t } = useTranslation();
  useUpdateEffect(() => setSteps(props.steps.sort((a, b) => a.index - b.index)), [props.steps]);
  if (!props.open) return <Fragment />;
  return (
    <div className="form-action-loading">
      <div className="inner-container">
        <Card>
          <CardHeader className="py-3">
            <div className="d-flex align-items-center justify-content-between">
              <h5>{`${t(upload)} ${t(status)}`}</h5>
              <span role="button" onClick={props.onClose}>
                <img src={CloseSquare} alt="Close Icon" />
              </span>
            </div>
          </CardHeader>
          <CardBody>
            <div className="d-flex flex-column align-items-center ">
              <div className="d-flex flex-column align-items-center">
                <img width="45px" height="45px" src={DownloadIcon} alt="downlaod icon" />
                <p className="mt-2">
                  {`${t(youAreUploading)} in ${props.steps.length} steps, ${t(mayTakeTime)}. ${t(pleaseWait)}  ...`}
                </p>
              </div>
              <div className="d-flex flex-column mt-4 w-100">
                {steps.map((step) => {
                  return (
                    <Fragment key={step.index}>
                      <Step {...step} />
                    </Fragment>
                  );
                })}
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

const Step: FC<step> = (props) => {
  const { status, progressConfig, onRetry, fileName, title } = props;
  const { t } = useTranslation();
  const STATUS_UI: Record<stepStatus, { element: JSX.Element }> = {
    COMPLETED: {
      element: (
        <StepContainer className="d-flex shadow-text-success mt-2">
          <StepStatusText>{`${t(upload)} ${t(completed)}`}</StepStatusText>
          <img className="ml-2" src={SucessTick} alt="success-tick" />
        </StepContainer>
      ),
    },
    FAILED: {
      element: (
        <StepContainer className="d-flex shadow-text-danger mt-2">
          <StepStatusText>{`${t(upload)} ${t(failed)}, ${t(tryAgain)}`}</StepStatusText>
          <div role="button" className="ml-2" onClick={onRetry}>
            <img alt="retry" src={RetryIcon} />
          </div>
        </StepContainer>
      ),
    },
    UPLOADING: {
      element: (
        <StepContainer className="d-flex shadow-text-active">
          <div className="d-flex">
            <StepStatusText>{`${t(uploading)}`}</StepStatusText>
            {progressConfig ? (
              <div className="d-flex align-items-center ml-1">
                <span className="mr-2">{` (${progressConfig.percent}%)`}</span>
                <Progress
                  style={{
                    height: '3px',
                    width: '100px',
                  }}
                  striped
                  value={progressConfig.percent}
                />
              </div>
            ) : (
              '...'
            )}
          </div>
        </StepContainer>
      ),
    },
    WAITING: {
      element: (
        <StepContainer className="d-flex align-items-center shadow-text-active">
          <StepStatusText className="mr-2">{`${t(waiting)}`}</StepStatusText>
          <Icon Name="FiLoader" />
        </StepContainer>
      ),
    },
  };

  return (
    <ul className="step d-flex flex-column">
      <li>
        <div className="d-flex">
          <span className="h6">{title}</span>
          <span className="step-title-divider" />
          <span>{fileName}</span>
        </div>
        {STATUS_UI[status].element}
      </li>
    </ul>
  );
};

export default FormActionLoading;

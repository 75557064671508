import React, { useMemo, useRef } from 'react';
import Select from 'react-select';
import useQuery from 'hooks/useQuery';
import { GET_ALL_ADMINS, GET_SCHOOL_ADMIN } from 'services/graphql/queries/users/users.geaphql';
import { useTranslation } from 'react-i18next';
import { youDontHavePermissionToSeeAdminList } from 'tools/i18n/constants/i18n';
import { useUpdateEffect } from 'hooks';
type adminDropdownItem = {
  label: string;
  value: string;
  firstName: string;
  lastName: string;
  id: string;
};
const UNPERMISSION_ERROR_MESSAGE = 'Forbidden resource';
interface AdminDropdownProps {
  onSelect: (val: adminDropdownItem) => void;
  placeholder?: string;
  defaultValue?: { label: string; value: string };
  isSchoolAdmin?: boolean;
  className?: string;
  customError?: string;
  disabled?: boolean;
  needClearValue?: boolean;
}
export default function AdminDropdown(props: AdminDropdownProps) {
  const {
    onSelect,
    placeholder,
    defaultValue,
    isSchoolAdmin,
    className = '',
    customError,
    disabled,
    needClearValue,
  } = props;
  const { t } = useTranslation();
  const selectRef = useRef<any>();
  const { data, error, loading, refetch } = useQuery(isSchoolAdmin ? GET_SCHOOL_ADMIN : GET_ALL_ADMINS);
  const handleRefetch = async () => {
    await refetch();
  };
  const handleFocus = async () => {
    if (error) {
      await handleRefetch();
    }
  };
  const dropdownAdmin = useMemo(() => {
    return data?.findAllAdmins?.map((admin: any) => ({
      ...admin,
      label: `${admin.firstName} ${admin.lastName}`,
      value: admin.id,
    }));
  }, [data]);
  useUpdateEffect(() => {
    if (selectRef.current && needClearValue) {
      selectRef.current.clearValue();
      handleRefetch().then();
    }
  }, [selectRef, needClearValue]);
  return (
    <div className="d-flex flex-column flex-grow-1">
      <Select
        ref={selectRef}
        isLoading={loading}
        defaultValue={defaultValue}
        onFocus={handleFocus}
        controlShouldRenderValue
        options={dropdownAdmin}
        onChange={(val) => onSelect(val as adminDropdownItem)}
        placeholder={placeholder}
        isDisabled={disabled || error?.message === UNPERMISSION_ERROR_MESSAGE}
        className={`flex-grow-1 ${className}`}
      />
      {(error?.message === UNPERMISSION_ERROR_MESSAGE || customError) && (
        <>
          <div className="rounded shadow-bg-danger p-2 mt-2">
            {customError || t(youDontHavePermissionToSeeAdminList)}
          </div>
        </>
      )}
    </div>
  );
}

import { TableSectionTitle } from 'components/tables';
import React from 'react';
import { T } from 'tools/i18n';
import {
  create,
  date,
  detail,
  failed,
  notification,
  send,
  sending,
  sentDate,
  status,
  title,
} from 'tools/i18n/constants/i18n';
import {
  E_FILTER_NAME,
  E_SENT_DATE,
  NotificationAction,
  NotificationStatus,
  t_filter,
  t_filterDefaultValues,
  t_tableColumns,
  t_userRoles,
  t_userType,
} from '../types';
import { toDate, toUpperCase } from 'tools/methods';
import Link from 'components/link';
import { Button } from 'reactstrap';
import { TFunction } from 'react-i18next';
import { Draft, Sent } from 'tools/i18n/constants';

export const FILTERS: t_filter = {
  [E_FILTER_NAME.STATUS]: {
    title: status,
    icon: 'FiLayout',
    options: [
      {
        active: false,
        name: NotificationStatus.ALL,
      },
      {
        active: false,
        name: NotificationStatus.SENT,
      },
      {
        active: false,
        name: NotificationStatus.SENDING,
      },
      {
        active: false,
        name: NotificationStatus.DRAFT,
      },
    ],
  },
  [E_FILTER_NAME.SENT_DATE]: {
    icon: 'FaRegCalendar',
    title: sentDate,
    options: [
      {
        active: false,
        name: E_SENT_DATE.START,
      },
      {
        active: false,
        name: E_SENT_DATE.END,
      },
    ],
  },
};

export const TABLE_COLUMNS = (t: TFunction): t_tableColumns[] => [
  {
    name: <TableSectionTitle name="FiUser" title={`${T(notification)} ${T(title)}`} />,
    center: false,
    cell: (r) => {
      return <span className="text-black">{r.title}</span>;
    },
  },
  {
    name: <TableSectionTitle name="FiUser" title={`${T(create)} ${T(date)}`} />,
    center: false,
    cell: (r) => {
      return <span className="text-black"> {toDate(r.createdAt)} </span>;
    },
  },
  {
    name: <TableSectionTitle name="FiUser" title={`${T(status)}`} />,
    center: false,
    cell: (r) => {
      if (r.status === NotificationStatus.DRAFT)
        return <span className=" shadow-bg-notActive rounded-lg p-2"> {t(Draft)} </span>;
      else if (r.status === NotificationStatus.SENT) {
        return <span className=" shadow-bg-success rounded-lg p-2"> {t(Sent)} </span>;
      } else if (r.status === NotificationStatus.FAILED) {
        return <span className=" shadow-bg-danger rounded-lg p-2"> {t(failed)} </span>;
      }
      return <span className="shadow-bg-active rounded-lg p-2">{t(sending)}</span>;
    },
  },
  {
    name: <TableSectionTitle name="FiUser" title={`${T(send)} ${T(date)}`} />,
    center: false,
    cell: (r) => {
      return <span className="text-black">{toDate(r.sentAt)}</span>;
    },
  },
  {
    name: '',
    center: false,
    cell: (r: any) => {
      return (
        <Link className="text-white ml-auto" to={'notificationDetail'} param={`${r.id}`}>
          <Button color="primary">{t(detail)}</Button>
        </Link>
      );
    },
  },
];

export const FILTER_DEFAULT_VALUES: t_filterDefaultValues = {
  status: undefined,
  sentDate: undefined,
};

export const ALL_ACTIONS = [
  {
    label: 'Profili Aç',
    value: NotificationAction.OPEN_PROFILE,
  },
  {
    label: 'Mavi Tik Sayfasını Aç',
    value: NotificationAction.OPEN_BLUETICK_PAGE,
  },
  {
    label: 'İş Bölümünü Aç',
    value: NotificationAction.OPEN_EMPLOYMENT_SECTION,
  },
  {
    label: 'Ekipman Bölümünü Aç',
    value: NotificationAction.OPEN_BAZAAR_SECTION,
  },
  {
    label: 'Döviz Bölümünü Aç',
    value: NotificationAction.OPEN_EXCHANGE_SECTION,
  },
  {
    label: 'Fuar Bölümünü Aç',
    value: NotificationAction.OPEN_EXHIBITION_SECTION,
  },
  {
    label: 'Dergi Bölümünü Aç',
    value: NotificationAction.OPEN_MAGAZINE_SECTION,
  },
  {
    label: 'Rehber Bölümünü Aç',
    value: NotificationAction.OPEN_GUIDE_SECTION,
  },
  {
    label: 'Yeni İş İlanı Sayfasını Aç',
    value: NotificationAction.EMPLOYMENT_CREATE_NEW_AD,
  },
  {
    label: 'Yeni Ekipman İlanı Sayfasını Aç',
    value: NotificationAction.BAZAAR_CREATE_NEW_AD,
  },
  {
    label: 'Bildirim Detay Sayfasını Aç',
    value: NotificationAction.MESSAGE_DETAILS,
  },
];

export function filterActions(actions?: NotificationAction[]) {
  if (!actions) return ALL_ACTIONS;
  return ALL_ACTIONS.filter((act) => actions.includes(act.value));
}

export function findAction(
  sendToAll: boolean,
  userType: t_userType = 'APP_USER',
  userRoles: t_userRoles = { all: true, employee: false, employer: false }
) {
  if (sendToAll)
    return filterActions([
      NotificationAction.OPEN_PROFILE,
      NotificationAction.OPEN_EMPLOYMENT_SECTION,
      NotificationAction.OPEN_BAZAAR_SECTION,
      NotificationAction.OPEN_EXCHANGE_SECTION,
      NotificationAction.OPEN_EXHIBITION_SECTION,
      NotificationAction.OPEN_MAGAZINE_SECTION,
      NotificationAction.OPEN_GUIDE_SECTION,
      NotificationAction.BAZAAR_CREATE_NEW_AD,
      NotificationAction.MESSAGE_DETAILS,
    ]);
  if (userType === 'APP_USER' && userRoles.employer && !userRoles.employee) {
    return filterActions();
  } else if (
    (userType === 'APP_USER' && userRoles.employee && userRoles.employer) ||
    (userType === 'APP_USER' && userRoles.employee && !userRoles.employer)
  ) {
    return filterActions([
      NotificationAction.OPEN_PROFILE,
      NotificationAction.OPEN_EMPLOYMENT_SECTION,
      NotificationAction.OPEN_BAZAAR_SECTION,
      NotificationAction.OPEN_EXCHANGE_SECTION,
      NotificationAction.OPEN_EXHIBITION_SECTION,
      NotificationAction.OPEN_MAGAZINE_SECTION,
      NotificationAction.OPEN_GUIDE_SECTION,
      NotificationAction.BAZAAR_CREATE_NEW_AD,
      NotificationAction.MESSAGE_DETAILS,
    ]);
  } else if (userType === 'GUEST') {
    return filterActions([NotificationAction.OPEN_PROFILE]);
  } else return filterActions();
}

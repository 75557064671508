import { numberish, stringish, t_setError } from 'tools/types';
import { documentStatus, progressConfig, t_defaultValues } from '../entity/types';
import React, { FC, memo, useCallback, useDeferredValue } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { StyledDocumentContainer } from '../entity/styles';
import Icon from 'components/icons';
import UploadPdf from 'components/inputs/uploadPdf';

interface UploadFileProps {
  isActive: boolean;
  progressConfig: progressConfig;
  onSelect: (file: File) => void;
  defaultFile?: string | null;
  setError: t_setError<t_defaultValues>;
  indexName: string | undefined;
  onAction: (status: documentStatus, documentId: number, documentName: string, reason?: stringish) => void;
  id?: numberish;
  reason?: stringish;
}

const UploadFile: FC<UploadFileProps> = ({
  isActive,
  onSelect,
  progressConfig,
  defaultFile,
  setError,
  indexName,
  onAction,
  id,
  reason,
}) => {
  const deferredVal = useDeferredValue(progressConfig);
  const onAcceptedClick = useCallback(() => {
    onAction('ACCEPTED', id as number, indexName as string, reason);
  }, [reason, id, indexName]);
  const onRejectedClick = useCallback(() => {
    onAction('REJECTED', id as number, indexName as string, reason);
  }, [reason, id, indexName]);

  const onPendingClicked = useCallback(() => {
    onAction('PENDING', id as number, indexName as string, reason);
  }, [reason, id, indexName]);
  return (
    <div style={{ maxWidth: '100px' }} aria-disabled={!isActive} role="button" className="mb-2 top-0">
      <StyledDocumentContainer className="mb-2">
        {deferredVal?.isStarted && <ProgressBar animated striped color="success" now={deferredVal.progress / 10000} />}
        <>
          {deferredVal?.error && (
            <span
              role="button"
              onClick={onRejectedClick}
              style={{ width: '40px', height: '40px' }}
              className="p-2 make-center border-danger mx-auto rounded-circle border-light-gray"
            >
              <Icon Name="FiX" size="25px" color="#EE5873" />
            </span>
          )}
          {deferredVal.isCompleted && (
            <span
              role="button"
              onClick={onAcceptedClick}
              style={{ width: '40px', height: '40px' }}
              className="p-2 make-center border-success mx-auto rounded-circle border-light-gray"
            >
              <Icon Name="FaCheck" size="25px" color="#51bb25" />
            </span>
          )}
          {deferredVal.pending && (
            <span
              role="button"
              onClick={onPendingClicked}
              style={{ width: '40px', height: '40px' }}
              className="p-2 make-center border-secondary mx-auto rounded-circle border-light-gray"
            >
              <Icon Name="FaRegPauseCircle" size="25px" color="#323232" />
            </span>
          )}
        </>
      </StyledDocumentContainer>
      <UploadPdf
        defaultPdfName={defaultFile?.length ? defaultFile?.[defaultFile?.length - 1] : undefined}
        //@ts-ignore
        pdfLink={defaultFile}
        defaultPdf={defaultFile}
        openPdfAsModal
        openImageByClick
        withLabel={false}
        onSelect={onSelect}
        textWrap
        maxSize={10000000}
        showFileName={false}
        onError={(text) => setError(indexName as string, { message: text })}
        isAcceptImage
      />
    </div>
  );
};

export default memo(UploadFile);

import React, { InputHTMLAttributes } from 'react';
import { Input } from 'reactstrap';
import { Control, Controller, FieldValues, Path, PathValue } from 'react-hook-form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { WEBSITE_REGEXP, WEBSITE_WITH_PROTOCOL_REG_EXP } from 'tools/constants';
import { useTranslation } from 'react-i18next';
import { enter, please, valid, website } from 'tools/i18n/constants/i18n';
import { trimString } from 'tools/methods';

export interface WebsiteInputProps<T extends FieldValues> extends InputHTMLAttributes<HTMLInputElement> {
  control: Control<T>;
  name: Path<T>;
  rules?: Omit<RegisterOptions<any, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  isRequired?: boolean;
  regExpErrorText?: string;
  requiredErrorText?: string;
  defaultValue?: PathValue<T, Path<T>> | undefined;
  withProtocol?: boolean;
  handleChange: (e: string) => void;
}

export function WebsiteInput<T extends FieldValues>(props: WebsiteInputProps<T>) {
  const { t } = useTranslation();
  const defaultValidErrorText = `${t(please)} ${t(enter)} ${t(valid)} ${t(website)}`;
  const defaultRequiredErrorText = `${t(please)} ${t(enter)} ${t(website)}`;
  return (
    <Controller
      control={props.control}
      name={props.name}
      defaultValue={props.defaultValue}
      rules={{
        // ...props?.rules,
        required: {
          value: props.defaultValue ? false : true,
          message: props.requiredErrorText || defaultRequiredErrorText,
        },
        pattern: {
          value: props.withProtocol ? WEBSITE_WITH_PROTOCOL_REG_EXP : WEBSITE_REGEXP,
          message: props.regExpErrorText || defaultValidErrorText,
        },
      }}
      render={({ field: { name, ref, onChange } }) => {
        return (
          <Input
            {...props}
            defaultValue={props.defaultValue}
            ref={ref}
            name={name}
            onChange={(e) => {
              onChange(trimString(e.target.value));
              props.handleChange(e.target.value);
            }}
            type="text"
            minLength={1}
          />
        );
      }}
    />
  );
}

export default WebsiteInput;

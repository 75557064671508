import { Loading } from 'components';
import Link from 'components/link';
import DataTable, { TableSectionTitle } from 'components/tables/dataTable';
import PagesLayout from 'pages/components/layout';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col } from 'reactstrap';
import { ageRange, detail } from 'tools/i18n/constants/i18n';
import useAgeRange, { t_ageRange } from './useAgeRange';

export default function AgeRanges() {
  const { data, loading, addNewTitle } = useAgeRange();
  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <Loading loading={loading}>
        <PagesLayout newSectionButtonLabel={addNewTitle}>
          <AgeRangesTable data={data} />
        </PagesLayout>
      </Loading>
    </Col>
  );
}
interface CategoriesTable {
  data: t_ageRange[] | undefined;
}

function AgeRangesTable(props: CategoriesTable) {
  const { data = [] } = props;
  const { t } = useTranslation();
  const columns = [
    {
      name: <TableSectionTitle name="FiUser" title={t(ageRange)} />,
      center: false,
      cell: (r: { from: number; to: number }) => {
        return <span className="text-black">{`${r.to ? `${r.from} - ${r.to}` : `${r.from}+`}`}</span>;
      },
    },
    {
      name: '',
      center: false,
      cell: (r: { from: number; to: number; _id: any }) => {
        return (
          <Link to="ageRangeDetail" className="ml-auto" param={r._id}>
            <Button color="primary">{t(detail)}</Button>
          </Link>
        );
      },
    },
  ];
  return <DataTable disabled={false} columns={columns} data={data} />;
}

import useQuery from 'hooks/useQuery';
import React, { useMemo, useRef } from 'react';
import Select from 'react-select';
import { GET_COMPANY_SIZES } from 'services/graphql/queries/App';
import { t_companySize, t_select } from 'tools/types';

type ddItemCity = t_companySize & { label: string; value: string };

type CompanySizeProps = Omit<t_select<ddItemCity>, 'selectedCountry' | 'selectedCity'>;

export function CompanySize(props: CompanySizeProps) {
  const { onSelect, defaultValue, ...rest } = props;
  const selectRef = useRef<any>();
  const { data, loading, error, refetch } = useQuery(GET_COMPANY_SIZES);
  const makeOption = useMemo(() => {
    const fetchedData = (data?.getCompanySize as t_companySize[]) || [];
    return fetchedData.map((size) => ({ ...size, label: size.title, value: size.id?.toString() }));
  }, [data]);
  const handleFocus = async () => {
    if (error) {
      await refetch();
    }
  };
  return (
    <Select
      {...rest}
      defaultValue={defaultValue}
      ref={selectRef}
      isLoading={loading}
      onFocus={handleFocus}
      options={makeOption}
      onChange={(val) => onSelect?.(val as ddItemCity)}
    />
  );
}

export default CompanySize;

import { gql } from '@apollo/client';

export const GET_REFRESH_TOKEN = gql`
  mutation getRefreshToken($jwtRefreshToken: String!) {
    refreshToken(RefreshTokenDto: { jwtRefreshToken: $jwtRefreshToken }) {
      token
      refreshToken
      message
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($password: String!, $confirmPassword: String!) {
    updatePassword(UpdatePasswordDto: { password: $password, confirmPassword: $confirmPassword }) {
      token
      message
    }
  }
`;

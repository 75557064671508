import { ApolloError } from '@apollo/client';
import { Loading } from 'components';
import Icon from 'components/icons';
import Link from 'components/link';
import DataTables, { TableSectionTitle } from 'components/tables/dataTable';
import { useToggle, useUpdateEffect } from 'hooks';
import PagesLayout from 'pages/components/layout';
import useSchool from 'pages/school/useShool';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, Input } from 'reactstrap';
import Styled from 'styled-components';
import { EDUCATION_GRADE_TEXT } from 'tools/enums';
import { Location } from 'tools/i18n/constants';
import defaultUniLogo from 'assets/images/buluro/icon/university-building.png';
import {
  New,
  active,
  add,
  detail,
  grade,
  isRegistered,
  list,
  name,
  school,
  schools,
  visible,
} from 'tools/i18n/constants/i18n';
import { removeCityFromCounty, toUpperCase } from 'tools/methods';
import SchoolsFilter, { t_filter } from './components/filters';

const SchoolNameContainer = Styled.div`
  min-width : 17vw;
`;

interface schoolTableProps {
  fetchMore: any;
  loading: boolean;
  data: { count: number; data: unknown[] };
  error?: ApolloError;
}
export type filterName =
  | 'grade'
  | 'isRegistered'
  | 'isActive'
  | 'isVisible'
  | 'simpleSchool'
  | 'inActive'
  | 'inVisible';

export default function ContentManagementSchool() {
  const { loading, data, error, fetchMore } = useSchool();
  const { t } = useTranslation();
  return (
    <Loading loading={loading}>
      <PagesLayout newSectionButtonLabel={`${t(add)} ${t(New)} ${t(school)}`}>
        <Card className="h-100">
          <CardBody className="p-0 h-100">
            <div className="table-responsive h-100">
              <SchoolTable data={data} loading={loading} error={error} fetchMore={fetchMore} />
            </div>
          </CardBody>
        </Card>
      </PagesLayout>
    </Loading>
  );
}

const SchoolTable: FC<schoolTableProps> = (props) => {
  const { data, fetchMore } = props;
  const { t } = useTranslation();
  const [realData, setRealData] = useState(data);
  const [loading, toggleLoading] = useToggle(false);
  const lastAppliedFilter = useRef<t_filter | undefined>(undefined);
  const onSearch = useCallback(async (key: string) => {
    fetchMore({
      variables: {
        ...(key ? { keyword: key, ...lastAppliedFilter.current } : { ...lastAppliedFilter.current }),
      },
    })
      .then((res: { data: { EmploymentSchoolsList: { count: number; data: unknown[] } } }) => {
        setRealData(res.data?.EmploymentSchoolsList);
      })
      .catch(console.error);
  }, []);

  const onPageChange = useCallback((page: number, size: number) => {
    fetchMore({
      variables: {
        page,
        size,
        ...lastAppliedFilter.current,
      },
    })
      .then((res: { data: { EmploymentSchoolsList: { count: number; data: unknown[] } } }) => {
        setRealData(res.data?.EmploymentSchoolsList);
      })
      .catch(console.error);
  }, []);

  const onFilter = useCallback((filter: t_filter) => {
    console.log(filter);
    toggleLoading(true);
    lastAppliedFilter.current = filter;
    fetchMore({
      variables: filter,
    })
      .then((res: { data: { EmploymentSchoolsList: { count: number; data: unknown[] } } }) => {
        setRealData(res.data?.EmploymentSchoolsList);
        toggleLoading(false);
      })
      .catch((err: any) => {
        console.error(err);
        toggleLoading(false);
      });
  }, []);
  const columnData = useMemo(() => {
    return [
      {
        name: <TableSectionTitle name={'FiBookOpen'} title={`${t(school)} ${t(name)}`} />,
        center: false,
        cell: (r: any) => (
          <SchoolNameContainer className="d-flex table-cell-container align-items-center py-2">
            {r.logo ? (
              <div className="img-md rounded-circle">
                <img className="rounded-circle " alt={r.companyName} src={r.logo} width={24} height={24} />
              </div>
            ) : (
              <img className="ml-2" alt={r.companyName} src={defaultUniLogo} width={32} height={32} />
            )}
            <span className="ml-2 line-height-lg">{r.name}</span>
          </SchoolNameContainer>
        ),
      },
      {},
      {
        name: <TableSectionTitle name="FiMapPin" title={t(Location)} />,
        center: false,
        cell: (r: any) => {
          return (
            <div className="d-flex flex-column">
              <span className="mb-2"> {toUpperCase(r.city)} </span>
              <span> {toUpperCase(removeCityFromCounty(r.county))} </span>
            </div>
          );
        },
      },
      {
        name: <TableSectionTitle name="FiType" title={t(grade)} />,
        center: false,

        selector: (r: any) => EDUCATION_GRADE_TEXT[r.grade],
      },
      {
        name: <TableSectionTitle name="FiType" title={t(isRegistered)} />,
        center: true,

        cell: (r: any) => (
          <Input
            type="checkbox"
            style={{ width: '18px', height: '18px' }}
            readOnly
            aria-readonly={true}
            checked={r.isRegistered}
          />
        ),
      },
      {
        name: <TableSectionTitle name="FiType" title={t(active)} />,
        center: true,

        cell: (r: any) => (
          <Input
            type="checkbox"
            style={{ width: '18px', height: '18px' }}
            readOnly
            aria-readonly={true}
            checked={r.isActive}
          />
        ),
      },
      {
        name: <TableSectionTitle name="FiType" title={t(visible)} />,
        center: true,
        cell: (r: any) => (
          <Input
            type="checkbox"
            style={{ width: '18px', height: '18px' }}
            readOnly
            aria-readonly={true}
            checked={r.isVisible}
          />
        ),
      },
      {
        name: '',
        center: false,
        cell: (r: any) => {
          return (
            <Link className="text-white ml-auto" to={'schoolDetail'} param={r._id.toString()}>
              <Button color="primary">{t(detail)}</Button>
            </Link>
          );
        },
      },
    ];
  }, []);

  useUpdateEffect(() => {
    setRealData(data);
  }, [data]);
  return (
    <DataTables
      disabled={loading}
      searchable
      headerTitle={`${t(schools)} ${t(list)}`}
      data={realData.data}
      paginationTotalRows={realData.count}
      filterable
      columns={columnData}
      onSearch={onSearch}
      customFilter={<SchoolsFilter loading={loading} onFilter={onFilter} />}
      onChangePage={onPageChange}
      onChangeRowsPerPage={async (size, currentPage) => onPageChange(currentPage, size)}
    />
  );
};

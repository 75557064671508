import { Loading } from 'components';
import Icon from 'components/icons';
import TreeDataCheckbox from 'components/tree-select-checkbox';
import YourListIsEmpty from 'pages/components/emptyList';
import PagesLayout from 'pages/components/layout';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import { d_selectedNode } from 'store/tree/tree.dispatchs';
import { New, add, bazaarAdsReport, report } from 'tools/i18n/constants/i18n';
import useBazaarAdsReport from './useBazaarAdsReport';

export default function JobAdsReport() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isListEmpty, data, loading } = useBazaarAdsReport();

  const onNodeSelect = (e: any) => {
    d_selectedNode(e);
    if (e.id) {
      navigate(`${e.id}`);
    }
  };
  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <Loading loading={loading}>
        <PagesLayout newSectionButtonLabel={`${t(New)} ${t(bazaarAdsReport)}`} newSectionLink="new">
          <Card>
            <CardBody className="p-2 d-flex align-items-center justify-content-center flex-grow-1">
              {isListEmpty ? (
                <div className="d-flex flex-column w-100">
                  <Header />
                  <div className="w-100 px-5 py-4">
                    <TreeDataCheckbox
                      onDetailButton={(node) => {
                        onNodeSelect(node);
                      }}
                      data={data}
                      detailButton
                      checkbox={false}
                    />
                  </div>
                </div>
              ) : (
                <div className="py-3">
                  <YourListIsEmpty addNewButton title="Your List is Empty" addButtonTitle={`${t(add)} ${t(report)}`} />
                </div>
              )}
            </CardBody>
          </Card>
        </PagesLayout>
      </Loading>
    </Col>
  );
}

const Header = () => {
  const { t } = useTranslation();
  return (
    <CardHeader className="py-2 d-flex justify-content-between align-items-center">
      <Col md={6} className="d-flex">
        <Icon Name="FiAperture" />
        <span className="h6 ml-2">{`${t(report)}`}</span>
      </Col>
      {/*<SearchForm />*/}
    </CardHeader>
  );
};

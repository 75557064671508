import { FilterAdsCommonProps } from 'pages/ads/bazzarAds/filter/index';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGroup, Input, Label } from 'reactstrap';
import { onlyAdsWithPhoto } from 'tools/i18n/constants/i18n';
type OnlyWithPhotoProps = FilterAdsCommonProps;
export default function BazaarAdsFilterOnluWithPhoto(props: OnlyWithPhotoProps) {
  const { control } = props;
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name="onlyWithPictures"
      render={({ field: { onChange } }) => (
        <FormGroup className="d-flex ">
          <Input onChange={(event) => onChange(event)} type="checkbox" style={{ width: '25px', height: '25px' }} />
          <Label className="ml-2 mb-0 mt-1">{t(onlyAdsWithPhoto)}</Label>
        </FormGroup>
      )}
    />
  );
}

import { useUpdateEffect } from 'hooks';
import useQuery from 'hooks/useQuery';
import React, { useMemo, useRef } from 'react';
import Select from 'react-select';
import { GET_CITY_COUNTIES } from 'services/graphql/queries/App';
import { t_city, t_select } from 'tools/types';

type ddItemCity = t_city & { label: string; value: string };

export function CityDropdown(props: t_select<ddItemCity>) {
  const { onSelect, defaultValue, selectedCountry, selectedCity, ...rest } = props;
  const selectRef = useRef<any>();
  const { data, loading, error, refetch } = useQuery(GET_CITY_COUNTIES);
  const makeOption = useMemo(() => {
    const typedCity = selectedCity as string[] | undefined;
    return (
      (selectedCountry?.city ? selectedCountry.city : (data?.employmentCitiesAndCountiesList as t_city[]))?.map(
        (city: any) => ({
          ...city,
          label: city.name?.toUpperCase() || city?.city?.toUpperCase(),
          value: city?.id?.toString() || city?.name || city?.city,
          isDisabled:
            !!typedCity &&
            (Array.isArray(typedCity)
              ? typedCity.find((c) => c === (city.name || city?.city))
              : typedCity === city?.name),
        })
      ) || []
    );
  }, [selectedCountry, data]);
  const handleFocus = async () => {
    if (error) {
      await refetch();
    }
  };
  useUpdateEffect(() => {
    if (selectedCountry && selectRef.current) {
      selectRef.current.clearValue();
      onSelect?.(undefined);
    }
  }, [selectedCountry]);

  return (
    <Select
      {...rest}
      defaultValue={defaultValue}
      ref={selectRef}
      isLoading={loading}
      onFocus={handleFocus}
      options={makeOption}
      onChange={(val) => onSelect?.(val as ddItemCity)}
    />
  );
}

export default CityDropdown;

export const enterPhoneNumber = 'enterPhoneNumber',
  phoneNumber = 'phoneNumber',
  contact = 'contact',
  countryCode = 'countryCode',
  enterPassword = 'enterPassword',
  otpCode = 'otpCode',
  enterOTP = 'enterOTP',
  password = 'password',
  repeat = 'repeat',
  welcomeBack = 'welcomeBack',
  forgetPassword = 'forgetPassword',
  verificationCode = 'verificationCode',
  loginSubTitle = 'loginSubTitle',
  forgotPassword = 'forgotPassword',
  restore = 'restore',
  loading = 'loading',
  login = 'login',
  requestOtp = 'requestOtp',
  sendOtpRequest = 'sendOtpRequest',
  bannedByAdmin = 'bannedByAdmin',
  activeUsersList = 'activeUsersList',
  deletedUsersList = 'deletedUsersList',
  deletedByUserReasons = 'deletedByUserReasons',
  banReasonsList = 'banReasonsList',
  requestSent = 'requestSent',
  details = 'details',
  detail = 'detail',
  statistics = 'statistics',
  applies = 'applies',
  apply = 'apply',
  list = 'list',
  agent = 'agent',
  deletedUsers = 'deletedUsers',
  search = 'search',
  appName = 'Bulur’O',
  done = 'done',
  edit = 'edit',
  New = 'new',
  secondHand = 'secondHand',
  used = 'used',
  an = 'an',
  option = 'option',
  admin = 'admin',
  superAdmin = 'superAdmin',
  assign = 'assign',
  add = 'add',
  registered = 'registered',
  bazzar = 'bazzar',
  bazzarCategories = 'bazzarCategories',
  role = 'role',
  Delete = 'delete',
  Deleted = 'deleted',
  updated = 'updated',
  successfully = 'successfully',
  created = 'created',
  record = 'record',
  added = 'added',
  user = 'user',
  users = 'users',
  appUsers = 'appUsers',
  guests = 'guests',
  type = 'type',
  name = 'name',
  Location = 'Location',
  grade = 'grade',
  mustBe = 'mustBe',
  isRegistered = 'isRegistered',
  confirm = 'confirm',
  accept = 'Accept',
  reject = 'reject',
  status = 'status',
  education = 'education',
  visibility = 'visibility',
  pending = 'pending',
  accepted = 'accepted',
  rejected = 'rejected',
  success = 'success',
  error = 'error',
  warning = 'warning',
  canceled = 'canceled',
  isLeader = 'isLeader',
  isStore = 'isStore',
  areYouSure = 'areYouSure',
  areYouSureToSendThisNotification = 'areYouSureToSendThisNotification',
  leader = 'leader',
  store = 'store',
  editor = 'editor',
  active = 'active',
  ads = 'ads',
  price = 'price',
  maximum = 'maximum',
  minimum = 'minimum',
  ad = 'ad',
  views = 'views',
  unique = 'unique',
  total = 'total',
  review = 'review',
  results = 'results',
  show = 'show',
  inActive = 'inActive',
  inVisible = 'inVisible',
  banned = 'banned',
  removed = 'removed',
  activityDate = 'activityDate',
  activityType = 'activityType',
  sectionActivities = 'sectionActivities',
  employer = 'employer',
  employee = 'employee',
  reviewed = 'reviewed',
  info = 'info',
  reset = 'reset',
  resetPasswordSubTitle = 'resetPasswordSubTitle',
  send = 'send',
  sending = 'sending',
  message = 'message',
  ban = 'ban',
  unban = 'unban',
  reasons = 'reasons',
  reason = 'reason',
  reasonsFor = 'reasonsFor',
  rejections = 'rejections',
  activities = 'activities',
  remove = 'remove',
  save = 'save',
  required = 'required',
  section = 'section',
  company = 'company',
  specialities = 'specialities',
  speciality = 'speciality',
  website = 'website',
  dontHaveWebSite = 'dontHaveWebSite',
  information = 'information',
  specialties = 'specialties',
  birthdays = 'birthdays',
  gender = 'Gender',
  military = 'military',
  personal = 'personal',
  personalInformation = 'personalInformation',
  nationality = 'nationality',
  driver = 'driver',
  licence = 'licence',
  size = 'size',
  numberOfPages = 'numberOfPages',
  range = 'range',
  and = 'and',
  or = 'or',
  years = 'years',
  year = 'year',
  month = 'month',
  establishment = 'establishment',
  companyEstablishment = 'companyEstablishment',
  companySpecialties = 'companySpecialties',
  companyContacts = 'companyContacts',
  companyWorkTime = 'companyWorkTime',
  companyAbout = 'companyAbout',
  companySocialMedia = 'companySocialMedia',
  companyLogo = 'companyLogo',
  companyDocuments = 'companyDocuments',
  companyInformation = 'companyInformation',
  setTemporaryPassword = 'setTemporaryPassword',
  phoneNumberVerification = 'phoneNumberVerification',
  companySizeEstablishment = 'companySizeEstablishment',
  phone = 'phone',
  number = 'number',
  first = 'first',
  call = 'call',
  last = 'last',
  online = 'online',
  offline = 'offline',
  unbanned = 'unbanned',
  day = 'day',
  yearOfEstablish = 'yearOfEstablish',
  email = 'email',
  verification = 'verification',
  students = 'students',
  level = 'level',
  valid = 'valid',
  verifyStatus = 'VerifyStatus',
  isSearchable = 'isSearchable',
  visible = 'visible',
  editions = 'editions',
  date = 'date',
  expired = 'expired',
  start = 'start',
  pages = 'pages',
  published = 'published',
  publish = 'publish',
  time = 'time',
  address = 'address',
  area = 'area',
  postalCode = 'postalCode',
  fullAddress = 'fullAddress',
  fullName = 'fullName',
  workingHours = 'workingHours',
  completed = 'completed',
  softwares = 'softwares',
  ageRange = 'ageRange',
  ageOfRange = 'ageOfRange',
  workRejectionReasons = 'workRejectionReasons',
  rejectionReason = 'rejectionReason',
  ageRangeOfEmployees = 'ageRangeOfEmployees',
  socialMedia = 'socialMedia',
  software = 'software',
  university = 'university',
  universities = 'universities',
  sections = 'sections',
  companies = 'companies',
  companyCategories = 'companyCategories',
  companySizes = 'companySizes',
  companyLogos = 'companyLogos',
  companyReasons = 'Rehber Rejections',
  documentTypes = 'documentTypes',
  documentRejectionReasons = 'documentRejectionReasons',
  documentRejectionReason = 'documentRejectionReason',
  documentType = 'documentType',
  schools = 'schools',
  entrance = 'entrance',
  dateOfPublish = 'dateOfPublish',
  bazaarCategories = 'bazaarCategories',
  bazaarCategory = 'bazaarCategory',
  workingDays = 'workingDays',
  end = 'end',
  description = 'description',
  fillInput = 'fillInput',
  upload = 'upload',
  uploading = 'uploading',
  waiting = 'waiting',
  failed = 'failed',
  image = 'image',
  selectSingleImage = 'selectSingleImage',
  cover = 'cover',
  logo = 'logo',
  documents = 'documents',
  usersManagement = 'usersManagement',
  contentManagement = 'contentManagement',
  employment = 'employment',
  bazar = 'bazar',
  categories = 'categories',
  job = 'job',
  jobAds = 'Job Ads',
  report = 'report',
  jobAdsReport = 'jobAdsReport',
  bazaarAdsReport = 'bazaarAdsReport',
  bazzarAds = 'Bazzar Ads',
  jobCategories = 'jobCategories',
  slider = 'slider',
  category = 'category',
  parent = 'parent',
  pleaseEnterUniversitySectionName = 'pleaseEnterUniversitySectionName',
  pleaseEnterUniversityName = 'pleaseEnterUniversityName',
  pleaseEnterExperienceExpectation = 'pleaseEnterExperienceExpectation',
  pleaseEnterCompanyName = 'pleaseEnterCompanyName',
  Parent = 'Parent',
  icon = 'icon',
  branch = 'branch',
  experienceExpectation = 'experienceExpectation',
  microCopies = 'microCopies',
  guide = 'guide',
  applicationUsers = 'applicationUsers',
  deleteReasons = 'deleteReasons',
  banReasons = 'banReasons',
  banReason = 'banReason',
  deleteReason = 'deleteReason',
  analytics = 'analytics',
  fuar = 'fuar',
  exhibition = 'exhibition',
  banner = 'banner',
  assigned = 'assigned',
  hotel = 'hotel',
  applications = 'applications',
  magazine = 'magazine',
  title = 'title',
  From = 'from',
  To = 'to',
  model = 'model',
  notification = 'notification',
  emlak = 'emlak',
  specialLocation = 'specialLocation',
  specialRegions = 'specialRegions',
  label = 'label',
  sent = 'sent',
  In = 'in',
  silent = 'silent',
  school = 'school',
  simple = 'simple',
  primary = 'primary',
  middle = 'middle',
  high = 'high',
  art = 'art',
  setting = 'setting',
  settings = 'settings',
  mobileAppVersions = 'mobileAppVersions',
  map = 'map',
  code = 'code',
  copy = 'copy',
  copied = 'copied',
  download = 'download',
  file = 'file',
  qrCode = 'qrCode',
  logOut = 'logOut',
  select = 'select',
  region = 'region',
  welcomeTo = 'welcomeTo',
  please = 'please',
  city = 'city',
  cities = 'cities',
  country = 'country',
  county = 'county',
  enter = 'enter',
  fill = 'fill',
  translation = 'translation',
  translations = 'translations',
  all = 'all',
  dashboard = 'dashboard',
  welcomeToAppDashboard = 'welcomeToAppDashboard',
  activity = 'activity',
  sureToDeleteAdmin = 'sureToDeleteAdmin',
  cancel = 'cancel',
  workingDaysHours = 'workingDaysHours',
  employmentContentManagement = 'employmentContentManagement',
  emptyList = 'emptyList',
  useAddToCategory = 'useAddToCategory',
  useToAdd = 'useToAdd',
  content = 'content',
  management = 'management',
  categoryParentPlaceHolders = 'categoryParentPlaceHolders',
  language = 'language',
  languages = 'languages',
  more = 'more',
  sizeExceeded = 'sizeExceeded',
  wrongFile = 'wrongFile',
  update = 'update',
  create = 'create',
  sureToDelete = 'sureToDelete',
  sureToDeleteCategory = 'sureToDeleteCategory',
  areYouSureToDelete = 'areYouSureToDelete',
  areYouSureToRemove = 'areYouSureToRemove',
  This = 'This',
  thisAdAlreadyBanned = 'thisAdAlreadyBanned',
  didntGetCode = 'didntGetCode',
  request = 'request',
  requests = 'requests',
  For = 'For',
  empty = 'empty',
  requested = 'requested',
  history = 'history',
  again = 'again',
  pleaseEnterSoftwareName = 'pleaseEnterSoftwareName',
  emptySoftwareList = 'emptySoftwareList',
  pleaseCheckPassword = 'pleaseCheckPassword',
  addToRegisteredSchool = 'addToRegisteredSchool',
  removeToRegisteredSchool = 'removeToRegisteredSchool',
  loadResourcesFailed = 'loadResourcesFailed',
  registeredStudentCount = 'registeredStudentCount',
  student = 'student',
  count = 'count',
  capacity = 'capacity',
  acceptableSchool = 'acceptableSchool',
  activeSchool = 'activeSchool',
  uploadSpecifySize = 'uploadSpecifySize',
  companyApplied = 'companyApplied',
  confirmAllConditions = 'confirmAllConditions',
  submit = 'submit',
  yes = 'yes',
  no = 'no',
  not = 'not',
  tryAgain = 'tryAgain',
  next = 'next',
  previous = 'previous',
  // blue tick
  blue = 'blue',
  ticket = 'ticket',
  tick = 'tick',
  thisCompanyHasBlueTick = 'thisCompanyHasBlueTick',
  canceledBlueTickDescription = 'canceledBlueTickDescription',
  blueTickAcceptDescription = 'blueTickAcceptDescription',
  productModelIsRequired = 'productModelIsRequired',
  input = 'Input',
  inputs = 'Inputs',
  additionalSchoolInformation = 'additionalSchoolInformation',
  basicSchoolInformation = 'basicSchoolInformation',
  assignAdminOrQrCode = 'assignAdminOrQrCode',
  adminSchoolCanAddStudent = 'adminSchoolCanAddStudent',
  publishedOn = 'publishedOn',
  sponsored = 'sponsored',
  normalDays = 'normalDays',
  sponsoredDays = 'sponsoredDays',
  verified = 'verified',
  only = 'only',
  bio = 'bio',
  work = 'work',
  experience = 'experience',
  passwordNotEqual = 'passwordNotEqual',
  isThisSchoolVisible = 'isThisSchoolVisible',
  isThisSchoolActive = 'isThisSchoolActive',
  //admin school
  adminSchoolPanel = 'adminSchoolPanel',
  studentManagement = 'studentManagement',
  youDontHavePermissionToSeeAdminList = 'youDontHavePermissionToSeeAdminList',
  unableToUploadIcon = 'unableToUploadIcon',
  unableToGetResponseData = 'unableToGetResponseData',
  networkError = 'networkError',
  onlyLastDayAds = 'onlyLastDayAds',
  onlyAdsWithPhoto = 'onlyAdsWithPhoto',
  deleted = 'deleted',
  needCustomText = 'needCustomText',
  hidden = 'hidden',
  sentDate = 'sentDate',
  action = 'action',
  saved = 'saved',
  filters = 'filters',
  reviewAndSend = 'reviewAndSend',
  sendNotificationIn3Steps = 'sendNotificationIn3Steps',
  body = 'body',
  text = 'text',
  audience = 'audience',
  youAreUploading = 'youAreUploading',
  files = 'files',
  mayTakeTime = 'mayTakeTime',
  pleaseWait = 'pleaseWait',
  skip = 'skip',
  hasJobAd = 'hasJobAd',
  hasNotJobAd = 'hasNotJobAd',
  hasBlueTick = 'hasBlueTick',
  hasNotBlueTick = 'hasNotBlueTick',
  hasBazaarAd = 'hasBazaarAd',
  hasNotBazaarAd = 'hasNotBazaarAd',
  isNotStore = 'isNotStore',
  isInGuide = 'isInGuide',
  isNotInGuide = 'isNotInGuide',
  isStudent = 'isStudent',
  isNotStudent = 'isNotStudent',
  emlakType = 'emlakType',
  hasCredit = 'hasCredit',
  emlakCategories = 'emlakCategories',
  emlakAds = 'emlakAds',
  hasLoan = 'hasLoan',
  hasPrice = 'hasPrice',
  hasPrePaidPrice = 'hasPrePaidPrice',
  hasChargePrice = 'hasChargePrice',
  hasDocumentType = 'hasDocumentType',
  hasDocumentStatus = 'hasDocumentStatus',
  hasEquipped = 'hasEquipped',
  retry = 'retry',
  sortBy = 'sortBy',
  sortType = 'sortType',
  createdAt = 'createdAt',
  profilePercentage = 'profilePercentage';

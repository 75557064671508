import useQuery from 'hooks/useQuery';
import { GET_SINGLE_COUNTRY } from 'services/graphql/queries/location';
import { useParams } from 'react-router-dom';

export default function useDetail() {
  const { type } = useParams();
  const { data, error, loading } = useQuery(GET_SINGLE_COUNTRY, {
    variables: {
      id: parseInt(type as string),
    },
  });
  return { data: data?.findOneCountry, error, loading };
}

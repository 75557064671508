import React, { ChangeEvent, InputHTMLAttributes, useCallback } from 'react';
import { Input } from 'reactstrap';
import { Control, Controller, FieldValues, Path, PathValue } from 'react-hook-form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import {
  CHARACTER_NUMBER_REGEXP,
  NUMBER_REGEXP,
  ONLY_CHARACTER_REGEXP,
  SPECIAL_CHARACTER_REGEXP,
} from 'tools/constants';
import { useTranslation } from 'react-i18next';
import { enter, input, please, valid } from 'tools/i18n/constants/i18n';
import { trimString } from 'tools/methods';

export interface OnlyCharInputProps<T extends FieldValues> extends InputHTMLAttributes<HTMLInputElement> {
  control: Control<T>;
  name: Path<T>;
  rules?: Omit<RegisterOptions<any, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  isRequired?: boolean;
  regExpErrorText?: string;
  requiredErrorText?: string;
  defaultValue?: PathValue<T, Path<T>> | undefined;
  isTextArea?: boolean;
  isNumberAllowed?: boolean;
  isEveryCharacterAllowed?: boolean;
  ref?: React.MutableRefObject<any>;
}

export function OnlyCharInput<T extends FieldValues>(props: OnlyCharInputProps<T>) {
  const { t } = useTranslation();
  const defaultValidErrorText = `${t(please)} ${t(enter)} ${t(valid)} ${t(input)}`;
  const defaultRequiredErrorText = `${t(please)} ${t(enter)} ${t(input)}`;
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, cb: any) => {
      const value = e.target.value;
      const parseValue = props.isEveryCharacterAllowed
        ? value
        : value.replace(props.isNumberAllowed ? SPECIAL_CHARACTER_REGEXP : NUMBER_REGEXP, '');
      e.target.value = parseValue;
      cb?.(trimString(parseValue));
    },
    [props.isNumberAllowed]
  );
  return (
    <Controller
      control={props.control}
      name={props.name}
      defaultValue={props.defaultValue}
      rules={{
        ...props.rules,
        required: {
          value: !!props.isRequired,
          message: props.requiredErrorText || defaultRequiredErrorText,
        },
        pattern: !props.isEveryCharacterAllowed
          ? {
              value: props.isNumberAllowed ? CHARACTER_NUMBER_REGEXP : ONLY_CHARACTER_REGEXP,
              message: props.regExpErrorText || defaultValidErrorText,
            }
          : undefined,
      }}
      render={({ field: { name, onChange } }) => {
        return (
          <Input
            {...props}
            ref={props?.ref}
            defaultValue={props.defaultValue}
            name={name}
            onChange={(e) => handleChange(e, onChange)}
            type={props.isTextArea ? 'textarea' : 'text'}
          />
        );
      }}
    />
  );
}

export default OnlyCharInput;

import { gql } from '@apollo/client';

export const GET_ALL_SLIDERS = gql`
  query GetSlidersDto(
    $location: SliderCategoriesLocationsEnum!
    $userType: UserTypesEnum!
    $sliderStatus: SlideStatusEnum!
  ) {
    getSliderDetailsForAdmin(getSlidersDto: { location: $location, userType: $userType, sliderStatus: $sliderStatus }) {
      result {
        id
        sortIndex
        isActive
        softDelete
        children {
          childTranslations {
            image
            language
          }
        }
        translations {
          id
          title
          description
          language
        }
        location {
          id
          location
          locationCategoryNumberId
          locationCategoryStringId
        }
        slot {
          id
          isEmployerSlider
          isEmployeeSlider
          startTime
          endTime
        }
      }
      statistics {
        employeeCount
        employerCount
        activeSlider
        deactivateSlider
        allSlider
        expireSlider
      }
    }
  }
`;

export const GET_ONE_SLIDER = gql`
  query GetOneSlider($id: Int!) {
    getSliderById(getSliderByIdDto: { id: $id }) {
      id
      image
      isActive
      softDelete
      title
      description
      content {
        id
        language
        title
        description
      }
      destination {
        id
        action
        stringPayload
        numberPayload
        url
      }
    }
  }
`;

export const GET_SHOP_COMPANIES = gql`
  query getShopCompanies {
    getVerifiedForShopCompanyListAndDetails {
      listResponse {
        data {
          id
          companyName
        }
      }
    }
  }
`;
export const CHANGE_SLOT_ORDER = gql`
  mutation ChangeSlotOrder($sort: [ChangeSlidersSortPayload!]!) {
    changeSortSlider(changeSortDto: { sort: $sort }) {
      id
    }
  }
`;

export const CREATE_SLIDER = gql`
  mutation createSlider(
    $translations: [SliderTranslationDto!]!
    $location: SliderLocationDto!
    $slot: SliderSlotDto!
    $duration: Int
    $children: [CreateSliderChildDto!]!
    $isShuffledChildren: Boolean!
  ) {
    createSlider(
      createSliderDto: {
        translation: $translations
        location: $location
        slot: $slot
        duration: $duration
        children: $children
        isShuffledChildren: $isShuffledChildren
      }
    ) {
      id
    }
  }
`;

export const UPDATE_SLIDER = gql`
  mutation UpdateSlider(
    $id: Int!
    $isActive: Boolean!
    $translations: [UpdateSliderTranslationDto!]!
    $children: [UpdateSliderChildDto!]!
    $location: UpdateSliderLocationDto!
    $slot: UpdateSliderSlotDto!
    $duration: Int
    $isShuffledChildren: Boolean!
  ) {
    updateSlider(
      updateSliderDto: {
        id: $id
        isActive: $isActive
        translation: $translations
        location: $location
        slot: $slot
        children: $children
        duration: $duration
        isShuffledChildren: $isShuffledChildren
      }
    ) {
      id
      isActive
      children {
        id
        action
        linkType
        link
        stringPayload
        numberPayload
        sortIndex
      }
      location {
        id
        location
        locationCategoryNumberId
        locationCategoryStringId
      }
      slot {
        id
        isEmployerSlider
        isEmployeeSlider
        startTime
        endTime
      }
      translations {
        id
        title
        description
        language
      }
      duration
    }
  }
`;

export const DE_ACTIVE_SLIDER = gql`
  mutation DeActiveSlider($sliderId: Int!) {
    deactivateSlider(deactivateSliderDto: { sliderId: $sliderId }) {
      message
    }
  }
`;

export const ACTIVE_SLIDER = gql`
  mutation ActiveSlider($sliderId: Int!) {
    activeSlider(activeSliderDto: { sliderId: $sliderId }) {
      message
    }
  }
`;

export const GET_SLIDER_BY_ID = gql`
  query getSliderById($sliderId: Int!) {
    getSliderById(getSliderByIdDto: { sliderId: $sliderId }) {
      id
      isActive
      softDelete
      isShuffledChildren
      duration
      translations {
        id
        title
        description
        language
      }
      children {
        id
        action
        linkType
        link
        stringPayload
        numberPayload
        sortIndex
        childTranslations {
          id
          image
          imageName
          language
        }
      }
      location {
        id
        location
        locationCategoryNumberId
        locationCategoryStringId
      }
      slot {
        id
        isEmployerSlider
        isEmployeeSlider
        startTime
        endTime
      }
    }
  }
`;

export const DELETE_SLIDE = gql`
  mutation deleteSlide($sliderId: Int!) {
    deleteSlider(deleteSliderDto: { sliderId: $sliderId }) {
      message
    }
  }
`;

export const REFRESH_SLIDERS = gql`
  mutation RefreshSliders {
    syncSliders {
      send_sync
    }
  }
`;

export const UPDATE_DEFAULT_SETTING = gql`
  mutation setSlidersLocationDuration($duration: Int!) {
    setSlidersLocationDuration(durationDto: { duration: $duration }) {
      message
    }
  }
`;

export const GET_DEFAULT_SETTING = gql`
  query getSlidersLocationDuration {
    getSlidersLocationDuration {
      duration
    }
  }
`;

import { combineReducers } from 'redux';
import User from 'store/user/user.reducer';
import App from 'store/app/app.reducer';
import Tree from 'store/tree/tree.reducer';
const reducersReducer = combineReducers({
  User,
  App,
  Tree,
});

export default reducersReducer;

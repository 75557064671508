import { Loading } from 'components';
import Icon from 'components/icons';
import ImgToBackGround from 'components/imgToBackground';
import Link from 'components/link';
import { TableSectionTitle } from 'components/tables';
import DataTable from 'components/tables/dataTable';
import { t_toggleValueFunc, useToggle, useUpdateEffect } from 'hooks';
import PagesLayout from 'pages/components/layout';
import MagazineSort from 'pages/magazine/magazineSort';
import useMagazine from 'pages/magazine/useMagazine';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { APP_HELPER } from 'tools/enums';
import {
  New,
  add,
  date,
  detail,
  editions,
  last,
  list,
  magazine,
  name,
  publish,
  published,
} from 'tools/i18n/constants/i18n';
import { toDate } from 'tools/methods';

export default function Magazine() {
  const { t } = useTranslation();
  const { data, loading, error, refetch } = useMagazine();
  const [sort, toggleSort] = useToggle(false);
  const addNewTitle = `${t(add)} ${t(New)} ${t(magazine)}`;
  useUpdateEffect(() => {
    if (!sort) {
      refetch();
    }
  }, [sort]);
  if (error) {
    return (
      <div className="d-flex flex-column align-items-center p-5 mt-5 mx-auto">
        <h6 className="mb-5">{`Error: ${error?.message}`}</h6>
        <Button color="primary" onClick={() => refetch()}>
          Retry
        </Button>
      </div>
    );
  }
  return (
    <Loading loading={loading}>
      <PagesLayout newSectionButtonLabel={addNewTitle}>
        {sort ? (
          <MagazineSort toggleSort={toggleSort} magazines={data.result} />
        ) : (
          <MagazineTable toggleSort={toggleSort} loading={!!error} data={data} />
        )}
      </PagesLayout>
    </Loading>
  );
}

interface MagazineTableProps {
  data: { count: number; result: unknown[] };
  loading: boolean;
  toggleSort: t_toggleValueFunc;
}

const MagazineTable: FC<MagazineTableProps> = (props) => {
  const { data, loading, toggleSort } = props;
  const { t } = useTranslation();
  const title = (
    <div className="d-flex align-items-center">
      <span>{`${t(magazine)} ${t(list)}`}</span>
      <Button className="ml-3 rounded" color="primary" onClick={() => toggleSort()}>
        Activate Sort Mode
      </Button>
    </div>
  );
  //  const [filter, setFilter] = useState<filterOptions>(INITIAL_FILTER);
  /*  const filterOptions: t_filterOption[] = useMemo(() => {
    return [
      {
        name: 'allTypeOfSchools',
        icon: 'BookOpen',
        title: `${t(registered)} ${t(school)}`,
      },
      {
        name: 'registeredSchool',
        icon: 'BookOpen',
        title: `${t(registered)} ${t(school)}`,
      },
    ];
  }, []);*/
  /*const onFilter = useCallback((type: keyof filterOptions) => {
      console.log(type);
      setFilter((prev) => {
        return {
          ...INITIAL_FILTER,
          [type]: !prev[type],
        };
      });
    }, []);*/
  /*const onSearch = (key: string) => {
        refetch({
              query: key,
          }).then();
    };*/
  /*const onPageChange = (page: number) => {
        refetch({
              pageNumber: page,
          }).then();
    };*/

  const columnData = useMemo(() => {
    return [
      {
        name: <TableSectionTitle name="FiAperture" title={`${t(magazine)} ${t(name)}`} />,
        center: false,
        cell: (r: { name: string; id: number; logo: string }) => {
          return (
            <div className="table-cell-container d-flex align-items-center text-black py-2">
              {r.logo ? (
                <div className="img-lg rounded-circle">
                  <ImgToBackGround src={r?.logo} />
                </div>
              ) : (
                <Icon size="35px" Name="FiAperture" />
              )}

              <span className="ml-2">{r.name}</span>
            </div>
          );
        },
      },
      {
        name: <TableSectionTitle title={`${t(editions)} ${t(published)}`} name="FiLayers" />,
        selector: (r: { edition: unknown[] }) => r?.edition?.length || 0,
      },
      {
        name: <TableSectionTitle title={`${t(last)} ${t(editions)} ${t(publish)} ${t(date)}`} name="FiActivity" />,
        selector: (r: { edition?: any[] }) =>
          r.edition?.length
            ? toDate(r?.edition?.[r.edition?.length - 1].publishDate, false)
            : APP_HELPER.emptyDescription,
      },
      {
        name: '',
        center: false,
        cell: (r: { id: any }) => {
          return (
            <Link className="ml-auto text-white" to={'magazineDetail'} param={r.id.toString()}>
              <Button color="primary">{t(detail)}</Button>
            </Link>
          );
        },
      },
    ];
  }, []);
  return (
    <DataTable
      disabled={loading}
      searchable
      headerTitle={title}
      columns={columnData}
      paginationTotalRows={data.count}
      data={data.result}
    />
  );
};

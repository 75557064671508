import EmlakCategoryTree from 'pages/content-management/emlak/categories/components/EmlakCategoryTree';
import { t_emlakCategory, t_emlakCategoryChild } from 'pages/content-management/emlak/categories/type';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { d_selectedNode } from 'store/tree/tree.dispatchs';

interface EmlakCategoriesTreeProps {
  data: t_emlakCategory[];
  loading: boolean;
}

export function EmlakCategoryTable(props: EmlakCategoriesTreeProps) {
  const { data, loading } = props;
  const navigate = useNavigate();
  const handleSelect = (node: t_emlakCategory | t_emlakCategoryChild) => {
    d_selectedNode(node);
  };
  return (
    <>
      <EmlakCategoryTree
        style={{ maxHeight: '100%', overflowY: 'auto', width: '100%' }}
        data={data}
        onSelect={handleSelect}
        disabled={loading}
        itemHeight="45px"
        detailButton
        onDetailButton={(node) => {
          d_selectedNode(node);
          navigate(`${node.id}`);
        }}
      />
      {/*   <DataTable
      disabled={loading}
      data={data}
      columns={EMLAK_CATEGORY_TABLE_COLUMNS}
      headerTitle={`${t(emlakCategories)}`}
      searchable
      onSearch={onSearch}
    />*/}
    </>
  );
}

export default EmlakCategoryTable;

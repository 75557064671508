import { gql } from '@apollo/client';


export const Send_OTP = gql`
    mutation SendOtp ($email : String!){
        sendOTP (data : {
            email : $email
        }) 
    }
`

export const Verify_OTP = gql`
    mutation VerifyOtp($email : String! , $sentCode : String!){
        verifyOTP(data : {
            email : $email,
            sentCode : $sentCode
        }) {
            message
            userId
            companyId
        }
    }
`;

export const CREATE_ACCOUNT = gql`
    mutation CreateAccount(
        $firstName : String!,
        $lastName : String!,
        $password : String!,
        $email : String!,
        $companyName : String!
    ) {
        createLegalAccount(data : {
            firstName : $firstName,
            lastName : $lastName
            password : $password
            email : $email
        companyName : $companyName
        }) {
            message
            userId
            companyId
        }
    }
`;

export const ADD_OR_UPDATE_SPECIALITIES = gql`
    mutation addOrUpdateSpecialities(
        $userId : Int!,
        $companyId : Int!,
        $specialities : [String!]!
    ) {
        EmploymentAddOrUpdateEmployerSpecialities(addUpdateEmployerSpecialitiesDto : {
            userId : $userId,
            companyId : $companyId
        specialities : $specialities
        })
    }
`

export const COMPANY_SIZE_AND_STABLISHMENT = gql`
    mutation companySizeAndStablishment(
        $userId : Int!,
        $yearOfStablish : Int!,
        $companySizeId : Int!
    ) {
        mutationDetailsLegalAccount(data : {
            userId : $userId
        yearOfStablish : $yearOfStablish
        companySizeId : $companySizeId
        }) 
    }
`

export const INSERT_DESCRIPTION = gql`
    mutation InsertDescription($userId : Int!,$companyName : String!, $description : String! ) {
        insertDescriptionLegalAccount(data : {
            userId : $userId,
            companyName : $companyName
            description : $description
        }) 
    }
`;

export const INSERT_CONTACTS = gql`
    mutation InsertContacts(
        $userId : Int!,
        $primaryPhone : String!
        $primaryPhoneCode : String!
        $secondaryPhone : String,
        $secondaryPhoneCode : String,
        $websiteUrl : String,
        $noWebsite : Boolean!,
        $email : String!
    ) {
        mutationContactsLegalAccount(data : {
            userId : $userId,
            primaryPhone : $primaryPhone,
            primaryPhoneCode : $primaryPhoneCode,
            secondaryPhone : $secondaryPhone
            secondaryPhoneCode : $secondaryPhoneCode
            websiteUrl : $websiteUrl
            noWebsite : $noWebsite
            email : $email
        }) 
    }
`


export const INSERT_DETAILS = gql`
    mutation InsertDetails($userId : Int! , $yearOfStablish : Int! , $companySizeId : Int!) {
        mutationDetailsLegalAccount(data : { userId : $userId , yearOfStablish : $yearOfStablish, companySizeId : $companySizeId })
    }
`

export const ADD_COMPANY_ADDRESS = gql`
    mutation AddCompanyAddress(
        $userId : Int!,
        $companyId : Int!,
        $title : String!,
        $city : String!,
        $county : String!,
        $postalCode : String,
        $fullAddress : String!
    ) {
        EmploymentAddEmployerAddress(addEmployerAddressDto : {
            userId : $userId
            companyId : $companyId,
            address : {
        title : $title,
        city : $city,
        county : $county,
        postalCode : $postalCode,
        fullAddress : $fullAddress
            }
        }) {
            id
        }
    }
`;

export const DELETE_COMPANY_ADDRESS = gql`
    mutation DeleteCompanyAddress($userId: Int!, $companyId: Int!, $addressId: String!) {
        EmploymentDeleteEmployerAddress(deleteEmployerAddressDto: {
            userId: $userId,
            companyId: $companyId,
            addressId: $addressId 
        }) 
    }
`;

export const AddOrUpdateWorkingDays = gql`
    mutation AddOrUpdateWorkingDays(
       $userId : Int!,
        $companyId : Int!
        $sunday : Boolean, 
        $monday : Boolean, 
        $tuesday : Boolean, 
        $wednesday : Boolean, 
        $thursday : Boolean, 
        $friday : Boolean, 
        $saturday : Boolean, 
        $startAt : String!,
        $endAt : String!
    ) {
        EmploymentAddOrUpdateEmployerWorkingDaysAndHours(addOrUpdateEmployerWorkingDaysAndHoursDto : {
            userId : $userId,
            companyId : $companyId,
            workingDaysAndHours : {
        sunday : $sunday ,
                monday : $monday,
        tuesday:$tuesday,
        wednesday: $wednesday,
        thursday : $thursday ,
        friday : $friday,
        saturday: $saturday ,
        startAt : $startAt,
        endAt : $endAt
            }
        })
    }
`



export const ADD_COMPANY_DESCRIPTION = gql`
  mutation AddCompanyDescription (
      $userId : Int!,
      $companyName : String!,
      $description : String!
  ) {
      mutationDescriptionLegalAccount(data : {
          userId : $userId,
          companyName : $companyName
          description : $description
      })
  }
`



export const ADD_SOCIAL_MEDIA = gql`
    mutation AddSocialMedia(
        $userId : Int!,
        $socialMedia : [MediaMutationDto!]!
        $dontHaveASocialMedia: Boolean
    ) {
        mutationSocialMediaLegalAccount(data : {
            userId : $userId,
            socialMedia: $socialMedia
            dontHaveASocialMedia: $dontHaveASocialMedia
        })
    }
`












import { useTranslation } from 'react-i18next';
import { TableSectionTitle } from 'components/tables';
import { detail, documentTypes, hidden, list, required, title } from 'tools/i18n/constants/i18n';
import { t_companySize } from 'pages/content-management/company/companySizes/useCompanySizes';
import Link from 'components/link';
import { Button, Col, Row } from 'reactstrap';
import DataTable from 'components/tables/dataTable';
import React from 'react';
import { Loading } from 'components';
import PagesLayout from 'pages/components/layout';
import useDocumentTypes from 'pages/content-management/company/documentTyps/useDocumentTypes';
import { t_languageKeys } from 'tools/types';

export type t_documentType = {
  id: number;
  isRequired: boolean;
  isHidden: boolean;
  language: {
    id: number;
    language: t_languageKeys;
    name: string;
  }[];
};
export default function DocumentTypes() {
  const { data, loading, addNewTitle } = useDocumentTypes();
  return (
    <Loading loading={loading}>
      <Row>
        <Col xxl={11} xl={11} md={12} className="mx-auto">
          <PagesLayout newSectionButtonLabel={addNewTitle}>
            <DocumentTypesTable data={data} />
          </PagesLayout>
        </Col>
      </Row>
    </Loading>
  );
}

interface DocumentTypesTableProps {
  data?: any[];
}

function DocumentTypesTable(props: DocumentTypesTableProps) {
  const { data = [] } = props;
  const { t } = useTranslation();
  const columns = [
    {
      name: <TableSectionTitle name="FiUser" title={t(title)} />,
      center: false,
      cell: (r: t_documentType) => {
        const title = r.language.find((item) => item.language === 'tr')?.name;
        return <span className="text-black">{title}</span>;
      },
    },
    {
      name: <TableSectionTitle name="FiUser" title={t(required)} />,
      center: false,
      cell: (r: t_documentType) => {
        return <span className="text-black">{r.isRequired ? 'Required' : 'No Required'}</span>;
      },
    },
    {
      name: <TableSectionTitle name="FiUser" title={t(hidden)} />,
      center: false,
      cell: (r: t_documentType) => {
        return <span className="text-black">{r.isHidden ? 'Hidden' : 'Appear'}</span>;
      },
    },
    {
      name: '',
      center: false,
      cell: (r: t_companySize) => {
        return (
          <Link to="ageRangeDetail" className="ml-auto" param={r.id}>
            <Button color="primary">{t(detail)}</Button>
          </Link>
        );
      },
    },
  ];
  return <DataTable headerTitle={`${t(documentTypes)} ${t(list)}`} disabled={false} columns={columns} data={data} />;
}

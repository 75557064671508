import { Loading } from 'components';
import PagesLayout from 'pages/components/layout';
import DataTables, { TableSectionTitle } from 'components/tables/dataTable';
import React from 'react';
import { ApolloError } from '@apollo/client';
import Link from 'components/link';
import { Button, Col } from 'reactstrap';
import { detail, rejectionReason } from 'tools/i18n/constants/i18n';
import { useTranslation } from 'react-i18next';
import { t_deleteReason } from 'pages/user-management/banReasons/useBanReasons';
import useGuideReason from './useCompanyReasons';

export default function CompanyLeaderReasons() {
  const { error, data, loading, fetchMore, refetch, addButtonText, tableTitle } = useGuideReason();
  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <Loading loading={loading}>
        <BanReasonTable
          tableTitle={tableTitle}
          refetch={refetch}
          addButtonText={addButtonText}
          fetchMore={fetchMore}
          data={data}
          error={error}
        />
      </Loading>
    </Col>
  );
}

interface BanReasonTableProps {
  error?: ApolloError;
  data: t_deleteReason[];
  fetchMore: any;
  refetch: any;
  addButtonText: string;
  tableTitle: string;
}

function BanReasonTable(props: BanReasonTableProps) {
  const { addButtonText, data, error, tableTitle } = props;
  const { t } = useTranslation();
  const tableColumn = [
    {
      name: <TableSectionTitle name="FiUser" title={t(rejectionReason)} />,
      center: false,
      cell: (r: t_deleteReason) => {
        return r.language?.find((item) => item.language === 'tr')?.content;
      },
    },
    {
      name: '',
      cell: (r: t_deleteReason) => {
        return (
          <Link className="text-white ml-auto" to="banReasonDetail" param={r?.id?.toString()}>
            <Button color="primary">{t(detail)}</Button>
          </Link>
        );
      },
    },
  ];
  return (
    <PagesLayout newSectionButtonLabel={addButtonText}>
      <DataTables columns={tableColumn} data={data} headerTitle={tableTitle} disabled={!!error} />
    </PagesLayout>
  );
}

import { GET_ADS_DETAILS } from 'services/graphql/queries/ads';
import { useParams } from 'react-router-dom';
import useQuery from 'hooks/useQuery';

export default function useAdsDetails() {
  const { type } = useParams();
  const { data, error, loading, refetch } = useQuery(GET_ADS_DETAILS, {
    variables: {
      id: type,
    },
  });
  return { data: data?.EmploymentAdDetails, error, loading, refetch };
}

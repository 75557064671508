import turkeyFlag from 'assets/images/buluro/country/icons8-turkey-48.png';
import defaultLogo from 'assets/images/buluro/dashboard/default.svg';
import { APP_ROUTE_NAME, ROLES } from 'tools/enums';
import { T } from 'tools/i18n';
import { art, high, middle, primary, school } from 'tools/i18n/constants/i18n';
import { t_country, t_languages, t_languages_dropdown } from 'tools/types';

export const DEFAULT_LOGO = defaultLogo;
export const COUNTRIES: t_country[] = [
  {
    label: 'Turkey',
    value: '+90',
    key: 'tr',
    language: 'tr',
    flag: turkeyFlag,
  },
];
export const LANGUAGES: t_languages[] = [
  {
    country: COUNTRIES[0],
    language: 'tr',
    label: 'Turkish',
    value: 'tr',
  },
  {
    country: COUNTRIES[0],
    language: 'en',
    label: 'English',
    value: 'en',
  },
];
export const LANGUAGES_DROPDOWN: t_languages_dropdown[] = LANGUAGES.map((l) => ({ ...l, isDisabled: false }));
export const IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/jpg'];

export const COOKIE_KEYS = {
  token: 'token',
  refreshToken: 'refreshToken',
  message: 'message',
};
export const STORAGE_KEYS = {
  i18nextLng: 'i18nextLng',
  theme: 'theme',
};
export const DEFAULT_COUNTRY = COUNTRIES[0];
export const ROLES_LIST = [
  { label: `Admin`, value: ROLES.ADMIN },
  { label: `Super Admin`, value: ROLES.SUPER_ADMIN },
  { label: `School Admin`, value: ROLES.SCHOOL_ADMIN },
];
export const SCHOOLS = [
  {
    label: `${T(primary)} ${T(school)}`,
    value: '1',
  },
  {
    label: `${T(middle)} ${T(school)}`,
    value: '2',
  },
  {
    label: `${T(high)} ${T(school)}`,
    value: '3',
  },
  {
    label: `${T(art)} ${T(school)}`,
    value: '4',
  },
];

export const APP_PAGE_LAYOUT_RESPONSIVE_SIZES = {
  xs: 12,
};
export const WEEK_DAY = [
  {
    label: 'Mon',
    value: 'monday',
  },
  {
    label: 'Tue',
    value: 'tuesday',
  },
  {
    label: 'Wed',
    value: 'wednesday',
  },
  {
    label: 'Thu',
    value: 'thursday',
  },
  {
    label: 'Fri',
    value: 'friday',
  },
  {
    label: 'SAT',
    value: 'saturday',
  },
  {
    label: 'Sun',
    value: 'Sunday',
  },
];

export const ERROR_CODES = {
  401: 401,
  403: 403,
};

export const PAGE_DISPLAY_NAME: Record<keyof typeof APP_ROUTE_NAME, string> = {
  dashboard: 'Dashboard',
  jobCategories: 'Job Category',
  address: 'Address',
  admins: 'Admins',
  analytics: 'Analytics',
  bazzarAds: 'Bazzar Ads',
  bazzarAdsApplyList: 'Bazzar Ads Apply List',
  bazzarAdsDetail: 'Bazzar Ads Detail',
  bazaarCategories: 'Bazzar Categories',
  companies: 'Companies',
  companiesDetails: 'Company Detail',
  companyCategories: 'Company Categories',
  companyLogos: 'Company Logos',
  companySizes: 'Company Sizes',
  employee: 'Employee',
  employer: 'Employer',
  experienceExpectation: 'Experience Expectation',
  ageRangeDetail: 'Age Range Detail',
  ageRange: 'Age Range',
  languages: 'Languages',
  exhibition: 'Exhibition',
  exhibitionDetails: 'Exhibition Details',
  hotel: 'Hotel',
  hotelDetail: 'Detail',
  jobAds: 'Job Ads',
  jobAdsDetail: 'Detail',
  jobApplyListDetails: 'Details',
  location: 'Location',
  locationCity: 'City',
  locationDetail: 'Detail',
  magazine: 'Magazine',
  magazineDetail: 'Detail',
  magazineDetailEdition: 'Edition Detail',
  notification: 'Notification',
  schoolDetail: 'Detail',
  schools: 'Schools',
  setting: 'Setting',
  softwares: 'Softwares',
  universities: 'Universities',
  universitySections: 'University Sections',
  users: 'Users',
  usersDetails: 'Users Details',
  logOut: 'Log Out',
  bazaarAdsReport: 'Bazaar Ads Report',
  companySizeDetail: 'Company Size Detail',
  deleteReasons: 'Delete Reasons',
  deleteReasonsDetail: 'Delete Reasons Detail',
  documentTypeDetail: 'Document Type Detail',
  documentTypes: 'Document Types',
  employeeDetail: 'Employee Detail',
  jobAdsReport: 'Job Ads Report',
  mobileAppOsVersions: 'Mobile App Os Versions',
  notificationDetail: 'Notification Detail',
  slider: 'Slider',
  workRejectionReasons: 'Work Rejection Reasons',
  emlakCategories: 'Emlak Categories',
  emlakCategoryDetail: 'Emalk Category Details',
  emlakTypes: 'Emlak Types',
  emlakTypesDetails: 'Emlak Type Details',
  sliderDetail: 'Slider Details',
  specialLocation: 'Special Location',
  specialLocationDetail: 'Special Location Details',
  adminDetail: 'Admin Detail',
  bazaarAdsReportDetail: 'Bazaar Ads Report Detail',
  bazaarCategoriesDetails: 'Bazaar Category Detail',
  companyCategoryDetail: 'Company Category Detail',
  emlakAds: 'Emlak Ads',
  emlakAdsDetail: 'Emlak Ads Detail',
  experienceExpectationDetails: 'Experience Expection Detail',
  jobAdsReportDetail: 'Job Ads Report Detail',
  jobCategoryDetails: 'Job Category Detail',
  languagesDetails: 'Language Detail',
  softwaresDetails: 'Software Detail',
  universitiesDetails: 'University Detail',
  universitySectionsDetails: 'University Section Detail',
  workRejectionReasonsDetails: 'Work Rejection Reason Detail',
  emlakAdsReportReasons: 'Emlak Ads Report Reasons',
  emlakAdsReportReasonsDetail: 'Detail',
};

export const YEAR_REGEXP = /^(19[5-9]\d|20[0-4]\d|2050)$/;

export const WEBSITE_REGEXP =
  /* eslint-disable */
  /(?:(?:http|https):\/\/)?([-a-zA-Z0-9.]{2,256}\.[a-z]{2,4})\b(?:\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/;
export const WEBSITE_WITH_PROTOCOL_REG_EXP = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

export const LATITUDE_REGEXP = new RegExp(/^-?([0-8]?[0-9]|90)(\.[0-9]{1,10})?$/);
export const LONGITUDE_REGEXP = new RegExp(/^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,10})?$/);
export const EMAIL_REGEXP = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
export const NUMBER_REGEXP = new RegExp(/^[0-9]+$/);
export const PHONE_NUMBER_REGEXP = new RegExp(/^[1-9]\d*$/);
export const PHONE_NUMBER_STRING_REGEXP = new RegExp(/^[1-9][\d]*$/);
export const NUMBER2_REGEXP = new RegExp(/\D/g);
export const NUMBER_AS_STRING_REGEXP = new RegExp(/^\d+$/);
export const EXCEPT_NUMBERS = new RegExp(/[^0-9]/g);
export const ONLY_NUMBERS_AND_SPECIAL_CHARACTER = new RegExp(/^[0-9!@#$%^&*()\-+=\[\]{}|\\,.?:;<>/_~`'"\\s]*$/);
export const ALL_EXCEPT_NUMBER_SPECIAL_CHARACTER = new RegExp(/^[^0-9!@#$%^&*()\-+=\[\]{}|\\,.?:;<>/_~`'"\\s]*$/g);
export const PASSWORD_REGEXP = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/);
export const ONLY_CHARACTER_REGEXP = new RegExp(/^[A-Za-z\s]+$/);
export const CHARACTER_NUMBER_REGEXP = new RegExp(/^[a-zA-Z0-9\s]*$/);
export const SPECIAL_CHARACTER_REGEXP = new RegExp(/[^\w\s]/);
export const ONLY_NUMBERS_CHARACTER_SPACE = new RegExp(/[^a-zA-Z0-9\s]/g);
export const ONLY_CHARACTERS_SPACES = new RegExp(/[a-zA-Z\s]/g);

export const NON_EMPTY_VALUES = new RegExp(/\S/);

export function currentYear() {
  return new Date().getUTCFullYear();
}
export function YEARS_DROPDOWN() {
  let years: { label: string; value: string }[] = [];
  for (let i = 1800; i <= currentYear(); i++) {
    years.push({ label: i.toString(), value: i.toString() });
  }
  return years.reverse();
}
export const MONTH_DROPDOWN = [
  {
    label: 'January',
    value: 1,
  },
  {
    label: 'February',
    value: 2,
  },
  {
    label: 'March',
    value: 3,
  },
  {
    label: 'April',
    value: 4,
  },
  {
    label: 'May',
    value: 5,
  },
  {
    label: 'June',
    value: 6,
  },
  {
    label: 'July',
    value: 7,
  },
  {
    label: 'August',
    value: 8,
  },
  {
    label: 'September',
    value: 9,
  },
  {
    label: 'October',
    value: 10,
  },
  {
    label: 'November',
    value: 11,
  },
  {
    label: 'December',
    value: 12,
  },
];

export function YEARS_DROPDOWN_FROM_NOW(year: number) {
  const targetYear = year + 5;
  const years: { label: number; value: number }[] = [];
  for (let i = year; i <= targetYear; i++) {
    years.push({ label: i, value: i });
  }
  return years;
}

import useQuery from 'hooks/useQuery';
import { GET_MAGAZINE_LIST } from 'services/graphql/queries/magazine';
import { t_languageKeys } from 'tools/types';

export type edition = {
  id: number;
  title: string;
  file: string;
  cover: string;
  size: number;
  pages: number;
  publishDate: string;
  softDelete: string;
  createdAt: string;
  updatedAt: string;
  language: {
    id: number;
    language: t_languageKeys;
    description: string;
  }[];
};

export type magazine = {
  id: number;
  name: string;
  logo: string;
  createdAt: string;
  updatedAt: string;
  edition: edition[];
  sortIndex: number;
};

export default function useMagazine() {
  const { data, error, loading, refetch } = useQuery<{ MagazineList: { count: number; result: magazine[] } }>(
    GET_MAGAZINE_LIST
  );
  return { data: data?.MagazineList || { count: 0, result: [] }, error, loading, refetch };
}

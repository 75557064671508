import DatePicker, { DayValue } from '@hassanmojab/react-modern-calendar-datepicker';
import FormActionLoading, { step } from 'components/formActionLoading';
import { DocumentNode } from 'graphql/language';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import sweetalert2 from 'sweetalert2';
import { Loading } from '../../../components';
import SubmitButton from '../../../components/buttons/submitButton';
import { useMutation, useToggle, useUpdateEffect } from '../../../hooks';
import FetchApi from '../../../services/api/axios';
import {
  ACTIVE_SLIDER,
  CREATE_SLIDER,
  DELETE_SLIDE,
  DE_ACTIVE_SLIDER,
  UPDATE_SLIDER,
} from '../../../services/graphql/queries/sliders';
import { convertDate } from '../../../tools/methods';
import PagesLayout from '../../components/layout';
import useDetailSlider from '../useSliderDetails';
import PayloadSelector from './components/payloadSelector';
import {
  ACIONS_TYPE_OPTION,
  ACTION_DETAILS_LIST,
  addTimeToDate,
  DEFAULT_END_TIME,
  DEFAULT_START_TIME,
  DEFAULT_STEP,
  LOCATION_ACTION_DETAILS_LIST,
  LOCATION_OPTIONS,
  USER_TYPE_OPTIONS,
} from './entity';
import { ImageContainer } from './styles';
import {
  SliderActionsEnum,
  actionTypeEnum,
  childrenType,
  slider,
  sliderLocationEnum,
  sliderUserTypeEnum,
  t_actionDetailList,
  t_newSlider,
  t_sliderFiles,
  t_updateSlider,
} from './types';
import Picker from './components/picker';
import Icon from '../../../components/icons';
import { register } from 'module';
import SliderChildrenContent from './components/childrenContent';
import { language } from 'tools/i18n/constants/i18n';
import { TimePicker } from 'antd';
import Dropzone from 'react-dropzone';
type stepInfo = {
  index: number;
  pending: boolean;
  completed: boolean;
  error: boolean;
  fileName?: string;
  percent?: number;
};
const fiveMb = 5242880;

export default function SliderDetailContainer() {
  const { isLoading, data, isNew, refetch, paramLoading } = useDetailSlider();
  return (
    <Loading loading={isLoading || paramLoading}>
      <SliderDetails isNew={isNew} data={data} isLoading={isLoading} refetch={refetch} />
    </Loading>
  );
}

interface SliderDetailsProps {
  isLoading: boolean;
  data: slider | undefined;
  isNew: boolean;
  refetch: any;
}

function SliderDetails({ isNew, refetch, data, isLoading }: SliderDetailsProps) {
  const [selectedDate, setSelectedDate] = useState<{
    startDate: DayValue | null;
    endDate: DayValue | null;
  }>({ endDate: null, startDate: null });
  const [files, setFiles] = useState<t_sliderFiles>({
    englishFile: undefined,
    turkishFile: undefined,
  });
  const [enabelSort, setEnabelSort] = useState(false);

  const [childrenData, setChildrenData] = useState<childrenType[]>(
    isNew
      ? [
          {
            // id: 0,
            link: undefined,
            action: undefined,
            stringPayload: undefined,
            sortIndex: 0,
            numberPayload: undefined,
            linkType: undefined,
            translations: [
              {
                image: '',
                language: 'en',
                imageUrl: '',
              },
              {
                image: '',
                language: 'tr',
                imageUrl: '',
              },
            ],
          },
        ]
      : (data?.children
          // ?.sort((a, b) => a.sortIndex - b.sortIndex)
          .map((item, index) => ({
            sortIndex: item.sortIndex,
            action: item.action,
            id: item.id,
            link: item.link,
            linkType: item.linkType,
            numberPayload: item.numberPayload,
            stringPayload: item.stringPayload,
            translations: item.childTranslations
              ?.sort((a, b) => a.language?.localeCompare(b.language))
              ?.map((item) => ({
                language: item.language,
                image: item.imageName,
                imageUrl: item.image,
              })),
          })) as any)
  );

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [openUILoading, toggleUILoading] = useToggle(false);
  const [loading, toggleLoading] = useToggle(false);
  const [createExecute] = useMutation(CREATE_SLIDER);
  const [updateExecute] = useMutation(UPDATE_SLIDER);
  const [deactiveExecute] = useMutation(DE_ACTIVE_SLIDER);
  const [activeExecute] = useMutation(ACTIVE_SLIDER);
  const [deleteExecute] = useMutation(DELETE_SLIDE);

  const [stepsInfo, setStepsInfo] = useState<stepInfo[]>(DEFAULT_STEP);

  const handleChangeStepInfo = useCallback((step: 1 | 2 | 3, changeKey: Partial<Record<keyof stepInfo, any>>) => {
    setStepsInfo((prev) => {
      return prev.map((info) => {
        if (info.index !== step) return info;
        return {
          ...info,
          ...changeKey,
        };
      });
    });
  }, []);

  const DEFAULT_VALUES: t_newSlider & t_updateSlider = {
    id: data?.id,
    isActive: data?.isActive,
    isShuffledChildren: data?.isShuffledChildren || false,
    translations: data?.translations.sort((a) => (a.language === 'tr' ? 1 : -1)),
    // destination: {
    //   id: data?.destination.id,
    //   link: data?.destination.link,
    //   action: data?.destination.action as SliderActionsEnum,
    //   stringPayload: data?.destination.stringPayload,
    //   numberPayload: data?.destination.numberPayload,
    //   linkType: data?.destination.linkType as actionTypeEnum,
    // },
    children: [],
    location: {
      id: data?.location.id,
      location: (data?.location.location as sliderLocationEnum) || (searchParams.get('location') as sliderLocationEnum),
      locationCategoryStringId: data?.location.locationCategoryStringId,
      locationCategoryNumberId: data?.location.locationCategoryNumberId,
    },
    slot: {
      id: data?.slot.id,
      userType: data
        ? data?.slot.isEmployerSlider
          ? sliderUserTypeEnum.EMPLOYER
          : sliderUserTypeEnum.EMPLOYEE
        : (searchParams.get('userType') as sliderUserTypeEnum),
      startTime: convertDate(data?.slot?.startTime),
      endTime: convertDate(data?.slot?.endTime),
    },
    duration: data?.duration,
  };
  const {
    handleSubmit,
    formState: { errors },
    control,
    setError,
    clearErrors,
    getValues,
    setValue,
    watch,
  } = useForm<t_newSlider & t_updateSlider>({
    defaultValues: DEFAULT_VALUES,
  });

  const lastFetchData = useRef<
    Record<'formInput' | 'enImage' | 'trImage', (t_newSlider & t_updateSlider) | { data: any; url?: string }>
  >({
    formInput: DEFAULT_VALUES as t_newSlider & t_updateSlider,
    enImage: { data: DEFAULT_VALUES.translations?.[0].image },
    trImage: { data: DEFAULT_VALUES.translations?.[1].image },
  });

  const selectedAction = (data: childrenType): Required<t_actionDetailList> | undefined => {
    if (data.linkType === actionTypeEnum.NO_ACTION || data.linkType === actionTypeEnum.EXTERNAL_LINK) {
      return undefined;
    }
    const actionDetail = ACTION_DETAILS_LIST.find((act) => act.enum === data.action);
    if (!!actionDetail?.is_string_payload || actionDetail?.is_integer_payload) {
      return actionDetail as Required<t_actionDetailList>;
    }

    return undefined;
  };

  const onSubmit: SubmitHandler<t_newSlider> = (data) => {
    setEnabelSort(false);

    if (Object.keys(errors).length) return;

    // const isEnFit = (data.children[0].translations?.[0].image as File).size <= fiveMb;
    // const isTrFit = (data.translations?.[1].image as File).size <= fiveMb;
    // if (!isEnFit) {
    //   setError('translations.0.image', { message: 'Size Should be equal or lower than 5 mb' });
    //   return;
    // }
    // if (!isTrFit) {
    //   setError('translations.1.image', { message: 'Size Should be equal or lower than 5 mb' });
    //   return;
    // }
    let flagImageError = false;
    childrenData?.map((item, index) => {
      item.translations?.map((item, index2) => {
        if (!item.image) {
          //@ts-ignore
          setError(`children.${index}.translations.${index2}.image`, {
            message: 'Please Select a Image',
          });
          flagImageError = true;
        }
      });
    });

    let flagTypeError = false;
    childrenData?.map((item, index) => {
      if (!item.linkType) {
        setError(`children.${index}.linkType`, { message: 'Please Specify Action Type' });
        flagTypeError = true;
      }
    });

    let flagActionError = false;
    childrenData?.map((item, index) => {
      if (!item.action && item.linkType === actionTypeEnum.INTERNAL_LINK) {
        setError(`children.${index}.action`, { message: 'Please Specify Action' });
        flagActionError = true;
      }
    });

    let flagPayloadError = false;
    childrenData?.map((item, index) => {
      if (selectedAction(item) && !item.numberPayload && !item.stringPayload) {
        setError(`children.${index}.numberPayload`, { message: 'Please Specify Payload' });
        flagPayloadError = true;
      }
    });

    let flagLinkError = false;
    childrenData?.map((item, index) => {
      if (!item.link && item.linkType === actionTypeEnum.EXTERNAL_LINK) {
        setError(`children.${index}.link`, { message: 'Please Specify Link' });
        flagLinkError = true;
      }
    });

    if (flagTypeError || flagActionError || flagImageError || flagPayloadError || flagLinkError) {
      return;
    }

    if (!childrenData.length) {
      setError('children', { message: 'Please Add One Slide' });
      return;
    }
    if (!childrenData.length) {
      setError('children', { message: 'Please Add One Slide' });
      return;
    }
    if (data.slot?.endTime && data.slot?.startTime) {
      if (new Date(data?.slot?.startTime).getTime() > new Date(data.slot?.endTime).getTime()) {
        setError('slot.startTime', { message: 'Start Time Should be lower or equal than end time' });
        return;
      }
    }
    const children = childrenData?.map((t, index) => ({
      action: t.action || undefined,
      link: t.link || undefined,
      linkType: t.linkType || undefined,
      numberPayload: t.numberPayload || undefined,
      sortIndex: t.sortIndex,
      stringPayload: t.stringPayload || undefined,
      translations: t.translations.map((item) => ({
        image: item.image,
        language: item.language,
      })),
    }));

    const translations = data?.translations?.map((t, index) => ({
      ...t,
      language: index === 0 ? 'en' : 'tr',
      // image: (t?.image as File)?.name,
    }));

    toggleLoading(true);
    // toggleUILoading(true);
    handleChangeStepInfo(1, { pending: true, completed: false, error: false });
    // const destination = {
    //   ...data?.destination,
    //   action:
    //     data?.destination?.linkType === actionTypeEnum.NO_ACTION
    //       ? SliderActionsEnum.NONE
    //       : data?.destination?.linkType === actionTypeEnum.EXTERNAL_LINK
    //       ? SliderActionsEnum.EXTERNAL_LINK
    //       : data?.destination?.action,
    // };
    const slot = {
      ...data.slot,
      startTime: data.slot?.startTime || undefined,
      endTime: data.slot?.endTime || undefined,
    };
    createExecute({
      variables: { ...data, translations, children, slot, duration: Number(data.duration) || null },
    })
      .then(async (result) => {
        console.log(result);

        toggleLoading(false);
        handleChangeStepInfo(1, { pending: false, completed: true, error: false });

        navigate(
          `/dashboard/contentManagement/slider?userType=${searchParams.get('userType')}&location=${searchParams.get(
            'location'
          )}`
        );
        //   toggleLoading(false);
        //   toggleUILoading(false);
      })
      .catch((error) => {
        toast.error(error?.message);
        toggleLoading(false);
        toggleUILoading(false);
      })
      .finally(() => {
        toggleLoading(false);
        toggleUILoading(false);
      });
  };
  const onUpdate: SubmitHandler<t_newSlider> = (data) => {
    setEnabelSort(false);
    console.log(data);

    if (Object.keys(errors).length) return;
    // const isEnFit =
    //   typeof data.translations?.[0].image !== 'string' ? (data.translations?.[0].image as File)?.size <= fiveMb : true;
    // const isTrFit =
    //   typeof data.translations?.[1].image !== 'string' ? (data.translations?.[1].image as File)?.size <= fiveMb : true;
    if (!childrenData.length) {
      setError('children', { message: 'Please Add One Slide' });
      return;
    }

    // if (!isTrFit) {
    //   setError('translations.1.image', { message: 'Size Should be equal or lower than 5 mb' });
    //   return;
    // }

    let flagImageError = false;
    childrenData?.map((item, index) => {
      item.translations?.map((item, index2) => {
        if (!item.image) {
          //@ts-ignore
          setError(`children.${index}.translations.${index2}.image`, {
            message: 'Please Select a Image',
          });
          flagImageError = true;
        }
      });
    });

    let flagTypeError = false;
    childrenData?.map((item, index) => {
      if (!item.linkType) {
        setError(`children.${index}.linkType`, { message: 'Please Specify Action Type' });
        flagTypeError = true;
      }
    });

    let flagActionError = false;
    childrenData?.map((item, index) => {
      if (!item.action && item.linkType === actionTypeEnum.INTERNAL_LINK) {
        setError(`children.${index}.action`, { message: 'Please Specify Action' });
        flagActionError = true;
      }
    });

    let flagLinkError = false;
    childrenData?.map((item, index) => {
      if (!item.link && item.linkType === actionTypeEnum.EXTERNAL_LINK) {
        setError(`children.${index}.link`, { message: 'Please Specify Link' });
        flagLinkError = true;
      }
    });

    let flagPayloadError = false;
    childrenData?.map((item, index) => {
      if (selectedAction(item) && !item.numberPayload && !item.stringPayload) {
        setError(`children.${index}.numberPayload`, { message: 'Please Specify Payload' });
        flagPayloadError = true;
      }
    });

    if (flagTypeError || flagActionError || flagImageError || flagPayloadError || flagLinkError) {
      return;
    }

    const variables = {
      ...data,
      // destination: {
      //   ...data.destination,
      //   id: DEFAULT_VALUES.destination?.id,
      //   link: data.destination?.link,
      //   stringPayload:
      //     data?.destination?.linkType === actionTypeEnum.NO_ACTION ||
      //     data?.destination?.linkType === actionTypeEnum.EXTERNAL_LINK
      //       ? undefined
      //       : data.destination?.stringPayload,
      //   numberPayload:
      //     data?.destination?.linkType === actionTypeEnum.NO_ACTION ||
      //     data?.destination?.linkType === actionTypeEnum.EXTERNAL_LINK
      //       ? undefined
      //       : data.destination?.numberPayload,
      //   action:
      //     data?.destination?.linkType === actionTypeEnum.NO_ACTION
      //       ? SliderActionsEnum.NONE
      //       : data?.destination?.linkType === actionTypeEnum.EXTERNAL_LINK
      //       ? SliderActionsEnum.EXTERNAL_LINK
      //       : data?.destination?.action,
      // },
      isShuffledChildren: data?.isShuffledChildren || false,
      duration: Number(data.duration) || null,
      children: childrenData.map((item) => ({
        ...item,
        translations: item.translations.map((item2) => ({
          image: item2.image,
          language: item2.language,
        })),
      })),
      location: {
        ...data.location,
        id: DEFAULT_VALUES.location?.id,
      },
      slot: {
        ...data.slot,
        startTime: data.slot?.startTime || undefined,
        endTime: data.slot?.endTime || undefined,
        id: DEFAULT_VALUES.slot?.id,
      },
      isActive: DEFAULT_VALUES.isActive,
      id: DEFAULT_VALUES.id,
      translations: data.translations?.map((_, i) => {
        if (i === 0) {
          return {
            title: _.title,
            description: _.description,
            id: DEFAULT_VALUES.translations?.[0].id,
            language: 'en',
          };
        } else {
          return {
            title: _.title,
            description: _.description,
            id: DEFAULT_VALUES.translations?.[1].id,
            language: 'tr',
          };
        }
      }),
    };

    if (variables.slot?.endTime && variables.slot?.startTime) {
      if (new Date(variables?.slot?.startTime).getTime() > new Date(variables.slot?.endTime).getTime()) {
        setError('slot.startTime', { message: 'Start Time Should be lower or equal than end time' });
        return;
      }
    }
    toggleLoading(true);
    // toggleUILoading(true);
    handleChangeStepInfo(1, { pending: true, completed: false, error: false });
    updateExecute({
      variables,
    })
      .then(async (result) => {
        console.log(result);

        if (result) {
          handleChangeStepInfo(1, { pending: false, completed: true, error: false });

          navigate(
            `/dashboard/contentManagement/slider?userType=${searchParams.get('userType')}&location=${searchParams.get(
              'location'
            )}`
          );
          toast.success('Slider Updated Successfully');
          toggleLoading(false);
          // toggleUILoading(false);
          setStepsInfo(DEFAULT_STEP);
          const refetchData = await refetch({
            sliderId: DEFAULT_VALUES.id,
          });
          setValue('translations', refetchData?.data?.getSliderById?.translations);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.message);
        toggleLoading(false);
        // toggleUILoading(false);
      })
      .finally(() => {
        toggleLoading(false);
      });
  };
  // const selectedAction = (): Required<t_actionDetailList> | undefined => {
  //   if (
  //     watch('destination.linkType') === actionTypeEnum.NO_ACTION ||
  //     watch('destination.linkType') === actionTypeEnum.EXTERNAL_LINK
  //   ) {
  //     return undefined;
  //   }
  //   const actionDetail = ACTION_DETAILS_LIST.find(
  //     (act) => act.enum === (watch('destination.action') || DEFAULT_VALUES.destination?.action)
  //   );
  //   if (!!actionDetail?.is_string_payload || actionDetail?.is_integer_payload) {
  //     return actionDetail as Required<t_actionDetailList>;
  //   }

  //   return undefined;
  // };
  const loadingSteps: step[] = [
    {
      index: 1,
      title: 'Upload Form Input',
      fileName: '',
      status: stepsInfo[0].error
        ? 'FAILED'
        : stepsInfo[0].pending
        ? 'UPLOADING'
        : stepsInfo[0].completed
        ? 'COMPLETED'
        : 'WAITING',
      onRetry: () =>
        isNew
          ? onSubmit(lastFetchData.current.formInput as t_newSlider & t_updateSlider)
          : onUpdate(lastFetchData.current.formInput as t_newSlider & t_updateSlider),
    },
    {
      index: 2,
      title: 'Uploading English Image',
      fileName: stepsInfo[1].fileName as string,
      onRetry: () =>
        isNew
          ? onSubmit(lastFetchData.current.formInput as t_newSlider & t_updateSlider)
          : onUpdate(lastFetchData.current.formInput as t_newSlider & t_updateSlider),
      status: stepsInfo[1].error
        ? 'FAILED'
        : stepsInfo[1].pending
        ? 'UPLOADING'
        : stepsInfo[1].completed
        ? 'COMPLETED'
        : 'WAITING',
      progressConfig: {
        percent: stepsInfo[1].percent || 0,
      },
    },
    {
      index: 3,
      title: 'Uploading Turkish Image',
      fileName: stepsInfo[2].fileName as string,
      onRetry: () =>
        isNew
          ? onSubmit(lastFetchData.current.formInput as t_newSlider & t_updateSlider)
          : onUpdate(lastFetchData.current.formInput as t_newSlider & t_updateSlider),
      status: stepsInfo[2].error
        ? 'FAILED'
        : stepsInfo[2].pending
        ? 'UPLOADING'
        : stepsInfo[2].completed
        ? 'COMPLETED'
        : 'WAITING',
      progressConfig: {
        percent: stepsInfo[2].percent || 0,
      },
    },
  ];
  // const ACTION_LIST_OPTION_BASED_USER_TYPE = useMemo(() => {
  //   return ACTION_DETAILS_LIST.filter((opt) => opt.is_internal_link)
  //     .filter((opt) => {
  //       return (
  //         opt.is_common ||
  //         (watch('slot.userType') === sliderUserTypeEnum.EMPLOYER && opt.is_employer) ||
  //         (watch('slot.userType') === sliderUserTypeEnum.EMPLOYEE && opt.is_employee)
  //       );
  //     })
  //     .map((opt) => ({ label: opt.description, value: opt.enum }));
  // }, [watch('slot.userType'), watch('destination.linkType')]);

  const findActionDetail = LOCATION_ACTION_DETAILS_LIST.find((item) => item.location === watch('location.location'));

  const handleToggleSliderActive = (id: number, isActive: boolean) => {
    sweetalert2
      .fire({
        title: `Are You Sure ${isActive ? 'to deActive' : 'to Active'}`,
        icon: 'warning',
        showConfirmButton: true,
        showCancelButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          sweetalert2.fire({
            title: 'Executing ...',
            icon: 'info',
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: false,
          });
          if (isActive) {
            deactiveExecute({
              variables: {
                sliderId: id,
              },
            })
              .then(async () => {
                await refetch();
                toast.success('Successfully Updated');
              })
              .finally(() => {
                sweetalert2.close();
              });
          } else {
            activeExecute({
              variables: {
                sliderId: id,
              },
            })
              .then(async () => {
                await refetch();
                toast.success('Successfully Updated');
              })
              .finally(() => {
                sweetalert2.close();
              });
          }
        }
      });
  };

  const handleDeleteSlide = (id: number) => {
    sweetalert2
      .fire({
        title: 'Are You Sure to delete this slide ?',
        icon: 'warning',
        showCancelButton: true,
        showConfirmButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          sweetalert2.close();
          sweetalert2.fire({
            title: 'Executing ...',
            icon: 'info',
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: false,
          });
          deleteExecute({
            variables: {
              sliderId: id,
            },
          })
            .then((response) => {
              if (response.data) {
                sweetalert2.close();
                toast.success('Slide Successfully Deleted');
                navigate(
                  `/dashboard/contentManagement/slider?userType=${searchParams.get(
                    'userType'
                  )}&location=${searchParams.get('location')}`
                );
              }
            })
            .catch((error) => {
              toast.error(error?.message);
            });
        }
      });
  };

  useEffect(() => {
    if (data) {
      setFiles({
        turkishFile: data?.translations?.[1]?.image || undefined,
        englishFile: data?.translations?.[0]?.image || undefined,
      });
      const dates: { startDate: DayValue | null; endDate: DayValue | null } = {
        startDate: null,
        endDate: null,
      };
      if (data.slot.startTime) {
        const dateObject = new Date(data.slot.startTime);
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth() + 1;
        const day = dateObject.getDate();
        dates.startDate = {
          year,
          month,
          day,
        };
      }
      if (data.slot.endTime) {
        const dateObject = new Date(`${new Date(data.slot.endTime).toISOString().split('T')[0]}T00:00:00`);
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth() + 1;
        const day = dateObject.getDate();
        dates.endDate = {
          year,
          month,
          day,
        };
      }
      setSelectedDate({
        startDate: dates.startDate,
        endDate: dates.endDate,
      });
    }
  }, [data]);
  useEffect(() => {
    navigate(`?userType=${watch('slot.userType')}&location=${watch('location.location')}`);
  }, [watch('slot.userType'), watch('location.location')]);

  const START_TIME = useMemo(() => {
    const getStartTimeDate = data?.slot?.startTime ? new Date(data?.slot.startTime) : undefined;
    if (getStartTimeDate) {
      return `${
        getStartTimeDate.getUTCHours() < 10 ? `0${getStartTimeDate.getUTCHours()}` : getStartTimeDate.getUTCHours()
      }:${
        getStartTimeDate.getUTCMinutes() < 10
          ? `0${getStartTimeDate.getUTCMinutes()}`
          : getStartTimeDate.getUTCMinutes()
      }`;
    }
    return undefined;
  }, [data]);

  const END_TIME = useMemo(() => {
    const getEndTimeDate = data?.slot?.endTime ? new Date(data?.slot?.endTime) : undefined;
    if (getEndTimeDate) {
      return `${
        getEndTimeDate.getUTCHours() < 10 ? `0${getEndTimeDate.getUTCHours()}` : getEndTimeDate.getUTCHours()
      }:${getEndTimeDate.getUTCMinutes() < 10 ? `0${getEndTimeDate.getUTCMinutes()}` : getEndTimeDate.getUTCMinutes()}`;
    }
    return undefined;
  }, [data]);

  // useUpdateEffect(() => {
  //   if (!selectedAction()) {
  //     setValue('destination.stringPayload', undefined);
  //     setValue('destination.numberPayload', undefined);
  //   }
  //   if (selectedAction()?.is_string_payload) {
  //     setValue('destination.numberPayload', undefined);
  //   }
  //   if (selectedAction()?.is_integer_payload) {
  //     setValue('destination.stringPayload', undefined);
  //   }
  // }, [selectedAction()]);

  return (
    <>
      <FormActionLoading onClose={() => toggleUILoading(false)} open={openUILoading} steps={loadingSteps} />
      <PagesLayout
        backLinkTitle="Sliders"
        backLink={undefined}
        onBackBtn={() => {
          navigate(
            `/dashboard/contentManagement/slider?userType=${searchParams.get('userType')}&location=${searchParams.get(
              'location'
            )}`
          );
        }}
      >
        <form onSubmit={handleSubmit(isNew ? onSubmit : onUpdate)} className="d-flex flex-column">
          <Card>
            <CardHeader className="py-3">
              <div className="d-flex align-items-center justify-content-between">
                <h5> {isNew ? 'Create New Slide' : 'Update Slide'} </h5>
                <div className="d-flex align-items-center">
                  {!isNew && (
                    <FormGroup switch className="d-flex align-items-center mr-4">
                      <Label check className="mr-5 mb-0" role="button" htmlFor="activeDeActive">
                        {data?.isActive ? 'Active' : 'De Active'}
                      </Label>
                      <Input
                        role="button"
                        id="activeDeActive"
                        className=" mb-1"
                        style={{
                          padding: '10px',
                          width: '40px',
                        }}
                        type="switch"
                        size={64}
                        checked={data?.isActive}
                        onClick={() => handleToggleSliderActive(data?.id as number, data?.isActive as boolean)}
                      />
                    </FormGroup>
                  )}
                  {!isNew && (
                    <Button
                      onClick={() => handleDeleteSlide(data?.id as number)}
                      disabled={isLoading}
                      color="danger"
                      className="ml-2"
                    >
                      Delete Slide
                    </Button>
                  )}
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <FormGroup className="flex-grow mr-1">
                <Label>Duration</Label>
                <Controller
                  control={control}
                  defaultValue={watch('duration')}
                  name="duration"
                  rules={{
                    max: {
                      value: 100,
                      message: 'Duration Cannot be greater than 100',
                    },
                    min: {
                      value: 0,
                      message: 'Duration Cannot be Less than 0',
                    },
                  }}
                  render={({ field: { onChange } }) => {
                    return (
                      <Input
                        value={watch('duration')}
                        placeholder="Enter Duration"
                        type="number"
                        onChange={({ target: { value } }) => {
                          if (Number(value) <= 100) onChange(value);
                        }}
                        max={100}
                        min={0}
                      />
                    );
                  }}
                />
                <FormFeedback>{errors?.duration?.message}</FormFeedback>
              </FormGroup>

              <FormGroup className="mb-2 px-0">
                <Label className="mb-0" style={{ fontWeight: 'bold', fontSize: '18px' }}>
                  Slide Location
                </Label>
                <div className="d-flex flex-column w-100 mt-3">
                  <div className="d-flex mb-3">
                    <FormGroup className="flex-grow px-0 mr-0">
                      <Label>User Type</Label>
                      <Controller
                        control={control}
                        defaultValue={DEFAULT_VALUES.slot?.userType}
                        name="slot.userType"
                        render={({ field: { onChange } }) => {
                          return (
                            <Select
                              className="z-index-top"
                              options={USER_TYPE_OPTIONS}
                              defaultValue={USER_TYPE_OPTIONS.find((i) => i.value === DEFAULT_VALUES.slot?.userType)}
                              onChange={(event) => {
                                if (event?.value) {
                                  if (event.value === watch('slot.userType')) return;
                                  onChange(event.value);
                                  setChildrenData(
                                    childrenData.map((item) => {
                                      return {
                                        ...item,
                                        action: undefined,
                                        stringPayload: null,
                                        numberPayload: null,
                                      };
                                    }) as any[]
                                  );
                                }
                              }}
                            />
                          );
                        }}
                      />
                    </FormGroup>
                    <FormGroup className="flex-grow ml-3 px-0">
                      <Label>Location</Label>
                      <Controller
                        defaultValue={DEFAULT_VALUES.location?.location}
                        render={({ field: { onChange } }) => {
                          return (
                            <Select
                              className="z-index-top"
                              options={LOCATION_OPTIONS}
                              defaultValue={LOCATION_OPTIONS.find((i) => i.value === DEFAULT_VALUES.location?.location)}
                              onChange={(event) => {
                                if (event?.value) {
                                  if (event.value === sliderLocationEnum.CONTENT_SEARCH_PAGE)
                                    setValue('location.locationCategoryNumberId', null as any);
                                  if (event.value === sliderLocationEnum.EQUIPMENT_SEARCH_PAGE)
                                    setValue('location.locationCategoryStringId', null as any);
                                  if (
                                    event.value !== sliderLocationEnum.EQUIPMENT_SEARCH_PAGE &&
                                    event.value !== sliderLocationEnum.CONTENT_SEARCH_PAGE
                                  ) {
                                    setValue('location.locationCategoryNumberId', null as any);
                                    setValue('location.locationCategoryStringId', null as any);
                                  }
                                  onChange(event.value);
                                }
                              }}
                            />
                          );
                        }}
                        name="location.location"
                        control={control}
                      />
                    </FormGroup>
                  </div>
                  {!!findActionDetail?.query && (
                    <FormGroup className="flex-grow w-100">
                      <Label>Location Category</Label>
                      <PayloadSelector
                        defaultValue={
                          DEFAULT_VALUES.location?.locationCategoryStringId ||
                          DEFAULT_VALUES.location?.locationCategoryNumberId
                        }
                        query={findActionDetail.query}
                        isStringPayload={findActionDetail.is_string_payload}
                        control={control}
                        variables={findActionDetail.vars}
                        onDataLoad={findActionDetail.onDataLoad}
                        // clearFormState={() => {
                        //   setValue('destination.action', undefined);
                        // }}
                        // handleChange={(data) => {
                        //   console.log(data);
                        // }}
                        removeData={() => {
                          if (findActionDetail?.is_integer_payload) {
                            setValue('location.locationCategoryStringId', undefined);
                          }
                          if (findActionDetail?.is_string_payload) {
                            setValue('location.locationCategoryNumberId', undefined);
                          }
                        }}
                        name={
                          findActionDetail.is_string_payload
                            ? 'location.locationCategoryStringId'
                            : 'location.locationCategoryNumberId'
                        }
                      />
                    </FormGroup>
                  )}
                </div>
              </FormGroup>

              <FormGroup>
                <Label className="mb-2" style={{ fontWeight: 'bold', fontSize: '18px' }}>
                  Slide Content
                </Label>
                <FormGroup className="d-flex mt-2">
                  <FormGroup className="flex-grow mr-1">
                    <Label>English Title</Label>
                    <Controller
                      control={control}
                      defaultValue={DEFAULT_VALUES.translations?.[0].title}
                      name="translations.0.title"
                      rules={{
                        required: 'Please Enter Title',
                        maxLength: {
                          value: 150,
                          message: 'Title Cannot be greater than 150 Character',
                        },
                        minLength: {
                          value: 5,
                          message: 'Title Cannot be Less than 5 Character',
                        },
                      }}
                      render={({ field: { onChange } }) => {
                        return (
                          <Input
                            defaultValue={DEFAULT_VALUES.translations?.[0].title}
                            placeholder="Enter English Title"
                            onChange={({ target: { value } }) => onChange(value?.trim())}
                          />
                        );
                      }}
                    />
                    <FormFeedback>{errors?.translations?.[0]?.title?.message}</FormFeedback>
                  </FormGroup>
                  <FormGroup className="flex-grow ml-1">
                    <Label>Turkish Title</Label>
                    <Controller
                      defaultValue={DEFAULT_VALUES.translations?.[1].title}
                      control={control}
                      rules={{
                        required: 'Please Enter Title',
                        maxLength: {
                          value: 150,
                          message: 'Title Cannot be greater than 150 Character',
                        },
                        minLength: {
                          value: 5,
                          message: 'Title Cannot be Less than 5 Character',
                        },
                      }}
                      name="translations.1.title"
                      render={({ field: { onChange } }) => {
                        return (
                          <Input
                            defaultValue={DEFAULT_VALUES.translations?.[1].title}
                            placeholder="Enter Turkish Title"
                            onChange={({ target: { value } }) => onChange(value?.trim())}
                          />
                        );
                      }}
                    />
                    <FormFeedback>{errors?.translations?.[1]?.title?.message}</FormFeedback>
                  </FormGroup>
                </FormGroup>
                <FormGroup className="d-flex">
                  <FormGroup className="flex-grow mr-1">
                    <Label>English Description</Label>
                    <Controller
                      defaultValue={DEFAULT_VALUES.translations?.[0].description}
                      control={control}
                      rules={{
                        required: 'Please Enter Description',
                        maxLength: {
                          value: 500,
                          message: 'Description Cannot be greater than 500 Character',
                        },
                        minLength: {
                          value: 5,
                          message: 'Description Cannot be Less than 5 Character',
                        },
                      }}
                      name="translations.0.description"
                      render={({ field: { onChange } }) => {
                        return (
                          <Input
                            type="textarea"
                            defaultValue={DEFAULT_VALUES.translations?.[0].description}
                            placeholder="Enter English Description"
                            onChange={({ target: { value } }) => onChange(value?.trim())}
                          />
                        );
                      }}
                    />
                    <FormFeedback>{errors?.translations?.[0]?.description?.message}</FormFeedback>
                  </FormGroup>
                  <FormGroup className="flex-grow ml-1">
                    <Label>Turkish Description</Label>
                    <Controller
                      defaultValue={DEFAULT_VALUES.translations?.[1].description}
                      rules={{
                        required: 'Please Enter Description',
                        maxLength: {
                          value: 500,
                          message: 'Description Cannot be greater than 500 Character',
                        },
                        minLength: {
                          value: 5,
                          message: 'Description Cannot be Less than 5 Character',
                        },
                      }}
                      control={control}
                      name="translations.1.description"
                      render={({ field: { onChange } }) => {
                        return (
                          <Input
                            type="textarea"
                            defaultValue={DEFAULT_VALUES.translations?.[1].description}
                            placeholder="Enter Turkish Description"
                            onChange={({ target: { value } }) => onChange(value?.trim())}
                          />
                        );
                      }}
                    />
                    <FormFeedback>{errors?.translations?.[1]?.description?.message}</FormFeedback>
                  </FormGroup>
                </FormGroup>
              </FormGroup>

              <FormGroup>
                <Label className="mb-2" style={{ fontWeight: 'bold', fontSize: '18px' }}>
                  Scheduling
                </Label>
                <FormGroup className="d-flex mb-2 mt-2">
                  <FormGroup className="mr-5">
                    <Label className="mr-2">Start Date:</Label>
                    <Controller
                      control={control}
                      defaultValue={DEFAULT_VALUES.slot?.startTime}
                      name="slot.startTime"
                      render={({ field: { onChange } }) => {
                        return (
                          <div className="d-flex align-items-center border rounded-md px-1">
                            <DatePicker
                              calendarPopperPosition="top"
                              inputPlaceholder="Select Date"
                              value={selectedDate.startDate}
                              inputClassName="rounded-md p-2 mr-2"
                              onChange={(value) => {
                                if (value) {
                                  setError('slot.startTime', { message: undefined });
                                  onChange(`${value.year}-${value.month}-${value.day}`);
                                  setSelectedDate((p) => ({ ...p, startDate: value }));
                                }
                              }}
                            />
                            {/* <TimePicker
                              onChange={(e) => {
                                console.log(e);
                              }}
                            /> */}

                            <Picker
                              isDateChanged={watch('slot.startTime')}
                              isDateSelected={!!getValues('slot.startTime') || !!watch('slot.startTime')}
                              isStart
                              defaultTime={START_TIME || DEFAULT_START_TIME}
                              onChange={(time) => {
                                if (time && watch('slot.startTime')) {
                                  setValue(
                                    'slot.startTime',
                                    addTimeToDate(time, watch('slot.startTime') as string) as string
                                  );
                                }
                              }}
                            />
                            <div
                              className="mt-1"
                              role={'button'}
                              onClick={() => {
                                setValue('slot.startTime', undefined);
                                setSelectedDate((p) => ({ ...p, startDate: undefined }));
                              }}
                            >
                              <Icon Name="FiX" />
                            </div>
                          </div>
                        );
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="mr-2">End Date</Label>
                    <Controller
                      control={control}
                      name="slot.endTime"
                      defaultValue={DEFAULT_VALUES.slot?.endTime}
                      render={({ field: { onChange } }) => {
                        return (
                          <div className="d-flex align-items-center border rounded-md px-1">
                            <DatePicker
                              calendarPopperPosition="top"
                              inputPlaceholder="Select Date"
                              value={selectedDate.endDate}
                              inputClassName="rounded-md p-2 mr-2"
                              onChange={(value) => {
                                if (value) {
                                  setError('slot.endTime', { message: undefined });
                                  onChange(`${value.year}-${value.month}-${value.day}`);
                                  setSelectedDate((p) => ({ ...p, endDate: value }));
                                }
                              }}
                            />
                            <Picker
                              isDateChanged={watch('slot.endTime')}
                              defaultTime={END_TIME || DEFAULT_END_TIME}
                              isStart={false}
                              isDateSelected={!!getValues('slot.endTime') || !!watch('slot.endTime')}
                              onChange={(time) => {
                                if (time && watch('slot.endTime')) {
                                  setValue(
                                    'slot.endTime',
                                    addTimeToDate(time, watch('slot.endTime') as string) as string
                                  );
                                }
                              }}
                            />
                            <div
                              className="mt-1"
                              role={'button'}
                              onClick={() => {
                                setValue('slot.endTime', undefined);
                                setSelectedDate((p) => ({ ...p, startDate: undefined }));
                              }}
                            >
                              <Icon Name="FiX" />
                            </div>
                          </div>
                        );
                      }}
                    />
                  </FormGroup>
                </FormGroup>
                <FormFeedback> {errors?.slot?.startTime?.message} </FormFeedback>
              </FormGroup>

              <SliderChildrenContent
                DEFAULT_VALUES={DEFAULT_VALUES}
                control={control}
                setError={setError}
                clearErrors={clearErrors}
                getValues={getValues}
                setValue={setValue}
                watch={watch}
                setFiles={setFiles}
                files={files}
                errors={errors}
                childrenData={childrenData || []}
                setChildren={(children) => setChildrenData(children)}
                setEnabelSort={(val: boolean) => setEnabelSort(val)}
                enabelSort={enabelSort}
              />
            </CardBody>
          </Card>
          <SubmitButton loading={loading} onClick={() => setEnabelSort(false)}>
            {data ? `Update Slider` : `Create Slider`}
          </SubmitButton>
        </form>
      </PagesLayout>
    </>
  );
}

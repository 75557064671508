import React, { useState } from 'react';
import TimeKeeper from 'react-timekeeper';

export interface TimePickerProps {
  onChange: (time: string) => void;
  defaultTime?: string;
}

export function Timepicker({ onChange, defaultTime = '12:00' }: TimePickerProps) {
  const [time, setTime] = useState(defaultTime);
  const handleChange = (value: string) => {
    setTime(value);
    onChange(value);
  };
  return (
    <TimeKeeper
      time={time}
      hour24Mode
      coarseMinutes={15}
      onChange={(newTime) => handleChange(newTime.formatted24)}
      switchToMinuteOnHourSelect
      closeOnMinuteSelect
    />
  );
}

export default Timepicker;

import { ApolloError } from '@apollo/client';
import { Loading } from 'components';
import Link from 'components/link';
import DataTables, { TableSectionTitle } from 'components/tables/dataTable';
import { useToggle } from 'hooks';
import AdStatus from 'pages/ads/jobAds/components/sections/adStatus';
import AppliesStatus from 'pages/ads/jobAds/components/sections/appliesStatus';
import DateOfPublish from 'pages/ads/jobAds/components/sections/dateOfPublish';
import ExperienceExpectation from 'pages/ads/jobAds/components/sections/experienceExpectation';
import Locations from 'pages/ads/jobAds/components/sections/location';
import OnlyLastDayAds from 'pages/ads/jobAds/components/sections/onlyLastDayAds';
import OnlyVerifiedCompanies from 'pages/ads/jobAds/components/sections/onlyVerifiedCompanies';
import Speciality from 'pages/ads/jobAds/components/sections/speciality';
import WorkingDays from 'pages/ads/jobAds/components/sections/workingDays';
import WorkingHours from 'pages/ads/jobAds/components/sections/workingHours';
import { DEFAULT_VALUE } from 'pages/ads/jobAds/index';
import useApplyList from 'pages/ads/jobAds/useApplyList';
import React, { useCallback, useMemo, useState } from 'react';
import { Control, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, Form } from 'reactstrap';
import { ADS_STATUS, ADS_STATUS_TEXT, APP_HELPER } from 'tools/enums';
import { Location } from 'tools/i18n/constants';
import { apply, detail, list, results, show, status, time } from 'tools/i18n/constants/i18n';
import defaultUserIcon from 'assets/images/buluro/dashboard/user-default.svg';
import { t_defaultValues } from './types';

export default function AdsApplyList() {
  const { data, loading, error, fetchMore } = useApplyList();
  return (
    <Loading loading={loading}>
      <Card>
        <CardBody className="p-0">
          <ApplyListTable data={data} loading={loading} error={error} refetch={fetchMore} />
        </CardBody>
      </Card>
    </Loading>
  );
}

interface ApplyListTableProps {
  refetch: any;
  loading: boolean;
  data: unknown[];
  error?: ApolloError;
}

function ApplyListTable(props: ApplyListTableProps) {
  const { data, refetch, error } = props;
  const { t } = useTranslation();
  const [realLoading, setRealLoading] = useToggle(false);
  const [realData, setRealData] = useState(data);
  const [filters, setFilters] = useState(DEFAULT_VALUE);

  const onPageChange = useCallback((page: number) => {
    setRealLoading(true);
    refetch({
      variables: {
        page: page,
        ...filters,
      },
    })
      .then((res: { data: { EmploymentSchoolsList: unknown[] } }) => {
        setRealData(res.data?.EmploymentSchoolsList);
        setRealLoading(false);
      })
      .catch(console.error);
  }, []);
  const onSearch = useCallback((key: string) => {
    setRealLoading(true);
    refetch({
      variables: {
        keyword: key,
        ...filters,
      },
    })
      .then((res: { data: { EmploymentSchoolsList: unknown[] } }) => {
        setRealData(res.data?.EmploymentSchoolsList);
      })
      .catch(console.error);
    setRealLoading(false);
  }, []);
  const onFilter = (data: t_defaultValues) => {
    setFilters(data);
    setRealLoading(true);
    const vars = { ...data, page: APP_HELPER.tableDefaultPage, size: APP_HELPER.tableDefaultSize };
    console.log(vars);
    refetch(vars).then((res: any) => {
      console.log(res);
      setRealData(res.data.EmploymentAdsList);
      setRealLoading(false);
    });
  };
  const columnData = useMemo(() => {
    return [
      {
        name: <TableSectionTitle name={'FiFileText'} title={`${t(apply)} ${t(list)}`} />,
        center: false,
        cell: (r: any) => {
          const branches = r?.branches || [];
          const sections = r?.sections || [];
          const branchAndSection = branches.map((branch: any) => {
            const findRelevantSection = sections.find((section: any) => section.branchId === branch._id);
            return [branch?.originalName, findRelevantSection?.originalName];
          });

          return (
            <div className="d-flex align-items-center p-2">
              <img
                className="rounded-circle mr-2"
                style={{
                  minWidth: '35px',
                  minHeight: '35px',
                  maxWidth: '35px',
                  maxHeight: '35px',
                }}
                alt={r.sender.name}
                src={r?.sender?.profilePhoto || defaultUserIcon}
                onError={(e) => (e.currentTarget.src = '/default.svg')}
              />
              <div className="d-flex flex-column justify-content-between">
                <div>
                  <span>{r.sender.name}</span>
                </div>
                <div className="d-flex align-items-center">
                  {r?.branches?.length && (
                    <div className="mt-2">
                      <span>{branchAndSection?.join(' / ')}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        name: <TableSectionTitle name="FiMapPin" title={t(Location)} />,
        center: false,
        cell: (r: any) => (
          <div className="d-flex flex-column">
            <span className="mb-1">{r.sender.city}</span>
            <span>{r.sender.county}</span>
          </div>
        ),
      },
      {
        name: <TableSectionTitle name="FiGrid" title={`${t(apply)} ${t(time)}`} />,
        center: false,
        cell: (r: any) => {
          const d = new Date(r.createdAt);
          return `${d.getFullYear()}.${d
            .toISOString()
            ?.substring(5, 7)}.${d.getDate()} ${d.getHours()}:${d.getMinutes()}`;
        },
      },
      {
        name: <TableSectionTitle name="FiType" title={t(status)} />,
        center: false,
        cell: (r: { status: keyof typeof ADS_STATUS_TEXT }) => {
          const selectClassName = () => {
            switch (r.status) {
              case ADS_STATUS.ACTIVE:
                return 'shadow-bg-success';
              case ADS_STATUS.DRAFT:
                return 'shadow-bg-notActive';
              case ADS_STATUS.ADMIN_BAN:
                return 'shadow-bg-danger';
              case ADS_STATUS.EXPIRED:
                return 'shadow-bg-danger';
              default:
                return '';
            }
          };
          return <div className={`p-2 rounded ${selectClassName()}`}>{ADS_STATUS_TEXT[r.status] || r.status}</div>;
        },
      },
      {
        name: '',
        center: false,
        cell: (r: any) => {
          return (
            <Link className="text-white ml-auto" to={'schoolDetail'} param={r._id.toString()}>
              <Button color="primary">{t(detail)}</Button>
            </Link>
          );
        },
      },
    ];
  }, []);
  return (
    <DataTables
      className="h-100"
      searchable
      headerTitle={`${t(apply)} ${t(list)}`}
      data={realData}
      columns={columnData}
      onSearch={onSearch}
      disabled={realLoading || !!error}
      onChangePage={onPageChange}
      filterable
      customFilter={<FilterOptions loading={realLoading} onFilter={onFilter} />}
    />
  );
}

export interface FormProps {
  control: Control<t_defaultValues, any>;
  onChange?: (name: keyof t_defaultValues, value: any) => void;
}

interface FilterOptionsProps {
  onFilter: (data: t_defaultValues) => any;
  loading: boolean;
}

function FilterOptions(props: FilterOptionsProps) {
  const { t } = useTranslation();
  const { onFilter, loading } = props;
  const { control, handleSubmit, setValue, getValues } = useForm<t_defaultValues>({
    defaultValues: DEFAULT_VALUE,
  });
  const onSubmit = (data = DEFAULT_VALUE) => {
    onFilter(data);
  };
  const handleChange = (name: keyof t_defaultValues, val: any) => {
    setValue(name, val);
  };
  return (
    <div style={{ width: '500px' }} className="bg-white p-3 rounded">
      <Form onSubmit={handleSubmit(onSubmit, console.error)} className="d-flex flex-column">
        <Locations control={control} currentCities={getValues('cities')} currentCounties={getValues('counties')} />
        <Speciality control={control} />
        <AppliesStatus control={control} onChange={handleChange} />
        <AdStatus control={control} onChange={handleChange} />
        <ExperienceExpectation control={control} onChange={handleChange} />
        <WorkingDays control={control} onChange={handleChange} />
        <WorkingHours control={control} />
        <DateOfPublish control={control} />
        <OnlyVerifiedCompanies control={control} />
        <OnlyLastDayAds control={control} />
        <Button disabled={loading} aria-disabled={loading} type="submit" color="primary">{`${t(show)} ${t(
          results
        )}`}</Button>
      </Form>
    </div>
  );
}

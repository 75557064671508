import { gql } from '@apollo/client';

export const GET_LANGUAGES = gql`
  query LanguageList($page: Int, $size: Int) {
    employmentLanguagesList(paginationDto: { page: $page, size: $size }) {
      _id
      name
    }
  }
`;

export const CREATE_LANGUAGE = gql`
  mutation CreateLanguage($name: String!) {
    employmentCreateLanguage(employmentCreateLanguageDto: { languageName: $name }) {
      _id
      name
    }
  }
`;

export const UPDATE_LANGUAGE = gql`
  mutation UpdateLanguage($name: String!, $id: String!) {
    employmentUpdateLanguage(employmentUpdateLanguageDto: { languageName: $name, id: $id }) {
      _id
      name
    }
  }
`;

import SubmitButton from 'components/buttons/submitButton';
import { PasswordInput } from 'components/inputs';
import OnlyCharInput from 'components/inputs/onlyCharacter';
import ShowError from 'components/showError';
import useMutation from 'hooks/useMutation';
import { newComponentsProps } from 'pages/user-management/employer/index';
import React, { FC, memo, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, FormFeedback, FormGroup, Label } from 'reactstrap';
import { CREATE_ACCOUNT } from 'services/graphql/queries/companies/createCompany.graphql';
import {
  company,
  enter,
  first,
  input,
  last,
  name,
  password,
  please,
  save,
  setTemporaryPassword,
} from 'tools/i18n/constants/i18n';
import { toNull } from 'tools/methods';
import { stringish } from 'tools/types';
import { formsNameEnum, steps } from './types';

type CreateCompanyProps = newComponentsProps;

type initialValues = {
  customError?: stringish;
  companyName: stringish;
  firstName: stringish;
  lastName: stringish;
  password: stringish;
};
const CreateCompany: FC<CreateCompanyProps> = (props) => {
  const {
    isActive,
    goNext,
    componentsValues,
    handleChangeComponentState,
    handleChangeComponentValues,
    handleChangeComponentResponse,
  } = props;
  const { t } = useTranslation();
  const DEFAULT_VALUES = {
    companyName: toNull(componentsValues?.companyName),
    firstName: null,
    lastName: null,
    password: null,
  };
  const [execute, { loading }] = useMutation(CREATE_ACCOUNT);
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<initialValues>({
    defaultValues: DEFAULT_VALUES,
  });
  const onSubmit = (data: initialValues) => {
    console.log(data);
    setValue('customError', '');
    if (isActive) {
      execute({
        variables: { ...componentsValues, ...data },
      })
        .then((res) => {
          if (res.data?.createLegalAccount) {
            handleChangeComponentState(formsNameEnum.CreateCompany, true);
            handleChangeComponentValues?.(data);
            handleChangeComponentResponse?.(res.data?.createLegalAccount);
            goNext(steps.three);
          } else {
            //@ts-ignore
            setValue('customError', res.errors?.message);
          }
        })
        .catch((err) => {
          console.error(err);
          setValue('customError', err?.message);
        });
    }
  };
  const onError = (e: any) => {
    console.log(e);
  };
  useEffect(() => {
    if (componentsValues?.companyName) {
      handleChangeComponentState(formsNameEnum.CreateCompany, true);
      goNext(steps.three);
    }
  }, [componentsValues?.companyName]);
  console.log(isActive);

  return (
    <Col xxl={7} className="p-2 pb-4 mx-auto">
      {!!getValues('customError') && (
        <FormGroup>
          <ShowError>{getValues('customError')}</ShowError>
        </FormGroup>
      )}

      <Form onSubmit={handleSubmit(onSubmit, onError)} aria-disabled={!isActive}>
        <FormGroup>
          <Label>{`${t(company)} ${t(name)}`}</Label>

          <OnlyCharInput
            control={control}
            isEveryCharacterAllowed
            name="companyName"
            placeholder={`${t(company)} ${t(name)}`}
            disabled={!isActive || !!DEFAULT_VALUES.companyName}
            aria-disabled={!isActive}
            required
            rules={{
              minLength: {
                value: 3,
                message: `${t(input)} should be longer`,
              },
            }}
          />
          <FormFeedback>{errors?.companyName?.message}</FormFeedback>
        </FormGroup>
        {!DEFAULT_VALUES.companyName && (
          <>
            <FormGroup>
              <Label>{`${t(first)} ${t(name)}`}</Label>

              <OnlyCharInput
                control={control}
                name="firstName"
                isEveryCharacterAllowed
                placeholder={`${t(first)} ${t(name)}`}
                disabled={!isActive}
                aria-disabled={!isActive}
                required
                rules={{
                  minLength: {
                    value: 3,
                    message: `${t(input)} should be longer`,
                  },
                }}
              />
              <FormFeedback>{errors?.firstName?.message}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label>{`${t(last)} ${t(name)}`}</Label>

              <OnlyCharInput
                control={control}
                name="lastName"
                isEveryCharacterAllowed
                placeholder={`${t(last)} ${t(name)}`}
                disabled={!isActive}
                aria-disabled={!isActive}
                required
                rules={{
                  minLength: {
                    value: 3,
                    message: `${t(input)} should be longer`,
                  },
                }}
              />
              <FormFeedback>{errors?.lastName?.message}</FormFeedback>
            </FormGroup>

            <FormGroup>
              <Label>{t(setTemporaryPassword)}</Label>
              <Controller
                control={control}
                name="password"
                rules={{ required: `${t(please)} ${t(enter)} ${t(password)}` }}
                render={({ field: { onChange } }) => {
                  return (
                    <PasswordInput
                      copyable
                      needCheckBox
                      name="password"
                      onChange={(val) => {
                        onChange(val);
                      }}
                      disabled={!isActive}
                      aria-disabled={!isActive}
                      placeholder={`${t(password)}`}
                    />
                  );
                }}
              />
              <FormFeedback>{errors?.password?.message}</FormFeedback>
            </FormGroup>
          </>
        )}

        {isActive && (
          <SubmitButton className="w-100 mt-4" loading={loading}>
            {t(save)}
          </SubmitButton>
        )}
      </Form>
    </Col>
  );
};
export default memo(CreateCompany);

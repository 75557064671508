import useQuery from 'hooks/useQuery';
import { GET_COMPANY, GET_COMPANY_DETAIL_BY_USER_ID } from 'services/graphql/queries/companies';
import { useParams } from 'react-router-dom';
import { t_company } from 'tools/types/sectionTypes/company';
import { useMemo } from 'react';

export function realId(type: string | undefined) {
  if (type?.includes('EMPLOYER-')) {
    const extractId = type?.replaceAll('EMPLOYER-', '');
    return { userId: parseInt(extractId) };
  }
  return { id: parseInt(type as string) };
}

export default function useDetails() {
  const { type } = useParams();

  const { error, loading, data, refetch } = useQuery(realId(type)?.id ? GET_COMPANY : GET_COMPANY_DETAIL_BY_USER_ID, {
    variables: {
      ...realId(type),
    },
  });
  const realData: t_company | null = useMemo(() => {
    if (data?.getCompanyListAndDetails?.singleResponse) {
      data.getCompanyListAndDetails.singleResponse['userId'] =
        data?.getCompanyListAndDetails?.singleResponse['userId']?.[0];
      data.getCompanyListAndDetails.singleResponse['companyId'] = data.getCompanyListAndDetails.singleResponse?.id;
      return data?.getCompanyListAndDetails?.singleResponse;
    } else if (data?.getCompanyDetailsByUserId) {
      data.getCompanyDetailsByUserId['userId'] = realId(type).userId;
      data.getCompanyDetailsByUserId['companyId'] = data.getCompanyDetailsByUserId?.id;
      return data?.getCompanyDetailsByUserId;
    } else return null;
  }, [data]);
  return {
    data: realData as t_company,
    error,
    loading,
    refetch,
    id: realId(type)?.id || realId(type)?.userId,
  };
}

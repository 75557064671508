import { gql } from '@apollo/client';

export const GET_COMPANY_CATEGORIES_TREE = gql`
  query getCompanyCategories($page: Int, $size: Int) {
    employmentEmployerCategoryList(
      employmentEmployerCategoryListDto: { bySubCategories: true, pagination: { page: $page, size: $size } }
    ) {
      _id
      ancestors {
        createdAt
        updatedAt
        parentId
        _id
        originalName
        imageUrl
        translations {
          language
          translate
        }
      }
      originalName
      imageUrl
      translations {
        language
        translate
      }
      subCategories {
        _id
        parentId
        originalName
        imageUrl
        translations {
          language
          translate
        }
        subCategories {
          _id
          parentId
          originalName
          imageUrl
          translations {
            language
            translate
          }
          subCategories {
            _id
            parentId
            originalName
            imageUrl
            translations {
              language
              translate
            }
            subCategories {
              _id
              parentId
              originalName
              imageUrl
              translations {
                language
                translate
              }
              subCategories {
                _id
                parentId
                originalName
                imageUrl
                translations {
                  language
                  translate
                }
              }
            }
          }
        }
      }
      parentId
      createdAt
      updatedAt
    }
  }
`;

export const GET_COMPANY_CATEGORIES_TREE_SLIDER = gql`
  query getCompanyCategories($page: Int, $size: Int) {
    employmentEmployerCategoryList(
      employmentEmployerCategoryListDto: { bySubCategories: true, pagination: { page: $page, size: $size } }
    ) {
      _id
      originalName
      imageUrl
      parentId
      createdAt
      updatedAt
      translations {
        language
        translate
      }
      ancestors {
        createdAt
        updatedAt
        parentId
        _id
        originalName
        imageUrl
        translations {
          language
          translate
        }
      }
    }
  }
`;

export const UPDATE_COMPANY_CATEGORIES = gql`
  mutation updateCompanyCategories($translations: [TranslationsDto!], $categoryId: String!, $parentId: String) {
    employmentUpdateEmployerCategory(
      employmentCreateEmployerCategoryDto: { translations: $translations, categoryId: $categoryId, parentId: $parentId }
    ) {
      _id
    }
  }
`;

export const CREATE_COMPANY_CATEGORIES = gql`
  mutation createCompanyCategories($translations: [TranslationsDto!], $parentId: String) {
    employmentCreateEmployerCategory(
      employmentCreateEmployerCategoryDto: { translations: $translations, parentId: $parentId }
    ) {
      _id
    }
  }
`;

export const DELETE_COMPANY_CATEGORIES = gql`
  mutation deleteCompanyCategories($id: String!) {
    employmentDeleteEmployerCategory(employmentCreateEmployerCategoryDto: { id: $id })
  }
`;

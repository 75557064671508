import { Loading } from 'components';
import SubmitButton from 'components/buttons/submitButton';
import OnlyCharInput from 'components/inputs/onlyCharacter';
import Link from 'components/link';
import DataTable, { TableSectionTitle } from 'components/tables/dataTable';
import useMutation from 'hooks/useMutation';
import PagesLayout from 'pages/components/layout';
import UpdateAddHeader from 'pages/components/updateAddHeader';
import useDetail from 'pages/fuar/location/useDetail';
import React, { FC, ReactNode, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Form, FormFeedback, FormGroup, Label } from 'reactstrap';
import { CREATE_COUNTRY, UPDATE_COUNTRY } from 'services/graphql/queries/location';
import { APP_ROUTE_NAME } from 'tools/enums';
import {
  Location,
  New,
  add,
  assigned,
  city,
  country,
  created,
  detail,
  enter,
  information,
  name,
  please,
  save,
  successfully,
  update,
  updated,
} from 'tools/i18n/constants/i18n';
import { isNew } from 'tools/methods';

export default function LocationDetail() {
  const { t } = useTranslation();
  const { type } = useParams();
  const backLinkTitle = `${t(Location)}`;

  return (
    <PagesLayout
      {...(!isNew(type) ? { newSectionButtonLabel: `${t(add)} ${t(New)} ${t(city)}` } : undefined)}
      backLinkTitle={backLinkTitle}
      backLink="location"
      backLinkParam="/"
    >
      <Card>
        <Header isNew={isNew(type)} />
        <CardBody>
          <div className="px-5">{isNew(type) ? <NewLocation /> : <UpdateLocation />}</div>
        </CardBody>
      </Card>
    </PagesLayout>
  );
}

function NewLocation() {
  return <LocationForm isNew={true} />;
}

function UpdateLocation() {
  const { data, loading } = useDetail();
  return (
    <Loading loading={loading}>
      <LocationForm isNew={false} data={data}>
        <AssignedCityTable cities={data?.city} />
      </LocationForm>
    </Loading>
  );
}

interface LocationFormProps {
  children?: ReactNode;
  isNew: boolean;
  data?: any;
}

function LocationForm(props: LocationFormProps) {
  const { children, isNew, data } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const INITIAL_VALUES = {
    name: data?.name,
    id: data?.id,
    city: data?.city,
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: INITIAL_VALUES,
  });
  const [execute, { loading: createLoading }] = useMutation(CREATE_COUNTRY);
  const [updateExecute, { loading: updateLoading }] = useMutation(UPDATE_COUNTRY);
  const onSubmit = (data = INITIAL_VALUES) => {
    execute({
      variables: data,
    })
      .then((res) => {
        if (res.data.createCountry) {
          toast.success(`${t(country)} ${t(successfully)} ${t(created)}`);
          navigate(`/${APP_ROUTE_NAME.dashboard}/${APP_ROUTE_NAME.location}`);
        }
      })
      .catch(console.error);
  };

  const onUpdate = (data = INITIAL_VALUES) => {
    updateExecute({
      variables: data,
    })
      .then((res) => {
        if (res.data?.updateCountry) {
          toast.success(`${t(country)} ${t(successfully)} ${t(updated)}`);
          navigate(`/${APP_ROUTE_NAME.dashboard}/${APP_ROUTE_NAME.location}`);
        }
      })
      .catch(console.error);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(isNew ? onSubmit : onUpdate, console.error)}>
        <FormGroup className=" bg-gray rounded p-3">
          <Label>{`${t(country)} ${t(name)}`}</Label>
          <OnlyCharInput
            isRequired
            isEveryCharacterAllowed
            requiredErrorText={`${t(please)} ${t(enter)} ${t(country)} ${t(name)}`}
            name="name"
            placeholder="Turkey"
            defaultValue={data?.name}
            control={control}
          />
          <FormFeedback>
            <>{errors?.name?.message}</>
          </FormFeedback>
        </FormGroup>
        <SubmitButton loading={createLoading || updateLoading}>{t(isNew ? save : update)}</SubmitButton>
      </Form>
      {children}
    </>
  );
}

interface HeaderProps {
  isNew: boolean;
}

const Header: FC<HeaderProps> = ({ isNew }) => {
  const { t } = useTranslation();
  const title = isNew ? `${t(add)} ${t(New)} ${t(country)}` : `${t(country)} ${information}`;
  return <UpdateAddHeader isNew={isNew} title={title} />;
};

const AssignedCityTable: FC<{ cities: { name: string; id: number }[] }> = ({ cities }) => {
  const { t } = useTranslation();
  const columnData = useMemo(() => {
    return [
      {
        name: <TableSectionTitle name="FiAperture" title={`${t(assigned)} ${t(city)}`} />,
        center: false,
        selector: (r: { name: string }) => r.name,
      },
      {
        name: '',
        cell: (r: { id: any }) => {
          return (
            <Link className="text-white ml-auto" to={'locationCity'} param={r.id.toString()}>
              <Button color="primary">{t(detail)}</Button>
            </Link>
          );
        },
      },
    ];
  }, []);
  return (
    <div className="p-2 mt-3 rounded bg-gray">
      <DataTable disabled={false} columns={columnData} data={cities} />
    </div>
  );
};

import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState, forwardRef, useImperativeHandle, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormFeedback, FormGroup, Label, Spinner } from 'reactstrap';
import NoteItem from './noteItem';
import Icon from 'components/icons';
import useCompanyNotes from '../useCompanyNotes';
import { useMutation } from 'hooks';
import { CREATE_COMPANY_NOTE, UPDATE_COMPANY_NOTE } from 'services/graphql/queries/companies';
import { ErrorMessage } from '@hookform/error-message';
import { Controller, useForm } from 'react-hook-form';
import SubmitButton from 'components/buttons/submitButton';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import { size } from 'lodash';
import { Reference } from '@apollo/client';

interface ChildProps {
  companyId?: number;
}

interface ChildRef {
  childFunction: () => void;
}

const CompanyNotes = forwardRef(({ companyId }: ChildProps, ref) => {
  const { t } = useTranslation();

  const [size, setSize] = useState(15);
  const [refetchLoading, setRefetchLoading] = useState(false);

  const { data, count, loading, refetch } = useCompanyNotes({ companyId });
  const [execute, { loading: createLoading }] = useMutation(CREATE_COMPANY_NOTE);

  // const [finalData, setfinalData] = useState(data);
  const DEFAULT_VALUES = {
    text: '',
  };
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError,
  } = useForm({
    defaultValues: DEFAULT_VALUES,
  });

  // useEffect(() => {
  //   setfinalData(data);
  // }, [data?.length]);

  useImperativeHandle(ref, () => ({
    refetch() {
      handleRefetchData();
    },
  }));

  const onSubmit = (data: { text: string }) => {
    if (!data.text) {
      setError('text', { message: 'Please Enter Text' });
      return;
    }

    execute({
      variables: { ...data, companyId },
    })
      .then((res) => {
        setValue('text', '');
        toast.success('Note Added Successfully');
        refetch && refetch();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const loadMoreData = () => {
    refetch && refetch({ page: 1, size: size + 15, companyId });
    setSize(size + 15);
  };

  const handleRefetchData = () => {
    setRefetchLoading(true);
    refetch &&
      refetch()
        .then(() => {
          setRefetchLoading(false);
        })
        .catch(() => {
          setRefetchLoading(false);
        });
  };

  console.log(companyId);

  return (
    <div className="h-100 d-flex flex-column w-100 ">
      <div
        className="max-h-100 flex-grow overflow-y-auto mb-2"
        id="scrollableDiv"
        // style={{ height: window.innerHeight + 100 }}
      >
        {/* <Button style={{ width: 30, height: 30, padding: 5 }} onClick={() => handleRefetchData()}>
          <Icon Name="FiRefreshCw" color="white" />
        </Button> */}
        {!loading && !refetchLoading ? (
          <InfiniteScroll
            style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
            next={() => {
              loadMoreData();
            }}
            hasMore={size >= (count as number) ? false : true}
            dataLength={data?.length || 0}
            scrollableTarget="scrollableDiv"
            loader={
              <div className="flex-grow d-flex justify-content-center align-items-center py-2">
                <Spinner />
              </div>
            }
          >
            {data?.map((item: any) => (
              <NoteItem key={item.id} data={item} companyId={companyId} id={item.id} refetch={() => refetch()} />
            ))}
            <div style={{ height: 90 }}></div>
          </InfiniteScroll>
        ) : (
          <div className="max-h-100 flex-grow d-flex justify-content-center align-items-center">
            <Spinner />
          </div>
        )}
      </div>
      <div className="d-flex align-items-start gap-2 w-100 mt-2">
        <form onSubmit={handleSubmit(onSubmit)} className="my-1 d-flex gap-2 w-100">
          <FormGroup className="flex-grow mb-0" style={{ marginBottom: '0px' }}>
            <Controller
              control={control}
              defaultValue={DEFAULT_VALUES?.text}
              name="text"
              rules={{
                required: 'Please Enter Note',
                maxLength: {
                  value: 5000,
                  message: 'Note Cannot be greater than 5000',
                },
                minLength: {
                  value: 3,
                  message: 'Note Cannot be less than 3',
                },
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <TextArea
                    role="button"
                    className=" mb-1"
                    placeholder="Note"
                    name="text"
                    style={{
                      padding: '10px',
                      minHeight: '100px',
                    }}
                    onChange={({ target: { value } }) => onChange(value)}
                    value={value}
                  />
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name={`text`}
              render={({ message }) => <FormFeedback>{message}</FormFeedback>}
            />
          </FormGroup>

          <SubmitButton
            loading={createLoading}
            className="d-flex align-items-center justify-content-center"
            style={{ width: 30, height: 30, maxWidth: 30, padding: 0 }}
          >
            <Icon Name="FiSend" />
          </SubmitButton>
        </form>
      </div>
    </div>
  );
});

export default CompanyNotes;

import React, { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import { FormFeedback, Input, InputProps, Label } from 'reactstrap';
import { enterPhoneNumber } from 'tools/i18n/constants/i18n';
import { NUMBER2_REGEXP } from 'tools/constants';
import { useTranslation } from 'react-i18next';
import { t_country, t_countryCodes } from 'tools/types';
import Select from 'react-select';
import { useQuery, useUpdateEffect } from 'hooks';
import { GET_COUNTRY_PHONE_CODE } from 'services/graphql/queries/App';
import { Emojione } from 'react-emoji-render';

export interface PhoneNumberInputProps {
  errorMessage?: string;
  onCountryChange?: (value: countryCodeOption) => void;
  onPhoneNumberChange?: (value: string | undefined) => void;
  label?: string;
  defaultValue?: {
    country: t_countryCodes;
    phoneNumber: string;
  };
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  inputProps?: InputProps;
}

type countryCode = {
  code: string;
  dial_code: string;
  flag: string;
  name: string;
};

type countryCodeOption = {
  label: string;
  value: string;
} & countryCode;

export const PhoneNumberInput: FC<PhoneNumberInputProps> = (props) => {
  const {
    onPhoneNumberChange,
    onCountryChange,
    errorMessage,
    label,
    defaultValue,
    name,
    placeholder,
    disabled,
    inputProps,
  } = props;
  const { t } = useTranslation();
  const { data, loading } = useQuery<{ getPhoneCodeList: countryCode[] }>(GET_COUNTRY_PHONE_CODE);
  const [selectedCountry, setSelectedCountry] = useState<countryCodeOption | undefined>(undefined);
  const COUNTRIES: countryCodeOption[] = useMemo(() => {
    if (data) {
      return data?.getPhoneCodeList.map((c) => {
        return {
          code: c.code,
          label: c.name,
          value: c.dial_code,
          flag: c.flag,
          dial_code: c.dial_code,
          name: c.name,
        };
      });
    }
    return [];
  }, [data]);
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      if (value) {
        const parseValue = value.replace(NUMBER2_REGEXP, '');
        e.target.value = parseValue;
        onPhoneNumberChange?.(parseValue);
      } else {
        onPhoneNumberChange?.(undefined);
      }
    },
    [onPhoneNumberChange]
  );
  const handleCountryCodeChange = useCallback(
    (val: countryCodeOption) => {
      setSelectedCountry(val);
      onCountryChange?.(val);
    },
    [onCountryChange]
  );
  const selectedCuntryCode = useMemo(() => {
    if (defaultValue?.country) {
      const findDefault = COUNTRIES.find((c) => c.value === defaultValue.country);
      return findDefault;
    }
    return COUNTRIES.find((c) => c.value === '+90');
  }, [defaultValue, COUNTRIES]);
  useUpdateEffect(() => {
    if (selectedCuntryCode && selectedCuntryCode.value !== selectedCountry?.dial_code) {
      setSelectedCountry(selectedCuntryCode);
      onCountryChange?.(selectedCuntryCode);
    }
  }, [selectedCuntryCode]);
  return (
    <div className="d-flex flex-column">
      {label && <Label className="col-form-label">{t(label)}</Label>}
      <div className="d-flex country-number-container border rounded input-container">
        <div className=" border-right country-selection my-1">
          <Select
            className="dropdown border-0"
            isLoading={loading}
            value={selectedCountry}
            options={COUNTRIES}
            onChange={(val: any) => handleCountryCodeChange(val as countryCodeOption)}
            formatOptionLabel={(data) => {
              return (
                <div className="d-flex align-items-center">
                  {data.flag && (
                    <span className="mr-3">
                      <Emojione className="text-lg" text={data.flag} />
                    </span>
                  )}
                  <span>{`${data.label} ${data.dial_code}`}</span>
                </div>
              );
            }}
          />
        </div>
        <Input
          {...inputProps}
          onChange={handleChange}
          className="border-0 transparent"
          type="tel"
          disabled={disabled}
          aria-disabled={disabled}
          autoComplete="off"
          invalid={!!errorMessage}
          placeholder={t(placeholder || enterPhoneNumber)}
          defaultValue={defaultValue?.phoneNumber}
          name={name}
          maxLength={11}
          minLength={10}
        />
      </div>
      <FormFeedback>{errorMessage}</FormFeedback>
    </div>
  );
};

export default PhoneNumberInput;

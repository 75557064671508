import { useMemo } from 'react';
import { add, documentType, New } from 'tools/i18n/constants/i18n';
import { useTranslation } from 'react-i18next';
import useQuery from 'hooks/useQuery';
import { useParams } from 'react-router-dom';
import { t_deleteReason } from 'pages/user-management/deleteReasons/useDeleteReasons';
import { CREATE_COMPANY_REASON, GET_COMPANY_REASON_BY_ID } from 'services/graphql/queries/companyReasons';

export default function useReasonDetail() {
  const { t } = useTranslation();
  const { type } = useParams();
  const { data, error, loading } = useQuery(GET_COMPANY_REASON_BY_ID, {
    variables: {
      id: parseInt(type as string),
    },
  });
  const isListEmpty: boolean = useMemo(() => !!data?.getGuideRequestRejectReasonById, [data]);
  const updatedData: t_deleteReason | undefined = isListEmpty ? data.getGuideRequestRejectReasonById : [];

  const addNewTitle = `${t(add)} ${t(New)} ${t(documentType)}`;

  return { addNewTitle, isListEmpty, data: updatedData, loading, error };
}

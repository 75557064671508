import { gql } from '@apollo/client';

export const GET_ALL_SPECIAL_REGIONS = gql`
  query getAllSpecialRegions($query: String) {
    realEstateFindAllSpecialRegions(realEstateSpecialRegionListDto: { query: $query }) {
      id
      picture
      title
      town {
        id
        name
      }
      city {
        id
        name
      }
      description {
        language
        translation
      }
      region {
        type
        coordinates
      }
      specialRegionLanguage {
        language
        translation
      }
    }
  }
`;

export const GET_ALL_SPECIAL_REGIONS_DETAIL = gql`
  query getAllSpecialRegions($id: Int!) {
    realEstateFindSpecialRegionDetails(onlyIdDto: { id: $id }) {
      id
      picture
      title
      town {
        id
        name
      }
      city {
        id
        name
      }
      description {
        language
        translation
      }
      region {
        type
        coordinates
      }
      specialRegionLanguage {
        language
        translation
      }
    }
  }
`;

export const CREATE_SPECIAL_REGIONS = gql`
  mutation createSpecialRegions(
    $language: [TranslationsDto!]!
    $descriptionTranslations: [TranslationsDto!]!
    $region: [[Float!]!]!
    $townId: Float!
    $cityId: Float!
  ) {
    realEstateCreateSpecialRegion(
      realEstateCreateSpecialRegionPayload: {
        translations: $language
        descriptionTranslations: $descriptionTranslations
        region: $region
        cityId: $cityId
        townId: $townId
      }
    ) {
      id
    }
  }
`;

export const UPDATE_SPECIAL_REGIONS = gql`
  mutation updateSpecialRegion(
    $specialRegionId: Float!
    $cityId: Float!
    $townId: Float!
    $region: [[Float!]!]!
    $descriptionTranslations: [TranslationsDto!]!
    $language: [TranslationsDto!]!
  ) {
    realEstateUpdateSpecialRegion(
      realEstateUpdateSpecialRegionPayload: {
        specialRegionId: $specialRegionId
        cityId: $cityId
        townId: $townId
        region: $region
        descriptionTranslations: $descriptionTranslations
        translations: $language
      }
    ) {
      id
    }
  }
`;

export const FIND_ALL_EMLAK_CITIES = gql`
  query FindAllEmlakCities($id: Int = 2) {
    realEstateFindAllCitiesOfCountry(onlyIdDto: { id: $id }) {
      id
      name
    }
  }
`;

export const FIND_ALL_EMLAK_TOWN_CITIES = gql`
  query FindAllEmalkTownsOfCities($id: Int!) {
    realEstateFindAllTownsOfCity(onlyIdDto: { id: $id }) {
      id
      name
    }
  }
`;

import React, { InputHTMLAttributes } from 'react';
import { Input } from 'reactstrap';
import { Control, Controller, FieldValues, Path, PathValue } from 'react-hook-form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { EMAIL_REGEXP } from 'tools/constants';
import { useTranslation } from 'react-i18next';
import { email, enter, please, valid } from 'tools/i18n/constants/i18n';
import { trimString } from 'tools/methods';

export interface EmailInputProps<T extends FieldValues> extends InputHTMLAttributes<HTMLInputElement> {
  control: Control<T>;
  name: Path<T>;
  rules?: Omit<RegisterOptions<any, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  isRequired?: boolean;
  regExpErrorText?: string;
  requiredErrorText?: string;
  defaultValue?: PathValue<T, Path<T>> | undefined;
}

export function EmailInput<T extends FieldValues>(props: EmailInputProps<T>) {
  const { t } = useTranslation();
  const defaultValidErrorText = `${t(please)} ${t(enter)} ${t(valid)} ${t(email)}`;
  const defaultRequiredErrorText = `${t(please)} ${t(enter)} ${t(email)}`;
  return (
    <Controller
      control={props.control}
      name={props.name}
      defaultValue={props.defaultValue}
      rules={{
        ...props.rules,
        required: {
          value: !!props.isRequired,
          message: props.requiredErrorText || defaultRequiredErrorText,
        },
        pattern: {
          value: EMAIL_REGEXP,
          message: props.regExpErrorText || defaultValidErrorText,
        },
      }}
      render={({ field: { name, ref, onChange } }) => {
        return (
          <Input
            {...props}
            ref={ref}
            defaultValue={props.defaultValue}
            name={name}
            onChange={(e) => onChange(trimString(e.target.value))}
            type="email"
            minLength={1}
          />
        );
      }}
    />
  );
}

export default EmailInput;

import { gql } from '@apollo/client';

export const GET_ALL_AGE_RANGE = gql`
  query GetAllAgeRange($page: Int, $size: Int) {
    employmentAgeRangesList(paginationDto: { page: $page, size: $size }) {
      _id
      from
      to
    }
  }
`;

export const GET_SINGLE_AGE_RANGE = gql`
  query GetSingleAgeRange($id: String!) {
    employmentAgeRangeDetails(ageRangeDetailsDto: { id: $id }) {
      _id
      from
      to
    }
  }
`;

export const ADD_AGE_RANGE = gql`
  mutation AddAgeRange($from: Int!, $to: Int) {
    employmentCreateAgeRange(employmentCreateAgeRangeDto: { from: $from, to: $to }) {
      _id
      from
      to
    }
  }
`;

export const UPDATE_AGE_RANGE = gql`
  mutation UpdateAgeRange($from: Int!, $to: Int, $id: String!) {
    employmentUpdateAgeRange(employmentUpdateAgeRangeDto: { from: $from, to: $to, id: $id }) {
      _id
      from
      to
    }
  }
`;

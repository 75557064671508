import React, { useState } from 'react';
import { add, category, emlak, emlakCategories, emptyList, useAddToCategory } from 'tools/i18n/constants/i18n';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import { Loading } from 'components';
import PagesLayout from 'pages/components/layout';
import { useTranslation } from 'react-i18next';
import useEmlakCategories from './useEmlakCategories';
import YourListIsEmpty from 'pages/components/emptyList';
import { EmlakCategoryTable } from 'pages/content-management/emlak/categories/components';
import { useToggle, useUpdateEffect } from 'hooks';
import { t_emlakCategory } from 'pages/content-management/emlak/categories/type';
import { SearchForm } from 'components/forms';

export default function EmlakCategoriesList() {
  const { t } = useTranslation();
  const { loading, data, isEmptyList, fetchMore, refetch, error } = useEmlakCategories();
  const [realData, setRealData] = useState<t_emlakCategory[]>(data);
  const [queryLoading, toggleQueryLoading] = useToggle(false);
  const handleSearch = (searchKeyword: string) => {
    toggleQueryLoading(true);
    fetchMore({
      variables: {
        query: searchKeyword,
      },
    })
      .then((res) => {
        if (res.data?.realEstateFindAllCategories) {
          setRealData(res.data.realEstateFindAllCategories);
        }
        toggleQueryLoading(false);
      })
      .catch((err) => {
        console.error(err);
        toggleQueryLoading(false);
      });
  };
  const handleRefetch = async () => {
    toggleQueryLoading(true);
    await refetch();
    toggleQueryLoading(false);
  };
  useUpdateEffect(() => {
    setRealData(data);
  }, [data]);
  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <Loading loading={loading} failed={!!error} onFail={handleRefetch}>
        <PagesLayout newSectionButtonLabel={`${t(add)} ${t(category)}`}>
          <Card>
            <CardHeader>
              <div className="d-flex align-items-center justify-content-between">
                <h5>{t(emlakCategories)}</h5>
                <div>
                  <SearchForm onChange={handleSearch} />
                </div>
              </div>
            </CardHeader>
            <CardBody className="px-3 py-2">
              {isEmptyList ? (
                <YourListIsEmpty
                  title={`${t(emptyList)}, ${t(useAddToCategory)}`}
                  addButtonTitle={`${t(add)} ${t(emlak)} ${t(category)}`}
                  addNewButton
                />
              ) : (
                <Loading loading={queryLoading}>
                  <EmlakCategoryTable data={realData} loading={queryLoading} />
                </Loading>
              )}
            </CardBody>
          </Card>
        </PagesLayout>
      </Loading>
    </Col>
  );
}

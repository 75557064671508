import { t_requiredFields } from 'components/tree-select-checkbox/type';
import { APP_HELPER } from 'tools/enums';

type t_dataToTreeOptions = {
  labelKey: string;
  childKey: string;
  iconKey: string;
  id: string;
  [x: string]: any;
};

export function dataToTree<T>(
  data: T[],
  options: t_dataToTreeOptions,
  setParentIconForChild?: boolean
): ({ [i in (typeof options)[keyof typeof options] as string]: unknown } & t_requiredFields<T>)[] {
  const makeChild = (dataItem: T, parentIcon: string | undefined) => {
    let convertedDataObj: T[] = [];
    if (dataItem && dataItem?.[options.childKey]) {
      convertedDataObj = dataItem[options.childKey].map((child: T) => {
        let makeItem = {};
        return (makeItem = {
          ...child,
          ...makeItem,
          child: makeChild(child, setParentIconForChild ? child[options.iconKey] : undefined),
          icon: child[options.iconKey] || parentIcon || APP_HELPER.defaultBazaarAndJobIcon,
          label: child[options.labelKey],
          id: child[options.id],
        });
      });
      return convertedDataObj;
    } else {
      return undefined;
    }
  };
  return data.map((dataItem) => {
    return {
      ...dataItem,
      icon: dataItem[options.iconKey] || APP_HELPER.defaultBazaarAndJobIcon,
      label: dataItem[options.labelKey],
      child: makeChild(dataItem, setParentIconForChild ? data[options.iconKey] : undefined),
      id: dataItem[options.id],
    };
  });
}

export function dataToTreeV2<T>(
  data: T[],
  options: t_dataToTreeOptions,
  setParentIconForChild?: boolean,
  boldOptions?: {
    id: string;
    selectable: boolean;
  }
): ({ [i in (typeof options)[keyof typeof options] as string]: unknown } & t_requiredFields<T>)[] {
  const makeChild = (dataItem: T, parentIcon: string | undefined, childIndex: number) => {
    let convertedDataObj: T[] = [];
    if (dataItem && dataItem?.[options.childKey]) {
      convertedDataObj = dataItem[options.childKey].map((child: T) => {
        let makeItem = {};
        return (makeItem = {
          ...child,
          ...makeItem,
          children: makeChild(child, setParentIconForChild ? child[options.iconKey] : undefined, childIndex + 1),
          logo: child[options.iconKey] || parentIcon,
          title: child[options.labelKey],
          key: child[options.id],
          selectable: boldOptions?.selectable,
          className: `position-relative left-${childIndex} pointer ${
            boldOptions?.id === child[options.id] ? 'selected-node py-2 rounded-md' : ''
          }`,
        });
      });
      return convertedDataObj;
    } else {
      return undefined;
    }
  };
  return data.map((dataItem) => {
    return {
      ...dataItem,
      logo: dataItem[options.iconKey],
      title: dataItem[options.labelKey],
      children: makeChild(dataItem, setParentIconForChild ? data[options.iconKey] : undefined, 1),
      key: dataItem[options.id],
      selectable: boldOptions?.selectable,
      className: `pointer position-relative ${
        boldOptions?.id === dataItem[options.id] ? 'selected-node py-2 rounded-md' : ''
      }`,
    };
  });
}

import { gql } from '@apollo/client';

export const GET_SCHOOL_LIST = gql`
  query getSchoolList(
    $page: Int!
    $size: Int!
    $lang: AcceptedLanguageEnum
    $grade: EducationGradeEnum
    $isRegistered: Boolean
    $isVisible: Boolean
    $isActive: Boolean
    $keyword: String
  ) {
    EmploymentSchoolsList(
      schoolListDto: {
        pagination: { page: $page, size: $size }
        lang: $lang
        filter: {
          grade: $grade
          isRegistered: $isRegistered
          isVisible: $isVisible
          isActive: $isActive
          keyword: $keyword
        }
      }
    ) {
      count
      data {
        createdAt
        updatedAt
        _id
        name
        logo
        city
        county
        postalCode
        fullAddress
        grade
        websiteUrl
        bio
        contactPhoneCode
        contactPhoneNumber
        contactEmail
        uniqueCode
        studentCountCapacity
        isRegistered
        isVisible
        isActive
      }
    }
  }
`;

export const CREATE_SCHOOL = gql`
  mutation createSchool($name: String!, $city: String!, $county: String, $grade: EducationGradeEnum!) {
    EmploymentCreateSchool(createSchoolDto: { name: $name, city: $city, county: $county, grade: $grade }) {
      _id
      name
      logo
      city
      county
      postalCode
      fullAddress
      grade
      websiteUrl
      bio
      contactPhoneCode
      contactPhoneNumber
      contactEmail
      uniqueCode
      studentCountCapacity
      isRegistered
      isVisible
      isActive
    }
  }
`;

export const UPDATE_SCHOOL = gql`
  mutation updateSchool(
    $id: String!
    $name: String
    $city: String
    $county: String
    $postalCode: String
    $bio: String
    $websiteUrl: String
    $grade: EducationGradeEnum
    $fullAddress: String
    $contactPhoneCode: String
    $contactPhoneNumber: String
    $contactEmail: String
    $studentCountCapacity: Int
    $isRegistered: Boolean
    $isActive: Boolean
    $isVisible: Boolean
  ) {
    EmploymentUpdateSchool(
      updateSchoolDto: {
        id: $id
        name: $name
        city: $city
        county: $county
        postalCode: $postalCode
        bio: $bio
        websiteUrl: $websiteUrl
        grade: $grade
        fullAddress: $fullAddress
        contactPhoneCode: $contactPhoneCode
        contactPhoneNumber: $contactPhoneNumber
        contactEmail: $contactEmail
        studentCountCapacity: $studentCountCapacity
        isRegistered: $isRegistered
        isActive: $isActive
        isVisible: $isVisible
      }
    ) {
      _id
      name
      logo
      city
      county
      postalCode
      fullAddress
      grade
      websiteUrl
      bio
      contactPhoneCode
      contactPhoneNumber
      contactEmail
      uniqueCode
      studentCountCapacity
      isRegistered
      isVisible
      isActive
    }
  }
`;

export const GET_SCHOOL = gql`
  query getSingleSchool($id: String!) {
    EmploymentSchoolDetails(schoolDetailsDto: { id: $id }) {
      _id
      name
      city
      county
      logo
      postalCode
      bio
      websiteUrl
      grade
      fullAddress
      contactPhoneCode
      contactPhoneNumber
      contactEmail
      studentCountCapacity
      uniqueCode
      isRegistered
      isActive
      isVisible
      user {
        id
        firstName
        lastName
        mail
        phoneNumber
      }
    }
  }
`;

export const ASSIGN_ADMIN = gql`
  mutation assignAdmin($userId: Int!, $schoolId: String!) {
    assignSchoolAdmin(AssignSchoolAdminDto: { userId: $userId, schoolId: $schoolId }) {
      message
    }
  }
`;
export const FIND_ALL_ADMINS = gql`
  query findAdmins($adminType: [AdminTypeEnum!] = [SCHOOL_ADMIN], $schoolAdminWithNoSchool: Boolean = true) {
    findAllAdmins(adminListDto: { adminType: $adminType, schoolAdminWithNoSchool: $schoolAdminWithNoSchool }) {
      id
      firstName
      lastName
      mail
      phoneNumber
      roles {
        id
        title
        slug
        permission {
          id
          title
        }
      }
    }
  }
`;

export const DELETE_ASSIGN_ADMIN = gql`
  mutation deleteAssignedAdmin($schoolId: String!, $userId: Int!) {
    removeSchoolAdmin(removeSchoolAdminDto: { schoolId: $schoolId, userId: $userId }) {
      message
    }
  }
`;

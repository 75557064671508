import { format } from 'date-fns';
import tr from 'date-fns/locale/tr';
import React, { FC, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { FormGroup } from 'reactstrap';
registerLocale('tr', tr);

interface DatePickerProps {
  onSelect?: (date: string | undefined) => void;
  defaultDate?: string | null;
  placeholder?: string;
}

const Datepicker: FC<DatePickerProps> = (props) => {
  const { onSelect, defaultDate, placeholder } = props;
  const [date, setDate] = useState<Date | null | undefined>(defaultDate ? new Date(defaultDate as string) : undefined);

  const handleChange = (date: Date) => {
    if (date) {
      setDate(new Date(date));
      onSelect?.(format(date, 'dd-MM-yyyy'));
    } else {
      onSelect?.(undefined);
    }
  };
  return (
    <FormGroup className="d-inline">
      <DatePicker
        locale={tr}
        placeholderText={placeholder}
        className="form-control digits"
        value={undefined}
        selected={date}
        dateFormat="d-MM-y"
        onChange={handleChange}
      />
    </FormGroup>
  );
};

export default Datepicker;

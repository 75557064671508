import React, { useMemo, useState } from 'react';
import { Stepper } from 'react-form-stepper';
import { GET_ONE_DATA, NotificationAction, SEND_ONE_NOTIFICATION, t_activeStep } from 'pages/notification/types';
import { useTranslation } from 'react-i18next';
import { notification } from 'tools/i18n/constants/i18n';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import { StepDTO } from 'react-form-stepper/dist/components/Step/StepTypes';
import Content from 'pages/notification/components/content';
import Action from 'pages/notification/components/action';
import Filters from 'pages/notification/components/filters';
import { ReviewAndSend } from 'pages/notification/components';
import PagesLayout from 'pages/components/layout';
import { useParams } from 'react-router-dom';
import { useQuery, useToggle, useUpdateEffect } from 'hooks';
import { GET_ONE_NOTIFICATION } from 'services/graphql/queries/notification';
import { isNew, toUndefined } from 'tools/methods';
import { Loading } from 'components';

const DEFAULT_STATE: SEND_ONE_NOTIFICATION<true> = {
  failError: null,
  id: undefined,
  isSilent: undefined,
  isDraft: true,
  payload: {
    url: undefined,
    contents: [],
    imageName: undefined,
  },
  actionType: undefined,
  filters: undefined,
};

function NotificationDetails(): JSX.Element {
  const { type } = useParams();
  const { t } = useTranslation();
  const [enablePreviewMode, toggleEnablePreviewMode] = useToggle(false);
  const [activeStep, setActiveStep] = useState<t_activeStep>(0);
  const [savedData, setSavedData] = useState<SEND_ONE_NOTIFICATION<true>>(DEFAULT_STATE);
  const { data: NotifData, loading: getLoading } = useQuery<
    { getCustomNotificationDetails: GET_ONE_DATA },
    { id: number }
  >(GET_ONE_NOTIFICATION, {
    skip: isNew(type),
    variables: {
      id: parseInt(type as string),
    },
  });
  const handleChangeSavedData = <N extends boolean, T extends keyof SEND_ONE_NOTIFICATION<N>>(
    arg: Record<T, SEND_ONE_NOTIFICATION<N>[T]>
  ) => {
    setSavedData((prev) => ({ ...prev, ...arg }));
  };
  useUpdateEffect(() => {
    const customNotif = NotifData?.getCustomNotificationDetails;
    if (customNotif) {
      if (
        customNotif.message.sentAt ||
        customNotif?.message?.status === 'SENDING' ||
        customNotif?.message?.status === 'FAILED'
      ) {
        toggleEnablePreviewMode(true);
      }
      handleChangeSavedData({
        failError: customNotif?.message?.failError,
        receiverCount: customNotif.receiverCount,
        isDraft: undefined,
        isSilent: customNotif.message.isSilent,
        id: customNotif.message.id as number,
        filters: {
          userType: customNotif?.message?.filter?.userType,
          userRoles: {
            employer: !!customNotif?.message?.filter?.employers,
            employee: !!customNotif?.message?.filter?.employees,
            all: !!customNotif?.message?.filter?.employees && !!customNotif?.message?.filter?.employers,
          },
          sendToAll: customNotif?.message?.filter?.sendToAll,
          hasBazaarAds: customNotif?.message?.filter?.hasBazaarAds,
          isStudent: customNotif?.message?.filter?.isStudent,
          isStore: customNotif?.message?.filter?.isStore,
          hasBlueTick: customNotif?.message?.filter?.hasBlueTick,
          hasJobAds: customNotif?.message?.filter?.hasJobAds,
          isInGuide: customNotif?.message?.filter?.isInGuide,
        },
        actionType: toUndefined(customNotif.message.action) as NotificationAction | undefined,
        payload: {
          url: toUndefined(customNotif.message.url) as string | undefined,
          contents: customNotif.message.translates
            .map((item) => ({
              title: item.title,
              body: item.body,
              language: item.language,
            }))
            .sort((a) => (a.language === 'tr' ? -1 : 1)),
          imageName: toUndefined(customNotif.message.imageName) as string | undefined,
          bannerUrl: toUndefined(customNotif?.message?.bannerUrl) as string | undefined,
        },
      });
    }
  }, [NotifData]);

  useUpdateEffect(() => {
    setActiveStep(3);
  }, [enablePreviewMode]);

  const steps: StepDTO[] = useMemo(() => {
    return [
      {
        index: 0,
        active: activeStep === 0,
        label: 'İçerik',
        completed: activeStep > 0,
      },
      {
        index: 1,
        active: activeStep === 1,
        label: 'Filtreler',
        completed: activeStep > 1,
      },
      {
        index: 2,
        active: activeStep === 2,
        label: 'Aksiyon',
        completed: activeStep > 2,
      },
      {
        index: 3,
        active: activeStep === 3,
        label: 'İncele ve Gönder',
        completed: activeStep > 3,
      },
    ];
  }, [activeStep]);

  const goNext = (step: t_activeStep, force = false) => {
    if (force) {
      setActiveStep(step);
    } else if (!enablePreviewMode && savedData.payload?.contents?.length > 0) {
      setActiveStep(step);
    }
  };
  const Elements: Record<t_activeStep, JSX.Element> = {
    '0': <Content savedData={savedData} goNext={goNext} handleChangeSavedData={handleChangeSavedData} />,
    '1': <Filters savedData={savedData} goNext={goNext} handleChangeSavedData={handleChangeSavedData} />,
    '2': <Action savedData={savedData} goNext={goNext} handleChangeSavedData={handleChangeSavedData} />,
    '3': (
      <ReviewAndSend
        previewMode={enablePreviewMode}
        savedData={savedData}
        goNext={goNext}
        handleChangeSavedData={handleChangeSavedData}
      />
    ),
  };
  return (
    <Loading loading={getLoading}>
      <Col xs={12} md={12} xl={11} xxl={10} className="mx-auto">
        <PagesLayout backLink="notification" backLinkTitle={t(notification)} backLinkParam="/">
          <Card>
            <CardHeader>
              <h5>{'Yeni Bildirim Gönder'}</h5>
            </CardHeader>
            <Col xs={12} className="mx-auto">
              <CardBody>
                <Stepper
                  className="px-0"
                  disabled={enablePreviewMode}
                  aria-disabled={enablePreviewMode}
                  styleConfig={{
                    activeTextColor: '#ffffff',
                    size: '35px',
                    activeBgColor: '#7366ff',
                    completedBgColor: '#1F71B8',
                    completedTextColor: '#ffffff',
                    borderRadius: '100%',
                    inactiveTextColor: '#ccc',
                    inactiveBgColor: '#323232',
                    labelFontSize: '18px',
                    fontWeight: 'bold',
                    circleFontSize: '18px',
                  }}
                  dir="horizontal"
                  activeStep={activeStep}
                  steps={steps}
                  onClick={(e: any) => {
                    if (enablePreviewMode) return;
                    if (e.target.tagName === 'SPAN' || e.target.tagName === 'BUTTON') {
                      if (typeof e.target.children[0] === 'object') {
                        goNext((parseInt(e.target.children[0].innerHTML as string) - 1) as t_activeStep);
                      } else {
                        goNext((parseInt(e.target.innerHTML) - 1) as t_activeStep);
                      }
                    }
                  }}
                />
                <Col xs={12} className="mx-auto bg-white">
                  {Elements[activeStep]}
                </Col>
              </CardBody>
            </Col>
          </Card>
        </PagesLayout>
      </Col>
    </Loading>
  );
}

export default NotificationDetails;

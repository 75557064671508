import { dataToTree } from 'components/tree-select-checkbox/utils';
import { useGetStore, useMutation } from 'hooks';
import { useParams } from 'react-router-dom';
import { CREATE_BAZZAR_CATEGORY, MUTATE_BAZZAR_CATEGORIES } from 'services/graphql/queries/bazzar';
import { isNew } from 'tools/methods';
import { t_bazaarCategory } from './type';

export default function useBazaarCategoryDetail() {
  const { type } = useParams();
  const [createExecute] = useMutation(CREATE_BAZZAR_CATEGORY);
  const [updateExecute] = useMutation(MUTATE_BAZZAR_CATEGORIES);
  const selectedNode = useGetStore().Tree.selectedNode;
  const treeData = useGetStore().Tree.treeData;
  return {
    treeData: dataToTree<Omit<t_bazaarCategory, 'customError'>>(treeData, {
      childKey: 'child',
      iconKey: 'icon',
      id: 'id',
      labelKey: 'title',
    }),
    selectedNode,
    isNew: isNew(type),
    createExecute,
    updateExecute,
  };
}

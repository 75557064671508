import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import LayoutRoutes from 'route/LayoutRoutes';
import Callback from 'pages/authentication/callback';
import { useGetStore } from 'hooks';

const Routers = () => {
  const user = useGetStore().User;
  const EXACT_DASHBOARD_ROUTE = {
    admin: 'dashboard',
    'school-admin': 'schoolAdmin',
    'super-admin': 'dashboard',
  };
  const dashboardPath = EXACT_DASHBOARD_ROUTE[user.userInfo.role?.[0]];
  return (
    <Routes>
      <Route path="/" element={<Callback />}>
        <Route path={`/${dashboardPath}/*`} element={<LayoutRoutes />} />
        <Route path="/" element={<Navigate to={`/${dashboardPath}`} />} />
        <Route path="*" element={<h1> 404 Not Found </h1>} />
      </Route>
    </Routes>
  );
};

export default Routers;

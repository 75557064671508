import useQuery from 'hooks/useQuery';
import { GET_EMPLOYEE_PROFILE } from 'services/graphql/queries/employee';
import { detailType } from 'tools/types/sectionTypes/student';
import { useParams } from 'react-router-dom';

export default function useEmployeeDetails() {
  const { type } = useParams();
  const { data, loading, error, refetch } = useQuery(GET_EMPLOYEE_PROFILE, {
    variables: {
      userId: parseInt(type as string),
    },
  });
  const isEmpty = !data?.EmploymentGetEmployeeProfile;
  const updatedData = isEmpty ? null : (data?.EmploymentGetEmployeeProfile as detailType);
  return { data: updatedData, error, loading, refetch };
}

import React, { useMemo } from 'react';
import { t_deleteReason, t_deleteReasonLanguage } from 'pages/user-management/deleteReasons/useDeleteReasons';
import { Button, Card, CardBody, CardHeader, Col, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  add,
  created,
  deleteReason,
  deleteReasons,
  enter,
  name,
  needCustomText,
  please,
  record,
  save,
  successfully,
  update,
  updated,
} from 'tools/i18n/constants/i18n';
import Select from 'react-select';
import OnlyCharacter from 'components/inputs/onlyCharacter';
import Styled from 'styled-components';
import useMutation from 'hooks/useMutation';
import { ADD_DELETE_REASON, UPDATE_DELETE_REASON } from 'services/graphql/queries/deleteReasons';
import { goBack, isNew } from 'tools/methods';
import { toast } from 'react-toastify';
import useDeleteReasonsDetail from 'pages/user-management/deleteReasons/useDeleteReasonsDetail';
import { Loading } from 'components';
import { useParams } from 'react-router-dom';
import PagesLayout from 'pages/components/layout';
import ShowError from 'components/showError';

const FormG = Styled(FormGroup)`
  min-height : 100px;
  max-height : 100px;
  box-sizing : border-box;
`;

const Checkbox = Styled(Input)`
  width : 20px;
  height : 20px
`;

export default function DeleteReasonDetail() {
  const { type } = useParams();
  const { t } = useTranslation();
  return (
    <Col xs={12} xl={10} xxl={8} className="mx-auto">
      <PagesLayout backLinkTitle={`${t(deleteReasons)}`}>
        <Card className="mt-5">
          <CardHeader>
            <h4>{`${t(isNew(type) ? add : update)} ${t(deleteReason)}`}</h4>
          </CardHeader>
          <CardBody>{isNew(type) ? <NewDeleteReason /> : <UpdateDeleteReason />}</CardBody>
        </Card>
      </PagesLayout>
    </Col>
  );
}

function NewDeleteReason() {
  return <DeleteReasonForm isNew={true} data={undefined} />;
}

function UpdateDeleteReason() {
  const { data, loading } = useDeleteReasonsDetail();
  return (
    <Loading loading={loading}>
      <DeleteReasonForm isNew={false} data={data} />
    </Loading>
  );
}
interface DeleteReasonFormProps<N extends boolean> {
  isNew: N;
  data: N extends true ? undefined : t_deleteReason;
}

type t_defaultValues = t_deleteReason & { customError?: string };
function DeleteReasonForm<N extends boolean, T extends DeleteReasonFormProps<N>>(props: T): JSX.Element {
  const { isNew, data } = props;
  const { t } = useTranslation();
  const [createExecute, { loading: createLoading }] = useMutation<any, Omit<t_deleteReason, 'id'>>(ADD_DELETE_REASON);
  const [updateExecute, { loading: updateLoading }] = useMutation<any, t_deleteReason>(UPDATE_DELETE_REASON);
  const DEFAULT_LANGUAGE: t_deleteReasonLanguage[] = useMemo(() => {
    return [
      {
        id: data?.language?.find((l) => l.language === 'en')?.id,
        language: 'en',
        content: data?.language?.find((l) => l.language === 'en')?.content || '',
      },
      {
        id: data?.language?.find((l) => l.language === 'tr')?.id,
        language: 'tr',
        content: data?.language?.find((l) => l.language === 'tr')?.content || '',
      },
    ];
  }, [data]);
  const DEFAULT_VALUES: t_deleteReason = useMemo(
    () => ({
      id: data?.id,
      language: DEFAULT_LANGUAGE,
      needToInsertCustomText: !!data?.needToInsertCustomText,
    }),
    [data]
  );
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { isDirty, errors },
  } = useForm<t_defaultValues>({
    defaultValues: DEFAULT_VALUES,
  });

  function onCreate(data: Omit<t_deleteReason, 'id'>) {
    console.log(this);
    console.log(data);
    createExecute({
      variables: data,
    })
      .then((res) => {
        console.log(res);
        if (res.data) {
          toast.success(`${t(record)} ${t(created)} ${t(successfully)}`);
          goBack();
        } else {
          //@ts-ignore
          setValue('customError', res?.errors?.message);
        }
      })
      .catch((err) => {
        setValue('customError', err?.message);
      });
  }

  function onUpdate(data: t_deleteReason) {
    updateExecute({
      variables: data,
    })
      .then((res) => {
        if (res.data) {
          toast.success(`${t(record)} ${t(updated)} ${t(successfully)}`);
          goBack();
        } else {
          //@ts-ignore
          setValue('customError', res?.errors?.message);
        }
      })
      .catch((err) => {
        setValue('customError', err?.message);
      });
  }

  return (
    <Form
      aria-disabled={createLoading || updateLoading}
      disabled={createLoading || updateLoading}
      onSubmit={handleSubmit(isNew ? onCreate : onUpdate, console.error)}
    >
      <FormGroup>{!!getValues('customError') && <ShowError>{getValues('customError')}</ShowError>}</FormGroup>

      <FormGroup className="d-flex align-items-center justify-content-between">
        <FormG className="d-flex flex-column flex-grow-1">
          <Controller
            control={control}
            defaultValue="tr"
            name="language.1.language"
            render={({ field: { onChange } }) => {
              return (
                <Select isDisabled defaultValue={{ value: 'tr', label: 'Turkish' }} onChange={(val) => onChange(val)} />
              );
            }}
          />

          <OnlyCharacter
            className="mt-3"
            isNumberAllowed
            isEveryCharacterAllowed
            isRequired
            control={control}
            placeholder={`${t(please)} ${t(enter)} ${t(name)}`}
            name="language.1.content"
            defaultValue={DEFAULT_VALUES?.language?.find?.((item) => item.language === 'tr')?.content}
          />
          <FormFeedback>{errors?.language?.[1]?.content?.message}</FormFeedback>
        </FormG>
        <FormG className="d-flex flex-column flex-grow-1 ml-3">
          <Controller
            control={control}
            defaultValue="en"
            name="language.0.language"
            render={({ field: { onChange } }) => {
              return (
                <Select isDisabled defaultValue={{ value: 'en', label: 'English' }} onChange={(val) => onChange(val)} />
              );
            }}
          />
          <OnlyCharacter
            placeholder={`${t(please)} ${t(enter)} ${t(name)}`}
            className="mt-3"
            isNumberAllowed
            isEveryCharacterAllowed
            isRequired
            control={control}
            name="language.0.content"
            defaultValue={DEFAULT_VALUES?.language?.find?.((item) => item.language === 'en')?.content}
          />
          <FormFeedback>{errors?.language?.[0]?.content?.message}</FormFeedback>
        </FormG>
      </FormGroup>
      <FormGroup>
        <Controller
          control={control}
          defaultValue={DEFAULT_VALUES.needToInsertCustomText}
          name="needToInsertCustomText"
          render={({ field: { onChange } }) => {
            return (
              <Checkbox
                id="needCustomText"
                defaultChecked={DEFAULT_VALUES.needToInsertCustomText}
                type="checkbox"
                onChange={onChange}
              />
            );
          }}
        />

        <Label className="ml-2 mb-0" htmlFor="needCustomText">
          {t(needCustomText)}
        </Label>
      </FormGroup>
      <Button
        className="w-100"
        aria-disabled={!isDirty || createLoading || updateLoading}
        disabled={!isDirty || createLoading || updateLoading}
        color="primary"
      >
        {t(isNew ? save : update)}
      </Button>
    </Form>
  );
}

import { ReactNode } from 'react';
import { Primitive } from 'tools/types';
import { t_company } from 'tools/types/sectionTypes/company';

export interface componentsType {
  name: formsName;
  isActive: boolean;
  required: boolean;
  title: ReactNode;
  step: steps;
  state?: string;
  element: ReactNode;
  expanded?: boolean;
}

export enum formsNameEnum {
  EmailVerification = 'EmailVerification',
  CreateCompany = 'CreateCompany',
  CompanySpecialties = 'CompanySpecialties',
  CompanySizeEstablishment = 'CompanySizeEstablishment',
  CompanyContacts = 'CompanyContacts',
  CompanyAddress = 'CompanyAddress',
  CompanyWorkTime = 'CompanyWorkTime',
  CompanyAbout = 'CompanyAbout',
  CompanySocialMedia = 'CompanySocialMedia',
  CompanyDocuments = 'CompanyDocuments',
  CompanyLogo = 'CompanyLogo',
}
export type t_componentRegisterInfo = { step: steps; isCompleted: boolean };

export type formsName = keyof typeof formsNameEnum;
export type t_defaultComponentsState = Record<formsName, t_componentRegisterInfo>;
export type t_defaultComponentsResponse = Record<keyof t_responseValues, Primitive>;

export type t_nextStepFunc = (step: steps) => void;
//export type t_changeComponentSCompleted = (section: formsName) => void;
export type t_handleChangeComponentValues = (record: Partial<Record<keyof t_company, any>>) => void;
export type t_handleChangeComponentResponse = (record: Partial<Record<keyof t_responseValues, Primitive>>) => void;

export type t_handleChangeComponentState = (componentsKey: formsName, isCompleted: boolean) => void;

export type t_responseValues = {
  companyId: string | undefined;
  userId: string | undefined;
  sentCode: string | undefined;
};
export enum steps {
  'one' = 1,
  'two' = 2,
  'three' = 3,
}

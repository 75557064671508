import useQuery from 'hooks/useQuery';
import { add, banReason, banReasons, New } from 'tools/i18n/constants/i18n';
import { useTranslation } from 'react-i18next';
import { t_languageKeys } from 'tools/types';
import { GET_BAN_REASONS_LIST } from 'services/graphql/queries/banReasons';

export type t_deleteReasonLanguage = {
  id?: number;
  language: t_languageKeys;
  content: string;
};

export type t_deleteReason = {
  id?: number;
  needToInsertCustomText?: boolean;
  language?: t_deleteReasonLanguage[];
};

export default function useBanReasons() {
  const { data, error, loading, refetch, fetchMore } = useQuery(GET_BAN_REASONS_LIST);
  console.log(data);
  const { t } = useTranslation();
  const realData = data?.getAllBanReason || [];
  const addButtonText = `${t(add)} ${t(New)} ${t(banReason)}`;
  const tableTitle = t(banReasons);

  return { data: realData as t_deleteReason[], error, loading, refetch, fetchMore, addButtonText, tableTitle };
}

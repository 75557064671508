import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'tools/i18n';
import './assets/scss/app.scss';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
reportWebVitals();

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { city, county, enter, please, school, select } from 'tools/i18n/constants/i18n';
import { Control, Controller } from 'react-hook-form';
import { t_errors } from 'tools/types';
import { t_defaultValues } from 'pages/school/schoolDetail';
import { useTranslation } from 'react-i18next';
import useQuery from 'hooks/useQuery';
import { GET_CITY_COUNTIES } from 'services/graphql/queries/App';
import Select from 'react-select';
import ShowError from 'components/showError';
import { useUpdateEffect } from 'hooks';

interface CityCountyProps {
  control: Control<t_defaultValues, any>;
  defaultValue: t_defaultValues | undefined;
  errors: t_errors<t_defaultValues>;
}
type t_cityCounty = {
  city: string;
  counties: string[];
};
type t_dropdownCityCounty = {
  label: string;
  value: string;
  counties: string[];
};
export default function CityCounty(props: CityCountyProps) {
  const { t } = useTranslation();
  const { control, defaultValue, errors } = props;
  const { data, loading, error } = useQuery(GET_CITY_COUNTIES);

  const OPTIONS: t_dropdownCityCounty[] = useMemo(() => {
    if (data?.employmentCitiesAndCountiesList) {
      return (data.employmentCitiesAndCountiesList as t_cityCounty[]).map((city) => ({
        label: city.city,
        value: city.city,
        counties: city.counties,
      }));
    }
    return [];
  }, [data]);
  const ple = `${t(please)} ${t(enter)} ${t(school)}`;
  const countySelectRef = useRef<any>(null);
  const [selectedCity, setSelectedCity] = useState<t_dropdownCityCounty | undefined>(undefined);
  const COUNTY_OPTIONS = useMemo(() => {
    if (selectedCity?.counties.length) {
      return selectedCity.counties.map((item) => ({ label: item, value: item }));
    }
    return [];
  }, [selectedCity]);

  useEffect(() => {
    if (defaultValue?.city) {
      const findDefaultCity = OPTIONS.find((city) => city.value === defaultValue?.city);
      setSelectedCity(findDefaultCity);
    }
  }, [OPTIONS, defaultValue?.city]);

  useUpdateEffect(() => {
    if (countySelectRef.current && selectedCity?.value && selectedCity.value !== defaultValue?.city) {
      countySelectRef.current.clearValue();
    }
  }, [selectedCity]);
  const CITY_DEFAULT_VALUE = useMemo(() => {
    if (defaultValue?.city) {
      return OPTIONS.find((city) => city.value === defaultValue.city);
    }
    return undefined;
  }, [defaultValue?.city, OPTIONS]);
  if (error) return <ShowError> {error?.message} </ShowError>;
  return (
    <>
      <FormGroup>
        <Label>{t(city)}</Label>
        <Controller
          name="city"
          control={control}
          rules={{ required: `${t(select)} ${t(school)} ${t(city)}` }}
          defaultValue={defaultValue ? defaultValue?.city : undefined}
          render={({ field: { onChange } }) => {
            if (loading || !OPTIONS.length) return <Input disabled aria-disabled placeholder="Loading City ..." />;
            return (
              <>
                <Select
                  isLoading={loading}
                  options={OPTIONS}
                  onChange={(value: t_dropdownCityCounty) => {
                    if (value) {
                      setSelectedCity(value);
                      onChange(value.value);
                    }
                  }}
                  placeholder={`${ple} ${t(city)}`}
                  defaultValue={CITY_DEFAULT_VALUE}
                />
              </>
            );
          }}
        />
        <FormFeedback>{errors?.city?.message}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label>{t(county)}</Label>
        <Controller
          name="county"
          control={control}
          render={({ field: { onChange } }) => {
            if (loading || !OPTIONS.length) return <Input disabled aria-disabled placeholder="Loading County ..." />;
            return (
              <Select
                isLoading={loading}
                ref={countySelectRef}
                isDisabled={!COUNTY_OPTIONS.length}
                options={COUNTY_OPTIONS}
                defaultValue={
                  defaultValue?.county ? { label: defaultValue.county, value: defaultValue.county } : undefined
                }
                onChange={(value) => {
                  if (value) {
                    onChange(value.value);
                  } else {
                    onChange(undefined);
                  }
                }}
                placeholder={`${ple} ${t(county)}`}
              />
            );
          }}
        />
        <FormFeedback>{errors?.county?.message}</FormFeedback>
      </FormGroup>
    </>
  );
}

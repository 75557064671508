import { gql } from '@apollo/client';

export const GET_HOTEL = gql`
  query getHotelList {
    hotelList {
      id
      name
      lat
      lon
      location
      webSite
      language {
        description
        language
      }
    }
  }
`;

export const CREATE_HOTEL = gql`
  mutation createHotel(
    $name: String!
    $lat: Float!
    $lon: Float!
    $location: String!
    $webSite: String!
    $countryId: Int!
    $cityId: Int!
    $language: [HotelDescriptionDto!]!
  ) {
    createHotel(
      createHotelDto: {
        name: $name
        lat: $lat
        lon: $lon
        location: $location
        webSite: $webSite
        countryId: $countryId
        cityId: $cityId
        language: $language
      }
    ) {
      id
      name
      location
      webSite
      lat
      lon
      language {
        description
        language
      }
    }
  }
`;

export const GET_SINGLE_HOTEL = gql`
  query getSingleHotel($id: Int!) {
    findOneHotel(findOneHotelDto: { id: $id }) {
      name
      id
      location
      webSite
      lat
      lon
      logo
      city {
        id
        name
      }
      country {
        id
        name
        city {
          id
          name
        }
      }
      language {
        description
        language
      }
    }
  }
`;

export const UPDATE_HOTEL = gql`
  mutation updateMotel(
    $id: Int!
    $name: String
    $countryId: Int
    $cityId: Int
    $lat: Float
    $lon: Float
    $location: String
    $webSite: String
    $language: [HotelDescriptionDto!]
  ) {
    updateHotel(
      updateHotelDto: {
        id: $id
        lat: $lat
        location: $location
        countryId: $countryId
        cityId: $cityId
        webSite: $webSite
        name: $name
        lon: $lon
        language: $language
      }
    ) {
      message
    }
  }
`;

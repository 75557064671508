import Link from 'components/link';
import Styled from 'styled-components';

export const StyledSlide = Styled(Link)`
  color: #000;
  min-height: 90px;
  max-height: 90x;
  img {
    min-width: 150px;
    max-width: 150px;
  }
  &:hover {
    color: #000;
  }
`;

export const ImageContainer = Styled.div`
  position: relative;
  border: 2px dotted #ccc;
  border-radius: 18px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  input {
    width: 100%;
    height: 100%;
  }
  img {
    width: auto;
    height: 200px;
   
  }
`;

export const TimePickerContainer = Styled.div`
  position: relative;
  width: 200px;
  text-align: center;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
    > div#clock-wrapper {
       position: absolute;
      top: -320px;
      left: 0;
   z-index: 999999999;
  }
`;

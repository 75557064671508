import { gql } from '@apollo/client';

export const GET_ALL_NOTIFICATION = gql`
  query GetAllNotifications(
    $page: Int!
    $size: Int!
    $keyword: String
    $from: Timestamp
    $to: Timestamp
    $status: [AdminNotificationStatusEnum!]
  ) {
    findAllCustomNotifications(
      findAllAdminNotificationDto: {
        pagination: { page: $page, size: $size }
        filter: { keyword: $keyword, date: { from: $from, to: $to }, status: $status }
      }
    ) {
      count
      data {
        id
        createdAt
        status
        sentAt
        title
      }
    }
  }
`;

export const CREATE_CONTENT_NOTIFICATION = gql`
  mutation CreateContentNotification($isDraft: Boolean!, $payload: AdminNotificationPayloadPayload!) {
    createCustomNotification(createCustomNotificationDto: { isDraft: $isDraft, payload: $payload }) {
      id
    }
  }
`;

export const CREATE_ACTION_NOTIFICATION = gql`
  mutation CreateActionNotification(
    $isDraft: Boolean!
    $actionType: NotificationActionEnum
    $contents: [AdminNotificationPayloadMultiLanguageContentPayload!]!
  ) {
    createCustomNotification(
      createCustomNotificationDto: { isDraft: $isDraft, actionType: $actionType, payload: { contents: $contents } }
    ) {
      id
    }
  }
`;

export const CREATE_FILTERS_NOTIFICATION = gql`
  mutation CreateFiltersNotification(
    $isDraft: Boolean!
    $contents: [AdminNotificationPayloadMultiLanguageContentPayload!]!
    $filters: AdminNotificationFilterPayload
  ) {
    createCustomNotification(
      createCustomNotificationDto: { isDraft: $isDraft, filters: $filters, payload: { contents: $contents } }
    ) {
      id
    }
  }
`;

export const CREATE_NOTIFICATION = gql`
  mutation CreateNotification(
    $isDraft: Boolean!
    $contents: [AdminNotificationPayloadMultiLanguageContentPayload!]!
    $filters: AdminNotificationFilterPayload
    $actionType: NotificationActionEnum
    $imageName: String
    $url: String
  ) {
    createCustomNotification(
      createCustomNotificationDto: {
        isDraft: $isDraft
        actionType: $actionType
        payload: { contents: $contents, imageName: $imageName, url: $url }
        filters: $filters
      }
    ) {
      id
    }
  }
`;

export const GET_ONE_NOTIFICATION = gql`
  query GetOneNotification($id: Int!) {
    getCustomNotificationDetails(getAdminNotificationDetailsDto: { id: $id }) {
      receiverCount
      message {
        id
        bannerUrl
        imageName
        failError
        url
        action
        isSilent
        sendAt
        sentAt
        status
        deletedAt
        translates {
          title
          body
          language
          id
        }
        filter {
          sendToAll
          userType
          employers
          employees
          isStudent
          hasJobAds
          hasBlueTick
          isInGuide
          isStore
          hasBazaarAds
          id
        }
      }
    }
  }
`;

export const UPDATE_NOTIFICATION = gql`
  mutation UpdateNotification(
    $id: Int!
    $isDraft: Boolean!
    $isSilent: Boolean
    $actionType: NotificationActionEnum
    $filters: AdminNotificationFilterPayload
    $payload: AdminNotificationPayloadPayload
  ) {
    updateCustomNotification(
      updateCustomNotificationDto: {
        id: $id
        isDraft: $isDraft
        isSilent: $isSilent
        actionType: $actionType
        payload: $payload
        filters: $filters
      }
    ) {
      id
    }
  }
`;

export const UPDATE_ACTION_NOTIFICATION = gql`
  mutation UpdateActionNotification(
    $id: Int!
    $isDraft: Boolean!
    $actionType: NotificationActionEnum
    $payload: AdminNotificationPayloadPayload
  ) {
    updateCustomNotification(
      updateCustomNotificationDto: { id: $id, isDraft: $isDraft, actionType: $actionType, payload: $payload }
    ) {
      id
    }
  }
`;

export const UPDATE_FILTERS_NOTIFICATION = gql`
  mutation UpdateFiltersNotification($id: Int!, $isDraft: Boolean!, $filters: AdminNotificationFilterPayload) {
    updateCustomNotification(updateCustomNotificationDto: { id: $id, isDraft: $isDraft, filters: $filters }) {
      id
    }
  }
`;

export const UPDATE_CONTENT_NOTIFICATION = gql`
  mutation UpdateContentNotification($id: Int!, $isDraft: Boolean!, $payload: AdminNotificationPayloadPayload!) {
    updateCustomNotification(updateCustomNotificationDto: { id: $id, isDraft: $isDraft, payload: $payload }) {
      id
    }
  }
`;

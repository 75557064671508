import SubmitButton from 'components/buttons/submitButton';
import LoadingSpin from 'components/loadingSpin';
import ShowError from 'components/showError';
import TreeSelectCheckbox from 'components/tree-select-checkbox';
import { t_requiredFields } from 'components/tree-select-checkbox/type';
import { useToggle } from 'hooks';
import { t_companyCategory } from 'pages/content-management/company/companyCategories/types';
import { newComponentsProps } from 'pages/user-management/employer/index';
import React, { FC, memo, useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Col, Form, FormFeedback, FormGroup, Label } from 'reactstrap';
import { categories, option, please, save, select, update, updated } from 'tools/i18n/constants/i18n';
import { errorFinder } from 'tools/methods';
import { t_company } from 'tools/types/sectionTypes/company';
import { formsNameEnum } from '../types';
import useCompanySpecialities, { t_defaultValues } from './useCompanySpecialities';

type CompanyContractsProps = newComponentsProps;

const CompanySpecialties: FC<CompanyContractsProps> = (props) => {
  const { isActive, componentsValues, componentsResponse, handleChangeComponentState, state } = props;
  const [loading, toggleLoading] = useToggle(false);
  const {
    data,
    loading: fetchLoading,
    error,
    refetch,
    execute,
    DEFAULT_VALUES,
  } = useCompanySpecialities(isActive, componentsValues);
  const {
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
    setError,
  } = useForm<t_defaultValues>({
    defaultValues: DEFAULT_VALUES,
  });
  const { t } = useTranslation();
  const onSubmit: SubmitHandler<t_defaultValues> = (data) => {
    if (isActive) {
      setValue('customError', '');
      if (!data.specialities.length) {
        setError('specialities', { message: `${t(please)} ${t(select)} ${t(option)}` });
      } else {
        toggleLoading(true);
        execute({
          variables: {
            ...componentsResponse,
            specialities: data.specialities,
          },
        })
          .then((res) => {
            if (res.data?.EmploymentAddOrUpdateEmployerSpecialities) {
              if (state.isCompleted) {
                toast.success(`${t(updated)}`);
              }
              handleChangeComponentState(formsNameEnum.CompanySpecialties, true);
              toggleLoading(false);
            } else {
              setValue('customError', errorFinder(res?.errors?.[0]?.message));
              toggleLoading(false);
            }
          })
          .catch((err) => {
            console.error(err);
            setValue('customError', errorFinder(err));
            toggleLoading(false);
          });
      }
    }
  };
  const handleAddSpeciality = useCallback((selectedNodeId: t_companyCategory['_id']) => {
    clearErrors('specialities');
    if (getValues('specialities')?.length) {
      if (getValues('specialities').includes(selectedNodeId)) {
        setValue(
          'specialities',
          getValues('specialities').filter((id) => id !== selectedNodeId)
        );
      } else {
        setValue('specialities', [...getValues('specialities'), selectedNodeId]);
      }
    } else {
      setValue('specialities', [selectedNodeId]);
    }
  }, []);

  useEffect(() => {
    if (componentsValues?.specialities?.length) {
      handleChangeComponentState(formsNameEnum.CompanySpecialties, true);
    }
  }, [componentsValues?.specialities]);
  return (
    <Col xs={8} lg={11} xxl={10} className="p-2 pb-4 mx-auto make-center">
      <LoadingSpin loading={fetchLoading} isFailed={!!error} onRetry={refetch}>
        <Form onSubmit={handleSubmit(onSubmit)} style={{ minWidth: '300px' }} className="w-100 p-2 mx-auto flex-column">
          <FormGroup>{!!getValues('customError') && <ShowError>{getValues('customError')}</ShowError>}</FormGroup>
          <FormGroup>
            <Label>
              <h6>{t(categories)}</h6>
            </Label>

            <Speciality
              data={data}
              defaultId={DEFAULT_VALUES.specialities as string[]}
              handleAddSpeciality={handleAddSpeciality}
            />
            <FormFeedback className="mt-3">{errors?.specialities?.message}</FormFeedback>
          </FormGroup>
          {isActive && (
            <SubmitButton loading={loading} className="w-100 mt-4">
              {t(state.isCompleted ? update : save)}
            </SubmitButton>
          )}
        </Form>
      </LoadingSpin>
    </Col>
  );
};
export default memo(CompanySpecialties);

interface SpecialityProps {
  data: t_requiredFields<t_company['specialities'][0]>[];
  handleAddSpeciality: (selectedNodeId: string) => void;
  defaultId?: string[];
}

const Speciality: FC<SpecialityProps> = memo((props) => {
  const { data, handleAddSpeciality, defaultId } = props;
  return (
    <FormGroup style={{ maxHeight: '500px', overflowY: 'scroll' }} className="w-100 d-flex flex-column mx-auto">
      <TreeSelectCheckbox
        data={data}
        defaultExpandedIds={defaultId}
        checkbox
        unselectParentsCheckbox
        isMultiSelect
        defaultExpanded
        onSelect={(node: t_requiredFields<t_companyCategory>) => {
          if (node) {
            handleAddSpeciality(node.id as string);
          }
        }}
      />
    </FormGroup>
  );
});

import { gql } from '@apollo/client';

export const GET_UNIVERSITY_SECTIONS = gql`
  query getUniversitySections($page: Int!, $size: Int!, $keyword: String) {
    employmentEducationSectionsList(paginationKeywordDto: { page: $page, size: $size, keyword: $keyword }) {
      count
      data {
        createdAt
        updatedAt
        originalName
        _id
        translations {
          language
          translate
        }
      }
    }
  }
`;
export const CREATE_UNIVERSITY_SECTION = gql`
  mutation createUniversitySection($translations: [TranslationsDto!]) {
    employmentCreateEducationSection(originalNameTranslationsDto: { translations: $translations }) {
      _id
    }
  }
`;
export const UPDATE_UNIVERSITY_SECTION = gql`
  mutation updateUniversitySection($id: String!, $translations: [TranslationsDto!]) {
    employmentUpdateEducationSection(idOriginalNameTranslationsDto: { id: $id, translations: $translations }) {
      _id
    }
  }
`;

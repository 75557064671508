import { DocumentNode } from 'graphql/language';
import React, { useEffect, useMemo, useRef } from 'react';
import { Control, Controller, Path, UseFormSetError } from 'react-hook-form';
import Select from 'react-select';
import { useQuery, useToggle, useUpdateEffect } from '../../../../hooks';
import { childrenType, t_newSlider, t_updateSlider } from '../types';
import { Spinner } from 'reactstrap';

interface PayloadSelectorProps {
  query: DocumentNode;
  variables?: Record<string, string | number | Record<string, string | unknown>> | undefined;
  control: Control<t_newSlider & t_updateSlider>;
  isStringPayload: boolean;
  onDataLoad: (
    data: unknown
  ) => { label: string; value: string; options?: { label: string; value: string | number }[] }[];
  name: Path<t_newSlider & t_updateSlider> | any;
  removeData: VoidFunction;
  defaultValue?: any;
  clearFormState?: VoidFunction;
  clearFormError?: VoidFunction;
  setError?: UseFormSetError<t_newSlider & t_updateSlider>;
  handleChange?: (list: string | number) => void;
  rules?: any;
  showExpireMessage?: boolean;
  isDisabled?: boolean;
}

export default function PayloadSelector({
  variables,
  query,
  control,
  isStringPayload,
  onDataLoad,
  name,
  defaultValue,
  rules,
  showExpireMessage,
  isDisabled,
  clearFormState,
  clearFormError,
  setError,
  handleChange,
}: PayloadSelectorProps) {
  const { data, loading } = useQuery(query, {
    variables,
  });
  console.log(query);

  const selectRef = useRef<any>();
  const OPTIONS = useMemo(() => {
    if (loading || !data) return [];
    return onDataLoad(data);
  }, [data, loading]);
  const findDefaultSelect = defaultValue
    ? OPTIONS.find((item) => {
        const finded = item?.options?.find((subItem) => subItem.value === defaultValue);
        if (finded) {
          return { label: finded.label, value: finded.value };
        } else if (item.value == defaultValue) return item.value == defaultValue;
        return undefined;
      })
    : undefined;

  const findedDefaultOptions = findDefaultSelect?.options
    ? findDefaultSelect.options.find((item) => item.value === defaultValue)
    : undefined;
  const [hide, toggleHide] = useToggle(defaultValue && !findDefaultSelect);
  useUpdateEffect(() => {
    if (!loading) {
      toggleHide(false);
    }
  }, [OPTIONS]);

  useUpdateEffect(() => {
    if (query) {
      selectRef.current?.clearValue();
      // setError?.(name, { message: 'Please Select an Option', type: 'required' });
      clearFormState?.();
    }
  }, [query]);

  useEffect(() => {
    // if (defaultValue && !findedDefaultOptions && !findDefaultSelect) {
    //   setError?.(name, { message: 'This option has expired. Please choose another option', type: 'required' });
    //   // handleChange && handleChange('');
    // }
  }, [defaultValue, findDefaultSelect]);
  console.log('-----------------');
  console.log(defaultValue, findedDefaultOptions, findDefaultSelect);
  console.log('-----------------');

  if (hide)
    return (
      <div>
        <Spinner size="sm" />
      </div>
    );
  return (
    <div>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field: { onChange } }) => {
          return (
            <Select
              ref={selectRef}
              isLoading={loading}
              options={OPTIONS}
              defaultValue={findedDefaultOptions || findDefaultSelect}
              isDisabled={isDisabled}
              onChange={(event) => {
                if (event?.value) {
                  clearFormError?.();
                  if (isStringPayload) {
                    onChange(event.value.toString());
                    handleChange && handleChange(event.value.toString());
                  } else {
                    onChange(parseInt(event?.value as any));
                    handleChange && handleChange(parseInt(event?.value as any));
                  }
                }
              }}
            />
          );
        }}
      />
      {defaultValue && !findedDefaultOptions && !findDefaultSelect ? (
        <p className="invalid-feedback">This option has expired. Please choose another option</p>
      ) : (
        ''
      )}
    </div>
  );
}

import SubmitButton from 'components/buttons/submitButton';
import CompanySize from 'components/dropdown/companySizeDropDown';
import ShowError from 'components/showError';
import useMutation from 'hooks/useMutation';
import { newComponentsProps } from 'pages/user-management/employer/index';
import React, { FC, memo, useEffect, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Col, Form, FormFeedback, FormGroup, Label } from 'reactstrap';
import { COMPANY_SIZE_AND_STABLISHMENT } from 'services/graphql/queries/companies/createCompany.graphql';
import { YEARS_DROPDOWN } from 'tools/constants';
import {
  an,
  company,
  option,
  please,
  save,
  select,
  size,
  update,
  updated,
  yearOfEstablish,
} from 'tools/i18n/constants/i18n';
import { errorFinder } from 'tools/methods';
import { numberish, stringish, t_companySize } from 'tools/types';
import { formsNameEnum } from './types';

type CompanySizeEstablishmentProps = newComponentsProps;

type t_defaultValues = {
  customError?: string;
  yearOfStablish: numberish | stringish;
  companySizeId: t_companySize | number | undefined;
};

const CompanySizeEstablishment: FC<CompanySizeEstablishmentProps> = (props) => {
  const { isActive, componentsValues, componentsResponse, handleChangeComponentState, state } = props;
  const { t } = useTranslation();
  const DEFAULT_VALUES: Partial<NonNullable<t_defaultValues>> = {
    yearOfStablish: componentsValues?.yearOfStablish,
    companySizeId: componentsValues?.companySize,
  };
  const [execute, { loading }] = useMutation(COMPANY_SIZE_AND_STABLISHMENT);
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<t_defaultValues>({ defaultValues: DEFAULT_VALUES });
  function findSizeId(companySize: t_companySize | number): number | undefined {
    if (typeof companySize === 'number') return companySize;
    else if ((companySize as t_companySize)?.id) return (companySize as t_companySize).id;
    else return undefined;
  }
  const onSubmit: SubmitHandler<t_defaultValues> = (data) => {
    console.log(data);
    setValue('customError', '');
    if (isActive) {
      execute({
        variables: {
          ...componentsResponse,
          yearOfStablish: parseInt(data?.yearOfStablish as string),
          companySizeId: data.companySizeId ? findSizeId(data.companySizeId) : undefined,
        },
      })
        .then((res) => {
          if (res.data?.mutationDetailsLegalAccount) {
            if (state.isCompleted) {
              toast.success(`${t(updated)}`);
            }
            handleChangeComponentState(formsNameEnum.CompanySizeEstablishment, true);
          } else {
            setValue('customError', errorFinder(res.errors?.[0]?.message));
          }
        })
        .catch((err) => {
          setValue('customError', errorFinder(err));
        });
    }
  };
  useEffect(() => {
    if (componentsValues?.companySize?.id && componentsValues?.yearOfStablish) {
      handleChangeComponentState(formsNameEnum.CompanySizeEstablishment, true);
    }
  }, [componentsValues]);
  const yearsDropDown = useMemo(() => YEARS_DROPDOWN(), []);
  const YEAR_OF_STABLISH__DEFAULT_VALUE = {
    label: DEFAULT_VALUES.yearOfStablish,
    value: DEFAULT_VALUES.yearOfStablish?.toString(),
  };
  const COMPANY_SIZE_DEFAULT_VALUE =
    typeof DEFAULT_VALUES.companySizeId === 'number'
      ? undefined
      : {
          label: (DEFAULT_VALUES.companySizeId as t_companySize)?.title,
          value: (DEFAULT_VALUES.companySizeId as t_companySize)?.id?.toString(),
        };
  return (
    <Col xxl={7} className="p-2 pb-4 mx-auto d-flex flex-column">
      <Form onSubmit={handleSubmit(onSubmit, console.error)} aria-disabled={!isActive}>
        {!!getValues('customError') && (
          <FormGroup>
            <ShowError>{getValues('customError')}</ShowError>
          </FormGroup>
        )}
        <FormGroup>
          <Label> {t(yearOfEstablish)} </Label>
          <Controller
            defaultValue={DEFAULT_VALUES.yearOfStablish}
            rules={{ required: `${t(please)} ${t(select)} ${t(an)} ${t(option)}` }}
            render={({ field: { onChange } }) => {
              return (
                <Select
                  defaultValue={YEAR_OF_STABLISH__DEFAULT_VALUE}
                  options={yearsDropDown}
                  name="yearOfStablish"
                  onChange={(val) => {
                    if (val) {
                      onChange(val.value as string);
                    }
                  }}
                  placeholder={'2002'}
                  isDisabled={!isActive}
                />
              );
            }}
            name="yearOfStablish"
            control={control}
          />
          <FormFeedback>{errors?.yearOfStablish?.message}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label> {`${t(company)} ${t(size)}`} </Label>
          <Controller
            defaultValue={DEFAULT_VALUES.companySizeId}
            rules={{ required: `${t(please)} ${t(select)} ${t(an)} ${t(option)}` }}
            render={({ field: { onChange } }) => {
              return (
                <CompanySize
                  defaultValue={COMPANY_SIZE_DEFAULT_VALUE}
                  className="dropdown w-100"
                  onSelect={(val) => {
                    if (val) {
                      onChange(parseInt(val.value));
                    }
                  }}
                  placeholder={`+50`}
                  isDisabled={!isActive}
                />
              );
            }}
            name="companySizeId"
            control={control}
          />
          <FormFeedback>{errors?.companySizeId?.message}</FormFeedback>
        </FormGroup>

        {isActive && (
          <SubmitButton className="w-100 mt-4" loading={loading}>
            {t(state.isCompleted ? update : save)}
          </SubmitButton>
        )}
      </Form>
    </Col>
  );
};
export default memo(CompanySizeEstablishment);

import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getCookie } from 'tools/methods';

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_GQL}`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getCookie('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const gelClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export const query = gelClient.query;
export const mutation = gelClient.mutate;
export default gelClient;

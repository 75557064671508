import { useQuery } from 'hooks';
import { GET_ALL_NOTIFICATION } from 'services/graphql/queries/notification';
import { useMemo } from 'react';
import { isTrophy } from 'tools/methods';
import { t_notification } from 'pages/notification/types';
import { APP_HELPER } from 'tools/enums';
import { useSearchParams } from 'react-router-dom';

export default function useNotification() {
  const [param] = useSearchParams();
  const defaultPage = param.get('page') ? parseInt(param.get('page') as string) : APP_HELPER.tableDefaultPage;
  const defaultSize = param.get('size') ? parseInt(param.get('size') as string) : APP_HELPER.tableDefaultSize;
  const { data, loading, error, refetch, fetchMore } = useQuery(GET_ALL_NOTIFICATION, {
    defaultOptions: {
      variables: {
        page: defaultPage,
        size: defaultSize,
      },
    },
  });
  const isEmpty = useMemo(() => isTrophy(data?.findAllCustomNotifications?.data), [data]);
  const realData = useMemo(() => (isEmpty ? (data.findAllCustomNotifications?.data as t_notification[]) : []), [data]);
  return { data: realData, loading, error, refetch, fetchMore, count: data?.findAllCustomNotifications?.count || 0 };
}

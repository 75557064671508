import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useQuery, useToggle, useUpdateEffect } from '../../hooks';
import { GET_SLIDER_BY_ID } from '../../services/graphql/queries/sliders';
import { isNew } from '../../tools/methods';
import { slider, sliderLocationEnum, sliderUserTypeEnum } from './slider/types';

export default function useDetailSlider() {
  const { type } = useParams();
  const isNewType = isNew(type);
  const [searchParams] = useSearchParams();
  const [paramLoading, toggleParamLoading] = useToggle(!isNewType);
  const navigate = useNavigate();
  const { data, loading, error, refetch } = useQuery<{ getSliderById: slider }>(GET_SLIDER_BY_ID, {
    skip: isNewType,
    variables: {
      sliderId: !isNewType ? parseInt(type as string) : undefined,
    },
  });
  useUpdateEffect(() => {
    if (data) {
      let userTypeParam = {
        isChanged: false,
        value: data.getSliderById.slot.isEmployerSlider ? sliderUserTypeEnum.EMPLOYER : sliderUserTypeEnum.EMPLOYEE,
      };
      let locationParam = {
        isChanged: false,
        value: data.getSliderById.location.location,
      };
      const userType = data.getSliderById.slot.isEmployerSlider
        ? sliderUserTypeEnum.EMPLOYER
        : sliderUserTypeEnum.EMPLOYEE;
      if (searchParams.get('userType') !== userType) {
        userTypeParam = {
          isChanged: true,
          value: userType as sliderUserTypeEnum,
        };
      }
      if (searchParams.get('location') !== data.getSliderById.location.location) {
        locationParam = {
          isChanged: true,
          value: data.getSliderById.location.location as sliderLocationEnum,
        };
      }
      if (userTypeParam.isChanged || locationParam.isChanged) {
        navigate(
          `/dashboard/contentManagement/slider/${type}?userType=${userTypeParam.value}&location=${locationParam.value}`
        );
      }
      toggleParamLoading(false);
    }
  }, [data]);

  console.log(data);

  return {
    data: data?.getSliderById,
    paramLoading,
    isLoading: loading,
    error,
    isNew: isNewType,
    refetch,
  };
}

import SubmitButton from 'components/buttons/submitButton';
import Icon from 'components/icons';
import ImgToBackGround from 'components/imgToBackground';
import { t_toggleValueFunc, useMutation, useToggle } from 'hooks';
import { magazine } from 'pages/magazine/useMagazine';
import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { UPDATE_MAGAZINE_SORT } from 'services/graphql/queries/magazine';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import sweetalert2 from 'sweetalert2';

interface MagazineSortProps {
  magazines: magazine[];
  toggleSort: t_toggleValueFunc;
}
type changeSortVariables = {
  id: number;
  sortIndex: number;
};

export default function MagazineSort({ magazines, toggleSort }: MagazineSortProps) {
  const [magazinesData, setMagazinesData] = useState(magazines.map((l, i) => ({ ...l, sortIndex: i })));
  const [loading, toggleLoading] = useToggle(false);
  const [changeSort] = useMutation<{ changeMagazinesSort: magazine[] }, { arr: changeSortVariables[] }>(
    UPDATE_MAGAZINE_SORT
  );
  const onSave = async () => {
    sweetalert2
      .fire({
        icon: 'question',
        title: 'Are You Sure to Save the Changes?',
        confirmButtonText: 'Save',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          toggleLoading(true);
          try {
            const arr: changeSortVariables[] = magazinesData.map((item) => {
              return {
                id: item.id,
                sortIndex: item.sortIndex,
              };
            });
            const response = await changeSort({
              variables: {
                arr,
              },
            });
            if (response.data?.changeMagazinesSort) {
              toast.success('Magazines Sort Successfully Changed');
              toggleSort();
            }
            toggleLoading(false);
          } catch (e: any) {
            console.error(e);
            toast.error(e?.message);
            toggleLoading(false);
          }
        }
      });
  };

  const onCancel = () => {
    sweetalert2
      .fire({
        icon: 'warning',
        title: 'Are You Sure to Discard the Changes?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
        cancelButtonText: 'No',
      })
      .then((result) => {
        if (result.isConfirmed) {
          toggleSort();
        }
      });
  };
  const SORTED_MAG = useMemo(() => {
    return magazinesData.sort((a, b) => a.sortIndex - b.sortIndex);
  }, [magazinesData]);
  return (
    <DragDropContext
      onDragEnd={(event) => {
        const { type, destination, source } = event;
        if (!destination) return;
        if (destination?.index === source.index) {
          return;
        }
        if (type === 'group') {
          setMagazinesData((prevState) => {
            const recordedState = prevState;
            const sourceIndex = source.index;
            const destinationIndex = destination.index;
            const [removedItemItem] = recordedState.splice(sourceIndex, 1);
            recordedState.splice(destinationIndex, 0, removedItemItem);
            return recordedState.map((item, index) => ({ ...item, sortIndex: index }));
          });
        }
      }}
    >
      <div className="w-100 h-100 bg-white p-3">
        <header className="py-3 border-bottom mb-3">
          <div className="d-flex align-items-center">
            <h4>Sort Magazine List</h4>
            <Button onClick={onCancel} className="ml-3" color="danger">
              Cancel And Back To List
            </Button>
            <SubmitButton loading={loading} onClick={() => onSave()} className="ml-3 w-auto" color="primary">
              Save Changes
            </SubmitButton>
          </div>
        </header>
        <Droppable droppableId="1" type="group">
          {(provided) => (
            <div className="d-flex flex-column" {...provided.droppableProps} ref={provided.innerRef}>
              {SORTED_MAG.map((mag, index) => {
                return (
                  <Draggable draggableId={`${mag.id}`} index={index} key={mag.id}>
                    {(provided) => (
                      <div
                        className="d-flex items-center border rounded p-2 mb-2"
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                      >
                        <div className="d-flex align-items-center" style={{ cursor: 'grab' }}>
                          <Icon Name="AiOutlineFullscreen" />
                          <b className="ml-2">{index + 1}</b>
                          <div className="table-cell-container d-flex align-items-center text-black py-2">
                            {mag.logo ? (
                              <div className="img-lg rounded-circle border ml-3">
                                <ImgToBackGround src={mag?.logo} />
                              </div>
                            ) : (
                              <Icon size="35px" Name="FiAperture" />
                            )}

                            <span className="ml-2">{`${mag.name} (${mag.edition?.length || 0} Editions)`}</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
            </div>
          )}
        </Droppable>
        <footer className="mt-3">
          <SubmitButton type="button" onClick={() => onSave()} loading={loading}>
            Save Changes
          </SubmitButton>
        </footer>
      </div>
    </DragDropContext>
  );
}

import { Loading } from 'components';
import Icon from 'components/icons';
import DataTable from 'components/tables/dataTable';
import YourListIsEmpty from 'pages/components/emptyList';
import PagesLayout from 'pages/components/layout';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import { New, add, reason, report } from 'tools/i18n/constants/i18n';
import useEmlakAdsReportReasons from './useEmlakAdsReportReasons';
import { TABLES_COLUMNS } from './utils';

export default function EmlakAdsReportReasons() {
  const { t } = useTranslation();
  const { isListEmpty, data, loading } = useEmlakAdsReportReasons();

  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <Loading loading={loading}>
        <PagesLayout newSectionButtonLabel={`${t(New)} ${t(report)} ${t(reason)}`} newSectionLink="new">
          <Card>
            <CardBody className="p-2 d-flex align-items-center justify-content-center flex-grow-1">
              {isListEmpty ? (
                <div className="d-flex flex-column w-100">
                  <Header />
                  <div className="w-100 px-5 py-4">
                    <DataTable data={data.data} paginationTotalRows={data.count} columns={TABLES_COLUMNS} />
                  </div>
                </div>
              ) : (
                <div className="py-3">
                  <YourListIsEmpty
                    addNewButton
                    title="Your List is Empty"
                    addButtonTitle={`${t(add)} ${t(report)} ${t(reason)}`}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </PagesLayout>
      </Loading>
    </Col>
  );
}

const Header = () => {
  const { t } = useTranslation();
  return (
    <CardHeader className="py-2 d-flex justify-content-between align-items-center">
      <Col md={6} className="d-flex">
        <Icon Name="FiAperture" />
        <span className="h6 ml-2">{`${t(report)}`}</span>
      </Col>
      {/*<SearchForm />*/}
    </CardHeader>
  );
};

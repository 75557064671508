import SubmitButton from 'components/buttons/submitButton';
import Icon from 'components/icons';
import IncreaseAble from 'components/increaseAble';
import { EmailInput, PhoneNumberInput, WebsiteInput } from 'components/inputs';
import ShowError from 'components/showError';
import { useToggle } from 'hooks';
import useMutation from 'hooks/useMutation';
import { TFunction } from 'i18next';
import { newComponentsProps } from 'pages/user-management/employer/index';
import React, { FC, memo, useEffect } from 'react';
import { Control, Controller, UseFormSetValue, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Col, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { INSERT_CONTACTS } from 'services/graphql/queries/companies/createCompany.graphql';
import { formsNameEnum } from './types';

import {
  add,
  company,
  dontHaveWebSite,
  email,
  phoneNumber,
  save,
  update,
  updated,
  website,
} from 'tools/i18n/constants/i18n';
import { toNull } from 'tools/methods';
import { stringish } from 'tools/types';

type CompanyContactsProps = newComponentsProps;

type t_defaultValues = {
  customError?: string;
  primaryPhoneCode: string | null;
  primaryPhone: string | null;
  secondaryPhoneCode: string | null;
  secondaryPhone: string | null;
  noWebsite: boolean | null;
  websiteUrl: string | null;
  email: string | null;
};

const CompanyContacts: FC<CompanyContactsProps> = (props) => {
  const { isActive, componentsResponse, handleChangeComponentState, componentsValues, state } = props;
  const { t } = useTranslation();
  const [execute, { loading }] = useMutation(INSERT_CONTACTS);
  const DEFAULT_VALUES: t_defaultValues = {
    noWebsite: !componentsValues?.websiteUrl,
    //@ts-ignore
    primaryPhoneCode: toNull(componentsValues?.primaryPhoneCode) || '+90',
    //@ts-ignore
    primaryPhone: toNull(componentsValues?.primaryPhone),
    //@ts-ignore
    secondaryPhoneCode: toNull(componentsValues?.secondaryPhoneCode),
    //@ts-ignore
    secondaryPhone: toNull(componentsValues?.secondaryPhone),
    //@ts-ignore
    websiteUrl: toNull(componentsValues?.websiteUrl),
    //@ts-ignore
    email: toNull(componentsValues?.email),
  };
  const [noWebsite, toggleNoWebSite] = useToggle(!componentsValues?.websiteUrl);
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm<t_defaultValues>({
    defaultValues: DEFAULT_VALUES,
  });
  const onSubmit = (data: t_defaultValues) => {
    console.log(data);
    if (isActive) {
      setValue('customError', '');
      const updatedValue = {
        ...componentsResponse,
        ...data,
      };
      if (data.secondaryPhone) {
        updatedValue['secondaryPhoneCode'] = '+90';
      } else {
        updatedValue['secondaryPhoneCode'] = null;
      }
      if (data.noWebsite) {
        updatedValue['websiteUrl'] = null;
      }
      execute({
        variables: updatedValue,
      })
        .then((res) => {
          console.log(res);
          if (res.data?.mutationContactsLegalAccount) {
            if (state.isCompleted) {
              toast.success(`${t(updated)}`);
            }
            handleChangeComponentState(formsNameEnum.CompanyContacts, true);
          } else {
            //@ts-ignore
            setValue('customError', res.errors?.message);
          }
        })
        .catch((err) => {
          console.error(err);
          setValue('customError', err.message);
        });
    }
  };
  useEffect(() => {
    reset();
  }, []);
  useEffect(() => {
    if (componentsValues?.primaryPhone && componentsValues?.primaryPhoneCode && componentsValues?.email) {
      handleChangeComponentState(formsNameEnum.CompanyContacts, true);
    }
  }, [componentsValues?.primaryPhone, componentsValues?.primaryPhoneCode, componentsValues?.email]);
  return (
    <Col xxl={7} className="p-2 pb-4 mx-auto d-flex flex-column">
      <Form onSubmit={handleSubmit(onSubmit, console.error)} aria-disabled={!isActive}>
        <FormGroup>{!!getValues('customError') && <ShowError>{getValues('customError')}</ShowError>}</FormGroup>

        <FormGroup className="m-0">
          <IncreaseAble
            maxCount={2}
            defaultValue={DEFAULT_VALUES.secondaryPhone ? 2 : 1}
            rootElement={PhoneNumberInput}
            render={({ increaseElement, index, currentElementsLength }) => {
              return (
                <AddPhoneNumber
                  setValue={setValue}
                  defaultValue={index === 0 ? DEFAULT_VALUES.primaryPhone : DEFAULT_VALUES?.secondaryPhone}
                  defaultCountryCode={index === 0 ? DEFAULT_VALUES.primaryPhoneCode : DEFAULT_VALUES.secondaryPhoneCode}
                  control={control}
                  increaseElement={increaseElement}
                  t={t}
                  renderMore={index + 1 === currentElementsLength}
                  index={index}
                  reachMax={index === 1}
                />
              );
            }}
          />
          <FormFeedback>{errors?.primaryPhone?.message}</FormFeedback>
        </FormGroup>
        <FormGroup className="m-0">
          <Label>{`${t(company)} ${t(website)}`}</Label>
          <WebsiteInput
            control={control}
            name="websiteUrl"
            isRequired={!noWebsite}
            defaultValue={DEFAULT_VALUES.websiteUrl}
            placeholder="https://abc.com"
            disabled={noWebsite || !isActive}
            aria-disabled={noWebsite || !isActive}
          />
          <FormGroup className="mt-2 mb-0">
            <Controller
              control={control}
              name="noWebsite"
              render={({ field: { onChange } }) => {
                return (
                  <Input
                    role="button"
                    defaultChecked={DEFAULT_VALUES.noWebsite as boolean}
                    id="noWebsite"
                    name="noWebsite"
                    type="checkbox"
                    onChange={(e) => {
                      toggleNoWebSite(e.target.checked);
                      onChange(e.target.checked);
                      if (e.target.checked) {
                        setValue('websiteUrl', null);
                      }
                    }}
                    style={{ width: '18px', height: '18px' }}
                  />
                );
              }}
            />
            <Label role="button" className="ml-2" htmlFor="noWebsite">
              {t(dontHaveWebSite)}
            </Label>
          </FormGroup>
          <FormFeedback>{errors?.websiteUrl?.message}</FormFeedback>
        </FormGroup>
        <FormGroup className="m-0">
          <Label>{`${t(email)}`}</Label>
          <EmailInput
            control={control}
            name="email"
            defaultValue={DEFAULT_VALUES.email}
            placeholder="abc@gmail.com"
            disabled={!isActive}
            aria-disabled={!isActive}
            isRequired
          />
          <FormFeedback>{errors?.email?.message}</FormFeedback>
        </FormGroup>

        {isActive && (
          <SubmitButton className="w-100 mt-4" loading={loading}>
            {t(state.isCompleted ? update : save)}
          </SubmitButton>
        )}
      </Form>
    </Col>
  );
};
export default memo(CompanyContacts);

interface AddPhoneNumberProps {
  renderMore: boolean;
  increaseElement: VoidFunction;
  t: TFunction;
  index: number;
  control: Control<t_defaultValues>;
  defaultValue: stringish;
  reachMax: boolean;
  defaultCountryCode: string;
  setValue: UseFormSetValue<t_defaultValues>;
}

const AddPhoneNumber: FC<AddPhoneNumberProps> = (props) => {
  const { renderMore, increaseElement, t, index, control, defaultValue, reachMax, setValue, defaultCountryCode } =
    props;
  return (
    <div className="d-flex flex-column mt-2">
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={index === 0 ? 'primaryPhone' : 'secondaryPhone'}
        render={({ field: { onChange } }) => {
          return (
            <PhoneNumberInput
              onCountryChange={(value) =>
                setValue(index === 0 ? 'primaryPhoneCode' : 'secondaryPhoneCode', value.dial_code)
              }
              defaultValue={
                defaultValue ? { phoneNumber: defaultValue, country: defaultCountryCode || '+90' } : undefined
              }
              onPhoneNumberChange={(val) => onChange(val?.toString())}
              name={index === 0 ? 'primaryPhoneNumber' : 'secondaryPhoneNumber'}
            />
          );
        }}
      />

      {renderMore && !reachMax && (
        <div role="button" onClick={increaseElement} className="make-center mt-2 text-primary underline mr-auto">
          <Icon Name="AiOutlinePlus" />
          <span className="ml-2">{`${t(add)} ${t(phoneNumber)}`}</span>
        </div>
      )}
    </div>
  );
};

import React, { FC, useEffect, useState } from 'react';
import SectionLayout from 'pages/ads/jobAds/components/layout/sectionLayout';
import { workingDays } from 'tools/i18n/constants/i18n';
import { useTranslation } from 'react-i18next';
import { FormProps } from 'pages/ads/jobAds/index';

const initialDays = {
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  sunday: false,
};

type WorkingDaysProps = FormProps;

export default function WorkingDays(props: WorkingDaysProps) {
  const { t } = useTranslation();
  const { onChange } = props;
  const [selected, setSelected] = useState(initialDays);

  const handleSelect = (name: keyof typeof initialDays) => {
    if (selected[name]) {
      setSelected((prev) => {
        return { ...prev, [name]: false };
      });
    } else {
      setSelected((prev) => ({ ...prev, [name]: true }));
    }
  };
  useEffect(() => {
    const keys = Object.keys(selected);
    const isAllValuesAreFalse = keys.some((key) => !!selected[key]);
    if (!isAllValuesAreFalse) {
      onChange?.('workingDays', undefined);
    } else {
      onChange?.('workingDays', selected);
    }
  }, [selected]);
  return (
    <SectionLayout title={t(workingDays)} icon="FaRegCalendar">
      <div className="p-2 d-flex flex-wrap">
        <WeekDay isSelected={selected['monday']} handleSelect={handleSelect} name="monday" title="Mon" />
        <WeekDay isSelected={selected['tuesday']} handleSelect={handleSelect} name="tuesday" title="TUE" />
        <WeekDay isSelected={selected['wednesday']} handleSelect={handleSelect} name="wednesday" title="WED" />
        <WeekDay isSelected={selected['thursday']} handleSelect={handleSelect} name="thursday" title="THU" />
        <WeekDay isSelected={selected['friday']} handleSelect={handleSelect} name="friday" title="FRI" />
        <WeekDay isSelected={selected['saturday']} handleSelect={handleSelect} name="saturday" title="SAT" />
        <WeekDay isSelected={selected['sunday']} handleSelect={handleSelect} name="sunday" title="SUN" />
      </div>
    </SectionLayout>
  );
}

interface WeekDayProps {
  name: keyof typeof initialDays;
  title: string;
  handleSelect: (name: keyof typeof initialDays) => void;
  isSelected: boolean;
}

const WeekDay: FC<WeekDayProps> = ({ title, handleSelect, isSelected, name }) => {
  return (
    <div
      onClick={() => handleSelect(name)}
      className={`rounded p-2 m-1 make-center ${isSelected ? 'shadow-bg-active' : 'border'}`}
      style={{ width: '60px', height: '60px' }}
    >
      <b>{title}</b>
    </div>
  );
};

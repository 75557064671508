import { LatLngBoundsExpression, LatLngExpression } from 'leaflet';
import React from 'react';
import {
  Circle,
  FeatureGroup,
  LayerGroup,
  MapContainer,
  Polygon,
  Polyline,
  Popup,
  Rectangle,
  TileLayer,
} from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';

import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

interface IProps {
  center: LatLngExpression | undefined;
  zoom: number;
  drawingShape: LatLngExpression[] | LatLngExpression[];
  onCreated: (value: LatLngExpression[] | LatLngExpression[]) => void;
  onDeleted: () => void;
}
export default function PolylineMap({ center, zoom, drawingShape, onCreated, onDeleted }: IProps) {
  const purpleOptions = { color: 'purple' };
  return (
    <div className="">
      <MapContainer center={center} zoom={zoom} scrollWheelZoom={false} style={{ height: 400 }}>
        {/* <TileLayer
          url={`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAP_API_KEY}`}
          id="mapbox/streets-v11"
        /> */}
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <FeatureGroup pathOptions={purpleOptions}>
          <Polygon positions={drawingShape} pathOptions={{ color: 'lime' }} />
          <EditControl
            position="topright"
            draw={{
              polygon: true,
              polyline: false,
              circle: false,
              circlemarker: false,
              marker: false,
              rectangle: false,
            }}
            edit={false}
            onCreated={(event) => {
              onCreated(event.layer._latlngs[0]);
            }}
            onEdited={(e) => {
              console.log(e);
            }}
            onDeleted={(e) => {
              onDeleted();
            }}
          />
        </FeatureGroup>
      </MapContainer>
    </div>
  );
}

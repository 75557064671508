import React from 'react';
import Icon from 'components/icons';
import { useTranslation } from 'react-i18next';
import { call, copy, number, requests, total } from 'tools/i18n/constants/i18n';
import { numberish } from 'tools/types';
import { toString } from 'tools/methods';

interface BazaarAdsRequests {
  totalRequests: numberish;
  callNumber: numberish;
  copyNumber: numberish;
}

export default function BazaarAdsRequests(props: BazaarAdsRequests) {
  const { copyNumber, callNumber, totalRequests } = props;
  const { t } = useTranslation();
  return (
    <div className="p-3 bg-white rounded my-2">
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center flex-grow-1">
          <Icon Name="FaSignal" />
          <span className="ml-2">{`${t(total)} ${t(requests)}: ${toString(totalRequests)}`}</span>
        </div>
        <div className="d-flex align-items-center flex-grow-1">
          <Icon Name="FaTicketAlt" />
          <span className="ml-2">{`${t(call)} ${t(number)}: ${toString(callNumber)}`}</span>
        </div>
        <div className="d-flex align-items-center flex-grow-1">
          <Icon Name="FiClipboard" />
          <span className="ml-2">{`${t(copy)} ${t(number)}: ${toString(copyNumber)}`}</span>
        </div>
      </div>
    </div>
  );
}

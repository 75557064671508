import { useQuery } from 'hooks';
import { GET_ALL_EMLAK_TYPE } from 'services/graphql/queries/emlak/types';
import { isTrophy } from 'tools/methods';
import { useMemo } from 'react';
import { d_getTreeData } from 'store/tree/tree.dispatchs';
import { t_Emlaktypes } from 'pages/content-management/emlak/types/type';

export default function useEmlakTypes() {
  const { data, loading, error, fetchMore, refetch } = useQuery<
    {
      realEstateFindAllCategoryTypes: t_Emlaktypes[];
    },
    { query?: string }
  >(GET_ALL_EMLAK_TYPE);
  const realData = useMemo(() => data?.realEstateFindAllCategoryTypes || [], [data?.realEstateFindAllCategoryTypes]);
  const isEmptyList = !isTrophy(realData.length);
  const TreeData = useMemo(() => {
    d_getTreeData(realData);
    return realData;
  }, [data?.realEstateFindAllCategoryTypes]);
  return { data: TreeData, isEmptyList, error, loading, fetchMore, refetch };
}

import { Loading } from 'components';
import Icon from 'components/icons';
import YourListIsEmpty from 'pages/components/emptyList';
import PagesLayout from 'pages/components/layout';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import { Category } from 'tools/i18n/constants';
import { New, add, category, company, emptyList, name, useAddToCategory } from 'tools/i18n/constants/i18n';
import useCompanyCategory from './useCompanyCategories';
import Tree from 'rc-tree';
import { useUpdateEffect } from 'hooks';
import { useDispatch } from 'react-redux';
import { d_selectedNode } from 'store/tree/tree.dispatchs';
import { APP_HELPER } from 'tools/enums';

export default function CompanyCategoriesV2() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isListEmpty, data, loading } = useCompanyCategory();
  const dispatch = useDispatch();
  const [gData, setGData] = useState({
    gData: [],
    autoExpandParent: true,
    expandedKeys: [],
  });

  const onExpand = (expandedKeys: string[]) => {
    setGData((p) => ({ ...p, expandedKeys: expandedKeys, autoExpandParent: false }));
  };

  const onCheck = (param) => {
    console.log(param);
  };

  const handleClick = (id: string[], info: any) => {
    dispatch(d_selectedNode(info.node));

    navigate(id[0], { state: info.node });
  };

  useUpdateEffect(() => {
    setGData((p) => ({ ...p, gData: data }));
  }, [data]);

  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <Loading loading={loading}>
        <PagesLayout newSectionButtonLabel={`${t(New)} ${t(company)} ${t(Category)}`} newSectionLink="new">
          <Card>
            <CardBody className="p-2 d-flex align-items-center justify-content-center flex-grow-1">
              {isListEmpty ? (
                <div className="d-flex flex-column w-100">
                  <Header />
                  <div className="w-100 px-5 py-4">
                    <Tree
                      expandAction="click"
                      onExpand={onExpand}
                      onSelect={handleClick}
                      treeData={gData.gData}
                      icon={({ data }) => {
                        console.log(data);

                        if (data.imageUrl) {
                          return (
                            <img className="mr-2" src={data.imageUrl} width={24} height={24} alt={data.originalName} />
                          );
                        } else {
                          return (
                            <img
                              className="mr-2"
                              src={APP_HELPER.defaultBazaarAndJobIcon}
                              width={20}
                              height={20}
                              alt={data.title}
                            />
                          );
                        }
                      }}
                      autoExpandParent={gData.autoExpandParent}
                      expandedKeys={gData.expandedKeys}
                      onCheck={onCheck}
                      showIcon
                      switcherIcon={({ expanded, data }) => {
                        if (data?.children?.length) {
                          return (
                            <span
                              style={{ width: '15px', height: '15px', marginRight: '10px', marginLeft: '10px' }}
                              className=" d-inline-block d-inline h6"
                            >
                              {expanded ? '-' : '+'}
                            </span>
                          );
                        }
                        return (
                          <span
                            style={{
                              visibility: 'hidden',
                              width: '20px',
                              height: '20px',
                              marginRight: '10px',
                              marginLeft: '10px',
                            }}
                            className="mr-1 d-inline h6 d-inline-block"
                          >
                            {'+'}
                          </span>
                        );
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className="py-3">
                  <YourListIsEmpty
                    addNewButton
                    addButtonTitle={`${t(add)} ${t(category)}`}
                    title={`${t(emptyList)}, ${t(useAddToCategory)}`}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </PagesLayout>
      </Loading>
    </Col>
  );
}
const Header = () => {
  const { t } = useTranslation();
  return (
    <CardHeader className="py-2 d-flex justify-content-between align-items-center">
      <Col md={6} className="d-flex">
        <Icon Name="FiAperture" />
        <span className="h6 ml-2">{`${t(category)} ${t(name)}`}</span>
      </Col>
    </CardHeader>
  );
};

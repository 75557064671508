import { ObjType, t_detailSectionName, t_redux_action } from 'tools/types';
import actions from 'store/tree/tree.actionTypes';
import { TreeData, TreeNodeProps } from 'react-dropdown-tree-select';

type t_initialState = {
  treeData: TreeData;
  selectedNode?: ObjType<unknown> | TreeNodeProps[];
  sectionName?: t_detailSectionName;
};

const INITIAL_STATE: t_initialState = {
  treeData: [],
  selectedNode: undefined,
  sectionName: undefined,
};

export default function treeReducer(state = INITIAL_STATE, action: t_redux_action<keyof typeof actions, any>) {
  switch (action.type) {
    case 'GET_TREE_DATA':
      return { treeData: action.payload, selectedNode: undefined };
    case 'SELECTED_NODE':
      return { ...state, selectedNode: action.payload };
    case 'SECTION_NAME':
      return {
        ...state,
        sectionName: action.payload,
      };
    case 'CLEAR_TREE_DATA':
      return INITIAL_STATE;
    default:
      return state;
  }
}

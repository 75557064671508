import { useGetStore, useMutation } from 'hooks';
import { useParams } from 'react-router-dom';
import { CREATE_UNIVERSITIES, UPDATE_UNIVERSITIES } from 'services/graphql/queries/universities';
import { isNew } from 'tools/methods';

export default function useUniversityDetail() {
  const { type } = useParams();
  const [createExecute] = useMutation(CREATE_UNIVERSITIES);
  const [updateExecute] = useMutation(UPDATE_UNIVERSITIES);
  const selectedNode = useGetStore().Tree.selectedNode;
  return {
    selectedNode,
    isNew: isNew(type),
    createExecute,
    updateExecute,
  };
}

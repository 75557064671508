import React, { FC, memo, useCallback } from 'react';
import SearchInput from 'components/inputs/search';
import { useTranslation } from 'react-i18next';
import { search } from 'tools/i18n/constants/i18n';
import { debounce } from 'tools/methods';

export interface SearchFormProps {
  onChange?: (key: string) => void;
  placeholder?: string;
}

// eslint-disable-next-line react/display-name
export const SearchForm: FC<SearchFormProps> = memo((props) => {
  const { t } = useTranslation();
  const { onChange, placeholder = t(search) } = props;
  const changeHandler = useCallback((e: any, isEyboardEvent = false) => {
    if (isEyboardEvent) {
      if (e.key === 'Enter') {
        onChange?.(e.target?.value);
      }
    } else {
      debounce(() => {
        onChange?.(e.target?.value);
      }, 1000)();
    }
  }, []);

  return (
    <div className="form-search">
      <SearchInput
        onChange={(e) => changeHandler(e)}
        onKeyDown={(e) => changeHandler(e, true)}
        className="form-control bg-transparent border-0"
        placeholder={placeholder}
      />
    </div>
  );
});

export default React.memo(SearchForm);

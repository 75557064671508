import { ApolloError, ObservableQuery, TypedDocumentNode, useQuery as useQ } from '@apollo/client';
import { OperationVariables } from '@apollo/client/core';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { QueryHookOptions, QueryResult } from '@apollo/client/react/types/types';
import { DocumentNode } from 'graphql/language';
import useToggle from 'hooks/useToggle';
import { useEffect, useRef } from 'react';
import { mutation } from 'services/graphql';
import { GET_REFRESH_TOKEN } from 'services/graphql/queries/authentication';
import { ERROR_CODES } from 'tools/constants';
import { getCookie, getGraphError, logout, setCookie } from 'tools/methods';

export type t_useQuery<TResponse = any, TQueryVars extends OperationVariables = OperationVariables> = <
  TData extends TResponse,
  TVariables extends TQueryVars
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
) => QueryResult<TData, TVariables>;

export type t_refetch = (
  variables?: Partial<{ page: number; size: number }> | undefined
) => Promise<ApolloQueryResult<any>>;

export function useQuery<TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
  const lastRequest = useRef({
    query,
    options,
    variables: options?.variables,
  });
  const [fetchFromInitial, toggleFetchFromInitial] = useToggle(true);
  const { fetchMore, loading, data, error, refetch, ...rest } = useQ(query, {
    ...options,
    onError: fetchFromInitial
      ? (e) => {
          toggleRealLoading(true);
          handleError(e, handleFetchAgain);
        }
      : undefined,
    fetchPolicy: 'no-cache',
  });
  const [realLoading, toggleRealLoading] = useToggle(loading);
  async function handleFetchAgain() {
    toggleRealLoading(true);
    await fetchMore({
      query: lastRequest.current.query,
      variables: lastRequest.current.variables,
    });
  }

  const Refetch: (variables?: Partial<TVariables> | undefined) => Promise<ApolloQueryResult<TData>> = (variables) => {
    toggleFetchFromInitial(true);
    return new Promise((resolve, reject) => {
      refetch(variables)
        .then((result) => {
          if (result.error) {
            toggleRealLoading(true);
            handleError(result.error, () => {
              refetch(variables)
                .then((res) => {
                  toggleRealLoading(false);
                  // @ts-ignore
                  resolve(res);
                })
                .catch((err) => {
                  console.log(err);
                  reject(err);
                  toggleRealLoading(false);
                });
            });
          } else {
            // @ts-ignore
            resolve(result);
            toggleRealLoading(false);
          }
        })
        .catch((error) => {
          toggleRealLoading(true);
          handleError(error, () => {
            refetch(variables)
              .then((res) => {
                toggleRealLoading(false);
                // @ts-ignore
                resolve(res);
              })
              .catch((err) => {
                toggleRealLoading(false);
                console.log(err);
                reject(err);
              });
          });
        });
    });
  };

  // @ts-ignore
  const FetchMore: ObservableQuery<TData, TVariables>['fetchMore'] = (
    fetchMoreOptionsOption
  ): Promise<ApolloQueryResult<TData>> | PromiseLike<ApolloQueryResult<TData>> => {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      fetchMore({
        ...fetchMoreOptionsOption,
      })
        .then((result) => {
          if (result.error) {
            handleError(result.error, () => {
              // @ts-ignore
              fetchMore({
                ...fetchMoreOptionsOption,
              })
                .then((res) => {
                  // @ts-ignore
                  resolve(res);
                })
                .catch((err) => {
                  console.log(err);
                  reject(err);
                });
            });
          } else {
            // @ts-ignore
            resolve(result);
          }
        })
        .catch((error) => {
          console.log(error);
          handleError(error, () => {
            fetchMore({
              ...fetchMoreOptionsOption,
            })
              .then((res) => {
                // @ts-ignore
                resolve(res);
              })
              .catch((err) => {
                console.log(err);
                reject(err);
              });
          });
        });
    });
  };

  useEffect(() => {
    if (fetchFromInitial) {
      const status =
        // @ts-ignore
        error?.graphQLErrors[0]?.extensions?.exception?.status ||
        // @ts-ignore
        error?.graphQLErrors[0]?.extensions?.response?.statusCode;
      // @ts-ignore
      if (loading || status === 401 || data?.refreshToken) {
        toggleRealLoading(true);
        // @ts-ignore
      } else if (data && !data?.refreshToken && Object.keys(data)?.length) {
        toggleRealLoading(loading);
        toggleFetchFromInitial(false);
      } else if (error && status !== 401) {
        toggleRealLoading(false);
      }
    }
  }, [error, data]);

  return {
    fetchMore: FetchMore,
    refetch: Refetch,
    loading: realLoading,
    data,
    error: fetchFromInitial ? error : undefined,
    ...rest,
  };
}

export default useQuery;

function handleError(error?: ApolloError, fetchAgain?: VoidFunction) {
  const { status } = getGraphError(error);
  switch (status as keyof typeof ERROR_CODES) {
    case ERROR_CODES['401']:
      {
        getRefreshToken()
          .then((r) => {
            console.log(r);
            setCookie({
              name: 'token',
              value: r?.data?.refreshToken.token,
            });
            fetchAgain?.();
          })
          .catch((e: ApolloError | undefined) => {
            console.log(e);
            logout();
          });
        console.log('401');
      }
      break;
    case ERROR_CODES['403']:
      {
        logout();
      }
      break;
    default:
      fetchAgain?.();
  }
}

function getRefreshToken() {
  return mutation({
    mutation: GET_REFRESH_TOKEN,
    variables: {
      jwtRefreshToken: getCookie('refreshToken'),
    },
  });
}

import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Col, Form, FormFeedback, FormGroup, Label, TabContent } from 'reactstrap';
import { FORGET_PASS_DEFAULT_VALUES } from './utils/constants';
import { t_forgetPassword } from './utils/types';
import { forgetPassword, verificationCode } from 'tools/i18n/constants/i18n';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import ShowError from 'components/showError';
import { PasswordInput, PhoneNumberInput } from 'components/inputs';
import SubmitButton from 'components/buttons/submitButton';
import MultiCodeInput from 'components/inputs/multiCode';
import { useMutation, useToggle } from 'hooks';
import { FORGET_PASSWORD, FORGET_PASSWORD_CHANGE_PASSWORD } from 'services/graphql/queries/forgetPassword';
import Timer from 'components/timer';
import { PASSWORD_REGEXP } from 'tools/constants';
import ReactPasswordChecklist from 'react-password-checklist';
import { toast } from 'react-toastify';

export default function ForgetPassword() {
  const { t } = useTranslation();
  const navigte = useNavigate();
  const [loading, toggleLoading] = useToggle(false);
  const {
    handleSubmit,
    setValue,
    control,
    setError,
    getValues,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<t_forgetPassword>({
    defaultValues: FORGET_PASS_DEFAULT_VALUES,
  });
  const [isOPTSent, toggleOTPSent] = useToggle(false);
  const [getOTPExecute] = useMutation(FORGET_PASSWORD);
  const [updatePasswordExecute] = useMutation(FORGET_PASSWORD_CHANGE_PASSWORD);

  const onSubmit: SubmitHandler<t_forgetPassword> = (data) => {
    setValue('customError', undefined);
    if (!isOPTSent) {
      toggleLoading(true);
      getOTPExecute({
        variables: {
          phoneCode: '+90',
          phoneNumber: data.phoneNumber?.toString(),
        },
      })
        .then((response) => {
          if (response.data) {
            toggleOTPSent(true);
          }
        })
        .catch((err) => {
          const errorText =
            //@ts-ignore
            err.graphQLErrors?.[0]?.extensions?.exception?.response?.devMessage ||
            //@ts-ignore
            err.graphQLErrors?.[0]?.extensions?.response?.message?.[0] ||
            err?.message ||
            'Error Happened';
          setValue('customError', errorText);
        })
        .finally(() => {
          toggleLoading(false);
        });
    } else {
      if (!data.otpCode) {
        setError('otpCode', { message: 'Please Enter OTP Code' });
        return;
      }
      if (!data.password) {
        setError('password', { message: 'Enter Password' });
        return;
      }
      if (!data.confirmPassword) {
        setError('confirmPassword', { message: 'Enter Confirm Password' });
        return;
      }
      if (data.password !== data.confirmPassword) {
        setError('confirmPassword', { message: 'Confirm Password is not same as Password' });
        return;
      }
      clearErrors();
      toggleLoading(true);
      updatePasswordExecute({
        variables: {
          phoneCode: '+90',
          phoneNumber: data.phoneNumber?.toString(),
          code: data.otpCode,
          password: data.password,
        },
      })
        .then((resp) => {
          if (resp.data) {
            toast.success('Password Successfuly Changed');
            navigte('/');
          }
        })
        .catch((err) => {
          const errorText =
            //@ts-ignore
            err.graphQLErrors?.[0]?.extensions?.exception?.response?.devMessage ||
            //@ts-ignore
            err.graphQLErrors?.[0]?.extensions?.response?.message?.[0] ||
            err?.message ||
            'Error Happened';
          setValue('customError', errorText);
        })
        .finally(() => {
          toggleLoading(false);
        });
    }
  };

  const onOTPResend = () => {
    toggleLoading(true);
    getOTPExecute({
      variables: {
        phoneCode: '+90',
        phoneNumber: getValues('phoneNumber')?.toString(),
      },
    })
      .then((response) => {
        if (response.data) {
          toggleOTPSent(true);
        }
      })
      .catch((err) => {
        const errorText =
          //@ts-ignore
          err.graphQLErrors?.[0]?.extensions?.exception?.response?.devMessage ||
          //@ts-ignore
          err.graphQLErrors?.[0]?.extensions?.response?.message?.[0] ||
          err?.message ||
          'Error Happened';
        setValue('customError', errorText);
      })
      .finally(() => {
        toggleLoading(false);
      });
  };

  return (
    <Col xs="12" md={6} className="mx-auto bg-white rounded-lg p-5 mt-3">
      <div className="login-main login-tab">
        <TabContent className={`content-login h-100`}>
          {!!getValues('customError') && (
            <FormGroup>
              <ShowError>{getValues('customError')}</ShowError>
            </FormGroup>
          )}
          <h4>{!getValues('otpCode') ? t(forgetPassword) : t(verificationCode)}</h4>
          <Form
            onSubmit={handleSubmit(onSubmit, console.log)}
            className="d-flex flex-column justify-content-between h-100 theme-form d-block"
          >
            <FormGroup className="mt-2">
              <Controller
                control={control}
                name="phoneNumber"
                rules={{
                  required: 'Enter Phone Number',
                }}
                render={({ field: { onChange } }) => {
                  return <PhoneNumberInput disabled={isOPTSent || loading} onPhoneNumberChange={onChange} />;
                }}
              />
              <FormFeedback>{errors?.phoneNumber?.message}</FormFeedback>
            </FormGroup>
            {isOPTSent && (
              <>
                <FormGroup className="px-1 px-md-3 px-lg-5">
                  <Controller
                    control={control}
                    name="otpCode"
                    render={({ field: { onChange } }) => {
                      return (
                        <>
                          <MultiCodeInput onFinish={(val) => onChange(val)} />
                          <div className="mt-5 mx-auto text-center flex-grow-1">
                            <Timer onRequestAgain={onOTPResend} />
                          </div>
                        </>
                      );
                    }}
                  />
                  <FormFeedback>{errors?.otpCode?.message}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label>New Password</Label>
                  <Controller
                    control={control}
                    rules={{
                      pattern: {
                        value: PASSWORD_REGEXP,
                        message: 'Password should be same as pattern',
                      },
                    }}
                    name="password"
                    render={({ field: { onChange } }) => {
                      return <PasswordInput name="password" onChange={onChange} />;
                    }}
                  />
                  <FormFeedback>{errors?.password?.message}</FormFeedback>
                </FormGroup>
                <FormGroup className="mb-1">
                  <Label>Confirm Password</Label>
                  <Controller
                    control={control}
                    name="confirmPassword"
                    render={({ field: { onChange } }) => {
                      return (
                        <PasswordInput
                          onChange={(event) => {
                            clearErrors('confirmPassword');
                            onChange(event);
                          }}
                          name="confirmPassword"
                        />
                      );
                    }}
                  />
                  <FormFeedback>{errors?.confirmPassword?.message}</FormFeedback>
                </FormGroup>
                <ReactPasswordChecklist
                  rules={['minLength', 'specialChar', 'capital', 'lowercase', 'number']}
                  minLength={8}
                  value={watch('password') || ''}
                  onChange={console.log}
                />
              </>
            )}

            <SubmitButton className="mt-3" loading={loading}>
              {isOPTSent ? 'Change Password' : 'Verify Phone Number'}
            </SubmitButton>
            <Link className="mt-3" to="/">
              Back To Login
            </Link>
          </Form>
        </TabContent>
      </div>
    </Col>
  );
}

import React from 'react';
import { Loading } from 'components';
import useBazaarAdsStatistics from 'pages/ads/bazzarAds/useBazaarAdsStatistics';
import BazaarAdsRequests from 'pages/ads/bazzarAds/components/staticsticsRequests';
import StaticsTicsCharts from 'pages/ads/bazzarAds/components/statisticsChart';
import BazaarAdHistory from 'pages/ads/bazzarAds/bazaarAdsHistory';

export default function BazaarAdsStatistics() {
  const { data, loading, error } = useBazaarAdsStatistics();
  console.log(data);
  const statistics = data?.statistics;
  return (
    <Loading loading={loading}>
      <div className="d-flex flex-column">
        {!error && <StaticsTicsCharts totalViews={statistics?.totalViews} uniqueViews={statistics?.uniqueViews} />}
        <BazaarAdsRequests
          totalRequests={statistics?.askedForNumber}
          callNumber={statistics?.called}
          copyNumber={statistics?.copiedThePhoneNumber}
        />
        <BazaarAdHistory data={data?.statusActivity} />
      </div>
    </Loading>
  );
}

import React, { useState } from 'react';
import { ad, New, secondHand, status } from 'tools/i18n/constants/i18n';
import SectionLayout from 'pages/ads/jobAds/components/layout/sectionLayout';
import { useTranslation } from 'react-i18next';
import { bazaarAdsEnum } from 'tools/enums';
import NewIcon from 'assets/images/buluro/dashboard/New.svg';
import UsedIcon from 'assets/images/buluro/dashboard/Secondhand.svg';
import { FilterAdsCommonProps } from 'pages/ads/bazzarAds/filter/index';
import { Controller } from 'react-hook-form';

type BazaarAdsTypeProps = FilterAdsCommonProps;
export default function BazaarAdsType(props: BazaarAdsTypeProps) {
  const { control } = props;
  const { t } = useTranslation();
  const [currentActiveType, setCurrentActiveType] = useState<bazaarAdsEnum>();
  const changeActiveStatus = (key: bazaarAdsEnum) => {
    setCurrentActiveType(key);
  };
  return (
    <SectionLayout icon="FiActivity" title={`${t(ad)} ${t(status)}`}>
      <div className="d-flex">
        <Controller
          control={control}
          name="type"
          render={({ field: { onChange } }) => {
            return (
              <>
                <div
                  onClick={() => {
                    changeActiveStatus(bazaarAdsEnum.New);
                    onChange(bazaarAdsEnum.New.toLowerCase());
                  }}
                  className={`p-2 m-1 d-flex align-items-center rounded-pill ${
                    currentActiveType === bazaarAdsEnum.New ? 'shadow-bg-active' : 'shadow-bg-notActive'
                  }`}
                >
                  <img alt="new" width="20px" height="20px" src={NewIcon} />
                  <span className="ml-1">{t(New)}</span>
                </div>
                <div
                  onClick={() => {
                    changeActiveStatus(bazaarAdsEnum.used);
                    onChange(bazaarAdsEnum.used.toLowerCase());
                  }}
                  className={`p-2 m-1 d-flex align-items-center rounded-pill ${
                    currentActiveType === bazaarAdsEnum.used ? 'shadow-bg-active' : 'shadow-bg-notActive'
                  }`}
                >
                  <img alt="new" width="20px" height="20px" src={UsedIcon} />
                  <span className="ml-1">{t(secondHand)}</span>
                </div>
              </>
            );
          }}
        />
      </div>
    </SectionLayout>
  );
}

import useQuery from 'hooks/useQuery';
import { add, documentRejectionReasons, New, rejectionReason } from 'tools/i18n/constants/i18n';
import { useTranslation } from 'react-i18next';
import { t_languageKeys } from 'tools/types';
import { GET_ALL_DOCUMENT_REJECTION_REASONS } from 'services/graphql/queries/documentRejectionReasons';
import { GET_ALL_COMPANY_REASONS } from 'services/graphql/queries/companyReasons';

export type t_deleteReasonLanguage = {
  id?: number;
  language: t_languageKeys;
  content: string;
};

export type t_deleteReason = {
  id?: number;
  needToInsertCustomText?: boolean;
  language?: t_deleteReasonLanguage[];
};

export default function useGuideReason() {
  const { data, error, loading, refetch, fetchMore } = useQuery(GET_ALL_COMPANY_REASONS);
  const { t } = useTranslation();
  const realData = data?.getAllGuideRequestRejectReason || [];
  const addButtonText = `${t('Add new reason')}`;
  const tableTitle = t('Rehber Rejection Reasons');

  return { data: realData as t_deleteReason[], error, loading, refetch, fetchMore, addButtonText, tableTitle };
}

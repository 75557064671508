import React, { ReactNode } from 'react';
import Link from 'components/link';
import Icon from 'components/icons';
import NewSectionButton, { dropDownOption } from 'pages/components/newSectionButton';
import { t_dashboardLinkKey } from 'route/appRoutes';
import { goBack } from 'tools/methods';

export interface PagesHeaderProps {
  newSectionButtonLabel?: string;
  newSectionButtonParam?: string;
  backLinkTitle?: string;
  backLink?: t_dashboardLinkKey;
  backLinkParam?: string;
  dropDownOption?: dropDownOption[];
  newSectionLink?: t_dashboardLinkKey;
  newButtonSideText?: string;
  onBackBtn?: VoidFunction;
  customHeader?: ReactNode;
}

export default function PagesHeader(props: PagesHeaderProps) {
  const {
    newSectionButtonLabel,
    backLinkTitle,
    backLink,
    newSectionButtonParam,
    dropDownOption,
    newSectionLink,
    backLinkParam,
    newButtonSideText,
    onBackBtn,
    customHeader,
  } = props;

  const Child = () => (
    <>
      {backLinkTitle && (
        <div className="d-flex text-black" role="button">
          <Icon size="22px" Name="FiArrowLeftCircle" />
          <h5 className="ml-2 whitespace-nowrap">{backLinkTitle}</h5>
        </div>
      )}
    </>
  );

  const BackLink = () =>
    backLink ? (
      <Link to={backLink} param={backLinkParam} className="mb-2 whitespace-nowrap">
        <Child />
      </Link>
    ) : (
      <div
        role="link"
        className="bg-transparent border-0 mb-2 "
        onClick={() => {
          if (onBackBtn) {
            onBackBtn?.();
          } else {
            goBack();
          }
        }}
      >
        <Child />
      </div>
    );
  return (
    <header className="layout-header flex-grow-1 d-inline-block">
      <div className="whitespace-nowrap d-flex flex-column w-auto">
        <BackLink />
        {newSectionButtonLabel && (
          <div className="d-flex align-items-center">
            <NewSectionButton
              param={newSectionButtonParam}
              label={newSectionButtonLabel}
              dropDownOption={dropDownOption}
              link={newSectionLink}
            />
            {!!newButtonSideText && <span className="ml-3">{newButtonSideText}</span>}
            {customHeader}
          </div>
        )}
      </div>
    </header>
  );
}

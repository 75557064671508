import SubmitButton from 'components/buttons/submitButton';
import FormActionLoading, { step } from 'components/formActionLoading';
import ShowError from 'components/showError';
import UploadProfileImage from 'components/uploadProfileImage';
import { useGetStore, useMutation, useToggle } from 'hooks';
import PagesLayout from 'pages/components/layout';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Col, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import FetchApi from 'services/api/axios';
import { CREATE_EMLAK_TYPE, UPDATE_EMLAK_TYPE } from 'services/graphql/queries/emlak/types';
import Styled from 'styled-components';
import { LANGUAGES } from 'tools/constants';
import { Add } from 'tools/i18n/constants';
import {
  option as Option,
  type as Type,
  an,
  created,
  emlakType,
  enter,
  hasChargePrice,
  hasCredit,
  hasDocumentStatus,
  hasEquipped,
  hasPrePaidPrice,
  label,
  please,
  price,
  save,
  select,
  successfully,
  translation,
  unableToUploadIcon,
  update,
  updated,
} from 'tools/i18n/constants/i18n';
import { goBack, isNew } from 'tools/methods';
import { t_defaultValues, t_emlakTypeLanguage } from './type';

const StyledInput = Styled(Input)`
  width : 20px;
  height : 20px;
`;
type stepInfo = {
  index: number;
  pending: boolean;
  completed: boolean;
  error: boolean;
  fileName?: string;
  percent: number;
};

enum PriceType {
  hasPrePaidPrice = 'hasPrePaidPrice',
  hasChargePrice = 'hasChargePrice',
}
const source: Record<PriceType, PriceType> = {
  [PriceType.hasChargePrice]: PriceType.hasChargePrice,
  [PriceType.hasPrePaidPrice]: PriceType.hasPrePaidPrice,
};

export default function EmlakTypeDetail() {
  const { type } = useParams();
  const isNewType = isNew(type);
  const formRef = useRef<any>();
  const selectedNode = useGetStore().Tree.selectedNode;
  // const treeData = useGetStore().Tree.treeData;
  const [createTypeExecute] = useMutation<any, Omit<t_defaultValues, 'icon'>>(CREATE_EMLAK_TYPE);
  const [updateTypeExecute] = useMutation<any, Omit<t_defaultValues, 'icon'>>(UPDATE_EMLAK_TYPE);
  const [loading, toggleLoading] = useToggle(false);
  const [openUI, toggleUI] = useToggle(false);
  const [stepsInfo, setStepsInfo] = useState<stepInfo[]>([
    {
      index: 1,
      error: false,
      percent: 0,
      pending: false,
      completed: false,
    },
    {
      index: 2,
      error: false,
      fileName: 'Cover',
      percent: 0,
      pending: false,
      completed: false,
    },
    {
      index: 3,
      error: false,
      fileName: 'PDF',
      percent: 0,
      pending: false,
      completed: false,
    },
  ]);
  const DEFAULT_VALUES: t_defaultValues = {
    categoryTypeId: selectedNode?.id,
    icon: selectedNode?.icon,
    language: selectedNode?.categoryTypeLanguage
      ?.sort((a: t_emlakTypeLanguage) => (a.language === 'tr' ? -1 : 1))
      .map?.((item: t_emlakTypeLanguage) => ({ language: item.language, translation: item.translation })),
    hasLoan: selectedNode?.hasLoan,
    hasPrice: selectedNode?.hasPrice,
    hasChargePrice: selectedNode?.hasChargePrice,
    hasPrePaidPrice: selectedNode?.hasPrePaidPrice,
    hasDocumentStatus: selectedNode?.hasDocumentStatus,
    hasEquipped: selectedNode?.hasEquipped,
  };
  const handleChangeStepInfo = useCallback((step: 1 | 2, changeKey: Partial<Record<keyof stepInfo, any>>) => {
    setStepsInfo((prev) => {
      return prev.map((info) => {
        if (info.index !== step) return info;
        return {
          ...info,
          ...changeKey,
        };
      });
    });
  }, []);
  const steps: step[] = [
    {
      index: 1,
      status: stepsInfo[0].completed
        ? 'COMPLETED'
        : stepsInfo[0].pending
        ? 'UPLOADING'
        : stepsInfo[0].error
        ? 'FAILED'
        : 'WAITING',
      title: 'Uploading Form Inputs',
      fileName: Object.keys(DEFAULT_VALUES).join(', '),
      onRetry: () => formRef.current?.props.onSubmit(),
    },
    {
      index: 2,
      title: 'Uploading Icon',
      status: stepsInfo[1].completed
        ? 'COMPLETED'
        : stepsInfo[1].pending
        ? 'UPLOADING'
        : stepsInfo[1].error
        ? 'FAILED'
        : 'WAITING',
      fileName: 'icon',
      onRetry: () => formRef.current?.props.onSubmit(),
      progressConfig: {
        percent: stepsInfo[1].percent,
      },
    },
  ];

  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<t_defaultValues>({
    defaultValues: DEFAULT_VALUES,
  });
  const { t } = useTranslation();
  const onSubmit: SubmitHandler<t_defaultValues> = (data) => {
    console.log(data);
    if (!data.hasPrePaidPrice && !data.hasChargePrice) {
      setError('hasPrePaidPrice', { message: `${t(please)} ${t(select)} ${t(an)} ${t(Option)}` });
      return;
    }
    toggleLoading(true);
    if (data.icon && data.icon !== DEFAULT_VALUES?.icon) {
      toggleUI(true);
      handleChangeStepInfo(1, { error: false, completed: false, pending: true });
      handleChangeStepInfo(2, { completed: false, error: false, pending: false });
    }
    data.language = data.language.map((item) => ({ language: item.language, translate: item.translation }));
    createTypeExecute({
      variables: data,
    })
      .then((res) => {
        console.log(res);
        if (res?.data?.realEstateCreateCategoryType) {
          handleChangeStepInfo(1, { pending: false, completed: true });
          if (data.icon && data.icon !== DEFAULT_VALUES?.icon) {
            handleChangeStepInfo(2, { error: false, completed: false, pending: true });
            const formData = new FormData();
            formData.append('file', data.icon);
            formData.append('id', res.data.realEstateCreateCategoryType.id);
            FetchApi({
              url: 'EMLAK_TYPE_ICON',
              method: 'PATCH',
              data: formData,
              onUploadProgress: (progressEvent) => {
                handleChangeStepInfo(2, { percent: Math.round((progressEvent.loaded * 100) / progressEvent.total) });
              },
            })
              .then((response) => {
                if (response.data) {
                  handleChangeStepInfo(2, { error: false, completed: true, pending: false });
                  console.log(response);
                  toast.success(`${t(emlakType)} ${t(successfully)} ${t(created)}`);
                  toggleLoading(false);
                  goBack();
                }
              })
              .catch((err) => {
                handleChangeStepInfo(2, { error: true, pending: false, completed: false });
                console.error(err);
                toggleLoading(false);
                setValue('customError', t(unableToUploadIcon));
              });
          } else {
            toast.success(`${t(emlakType)} ${t(successfully)} ${t(created)}`);
            toggleLoading(false);
            goBack();
          }
        }
      })
      .catch((err) => {
        handleChangeStepInfo(1, { error: true, pending: false, completed: false });
        toggleLoading(false);
        console.error(err);
        setValue('customError', err.message);
      });
  };

  const onUpdate: SubmitHandler<t_defaultValues> = (data) => {
    console.log(data);
    if (!data.hasPrePaidPrice && !data.hasChargePrice) {
      setError('hasPrePaidPrice', { message: `${t(please)} ${t(select)} ${t(an)} ${t(Option)}` });
      return;
    }
    toggleLoading(true);
    if (data.icon && data.icon !== DEFAULT_VALUES.icon) {
      toggleUI(true);
      handleChangeStepInfo(1, { completed: false, error: false, pending: true });
      handleChangeStepInfo(2, { completed: false, error: false, pending: false });
    }
    data.language = data.language.map((item) => ({ language: item.language, translate: item.translation }));
    updateTypeExecute({
      variables: data,
    })
      .then((res) => {
        console.log(res);
        if (res?.data?.realEstateUpdateCategoryType) {
          handleChangeStepInfo(1, { completed: true, pending: false });
          if (data.icon !== DEFAULT_VALUES?.icon) {
            handleChangeStepInfo(2, { completed: false, error: false, pending: true });

            const formData = new FormData();
            formData.append('file', data.icon as string);
            formData.append('id', res.data.realEstateUpdateCategoryType.id);
            FetchApi({
              url: 'EMLAK_TYPE_ICON',
              method: 'PATCH',
              data: formData,
              onUploadProgress: (progressEvent) => {
                handleChangeStepInfo(2, { percent: Math.round((progressEvent.loaded * 100) / progressEvent.total) });
              },
            })
              .then((response) => {
                console.log(response);
                if (response.data) {
                  handleChangeStepInfo(2, { completed: true, error: false, pending: false });

                  toast.success(`${t(emlakType)} ${t(successfully)} ${t(updated)}`);
                  toggleLoading(false);
                  goBack();
                } else {
                  handleChangeStepInfo(2, { error: true, pending: false, completed: false });
                  setValue('customError', response?.data?.message);
                  toggleLoading(false);
                }
              })
              .catch((err) => {
                handleChangeStepInfo(2, { error: true, pending: false, completed: false });
                console.error(err);
                setValue('customError', t(unableToUploadIcon));
                toggleLoading(false);
              });
          } else {
            toast.success(`${t(emlakType)} ${t(successfully)} ${t(updated)}`);
            toggleLoading(false);
            goBack();
          }
        }
      })
      .catch((err) => {
        handleChangeStepInfo(1, { error: true, pending: false, completed: false });
        toggleLoading(false);
        console.error(err);
        setValue('customError', err.message);
      });
  };

  const priceTypeOption = useMemo(() => {
    return [
      {
        value: PriceType.hasChargePrice,
        label: t(hasChargePrice),
      },
      {
        value: PriceType.hasPrePaidPrice,
        label: t(hasPrePaidPrice),
      },
    ];
  }, []);

  const priceTypeDefaultValue = useMemo(() => {
    const defaultArray = [];
    if (DEFAULT_VALUES.hasChargePrice) {
      //@ts-ignore
      defaultArray.push({ label: t(hasChargePrice), value: PriceType.hasChargePrice });
    }
    if (DEFAULT_VALUES.hasPrePaidPrice) {
      //@ts-ignore
      defaultArray.push({ label: t(hasPrePaidPrice), value: PriceType.hasPrePaidPrice });
    }

    return defaultArray;
  }, [DEFAULT_VALUES]);

  const handleSelectPriceType = (prices: PriceType[]) => {
    clearErrors('hasChargePrice');
    clearErrors('hasPrePaidPrice');
    const keys = Object.keys(source) as PriceType[];
    keys.forEach((item) => {
      if (prices.includes(item)) {
        setValue(item, true);
      } else {
        setValue(item, undefined);
      }
    });
  };

  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <PagesLayout backLink="emlakTypes" backLinkTitle={t(emlakType)} backLinkParam="/">
        <FormActionLoading onClose={toggleUI} open={openUI} steps={steps} />
        <Card>
          <CardHeader>
            <h4>{`${t(isNewType ? Add : update)} ${t(Type)}`}</h4>
          </CardHeader>
          <CardBody>
            {!!getValues('customError') && (
              <FormGroup>
                <ShowError>{getValues('customError')}</ShowError>
              </FormGroup>
            )}
            <Form ref={formRef} onSubmit={handleSubmit(isNewType ? onSubmit : onUpdate, console.error)}>
              <div className="bg-gray align-items-center justify-content-between">
                <FormGroup className="flex-grow-1 bg-gray px-5 py-3 rounded-md">
                  <Controller
                    control={control}
                    defaultValue={DEFAULT_VALUES.icon}
                    name="icon"
                    render={({ field: { onChange } }) => {
                      return (
                        <UploadProfileImage
                          defaultImage={DEFAULT_VALUES.icon as string}
                          onSelect={(file) => {
                            onChange(file);
                            clearErrors('icon');
                          }}
                          avatarSize="70px"
                          maxSize={500000}
                          onError={(message) => setError('icon', { message })}
                        />
                      );
                    }}
                  />
                  <FormFeedback>{errors?.icon?.message}</FormFeedback>
                </FormGroup>
              </div>
              <div className="d-flex">
                <div className="bg-gray flex-grow-1">
                  <FormGroup className="flex-grow-1 bg-gray px-3 py-2 rounded-md">
                    <Controller
                      control={control}
                      defaultValue={LANGUAGES[0].value}
                      name="language.0.language"
                      render={() => (
                        <Select value={{ label: LANGUAGES[0].label, value: LANGUAGES[0].value }} isDisabled />
                      )}
                    />
                    <FormGroup className="mb-0 mt-4">
                      <Label>{`${t(emlakType)} ${t(label)} *`}</Label>
                      <Controller
                        rules={{
                          required: `${t(please)} ${t(enter)} ${t(translation)}`,
                        }}
                        defaultValue={DEFAULT_VALUES.language?.[0]?.translation}
                        control={control}
                        name="language.0.translation"
                        render={({ field: { onChange } }) => {
                          return (
                            <input
                              onChange={onChange}
                              defaultValue={DEFAULT_VALUES.language?.[0]?.translation}
                              className="form-control"
                              type="text"
                              placeholder={`${t(emlakType)} ${t(label)}`}
                            />
                          );
                        }}
                      />
                      <FormFeedback>{errors?.language?.[0]?.translation?.message}</FormFeedback>
                    </FormGroup>
                  </FormGroup>
                </div>

                <div className="bg-gray flex-grow-1">
                  <FormGroup className="flex-grow-1 bg-gray px-3 py-2 rounded-md">
                    <Controller
                      control={control}
                      defaultValue={LANGUAGES[1].value}
                      name="language.1.language"
                      render={() => (
                        <Select value={{ label: LANGUAGES[1].label, value: LANGUAGES[1].value }} isDisabled />
                      )}
                    />
                    <FormGroup className="mb-0 mt-4">
                      <Label>{`${t(emlakType)} ${t(label)} *`}</Label>
                      <Controller
                        rules={{
                          required: `${t(please)} ${t(enter)} ${t(translation)}`,
                        }}
                        control={control}
                        name="language.1.translation"
                        defaultValue={DEFAULT_VALUES?.language?.[1]?.translation}
                        render={({ field: { onChange } }) => {
                          return (
                            <input
                              onChange={onChange}
                              className="form-control"
                              defaultValue={DEFAULT_VALUES?.language?.[1]?.translation}
                              type="text"
                              placeholder={`${t(emlakType)} ${t(label)}`}
                            />
                          );
                        }}
                      />
                      <FormFeedback>{errors?.language?.[1]?.translation?.message}</FormFeedback>
                    </FormGroup>
                  </FormGroup>
                </div>
              </div>

              <div className="bg-gray mt-2 px-3 py-2">
                <FormGroup>
                  <Label>{`${t(price)} ${t(Type)}`}</Label>
                  <Select
                    //@ts-ignore
                    options={priceTypeOption}
                    isMulti
                    defaultValue={priceTypeDefaultValue}
                    //@ts-ignore
                    onChange={(values) => handleSelectPriceType(values.map((i) => i.value))}
                  />
                  {!!errors?.hasPrePaidPrice?.message && (
                    <FormFeedback>{errors?.hasPrePaidPrice?.message}</FormFeedback>
                  )}
                </FormGroup>
              </div>
              <FormGroup className="my-4">
                <Controller
                  control={control}
                  defaultValue={DEFAULT_VALUES?.hasLoan}
                  name="hasLoan"
                  render={({ field: { onChange } }) => {
                    return (
                      <div className="d-flex align-items-center">
                        <StyledInput
                          id="credit"
                          onChange={onChange}
                          defaultChecked={DEFAULT_VALUES?.hasLoan}
                          type="checkbox"
                          name="hasLoan"
                        />
                        <Label htmlFor="credit" className="mb-0 ml-2">
                          {t(hasCredit)}
                        </Label>
                      </div>
                    );
                  }}
                />
              </FormGroup>

              <FormGroup className="my-4">
                <Controller
                  control={control}
                  defaultValue={DEFAULT_VALUES?.hasDocumentStatus}
                  name="hasDocumentStatus"
                  render={({ field: { onChange } }) => {
                    return (
                      <div className="d-flex align-items-center">
                        <StyledInput
                          onChange={onChange}
                          defaultChecked={DEFAULT_VALUES?.hasDocumentStatus}
                          type="checkbox"
                          name="hasEquipped"
                        />
                        <Label className="mb-0 ml-2">{t(hasDocumentStatus)}</Label>
                      </div>
                    );
                  }}
                />
              </FormGroup>

              <FormGroup className="my-4">
                <Controller
                  control={control}
                  defaultValue={DEFAULT_VALUES?.hasEquipped}
                  name="hasEquipped"
                  render={({ field: { onChange } }) => {
                    return (
                      <div className="d-flex align-items-center">
                        <StyledInput
                          onChange={onChange}
                          defaultChecked={DEFAULT_VALUES?.hasEquipped}
                          type="checkbox"
                          name="hasEquipped"
                        />
                        <Label className="mb-0 ml-2">{t(hasEquipped)}</Label>
                      </div>
                    );
                  }}
                />
              </FormGroup>

              <FormGroup className="w-100">
                <SubmitButton loading={loading}>{`${t(isNewType ? save : update)}`}</SubmitButton>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      </PagesLayout>
    </Col>
  );
}

import useQuery from 'hooks/useQuery';
import { GET_FUAR_LIST } from 'services/graphql/queries/fuar';

export type exhibitionStatus = 'EXPIRED' | 'ACTIVE';

export type fuar = {
  id: number;
  title: string;
  logo: string;
  poster: string;
  webSite: string;
  softDelete: boolean;
  city: {
    id: number;
    name: string;
  }[];
  country: {
    id: number;
    name: string;
  }[];
  startDate: string;
  status: exhibitionStatus;
  endDate: string;
  createdAt: string;
};

export default function useFuar() {
  const { data, loading, error } = useQuery(GET_FUAR_LIST);
  return { data: data?.exhibitionList || [], loading, error };
}

import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Form, Progress, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import EditIcon from 'assets/images/buluro/dashboard/edit.svg';
import SectionLayout from 'pages/ads/jobAds/components/layout/sectionLayout';
import { ICommonProps } from 'pages/notification/types';
import { t_toggleValueFunc, useMutation, useToggle } from 'hooks';
import { UPDATE_NOTIFICATION } from 'services/graphql/queries/notification';
import { toast } from 'react-toastify';
import { goBack } from 'tools/methods';
import Styled from 'styled-components';
import Modal from 'components/modal';
import sweetalert2 from 'sweetalert2';
import ImgToBackGround from 'components/imgToBackground';
import { LANGUAGES } from 'tools/constants';
import ShowError from 'components/showError';
import { ALL_ACTIONS } from '../entities';

const BannerContainer = Styled.div`
  height : 500px;
  width : 100%;
`;

const TIMEOUT = 10;
export type ReviewSendProps<N extends boolean> = ICommonProps<N>;

export function ReviewAndSend<N extends boolean>(props: ReviewSendProps<N>): JSX.Element {
  const { savedData, goNext, previewMode } = props;
  const { t } = useTranslation();
  const [isSilent, toggleIsSilent] = useToggle(false);
  const [execute, { loading }] = useMutation(UPDATE_NOTIFICATION);
  const [openModal, toggleModal] = useToggle(false);
  const handleConfirmAction = () => {
    sweetalert2
      .fire({
        icon: 'question',
        cancelButtonText: 'İptal',
        confirmButtonText: 'Evet',
        showCancelButton: true,
        showCloseButton: true,
        text: `Bu Bildirimi Göndermek İstediğinizden Emin misiniz?`,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          sweetalert2.close();
          toggleModal(true);
        }
      });
  };
  const onSubmit = async (e?: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    const response = await execute({
      variables: {
        id: savedData?.id,
        isDraft: false,
        isSilent,
      },
    });
    if (response?.data?.updateCustomNotification) {
      toast.success(`Bildirim Gönderildi`);
      goBack();
    } else {
      // @ts-ignore
      toast.error(response?.errors?.[0]?.exception?.response?.devMessage || 'Error Happened');
    }
  };
  const filters = savedData.filters
    ? Object.keys(savedData.filters)
        .filter((k) => typeof savedData?.filters?.[k] === 'boolean' && k !== 'sendToAll')
        .map((k) => {
          if (savedData?.filters?.[k]) return k.replace(/([A-Z])/g, ' $1');
          return k
            .replace('is', 'is Not')
            .replace('has', 'has Not')
            .replace(/([A-Z])/g, ' $1');
        })
    : [];
  useMemo(() => {
    if (!savedData.filters?.userRoles?.all) {
      if (!savedData.filters?.userRoles?.employee || !savedData.filters?.userRoles?.employer) {
        if (savedData.filters?.userRoles?.employee) filters.unshift('Employees');
        else if (savedData.filters?.userRoles?.employer) filters.unshift('Employers');
      }
    }
    return true;
  }, [savedData, filters]);
  if (
    savedData?.filters?.userRoles?.all ||
    (savedData.filters?.userRoles?.employee && savedData.filters?.userRoles?.employer)
  ) {
    filters.unshift('All');
  }
  return (
    <div>
      <Counter onFinishCounter={onSubmit} isOpen={openModal} toggleOpen={toggleModal} />

      <Form onSubmit={onSubmit}>
        <div className="bg-gray rounded-md p-4">
          <div className="d-flex flex-column">
            <h6 className="mb-3">{`Bildirimi İncele`}</h6>
            {!!savedData?.failError && (
              <div className="my-3">
                <ShowError>{savedData?.failError}</ShowError>
              </div>
            )}
            {previewMode && (
              <div className="d-flex align-items-center bg-white justify-content-between p-3 mb-3 rounded-md">
                <span className="h6">{`Kullanıcı Ad ve Soyadı`}</span>
                <div className="d-flex align-items-center">
                  <span className="h6">{savedData.receiverCount || 0}</span>
                </div>
              </div>
            )}

            <div className="d-flex bg-white p-3">
              <div className="flex-grow-1 d-flex align-items-center justify-content-between">
                {savedData?.payload.contents?.map((item) => {
                  return (
                    <div
                      key={item.title}
                      className="col-6 h-100 justify-content-start flex-grow-1 d-flex flex-column m-2"
                    >
                      <h5 className="mb-3">{LANGUAGES.find((lang) => lang.language === item.language)?.label}</h5>
                      <h6>{item.title}</h6>
                      <p>{item.body}</p>
                    </div>
                  );
                })}
              </div>
              {!previewMode && (
                <span role="button" className="ml-2" onClick={() => goNext(0)}>
                  <img alt="edit" src={EditIcon} />
                </span>
              )}
            </div>

            {!!savedData.payload.bannerUrl && (
              <BannerContainer className="mt-3">
                <ImgToBackGround src={savedData.payload.bannerUrl} />
              </BannerContainer>
            )}

            {savedData.payload.url && (
              <div className="d-flex mt-2 align-items-center bg-white justify-content-between p-3 rounded-md">
                <span>URL</span>

                {!previewMode && (
                  <div>
                    <span>{savedData.payload.url}</span>
                    <span role="button" className="ml-2" onClick={() => goNext(2)}>
                      <img alt="edit" src={EditIcon} />
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {savedData.actionType && (
          <div className="d-flex mt-3 bg-gray align-items-center justify-content-between py-3 px-4 rounded-md">
            <span>Aksiyon</span>
            <div className="d-flex align-items-center">
              <span>{ALL_ACTIONS.find((action) => action.value === savedData?.actionType)?.label}</span>
              {!previewMode && (
                <span role="button" className="ml-2 mr-3" onClick={() => goNext(2)}>
                  <img alt="edit" src={EditIcon} />
                </span>
              )}
            </div>
          </div>
        )}

        <div className="p-4 mt-3 bg-gray rounded-md">
          <div className="mt-2">
            <div className="p-3 bg-white">
              <SectionLayout title={`Kullanıcı tipi`} icon="FiType">
                {!(savedData?.filters?.sendToAll || savedData?.filters?.sendToAll) &&
                  (savedData?.filters?.userType || savedData?.filters?.userType) && (
                    <div className="d-flex align-items-center justify-content-between">
                      <Row>
                        <div className="ml-5 w-auto p-2 rounded-lg shadow-bg-active">
                          {savedData?.filters?.userType === 'APP_USER' ? 'Uygulama Kullanıcıları' : 'Giriş Yapmayanlar'}
                        </div>
                      </Row>
                      {!previewMode && (
                        <span role="button" className="mb-2 ml-auto" onClick={() => goNext(1)}>
                          <img alt="edit" src={EditIcon} />
                        </span>
                      )}
                    </div>
                  )}
              </SectionLayout>
              <SectionLayout title={`Kullanıcı Rolü`} icon="FiLayout">
                <div className="d-flex align-items-center justify-content-between">
                  <Row>
                    {/* {filters?.map((role) => {
                      return (
                        <div className="ml-5 mb-2 w-auto px-3 py-2 rounded-lg shadow-bg-active" key={role}>
                          {toUpperCase(role.replaceAll('_', ' '))}
                        </div>
                      );
                    })} */}
                    {filters?.map((role) => {
                      return (
                        <div className="ml-5 mb-2 w-auto px-3 py-2 rounded-lg shadow-bg-active" key={role}>
                          {role === 'Employees' ? 'Bireysel' : role === 'Employers' ? 'Kurumsal' : 'Tüm'}
                        </div>
                      );
                    })}
                  </Row>
                  {!previewMode && (
                    <span role="button" className="mb-2 ml-auto" onClick={() => goNext(1)}>
                      <img alt="edit" src={EditIcon} />
                    </span>
                  )}
                </div>
              </SectionLayout>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center px-5 mt-3">
          <Button
            disabled={loading || previewMode}
            type="button"
            onClick={() => {
              handleConfirmAction();
              toggleIsSilent(true);
            }}
            className="border-primary mr-2 flex-grow-1"
            color=""
          >
            {`Bildirimi Sessiz Olarak Gönder`}
          </Button>
          <Button
            disabled={loading || previewMode}
            onClick={() => {
              handleConfirmAction();
              toggleIsSilent(false);
            }}
            type="button"
            color="primary"
            className="flex-grow-1"
          >{`Bildirimi Gönder`}</Button>
        </div>
      </Form>
    </div>
  );
}
export default ReviewAndSend;

interface CounterProps {
  isOpen: boolean;
  toggleOpen: t_toggleValueFunc;
  onFinishCounter(): void;
}

// eslint-disable-next-line react/display-name
const Counter: FC<CounterProps> = memo((props) => {
  const { isOpen, toggleOpen, onFinishCounter } = props;
  const [counter, setCounter] = useState({
    value: TIMEOUT,
    displayValue: '30',
  });

  useEffect(() => {
    let interval: NodeJS.Timer | undefined = undefined;
    if (isOpen) {
      interval = setInterval(() => {
        setCounter((prev) => {
          if (prev.value === 0) {
            onFinishCounter();
            clearInterval(interval);
            toggleOpen();
            return {
              value: TIMEOUT,
              displayValue: '00',
            };
          }
          return {
            value: prev.value - 1,
            displayValue: `${prev.value < 10 ? `0${prev.value - 1}` : prev.value - 1}`,
          };
        });
      }, 1000);
    } else {
      setCounter({
        value: TIMEOUT,
        displayValue: '10',
      });
      clearInterval(interval);
    }

    return () => {
      setCounter({
        value: TIMEOUT,
        displayValue: '10',
      });
      clearInterval(interval);
    };
  }, [isOpen]);

  return (
    <Modal unmountOnClose isOpen={isOpen} toggleOpen={toggleOpen} title={`Bildirimi Gönder`}>
      <Card className="m-0 p-0">
        <CardHeader>
          <div className="d-flex align-items-center flex-column">
            <div className="flex-column d-flex justify-content-center">
              <Progress
                className="mx-auto"
                value={counter.value}
                max={TIMEOUT}
                style={{ width: '150px', height: '10px' }}
                striped
              />
            </div>
            <div className="d-flex mt-3">
              <h6>{`Bildirim Gönderilecek: `}</h6>
              <b className="mx-auto ml-1">{counter.displayValue}</b>
            </div>
          </div>
        </CardHeader>
        <CardBody className="mt-3 mx-auto w-100 p-0">
          <Button type="button" color="danger" className="mx-auto d-block" onClick={() => toggleOpen()}>
            {`Bildirim Göndermeyi İptal Et`}
          </Button>
        </CardBody>
      </Card>
    </Modal>
  );
});

import { dataToTree } from 'components/tree-select-checkbox/utils';
import { useUpdateEffect } from 'hooks';
import useQuery from 'hooks/useQuery';
import { useMemo } from 'react';
import { GET_SOFTWARES } from 'services/graphql/queries/softwares';
import { d_getTreeData } from 'store/tree/tree.dispatchs';
import { APP_HELPER } from 'tools/enums';
import { isTrophy, toArray } from 'tools/methods';
import { t_software } from './types';

export default function useSoftware() {
  const { data, loading, error } = useQuery<{ employmentSoftwaresList: t_software[] }>(GET_SOFTWARES, {
    variables: {
      page: 1,
      size: APP_HELPER.treeMaxSize,
    },
  });
  const isListEmpty: boolean = useMemo(() => isTrophy(data?.employmentSoftwaresList?.length), [data]);
  const updatedData = useMemo(
    () =>
      isListEmpty
        ? dataToTree(toArray(data?.employmentSoftwaresList), {
            id: '_id',
            labelKey: 'name',
            iconKey: 'imageUrl',
            childKey: 'sections',
          })
        : [],
    [data, isListEmpty]
  );

  useUpdateEffect(() => {
    if (isTrophy(updatedData)) {
      d_getTreeData(updatedData);
    }
  }, [updatedData]);
  return { isListEmpty, data: updatedData, error, loading };
}

import React from 'react';
import SectionLayout from 'pages/ads/jobAds/components/layout/sectionLayout';
import { FILTERS } from 'pages/notification/entities';
import { toUpperCase } from 'tools/methods';
import DatePicker from 'react-datepicker';
import { E_SENT_DATE, NotificationFiltersProps, NotificationStatus } from 'pages/notification/types';
import { useTranslation } from 'react-i18next';
import Styled from 'styled-components';
import { Button, Form, Input } from 'reactstrap';
import { results, show } from 'tools/i18n/constants/i18n';

const StatusBlock = Styled.div`
  width : 75px;
  height : 65px;
  text-align : center;
`;

function NotificationFilters<T extends NotificationFiltersProps>(props: T): JSX.Element {
  const { t } = useTranslation();
  const { disabled, selectedDates, onStatusChange, onDateChange, currentActiveStatus, onSubmit } = props;
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
      className="p-3 d-flex flex-column"
    >
      <SectionLayout title={t(FILTERS.STATUS.title)} icon={FILTERS.STATUS.icon}>
        <div className="d-flex flex-wrap">
          {FILTERS.STATUS.options.map((option, index) => (
            <StatusBlock key={index} className="m-2">
              <div
                className={`rounded-md p-2 ${
                  currentActiveStatus.includes(option.name as NotificationStatus)
                    ? 'shadow-bg-active'
                    : 'shadow-bg-notActive'
                } `}
                role="button"
                onClick={() => onStatusChange(option.name as NotificationStatus)}
              >
                <span>{toUpperCase(option.name)}</span>
              </div>
            </StatusBlock>
          ))}
        </div>
      </SectionLayout>
      <SectionLayout title={t(FILTERS.SENT_DATE.title)} icon={FILTERS.SENT_DATE.icon}>
        <div className="d-flex flex-column">
          {FILTERS.SENT_DATE.options.map((option, index) => {
            return (
              <div key={index} className="my-2">
                <DatePicker
                  onChange={(date) => onDateChange(option.name as E_SENT_DATE, date)}
                  selected={index === 0 ? selectedDates.START : selectedDates.END}
                  placeholderText={index === 0 ? 'Start' : 'End'}
                  customInput={<Input className="rounded-md p-3" placeholder={index === 0 ? 'Start' : 'End'} />}
                  dateFormat="yyyy-mm-dd"
                />
              </div>
            );
          })}
        </div>
      </SectionLayout>
      <Button disabled={disabled} aria-disabled={disabled} type="submit" color="primary" className="w-100">{`${t(
        show
      )} ${t(results)}`}</Button>
    </Form>
  );
}
export default NotificationFilters;

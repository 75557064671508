import { TableColumn } from 'react-data-table-component';
import { reportReason } from './types';

export const TABLES_COLUMNS: TableColumn<reportReason>[] = [
  {
    id: 'id',
    name: 'reason',
    selector: (row) => row.reason,
  },
];

import { dataToTreeV2 } from 'components/tree-select-checkbox/utils';
import { useUpdateEffect } from 'hooks';
import useQuery from 'hooks/useQuery';
import { useMemo } from 'react';
import { GET_COMPANY_CATEGORIES_TREE } from 'services/graphql/queries/companyCategories';
import { d_getTreeData } from 'store/tree/tree.dispatchs';
import { APP_HELPER } from 'tools/enums';
import { isTrophy, toArray } from 'tools/methods';
import { t_companyCategory } from './types';

export default function useCompanyCategories() {
  const { data, loading, error } = useQuery<{ employmentEmployerCategoryList: t_companyCategory[] }>(
    GET_COMPANY_CATEGORIES_TREE,
    {
      variables: {
        page: 1,
        size: APP_HELPER.treeMaxSize,
      },
    }
  );
  const isListEmpty: boolean = useMemo(() => isTrophy(data?.employmentEmployerCategoryList?.length), [data]);
  const updatedData = useMemo(
    () =>
      isListEmpty
        ? dataToTreeV2(toArray(data?.employmentEmployerCategoryList), {
            id: '_id',
            labelKey: 'originalName',
            iconKey: 'imageUrl',
            childKey: 'subCategories',
          })
        : [],
    [data, isListEmpty]
  );

  useUpdateEffect(() => {
    if (isTrophy(updatedData)) {
      d_getTreeData(updatedData);
    }
  }, [updatedData]);
  return { isListEmpty, data: updatedData, error, loading };
}

import { ApolloError } from '@apollo/client';
import { Loading } from 'components';
import Link from 'components/link';
import { TableSectionTitle } from 'components/tables';
import DataTable from 'components/tables/dataTable';
import PagesLayout from 'pages/components/layout';
import useFuar, { exhibitionStatus } from 'pages/fuar/fuar/useFuar';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Input } from 'reactstrap';
import { Location, New, add, date, detail, fuar, list, status, website } from 'tools/i18n/constants/i18n';

export default function Fuar() {
  const { t } = useTranslation();
  const addNewTitle = `${t(add)} ${t(New)} ${t(fuar)}`;
  const { data, loading } = useFuar();
  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <Loading loading={loading}>
        <PagesLayout newSectionButtonLabel={addNewTitle}>
          <MagazineTable data={data} />
        </PagesLayout>
      </Loading>
    </Col>
  );
}

interface MagazineTableProps {
  data: unknown[];
  error?: ApolloError;
}

const MagazineTable: FC<MagazineTableProps> = (props) => {
  const { data, error } = props;
  const { t } = useTranslation();
  const title = `${t(fuar)} ${t(list)}`;

  const columnData = useMemo(() => {
    return [
      {
        name: <TableSectionTitle name="FiAperture" title={`${t(fuar)} ${t(title)}`} />,
        center: false,
        cell: (r: { title: string; logo: string; id: number }) => (
          <Link to={'exhibitionDetails'} param={r.id.toString()}>
            <div className="d-flex align-items-center table-cell-container">
              <div className="rounded-circle img-md">
                <img className="rounded-circle" src={r.logo} alt={r.title} />
              </div>
              <span className="ml-2 text-black">{r.title}</span>
            </div>
          </Link>
        ),
      },

      {
        name: <TableSectionTitle title={`${t(date)}`} name="FiLayers" />,
        center: true,
        cell: (r: { startDate?: string; endDate?: string }) => (
          <div className="d-flex flex-column py-2">
            <span className="mb-2 line-height-lg">{r?.startDate}</span> <span>{r?.endDate}</span>
          </div>
        ),
      },
      {
        name: <TableSectionTitle title={`${t(Location)}`} name="FiActivity" />,
        center: false,
        cell: (r: { country?: any; city: any }) => (
          <div className="d-flex flex-column text-left">
            <span className="mb-3">{r?.country?.name}</span>
            <span>{r?.city?.name}</span>
          </div>
        ),
      },
      {
        name: <TableSectionTitle title={`${t(website)}`} name="FiActivity" />,
        selector: (r: { webSite?: any }) => r?.webSite || '',
      },
      {
        center: true,
        name: <TableSectionTitle title={`${t(status)}`} name="FiActivity" />,
        selector: (r: { status?: exhibitionStatus }) => (
          <span
            style={{ width: '80px' }}
            className={`text-center d-inline-block p-2 rounded-md ${
              r.status === 'ACTIVE' ? 'shadow-bg-success' : 'shadow-bg-danger'
            }`}
          >
            {r.status === 'ACTIVE' ? 'Active' : 'DeActive'}
          </span>
        ),
      },
      {
        name: '',
        cell: (r: { id: any }) => {
          return (
            <Link className="text-white ml-auto" to={'exhibitionDetails'} param={r.id.toString()}>
              <Button color="primary">{t(detail)}</Button>
            </Link>
          );
        },
      },
    ];
  }, []);
  // @ts-ignore
  return <DataTable disabled={!!error} searchable headerTitle={title} columns={columnData} data={data} />;
};

import React, { Fragment } from 'react';

type renderProps = {
  Elm: React.ElementType;
  increaseElement: VoidFunction;
  index: number;
  currentElementsLength: number;
  isReachedMaxCount: boolean;
};

interface IncreaseAbleProps {
  defaultValue?: number;
  maxCount?: number;
  rootElement: any;
  render(props: renderProps): JSX.Element;
}

interface IncreaseAbleState {
  elements: number[];
}

class IncreaseAble extends React.Component<IncreaseAbleProps, IncreaseAbleState> {
  constructor(props: any) {
    super(props);
    const defaultCount: number[] = [];
    const defaultValue = this?.props?.defaultValue || 1;
    for (let i = 1; i <= defaultValue; i++) {
      defaultCount.push(i);
    }
    this.state = {
      elements: defaultCount,
    };
  }

  handleIncrease = () => {
    const maxCount = this.props.maxCount;
    const currentLength = this.state.elements.length;
    if (maxCount !== 0) {
      if (maxCount !== undefined) {
        if (currentLength <= maxCount - 1) {
          this.setState({
            elements: [...this.state.elements, this.state.elements.length + 1],
          });
        }
      } else {
        this.setState({
          elements: [...this.state.elements, this.state.elements.length + 1],
        });
      }
    }
  };

  render() {
    return (
      <>
        {this.state.elements.map((elm: any, index) => {
          return (
            <Fragment key={elm}>
              {this.props.render({
                Elm: this.props.rootElement,
                increaseElement: this.handleIncrease,
                index,
                currentElementsLength: this.state.elements.length,
                isReachedMaxCount: this.props.maxCount === this.state.elements.length,
              })}
            </Fragment>
          );
        })}
      </>
    );
  }
}

export default IncreaseAble;

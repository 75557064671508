import { useMemo } from 'react';
import { isTrophy } from 'tools/methods';
import { APP_HELPER } from 'tools/enums';
import { GET_ADS_APPLY_LIST } from 'services/graphql/queries/ads';
import { useParams, useSearchParams } from 'react-router-dom';
import useQuery from 'hooks/useQuery';

export default function useApplyList() {
  const [param] = useSearchParams();
  const defaultPage = param.get('page') ? parseInt(param.get('page') as string) : APP_HELPER.tableDefaultPage;
  const defaultSize = param.get('size') ? parseInt(param.get('size') as string) : APP_HELPER.tableDefaultSize;
  const { type } = useParams();
  const {
    loading,
    data = { EmploymentAdsList: [] },
    error,
    refetch,
    fetchMore,
  } = useQuery(GET_ADS_APPLY_LIST, {
    defaultOptions: {
      variables: {
        page: defaultPage,
        size: defaultSize,
        id: type,
      },
    },
    fetchPolicy: 'no-cache',
  });
  const isListEmpty = useMemo(() => !isTrophy(data.EmploymentAdAppliesList), [data]);
  const updatedData = useMemo(() => (isListEmpty ? [] : data.EmploymentAdAppliesList), [isListEmpty]);
  return { loading, data: updatedData, error, refetch, fetchMore };
}

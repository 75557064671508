import { FormProps } from 'pages/ads/jobAds/index';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGroup, Input, Label } from 'reactstrap';
import styled from 'styled-components';
import { companies, only, verified } from 'tools/i18n/constants/i18n';
type OnlyVerifiedCompaniesProps = FormProps;

const StyledCheckbox = styled(Input)`
  width: 20px;
  height: 24px;
`;

export default function OnlyVerifiedCompanies(props: OnlyVerifiedCompaniesProps) {
  const { t } = useTranslation();
  const { control } = props;
  return (
    <FormGroup className="d-flex">
      <Controller
        control={control}
        name="onlyVerifiedCompanies"
        render={({ field: { onChange, name } }) => {
          return <StyledCheckbox type="checkbox" onChange={onChange} name={name} />;
        }}
      />
      <Label className="ml-2">{`${t(only)} ${t(verified)} ${t(companies)}`}</Label>
    </FormGroup>
  );
}

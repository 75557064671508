import React, { FC, ReactNode, useEffect, useState } from 'react';

import {
  Accordion as A,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

export type accordionOption = {
  title: ReactNode;
  state?: string;
  element: ReactNode;
  expanded?: boolean;
};

export interface AccordionProps {
  items: accordionOption[];
}

interface AccordionTitleProps {
  isRequired?: boolean;
  children?: ReactNode;
  rightChildren?: ReactNode;
}

export const AccordionTitle: FC<AccordionTitleProps> = (props) => {
  const { isRequired, children, rightChildren } = props;
  return (
    <div className="d-flex align-items-center">
      <h6> {children} </h6>
      {isRequired && <div className="h5 text-danger ml-2"> * </div>}
      {rightChildren && <div className="ml-auto">{rightChildren}</div>}
    </div>
  );
};

export const Accordion: FC<AccordionProps> = (props) => {
  const { items } = props;
  const [Items, setItems] = useState<accordionOption[]>(items);
  const handleClick = (opt: accordionOption) => {
    setItems((prev) =>
      prev.map((item) => {
        if (item.state === opt.state) {
          return { ...item, expanded: !item.expanded };
        }
        return item;
      })
    );
  };
  useEffect(() => {
    setItems(items);
  }, [items]);
  return (
    <A allowMultipleExpanded allowZeroExpanded>
      {Items.map((opt, i) => {
        return (
          <AccordionItem dangerouslySetExpanded={opt.expanded} aria-expanded={opt.expanded} key={i}>
            <AccordionItemHeading onClick={() => handleClick(opt)}>
              <AccordionItemButton>{opt.title}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>{opt.element}</AccordionItemPanel>
          </AccordionItem>
        );
      })}
    </A>
  );
};

export default Accordion;

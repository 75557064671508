import { UseFormHandleSubmit, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { Control, Controller } from 'react-hook-form';
import { t_countryCodes, t_errors } from 'tools/types';
import { useTranslation } from 'react-i18next';
import { Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import {
  address,
  capacity,
  contact,
  count,
  description,
  email,
  enter,
  fullAddress,
  isThisSchoolActive,
  isThisSchoolVisible,
  phoneNumber,
  please,
  postalCode,
  save,
  student,
  update,
  website,
} from 'tools/i18n/constants/i18n';
import OnlyCharInput from 'components/inputs/onlyCharacter';
import OnlyNumberInput from 'components/inputs/onlyNumber';
import { EmailInput, PhoneNumberInput, WebsiteInput } from 'components/inputs';
import React, { memo, useState } from 'react';
import { t_defaultValues, t_steps } from 'pages/school/schoolDetail';
import SubmitButton from 'components/buttons/submitButton';

interface AdditionalFormProps {
  isRegistered: boolean;
  steps: t_steps;
  handleSubmit: UseFormHandleSubmit<t_defaultValues>;
  control: Control<t_defaultValues, any>;
  onSubmit: (data: t_defaultValues) => void;
  setValue: UseFormSetValue<t_defaultValues>;
  defaultValue?: t_defaultValues;
  loading: boolean;
  errors: t_errors<t_defaultValues>;
}
interface SchoolActiveVisibleProps {
  control: Control<t_defaultValues>;
  defaultValue?: t_defaultValues;
  setValue: UseFormSetValue<t_defaultValues>;
}

export default function AdditionalForm(props: AdditionalFormProps) {
  const { defaultValue, onSubmit, handleSubmit, setValue, control, loading, errors } = props;
  const { t } = useTranslation();
  return (
    <div className="px-5 py-3">
      <Form className="bg-gray rounded p-4" onSubmit={handleSubmit(onSubmit, console.error)}>
        <FormGroup>
          <Label htmlFor="fullAddress">{t(fullAddress)}</Label>
          <OnlyCharInput
            id="fullAddress"
            defaultValue={defaultValue ? (defaultValue?.fullAddress as string) : undefined}
            placeholder={`${t(please)} ${t(enter)} ${t(fullAddress)}`}
            control={control}
            isRequired
            name="fullAddress"
            isNumberAllowed
            isEveryCharacterAllowed
            requiredErrorText={`${t(enter)} ${t(fullAddress)}`}
          />
          <FormFeedback>{errors?.fullAddress?.message}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="fullAddress">{t(postalCode)}</Label>
          <OnlyNumberInput
            placeholder={`${t(please)} ${t(enter)} ${t(postalCode)}`}
            defaultValue={defaultValue?.postalCode ? parseInt(defaultValue?.postalCode) : undefined}
            //@ts-ignore
            control={control}
            maxLength={5}
            name="postalCode"
            requiredErrorText={`${t(enter)} ${t(postalCode)}`}
            validNumberAsString
            isRequired
          />
          <FormFeedback>{errors?.postalCode?.message}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="description">{t(description)}</Label>
          <OnlyCharInput
            isNumberAllowed
            isEveryCharacterAllowed
            isRequired
            isTextArea
            control={control}
            name="bio"
            requiredErrorText={`${t(enter)} ${t(description)}`}
            id="description"
            placeholder={`${t(please)} ${t(enter)} ${t(description)}`}
            defaultValue={defaultValue?.bio as string}
          />
          <FormFeedback>{errors?.bio?.message}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>{`${t(contact)} ${t(phoneNumber)}`}</Label>
          <Controller
            control={control}
            name="contactPhoneNumber"
            defaultValue={defaultValue?.contactPhoneNumber}
            rules={{ required: `${t(enter)} ${t(phoneNumber)}` }}
            render={({ field: { onChange } }) => {
              return (
                <PhoneNumberInput
                  onPhoneNumberChange={(val) => onChange(val.toString())}
                  errorMessage={errors?.contactPhoneNumber?.message}
                  onCountryChange={(val) => setValue('contactPhoneCode', val.value)}
                  defaultValue={{
                    phoneNumber: defaultValue?.contactPhoneNumber as string,
                    country: defaultValue?.contactPhoneCode as t_countryCodes,
                  }}
                />
              );
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label>{`${t(contact)} ${t(email)} ${t(address)}`}</Label>
          <EmailInput
            isRequired
            control={control}
            defaultValue={defaultValue?.contactEmail}
            name="contactEmail"
            placeholder={`${t(please)} ${t(enter)} ${t(email)}`}
          />
          <FormFeedback>{errors?.contactEmail?.message}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>{t(website)}</Label>
          <WebsiteInput
            control={control}
            name="websiteUrl"
            defaultValue={defaultValue?.websiteUrl}
            placeholder={`${t(please)} ${t(enter)} ${t(website)}`}
            isRequired
          />
          <FormFeedback>{errors?.websiteUrl?.message}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>{`${t(student)} ${t(count)} ${t(capacity)}`}</Label>
          <OnlyNumberInput
            minLength={1}
            min={1}
            maxLength={5}
            isRequired
            validNumberAsString
            makeFinalValueAsNumber
            //@ts-ignore
            control={control}
            defaultValue={defaultValue?.studentCountCapacity ? parseInt(defaultValue?.studentCountCapacity) : undefined}
            rules={{ minLength: 1, maxLength: 5, min: 1 }}
            placeholder={`${t(please)} ${t(enter)} ${t(student)} ${t(count)}`}
            name="studentCountCapacity"
          />

          <FormFeedback>{errors?.studentCountCapacity?.message}</FormFeedback>
        </FormGroup>
        <SchoolActiveVisible setValue={setValue} control={control} defaultValue={defaultValue} />
        <SubmitButton loading={loading}>{t(defaultValue ? update : save)}</SubmitButton>
      </Form>
    </div>
  );
}

// eslint-disable-next-line react/display-name
const SchoolActiveVisible = memo((props: SchoolActiveVisibleProps) => {
  const { control, defaultValue, setValue } = props;
  const { t } = useTranslation();
  const [value, setCheckbox] = useState({
    isActive: defaultValue?.isActive,
    isVisible: defaultValue?.isVisible,
  });
  return (
    <FormGroup className="mt-2">
      <FormGroup className="d-flex align-items-center">
        <Controller
          control={control}
          name="isVisible"
          defaultValue={defaultValue?.isVisible}
          render={({ field: { onChange } }) => {
            return (
              <Input
                {...(value.isActive ? { checked: value.isVisible } : { checked: false })}
                onChange={(e) => {
                  onChange(e);
                  setCheckbox((prev) => {
                    return { ...prev, isVisible: e.target.checked };
                  });
                }}
                id="acceptable"
                type="checkbox"
                defaultChecked={value?.isVisible}
                style={{ width: '18px', height: '18px' }}
                role="button"
              />
            );
          }}
        />

        <Label className="ml-2 mb-0 mt-1" htmlFor="acceptable">
          {t(isThisSchoolVisible)}
        </Label>
      </FormGroup>
      <FormGroup className="d-flex align-items-center">
        <Controller
          control={control}
          defaultValue={value?.isActive}
          name="isActive"
          render={({ field: { onChange } }) => {
            return (
              <Input
                onChange={(e) => {
                  onChange(e);
                  setCheckbox((prev) => {
                    const isVisible = (!e.target.checked ? false : prev.isVisible) as boolean;
                    if (!e.target.checked) {
                      setValue('isVisible', false);
                    }
                    return { isVisible, isActive: e.target.checked };
                  });
                }}
                id="isActive"
                type="checkbox"
                defaultChecked={value?.isActive}
                role="button"
                style={{ width: '18px', height: '18px' }}
              />
            );
          }}
        />

        <Label className="ml-2 mb-0 mt-1" htmlFor="isActive">
          {t(isThisSchoolActive)}
        </Label>
      </FormGroup>
    </FormGroup>
  );
});

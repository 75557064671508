import { ApolloError } from '@apollo/client';
import { Loading } from 'components';
import FormActionLoading, { step } from 'components/formActionLoading';
import UploadCover from 'components/inputs/uploadCover';
import UploadPdf from 'components/inputs/uploadPdf';
import Modal from 'components/modal';
import { useToggle, useUpdateEffect } from 'hooks';
import useMutation from 'hooks/useMutation';
import PagesLayout from 'pages/components/layout';
import UpdateAddHeader from 'pages/components/updateAddHeader';
import useMagazineEditionDetail from 'pages/magazine/useMagazineEditionDetail';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Image } from 'react-bootstrap';
import { Control, Controller, useForm } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import FetchApi from 'services/api/axios';
import {
  CREATE_MAGAZINE_EDITION,
  DELETE_MAGAZINE_EDITION,
  UPDATE_MAGAZINE_EDITION,
} from 'services/graphql/queries/magazine';
import { LANGUAGES_DROPDOWN, MONTH_DROPDOWN, NON_EMPTY_VALUES, YEARS_DROPDOWN, YEAR_REGEXP } from 'tools/constants';
import { APP_ROUTE_NAME } from 'tools/enums';
import {
  Delete,
  Deleted,
  cancel,
  cover,
  created,
  date,
  description,
  edit,
  editions,
  enter,
  file,
  fill,
  information,
  inputs,
  language,
  magazine,
  month,
  please,
  publish,
  save,
  select,
  successfully,
  title,
  updated,
  upload,
  valid,
  year,
} from 'tools/i18n/constants/i18n';
import { getMonth, getYear, goBack, isNew } from 'tools/methods';
import { t_errors, t_languageKeys } from 'tools/types';

export default function EditionMagazineDetail() {
  const { id } = useParams();
  const { t } = useTranslation();
  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <PagesLayout backLinkTitle={t(magazine)}>
        <Card className="overflow-hidden rounded-md">
          {isNew(id) ? <NewMagazineEdition /> : <UpdateMagazineEdition />}
        </Card>
      </PagesLayout>
    </Col>
  );
}

const Header: FC<{ isNew: boolean; data?: any }> = ({ isNew, data }) => {
  const { t } = useTranslation();
  const title = `${t(edit)} ${t(information)}`;
  const [openModal, toggleModal] = useToggle(false);
  const [execute, { loading }] = useMutation(DELETE_MAGAZINE_EDITION);
  const { type, id } = useParams();
  const navigate = useNavigate();
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    execute({
      variables: {
        id: parseInt(id as string),
      },
    })
      .then((res) => {
        console.log(res);
        toast.success(`${t(magazine)} ${t(editions)} ${t(successfully)} ${t(Deleted)}`);
        navigate(`/${APP_ROUTE_NAME.dashboard}/${APP_ROUTE_NAME.magazine}/${type}`);
      })
      .catch(console.error);
  };
  return (
    <>
      <Modal size="lg" isOpen={openModal} toggleOpen={toggleModal} title={`${t(Delete)} ${t(magazine)}`}>
        <Form onSubmit={(e) => onSubmit(e)} className="d-flex flex-column">
          <div className="d-flex align-items-center">
            <div>
              <Image
                className="border rounded-circle mr-3"
                src={data?.cover}
                style={{ minWidth: '80px', minHeight: '80px', maxHeight: '80px', maxWidth: '80px' }}
              />
            </div>
            <h6>{data?.title}</h6>
          </div>
          <div className="d-flex flex-column mt-3">
            <Button disabled={loading} color="danger" type="submit">
              {t(Delete)}
            </Button>
            <Button disabled={loading} onClick={() => toggleModal()} type="button" className="mt-2">
              {t(cancel)}
            </Button>
          </div>
        </Form>
      </Modal>
      <UpdateAddHeader onClick={toggleModal} title={title} isNew={isNew} />
    </>
  );
};

type t_customLang = { language: t_languageKeys; description: string; id?: number };

type t_defaultValue = {
  data?: any;
  url?: any;
  id?: string;
  title?: string;
  year?: string | number;
  month?: string | number;
  cover?: string;
  pages?: string;
  size?: string;
  file?: string;
  language: t_customLang[];
  descriptionInputTr?: string;
  descriptionInputEn?: string;
};

function UpdateMagazineEdition() {
  const { error, loading, data } = useMagazineEditionDetail();
  return <EditionMagazineDetailForm error={error} loading={loading} data={data} isNew={false} />;
}

function NewMagazineEdition() {
  return <EditionMagazineDetailForm isNew={true} />;
}

interface EditionMagazineDetailProps {
  data?: any;
  loading?: boolean;
  error?: ApolloError;
  isNew?: boolean;
}
type stepInfo = {
  index: number;
  pending: boolean;
  completed: boolean;
  error: boolean;
  fileName?: string;
  percent?: number;
};
const EditionMagazineDetailForm: FC<EditionMagazineDetailProps> = (props) => {
  const { isNew, data, loading } = props;
  const { t } = useTranslation();
  const [openUILoading, toggleUILoading] = useToggle(false);
  const { id, type } = useParams();
  const [createExecute] = useMutation(CREATE_MAGAZINE_EDITION);
  const [updateExecute] = useMutation(UPDATE_MAGAZINE_EDITION);
  const [stepsInfo, setStepsInfo] = useState<stepInfo[]>([
    {
      index: 1,
      error: false,
      percent: 0,
      pending: false,
      completed: false,
    },
    {
      index: 2,
      error: false,
      fileName: 'Cover',
      percent: 0,
      pending: false,
      completed: false,
    },
    {
      index: 3,
      error: false,
      fileName: 'PDF',
      percent: 0,
      pending: false,
      completed: false,
    },
  ]);
  const [realLoading, toggleRealLoading] = useToggle(loading);
  const DEFAULT_VALUES: t_defaultValue = useMemo(() => {
    return {
      cover: data?.cover,
      file: data?.file,
      pages: data?.pages,
      size: data?.size,
      language: data?.language,
      month: data?.publishDate ? +getMonth(data?.publishDate) : undefined,
      year: data?.publishDate ? +getYear(data?.publishDate) : undefined,
      title: data?.title,
    };
  }, [data]);
  const lastFetchData = useRef<Record<'formInput' | 'cover' | 'pdf', t_defaultValue | { data: any; url?: string }>>({
    formInput: DEFAULT_VALUES as t_defaultValue,
    cover: { data: DEFAULT_VALUES.cover },
    pdf: { data: DEFAULT_VALUES.file },
  });
  const handleChangeStepInfo = useCallback((step: 1 | 2 | 3, changeKey: Partial<Record<keyof stepInfo, any>>) => {
    setStepsInfo((prev) => {
      return prev.map((info) => {
        if (info.index !== step) return info;
        return {
          ...info,
          ...changeKey,
        };
      });
    });
  }, []);
  const onSuccess = useCallback(() => {
    toast.success(`${t(magazine)} ${t(isNew ? created : updated)}`);
    toggleRealLoading(false);
    goBack();
  }, [isNew]);

  const onFailure = useCallback(() => {
    toggleRealLoading(false);
  }, [isNew]);
  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<t_defaultValue>({
    defaultValues: DEFAULT_VALUES,
  });
  const ple = `${t(please)} ${t(enter)} ${t(editions)}`;

  const Fetch = useCallback(async ({ url, data, step }: { url: any; data: any; step: 2 | 3 }) => {
    handleChangeStepInfo(step, { pending: true, completed: false, error: false });
    return FetchApi({
      url,
      data,
      method: 'PATCH',
      onUploadProgress: (progressEvent) => {
        handleChangeStepInfo(step, {
          pending: true,
          completed: false,
          error: false,
          percent: Math.round((progressEvent.loaded * 100) / progressEvent.total),
        });
      },
    });
  }, []);

  const onSubmit = async (data = DEFAULT_VALUES) => {
    if (realLoading) {
      return;
    }
    toggleRealLoading(true);
    toggleUILoading(true);
    handleChangeStepInfo(1, { pending: true, completed: false, error: false });
    const updatedValue: t_defaultValue = {
      ...data,
      language: data.language.map((desc) => ({ language: desc.language, description: desc.description })),
    };
    const keys = Object.keys(updatedValue);
    keys.forEach((key) => {
      if (!updatedValue[key] && DEFAULT_VALUES[key]) {
        updatedValue[key] = DEFAULT_VALUES[key];
      }
    });
    updatedValue['magazineId'] = parseInt(type as string);
    updatedValue['year'] = parseInt(updatedValue.year as string);
    updatedValue['month'] = parseInt(updatedValue.month as string);
    if (updatedValue.size) {
      // @ts-ignore
      updatedValue['size'] = parseFloat(updatedValue.size as string);
    }
    if (updatedValue.pages) {
      // @ts-ignore
      updatedValue['pages'] = parseFloat(updatedValue.pages as string);
    }
    lastFetchData.current.formInput = updatedValue as t_defaultValue;
    try {
      const Response = await createExecute({
        variables: updatedValue,
      });
      console.log(Response);

      if (Response.data?.CreateMagazineEdition?.id) {
        handleChangeStepInfo(1, { pending: false, completed: true, error: false });
        if (updatedValue.cover) {
          handleChangeStepInfo(2, { pending: true, completed: false, error: false });
          const formData = new FormData();
          formData.append('image', updatedValue.cover);
          formData.append('id', Response.data.CreateMagazineEdition.id);
          lastFetchData.current.cover['data'] = formData;
          lastFetchData.current.cover['url'] = 'MAGAZINE_EDITION_COVER';
          const coverResponse = await Fetch({
            url: 'MAGAZINE_EDITION_COVER',
            data: formData,
            step: 2,
          });
          if (coverResponse?.data?.message !== 'done') {
            throw Error('2');
          } else handleChangeStepInfo(2, { pending: false, completed: true, error: false });
          if (updatedValue.file) {
            handleChangeStepInfo(3, { pending: true, completed: false, error: false });
            const formData = new FormData();
            formData.append('PDFFile', updatedValue.file);
            formData.append('id', Response.data.CreateMagazineEdition.id);
            lastFetchData.current.pdf['data'] = formData;
            lastFetchData.current.pdf['url'] = 'MAGAZINE_EDITION_FILE';
            const pdfResponse = await Fetch({
              url: 'MAGAZINE_EDITION_FILE',
              data: formData,
              step: 3,
            });
            if (pdfResponse.data?.message !== 'done') {
              throw Error('3');
            } else {
              handleChangeStepInfo(3, { pending: false, completed: true, error: false });
            }
          }
        }
        onSuccess();
      } else throw Error('1');
    } catch (e) {
      console.log(e.message);
      if (typeof e.message === 'string') {
        handleChangeStepInfo(parseInt(e.message) as any, { pending: false, error: true, completed: false });
      }
      onFailure();
    }
  };

  const onUpdate = async (data = DEFAULT_VALUES) => {
    if (realLoading) return;
    toggleRealLoading(true);
    toggleUILoading(true);
    handleChangeStepInfo(1, { pending: true, completed: false, error: false });
    const updatedValue: t_defaultValue = {
      ...data,
      language: data.language.map((desc) => ({ language: desc.language, description: desc.description })),
      year: parseInt(data.year as string),
      month: parseInt(data.month as string),
    };
    const keys = Object.keys(updatedValue);
    keys.forEach((key) => {
      if (!updatedValue[key] && DEFAULT_VALUES[key]) {
        updatedValue[key] = DEFAULT_VALUES[key];
      }
    });
    // @ts-ignore
    updatedValue['id'] = parseInt(id as string);
    if (updatedValue.size) {
      // @ts-ignore
      updatedValue['size'] = parseFloat(updatedValue.size as string);
    }
    if (updatedValue.pages) {
      // @ts-ignore
      updatedValue['pages'] = parseFloat(updatedValue.pages as string);
    }
    lastFetchData.current.formInput = updatedValue;
    try {
      const Response = await updateExecute({
        variables: updatedValue,
      });

      if (Response?.data?.UpdateMagazineEdition) {
        handleChangeStepInfo(1, { pending: false, completed: true, error: false });
        if (updatedValue.cover && DEFAULT_VALUES.cover !== updatedValue.cover) {
          handleChangeStepInfo(2, { pending: true, completed: false, error: false });
          const formData = new FormData();
          formData.append('image', updatedValue.cover);
          formData.append('id', id as string);
          lastFetchData.current.cover['data'] = formData;
          lastFetchData.current.cover['url'] = 'MAGAZINE_EDITION_COVER';
          const coverResponse = await Fetch({
            url: 'MAGAZINE_EDITION_COVER',
            data: formData,
            step: 2,
          });
          if (coverResponse?.data?.message !== 'done') {
            throw Error('2');
          } else {
            handleChangeStepInfo(2, { pending: false, error: false, completed: true });
          }
        }
        if (updatedValue.file && DEFAULT_VALUES.file !== updatedValue.file) {
          handleChangeStepInfo(3, { pending: true, completed: false, error: false });
          const formData = new FormData();
          formData.append('PDFFile', updatedValue.file);
          formData.append('id', id as string);
          lastFetchData.current.pdf['data'] = formData;
          lastFetchData.current.pdf['url'] = 'MAGAZINE_EDITION_FILE';
          const pdfResponse = await Fetch({
            url: 'MAGAZINE_EDITION_FILE',
            data: formData,
            step: 3,
          });
          if (pdfResponse?.data?.message !== 'done') {
            throw Error('3');
          } else {
            handleChangeStepInfo(3, { error: false, completed: true, pending: false });
          }
        }
        onSuccess();
      } else {
        throw Error('1');
      }
    } catch (e) {
      console.log(e.message);
      if (typeof e.message === 'string') {
        handleChangeStepInfo(parseInt(e.message) as any, { pending: false, error: true, completed: false });
      }
      onFailure();
    }
  };
  useUpdateEffect(() => {
    toggleRealLoading(loading);
  }, [loading]);
  const loadingSteps: step[] = [
    {
      index: 1,
      title: 'Upload Form Input',
      fileName: Object.keys(DEFAULT_VALUES).join(', '),
      status: stepsInfo[0].error
        ? 'FAILED'
        : stepsInfo[0].pending
        ? 'UPLOADING'
        : stepsInfo[0].completed
        ? 'COMPLETED'
        : 'WAITING',
      onRetry: () =>
        isNew
          ? onSubmit(lastFetchData.current.formInput as t_defaultValue)
          : onUpdate(lastFetchData.current.formInput as t_defaultValue),
    },
    {
      index: 2,
      title: 'Uploading Cover',
      fileName: stepsInfo[1].fileName as string,
      onRetry: () =>
        isNew
          ? onSubmit(lastFetchData.current.formInput as t_defaultValue)
          : onUpdate(lastFetchData.current.formInput as t_defaultValue),
      status: stepsInfo[1].error
        ? 'FAILED'
        : stepsInfo[1].pending
        ? 'UPLOADING'
        : stepsInfo[1].completed
        ? 'COMPLETED'
        : 'WAITING',
      progressConfig: {
        percent: stepsInfo[1].percent || 0,
      },
    },
    {
      index: 3,
      title: 'Uploading Pdf File',
      fileName: stepsInfo[2].fileName as string,
      onRetry: () =>
        isNew
          ? onSubmit(lastFetchData.current.formInput as t_defaultValue)
          : onUpdate(lastFetchData.current.formInput as t_defaultValue),
      status: stepsInfo[2].error
        ? 'FAILED'
        : stepsInfo[2].pending
        ? 'UPLOADING'
        : stepsInfo[2].completed
        ? 'COMPLETED'
        : 'WAITING',
      progressConfig: {
        percent: stepsInfo[2].percent || 0,
      },
    },
  ];
  return (
    <Loading loading={!!loading}>
      <FormActionLoading onClose={() => toggleUILoading(false)} open={openUILoading} steps={loadingSteps} />
      {/* <Header isNew={!!isNew} data={data} /> */}
      <CardBody>
        <Form onSubmit={handleSubmit(isNew ? onSubmit : onUpdate, console.error)}>
          <FormGroup className="bg-gray p-4 rounded">
            <FormGroup>
              <Label>{`${t(editions)} ${t(title)}`}</Label>
              <Controller
                control={control}
                defaultValue={DEFAULT_VALUES?.title}
                name="title"
                rules={{
                  required: `${t(please)} ${t(enter)} ${t(title)}`,
                  maxLength: { value: 200, message: 'Only 200 character Allowed' },
                  minLength: { value: 1, message: 'At least one character Allowed' },
                  pattern: { value: NON_EMPTY_VALUES, message: 'Please Enter Valid Title' },
                }}
                render={({ field: { onChange } }) => {
                  return (
                    <Input
                      defaultValue={DEFAULT_VALUES?.title}
                      onChange={onChange}
                      name={'title'}
                      placeholder={`${t(magazine)} ${t(editions)} ${t(title)}`}
                    />
                  );
                }}
              />
              <FormFeedback>{errors?.title?.message}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label>{`${t(publish)} ${t(date)}`}</Label>
              <div className="d-flex">
                <div className="d-flex flex-column mr-1 flex-grow-1">
                  <Controller
                    control={control}
                    name="year"
                    defaultValue={DEFAULT_VALUES.year}
                    rules={{
                      required: `${t(please)} ${t(enter)} ${t(year)}`,
                      pattern: YEAR_REGEXP,
                    }}
                    render={({ field: { onChange, name } }) => {
                      return (
                        <Select
                          options={YEARS_DROPDOWN()}
                          className="flex-grow-1 "
                          placeholder={t(year)}
                          onChange={(val) => onChange(val?.value)}
                          name={name}
                          {...(DEFAULT_VALUES.year
                            ? {
                                defaultValue: {
                                  label: DEFAULT_VALUES.year,
                                  value: DEFAULT_VALUES.year,
                                },
                              }
                            : {})}
                        />
                      );
                    }}
                  />
                  <FormFeedback className="d-inline">
                    {errors?.year ? `${t(please)} ${t(enter)} ${t(valid)} ${t(year)}` : ''}
                  </FormFeedback>
                </div>
                <div className="d-flex flex-column flex-grow-1">
                  <Controller
                    control={control}
                    name="month"
                    rules={{
                      required: `${t(please)} ${t(enter)} ${t(valid)} ${t(month)}`,
                      max: 12,
                      minLength: 1,
                    }}
                    defaultValue={DEFAULT_VALUES.month}
                    render={({ field: { onChange } }) => {
                      return (
                        <Select
                          onChange={(val) => onChange(val?.value)}
                          className="flex-grow-1"
                          {...(DEFAULT_VALUES.month
                            ? {
                                defaultValue: {
                                  label: DEFAULT_VALUES.month,
                                  value: DEFAULT_VALUES.month,
                                },
                              }
                            : {})}
                          placeholder={t(month)}
                          name="month"
                          options={MONTH_DROPDOWN}
                        />
                      );
                    }}
                  />
                  <FormFeedback>{errors?.month ? `${t(please)} ${t(enter)} ${t(valid)} ${t(month)}` : ''}</FormFeedback>
                </div>
              </div>
            </FormGroup>
          </FormGroup>

          <MultipleLang
            placeholder={`${t(ple)} ${t(description)}`}
            setValue={setValue}
            defaultValue={DEFAULT_VALUES.language}
            control={control}
            errors={errors}
          />
          <FormGroup className="p-4 rounded bg-gray d-flex align-items-end ">
            <FormGroup className="mr-3 flex-column">
              <Label>{`${t(upload)} ${t(cover)}`}</Label>
              <Controller
                control={control}
                name="cover"
                rules={{ required: `${t(please)} ${t(select)} ${t(cover)}` }}
                defaultValue={DEFAULT_VALUES?.cover}
                render={({ field: { onChange } }) => {
                  return (
                    <UploadCover
                      defaultImage={DEFAULT_VALUES.cover}
                      onSelect={(file) => {
                        if (file?.size && file?.size > 1000000) {
                          setError('cover', { message: 'File Size Exceeds' });
                        } else {
                          clearErrors();
                          onChange(file);
                        }
                      }}
                    />
                  );
                }}
              />
              <FormFeedback>{errors?.cover?.message}</FormFeedback>
            </FormGroup>
            <FormGroup className="ml-5">
              <Controller
                name="file"
                control={control}
                rules={{ required: `${t(please)} ${t(select)} ${t(file)}` }}
                defaultValue={DEFAULT_VALUES.file}
                render={({ field: { onChange } }) => {
                  return (
                    <UploadPdf
                      maxSize={50000000}
                      defaultPdf={DEFAULT_VALUES.file}
                      onSelect={(file) => {
                        onChange(file);
                        setValue('size', (file?.size as number).toString());
                      }}
                    />
                  );
                }}
              />
              <FormFeedback>{errors?.file?.message}</FormFeedback>
            </FormGroup>
          </FormGroup>
          <Button disabled={realLoading} type="submit" color="primary" className="w-100">
            {t(isNew ? save : upload)}
          </Button>
        </Form>
      </CardBody>
    </Loading>
  );
};
interface MultipleLang {
  placeholder: string;
  setValue: UseFormSetValue<t_defaultValue>;
  defaultValue?: t_customLang[];
  errors?: t_errors<t_defaultValue>;
  control: Control<t_defaultValue>;
}

const MultipleLang: FC<MultipleLang> = (props) => {
  const { placeholder, setValue, defaultValue = [], control, errors } = props;
  const { t } = useTranslation();
  const [value, setValues] = useState(defaultValue);
  const handleChangeValue = (l: t_languageKeys, val: string) => {
    setValues((prev) => {
      const isSame = prev?.find((lang) => lang.language === l);
      if (isSame) {
        const findUnMatch = prev.filter((lang) => lang.language !== l);
        return [...findUnMatch, { ...isSame, description: val }];
      } else {
        //const findUnMatch = prev.filter(lang => lang.language !== l)
        return [...prev, { language: l, description: val }];
      }
    });
  };
  useEffect(() => {
    if (value) {
      setValue('language', value);
    }
  }, [value]);
  return (
    <div className="d-flex justify-content-between flex-wrap">
      <FormGroup className={`p-4 mr-3 flex-grow-1 rounded bg-gray position-relative mb-2`}>
        <FormGroup>
          <Label>{t(language)}</Label>
          <Select isDisabled defaultValue={LANGUAGES_DROPDOWN[0]} options={[LANGUAGES_DROPDOWN[0]]} />
        </FormGroup>
        <FormGroup>
          <Label>{t(description)}</Label>

          <Controller
            name="descriptionInputTr"
            control={control}
            defaultValue={defaultValue?.find((item) => item.language === LANGUAGES_DROPDOWN[0].value)?.description}
            rules={{
              required: `${t(please)} ${t(fill)} ${t(inputs)}`,
              pattern: { value: NON_EMPTY_VALUES, message: 'Please Enter Valid Title' },
            }}
            render={({ field: { onChange } }) => {
              return (
                <Input
                  type="textarea"
                  defaultValue={
                    defaultValue?.find((item) => item.language === LANGUAGES_DROPDOWN[0].value)?.description
                  }
                  onBlur={(e) => {
                    onChange(e);
                    handleChangeValue(LANGUAGES_DROPDOWN[0].value, e.target.value);
                  }}
                  placeholder={placeholder}
                  name="descriptionInput"
                />
              );
            }}
          />
        </FormGroup>
        <FormFeedback>{errors?.language?.message || errors?.descriptionInputTr?.message}</FormFeedback>
      </FormGroup>

      <FormGroup className={`p-4 flex-grow-1 rounded bg-gray position-relative mb-2`}>
        <FormGroup>
          <Label>{t(language)}</Label>
          <Select isDisabled defaultValue={LANGUAGES_DROPDOWN[1]} options={[LANGUAGES_DROPDOWN[1]]} />
        </FormGroup>
        <FormGroup>
          <Label>{t(description)}</Label>
          <Controller
            name="descriptionInputEn"
            control={control}
            defaultValue={defaultValue?.find((item) => item.language === LANGUAGES_DROPDOWN[1].value)?.description}
            rules={{
              required: `${t(please)} ${t(fill)} ${t(inputs)}`,
              pattern: { value: NON_EMPTY_VALUES, message: 'Please Enter Valid Title' },
            }}
            render={({ field: { onChange } }) => {
              return (
                <Input
                  type="textarea"
                  defaultValue={
                    defaultValue?.find((item) => item.language === LANGUAGES_DROPDOWN[1].value)?.description
                  }
                  onBlur={(e) => {
                    onChange(e);
                    handleChangeValue(LANGUAGES_DROPDOWN[1].value, e.target.value);
                  }}
                  placeholder={placeholder}
                  name="descriptionInput"
                />
              );
            }}
          />
        </FormGroup>
        <FormFeedback>{errors?.language?.message || errors?.descriptionInputEn?.message}</FormFeedback>
      </FormGroup>
    </div>
  );
};

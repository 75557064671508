import { gql } from '@apollo/client';

export const GET_DELETED_USERS_LIST = gql`
  query GetDeletedUsers($page: Int, $size: Int, $userType: UserTypeEnum, $byAdmin: Boolean, $query: String) {
    findAllDeletedUsers(
      userListDto: { page: $page, size: $size, userType: $userType, byAdmin: $byAdmin, query: $query }
    ) {
      count
      result {
        userId
        companyId
        phone
        email
        companyName
        fullName
        profilePercentage
        userType
        imageUrl
        registerDate
        blueMark
        deletedByAdmin
        isBanned
        deletedAt
        insertedReason
        reason
      }
    }
  }
`;

//@ts-nocheck

import en from 'assets/i18n/en.json';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
//import tr from 'assets/i18n/tr.json';

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    // have a common namespace used around the full app
    keySeparator: false, // we use content as keys
    resources: {
      /*   tr: {
        translation: tr,
      }, */
      en: {
        translation: en,
      },
    },
  })
  .then();

export const T = i18n.t;
export default i18n;

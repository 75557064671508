import React from 'react';
import PagesLayout from 'pages/components/layout';
import { Col } from 'reactstrap';

const UserDetails = () => {
  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <PagesLayout>User Details</PagesLayout>
    </Col>
  );
};
export default UserDetails;

import useQuery from 'hooks/useQuery';
import { GET_SINGLE_MAGAZINE } from 'services/graphql/queries/magazine';
import { useParams } from 'react-router-dom';

export default function useMagazineDetail() {
  const { type } = useParams();
  const { data, loading, error } = useQuery(GET_SINGLE_MAGAZINE, {
    variables: {
      id: parseInt(type as string),
    },
  });

  return { data: data?.FindOneMagazine, loading, error };
}

import { gql } from '@apollo/client';

export const GET_ALL_SIZES = gql`
  query GetAllSizes {
    getCompanySize {
      id
      fromStaffs
      toStaffs
      title
    }
  }
`;
export const ADD_COMPANY_SIZE = gql`
  mutation AddCompanySize($title: String!, $fromStaffs: Int!, $toStaffs: Int!) {
    createCompanySize(data: { title: $title, fromStaffs: $fromStaffs, toStaffs: $toStaffs })
  }
`;
export const UPDATE_COMPANY_SIZE = gql`
  mutation UpdateCompanySize($id: Float!, $fromStaffs: Int!, $toStaffs: Int!, $title: String!) {
    updateCompanySize(id: $id, data: { fromStaffs: $fromStaffs, toStaffs: $toStaffs, title: $title })
  }
`;
export const GET_ONE_COMPANY_SIZE = gql`
  query GetOneCompanySize($id: Float!) {
    getCompanySizeById(id: $id) {
      id
      title
      fromStaffs
      toStaffs
    }
  }
`;

import React from 'react';
import { Card, CardBody } from 'reactstrap';

export default function SchoolAnalytics() {
  return (
    <Card>
      <CardBody>
        <h1>Coming Soon</h1>
      </CardBody>
    </Card>
  );
}

import { gql } from '@apollo/client';

export const GET_COMPANY_LIST = gql`
  query getCompanies(
    $size: Int!
    $page: Int!
    $query: String
    $blueTickStatus: BlueMarkStatusEnum
    $guideStatus: GuideRequestStatusEnum
    $isShop: Boolean
    $sort: SortDto
  ) {
    getCompanyListAndDetails(
      data: {
        list: {
          page: $page
          size: $size
          filter: { searchQuery: $query, blueMarkStatus: $blueTickStatus, guideStatus: $guideStatus, isShop: $isShop }
          sort: $sort
        }
      }
    ) {
      listResponse {
        count
        data {
          companyName
          registrationEmail
          isBanned
          deletedByAdmin
          profilePercentage
          userId
          primaryPhone
          email
          registrationEmail
          id
          secondaryPhone
          yearOfStablish
          verifiedForShop
          guideStatus
          logoUrl
          blueMark {
            status
          }
        }
      }
    }
  }
`;
/*
*  query getCompanies($size: Int!, $page: Int!, $filter: FilterDto, $sort: SortDto) {
    getCompanyListAndDetails(data: { list: { page: $page, size: $size, filter: $filter, sort: $sort } }) {
      listResponse {
        count
        data {
          companyName
          profilePercentage
          userId
          primaryPhone
          id
          secondaryPhone
          yearOfStablish
          verifiedForRehber
          verifiedForShop
          logoUrl
          blueMark {
            status
          }
        }
      }
    }
  }*/

export const GET_COMPANY = gql`
  query getCompany($id: Int!) {
    getCompanyListAndDetails(data: { id: $id }) {
      singleResponse {
        id
        profilePercentage
        primaryPhone
        primaryPhoneCode
        secondaryPhone
        fullName
        secondaryPhoneCode
        email
        registrationEmail
        userId
        companyName
        yearOfStablish
        verifiedForShop
        guideStatus
        guideRequestHistory {
          id
          status
          reason
          createdAt
        }
        blueMark {
          id
          status
          updatedAt
          createdAt
          logs {
            id
            status
            description
            createdAt
          }
        }
        companySize {
          id
          fromStaffs
          toStaffs
          title
        }
        workingDaysAndHours {
          saturday
          endAt
          friday
          startAt
          sunday
          monday
          thursday
          tuesday
          wednesday
        }
        specialities {
          imageUrl
          originalName
          parentId
          _id
          translation {
            language
            translate
          }
          subCategories {
            imageUrl
            originalName
            parentId
            _id
            subCategories {
              imageUrl
              originalName
              parentId
              _id
              subCategories {
                imageUrl
                originalName
                parentId
                _id
                subCategories {
                  imageUrl
                  originalName
                  parentId
                  _id
                  subCategories {
                    imageUrl
                    originalName
                    parentId
                    _id
                    subCategories {
                      imageUrl
                      originalName
                      parentId
                      _id
                      subCategories {
                        imageUrl
                        originalName
                        parentId
                        _id
                        subCategories {
                          imageUrl
                          originalName
                          parentId
                          _id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        addresses {
          _id
          title
          city
          county

          postalCode
          fullAddress
        }
        documents {
          id
          url
          isAccepted
          rejectedReason
          documentType {
            id
          }
        }
        dontHaveASocialMedia
        socialMedia {
          id
          name
          url
        }
        websiteUrl
        logoUrl
        description
      }
    }
  }
`;

export const GET_BLUE_TICK = gql`
  query getBlueTick($id: Int!) {
    getCompanyListAndDetails(data: { id: $id }) {
      singleResponse {
        id
        logoUrl
        companyName
        blueMark {
          id
          status
          updatedAt
          createdAt
          logs {
            id
            status
            description
            createdAt
          }
        }
      }
    }
  }
`;
export const BLUE_TICK_ACTION = gql`
  mutation blueTickAction($id: Int!, $status: BlueMarkStatusEnum!, $description: String!) {
    changeBlueMarkStatus(data: { companyId: $id, status: $status, description: $description })
  }
`;

export const UPDATE_IS_STORE = gql`
  mutation updateIsStore($id: Int!, $isShop: Boolean!) {
    updateToShopOrCreateNewOwnerAsShop(bazaarShopDto: { id: $id, isShop: $isShop }) {
      id
    }
  }
`;

export const GET_COMPANY_DETAIL_BY_USER_ID = gql`
  query getCompanyDetailByUserId($userId: Float!) {
    getCompanyDetailsByUserId(userId: $userId) {
      id
      profilePercentage
      primaryPhone
      fullName
      primaryPhoneCode
      email
      registrationEmail
      companyName
      yearOfStablish
      verifiedForRehber
      verifiedForShop
      blueMark {
        id
        status
        updatedAt
        createdAt
        logs {
          id
          status
          description
          createdAt
        }
      }
      companySize {
        id
        fromStaffs
        toStaffs
        title
      }
      workingDaysAndHours {
        saturday
        endAt
        friday
        startAt
        sunday
        monday
        thursday
        tuesday
        wednesday
      }
      specialities {
        imageUrl
        originalName
        parentId
        _id
        translation {
          language
          translate
        }
        subCategories {
          imageUrl
          originalName
          parentId
          _id
          subCategories {
            imageUrl
            originalName
            parentId
            _id
            subCategories {
              imageUrl
              originalName
              parentId
              _id
              subCategories {
                imageUrl
                originalName
                parentId
                _id
                subCategories {
                  imageUrl
                  originalName
                  parentId
                  _id
                  subCategories {
                    imageUrl
                    originalName
                    parentId
                    _id
                    subCategories {
                      imageUrl
                      originalName
                      parentId
                      _id
                      subCategories {
                        imageUrl
                        originalName
                        parentId
                        _id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      addresses {
        _id
        title
        city
        county

        postalCode
        fullAddress
      }
      documents {
        id
        url
        isAccepted
        rejectedReason
        documentType {
          id
          isRequired
          isHidden
          language {
            name
            language
          }
        }
      }
      dontHaveASocialMedia
      socialMedia {
        id
        name
        url
      }
      websiteUrl
      logoUrl
      description
    }
  }
`;

export const GET_BLUE_MARK_BY_USER_ID = gql`
  query GetBlueMarkByUserId($userId: Float!) {
    getCompanyDetailsByUserId(userId: $userId) {
      id
      logoUrl
      companyName
      blueMark {
        id
        status
        updatedAt
        createdAt
        logs {
          id
          status
          description
          createdAt
        }
      }
    }
  }
`;

export const GET_COMPANY_NOTE_LIST = gql`
  query getAdminNotesOnCompany($size: Int!, $page: Int!, $companyId: Int!) {
    getAdminNotesOnCompany(getAdminNotesOnCompanyDto: { size: $size, page: $page, companyId: $companyId }) {
      count
      data {
        id
        text
        creator {
          id
          firstName
          lastName
        }
        createdAt
        updatedAt
        isMyNote
      }
    }
  }
`;

export const UPDATE_COMPANY_NOTE = gql`
  mutation updateAdminNoteOnCompany($text: String!, $id: Int!) {
    updateAdminNoteOnCompany(updateAdminNotificationOnCompany: { text: $text, id: $id }) {
      id
      text
      creator {
        id
        firstName
        lastName
      }
    }
  }
`;

export const CREATE_COMPANY_NOTE = gql`
  mutation createAdminNoteOnCompany($companyId: Int!, $text: String!) {
    createAdminNoteOnCompany(createAdminNoteOnCompanyDto: { companyId: $companyId, text: $text }) {
      id
      text
      creator {
        id
        firstName
        lastName
      }
    }
  }
`;

export const DELETE_COMPANY_NOTE = gql`
  mutation deleteAdminNoteOnCompany($id: Int!) {
    deleteAdminNoteOnCompany(onlyIdDto: { id: $id }) {
      message
    }
  }
`;

import { useTranslation } from 'react-i18next';
import useQuery from 'hooks/useQuery';
import { GET_COMPANY_NOTE_LIST } from 'services/graphql/queries/companies';
import { t_blueTickStatus } from '../../../../tools/types';
import { BLUE_TICKET_STATUS } from 'tools/enums';

export type companyNoteItemType = {
  createdAt: number;
  creator: { id: number; firstName: string; lastName: string };
  id: number;
  text: string;
  updatedAt: number;
};
export type filter = {
  blueTickStatus: BLUE_TICKET_STATUS | undefined;
  sortType: 'ASC' | 'DESC' | undefined;
  sortBy: 'createdAt' | 'profilePercentage' | 'companyName' | undefined;
  isInGuide?: boolean | null;
  isShop?: boolean | null;
};

type PropsType = {
  companyId?: number | null;
};

export default function useCompanyNotes({ companyId }: PropsType) {
  // if (!companyId) return {};
  const { data, loading, error, refetch } = useQuery<
    { getAdminNotesOnCompany: { data: companyNoteItemType[]; count: number } },
    any
  >(GET_COMPANY_NOTE_LIST, {
    variables: {
      page: 1,
      size: 15,
      companyId,
    },
  });
  const { t } = useTranslation();
  return {
    loading,
    refetch,
    error,
    t,
    data: data?.getAdminNotesOnCompany?.data,
    count: data?.getAdminNotesOnCompany?.count,
  };
}

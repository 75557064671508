import React, { Fragment } from 'react';

const RightBar = () => {
  /* const LanguageSelection = (language) => {
    if (language) {
      setLangdropdown(!language);
    } else {
      setLangdropdown(!language);
    }
  };*/

  /*  const MoonlightToggle = (light: boolean) => {
    if (light) {
      setMoonlight(!light);
      document.body.classList.add('light');
      document.body.classList.remove('dark-only');
      // document.body.className = "light"
      localStorage.setItem('layout_version', 'light');
    } else {
      setMoonlight(!light);
      document.body.classList.remove('light');
      document.body.classList.add('dark-only');
      // document.body.className = "dark-only"
      localStorage.setItem('layout_version', 'dark-only');
    }
  };*/

  /*  const logout = () => {
    deleteCookie('token');
    deleteCookie('message');
    deleteCookie('refreshToken');
    window.location.reload();
  };*/

  return (
    <Fragment>
      {/* <div className="nav-right col-8 pull-right right-header p-0">
        <ul className="nav-menus m-0">
          <li className="language-nav">
            <div className={`translate_wrapper ${langdropdown ? 'active' : ''}`}>
              <div className="current_lang">
                <div className="lang" onClick={() => LanguageSelection(langdropdown)}>
                  <i className={`flag-icon flag-icon-${currentLanguage === 'en' ? 'us' : 'tr'}`}></i>
                  <span className="lang-txt">{currentLanguage}</span>
                </div>
              </div>
              <div className={`more_lang ${langdropdown ? 'active' : ''}`}>
                <div className="lang" onClick={() => changeLanguage('en')}>
                  <i className="flag-icon flag-icon-us"></i>
                  <span className="lang-txt">
                    {English}
                    <span> {'(US)'}</span>
                  </span>
                </div>

                <div className="lang" onClick={() => changeLanguage('tr')}>
                  <i className="flag-icon flag-icon-tr"></i>
                  <span className="lang-txt">
                    {Turkish}
                    <span> {'(TR)'}</span>
                  </span>
                </div>
              </div>
            </div>
          </li>

          <li>
            <div className="mode" onClick={() => MoonlightToggle(moonlight)}>
              {moonlight ? <i className="fa fa-lightbulb-o"></i> : <i className="fa fa-moon-o"></i>}
            </div>
            <div className="mode" onClick={() => MoonlightToggle(moonlight)}>
              <i className={`fa ${moonlight ? 'fa-lightbulb-o' : 'fa-moon-o'}`}></i>
            </div>
          </li>
          <div className="d-flex align-items-center">
            <h6 className="mr-2 mt-2"> {t(admin)} </h6>
            <img src={Logo} alt={`${appName}-logo`} width="40px" height="40px" />
          </div>
        </ul>
      </div>*/}
    </Fragment>
  );
};
export default RightBar;

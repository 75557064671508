const actions = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',
  LOGIN_INFO: 'LOGIN_INFO',
  SCHOOL_INFO: 'SCHOOL_INFO',
  LOG_OUT: 'LOG_OUT',
};

export type t_userActions = keyof typeof actions;

export default actions;

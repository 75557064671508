import React from 'react';
import Select from 'react-select';
import { t_countryList, t_select } from 'tools/types';
import useQuery from 'hooks/useQuery';
import { GET_COUNTRY_LIST } from 'services/graphql/queries/App';

type ddItemCountry = t_countryList & { label: string; value: string };

export function CountryDropdown(props: t_select<ddItemCountry>) {
  const { onSelect, defaultValue, ...rest } = props;
  const { data, loading, error, refetch } = useQuery(GET_COUNTRY_LIST);
  const makeOption = () => {
    return (
      (data?.countryList as t_countryList[])?.map((country) => ({
        ...country,
        label: country.name,
        value: country.id.toString(),
      })) || []
    );
  };
  const handleFocus = async () => {
    if (error) {
      await refetch();
    }
  };
  return (
    <Select
      {...rest}
      isLoading={loading}
      onFocus={handleFocus}
      defaultValue={defaultValue}
      options={makeOption()}
      onChange={(val) => onSelect?.(val as ddItemCountry)}
    />
  );
}

export default CountryDropdown;

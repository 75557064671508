import { useQuery } from 'hooks';
import { GET_ALL_EMLAK_CATEGORIES } from 'services/graphql/queries/emlak/categories';
import { useMemo } from 'react';
import { d_getTreeData } from 'store/tree/tree.dispatchs';
import { isTrophy } from 'tools/methods';
import { t_emlakCategory } from 'pages/content-management/emlak/categories/type';

export default function useEmlakCategories() {
  const { data, loading, error, refetch, fetchMore } = useQuery(GET_ALL_EMLAK_CATEGORIES);
  const realData = useMemo(() => {
    const TData = data?.realEstateFindAllCategories || [];
    d_getTreeData(TData);
    return TData;
  }, [data?.realEstateFindAllCategories]);
  const isEmptyList = !isTrophy(realData);
  return { loading, data: realData as t_emlakCategory[], isEmptyList, error, refetch, fetchMore };
}

import { t_toggleValueFunc } from 'hooks';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, CardHeader, Modal, Progress } from 'reactstrap';
import { SaveChanges } from 'tools/i18n/constants';
import { In, cancel, done, sent } from 'tools/i18n/constants/i18n';
import { timer } from 'tools/types';

interface CounterProps {
  isOpen: boolean;
  toggleOpen: t_toggleValueFunc;
  onFinishCounter(): void;
  saveOnCounter?: boolean;
}
const TIMEOUT = 10;

const Counter: FC<CounterProps> = (props) => {
  const { isOpen, toggleOpen, onFinishCounter, saveOnCounter } = props;
  const { t } = useTranslation();
  const [counter, setCounter] = useState({
    value: TIMEOUT,
    displayValue: '30',
  });

  useEffect(() => {
    let interval: timer | undefined = undefined;
    if (isOpen) {
      interval = setInterval(() => {
        setCounter((prev) => {
          if (prev.value === 0) {
            onFinishCounter();
            clearInterval(interval);
            toggleOpen();
            return {
              value: TIMEOUT,
              displayValue: '00',
            };
          }
          return {
            value: prev.value - 1,
            displayValue: `${prev.value < 10 ? `0${prev.value - 1}` : prev.value - 1}`,
          };
        });
      }, 1000);
    } else {
      setCounter({
        value: TIMEOUT,
        displayValue: '10',
      });
      clearInterval(interval);
    }

    return () => {
      setCounter({
        value: TIMEOUT,
        displayValue: '10',
      });
      clearInterval(interval);
    };
  }, [isOpen]);

  return (
    <Modal unmountOnClose isOpen={isOpen} toggleOpen={toggleOpen} title={`${t(SaveChanges)}`}>
      <Card className="m-0 p-0">
        <CardHeader>
          <div className="d-flex align-items-center flex-column">
            <div className="flex-column d-flex justify-content-center">
              <Progress
                className="mx-auto"
                value={counter.value}
                max={TIMEOUT}
                style={{ width: '150px', height: '10px' }}
                striped
              />
            </div>
            <div className="d-flex mt-3">
              <h6>{`${t(sent)} ${t(In)}: `}</h6>
              <b className="mx-auto ml-1">{counter.displayValue}</b>
            </div>
          </div>
        </CardHeader>
        <CardBody className="mt-3 mx-auto w-100 pb-3">
          <div className="d-flex align-items-center">
            <Button type="button" color="danger" className="mx-auto d-block" onClick={() => toggleOpen()}>
              {`${t(cancel)}`}
            </Button>
            {saveOnCounter && (
              <Button
                type="button"
                color="primary"
                className="ml-2 mx-auto d-block"
                onClick={() => {
                  toggleOpen();
                  onFinishCounter();
                }}
              >
                {`${t(done)}`}
              </Button>
            )}
          </div>
        </CardBody>
      </Card>
    </Modal>
  );
};

export default Counter;

import React from 'react';
import { ReactNode, useEffect } from 'react';
import useSchoolDashboard from 'pages/schoolDashboard/useSchoolDashboard';
import { d_schoolInfo } from 'store/user/user.dispatchs';
import { Loading } from 'components';

interface DashboardSchoolLayoutProps {
  children?: ReactNode;
}

export default function DashboardSchoolLayout(props: DashboardSchoolLayoutProps) {
  const { data } = useSchoolDashboard();
  useEffect(() => {
    if (data) {
      d_schoolInfo(data);
    }
  }, [data]);
  return <Loading loading={!data}>{props.children}</Loading>;
}

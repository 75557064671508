import { Button } from 'reactstrap';
import { remove } from 'tools/i18n/constants/i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'components/icons';

interface RemoveButtonProps {
  onClick?: VoidFunction;
}

export default function RemoveButton(props: RemoveButtonProps) {
  const { t } = useTranslation();
  const { onClick } = props;
  return (
    <Button onClick={onClick} className="rounded d-flex px-3" color="danger">
      <span className="mr-2">
        <Icon Name="FiTrash" />
      </span>
      <span>{t(remove)}</span>
    </Button>
  );
}

import { ApolloError } from '@apollo/client';
import { ApolloQueryResult } from '@apollo/client/core';
import DefaultCompanyIcon from 'assets/images/buluro/dashboard/Company default.svg';
import NewIcon from 'assets/images/buluro/dashboard/New.svg';
import SecondHandIcon from 'assets/images/buluro/dashboard/Secondhand.svg';
import DefaultIcon from 'assets/images/buluro/dashboard/default-bazzar.svg';
import WalletIcon from 'assets/images/buluro/dashboard/empty-wallet.svg';
import { Loading } from 'components';
import Icon from 'components/icons';
import { BlueTickIcon } from 'components/icons/customeIcon';
import ImgToBackGround from 'components/imgToBackground';
import Link from 'components/link';
import ShowError from 'components/showError';
import DataTables, { TableSectionTitle } from 'components/tables/dataTable';
import { useToggle } from 'hooks';
import FilterBazaarAds, { t_defaultValues } from 'pages/ads/bazzarAds/filter';
import useBazaarAds, { t_dataTypes } from 'pages/ads/bazzarAds/useBazzarAds';
import PagesLayout from 'pages/components/layout';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody } from 'reactstrap';
import Styled from 'styled-components';
import { APP_HELPER, BAZAAR_ADS_STATUS, bazaarAdsEnum } from 'tools/enums';
import { Location } from 'tools/i18n/constants';
import { ads, detail, list, publish, status, time, title } from 'tools/i18n/constants/i18n';
import { removeCityFromCounty, toDate, toUpperCase } from 'tools/methods';

type result = { findAllBazaarPosters: { count: number; data: t_dataTypes[] } };

const ImageContainer = Styled.div`
  min-width : 160px;
  min-height : 160px;
`;

export default function Ads() {
  const { loading, error, data, fetchMore } = useBazaarAds();
  return (
    <Loading loading={loading}>
      <PagesLayout>
        <Card>
          <CardBody className="p-0">
            <AdsTable data={data?.data} count={data?.count} loading={loading} error={error} fetchMore={fetchMore} />
          </CardBody>
        </Card>
      </PagesLayout>
    </Loading>
  );
}

interface AdsTableProps {
  fetchMore: any;
  loading: boolean;
  data: t_dataTypes[];
  error?: ApolloError;
  count?: number;
}

function AdsTable(props: AdsTableProps) {
  const { data = [], count = 0, fetchMore, loading } = props;
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [activeFilters, setActiveFilters] = useState<t_defaultValues | undefined>(undefined);
  const [realData, setRealData] = useState<{ data: t_dataTypes[]; count: number }>({ data, count });
  const [disabled, toggleDisabled] = useToggle(false);
  const onPageChange = useCallback(
    async (page: number, size: number = APP_HELPER.tableDefaultSize) => {
      setError('');

      const vars = { skip: (page - 1) * size, take: size, ...activeFilters };
      fetchMore({
        variables: vars,
      })
        .then((res: ApolloQueryResult<result>) => {
          if (res?.data?.findAllBazaarPosters) {
            setRealData(res.data.findAllBazaarPosters);
          } else {
            setRealData({ count: 0, data: [] });
            // @ts-ignore
            setError(res?.errors?.message);
          }
        })
        .catch((err: ApolloError) => {
          setRealData({ count: 0, data: [] });
          setError(err?.message);
        });
    },
    [activeFilters]
  );

  const onSearch = useCallback(
    (key: string) => {
      setError('');
      if (key.length === 0 || !key) {
        fetchMore({
          ...activeFilters,
        })
          .then((res: { data: { findAllBazaarPosters: { count: number; data: t_dataTypes[] } } }) => {
            setRealData(res.data?.findAllBazaarPosters);
          })
          .catch(console.error);
      } else if (key.length >= 3) {
        const vars = {
          search: key,
          ...activeFilters,
        };
        fetchMore({
          variables: vars,
        })
          .then((res: ApolloQueryResult<result>) => {
            console.log(res);
            if (res?.data?.findAllBazaarPosters) {
              setRealData(res.data.findAllBazaarPosters);
            } else {
              setRealData({ count: 0, data: [] });
              // @ts-ignore
              setError(res?.errors?.message);
            }
          })
          .catch((err: ApolloError) => {
            setRealData({ count: 0, data: [] });
            setError(err?.message);
          });
      }
    },
    [activeFilters]
  );
  const onFilter = (data: t_defaultValues | undefined) => {
    setError('');
    toggleDisabled(true);
    if (data) {
      if (data.county?.length) {
        data['city'] = undefined;
      } else {
        data['county'] = undefined;
      }
      data['categoryId'] = data?.category;
      data['posterType'] = data.type;
      if (data && !data.onlyWithNoPrice) {
        data['currencySlug'] = data.unit;
        data['priceFrom'] = data.priceMin;
        data['priceTo'] = data.priceMax;
      }
    }

    setActiveFilters(data);
    fetchMore({
      variables: data,
    })
      .then((res: any) => {
        setRealData(res.data?.findAllBazaarPosters);
        if (res?.errors) {
          setError(res?.errors?.message);
        }
        toggleDisabled(false);
      })
      .catch((err: any) => {
        toggleDisabled(false);
        console.log(err);
        setError(err?.message);
      });
  };
  const columnData = useMemo(() => {
    const iconSize = '18px';
    return [
      {
        name: <TableSectionTitle name={'FiFileText'} title={t(title)} />,
        center: false,
        width: '400px',
        cell: (r: t_dataTypes) => {
          return (
            <Link to={'bazzarAdsDetail'} param={r?.id?.toString()} className=" text-black">
              <div className="d-flex text-black py-2 table-cell-container">
                <ImageContainer className="rounded mr-2 border-light-gray">
                  {/*@ts-ignore */}
                  <ImgToBackGround src={r?.pictures?.[0]?.fileUrl || DefaultIcon} />
                </ImageContainer>
                <div className=" d-flex flex-column justify-content-between py-2">
                  <div className="d-flex table-cell-container align-items-center mb-2">
                    <div className="img-sm">
                      <img
                        className=" rounded-circle"
                        alt={`${r?.ownerInfo?.name}`}
                        src={r.ownerInfo?.picture || DefaultCompanyIcon}
                      />
                    </div>
                    <span className="ml-2">{r?.ownerInfo?.name}</span>
                    {r?.ownerInfo?.blueMark && (
                      <span className="ml-2">
                        <BlueTickIcon />
                      </span>
                    )}
                  </div>
                  <div className="d-flex mb-2">
                    <div style={{ minWidth: '20px' }}>
                      <Icon size={iconSize} Name="FiGrid" />
                    </div>
                    <span className="ml-2">{r?.title}</span>
                  </div>
                  <div className="d-flex mb-2">
                    <img
                      alt={r?.type}
                      width="20px"
                      height="20px"
                      src={toUpperCase(r?.type) === bazaarAdsEnum.New ? NewIcon : SecondHandIcon}
                    />
                    <span className="ml-2">{toUpperCase(r?.type)}</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <img alt={r?.type} width="20px" height="20px" src={WalletIcon} />
                    <span className="ml-2 whitespace-nowrap">{`${
                      r?.price && r?.currency?.name && r?.currency?.symbol
                        ? new Intl.NumberFormat(undefined, {
                            useGrouping: true,
                          }).format(r?.price)
                        : 'Unpriced'
                    } ${r?.currency?.symbol || ''}`}</span>
                  </div>
                </div>
              </div>
            </Link>
          );
        },
      },
      {
        name: <TableSectionTitle name="FaRegMap" title={t(Location)} />,
        center: false,
        cell: (r: t_dataTypes) => {
          const firstAddress = r.addresses[0];
          return (
            <div className="text-left">
              <span>{`${toUpperCase(firstAddress?.city)}, ${removeCityFromCounty(
                toUpperCase(firstAddress?.county)
              )}`}</span>
              {r.addresses.length > 1 && (
                <>
                  <br />
                  <br />
                  <span> {`+${r.addresses.length - 1} more`} </span>
                </>
              )}
            </div>
          );
        },
      },
      {
        name: <TableSectionTitle name="FiGrid" title={`${t(publish)} ${t(time)}`} />,
        center: false,
        selector: (r: t_dataTypes) => toDate(r?.publishedAt as string),
      },
      {
        name: <TableSectionTitle name="FiType" title={t(status)} />,
        center: false,
        cell: (r: t_dataTypes) => {
          const selectClassName = () => {
            switch (r?.status) {
              case BAZAAR_ADS_STATUS.ACTIVE:
                return 'shadow-bg-success';
              case BAZAAR_ADS_STATUS.DRAFT:
                return 'shadow-bg-notActive';
              case BAZAAR_ADS_STATUS.BAN:
                return 'shadow-bg-danger';
              case BAZAAR_ADS_STATUS.EXPIRED:
                return 'shadow-bg-danger';
              default:
                return 'shadow-bg-notActive';
            }
          };
          return (
            <div className={`p-2 rounded ${selectClassName()}`}>{r.status ? BAZAAR_ADS_STATUS[r?.status] : ''}</div>
          );
        },
      },
      {
        name: '',
        center: false,
        cell: (r: t_dataTypes) => {
          return (
            <Link className="text-white ml-auto" to={'bazzarAdsDetail'} param={r?.id?.toString()}>
              <Button color="primary">{t(detail)}</Button>
            </Link>
          );
        },
      },
    ];
  }, []);

  return (
    <div className="d-flex flex-column">
      {!!error && (
        <div className="mb-4">
          <ShowError>{error}</ShowError>
        </div>
      )}
      <DataTables
        searchable
        headerTitle={`${t(ads)} ${t(list)}`}
        data={realData?.data}
        columns={columnData}
        filterable
        onSearch={onSearch}
        paginationTotalRows={realData?.count}
        onChangePage={onPageChange}
        onChangeRowsPerPage={(size, currentPage) => onPageChange(currentPage, size)}
        pagination
        customFilter={<FilterBazaarAds loading={disabled || loading} onFilter={onFilter} />}
      />
    </div>
  );
}

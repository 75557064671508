import React, { FC } from 'react';
import { InputProps } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { search } from 'tools/i18n/constants/i18n';
import Icon from 'components/icons';

type SearchInputProps = InputProps;

export const SearchInput: FC<SearchInputProps> = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex">
      <div className="d-flex align-items-center justify-content-between bg-gray">
        <Icon Name="FiSearch" />
        <input className="form-control" type="search" placeholder={t(search)} {...props} />
      </div>
    </div>
  );
};

export default SearchInput;

import Datepicker from 'components/forms/form-widget/datepicker';
import { FilterAdsCommonProps } from 'pages/ads/bazzarAds/filter/index';
import SectionLayout from 'pages/ads/jobAds/components/layout/sectionLayout';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { date, publish } from 'tools/i18n/constants/i18n';

type BazaarAdsDateOfPublishProps = FilterAdsCommonProps;

export default function BazaarAdsDateOfPublish(props: BazaarAdsDateOfPublishProps) {
  const { control } = props;
  const { t } = useTranslation();
  return (
    <SectionLayout title={`${t(publish)} ${t(date)}`} icon="AiOutlineCalendar">
      <div className="d-flex">
        <div className="flex-grow-1 mr-2">
          <Controller
            control={control}
            name="entrance"
            render={({ field: { onChange } }) => {
              return (
                <Datepicker
                  onSelect={(date) => {
                    if (date) {
                      onChange(date.split('-').reverse().join('-'));
                    }
                  }}
                />
              );
            }}
          />
        </div>
        <div className="flex-grow-1">
          <Controller
            control={control}
            name={'end'}
            render={({ field: { onChange } }) => {
              return (
                <Datepicker
                  onSelect={(date) => {
                    if (date) {
                      onChange(date.split('-').reverse().join('-'));
                    }
                  }}
                />
              );
            }}
          />
        </div>
      </div>
    </SectionLayout>
  );
}

import { t_redux_action } from 'tools/types';
import actions from 'store/user/user.actionTypes';
import { EDUCATION_GRADE, Permission, ROLES_BASED_BACKEND } from 'tools/enums';

export type schoolInfo = {
  _id: string | null;
  name: string | null;
  city: string | null;
  county: string | null;
  logo: string | null;
  postalCode: string | null;
  bio: string | null;
  websiteUrl: string | null;
  grade: EDUCATION_GRADE | null;
  fullAddress: string | null;
  contactPhoneCode: string | null;
  contactPhoneNumber: string | null;
  contactEmail: string | null;
  studentCountCapacity: string | null;
  uniqueCode: string | null;
  isRegistered: boolean | null;
  isActive: boolean | null;
  isVisible: boolean | null;
  user: {
    id: number;
    firstName: string;
    lastName: string;
    mail: string;
    phoneNumber: string;
  };
};

export type t_initialState = {
  isLogged: boolean | null;
  userInfo: {
    firstName: undefined | string;
    lastName: undefined | string;
    email: undefined | string;
    role: undefined | ROLES_BASED_BACKEND[];
    permissions: undefined | Permission[];
    schoolId?: number;
    schoolInformation?: schoolInfo;
  };
};

const INITIAL_STATE: t_initialState = {
  isLogged: null,
  userInfo: {
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    role: undefined,
    permissions: undefined,
  },
};

export default function userReducer(state = INITIAL_STATE, action: t_redux_action<keyof typeof actions, any>) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return { ...state, isLogged: true };
    case 'LOGIN_INFO':
      return {
        ...state,
        userInfo: action.payload,
      };
    case 'SCHOOL_INFO':
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          schoolInformation: action.payload,
        },
      };
    case 'LOGIN_FAIL':
      return { ...state, isLogged: false };
    case 'LOG_OUT':
      return {
        isLogged: false,
        userInfo: {
          email: undefined,
          firstName: undefined,
          lastName: undefined,
          role: undefined,
        },
      };
    default:
      return state;
  }
}

import { gql } from '@apollo/client';

export const GET_ALL_EMLAK_CATEGORIES = gql`
  query getAllEmlakCategories($query: String) {
    realEstateFindAllCategories(realEstateCategoryListDto: { query: $query }) {
      id
      title
      categoryLanguage {
        translation
        language
      }
      categoryTypes {
        id
        title
      }
      status
      icon
      child {
        id
        title
        parentId
        categoryLanguage {
          translation
          language
        }
        categoryTypes {
          id
          title
        }
        status
        icon
        child {
          id
          title
          parentId
          categoryLanguage {
            translation
            language
          }
          categoryTypes {
            id
            title
          }
          status
          icon
          child {
            id
            title
            parentId
            categoryLanguage {
              translation
              language
            }
            categoryTypes {
              id
              title
            }
            status
            icon
          }
        }
      }
    }
  }
`;

export const CREATE_EMLAK_CATEGORY = gql`
  mutation createEmlakCategory($categoryTypes: [Int!]!, $translations: [TranslationsDto!]!, $parentCategory: Int) {
    realEstateCreateCategory(
      realEstateCreateCategoryDto: {
        parentCategory: $parentCategory
        categoryTypes: $categoryTypes
        translations: $translations
      }
    ) {
      id
    }
  }
`;

export const UPDATE_EMLAK_CATEGORY = gql`
  mutation updateEmlakCategory(
    $categoryTypes: [Int!]!
    $translations: [TranslationsDto!]!
    $parentCategory: Int
    $categoryId: Int!
    $setAsRootCategory: Boolean
  ) {
    realEstateUpdateCategory(
      realEstateUpdateCategoryDto: {
        parentCategory: $parentCategory
        categoryTypes: $categoryTypes
        translations: $translations
        categoryId: $categoryId
        setAsRootCategory: $setAsRootCategory
      }
    ) {
      id
    }
  }
`;

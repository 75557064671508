import { gql } from '@apollo/client';

export const GET_ALL_ADMINS = gql`
  query getAdmins($adminType: [AdminTypeEnum!] = [ADMIN, SUPER_ADMIN, SCHOOL_ADMIN]) {
    findAllAdmins(adminListDto: { adminType: $adminType }) {
      firstName
      lastName
      mail
      phoneNumber
      id
      roles {
        id
        title
        slug
        permission {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_SCHOOL_ADMIN = gql`
  query getAdmins($adminType: [AdminTypeEnum!] = [SCHOOL_ADMIN]) {
    findAllAdmins(adminListDto: { adminType: $adminType, schoolAdminWithNoSchool: true }) {
      firstName
      lastName
      mail
      phoneNumber
      id
      roles {
        id
        title
        slug
        permission {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_USERS = gql`
  query getAllUsers(
    $pageNumber: Int = 1
    $size: Int!
    $userType: UserTypeEnum
    $query: String
    $isOnline: Boolean
    $isBanned: Boolean
  ) {
    findAllUsers(
      userListDto: {
        page: $pageNumber
        size: $size
        userType: $userType
        query: $query
        isOnline: $isOnline
        isBanned: $isBanned
      }
    ) {
      count
      result {
        isBanned
        deletedByAdmin
        phone
        fullName
        email
        userType
        registerDate
        companyName
        profilePercentage
        userId
        imageUrl
        isOnline
        lastOnline
        companyId
        blueMark
      }
    }
  }
`;

export const ADD_NEW_ADMIN = gql`
  mutation addAdmin(
    $fn: String!
    $ln: String!
    $pc: String!
    $pn: String!
    $mail: String!
    $password: String!
    $roleSlugs: [AdminTypeEnum!]!
  ) {
    createNewAdmin(
      createAdminDto: {
        firstName: $fn
        lastName: $ln
        phoneCode: $pc
        phoneNumber: $pn
        mail: $mail
        password: $password
        roleSlugs: $roleSlugs
      }
    ) {
      phoneNumber
      id
    }
  }
`;

export const GET_ADMIN = gql`
  query getAdminById($id: Float!) {
    findOneAdmin(id: $id) {
      firstName
      lastName
      phoneCode
      phoneNumber
      mail
      password
      roles {
        id
        title
        slug
        permission {
          id
          title
          group
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_BAN_REASONS_LIST = gql`
  query GetBanReasonList {
    getAllBanReason {
      id
      needToInsertCustomText
      language {
        id
        language
        content
      }
    }
  }
`;

export const UNBAN_USER = gql`
  mutation UnbanUser($userId: Int!, $adminId: Int!) {
    unBanAccount(unBanAccountDto: { userId: $userId, adminId: $adminId })
  }
`;

export const BAN_USER = gql`
  mutation BanAccount($adminId: Int!, $userId: Int!, $reasonId: Int!, $reason: String) {
    banAccount(banAccountDto: { adminId: $adminId, userId: $userId, reasonId: $reasonId, reason: $reason })
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($adminId: Int!, $userId: Int!, $reasonId: Int!, $reason: String) {
    deleteUserAccount(
      deleteUserAccountDto: { adminId: $adminId, userId: $userId, reasonId: $reasonId, reason: $reason }
    )
  }
`;

export const BAN_HISTORY = gql`
  query BanHistory($id: Int!) {
    findUserBanHistory(onlyIdDto: { id: $id }) {
      id
      status
      adminName
      adminId
      createdAt
      insertedReason
      banReason {
        id
        needToInsertCustomText
        language {
          language
          content
        }
      }
    }
  }
`;

export const CHANGE_USER_PHONE_NUMBER = gql`
  mutation ChangeUserPhoneNumber($userId: Int!, $phoneNumber: String!, $phoneCode: String!) {
    updateEmployeePhone(data: { userId: $userId, phoneNumber: $phoneNumber, phoneCode: $phoneCode })
  }
`;

export const CHANGE_USER_EMAIL = gql`
  mutation ChangeUserEmail($email: String!, $userId: Int!) {
    updateEmployerEmail(data: { email: $email, userId: $userId })
  }
`;

export const GET_USERS_EXCELL = gql`
  query ExportUsersListExcel {
    exportUsersListExcel {
      link
    }
  }
`;

import OnlyNumber from 'components/inputs/onlyNumber';
import { FilterAdsCommonProps } from 'pages/ads/bazzarAds/filter/index';
import SectionLayout from 'pages/ads/jobAds/components/layout/sectionLayout';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { For, model, search } from 'tools/i18n/constants/i18n';

type BazaarAdsModelProps = FilterAdsCommonProps;
export default function BazaarAdsModel(props: BazaarAdsModelProps) {
  const { control } = props;
  const { t } = useTranslation();
  return (
    <SectionLayout title={t(model)} icon="FiGrid">
      <div className="d-flex flex-column">
        <OnlyNumber
          name="model"
          //@ts-ignore
          control={control}
          placeholder={`${t(search)} ${t(For)} ${t(model)}`}
        />
      </div>
    </SectionLayout>
  );
}

import { gql } from '@apollo/client';

export const GET_FUAR_LIST = gql`
  query getFuarList {
    exhibitionList {
      id
      title
      logo
      poster
      webSite
      status
      softDelete
      city {
        id
        name
      }
      country {
        id
        name
      }
      startDate
      endDate
      createdAt
    }
  }
`;

export const CREATE_FUAR = gql`
  mutation createFuar(
    $title: String!
    $startDate: Timestamp!
    $endDate: Timestamp!
    $webSite: String!
    $countryId: Int!
    $cityId: Int!
    $hotelIds: [Int!]!
    $language: [MultiLanguageDto!]!
  ) {
    createExhibition(
      createExhibitionDto: {
        title: $title
        startDate: $startDate
        endDate: $endDate
        webSite: $webSite
        countryId: $countryId
        cityId: $cityId
        hotelIds: $hotelIds
        language: $language
      }
    ) {
      id
    }
  }
`;

export const GET_SINGLE_FUAR = gql`
  query getSingleFuar($id: Int!) {
    findOneExhibition(findOneExhibitionDto: { id: $id }) {
      id
      title
      startDate
      endDate
      logo
      poster
      webSite
      status
      createdAt
      country {
        id
        name
        city {
          id
          name
        }
      }
      city {
        id
        name
      }
      hotel {
        id
        name
        lat
        lon
        location
        webSite
        language {
          description
          language
        }
      }
      language {
        description
        language
      }
    }
  }
`;

export const UPDATE_FUAR = gql`
  mutation updateFuar(
    $id: Int!
    $title: String
    $startDate: Timestamp!
    $endDate: Timestamp!
    $countryId: Int
    $cityId: Int
    $hotelIds: [Int!]
    $location: String
    $webSite: String
    $isActive: Boolean
    $language: [HotelDescriptionDto!]
  ) {
    updateExhibition(
      updateExhibitionDto: {
        id: $id
        title: $title
        startDate: $startDate
        endDate: $endDate
        countryId: $countryId
        cityId: $cityId
        isActive: $isActive
        hotelIds: $hotelIds
        location: $location
        webSite: $webSite
        language: $language
      }
    ) {
      message
    }
  }
`;

export const DELETE_FUAR = gql`
  mutation deleteFuar($id: Int!) {
    deleteExhibition(deleteExhibitionDto: { id: $id }) {
      message
    }
  }
`;

export const GET_FUAR_FILTERED_LIST = gql`
  query getFuarFilteredList {
    activeExhibitionList {
      id
      title
    }
  }
`;

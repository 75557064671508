import { gql } from '@apollo/client';

export const GET_ALL_EMLAK_ADS_REPORT_REASONS = gql`
  query GetAllEmlakAdsReportReasons {
    realEstateReportReasonList {
      count
      data {
        id
        reason
        reportReasonLanguage {
          language
          translation
        }
      }
    }
  }
`;

export const GET_ONE_EMLAK_AD_REPORT_REASON = gql`
  query GetOneEmlakAdReportReason($id: Int!) {
    realEstateFindOneReportReason(onlyIdDto: { id: $id }) {
      id
      reason
      reportReasonLanguage {
        language
        translation
      }
    }
  }
`;

export const CREATE_EMLAK_AD_REPORT_REASON = gql`
  mutation CreateEmlakAdReportReason($translations: [TranslationsReportDto!]!) {
    realEstateCreateReportReason(realEstateCreatePosterReportReasonDto: { translations: $translations }) {
      id
    }
  }
`;

export const UPDATE_EMLAK_AD_REPORT_REASON = gql`
  mutation UpdateEmlakAdReportReason($id: Int!, $translations: [TranslationsReportDto!]!) {
    realEstateUpdateReportReason(realEstateUpdatePosterReportReasonDto: { id: $id, translations: $translations }) {
      id
    }
  }
`;

import { ApolloError } from '@apollo/client';
import companyIcon from 'assets/images/buluro/dashboard/Company default.svg';
import userDefault from 'assets/images/buluro/dashboard/user-default.svg';
import { Loading } from 'components';
import { BlueTickIcon } from 'components/icons/customeIcon';
import Link from 'components/link';
import DataTables, { TableSectionTitle } from 'components/tables/dataTable';
import { useGetStore, useToggle, useUpdateEffect } from 'hooks';
import useQuery from 'hooks/useQuery';
import PagesLayout from 'pages/components/layout';
import { dropDownOption } from 'pages/components/newSectionButton';
import React, { FC, useMemo, useRef, useState } from 'react';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
import { Badge, Button, Card, Col, Modal, ModalBody } from 'reactstrap';
import { GET_USERS, GET_USERS_EXCELL } from 'services/graphql/queries/users/users.geaphql';
import { APP_HELPER, APP_ROUTE_NAME, ROUTE_PREFIXES, UserTypeEnum } from 'tools/enums';
import { New, add, detail, employer, last, list, online, user, users } from 'tools/i18n/constants/i18n';
import { toDate } from 'tools/methods';
import UsersTableCustomFilter, { t_usersTableFilter_defaultValues } from './customeFilter';
import { t_user } from './type';
import { ProfileImage, TableCellContainer, OnlineStatusBadge } from './style';
import Dropdown from 'react-multilevel-dropdown';
import Icon from 'components/icons';
import UnbanModal from 'pages/user-management/users/unbanModal';
import BanModal from 'pages/user-management/users/banModal';
import DeleteModal from 'pages/user-management/users/deleteModal';
import BanHistoryModal from 'pages/user-management/users/banHistory';
import ChangeInfoModal from './changeInfoModal';
import useDownloader from 'react-use-downloader';
import { message } from 'antd';
import Dayjs from 'dayjs';
import { formatDate } from '../../exchange/holydayManagement/utils/helper';
import { useSearchParams } from 'react-router-dom';

type TResponse = {
  count: number;
  result: t_user[] | never[];
};
const UsersTable = () => {
  const [param] = useSearchParams();
  const defaultPage = param.get('page') ? parseInt(param.get('page') as string) : APP_HELPER.tableDefaultPage;
  const defaultSize = param.get('size') ? parseInt(param.get('size') as string) : APP_HELPER.tableDefaultSize;
  const {
    error,
    data = { findAllUsers: { count: 0, result: [] } },
    refetch,
    fetchMore,
    loading,
  } = useQuery<{ findAllUsers: TResponse }>(GET_USERS, {
    defaultOptions: {
      variables: {
        pageNumber: defaultPage,
        size: defaultSize,
      },
    },
    fetchPolicy: 'no-cache',
  });
  return <Table error={error} fetchMore={fetchMore} refetch={refetch} loading={loading} data={data.findAllUsers} />;
};

interface TableProps {
  fetchMore: any;
  refetch: any;
  error?: ApolloError;
  data: TResponse;
  loading: boolean;
}

const Table: FC<TableProps> = (props) => {
  const isFirst = useRef(true);
  const { refetch, error, data, loading, fetchMore } = props;
  const [isExcellLink, toggleExcellLink] = useToggle(false);
  const { data: excellLink, loading: excellLoading } = useQuery(GET_USERS_EXCELL, {
    skip: !isExcellLink,
  });
  const { download } = useDownloader();
  const [realData, setRealData] = useState<TResponse>(data || []);
  const [currentPerRow, setCurrentPerRow] = useState(50);
  const { User } = useGetStore();
  const [realLoading, toggleRealLoading] = useToggle(false);
  const [deleteModal, setDeleteModal] = useState<{
    isOpen: boolean;
    user: t_user | undefined;
  }>({
    isOpen: false,
    user: undefined,
  });
  const [banModal, setBanModal] = useState<{
    isOpen: boolean;
    user: t_user | undefined;
  }>({
    isOpen: false,
    user: undefined,
  });
  const [unbanModal, setUnbanModal] = useState<{
    isOpen: boolean;
    user: t_user | undefined;
  }>({
    isOpen: false,
    user: undefined,
  });
  const [banHistoryModal, setBanHistoryModal] = useState<{
    isOpen: boolean;
    user: t_user | undefined;
  }>({
    isOpen: false,
    user: undefined,
  });
  const [changeInfoModal, setChangeInfoModal] = useState<{
    isOpen: boolean;
    user: t_user | undefined;
  }>({
    isOpen: false,
    user: undefined,
  });
  const { t } = useTranslation();
  const handleOpenBanModal = (selectedUser: t_user) => {
    setBanModal({
      isOpen: true,
      user: selectedUser,
    });
  };
  const handleCloseModal = () => {
    setBanModal({
      isOpen: false,
      user: undefined,
    });
  };
  const handleOpenDeleteModal = (selectedUser: t_user) => {
    setDeleteModal({
      isOpen: true,
      user: selectedUser,
    });
  };
  const handleOpenUnbanModal = (selectedUser: t_user) => {
    setUnbanModal({
      isOpen: true,
      user: selectedUser,
    });
  };
  const handleCloseUnbanModal = () => {
    setUnbanModal({
      isOpen: false,
      user: undefined,
    });
  };
  const handleCloseDeleteModal = () => {
    setDeleteModal({
      isOpen: false,
      user: undefined,
    });
  };
  const handleOpenChangeInfoModal = (selectedUser: t_user) => {
    setChangeInfoModal({
      isOpen: true,
      user: selectedUser,
    });
  };
  const handleCloseChangeInfoModal = () => {
    setChangeInfoModal({
      isOpen: false,
      user: undefined,
    });
  };
  const onFilter = (data: t_usersTableFilter_defaultValues) => {
    toggleRealLoading(true);
    const variables: any = {};
    if (data.employer && data.employee) {
      variables['userType'] = undefined;
    } else if (data.employer) {
      variables['userType'] = 'EMPLOYER';
    } else if (data.employee) {
      variables['userType'] = 'EMPLOYEE';
    }
    if (data.isOnline && data.isOffline) {
      variables['isOnline'] = undefined;
    } else if (data.isOnline) {
      variables['isOnline'] = true;
    } else if (data.isOffline) {
      variables['isOnline'] = false;
    }
    if (data.banned && data.unbanned) {
      variables['isBanned'] = undefined;
    } else if (data.banned) {
      variables['isBanned'] = true;
    } else if (data.unbanned) {
      variables['isBanned'] = false;
    }
    fetchMore({
      variables,
    })
      .then((res: any) => {
        if (res?.data?.findAllUsers) {
          setRealData(res.data.findAllUsers);
        }
      })
      .finally(() => {
        toggleRealLoading(false);
      });
  };
  const onSearch = async (key: string) => {
    toggleRealLoading(true);
    const result = await refetch({
      query: key || undefined,
    });
    if (result.data) {
      toggleRealLoading(false);
      setRealData(() => result.data.findAllUsers);
    }
  };
  const handleOpenBanHistory = (selectedUser: t_user) => {
    setBanHistoryModal({
      isOpen: true,
      user: selectedUser,
    });
  };
  const handleCloseBanHistoryModal = () => {
    setBanHistoryModal({
      isOpen: false,
      user: undefined,
    });
  };
  const handleChangeRef = () => {
    isFirst.current = true;
  };
  const columnData: any = useMemo(() => {
    return [
      {
        name: <TableSectionTitle name="FiUser" title="Full Name" />,
        center: false,
        minWidth: '350px',
        cell: (r: t_user) => {
          return (
            <TableCellContainer className="d-flex align-items-center py-3">
              <ProfileImage data-percent={r.profilePercentage ? `${r.profilePercentage}%` : '0%'}>
                <Avatar src={r?.imageUrl || userDefault} size="64px" />
              </ProfileImage>

              <div className="d-flex flex-column ml-3">
                {!!r?.companyName && (
                  <div className="d-flex align-items-center mb-1">
                    <img alt="company logo" src={companyIcon} />
                    <span className=" text-black ml-1"> {r.companyName} </span>
                    {r.blueMark && (
                      <span className="ml-2">
                        <BlueTickIcon />
                      </span>
                    )}
                  </div>
                )}
                <span className="text-gray text-md">{r.fullName}</span>
                <div className="d-flex align-items-center mt-2">
                  <OnlineStatusBadge className={`mr-2 ${r.isOnline ? 'bg-success' : 'bg-danger'}`} />
                  {!r.isOnline && (
                    <div className="d-flex align-items-center text-left justify-content-start">
                      <small className="whitespace-nowrap">{`${t(last)} ${t(online)}:`}</small>
                      <small className="ml-2 text-left whitespace-nowrap">{toDate(r.lastOnline)}</small>
                    </div>
                  )}
                </div>
              </div>
            </TableCellContainer>
          );
        },
      },
      {
        name: <TableSectionTitle name="FiPhone" title="Phone Number Or Email" />,
        minWidth: '250px',
        cell: (r: { phone?: string; email?: string; mail?: string }) => r?.phone || r?.email,
      },
      {
        name: <TableSectionTitle name="FiType" title="Type" />,
        selector: (r: { userType: string }) => (r.userType === 'EMPLOYER' ? 'Kurumsal' : 'Bireysel'),
      },
      {
        name: <TableSectionTitle name="FiCalendar" title="Registration Date" />,
        minWidth: '200px',
        cell: (r: { registerDate: string }) => toDate(r.registerDate),
      },
      {
        name: <TableSectionTitle name="AiOutlineBlock" title="Status" />,
        cell: (r: { isBanned: boolean }) => {
          return (
            <Badge color={r.isBanned ? 'danger' : 'success'}>
              <span style={{ fontSize: '14px' }}>{r.isBanned ? 'Blocked' : 'Active'}</span>
            </Badge>
          );
        },
      },
      {
        name: '',
        minWidth: '150px',
        center: true,
        cell: (r: t_user) => {
          const companyDetail = `/${APP_ROUTE_NAME.dashboard}/${ROUTE_PREFIXES.contentManagement}/${
            APP_ROUTE_NAME.companies
          }/EMPLOYER-${r.userId.toString()}`;
          return (
            <>
              {r.userType === 'EMPLOYER' ? (
                <Link className="text-white ml-auto whitespace-nowrap" to={'companiesDetails'} param={companyDetail}>
                  <Button color="primary">{t(detail)}</Button>
                </Link>
              ) : (
                <Link className="text-white ml-auto whitespace-nowrap" to="employeeDetail" param={r?.userId.toString()}>
                  <Button color="primary">{t(detail)}</Button>
                </Link>
              )}
              <Dropdown
                position="left"
                title={<Icon Name="AiOutlineMore" size="24px" />}
                buttonClassName="bg-transparent pointer border-0 shadow-0"
              >
                <Dropdown.Item
                  onClick={() => {
                    if (r.isBanned) {
                      handleOpenUnbanModal(r);
                    } else {
                      handleOpenBanModal(r);
                    }
                  }}
                >
                  <Icon Name={r.isBanned ? 'FiUser' : 'AiOutlineStop'} />
                  <span className="ml-2">{r.isBanned ? 'Unblock' : 'Block'}</span>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleOpenBanHistory(r)}>
                  <Icon Name="FaHistory" />
                  <span className="ml-2">Block History</span>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleOpenDeleteModal(r)}>
                  <Icon Name="FaTrashAlt" />
                  <span className="ml-2">Delete User</span>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleOpenChangeInfoModal(r)}>
                  <Icon Name="FaEdit" />
                  <span className="ml-2">
                    {`Change ${r.userType === UserTypeEnum.employer ? 'Email' : 'Phone Number'}`}
                  </span>
                </Dropdown.Item>
              </Dropdown>
            </>
          );
        },
      },
    ];
  }, []);
  const buttonText = `${t(add)} ${t(New)} ${t(user)}`;
  const dropdownOption: dropDownOption[] = [{ label: t(employer), value: 'employer', link: 'employer' }];
  const onPageChange = async (page: number) => {
    toggleRealLoading(true);
    const result = await refetch({
      pageNumber: page,
      size: currentPerRow,
    });
    if (result.data) {
      setRealData(() => result.data.findAllUsers);
      toggleRealLoading(false);
    }
  };
  const onChangeRowsPerPage = async (currentPerRow: number, currentPage: number) => {
    toggleRealLoading(true);
    setCurrentPerRow(currentPerRow);
    const result = await fetchMore({
      variables: {
        size: currentPerRow,
        page: currentPage,
      },
    });
    if (result.data) {
      setRealData(() => result.data.findAllUsers);
      toggleRealLoading(false);
    }
  };
  useUpdateEffect(() => {
    if (data) {
      setRealData(data);
    }
  }, [data]);

  useUpdateEffect(() => {
    if (!excellLink || excellLink) {
      if (excellLink?.exportUsersListExcel?.link) {
        const { year, month, day } = formatDate(Dayjs());
        download(
          excellLink?.exportUsersListExcel?.link,
          `buluro kullanıcılar listesi (${year}/${month}/${day}).xlsx`
        ).finally(() => {
          message.destroy();
          toggleExcellLink(false);
        });
      }
    }
  }, [excellLoading, excellLink]);
  return (
    <>
      <Modal
        unmountOnClose
        style={{ width: '50vw', maxWidth: '50vw' }}
        isOpen={banHistoryModal.isOpen}
        toggle={handleCloseBanHistoryModal}
        onClosed={handleCloseBanHistoryModal}
        title={`Block ${User?.userInfo?.companyName || User?.userInfo?.fullName} History`}
      >
        <ModalBody>
          <Card>
            <BanHistoryModal
              onClose={handleCloseBanHistoryModal}
              user={banHistoryModal.user as t_user}
              open={banHistoryModal.isOpen}
            />
          </Card>
        </ModalBody>
      </Modal>

      <Modal
        unmountOnClose
        style={{ width: '50vw', maxWidth: '50vw' }}
        isOpen={deleteModal.isOpen}
        toggle={handleCloseDeleteModal}
        onClosed={handleCloseDeleteModal}
        title={`Delete ${User?.userInfo?.companyName || User?.userInfo?.fullName}`}
      >
        <DeleteModal
          refetch={refetch}
          handleChangeRef={handleChangeRef}
          adminId={User?.userInfo?.userId as number}
          user={deleteModal.user as t_user}
          onClose={handleCloseDeleteModal}
          open={deleteModal.isOpen}
        />
      </Modal>
      <Modal
        unmountOnClose
        style={{ width: '50vw', maxWidth: '50vw' }}
        isOpen={banModal.isOpen}
        toggle={handleCloseModal}
        onClosed={handleCloseModal}
        title={`Block ${User?.userInfo?.companyName || User?.userInfo?.fullName}`}
      >
        <BanModal
          refetch={refetch}
          handleChangeRef={handleChangeRef}
          adminId={User?.userInfo?.userId as number}
          user={banModal.user as t_user}
          onClose={handleCloseModal}
          open={banModal.isOpen}
        />
      </Modal>

      <Modal
        unmountOnClose
        style={{ width: '50vw', maxWidth: '50vw' }}
        isOpen={changeInfoModal.isOpen}
        toggle={handleCloseChangeInfoModal}
        onClosed={handleCloseChangeInfoModal}
        title={`Change info for ${User?.userInfo?.companyName || User?.userInfo?.fullName}`}
      >
        <ChangeInfoModal
          adminId={User?.userInfo?.userId as number}
          user={changeInfoModal.user as t_user}
          onClose={handleCloseChangeInfoModal}
          open={changeInfoModal.isOpen}
          refetch={refetch}
        />
      </Modal>

      <UnbanModal
        refetch={fetchMore}
        handleChangeRef={handleChangeRef}
        adminId={User?.userInfo?.userId as number}
        isOpen={unbanModal.isOpen}
        onClose={handleCloseUnbanModal}
        user={unbanModal.user as t_user}
      />

      <Col xxl={11} xl={11} md={12} className="mx-auto">
        <Loading loading={loading}>
          <PagesLayout
            newSectionLink="employer"
            newSectionButtonLabel={buttonText}
            dropDownOption={dropdownOption}
            customHeader={
              <div className="d-flex align-items-center ml-2">
                <Button
                  className="d-flex align-items-center"
                  disabled={excellLoading}
                  onClick={() => {
                    toggleExcellLink();
                    message.loading('Downloading ...', 1000000000);
                  }}
                >
                  <Icon Name="FaFileCsv" />
                  <span className="ml-2">Download Users List</span>
                </Button>
              </div>
            }
          >
            <DataTables
              data={realData.result}
              paginationTotalRows={realData.count}
              headerTitle={`${t(users)} ${t(list)}`}
              filterable
              searchable
              customFilter={<UsersTableCustomFilter loading={realLoading} onFilter={onFilter} />}
              columns={columnData}
              onSearch={onSearch}
              disabled={loading || realLoading || !!error}
              onChangePage={onPageChange}
              onChangeRowsPerPage={onChangeRowsPerPage}
            />
          </PagesLayout>
        </Loading>
      </Col>
    </>
  );
};

export default UsersTable;

import { createStore, compose } from 'redux';
import reducersReducer from 'store/reducers.reducer';

// @ts-ignore
const composeEnhancers = window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// @ts-ignore
const isActiveDelTool = window?.devToolsExtension
  ? // @ts-ignore
    window.devToolsExtension()
  : function (f: any) {
      return f;
    };
const store = createStore(reducersReducer, composeEnhancers(isActiveDelTool));

export const dispatch = store.dispatch;
export const getStoreState = store.getState();

export default store;

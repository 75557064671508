import { gql } from '@apollo/client';

export const GET_JOB_CATEGORY = gql`
  query getJobCategory($page: Int = 1, $size: Int = 10000) {
    employmentBranchesAndSectionsList(paginationDto: { page: $page, size: $size }) {
      createdAt
      updatedAt
      _id
      imageUrl
      originalName
      translations {
        language
        translate
      }
      sections {
        createdAt
        updatedAt
        _id
        originalName
        branchId
        translations {
          language
          translate
        }
      }
    }
  }
`;

export const CREATE_BRANCH_JOB_CATEGORY = gql`
  mutation createJobCategory($translations: [TranslationsDto!]) {
    employmentCreateBranch(originalNameTranslationsDto: { translations: $translations }) {
      _id
    }
  }
`;

export const UPDATE_BRANCH_JOB_CATEGORY = gql`
  mutation updateJobCategory($branchId: String!, $translations: [TranslationsDto!]) {
    employmentUpdateBranch(employmentUpdateBranchDto: { branchId: $branchId, translations: $translations }) {
      _id
    }
  }
`;

export const CREATE_SECTION_JOB_CATEGORY = gql`
  mutation createSectionJobCategory($translations: [TranslationsDto!], $branchId: String!) {
    employmentCreateSection(employmentCreateSectionDto: { translations: $translations, branchId: $branchId }) {
      _id
    }
  }
`;
export const UPDATE_SECTION_JOB_CATEGORY = gql`
  mutation updateSectionJobCategory($translations: [TranslationsDto!], $sectionId: String!) {
    employmentUpdateSection(employmentUpdateSectionDto: { translations: $translations, sectionId: $sectionId }) {
      _id
    }
  }
`;

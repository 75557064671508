import { gql } from '@apollo/client';

export const GET_UNIVERSITIES = gql`
  query getUniversities($page: Int!, $size: Int!, $keyword: String) {
    employmentUniversitiesList(
      findAllUniversitiesDto: { pagination: { page: $page, size: $size }, keyword: $keyword }
    ) {
      count
      data {
        createdAt
        updatedAt
        _id
        name
        logo
        city
      }
    }
  }
`;

export const CREATE_UNIVERSITIES = gql`
  mutation createUniversities($name: String!, $city: String!) {
    employmentCreateUniversity(employmentCreateUniversityDto: { name: $name, city: $city }) {
      _id
    }
  }
`;

export const UPDATE_UNIVERSITIES = gql`
  mutation updateUniversities($id: String!, $name: String!, $city: String!) {
    employmentUpdateUniversity(employmentUpdateUniversityDto: { id: $id, name: $name, city: $city }) {
      _id
    }
  }
`;

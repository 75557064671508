import actions from 'store/user/user.actionTypes';
import { dispatch } from 'store/index';
import { schoolInfo, t_initialState } from 'store/user/user.reducer';

export const d_LoginSuccess = () => dispatch({ type: actions.LOGIN_SUCCESS });
export const d_loginFail = () => dispatch({ type: actions.LOGIN_FAIL });
export const d_logOut = () => dispatch({ type: actions.LOG_OUT });
export const d_loginInfo = (info: Pick<t_initialState, 'userInfo'>) =>
  dispatch({ type: actions.LOGIN_INFO, payload: info });
export const d_schoolInfo = (info: schoolInfo) => dispatch({ type: actions.SCHOOL_INFO, payload: info });

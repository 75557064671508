import SubmitButton from 'components/buttons/submitButton';
import UploadProfileImage from 'components/uploadProfileImage';
import { useToggle } from 'hooks';
import PagesLayout from 'pages/components/layout';
import React, { useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import FetchApi from 'services/api/axios';
import Styled from 'styled-components';
import { APP_HELPER } from 'tools/enums';
import { Add } from 'tools/i18n/constants';
import {
  category,
  company,
  companyCategories,
  create,
  created,
  enter,
  name,
  parent,
  please,
  remove,
  successfully,
  translation,
  update,
  updated,
} from 'tools/i18n/constants/i18n';
import { errorFinder, goBack } from 'tools/methods';
import { t_companyCategory } from './types';
import useCompanyCategoriesDetails from './useCompanyCategoriesDetails';
import Tree from 'rc-tree';
import sweetAlert from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { dataToTreeV2 } from 'components/tree-select-checkbox/utils';

const StyledIcon = Styled(UploadProfileImage)`
  min-width : 80px;
  max-width : 80px;
`;

export default function CompanyCategoryDetailV2() {
  const { selectedNode, createExecute, updateExecute, isNew, treeData, type, deleteExecute, deleteLoading } =
    useCompanyCategoriesDetails();
  const navigate = useNavigate();
  const [loading, toggleLoading] = useToggle(false);
  const [openConfirmModal, toggleOpenConfirmModal] = useToggle(false);
  const { t } = useTranslation();
  const [gData, setGData] = useState({
    gData: treeData,
    autoExpandParent: true,
    expandedKeys: [],
    checkedKey: undefined,
  });
  const DEFAULT_VALUES: t_companyCategory = useMemo(() => {
    return {
      _id: selectedNode?._id,
      imageUrl: selectedNode?.imageUrl === APP_HELPER.defaultBazaarAndJobIcon ? undefined : selectedNode?.imageUrl,
      originalName: selectedNode?.originalName,
      subCategories: selectedNode?.subCategories,
      translations: selectedNode?.translations?.map((item: any) => ({
        language: item.language,
        translate: item.translate,
      })),
      ancestors: selectedNode?.ancestors,
      parentId: selectedNode?.parentId,
    };
  }, [selectedNode]);
  const {
    handleSubmit,
    control,
    setError,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<t_companyCategory>({
    defaultValues: DEFAULT_VALUES,
  });
  const handleUploadIcon = (image: File, id: string, parentId: string | undefined) => {
    const formData = new FormData();
    formData.append('file', image);
    formData.append('categoryId', id);
    parentId && formData.append('parentId', parentId);
    FetchApi({
      url: 'UPDATE_COMPANY_CATEGORIES_IMAGE',
      method: 'PATCH',
      data: formData,
    })
      .then((res: any) => {
        if (res.data) {
          toast.success(`${t(successfully)} ${t(isNew ? created : updated)}`);
          goBack();
        }
        toggleLoading(false);
      })
      .catch((err: any) => {
        toggleLoading(false);
        setValue('customError', errorFinder(err.message));
      });
  };
  const onSubmit: SubmitHandler<t_companyCategory> = (data) => {
    toggleLoading(true);
    setValue('customError', '');
    createExecute({
      variables: {
        translations: data.translations.map((t, i) => ({ ...t, language: i === 0 ? 'tr' : 'en' })),
        parentId: data.parentId,
      },
    })
      .then((res) => {
        if (res.data.employmentCreateEmployerCategory) {
          if (data.imageUrl) {
            handleUploadIcon(data.imageUrl as File, res.data.employmentCreateEmployerCategory._id, data.parentId);
          } else {
            toast.success(`${t(successfully)} ${t(created)}`);
            goBack();
          }
        } else {
          setValue('customError', errorFinder(res?.errors?.[0]?.message));
          toggleLoading(false);
        }
      })
      .catch((err) => {
        setValue('customError', errorFinder(err));
        toggleLoading(false);
      });
  };
  const onUpdate: SubmitHandler<t_companyCategory> = (data) => {
    setValue('customError', '');
    console.log(data);
    toggleLoading(true);

    updateExecute({
      variables: {
        categoryId: data._id,
        parentId: data.parentId,
        translations: data.translations.map((t, i) => ({ ...t, language: i === 0 ? 'tr' : 'en' })),
      },
    })
      .then((res) => {
        if (res.data.employmentUpdateEmployerCategory) {
          if (data.imageUrl && data.imageUrl !== DEFAULT_VALUES.imageUrl) {
            handleUploadIcon(data.imageUrl as File, data._id as string, data.parentId);
          } else {
            toast.success(`${t(successfully)} ${t(updated)}`);
            goBack();
          }
        } else {
          setValue('customError', errorFinder(res?.errors?.[0]?.message));
          toggleLoading(false);
        }
      })
      .catch((err) => {
        setValue('customError', errorFinder(err));
        toggleLoading(false);
        setGData((p) => ({
          ...p,
          gData: treeData,
        }));
      });
  };

  const onDragStart = (info: any) => {
    console.log('start', info);
  };
  const onDrop = (info: any) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    console.log(info);
    const loop = (data, key, callback) => {
      data.forEach((item, index, arr) => {
        if (!!item?.key && item?.key === key) {
          callback(item, index, arr);
          return;
        }
        if (item.children) {
          loop(item.children, key, callback);
        }
      });
    };
    if (dragKey !== type) {
      toast.warning('You Can Only Move Selected Category!');
      return;
    } else if (dropKey === dragKey) {
      toast.warning('Dragged Category and Dropped Location are the same!');
      return;
    }
    if (info.dropToGap) {
      setValue('parentId', undefined);
    } else {
      setValue('parentId', dropKey);
    }
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const data = [...gData.gData];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (dropPosition === 0) {
      // Drop on the content
      loop(data, dropKey, (item) => {
        // eslint-disable-next-line no-param-reassign
        item.children = item.children || [];
        console.log(dragObj);
        item.children.unshift(dragObj);
      });
    } else {
      // Drop on the gap (insert before or insert after)
      let ar;
      let i;
      loop(data, dropKey, (item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }

    setGData((p) => ({
      ...p,
      gData: dataToTreeV2(data, {
        childKey: 'children',
        iconKey: 'logo',
        id: '_id',
        labelKey: 'title',
      }),
    }));
  };
  function allowDrop() {
    return true;
  }
  const onExpand = (expandedKeys: string[]) => {
    setGData((p) => ({ ...p, expandedKeys: expandedKeys, autoExpandParent: false }));
  };
  const handleShowRemovePopup = () => {
    sweetAlert
      .fire({
        title: 'Are You Sure to Delete this Category ?',
        showCancelButton: true,
        showConfirmButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          toggleOpenConfirmModal();
        }
      });
  };
  const onDelete = () => {
    deleteExecute({
      variables: {
        id: type,
      },
    })
      .then(() => {
        toast.success('Category Deleted Successfully');
        navigate('/dashboard/contentManagement/companyCategoriesV2');
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <Modal isOpen={openConfirmModal} unmountOnClose onClosed={() => toggleOpenConfirmModal(false)}>
        <ModalHeader>Are Your Sure to delete this Category ?</ModalHeader>
        <ModalBody>
          <p>By Deleting this category ...</p>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex align-items-center">
            <Button
              type="button"
              className="mr-3"
              onClick={() => toggleOpenConfirmModal(false)}
              disabled={deleteLoading}
            >
              Cancel
            </Button>
            <Button onClick={() => onDelete()} type="button" color="danger" disabled={deleteLoading}>
              Delete
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <PagesLayout backLinkTitle={t(companyCategories)}>
        <Card>
          <CardHeader className="py-3">
            <div className="d-flex align-items-center justify-content-between">
              <h5>{`${t(isNew ? Add : update)} ${t(company)} ${t(category)}`}</h5>
              <Button color="danger" onClick={handleShowRemovePopup}>
                {t(remove)}
              </Button>
            </div>
          </CardHeader>
          <CardBody className="px-1">
            <Row className="mx-auto">
              <Col xxl="12" className="mx-auto">
                <Form onSubmit={handleSubmit(isNew ? onSubmit : onUpdate, console.error)}>
                  {!!getValues('customError') && (
                    <div className="rounded my-2 shadow-bg-danger p-2">{getValues('customError')}</div>
                  )}
                  <div className="bg-gray p-3 d-flex align-items-center">
                    <FormGroup className="mr-5">
                      <Controller
                        control={control}
                        name="imageUrl"
                        render={({ field: { onChange } }) => {
                          return (
                            <StyledIcon
                              avatarSize="70px"
                              maxSize={512000}
                              defaultImage={DEFAULT_VALUES.imageUrl as string}
                              onSelect={(file: File) => {
                                onChange(file);
                              }}
                              onError={(e) => setError('imageUrl', { message: e })}
                            />
                          );
                        }}
                      />
                      <FormFeedback>{errors?.imageUrl?.message}</FormFeedback>
                    </FormGroup>

                    <div
                      className="flex-grow-1 d-flex align-items-center
                    justify-content-between flex-wrap bg-gray"
                    >
                      <FormGroup className={`mr-3 mb-0 flex-grow-1 rounded bg-gray position-relative`}>
                        <FormGroup>
                          <Label>{`${t(category)} Turkish ${t(name)}`}</Label>
                          <Controller
                            name="translations.0.translate"
                            defaultValue={DEFAULT_VALUES.translations?.[0]?.translate}
                            rules={{ required: `${t(please)} ${t(enter)} ${t(translation)}` }}
                            control={control}
                            render={({ field: { onChange } }) => {
                              return (
                                <Input
                                  defaultValue={DEFAULT_VALUES.translations?.[0]?.translate}
                                  onChange={onChange}
                                  placeholder="Translate"
                                />
                              );
                            }}
                          />
                          <FormFeedback>{errors?.translations?.[0]?.translate?.message}</FormFeedback>
                        </FormGroup>
                      </FormGroup>

                      <FormGroup className={`mr-3 mb-0 flex-grow-1 rounded bg-gray position-relative`}>
                        <FormGroup>
                          <Label>{`${t(category)} English ${t(name)}`}</Label>
                          <Controller
                            name="translations.1.translate"
                            control={control}
                            rules={{ required: `${t(please)} ${t(enter)} ${t(translation)}` }}
                            defaultValue={DEFAULT_VALUES.translations?.[1]?.translate}
                            render={({ field: { onChange } }) => {
                              return (
                                <Input
                                  defaultValue={DEFAULT_VALUES.translations?.[1]?.translate}
                                  onChange={onChange}
                                  placeholder={'Translate'}
                                />
                              );
                            }}
                          />
                          <FormFeedback>{errors?.translations?.[1]?.translate?.message}</FormFeedback>
                        </FormGroup>
                      </FormGroup>
                    </div>
                  </div>
                  <FormGroup className="flex-grow-1  bg-gray mt-2 p-3">
                    <Label style={{ fontWeight: 'bold' }}>{`${t(parent)} ${t(category)}`}</Label>
                    <Controller
                      name="parentId"
                      control={control}
                      render={({ field: { onChange } }) => {
                        return (
                          <div className="w-100 px-5 mt-3">
                            <Tree
                              allowDrop={allowDrop}
                              defaultExpandAll
                              draggable={!isNew}
                              indentWidth={40}
                              expandAction="click"
                              multiple={false}
                              onExpand={onExpand}
                              checkable={isNew}
                              showLine
                              onDragStart={onDragStart}
                              onDrop={onDrop}
                              className="mb-5"
                              treeData={gData.gData}
                              icon={({ data }) => {
                                return (
                                  <div className="mr-2 d-flex align-items-center">
                                    {isNew && (
                                      <Input
                                        style={{ width: '20px', height: '20px' }}
                                        type="checkbox"
                                        className="mr-2"
                                        checked={gData.checkedKey === data._id}
                                        onClick={() => {
                                          setGData((p) => {
                                            if (p.checkedKey === data._id) {
                                              onChange(undefined);
                                            } else {
                                              onChange(data._id);
                                            }
                                            return {
                                              ...p,
                                              checkedKey: data._id === p.checkedKey ? undefined : data._id,
                                            };
                                          });
                                        }}
                                      />
                                    )}
                                    {data?.imageUrl ? (
                                      <img src={data.logo} width={24} height={24} alt={data.originalName} />
                                    ) : (
                                      <img
                                        className="mr-2"
                                        src={APP_HELPER.defaultBazaarAndJobIcon}
                                        width={20}
                                        height={20}
                                        alt={data.originalName}
                                      />
                                    )}
                                  </div>
                                );
                              }}
                              // autoExpandParent={gData.autoExpandParent}
                              // expandedKeys={gData.expandedKeys}
                              showIcon
                              switcherIcon={({ expanded, data }) => {
                                if (data?.children?.length) {
                                  return (
                                    <span
                                      style={{ width: '15px', height: '15px' }}
                                      className="mx-2 d-inline-block d-inline h6"
                                    >
                                      {expanded ? '-' : '+'}
                                    </span>
                                  );
                                }
                                return (
                                  <span
                                    style={{ visibility: 'hidden', width: '20px', height: '20px' }}
                                    className="mr-1 d-inline h6 d-inline-block"
                                  >
                                    {'+'}
                                  </span>
                                );
                              }}
                            />
                          </div>
                        );
                      }}
                    />
                  </FormGroup>
                  <SubmitButton loading={loading}>{t(isNew ? create : update)}</SubmitButton>
                </Form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </PagesLayout>
    </Col>
  );
}

import React, { FC, useEffect, useState } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

export interface ImageUploader {
  onClick?: (value: any) => void;
  maxFiles?: number;
  label?: string;
  defaultValue?: any;
  className?: string;
}

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export const ImageUploader: FC<ImageUploader> = ({ onClick, maxFiles = 1, label = '', className = '' }) => {
  const [state, setState] = useState<any>();

  const handleUpdate = (fileItems: any) => {
    if (fileItems[0] && ['image/png', 'image/jpg', 'image/jpeg'].includes(fileItems[0].fileType)) {
      readFile(fileItems[0].file);
    }
  };

  const readFile = (files: any) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setState(reader.result);
    };
    reader.readAsDataURL(files);
  };

  useEffect(() => {
    onClick?.(state);
  }, [state]);

  return (
    <FilePond
      maxFiles={maxFiles}
      className={className}
      onupdatefiles={(fileItems) => handleUpdate(fileItems)}
      labelIdle={label}
    />
  );
};

export default ImageUploader;

import React, { ReactNode } from 'react';

interface PagesMainProps {
  children?: ReactNode;
}

export default function PagesMain(props: PagesMainProps) {
  const { children } = props;
  return <main id="pages-main">{children}</main>;
}

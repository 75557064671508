import { ApolloError } from '@apollo/client';
import { ApolloQueryResult } from '@apollo/client/core';
import DefaultCompanyIcon from 'assets/images/buluro/dashboard/Company default.svg';
import DefaultIcon from 'assets/images/buluro/dashboard/default-bazzar.svg';
import WalletIcon from 'assets/images/buluro/dashboard/empty-wallet.svg';
import Icon from 'components/icons';
import { BlueTickIcon } from 'components/icons/customeIcon';
import ImgToBackGround from 'components/imgToBackground';
import Link from 'components/link';
import ShowError from 'components/showError';
import DataTables, { TableSectionTitle } from 'components/tables/dataTable';
import { useToggle } from 'hooks';
import FilterBazaarAds, { t_defaultValues } from 'pages/ads/bazzarAds/filter';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import Styled from 'styled-components';
import { APP_HELPER } from 'tools/enums';
import { Location } from 'tools/i18n/constants';
import { ads, detail, list, publish, status, time, title } from 'tools/i18n/constants/i18n';
import { removeCityFromCounty, toDate, toUpperCase } from 'tools/methods';
import { emlakAd } from '../../types';
import { STATUS } from '../../types/enum';

const ImageContainer = Styled.div`
  min-width : 160px;
  min-height : 160px;
`;

export interface EmlakAdsTableProps {
  data: emlakAd[];
  fetchMore: any;
  loading: boolean;
  error?: ApolloError;
  count?: number;
}
export function EmlakAdsTable(props: EmlakAdsTableProps) {
  const { data = [], count = 0, fetchMore, loading } = props;
  console.log(data);

  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [activeFilters, setActiveFilters] = useState<t_defaultValues | undefined>(undefined);
  const [realData, setRealData] = useState<{ data: emlakAd[]; count: number }>({ data, count });
  const [disabled, toggleDisabled] = useToggle(false);
  const onPageChange = useCallback(
    async (page: number, size: number = APP_HELPER.tableDefaultSize) => {
      setError('');

      const vars = { skip: page, take: size, ...activeFilters };
      fetchMore({
        variables: vars,
      })
        .then((res: ApolloQueryResult<{ findAllRealestateAds: { data: emlakAd[]; count: number } }>) => {
          if (res?.data?.findAllRealestateAds) {
            setRealData(res.data.findAllRealestateAds);
          } else {
            setRealData({ count: 0, data: [] });
            // @ts-ignore
            setError(res?.errors?.message);
          }
        })
        .catch((err: ApolloError) => {
          console.error(err);
          setRealData({ count: 0, data: [] });
          setError(err?.message);
        });
    },
    [activeFilters]
  );

  const onSearch = useCallback(
    (key: string) => {
      setError('');
      if (key.length === 0 || !key) {
        fetchMore({
          ...activeFilters,
        })
          .then((res: { data: { findAllBazaarPosters: { count: number; data: emlakAd[] } } }) => {
            console.log(res);
            setRealData(res.data?.findAllBazaarPosters);
          })
          .catch(console.error);
      } else if (key.length >= 3) {
        const vars = {
          search: key,
          ...activeFilters,
        };
        fetchMore({
          variables: vars,
        })
          .then((res: ApolloQueryResult<{ findAllRealestateAdsTable: { data: emlakAd[]; count: number } }>) => {
            console.log(res);
            if (res?.data?.findAllRealestateAdsTable) {
              setRealData(res.data.findAllRealestateAdsTable);
            } else {
              setRealData({ count: 0, data: [] });
              // @ts-ignore
              setError(res?.errors?.message);
            }
          })
          .catch((err: ApolloError) => {
            console.error(err);
            setRealData({ count: 0, data: [] });
            setError(err?.message);
          });
      }
    },
    [activeFilters]
  );
  const onFilter = (data: t_defaultValues) => {
    setError('');
    toggleDisabled(true);
    const updatedData: t_defaultValues = {
      categoryId: data?.category,
      currencySlug: data?.unit,
      priceFrom: data?.priceMin,
      priceTo: data?.priceMax,
      // @ts-ignore
      city: data?.city?.map((city) => city.city as string) as string[],
      counties: data?.county,
      posterType: data.type,
      status: data?.status,
      model: data?.model,
      onlyWithPictures: data?.onlyAdsWithPhoto,
      startDate: data?.entrance,
      endDate: data?.end,
    };
    setActiveFilters(updatedData);
    fetchMore({
      variables: updatedData,
    })
      .then((res: any) => {
        console.log(res);
        setRealData(res.data?.findAllBazaarPosters);
        if (res?.errors) {
          setError(res?.errors?.message);
        }
        toggleDisabled(false);
      })
      .catch((err: any) => {
        toggleDisabled(false);
        console.log(err);
        setError(err?.message);
      });
  };
  const columnData = useMemo(() => {
    const iconSize = '18px';
    return [
      {
        name: <TableSectionTitle name={'FiFileText'} title={t(title)} />,
        center: false,
        width: '400px',
        cell: (r: emlakAd) => {
          return (
            <Link to={'emlakAdsDetail'} param={r?.id?.toString()} className="text-black">
              <div className="d-flex text-black py-2 table-cell-container">
                <ImageContainer className="rounded mr-2 border-light-gray">
                  <ImgToBackGround src={r.category?.picture || DefaultIcon} />
                </ImageContainer>
                <div className=" d-flex flex-column justify-content-between py-2">
                  <div className="d-flex table-cell-container align-items-center mb-2">
                    <div className="img-sm">
                      <img
                        className=" rounded-circle"
                        alt={`${r?.ownerInfo?.name}`}
                        src={r.ownerInfo?.picture || DefaultCompanyIcon}
                      />
                    </div>
                    <span className="ml-2">{r?.ownerInfo?.name}</span>
                    {r?.ownerInfo?.blueMark && (
                      <span className="ml-2">
                        <BlueTickIcon />
                      </span>
                    )}
                  </div>
                  <div className="d-flex mb-2">
                    <Icon size={iconSize} Name="FiGrid" />
                    <span className="ml-2">{r?.title}</span>
                  </div>
                  <div className="mb-2">
                    {/*  <img
                      alt={r?.status}
                      width="20px"
                      height="20px"
                      src={toUpperCase(r?.status) === bazaarAdsEnum.New ? NewIcon : SecondHandIcon}
                    /> */}
                    <span className="ml-2">{toUpperCase(r?.categoryType?.title)}</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <img alt={r?.status} width="20px" height="20px" src={WalletIcon} />
                    <span className="ml-2 whitespace-nowrap">{`${
                      r?.price && r?.currency?.name && r?.currency?.symbol
                        ? new Intl.NumberFormat(undefined, {
                            useGrouping: true,
                          }).format(parseInt(r?.price))
                        : ''
                    } ${r?.currency?.symbol}`}</span>
                  </div>
                </div>
              </div>
            </Link>
          );
        },
      },
      {
        name: <TableSectionTitle name="FaRegMap" title={t(Location)} />,
        center: false,
        cell: (r: emlakAd) => (
          <div className="text-left">
            <span>{toUpperCase(r?.address?.city?.name)}</span>
            <br />
            <br />
            <span>{removeCityFromCounty(toUpperCase(r?.address?.town?.name))}</span>
          </div>
        ),
      },
      {
        name: <TableSectionTitle name="FiGrid" title={`${t(publish)} ${t(time)}`} />,
        center: false,
        selector: (r: emlakAd) => toDate(r?.publishedAt as string),
      },
      {
        name: <TableSectionTitle name="FiType" title={t(status)} />,
        center: false,
        cell: (r: emlakAd) => {
          const selectClassName = () => {
            switch (r?.status) {
              case STATUS.ACTIVE:
                return 'shadow-bg-success';
              case STATUS.DRAFT:
                return 'shadow-bg-notActive';
              case STATUS.BAN:
                return 'shadow-bg-danger';
              case STATUS.EXPIRED:
                return 'shadow-bg-danger';
              default:
                return 'shadow-bg-notActive';
            }
          };
          return <div className={`p-2 rounded ${selectClassName()}`}>{r.status ? STATUS[r?.status] : 'NO STATUS'}</div>;
        },
      },
      {
        name: '',
        center: false,
        cell: (r: emlakAd) => {
          return (
            <Link className="text-white ml-auto" to={'bazzarAdsDetail'} param={r?.id?.toString()}>
              <Button color="primary">{t(detail)}</Button>
            </Link>
          );
        },
      },
    ];
  }, []);

  return (
    <div className="d-flex flex-column">
      {!!error && (
        <div className="mb-4">
          <ShowError>{error}</ShowError>
        </div>
      )}
      <DataTables
        searchable
        headerTitle={`${t(ads)} ${t(list)}`}
        data={realData?.data}
        columns={columnData}
        filterable
        onSearch={onSearch}
        paginationTotalRows={realData?.count}
        onChangePage={onPageChange}
        onChangeRowsPerPage={async (size, currentPage) => await onPageChange(currentPage, size)}
        pagination
        customFilter={<FilterBazaarAds loading={disabled || loading} onFilter={onFilter} />}
      />
    </div>
  );
}

export default EmlakAdsTable;

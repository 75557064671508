import { BLUE_TICKET_STATUS, COMPANY_REHBER_STATUS } from 'tools/enums';
import { filter } from '../useCompanies';

export const SORT_BY: { label: string; value: filter['sortBy'] }[] = [
  {
    label: 'Oluşturma Tarihi',
    value: 'createdAt',
  },
  {
    label: 'Profil Doluluk Oranı',
    value: 'profilePercentage',
  },
  {
    label: 'Alfabeye Göre',
    value: 'companyName',
  },
];
export const SORT_TYPE: { label: string; value: filter['sortType'] }[] = [
  {
    label: 'Artan',
    value: 'ASC',
  },
  {
    label: 'Azalan',
    value: 'DESC',
  },
];

export const BLUE_TICKET_STATUS_LIST: { label: string; value: filter['blueTickStatus'] }[] = [
  {
    label: 'Accepted',
    value: BLUE_TICKET_STATUS.ACCEPTED,
  },
  {
    label: 'Canceled',
    value: BLUE_TICKET_STATUS.CANCELED,
  },
  {
    label: 'In Progress',
    value: BLUE_TICKET_STATUS.IN_PROGRESS,
  },
  {
    label: 'Rejected',
    value: BLUE_TICKET_STATUS.REJECTED,
  },
  {
    label: 'Not Requested',
    value: BLUE_TICKET_STATUS.NOT_REQUESTED,
  },
];

export const FILTER_SELECT_REHBER: { label: string; value?: COMPANY_REHBER_STATUS | '' }[] = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Accepted',
    value: COMPANY_REHBER_STATUS.ACCEPTED,
  },
  {
    label: 'Rejected',
    value: COMPANY_REHBER_STATUS.REJECTED,
  },
  {
    label: 'Pending',
    value: COMPANY_REHBER_STATUS.PENDING,
  },
  {
    label: 'None',
    value: COMPANY_REHBER_STATUS.NONE,
  },
];

export const FILTER_SELECT_ENUM: { label: string; value: boolean | null }[] = [
  {
    label: 'Var',
    value: true,
  },
  {
    label: 'Yok',
    value: false,
  },
  {
    label: 'Hepsi',
    value: null,
  },
];

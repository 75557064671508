import React, { memo } from 'react';
import DataTable from 'components/tables/dataTable';
import { TABLE_COLUMNS } from 'pages/notification/entities';
import { NotificationFiltersProps, NotificationTableProps } from '../../types';
import NotificationFilters from 'pages/notification/components/table/filters';
import { list, notification } from 'tools/i18n/constants/i18n';
import { useTranslation } from 'react-i18next';

function NotificationTable<T extends NotificationTableProps & NotificationFiltersProps>(props: T): JSX.Element {
  const { t } = useTranslation();
  const {
    data,
    onSizeChange,
    onPageChange,
    onDateChange,
    onStatusChange,
    onSearch,
    selectedDates,
    onSubmit,
    currentActiveStatus,
    count,
    disabled,
  } = props;
  return (
    <DataTable
      disabled={disabled}
      columns={TABLE_COLUMNS(t)}
      headerTitle={`${t(notification)} ${t(list)}`}
      data={data}
      pagination
      searchable
      filterable
      onChangePage={onPageChange}
      onChangeRowsPerPage={onSizeChange}
      onSearch={onSearch}
      paginationTotalRows={count}
      customFilter={
        <NotificationFilters
          disabled={disabled}
          currentActiveStatus={currentActiveStatus}
          onSubmit={onSubmit}
          selectedDates={selectedDates}
          onDateChange={onDateChange}
          onStatusChange={onStatusChange}
        />
      }
    />
  );
}
export default memo(NotificationTable);

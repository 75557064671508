import { ApolloError } from '@apollo/client';
import { Loading } from 'components';
import Icon from 'components/icons';
import { BlueTickIcon } from 'components/icons/customeIcon';
import ImgToBackGround from 'components/imgToBackground';
import Link from 'components/link';
import DataTables, { TableSectionTitle } from 'components/tables/dataTable';
import { useToggle } from 'hooks';
import AdStatus from 'pages/ads/jobAds/components/sections/adStatus';
import DateOfPublish from 'pages/ads/jobAds/components/sections/dateOfPublish';
import ExperienceExpectation from 'pages/ads/jobAds/components/sections/experienceExpectation';
import Locations from 'pages/ads/jobAds/components/sections/location';
import OnlyVerifiedCompanies from 'pages/ads/jobAds/components/sections/onlyVerifiedCompanies';
import Speciality from 'pages/ads/jobAds/components/sections/speciality';
import WorkingDays from 'pages/ads/jobAds/components/sections/workingDays';
import WorkingHours from 'pages/ads/jobAds/components/sections/workingHours';
import useJobAds from 'pages/ads/jobAds/useJobAds';
import PagesLayout from 'pages/components/layout';
import React, { useCallback, useMemo, useState } from 'react';
import { Control, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, Form } from 'reactstrap';
import styled from 'styled-components';
import { ADS_STATUS, ADS_STATUS_TEXT, APP_HELPER } from 'tools/enums';
import { Location } from 'tools/i18n/constants';
import { ads, detail, list, publish, reset, results, show, status, time, title } from 'tools/i18n/constants/i18n';
import { removeCityFromCounty, toDate, toUpperCase } from 'tools/methods';
import { t_defaultValues } from './types';

const StyledFilterContainer = styled.div`
  min-width: 500px;
  max-width: 500px;
`;

export const DEFAULT_VALUE: t_defaultValues = {
  page: undefined,
  size: undefined,
  keyword: undefined,
  lang: 'en',
  cities: undefined,
  counties: undefined,
  speciality: undefined,
  appliesStatus: undefined,
  statusesMustBeShown: undefined,
  experienceExpectations: undefined,
  workingDays: undefined,
  workingHours: undefined,
  date: undefined,
  onlyVerifiedCompanies: false,
};

export default function Ads() {
  const { data, loading, error, fetchMore, refetch, count } = useJobAds();

  return (
    <Loading loading={loading}>
      <PagesLayout>
        <Card>
          <CardBody className="p-0">
            <AdsTable
              data={data}
              count={count}
              loading={loading}
              error={error}
              refetch={refetch}
              fetchMore={fetchMore}
            />
          </CardBody>
        </Card>
      </PagesLayout>
    </Loading>
  );
}

interface AdsTableProps {
  refetch: any;
  fetchMore: any;
  loading: boolean;
  data: unknown[];
  error?: ApolloError;
  count: number;
}

function AdsTable(props: AdsTableProps) {
  const { data, error, fetchMore, count } = props;
  const { t } = useTranslation();
  const [realLoading, setRealLoading] = useToggle(false);
  const [realData, setRealData] = useState(data);
  const [filters, setFilters] = useState<t_defaultValues | undefined>(DEFAULT_VALUE);
  const onPageChange = useCallback(
    async (page: number, size: number) => {
      const vars: t_defaultValues = { ...filters, page, size };
      fetchMore({
        variables: vars,
      })
        .then((res: { data: { EmploymentAdsList: { data: unknown[] } } }) => {
          setRealData(res.data?.EmploymentAdsList.data);
        })
        .catch(console.error);
    },
    [filters]
  );

  const onSearch = useCallback(
    (key = '') => {
      fetchMore({
        variables: {
          ...(key.length > 3 && { keyword: key }),
        },
      })
        .then((res: { data: { EmploymentAdsList: { data: unknown[] } } }) => {
          setRealData(res.data?.EmploymentAdsList.data);
        })
        .catch(console.error);
    },
    [filters]
  );
  const onFilter = (data: t_defaultValues | undefined) => {
    if (data) {
      if (!data.workingHours?.endAt && !data.workingHours?.startAt) {
        data['workingHours'] = undefined;
      }
      if (!data.date?.from && !data.date?.to) {
        data['date'] = undefined;
      }
      if (data.counties?.length) {
        data['cities'] = undefined;
      } else {
        data['counties'] = undefined;
      }
    }
    setFilters(data);
    setRealLoading(true);
    const vars = { ...data, page: APP_HELPER.tableDefaultPage, size: APP_HELPER.tableDefaultSize };
    fetchMore({
      variables: vars,
    })
      .then((res: any) => {
        console.log(res);
        setRealData(res.data.EmploymentAdsList.data);
        setRealLoading(false);
      })
      .catch(() => {
        setRealLoading(false);
      });
  };
  const columnData = useMemo(() => {
    return [
      {
        name: <TableSectionTitle name={'FiFileText'} title={t(title)} />,
        center: false,
        cell: (r: any) => {
          return (
            <div className="d-flex flex-column py-2">
              <div className="d-flex mb-3 table-cell-container">
                {r?.branch?.imageUrl ? (
                  <div className="img-sm rounded-circle">
                    <ImgToBackGround src={r?.branch?.imageUrl} />
                  </div>
                ) : (
                  <Icon Name="FaRegBuilding" size="20px" />
                )}

                <span className="ml-2">{`${r?.branch?.originalName || ''}${
                  r?.section?.originalName ? `/${r?.section?.originalName}` : ''
                }`}</span>
              </div>
              <div className="d-flex align-items-center table-cell-container">
                {r.company.logUrl ? (
                  <div className="img-sm rounded-circle">
                    <ImgToBackGround src={r?.company?.logUrl} />
                  </div>
                ) : (
                  <Icon Name="FaWarehouse" />
                )}
                <span className="mx-2">{r?.company?.name}</span>
                {r?.company?.blueTick && <BlueTickIcon />}
              </div>
            </div>
          );
        },
      },
      {
        name: <TableSectionTitle name="FiMapPin" title={t(Location)} />,
        center: false,
        cell: (r: any) => (
          <div>
            <span>{toUpperCase(r?.address?.city)}</span>
            <br />
            <br />
            <span>{removeCityFromCounty(toUpperCase(r?.address?.county))}</span>
          </div>
        ),
      },
      {
        name: <TableSectionTitle name="FiGrid" title={`${t(publish)} ${t(time)}`} />,
        center: false,
        cell: (r: any) => {
          return toDate(r.releaseDate);
        },
      },
      {
        name: <TableSectionTitle name="FiType" title={t(status)} />,
        center: false,
        cell: (r: any) => {
          const selectClassName = () => {
            switch (r.status) {
              case ADS_STATUS.ACTIVE:
                return 'shadow-bg-success';
              case ADS_STATUS.DRAFT:
                return 'shadow-bg-notActive';
              case ADS_STATUS.ADMIN_BAN:
                return 'shadow-bg-danger';
              case ADS_STATUS.EXPIRED:
                return 'shadow-bg-danger';
              default:
                return 'shadow-bg-notActive';
            }
          };
          return (
            <div className={`p-2 rounded ${selectClassName()}`}>
              {ADS_STATUS_TEXT[r.status as keyof typeof ADS_STATUS_TEXT]}
            </div>
          );
        },
      },
      {
        name: '',
        center: false,
        cell: (r: any) => {
          return (
            <Link className="text-white ml-auto" to={'schoolDetail'} param={r._id.toString()}>
              <Button color="primary">{t(detail)}</Button>
            </Link>
          );
        },
      },
    ];
  }, []);
  return (
    <DataTables
      searchable
      headerTitle={`${t(ads)} ${t(list)}`}
      data={realData}
      filterable
      paginationTotalRows={count}
      columns={columnData}
      onSearch={onSearch}
      disabled={realLoading || !!error}
      onChangePage={onPageChange}
      pagination
      customFilter={<FilterOptions onFilter={onFilter} loading={realLoading} />}
    />
  );
}

export interface FormProps {
  control: Control<t_defaultValues, any>;
  onChange?: (name: keyof t_defaultValues, value: any) => void;
}

interface FilterOptionsProps {
  onFilter: (data: t_defaultValues | undefined) => any;
  loading: boolean;
}

function FilterOptions(props: FilterOptionsProps) {
  const { t } = useTranslation();
  const { onFilter, loading } = props;
  const {
    control,
    handleSubmit,
    setValue,
    reset: resetForm,
  } = useForm<t_defaultValues>({
    defaultValues: DEFAULT_VALUE,
  });
  const onSubmit = (data = DEFAULT_VALUE) => {
    onFilter(data);
  };
  const handleChange = useCallback((name: keyof t_defaultValues, val: any) => {
    setValue(name, val);
  }, []);
  const handleResetForm = () => {
    resetForm();
    onFilter(undefined);
  };

  return (
    <StyledFilterContainer className="bg-white p-3 rounded">
      <Form onSubmit={handleSubmit(onSubmit, console.error)} className="d-flex flex-column">
        <Locations control={control} onChange={handleChange} />
        <Speciality control={control} onChange={handleChange} />
        <AdStatus control={control} onChange={handleChange} />
        <ExperienceExpectation control={control} onChange={handleChange} />
        <WorkingDays control={control} onChange={handleChange} />
        <WorkingHours control={control} onChange={handleChange} />
        <DateOfPublish control={control} onChange={handleChange} />
        <OnlyVerifiedCompanies control={control} onChange={handleChange} />
        <Button disabled={loading} aria-disabled={loading} type="submit" color="primary">{`${t(show)} ${t(
          results
        )}`}</Button>
        <Button
          type="button"
          onClick={handleResetForm}
          disabled={loading}
          aria-disabled={loading}
          className="w-100 mt-2"
          color=""
        >
          {`${t(reset)}`}
        </Button>
      </Form>
    </StyledFilterContainer>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { company, detail, size } from 'tools/i18n/constants/i18n';
import PagesLayout from 'pages/components/layout';
import { Loading } from 'components';
import DataTable, { TableSectionTitle } from 'components/tables/dataTable';
import { Button, Col, Row } from 'reactstrap';
import Link from 'components/link';
import useCompanySizes, { t_companySize } from 'pages/content-management/company/companySizes/useCompanySizes';

export default function CompanySizes() {
  const { data, loading, addNewTitle } = useCompanySizes();
  return (
    <Loading loading={loading}>
      <Row>
        <Col className="mx-auto" xs={12} xl={8} xxl={10}>
          <PagesLayout newSectionButtonLabel={addNewTitle}>
            <CompanySizeTable data={data} />
          </PagesLayout>
        </Col>
      </Row>
    </Loading>
  );
}
interface CategoriesTable {
  data: t_companySize[] | undefined;
}

function CompanySizeTable(props: CategoriesTable) {
  const { data = [] } = props;
  const { t } = useTranslation();
  const columns = [
    {
      name: <TableSectionTitle name="FiUser" title={`${t(company)} ${t(size)}`} />,
      center: false,
      cell: (r: t_companySize) => {
        return <span className="text-black">{r.title}</span>;
      },
    },
    {
      name: '',
      center: false,
      cell: (r: t_companySize) => {
        return (
          <Link to="ageRangeDetail" className="ml-auto" param={r.id}>
            <Button color="primary">{t(detail)}</Button>
          </Link>
        );
      },
    },
  ];
  return <DataTable disabled={false} columns={columns} data={data} />;
}

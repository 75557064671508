import { Col } from 'reactstrap';
import { Loading } from 'components';
import PagesLayout from 'pages/components/layout';
import DataTables, { TableSectionTitle } from 'components/tables/dataTable';
import { list, users } from 'tools/i18n/constants/i18n';
import React, { useRef, useState } from 'react';
import { useQuery, useToggle, useUpdateEffect } from 'hooks';
import { GET_DELETED_USERS_LIST } from 'services/graphql/queries/deletedUsers';
import { APP_HELPER, UserTypeEnum } from 'tools/enums';
import { ProfileImage, TableCellContainer } from 'pages/user-management/users/style';
import Avatar from 'react-avatar';
import userDefault from 'assets/images/buluro/dashboard/user-default.svg';
import companyIcon from 'assets/images/buluro/dashboard/Company default.svg';
import { BlueTickIcon } from 'components/icons/customeIcon';
import { toDate } from 'tools/methods';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import DeletedUsersTableCustomFilter, {
  t_deletedTableFilter_defaultValues,
} from 'pages/user-management/users/deletedUsersFilter';
import { useSearchParams } from 'react-router-dom';

type deletedUser = {
  userId: number;
  companyId: number;
  phone: string;
  email: string;
  companyName: string;
  profilePercentage: string;
  userType: UserTypeEnum;
  fullName: string;
  imageUrl: string;
  registerDate: string;
  blueMark: boolean;
  deletedByAdmin: boolean;
  isBanned: boolean;
  deletedAt: string;
  insertedReason: string;
  reason: string;
};

type queryVariables = {
  page?: number | undefined;
  size?: number | undefined;
  userType?: UserTypeEnum | undefined;
  byAdmin?: boolean | undefined;
  query?: string | undefined;
};

type queryResponse = {
  findAllDeletedUsers: {
    count: number;
    result: deletedUser[];
  };
};

export default function DeletedUsers() {
  const isFirst = useRef(true);
  const { t } = useTranslation();
  const [param] = useSearchParams();
  const defaultPage = param.get('page') ? parseInt(param.get('page') as string) : APP_HELPER.tableDefaultPage;
  const defaultSize = param.get('size') ? parseInt(param.get('size') as string) : APP_HELPER.tableDefaultSize;
  const { data, loading, error, fetchMore } = useQuery<queryResponse, queryVariables>(GET_DELETED_USERS_LIST, {
    defaultOptions: {
      variables: {
        page: defaultPage,
        size: defaultSize,
      },
    },
  });
  const [actionLoading, toggleActionLoading] = useToggle(false);
  const [deletedUsers, setDeletedUsers] = useState<queryResponse['findAllDeletedUsers']>({
    count: 0,
    result: [],
  });
  const assignedFilter = useRef<queryVariables>({
    size: 50,
    byAdmin: undefined,
    userType: undefined,
    query: undefined,
    page: 1,
  });

  const onSearch = (key: string) => {
    toggleActionLoading(true);
    assignedFilter.current.query = key || undefined;
    fetchMore({
      variables: {
        ...assignedFilter.current,
        query: key || undefined,
      },
    })
      .then((result) => {
        if (result.data.findAllDeletedUsers) {
          setDeletedUsers({
            count: result.data.findAllDeletedUsers.count,
            result: result.data.findAllDeletedUsers.result,
          });
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      })
      .finally(() => {
        toggleActionLoading(false);
      });
  };
  const onFilter = (filter: t_deletedTableFilter_defaultValues) => {
    toggleActionLoading(true);
    const updateData: Partial<queryVariables> = {};
    if (filter.employee && filter.employer) {
      updateData['userType'] = undefined;
    } else {
      updateData['userType'] = filter.employer ? UserTypeEnum.employer : UserTypeEnum.employee;
    }
    if (!filter.bannedByAdmin) {
      updateData['byAdmin'] = undefined;
    }
    if (filter.bannedByAdmin) {
      updateData['byAdmin'] = true;
    }
    assignedFilter.current = {
      ...assignedFilter.current,
      ...updateData,
    };
    console.log(assignedFilter.current);
    fetchMore({
      variables: {
        ...assignedFilter.current,
      },
    })
      .then((result) => {
        if (result.data.findAllDeletedUsers) {
          setDeletedUsers({
            count: result.data.findAllDeletedUsers.count,
            result: result.data.findAllDeletedUsers.result,
          });
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      })
      .finally(() => {
        toggleActionLoading(false);
      });
  };

  const onPageChange = (page: number, size: number) => {
    toggleActionLoading(true);
    assignedFilter.current.page = page;
    assignedFilter.current.size = size;
    fetchMore({
      variables: {
        query: {
          ...assignedFilter.current,
          page,
          size,
        },
      },
    })
      .then((result) => {
        if (result.data.findAllDeletedUsers) {
          setDeletedUsers({
            count: result.data.findAllDeletedUsers.count,
            result: result.data.findAllDeletedUsers.result,
          });
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      })
      .finally(() => {
        toggleActionLoading(false);
      });
  };

  const onChangeRowsPerPage = (size: number) => {
    toggleActionLoading(true);
    assignedFilter.current.size = size;
    fetchMore({
      variables: {
        query: {
          ...assignedFilter.current,
          size,
        },
      },
    })
      .then((result) => {
        if (result.data.findAllDeletedUsers) {
          setDeletedUsers({
            count: result.data.findAllDeletedUsers.count,
            result: result.data.findAllDeletedUsers.result,
          });
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      })
      .finally(() => {
        toggleActionLoading(false);
      });
  };

  const TABLE_COLUMN = [
    {
      name: <TableSectionTitle name="FiUser" title="Full Name" />,
      center: false,
      minWidth: '350px',
      cell: (r: deletedUser) => {
        return (
          <TableCellContainer className="d-flex align-items-center py-3">
            <ProfileImage data-percent={r.profilePercentage ? `${r.profilePercentage}%` : '0%'}>
              <Avatar src={r?.imageUrl || userDefault} size="64px" />
            </ProfileImage>

            <div className="d-flex flex-column ml-3">
              {!!r?.companyName && (
                <div className="d-flex align-items-center mb-1">
                  <img alt="company logo" src={companyIcon} />
                  <span className=" text-black ml-1"> {r.companyName} </span>
                  {r.blueMark && (
                    <span className="ml-2">
                      <BlueTickIcon />
                    </span>
                  )}
                </div>
              )}
              <span className="text-gray text-md">{r?.companyName || r.fullName}</span>
            </div>
          </TableCellContainer>
        );
      },
    },
    {
      name: <TableSectionTitle name="FiPhone" title="Phone Number Or Email" />,
      minWidth: '250px',
      cell: (r: deletedUser) => r?.phone || r?.email,
    },
    {
      name: <TableSectionTitle name="FiType" title="Type" />,
      minWidth: '150px',
      selector: (r: deletedUser) => (r.userType === 'EMPLOYER' ? 'Kurumsal' : 'Bireysel'),
    },
    {
      name: <TableSectionTitle name="FiCalendar" title="Registration Date" />,
      minWidth: '200px',
      cell: (r: deletedUser) => toDate(r.registerDate),
    },

    {
      name: <TableSectionTitle name="AiOutlineDeleteRow" title="Deleted Date" />,
      minWidth: '200px',
      cell: (r: deletedUser) => toDate(r.deletedAt),
    },
    {
      name: <TableSectionTitle name="AiOutlineUserDelete" title="Deleted By" />,
      width: '150px',
      cell: (r: deletedUser) => (r.deletedByAdmin ? 'By Admin' : 'By User'),
    },
    {
      name: <TableSectionTitle name="FaEnvelopeOpenText" title="Reason" />,
      minWidth: '200px',
      cell: (r: deletedUser) => {
        return <p className="max-w-100">{r.insertedReason || r.reason}</p>;
      },
    },
  ];

  useUpdateEffect(() => {
    if (isFirst.current) {
      if (data?.findAllDeletedUsers.result) {
        setDeletedUsers({
          count: data.findAllDeletedUsers.count,
          result: data.findAllDeletedUsers.result,
        });
      }
      isFirst.current = false;
    }
  }, [data]);
  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <Loading loading={loading}>
        <PagesLayout>
          <DataTables
            data={deletedUsers.result}
            paginationTotalRows={deletedUsers.count}
            headerTitle={`Deleted ${t(users)} ${t(list)}`}
            filterable
            searchable
            customFilter={<DeletedUsersTableCustomFilter loading={actionLoading || loading} onFilter={onFilter} />}
            columns={TABLE_COLUMN as any}
            onSearch={onSearch}
            disabled={loading || actionLoading || !!error}
            onChangePage={onPageChange}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        </PagesLayout>
      </Loading>
    </Col>
  );
}

import { Drawer } from 'antd';
import { Loading } from 'components';
import Icon from 'components/icons';
import { t_refetch, t_toggleValueFunc, useToggle } from 'hooks';
import useMutation from 'hooks/useMutation';
import PagesLayout from 'pages/components/layout';
import BlueTickRequest from 'pages/content-management/company/companies/requestBlueTick';
import useDetails from 'pages/content-management/company/companies/useDetails';
import CompaniesDetail from 'pages/user-management/employer';
import React, { FC, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { FormCheck } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Label } from 'reactstrap';
import FetchApi from 'services/api/axios';
import { UPDATE_IS_STORE } from 'services/graphql/queries/companies';
import SweetAlert from 'sweetalert2';
import {
  areYouSure,
  blue,
  company,
  details,
  isLeader,
  isStore,
  no,
  request,
  tick,
  yes,
} from 'tools/i18n/constants/i18n';
import { getLocation, isNew } from 'tools/methods';
import CompanyNotes from './components/companyNotes';
import CompanyGuideTab from './companyGuideTab';
import { t_company } from 'tools/types/sectionTypes/company';
import { COMPANY_REHBER_STATUS } from 'tools/enums';
import { boolish } from 'tools/types';

enum e_selectedSection {
  details = 'DETAILS',
  blueTicketRequest = 'BLUE_TICKET_REQUEST',
  rehber = 'REHBER',
}

interface ChildRef {
  refetch: () => void;
}

export default function CompanyDetail() {
  const { type, id } = useParams();
  const [isCompanySection, toggleCompanySection] = useState<e_selectedSection>(e_selectedSection.details);
  const { data, loading, id: detailId, error, refetch } = useDetails();
  const [execute, { loading: isStoreLoading }] = useMutation(UPDATE_IS_STORE);
  // const [isStore, toggleIsStore] = useToggle(data?.verifiedForShop as boolean);
  const { t } = useTranslation();

  console.log(data);

  const handleToggleStore = () => {
    SweetAlert.fire({
      title: t(areYouSure),
      showCancelButton: true,
      icon: 'question',
      confirmButtonText: t(yes),
      cancelButtonText: t(no),
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        SweetAlert.fire({
          title: 'Progressing ...',
          backdrop: true,
          icon: 'info',
          timerProgressBar: true,
          showCancelButton: false,
          showCloseButton: false,
          showDenyButton: false,
          showConfirmButton: false,
        });
        execute({
          variables: {
            id: data.id,
            isShop: !data?.verifiedForShop,
          },
        })
          .then((res) => {
            if (res.data.updateToShopOrCreateNewOwnerAsShop) {
              SweetAlert.close();
              toast.success(t('update successful'));
              refetch();
              // toggleIsStore();
            } else {
              SweetAlert.close();
              toast.error(res?.errors?.[0]?.message);
            }
          })
          .catch((err) => {
            SweetAlert.close();
            console.error(err);
            toast.error(err.message);
          });
      } else {
        SweetAlert.close();
      }
    });
  };

  // useEffect(() => {
  //   const selectedSection = getLocation().hash.replace('#', '');
  //   if (selectedSection) {
  //     // toggleCompanySection(selectedSection === e_selectedSection.details);
  //   } else toggleCompanySection(true);
  // }, []);
  const RenderComponent: ReactElement<{}> =
    isCompanySection === e_selectedSection.details ? (
      <UpdateCompany
        data={data}
        loading={loading}
        id={detailId as number}
        error={error}
        refetch={() => refetch()}
        isStore={data?.verifiedForShop as boolean}
        activeSection={isCompanySection}
        toggleCompanySection={(action) => toggleCompanySection(action)}
        handleToggleStore={handleToggleStore}
        isStoreLoading={isStoreLoading}
        // toggleIsStore={() => toggleIsStore(!isStore)}
      />
    ) : isCompanySection === e_selectedSection.blueTicketRequest ? (
      <>
        <Header
          isNew={isNew(type)}
          activeSection={isCompanySection}
          toggleActiveSection={(action) => toggleCompanySection(action)}
          isStore={data?.verifiedForShop as boolean}
          handleToggleStore={handleToggleStore}
          loading={loading}
          storeLoading={isStoreLoading}
        />
        <BlueTickRequest />
      </>
    ) : isCompanySection === e_selectedSection.rehber ? (
      <>
        <Header
          isNew={isNew(type)}
          activeSection={isCompanySection}
          toggleActiveSection={(action) => toggleCompanySection(action)}
          isStore={data?.verifiedForShop as boolean}
          handleToggleStore={handleToggleStore}
          loading={loading}
          storeLoading={isStoreLoading}
        />
        <CompanyGuideTab data={data} loading={loading} id={detailId as number} refetch={() => refetch()} />
      </>
    ) : (
      <></>
    );
  // }, [isCompanySection, data]);

  return (
    <Col xs={12} xl={11} xxl={10} className="mx-auto">
      <PagesLayout>{RenderComponent}</PagesLayout>
    </Col>
  );
}
interface HeaderProps {
  isNew: boolean;
  isLeader?: boolean;
  isStore: boolean;
  activeSection: e_selectedSection;
  toggleActiveSection?: (action: e_selectedSection) => void;
  handleToggleLeader?: VoidFunction;
  handleToggleStore?: () => void;
  loading?: boolean;
  rehberLoading?: boolean;
  storeLoading?: boolean;
}
export const Header: FC<HeaderProps> = (props) => {
  const ref = useRef<ChildRef | null>(null);

  const [show, setShow] = useState(false);
  const {
    toggleActiveSection,
    activeSection,
    // handleToggleLeader,
    handleToggleStore,
    isLeader: isL,
    isStore: isS,
    loading,
    storeLoading,
    // rehberLoading,
  } = props;
  const { t } = useTranslation();

  const { type } = useParams();

  return (
    <header className="mb-4 d-flex align-items-center justify-content-between">
      <Drawer
        open={show}
        style={{ width: 'auto', overflowY: 'auto', zIndex: 10000, position: 'relative' }}
        className=" bg-white"
        onClose={() => setShow(false)}
        title={
          <div className="d-flex justify-content-between">
            {t('Admins Notes')}
            <Button style={{ width: 30, height: 30, padding: 5 }} onClick={() => ref.current?.refetch()}>
              <Icon Name="FiRefreshCw" color="white" />
            </Button>
          </div>
        }
        classNames={{
          wrapper: 'p-0',
          body: 'pt-2',
        }}
        // direction="right"
      >
        <CompanyNotes companyId={Number(type)} ref={ref} />
      </Drawer>

      <div className="d-flex flex-wrap gap-2">
        <Button
          onClick={() => toggleActiveSection?.(e_selectedSection.details)}
          type="button"
          color={activeSection === e_selectedSection.details ? 'primary' : 'secondary'}
          className="d-flex align-items-center"
        >
          <Icon Name="FiBook" />
          <span className="ml-2">{`${t(company)} ${t(details)}`}</span>
        </Button>
        <Button
          onClick={() => toggleActiveSection?.(e_selectedSection.blueTicketRequest)}
          type="button"
          color={activeSection === e_selectedSection.blueTicketRequest ? 'primary' : 'secondary'}
          className=" d-flex align-items-center"
        >
          <Icon Name="FiCheck" />
          <span className="ml-2">{`${t(blue)} ${t(tick)} ${t(request)}`}</span>
        </Button>
        <Button
          onClick={() => toggleActiveSection?.(e_selectedSection.rehber)}
          type="button"
          color={activeSection === e_selectedSection.rehber ? 'primary' : 'secondary'}
          className=" d-flex align-items-center"
        >
          <Icon Name="FiAward" />
          <span className="ml-2">{`${t('Rehber')}`}</span>
        </Button>
        <Button onClick={() => setShow(true)} type="button" className=" d-flex align-items-center">
          <Icon Name="FaPen" />
          <span className="ml-2">{t('Notes')}</span>
        </Button>
      </div>
      {activeSection && (
        <>
          <div className="d-flex">
            {/* <div className="d-flex " style={{ zIndex: 0 }}>
              <div
                role="button"
                onClick={handleToggleLeader}
                className="have-cover make-center position-relative d-flex align-items-center"
              >
                <FormCheck
                  style={{ width: '20px', height: '20px' }}
                  defaultChecked={isL}
                  checked={isL}
                  role="button"
                  className="user-unselect"
                  id="isRehber"
                  type="checkbox"
                  readOnly
                  aria-readonly
                  disabled={rehberLoading || loading}
                  aria-disabled={rehberLoading || loading}
                />
                <Label role="button" htmlFor={isLeader} className="ml-2 mb-0 mt-1">
                  {t(isLeader)}
                </Label>
              </div>
            </div> */}
            <div className="d-flex ml-5 " style={{ zIndex: 0 }}>
              <div
                role="button"
                onClick={handleToggleStore}
                className="have-cover make-center position-relative d-flex align-items-center"
              >
                <FormCheck
                  style={{ width: '20px', height: '20px' }}
                  disabled={storeLoading}
                  aria-disabled={storeLoading}
                  role="button"
                  id={isStore}
                  checked={isS}
                  className="user-unselect"
                  readOnly
                  aria-readonly
                  defaultChecked={isS}
                  type="checkbox"
                />
                <Label role="button" htmlFor={isStore} className="ml-2 mb-0 mt-1">
                  {t(isStore)}
                </Label>
              </div>
            </div>
          </div>
        </>
      )}
    </header>
  );
};

interface CommonProps {
  activeSection: e_selectedSection;
  toggleCompanySection: (action: e_selectedSection) => void;
  data: t_company;
  loading: boolean;
  id: number;
  refetch: t_refetch;
  error: any;
  handleToggleStore: () => void;
  isStoreLoading: boolean;
  isStore: boolean;
  // toggleIsStore: (val: boolish) => void;
}

function UpdateCompany(props: CommonProps) {
  const {
    toggleCompanySection,
    activeSection,
    data,
    id,
    loading,
    isStoreLoading,
    refetch,
    error,
    handleToggleStore,
    isStore,
    // toggleIsStore,
  } = props;
  const [isRehber, setIsRehber] = useState({
    value: data?.guideStatus === COMPANY_REHBER_STATUS.ACCEPTED ? true : false,
    loading: false,
  });

  // const handleToggleLeader = () => {
  //   console.log(data);

  //   if (data.id) {
  //     setIsRehber((p) => ({ ...p, loading: true }));
  //     SweetAlert.fire({
  //       title: t(areYouSure),
  //       showCancelButton: true,
  //       icon: 'question',
  //       confirmButtonText: t(yes),
  //       cancelButtonText: t(no),
  //       reverseButtons: true,
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         SweetAlert.fire({
  //           title: 'Progressing ...',
  //           backdrop: true,
  //           icon: 'info',
  //           timerProgressBar: true,
  //           showCancelButton: false,
  //           showCloseButton: false,
  //           showDenyButton: false,
  //           showConfirmButton: false,
  //         });
  //         FetchApi({
  //           url: 'LEADER_STATUS',
  //           method: 'PATCH',
  //           data: {
  //             companyId: data.id,
  //             status:
  //               data?.guideStatus === COMPANY_REHBER_STATUS.ACCEPTED ||
  //               data?.guideStatus === COMPANY_REHBER_STATUS.NONE ||
  //               data?.guideStatus === COMPANY_REHBER_STATUS.PENDING
  //                 ? COMPANY_REHBER_STATUS.REJECTED
  //                 : data?.guideStatus === COMPANY_REHBER_STATUS.REJECTED
  //                 ? COMPANY_REHBER_STATUS.ACCEPTED
  //                 : '',
  //           },
  //         })
  //           .then((res: any) => {
  //             console.log(res);

  //             if (res.data) {
  //               SweetAlert.close();
  //               toast.success('Updated');
  //               setIsRehber((p) => ({ value: !p.value, loading: false }));
  //             } else {
  //               SweetAlert.close();
  //               toast.error(res?.response?.data?.devMessage);
  //             }
  //           })
  //           .catch((err: any) => {
  //             SweetAlert.close();
  //             console.log(err);

  //             toast.error(err?.message || 'Failed');
  //             setIsRehber((p) => ({ ...p, loading: false }));
  //           });
  //       } else {
  //         SweetAlert.close();
  //       }
  //     });
  //   }
  // };

  useEffect(() => {
    if (data) {
      setIsRehber({
        loading: false,
        value: !!data?.verifiedForRehber,
      });
      // toggleIsStore(!!data?.verifiedForShop);
    }
  }, [data]);

  return (
    <div className="mx-auto d-flex flex-column">
      <Loading loading={loading} failed={!!error} onRetry={() => refetch()}>
        <Header
          // handleToggleLeader={handleToggleLeader}
          isLeader={isRehber.value}
          isStore={isStore}
          isNew={false}
          activeSection={activeSection}
          toggleActiveSection={toggleCompanySection}
          handleToggleStore={handleToggleStore}
          loading={loading}
          storeLoading={isStoreLoading}
          rehberLoading={isRehber.loading}
        />

        <CompaniesDetail isNew={false} id={id} provideData={data} />
      </Loading>
    </div>
  );
}

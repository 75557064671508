import { add, company } from 'tools/i18n/constants/i18n';
import { isTrophy } from 'tools/methods';
import { useTranslation } from 'react-i18next';
import useQuery from 'hooks/useQuery';
import { GET_COMPANY_LIST } from 'services/graphql/queries/companies';
import { t_blueTickStatus } from '../../../../tools/types';
import { useSearchParams } from 'react-router-dom';
import { APP_HELPER, BLUE_TICKET_STATUS, COMPANY_REHBER_STATUS } from 'tools/enums';

export type companyItem = {
  companyName: string;
  primaryPhone: string;
  id: number;
  secondaryPhone: string;
  yearOfStablish: number;
  verifiedForRehber: boolean;
  verifiedForShop: boolean;
  logoUrl: string;
  blueMark: {
    status: t_blueTickStatus;
  };
};
export type filter = {
  blueTickStatus: BLUE_TICKET_STATUS | undefined;
  sortType: 'ASC' | 'DESC' | undefined;
  sortBy: 'createdAt' | 'profilePercentage' | 'companyName' | undefined;
  guideStatus?: COMPANY_REHBER_STATUS | undefined;
  isShop?: boolean | null;
  cities?: string[] | null;
  counties: string[] | null;
  category: string | null;
  logo: boolean | null;
  job: boolean | null;
  equipment: boolean | null;
};
export type companyFilterQuery = {
  sort:
    | {
        sortBy: filter['sortBy'] | undefined;
        sortType: filter['sortType'] | undefined;
      }
    | undefined;
  blueTickStatus: filter['blueTickStatus'] | undefined;
  isShop: filter['isShop'] | undefined;
  guideStatus: filter['guideStatus'] | undefined;
  query: string | undefined;
};
export default function useCategory() {
  const [param] = useSearchParams();
  const { data, loading, error, refetch, fetchMore } = useQuery<
    { getCompanyListAndDetails: { listResponse: { data: unknown[]; count: number } } },
    any
  >(GET_COMPANY_LIST, {
    defaultOptions: {
      variables: {
        page: param.get('page') ? parseInt(param.get('page') as string) : +APP_HELPER.tableDefaultPage,
        size: param.get('size') ? parseInt(param.get('size') as string) : +APP_HELPER.tableDefaultSize,
        sort: {
          sortBy: param.get('sortBy') || 'createdAt',
          sortType: param.get('sortType') || 'DESC',
        },
        blueTickStatus: param.get('blueTickStatus') || undefined,
        isShop: param.get('isShop') === 'false' ? false : param.get('isShop') === 'true' ? true : undefined,
        guideStatus: param.get('guideStatus') ? param.get('guideStatus') : undefined,
      },
    },
  });
  const { t } = useTranslation();
  const isEmptyList = isTrophy(data?.getCompanyListAndDetails?.listResponse?.data);
  const addNewTitle = `${t(add)} ${t(company)} `;
  return {
    addNewTitle,
    isEmptyList,
    loading,
    refetch,
    error,
    t,
    data: data?.getCompanyListAndDetails?.listResponse?.data,
    count: data?.getCompanyListAndDetails?.listResponse?.count,
    fetchMore,
  };
}

import React, { ReactNode } from 'react';
import { Col, Container, Row } from 'reactstrap';
import PagesHeader, { PagesHeaderProps } from 'pages/components/layout/header';
import PagesMain from 'pages/components/layout/main';
import { APP_PAGE_LAYOUT_RESPONSIVE_SIZES } from 'tools/constants';
import Breadcrumb from 'layout/breadcrumb';
import { t_dashboardLinkKey } from 'route/appRoutes';

interface PageLayoutProps extends PagesHeaderProps {
  children?: ReactNode;
  newSectionButtonLabel?: string;
  newSectionButtonParam?: string;
  newSectionLink?: t_dashboardLinkKey;
  newButtonSideText?: string;
  onBackBtn?: VoidFunction;
}

export default function PagesLayout(props: PageLayoutProps) {
  const {
    children,
    backLink,
    newSectionButtonLabel,
    backLinkTitle,
    newSectionButtonParam,
    dropDownOption,
    newSectionLink,
    backLinkParam,
    newButtonSideText,
    onBackBtn,
    customHeader,
  } = props;
  const activeHeader = !!(
    newSectionButtonLabel ||
    newSectionButtonParam ||
    backLinkTitle ||
    backLink ||
    dropDownOption ||
    newSectionLink ||
    backLinkParam
  );
  return (
    <Container fluid>
      <Row>
        <Col className="mx-auto" {...APP_PAGE_LAYOUT_RESPONSIVE_SIZES}>
          <div className="d-flex flex-wrap my-3 align-items-center justify-content-between">
            {activeHeader && (
              <PagesHeader
                newSectionButtonLabel={newSectionButtonLabel}
                newSectionButtonParam={newSectionButtonParam}
                backLinkTitle={backLinkTitle}
                backLink={backLink}
                dropDownOption={dropDownOption}
                newSectionLink={newSectionLink}
                backLinkParam={backLinkParam}
                newButtonSideText={newButtonSideText}
                onBackBtn={onBackBtn}
                customHeader={customHeader}
              />
            )}
            <Breadcrumb />
          </div>

          <PagesMain>{children}</PagesMain>
        </Col>
      </Row>
    </Container>
  );
}

import React, { FC, useState } from 'react';
import SectionLayout from 'pages/ads/jobAds/components/layout/sectionLayout';
import { useTranslation } from 'react-i18next';
import { ad, status } from 'tools/i18n/constants/i18n';
import { BAZAAR_ADS_STATUS } from 'tools/enums';
import Icon, { IconsName } from 'components/icons';
import { FilterAdsCommonProps } from 'pages/ads/bazzarAds/filter/index';
import { Controller } from 'react-hook-form';

type AdStatusProps = FilterAdsCommonProps;

export default function BazzarAdStatus(props: AdStatusProps) {
  const { t } = useTranslation();
  const { control } = props;
  const [currentActiveStatus, setCurrentActiveStatus] = useState<BAZAAR_ADS_STATUS>();
  const handleChange = (val: BAZAAR_ADS_STATUS) => {
    setCurrentActiveStatus(val);
  };
  return (
    <SectionLayout icon="FiActivity" title={`${t(ad)} ${t(status)}`}>
      <div className="d-flex align-items-center flex-wrap">
        <Controller
          control={control}
          name="status"
          render={({ field: { onChange } }) => {
            return (
              <>
                <Status
                  dataContent={BAZAAR_ADS_STATUS.ACTIVE}
                  currentActiveStatus={currentActiveStatus}
                  changeActiveStatus={(val: any) => {
                    onChange(val);
                    handleChange(val);
                  }}
                  icon="FiActivity"
                />
                <Status
                  dataContent={BAZAAR_ADS_STATUS.EXPIRED}
                  currentActiveStatus={currentActiveStatus}
                  changeActiveStatus={(val: any) => {
                    onChange(val);
                    handleChange(val);
                  }}
                  icon="FaExclamationTriangle"
                />
                <Status
                  dataContent={BAZAAR_ADS_STATUS.DE_ACTIVE}
                  currentActiveStatus={currentActiveStatus}
                  changeActiveStatus={(val: any) => {
                    onChange(val);
                    handleChange(val);
                  }}
                  icon="FiX"
                />
                <Status
                  dataContent={BAZAAR_ADS_STATUS.BAN}
                  currentActiveStatus={currentActiveStatus}
                  changeActiveStatus={(val: any) => {
                    onChange(val);
                    handleChange(val);
                  }}
                  icon="FaBan"
                />
                <Status
                  dataContent={BAZAAR_ADS_STATUS.DRAFT}
                  currentActiveStatus={currentActiveStatus}
                  changeActiveStatus={(val: any) => {
                    onChange(val);
                    handleChange(val);
                  }}
                  icon="FaBan"
                />
              </>
            );
          }}
        />
      </div>
    </SectionLayout>
  );
}

interface StatusProps {
  currentActiveStatus?: BAZAAR_ADS_STATUS;
  changeActiveStatus: (currentActiveStatus: BAZAAR_ADS_STATUS) => void;
  dataContent: BAZAAR_ADS_STATUS;
  icon: IconsName;
}
const Status: FC<StatusProps> = (props) => {
  const { currentActiveStatus, changeActiveStatus, dataContent, icon } = props;
  return (
    <div
      onClick={() => changeActiveStatus(dataContent)}
      className={`p-2 m-1 d-flex align-items-center rounded-pill ${
        currentActiveStatus === dataContent ? 'shadow-bg-active' : 'shadow-bg-notActive'
      }`}
    >
      <Icon Name={icon} />
      <span className="ml-1">{BAZAAR_ADS_STATUS[dataContent]}</span>
    </div>
  );
};

import { dataToTree } from 'components/tree-select-checkbox/utils';
import { useGetStore, useMutation } from 'hooks';
import { useParams } from 'react-router-dom';
import {
  ADD_WORK_EXPERIENCE_REJECTION,
  UPDATE_WORK_EXPERIENCE_REJECTION,
} from 'services/graphql/queries/workExperienceRejection';
import { isNew } from 'tools/methods';

export default function useExperienceExpection() {
  const { type } = useParams();
  const [createExecute] = useMutation(ADD_WORK_EXPERIENCE_REJECTION);
  const [updateExecute] = useMutation(UPDATE_WORK_EXPERIENCE_REJECTION);
  const selectedNode = useGetStore().Tree.selectedNode;
  const treeData = useGetStore().Tree.treeData;
  return {
    treeData: dataToTree(treeData, {
      childKey: 'sections',
      iconKey: 'imageUrl',
      id: '_id',
      labelKey: 'originalText',
    }),
    selectedNode,
    isNew: isNew(type),
    createExecute,
    updateExecute,
  };
}

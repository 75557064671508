import useQuery from 'hooks/useQuery';
import { GET_SINGLE_HOTEL } from 'services/graphql/queries/hotel/hotel';
import { useParams } from 'react-router-dom';

export default function useDetail() {
  const { type } = useParams();
  const { data, loading, error } = useQuery(GET_SINGLE_HOTEL, {
    variables: {
      id: parseInt(type as string),
    },
  });
  return { data: data?.findOneHotel, loading, error };
}

import { Loading } from 'components';
import SubmitButton from 'components/buttons/submitButton';
import OnlyCharacter from 'components/inputs/onlyCharacter';
import ShowError from 'components/showError';
import useMutation from 'hooks/useMutation';
import useQuery from 'hooks/useQuery';
import PagesLayout from 'pages/components/layout';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Col, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import {
  ADD_DOCUMENT_TYPE,
  GET_ONE_DOCUMENT_BY_ID,
  UPDATE_DOCUMENT_TYPE,
} from 'services/graphql/queries/documentTypes';
import Styled from 'styled-components';
import {
  add,
  created,
  documentType,
  documentTypes,
  enter,
  hidden,
  name,
  please,
  record,
  required,
  save,
  successfully,
  update,
  updated,
} from 'tools/i18n/constants/i18n';
import { goBack, isNew } from 'tools/methods';
import { t_languageKeys } from 'tools/types';

const FormG = Styled(FormGroup)`
  min-height : 100px;
  max-height : 100px;
  box-sizing : border-box;
`;
const Checkbox = Styled(Input)`
  width : 20px;
  height : 20px
`;
export default function DocumentTypeDetail() {
  const { type } = useParams();
  const { t } = useTranslation();
  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <PagesLayout backLinkTitle={`${t(documentTypes)}`} backLink="documentTypes" backLinkParam="/">
        <Card>
          <CardHeader>
            <h4>{`${t(isNew(type) ? add : update)} ${t(documentType)}`}</h4>
          </CardHeader>
          <CardBody>
            {isNew(type) ? <NewDocumentType isNew={true} /> : <UpdateDocumentType isNew={false} id={type as string} />}
          </CardBody>
        </Card>
      </PagesLayout>
    </Col>
  );
}

interface DocumentTypesCommonProps {
  isNew: boolean;
}

function NewDocumentType<T extends DocumentTypesCommonProps>(props: T): JSX.Element {
  return <DocumentTypeDetailForm isNew={props.isNew} />;
}
interface UpdateAgeRangeProps extends DocumentTypesCommonProps {
  id: string;
}

function UpdateDocumentType<T extends UpdateAgeRangeProps>({ id, isNew }: T) {
  const { data, loading } = useQuery(GET_ONE_DOCUMENT_BY_ID, {
    variables: {
      id: parseInt(id as string),
    },
  });
  console.log(data);
  const realData = data?.getDocumentTypeById;
  return (
    <Loading loading={loading}>
      <DocumentTypeDetailForm
        isNew={isNew}
        isRequired={realData?.isRequired}
        isHidden={realData?.isHidden}
        language={realData?.language?.map((item: languageContent) => ({
          name: item.name,
          language: item.language,
          id: item.id,
        }))}
        id={id}
      />
    </Loading>
  );
}

type languageContent = {
  language: t_languageKeys;
  name: string;
  id?: number;
};
interface t_defaultValues {
  customError?: string;
  id?: string;
  isRequired?: boolean;
  isHidden?: boolean;
  language?: languageContent[];
}

interface DocumentTypesFormProps extends t_defaultValues {
  isNew: boolean;
}

type t_vars = {
  id?: number;
  isRequired?: boolean;
  isHidden?: boolean;
  contents: languageContent[];
};
function DocumentTypeDetailForm<T extends Omit<DocumentTypesFormProps, 'customError'>>(props: T) {
  const { t } = useTranslation();
  const defaultLanguages: languageContent[] = [
    {
      name: '',
      language: 'tr',
    },
    {
      name: '',
      language: 'en',
    },
  ];
  const { language = defaultLanguages, isRequired = false, isHidden = false, id, isNew } = props;

  const [createExecute, { loading }] = useMutation<any, t_vars>(ADD_DOCUMENT_TYPE);
  const [updateExecute, { loading: loadingUpdate }] = useMutation<any, t_vars>(UPDATE_DOCUMENT_TYPE);
  const DEFAULT_VALUES: t_defaultValues = {
    language: language.sort((a) => (a.language === 'tr' ? -1 : 1)),
    isRequired,
    isHidden,
    id,
  };
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<t_defaultValues>({
    defaultValues: DEFAULT_VALUES,
  });
  const onSubmit = (data: Omit<t_defaultValues, 'id'> = DEFAULT_VALUES) => {
    console.log(data);
    createExecute({
      variables: {
        isRequired: data.isRequired as boolean,
        contents: data.language as languageContent[],
      },
    })
      .then((res) => {
        if (res.data) {
          toast.success(`${t(record)} ${t(created)} ${t(successfully)}`);
          goBack();
        } else {
          //@ts-ignore
          setValue('customError', res.errors?.message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const onUpdate = (data: t_defaultValues = DEFAULT_VALUES) => {
    console.log(data);
    updateExecute({
      variables: {
        isRequired: data.isRequired as boolean,
        isHidden: !!data.isHidden,
        contents: data.language as languageContent[],
        id: parseInt(id as string),
      },
    })
      .then((res) => {
        if (res.data) {
          toast.success(`${t(record)} ${t(updated)} ${t(successfully)}`);
          goBack();
        } else {
          //@ts-ignore
          setValue('customError', res.errors?.message);
        }
      })
      .catch((err) => {
        console.error(err);
        setValue('customError', err?.message);
      });
  };
  console.log(errors);
  return (
    <Form onSubmit={handleSubmit(isNew ? onSubmit : onUpdate, console.error)}>
      <FormGroup>{!!getValues('customError') && <ShowError>{getValues('customError')}</ShowError>}</FormGroup>
      <FormGroup className="d-flex align-items-center justify-content-between">
        <FormG className="d-flex flex-column flex-grow-1">
          <Controller
            control={control}
            defaultValue="tr"
            name="language.0.language"
            render={({ field: { onChange } }) => {
              return (
                <Select isDisabled defaultValue={{ value: 'tr', label: 'Turkish' }} onChange={(val) => onChange(val)} />
              );
            }}
          />

          <OnlyCharacter
            className="mt-3"
            isNumberAllowed
            isEveryCharacterAllowed
            isRequired
            control={control}
            placeholder={`${t(please)} ${t(enter)} ${t(name)}`}
            name="language.0.name"
            defaultValue={DEFAULT_VALUES?.language?.find?.((item) => item.language === 'tr')?.name}
          />
          <FormFeedback>{errors?.language?.[0]?.name?.message}</FormFeedback>
        </FormG>
        <FormG className="d-flex flex-column flex-grow-1 ml-3">
          <Controller
            control={control}
            defaultValue="en"
            name="language.1.language"
            render={({ field: { onChange } }) => {
              return <Select isDisabled defaultValue={{ value: 'en', label: 'English' }} onChange={onChange} />;
            }}
          />
          <OnlyCharacter
            placeholder={`${t(please)} ${t(enter)} ${t(name)}`}
            className="mt-3"
            isNumberAllowed
            isEveryCharacterAllowed
            isRequired
            control={control}
            name="language.1.name"
            defaultValue={DEFAULT_VALUES?.language?.find?.((item) => item.language === 'en')?.name}
          />
          <FormFeedback>{errors?.language?.[1]?.name?.message}</FormFeedback>
        </FormG>
      </FormGroup>
      <FormGroup>
        <Controller
          control={control}
          name="isRequired"
          defaultValue={DEFAULT_VALUES.isRequired}
          render={({ field: { onChange } }) => {
            return (
              <Checkbox
                role="button"
                id="isRequired"
                type="checkbox"
                defaultChecked={DEFAULT_VALUES.isRequired}
                onChange={onChange}
              />
            );
          }}
        />
        <Label role="button" className="ml-2 mt-1" htmlFor="isRequired">
          {t(required)}
        </Label>
      </FormGroup>
      {!isNew && (
        <FormGroup className="mt-3">
          <Controller
            control={control}
            name="isHidden"
            defaultValue={DEFAULT_VALUES.isHidden}
            render={({ field: { onChange } }) => {
              return (
                <Checkbox
                  role="button"
                  id="isHidden"
                  type="checkbox"
                  defaultChecked={DEFAULT_VALUES.isHidden}
                  onChange={onChange}
                />
              );
            }}
          />
          <Label role="button" className="ml-2 mt-1" htmlFor="isHidden">
            {t(hidden)}
          </Label>
        </FormGroup>
      )}
      <SubmitButton loading={loading || loadingUpdate}>{t(isNew ? save : update)}</SubmitButton>
    </Form>
  );
}

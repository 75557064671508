import useQuery from 'hooks/useQuery';
import { useParams } from 'react-router-dom';
import { FIND_ONE_BAZAAR_ADS } from 'services/graphql/queries/bazzarAds';
import { t_dataTypes } from './useBazzarAds';

export default function useBazzarAdsDetail() {
  const { type } = useParams();
  const { data, error, loading, refetch } = useQuery(FIND_ONE_BAZAAR_ADS, {
    variables: {
      id: parseInt(type as string),
    },
  });
  return { data: data?.findOneBazaarPoster as t_dataTypes, error, loading, refetch };
}

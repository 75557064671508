import { RouteObject } from 'react-router-dom';
import Signing from 'pages/authentication/signin';
import React from 'react';
import ResetPassword from 'pages/authentication/resetPassword';
import ForgetPassword from 'pages/authentication/forget-password';

export const publicRoutes: RouteObject[] = [
  { path: '', element: <Signing /> },
  { path: 'reset-password/:tk', element: <ResetPassword /> },
  { path: 'forget-password', element: <ForgetPassword /> },
];

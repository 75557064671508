import { Card, CardBody } from 'reactstrap';
import PagesLayout from 'pages/components/layout';
import React from 'react';

export default function Setting() {
  return (
    <PagesLayout>
      <Card>
        <CardBody>
          <h1>Coming Soon ...</h1>
        </CardBody>
      </Card>
    </PagesLayout>
  );
}

import { gql } from '@apollo/client';

export const GET_ALL_DOCUMENT_TYPES = gql`
  query GetAllDocumentTypes {
    getDocumentType {
      id
      isRequired
      isHidden
      language {
        id
        name
        language
      }
    }
  }
`;

export const GET_ONE_DOCUMENT_BY_ID = gql`
  query GetOneDocumentByID($id: Float!) {
    getDocumentTypeById(id: $id) {
      id
      isRequired
      isHidden
      language {
        id
        name
        language
      }
    }
  }
`;

export const ADD_DOCUMENT_TYPE = gql`
  mutation AddDocumentType($isRequired: Boolean!, $contents: [CreateDocumentTypeLanguageDto!]!) {
    createDocumentType(data: { isRequired: $isRequired, contents: $contents })
  }
`;

export const UPDATE_DOCUMENT_TYPE = gql`
  mutation UpdateDocumentType(
    $id: Int!
    $isRequired: Boolean!
    $contents: [UpdateDocumentTypeLanguageDto!]!
    $isHidden: Boolean!
  ) {
    updateDocumentType(data: { id: $id, isRequired: $isRequired, contents: $contents, isHidden: $isHidden })
  }
`;

import SubmitButton from 'components/buttons/submitButton';
import CityDropdown from 'components/dropdown/cityDropdown';
import UploadProfileImage from 'components/uploadProfileImage';
import { useToggle } from 'hooks';
import PagesLayout from 'pages/components/layout';
import React, { useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import FetchApi from 'services/api/axios';
import { Add, Name } from 'tools/i18n/constants';
import {
  city,
  create,
  created,
  enter,
  icon,
  name,
  please,
  successfully,
  universities,
  university,
  update,
  updated,
} from 'tools/i18n/constants/i18n';
import { errorFinder, goBack } from 'tools/methods';
import { t_university } from './types';
import useUniversityDetail from './useUniversityDetail';

export default function UniversityDetail() {
  const { selectedNode, createExecute, updateExecute, isNew } = useUniversityDetail();
  const [loading, toggleLoading] = useToggle(false);
  const { t } = useTranslation();
  const DEFAULT_VALUES: t_university = useMemo(() => {
    return {
      _id: selectedNode?._id,
      name: selectedNode?.name,
      city: selectedNode?.city,
      logo: selectedNode?.logo,
    };
  }, [selectedNode]);
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm<t_university>({
    defaultValues: DEFAULT_VALUES,
  });
  const handleUploadIcon = (args: Required<Omit<t_university, 'customError'>>) => {
    const formData = new FormData();
    formData.append('file', args.logo as File);
    formData.append('id', args._id as string);
    formData.append('city', args.city as string);
    formData.append('name', args.name as string);
    FetchApi({
      url: 'UNIVERSITY',
      method: 'PATCH',
      data: formData,
    })
      .then((res) => {
        if (res.data) {
          toast.success(`${t(successfully)} ${t(isNew ? created : updated)}`);
          goBack();
        }
        toggleLoading(false);
      })
      .catch((err) => {
        toggleLoading(false);
        setValue('customError', errorFinder(err.message));
      });
  };

  const onSubmit: SubmitHandler<t_university> = (data) => {
    console.log(data);
    toggleLoading(true);
    setValue('customError', '');
    createExecute({
      variables: {
        name: data.name,
        city: data.city,
      },
    })
      .then((res) => {
        if (res.data?.employmentCreateUniversity) {
          if (data.logo) {
            handleUploadIcon({
              city: data.city,
              logo: data.logo,
              name: data.name,
              _id: res.data?.employmentCreateUniversity._id,
            });
          } else {
            toast.success(`${t(successfully)} ${t(created)}`);
            toggleLoading(false);
            goBack();
          }
        } else {
          setValue('customError', errorFinder(res?.errors?.[0]?.message));
          toggleLoading(false);
        }
      })
      .catch((err) => {
        setValue('customError', errorFinder(err));
        toggleLoading(false);
      });
  };
  const onUpdate: SubmitHandler<t_university> = (data) => {
    console.log(data);
    setValue('customError', '');
    toggleLoading(true);
    updateExecute({
      variables: {
        id: data._id,
        name: data.name,
        city: data.city,
      },
    })
      .then((res) => {
        if (res.data.employmentUpdateUniversity) {
          if (data.logo && data.logo !== DEFAULT_VALUES.logo) {
            handleUploadIcon(data);
          } else {
            toast.success(`${t(successfully)} ${t(updated)}`);
            toggleLoading(false);
            goBack();
          }
        } else {
          setValue('customError', errorFinder(res?.errors?.[0]?.message));
          toggleLoading(false);
        }
      })
      .catch((err) => {
        setValue('customError', errorFinder(err));
        toggleLoading(false);
      });
  };

  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <PagesLayout backLinkTitle={t(universities)}>
        <Card>
          <CardHeader className="py-3">
            <div className="d-flex align-items-center justify-content-between">
              <h5>{`${t(isNew ? Add : update)} ${t(university)}`}</h5>
              {/* <Button color="danger">{t(remove)}</Button> */}
            </div>
          </CardHeader>
          <CardBody>
            <Row className="mx-auto">
              <Col xxl="12" className="mx-auto">
                <Form onSubmit={handleSubmit(isNew ? onSubmit : onUpdate, console.error)}>
                  {!!getValues('customError') && (
                    <div className="rounded my-2 shadow-bg-danger p-2">{getValues('customError')}</div>
                  )}
                  <FormGroup className="bg-gray p-3">
                    <Label className="whitespace-nowrap">{`${t(university)} ${t(icon)}`}</Label>
                    <Controller
                      control={control}
                      name="logo"
                      render={({ field: { onChange } }) => {
                        return (
                          <UploadProfileImage
                            avatarSize="70px"
                            maxSize={512000}
                            defaultImage={DEFAULT_VALUES.logo as string}
                            onSelect={(file: File) => {
                              onChange(file);
                            }}
                            onError={(e) => setError('logo', { message: e })}
                          />
                        );
                      }}
                    />
                    <FormFeedback>{errors?.logo?.message}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label>{`${t(university)} ${t(name)}`}</Label>
                    <Controller
                      name="name"
                      control={control}
                      rules={{ required: `${t(please)} ${t(enter)} ${t(university)} ${t(Name)}` }}
                      defaultValue={DEFAULT_VALUES.name}
                      render={({ field: { onChange } }) => {
                        return (
                          <Input
                            defaultValue={DEFAULT_VALUES.name}
                            onChange={onChange}
                            placeholder={'Istanbul University'}
                          />
                        );
                      }}
                    />
                    <FormFeedback>{errors?.name?.message}</FormFeedback>
                  </FormGroup>

                  <FormGroup>
                    <Label>{t(city)}</Label>
                    <Controller
                      control={control}
                      name="city"
                      rules={{ required: `${t(please)} ${t(enter)} ${t(city)}` }}
                      render={({ field: { onChange } }) => {
                        return (
                          <CityDropdown
                            onSelect={(city) => {
                              if (city) onChange(city.value);
                            }}
                            defaultValue={
                              DEFAULT_VALUES.city
                                ? { label: DEFAULT_VALUES.city, value: DEFAULT_VALUES.city }
                                : undefined
                            }
                          />
                        );
                      }}
                    />
                    <FormFeedback>{errors?.city?.message}</FormFeedback>
                  </FormGroup>

                  <SubmitButton loading={loading}>{t(isNew ? create : update)}</SubmitButton>
                </Form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </PagesLayout>
    </Col>
  );
}

import React, { FC, useMemo, useState } from 'react';
import { Button, Input } from 'reactstrap';
import DataTables, { TableSectionTitle } from 'components/tables/dataTable';
import { useTranslation } from 'react-i18next';
import { detail, isSearchable, level, list, section, students, verifyStatus } from 'tools/i18n/constants/i18n';
import { useGetStore, useUpdateEffect } from 'hooks';
import Icon from 'components/icons';
import { EDUCATION_GRADE, STUDENT_ACCEPTATION_STATUS } from 'tools/enums';
import { Loading } from 'components';
import { ApolloError } from '@apollo/client';
import useStudent from 'pages/schoolDashboard/student-management/useStudent';
import Link from 'components/link';

const StudentManagement = () => {
  const { refetch, error, data, loading, fetchMore } = useStudent();
  return (
    <Table
      error={error}
      fetchMore={fetchMore}
      refetch={refetch}
      count={data.count}
      loading={loading}
      data={data.data}
    />
  );
};

interface TableProps {
  fetchMore: any;
  refetch: any;
  error?: ApolloError;
  data: any[];
  loading: boolean;
  count?: number;
}

const Table: FC<TableProps> = (props) => {
  const { refetch, error, data, loading, fetchMore, count } = props;
  const [realData, setRealData] = useState(data || []);
  const [currentPerRow, setCurrentPerRow] = useState(50);
  const { t } = useTranslation();

  /*const onSearch = async (key: string) => {
    /!*  const result = await refetch({
      query: key,
    });
    if (result.data) {
      setRealData(() => result.data.findAllAdmins);
      await Promise.resolve();
    } else {
      await Promise.reject();
    }*!/
  };*/

  const schoolInformation = useGetStore()?.User?.userInfo?.schoolInformation;
  const grade = schoolInformation?.grade;
  const schoolName = schoolInformation?.name;
  const columnData = useMemo(() => {
    return [
      {
        name: <TableSectionTitle name="FiUser" title={t(students)} />,
        center: false,
        cell: (r: { firstName: any; lastName: any; id: any }) => {
          const fullName = `${r.firstName} ${r.lastName}`;
          return <span className="text-black">{fullName}</span>;
        },
      },
      {
        name: <TableSectionTitle title={t(grade === EDUCATION_GRADE.UNIVERSITY ? section : level)} name="FiLayers" />,
        center: false,
        selector: (r: { level: string; section: { originalName: string } }) => r?.section?.originalName || r.level,
      },
      {
        name: <TableSectionTitle title={t(verifyStatus)} name="FiLayers" />,
        cell: (r: { status?: STUDENT_ACCEPTATION_STATUS }) => {
          return (
            <div
              className={`make-center ${
                r.status === 'PENDING'
                  ? 'shadow-text-notActive'
                  : r.status === 'ACCEPTED'
                  ? 'shadow-text-success'
                  : r.status === 'REJECTED'
                  ? 'shadow-text-danger'
                  : 'shadow-text-warning'
              }`}
            >
              <Icon
                Name={
                  r.status === 'PENDING'
                    ? 'AiOutlineLoading3Quarters'
                    : r.status === 'ACCEPTED'
                    ? 'AiOutlineCheck'
                    : r.status === 'REJECTED'
                    ? 'AiOutlineException'
                    : 'FiX'
                }
              />
              <span className="ml-2">{r.status}</span>
            </div>
          );
        },
      },
      {
        name: <TableSectionTitle title={t(isSearchable)} name="FiLayers" />,
        center: true,
        cell: (r: { isSearchable?: boolean }) => (
          <Input
            type="checkbox"
            style={{ width: '18px', height: '18px' }}
            readOnly
            aria-readonly
            defaultChecked={r.isSearchable}
            checked={r.isSearchable}
          />
        ),
      },
      {
        name: '',
        cell: (r: { _id: any; fullName: any }) => {
          if (r._id) {
            return (
              <Link to="studentDetail" param={r?._id} className="text-white ml-auto">
                <Button color="primary">{t(detail)}</Button>
              </Link>
            );
          } else return '';
        },
      },
    ];
  }, []);

  const onPageChange = async (page: number) => {
    // toggler(true);
    const result = await refetch({
      pageNumber: page,
      size: currentPerRow,
    });
    if (result.data) {
      setRealData(() => result.data.EmploymentSchoolStudentList);
      await Promise.resolve();
    } else {
      await Promise.reject();
    }
  };

  const onChangeRowsPerPage = async (currentPerRow: number, currentPage: number) => {
    console.log(currentPerRow, currentPage);
    setCurrentPerRow(currentPerRow);
    const result = await fetchMore({
      variables: {
        size: currentPerRow,
        page: currentPage,
      },
    });
    if (result.data) {
      setRealData(() => result.data.EmploymentSchoolStudentList);
      await Promise.resolve();
    } else {
      await Promise.reject();
    }
  };

  useUpdateEffect(() => {
    setRealData(data);
  }, [data]);
  return (
    <Loading loading={loading}>
      <DataTables
        data={realData}
        headerTitle={`${schoolName} ${t(students)} ${t(list)}`}
        searchable
        columns={columnData}
        disabled={loading || !!error}
        pagination
        onChangePage={onPageChange}
        onChangeRowsPerPage={onChangeRowsPerPage}
        paginationTotalRows={count}
      />
    </Loading>
  );
};

export default StudentManagement;

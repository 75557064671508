import SectionLayout from 'pages/ads/jobAds/components/layout/sectionLayout';
import { FormProps } from 'pages/ads/jobAds/index';
import useExperienceExpection from 'pages/content-management/employment/experienceExpectation/useExperienceExpectation';
import React, { FC, useState } from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { experienceExpectation } from 'tools/i18n/constants/i18n';

type ExperienceExpectationProps = FormProps;

export default function ExperienceExpectation(props: ExperienceExpectationProps) {
  const { t } = useTranslation();
  const { onChange } = props;
  const { data } = useExperienceExpection();
  const [currentSelected, setCurrentSelected] = useState<string[] | undefined>(undefined);
  const handleChange = (val: string) => {
    const isSelected = currentSelected?.includes(val);
    if (isSelected) {
      const filteredList = currentSelected?.filter((item) => item !== val);
      onChange?.('experienceExpectations', filteredList);
      setCurrentSelected(filteredList);
    } else {
      if (currentSelected) {
        const addedList = [...currentSelected, val];
        setCurrentSelected(addedList);
        onChange?.('experienceExpectations', addedList);
      } else {
        onChange?.('experienceExpectations', [val]);
        setCurrentSelected([val]);
      }
    }
  };
  return (
    <SectionLayout title={t(experienceExpectation)} icon="FaDatabase">
      <div className="p-2">
        <div className="d-flex flex-wrap">
          {data.map((item) => {
            return (
              <Experience
                key={item.id}
                icon={item.icon}
                dataContent={item.originalName as string}
                currentSelected={currentSelected}
                handleChange={handleChange}
                id={item.id as string}
              />
            );
          })}
        </div>
      </div>
    </SectionLayout>
  );
}
interface ExperienceProps {
  currentSelected?: string[];
  handleChange: (currentSelected: string) => void;
  dataContent: string;
  icon: string;
  id: string;
}
const Experience: FC<ExperienceProps> = (props) => {
  const { currentSelected, handleChange, dataContent, icon, id } = props;
  return (
    <div
      role="button"
      onClick={() => handleChange(id)}
      className={`p-2 m-1 d-flex align-items-center rounded-pill ${
        currentSelected?.includes(id) ? 'shadow-bg-active' : 'shadow-bg-notActive'
      }`}
    >
      <Image src={icon} width="20px" height="20px" />
      <span className="ml-1">{dataContent}</span>
    </div>
  );
};

import SearchForm from 'components/forms/form-search';
import Icon, { IconsName } from 'components/icons';
import { t_toggleValueFunc, useToggle, useUpdateEffect } from 'hooks';
import React, { FC, Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import DataTable, { IDataTableProps } from 'react-data-table-component';
import Drawer from 'react-modern-drawer';
import { useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import { Card, CardBody, CardHeader, Input } from 'reactstrap';
import { APP_HELPER } from 'tools/enums';

export type option = {
  title?: string;
  name: string;
  icon?: IconsName;
  firstItem?: boolean;
  firstItemTitle?: string;
  firstItemIcon?: IconsName;
  isDropdown?: boolean;
  dropdownOption?: {
    placeholder?: string;
    options?: { label: string; value: string; [x: string]: any }[];
    onSelect?: (val: unknown) => void;
    defaultValue?: { label: string | number; value: string | number };
  };
};

type TFilterOption<T extends boolean | undefined> = T extends true
  ? { filterOptions?: option[]; customFilter?: ReactNode }
  : undefined;

interface DataTablesProps<T, U extends boolean | undefined> extends IDataTableProps<T> {
  onRowSelect?: (row: T) => void;
  headerTitle?: ReactNode;
  searchable?: boolean;
  onSearch?: (searchKey: string) => void;
  onFilter?: (type: string | undefined) => void;
  filterable?: U;
  disableURlPagination?: boolean;
}
interface FilterOptionsProps {
  onFilter?: (type: string | undefined, value?: boolean) => void;
  disabled: boolean;
  options: option[];
  toggleDisabled: t_toggleValueFunc;
}
export type t_filterOption = {
  title: string;
  name: string;
  icon: IconsName;
  firstItem?: boolean;
};

function DataTables<T, U extends boolean | undefined>(props: DataTablesProps<T, U> & TFilterOption<U>) {
  const [param, setParam] = useSearchParams();
  const {
    headerTitle,
    searchable,
    onSearch,
    filterable,
    onFilter,
    filterOptions,
    onChangePage,
    paginationDefaultPage,
    paginationPerPage,
    disabled,
    onChangeRowsPerPage,
    customFilter,
    paginationTotalRows,
    data,
    columns,
    pagination,
    style,
    disableURlPagination,
  } = props;
  const [isDisabled, toggleDisabled] = useToggle(disabled);
  const [size, setSize] = useState<number>(parseInt(APP_HELPER.tableDefaultSize as string));
  const [open, toggleOpen] = useToggle(false);
  const handlePageChange = (pageNumber: number, pageSize?: number) => {
    if (param.get('page') === pageNumber.toString()) {
      return;
    }
    if (onChangePage) {
      if (!disableURlPagination) {
        setParam({
          page: pageNumber.toString(),
          size: pageSize?.toString() || size.toString(),
        });
      }
      toggleDisabled(true);
      onChangePage(pageNumber, pageSize || size);
    }
  };
  const handleNnChangeRowsPerPage = useCallback((currentSize: number, currentPage: number) => {
    if (onChangeRowsPerPage) {
      if (!disableURlPagination) {
        setParam({
          page: currentPage.toString(),
          size: currentSize?.toString() || size.toString(),
        });
      }

      toggleDisabled(true);
      setSize(currentSize);
      onChangeRowsPerPage(currentSize, currentPage);
    }
  }, []);
  const handleOnFilter = useCallback((type: string | undefined) => {
    if (onFilter) {
      toggleDisabled(true);
      onFilter(type);
    }
  }, []);
  const handleOnSearch = useCallback((key: string) => {
    if (onSearch) {
      toggleDisabled(true);
      onSearch(key);
    }
  }, []);

  useUpdateEffect(() => {
    toggleDisabled(false);
    toggleOpen(false);
  }, [data]);
  useUpdateEffect(() => {
    if (typeof disabled === 'boolean' && !disabled) {
      toggleDisabled(false);
    }
  }, [disabled]);

  const rowsOption = useMemo(() => {
    const SizeArray = [50, 60, 70, 80];

    return data ? (data.length < APP_HELPER.tableDefaultSize ? [data.length] : SizeArray) : SizeArray;
  }, [data]);

  const disablePagination = typeof pagination === 'boolean' && !pagination;
  return (
    <Fragment>
      {filterable && (
        <DrawerComponent open={open} toggleOpen={toggleOpen}>
          {customFilter ? (
            customFilter
          ) : (
            <FilterOptions
              toggleDisabled={toggleDisabled}
              disabled={isDisabled}
              onFilter={handleOnFilter}
              options={filterOptions as option[]}
            />
          )}
        </DrawerComponent>
      )}
      <Card className="m-0">
        <CardHeader className="py-3 h-auto d-flex align-items-center justify-content-between">
          <h5>{headerTitle}</h5>
          <div className="d-flex align-items-center justify-content-center">
            {searchable && <SearchForm onChange={handleOnSearch} />}
            {filterable && (
              <span role="button" onClick={() => toggleOpen()}>
                <Icon Name="FiSliders" />
              </span>
            )}
          </div>
        </CardHeader>
        <CardBody className="px-md-1 pb-80" style={{ ...style }}>
          <DataTable
            onChangePage={(page) => {
              handlePageChange(page);
            }}
            onChangeRowsPerPage={handleNnChangeRowsPerPage}
            disabled={isDisabled}
            striped={true}
            persistTableHead={true}
            style={{ paddingBottom: 100 }}
            paginationServer
            responsive={true}
            fixedHeader={true}
            pagination={!disablePagination}
            className="bg-transparent text-left overflow-auto h-100 pb-160"
            paginationRowsPerPageOptions={rowsOption}
            paginationDefaultPage={param.get('page') ? parseInt(param.get('page') as string) : paginationDefaultPage}
            paginationPerPage={
              param.get('size')
                ? parseInt(param.get('size') as string)
                : paginationPerPage || +APP_HELPER.tableDefaultSize
            }
            paginationTotalRows={paginationTotalRows}
            data={data}
            columns={columns}
          />
        </CardBody>
      </Card>
    </Fragment>
  );
}

export default React.memo(DataTables);

export const TableSectionTitle: FC<{ name: IconsName; title: ReactNode; className?: string }> = ({
  name,
  title,
  className = '',
}) => (
  <div className={`d-flex align-items-center justify-content-center ${className}`}>
    <span>
      <Icon Name={name} />
    </span>
    <span className="ml-1"> {title} </span>
  </div>
);

export function FilterOptions(props: FilterOptionsProps) {
  const { options, onFilter, disabled, toggleDisabled } = props;
  const [selected, setSelected] = useState<string | undefined>(undefined);
  const handleSelect = (value: string) => {
    setSelected((p) => {
      if (p === value) {
        onFilter?.(undefined, undefined);
        return undefined;
      }
      onFilter?.(value, true);
      return value;
    });
  };

  return (
    <div className="filter-option-container p-4">
      {options.map((opt) => {
        return (
          <Fragment key={opt.title}>
            {opt.firstItem && (
              <span className="h6 d-flex align-items-center">
                {opt.firstItemIcon && <Icon Name={opt.firstItemIcon} />}
                <span className={opt.firstItemIcon ? 'ml-3' : ''}>{opt?.firstItemTitle}</span>
              </span>
            )}
            {opt.firstItem && <hr />}
            {opt.isDropdown ? (
              <Select
                className="mb-3"
                isDisabled={disabled}
                //@ts-ignore
                options={
                  opt.dropdownOption?.options
                    ? [{ label: 'Select Option', value: undefined }, ...opt.dropdownOption.options]
                    : undefined
                }
                onChange={(val) => {
                  if (opt.dropdownOption?.onSelect) {
                    toggleDisabled(true);
                    opt.dropdownOption?.onSelect(val);
                  }
                }}
                defaultValue={opt.dropdownOption?.defaultValue}
                placeholder={opt.dropdownOption?.placeholder}
              />
            ) : (
              <div className="d-flex mb-3 w-100 align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  {opt.icon && <Icon Name={opt.icon} />}
                  <label role="button" htmlFor={opt.title} className="ml-2 mb-0">
                    {opt.title}
                  </label>
                </div>
                <Input
                  onClick={() => {
                    handleSelect(opt.name);
                  }}
                  role="button"
                  checked={selected === opt.name}
                  id={opt.title}
                  type="radio"
                  disabled={disabled}
                  name={opt.name}
                  className="ml-5"
                />
              </div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
}

interface DrawerComponentProps {
  open: boolean;
  toggleOpen: t_toggleValueFunc;
  children?: ReactNode;
}

const DrawerComponent: FC<DrawerComponentProps> = (props) => {
  const { open, toggleOpen, children } = props;
  return (
    <Drawer
      open={open}
      style={{ width: 'auto', overflowY: 'auto' }}
      className=" bg-white"
      onClose={() => toggleOpen(false)}
      direction="right"
    >
      <div className="h-100 p-3 bg-white">{children}</div>
    </Drawer>
  );
};

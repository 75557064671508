import useQuery from 'hooks/useQuery';
import { useMemo } from 'react';
import { GET_ALL_EMLAK_ADS_REPORT_REASONS } from 'services/graphql/queries/emlak/adsReport';
import { isTrophy, toArray } from 'tools/methods';
import { reportReason } from './types';

export default function useEmlakAdsReportReasons() {
  const { data, loading, error } = useQuery<{ realEstateReportReasonList: { count: number; data: reportReason[] } }>(
    GET_ALL_EMLAK_ADS_REPORT_REASONS
  );
  const isListEmpty: boolean = useMemo(() => isTrophy(data?.realEstateReportReasonList?.data.length), [data]);
  const updatedData = useMemo(
    () => (isListEmpty ? toArray(data?.realEstateReportReasonList.data) : []),
    [data, isListEmpty]
  );

  return { isListEmpty, data: { data: updatedData, count: data?.realEstateReportReasonList.count }, error, loading };
}

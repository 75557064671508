import Icon from 'components/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { all, unique, views } from 'tools/i18n/constants/i18n';
import { toString } from 'tools/methods';
import { numberish } from 'tools/types';

/* type view = {
  date: string;
  totalViews: numberish;
  uniqueViews: numberish;
  boost: boolean;
}; */
interface ChartsProps {
  totalViews: numberish;
  uniqueViews: numberish;
}
export default function StaticsTicsCharts(props: ChartsProps) {
  const { t } = useTranslation();
  const { uniqueViews, totalViews } = props;
  return (
    <div className="bg-white rounded mb-2">
      <div className="border-bottom p-3">
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            <Icon Name="FiEye" />
            <span className="ml-2">{`${t(all)} ${t(views)}: ${toString(totalViews)}`}</span>
          </div>
          <div className="make-center mx-auto">
            <Icon Name="FiEye" />
            <span className="ml-2">{`${t(unique)} ${t(views)}: ${toString(uniqueViews)}`}</span>
          </div>
        </div>
      </div>
      {/*   <div className="p-3">
        <ColumnChart views={} />
      </div>*/}
    </div>
  );
}

/* const ColumnChart: FC<{ views: view[] }> = ({ views }) => {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState<string[]>([]);
  const { t } = useTranslation();
  const options: ApexOptions = useMemo(() => {
    return {
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -25,
        style: {
          fontSize: '15px',
          colors: ['#304758'],
        },
      },

      xaxis: {
        categories,
        position: 'top',
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
      title: {
        text: '',
        floating: true,
        offsetY: 330,
        align: 'left',
        style: {
          color: '#444',
        },
      },
    };
  }, [categories]);

  useEffect(() => {
    // @ts-ignore
    setData(() => {
      return [
        {
          name: t(normalDays),
          color: '#979797',
          data: views
            .filter((item) => !item.boost)
            .map((item) => {
              return item?.totalViews || 0 + (item?.uniqueViews || 0);
            }),
        },
        {
          name: t(sponsoredDays),
          color: '#FC9154',
          data: views
            .filter((item) => item.boost)
            .map((item) => {
              return item?.totalViews || 0 + (item?.uniqueViews || 0);
            }),
        },
      ];
    });
  }, [views]);

  useEffect(() => {
    setCategories(
      views.map((item) => {
        const date = new Date(item.date);
        return `${date.getFullYear()}.${date.getMonth()}.${date.getDate()}`;
      })
    );
  }, [views]);
  console.log(':', categories);
  return <ReactApexChart options={options} series={data} type="bar" height={350} />;
}; */

import React, { useState } from 'react';
import { add, emlakType, emptyList, type, useAddToCategory } from 'tools/i18n/constants/i18n';
import { Card, CardBody, Col } from 'reactstrap';
import { Loading } from 'components';
import PagesLayout from 'pages/components/layout';
import useEmlakTypes from 'pages/content-management/emlak/types/useEmlakTypes';
import { useTranslation } from 'react-i18next';
import EmlakTypesTable from './components/table';
import YourListIsEmpty from 'pages/components/emptyList';
import { useToggle, useUpdateEffect } from 'hooks';
import { makeTree } from 'tools/methods';

export default function EmlakTypesList() {
  const { t } = useTranslation();
  const { loading, data, isEmptyList, error, fetchMore, refetch } = useEmlakTypes();
  const [realData, setRealData] = useState(data);
  const [queryLoading, toggleQueryLoading] = useToggle(false);
  const handleSearch = (searchKeyword: string) => {
    toggleQueryLoading(true);
    fetchMore({
      variables: {
        query: searchKeyword,
      },
    })
      .then((res) => {
        toggleQueryLoading(false);
        if (res.data?.realEstateFindAllCategoryTypes) {
          // @ts-ignore
          setRealData(makeTree(res.data.realEstateFindAllCategoryTypes));
        }
        toggleQueryLoading(false);
      })
      .catch((err) => {
        console.error(err);
        toggleQueryLoading(false);
      });
  };
  const handleRefetch = async () => {
    toggleQueryLoading(true);
    await refetch();
    toggleQueryLoading(false);
  };
  useUpdateEffect(() => {
    setRealData(data);
  }, [data]);
  console.log(data);
  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <Loading loading={loading} failed={!!error} onFail={handleRefetch}>
        <PagesLayout newSectionButtonLabel={`${t(add)} ${t(type)}`}>
          {isEmptyList ? (
            <Card>
              <CardBody>
                <YourListIsEmpty
                  title={`${t(emptyList)}, ${t(useAddToCategory)} ${t(type)}`}
                  addButtonTitle={`${t(add)} ${t(emlakType)}`}
                  addNewButton
                />
              </CardBody>
            </Card>
          ) : (
            <EmlakTypesTable onSearch={handleSearch} queryLoading={queryLoading} data={realData} />
          )}
        </PagesLayout>
      </Loading>
    </Col>
  );
}

import { gql } from '@apollo/client';

export const GET_CITY_COUNTIES = gql`
  query GetCityCounties {
    employmentCitiesAndCountiesList {
      city
      counties
    }
  }
`;

export const GET_COUNTRY_LIST = gql`
  query getCountry {
    countryList {
      id
      name
      city {
        id
        name
      }
    }
  }
`;

export const GET_COMPANY_SIZES = gql`
  query getCompanySize {
    getCompanySize {
      id
      fromStaffs
      toStaffs
      title
    }
  }
`;

export const GET_DOCUMENT_TYPE_LIST = gql`
  query GetDocumentTypeId($language: String!) {
    DocumentTypeList(language: $language) {
      id
      name
    }
  }
`;

export const GET_COUNTRY_PHONE_CODE = gql`
  query GetPhoneCodeList {
    getPhoneCodeList {
      name
      flag
      code
      dial_code
    }
  }
`;

import { gql } from '@apollo/client';

export const GET_ALL_DELETE_REASONS = gql`
  query GetAllDeleteReasons {
    getAllDeleteReason {
      id
      needToInsertCustomText
      language {
        id
        language
        content
      }
    }
  }
`;

export const GET_ONE_DELETE_REASON = gql`
  query GetOneDeleteReasons($id: Float!) {
    getDeleteReasonById(id: $id) {
      id
      needToInsertCustomText
      language {
        id
        language
        content
      }
    }
  }
`;

export const ADD_DELETE_REASON = gql`
  mutation AddDeleteReasons($needToInsertCustomText: Boolean!, $language: [CreateReasonLanguageDto!]!) {
    createDeleteReason(data: { needToInsertCustomText: $needToInsertCustomText, language: $language })
  }
`;

export const UPDATE_DELETE_REASON = gql`
  mutation UpdateDeleteReasons($needToInsertCustomText: Boolean!, $id: Int!, $language: [UpdateReasonLanguageDto!]!) {
    updateDeleteReason(data: { id: $id, needToInsertCustomText: $needToInsertCustomText, language: $language })
  }
`;

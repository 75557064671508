import React, { FC, FormEvent, useEffect, useState } from 'react';
import { Input, InputProps } from 'reactstrap';
import { EXCEPT_NUMBERS } from 'tools/constants';

interface MultiCCodeInput {
  inputsProps?: InputProps;
  onFinish: (val: string) => void;
}

const INITIAL_VALUE = {
  '1': '',
  '2': '',
  '3': '',
  '4': '',
  '5': '',
  '6': '',
};
//111111
const MultiCodeInput: FC<MultiCCodeInput> = (props) => {
  const { onFinish, inputsProps } = props;
  const [code, setCode] = useState<Record<string, string>>(INITIAL_VALUE);
  const handleFinish = (val: string, level: number) => {
    if (val) {
      setCode((prev) => ({ ...prev, [level]: val }));
    }
  };

  const handleBackSpace = (event: any, index: number) => {
    if (event.code === 'Backspace') {
      try {
        const selfElm = document.querySelector(`#multi-code-${index}`) as HTMLInputElement;
        const targetElm = document.querySelector(`#multi-code-${index - 1}`) as HTMLInputElement;
        setCode((prev) => ({ ...prev, [index]: '' }));
        selfElm.value = '';
        targetElm?.focus();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleInput = (e: FormEvent<HTMLInputElement>, index: number) => {
    const targetElm = document.querySelector(`#multi-code-${index + 1}`) as HTMLInputElement;
    const val = e.currentTarget?.value?.replace(EXCEPT_NUMBERS, '');
    e.currentTarget.value = val;
    console.log(val);
    if (val.length > 1) {
      for (let i = 1; i <= 6; i++) {
        const elm = document.querySelector(`#multi-code-${i}`) as HTMLInputElement;
        elm.value = val[i - 1] || '';
        handleFinish(val[i - 1] || '', i);
      }
    } else {
      if (val.length === 1) {
        handleFinish(val, index);
        if (targetElm) {
          targetElm?.focus();
        }
      }
    }
  };

  useEffect(() => {
    const keys = Object.keys(code);
    const isFilled = keys.every((key) => code[key]);
    if (isFilled) {
      const finalValue = keys.map((key) => code[key]).join('');
      onFinish(finalValue);
    }
  }, [code]);

  return (
    <div className="multi-code">
      <Input
        data-focused={true}
        onInput={(e) => handleInput(e, 1)}
        maxLength={6}
        id="multi-code-1"
        className="multi-code-input small-input"
        autoFocus
        onKeyDown={(e) => handleBackSpace(e, 1)}
        required
        type="tel"
        {...inputsProps}
      />
      <Input
        id="multi-code-2"
        data-focused={false}
        onInput={(e) => handleInput(e, 2)}
        maxLength={6}
        className="multi-code-input small-input"
        onKeyDown={(e) => handleBackSpace(e, 2)}
        type="tel"
        required
        {...inputsProps}
      />
      <Input
        id="multi-code-3"
        data-focused={false}
        onInput={(e) => handleInput(e, 3)}
        maxLength={6}
        className="multi-code-input small-input"
        onKeyDown={(e) => handleBackSpace(e, 3)}
        type="tel"
        required
        {...inputsProps}
      />
      <Input
        id="multi-code-4"
        data-focused={false}
        onInput={(e) => handleInput(e, 4)}
        maxLength={6}
        className="multi-code-input small-input"
        type="tel"
        required
        onKeyDown={(e) => handleBackSpace(e, 4)}
        {...inputsProps}
      />
      <Input
        id="multi-code-5"
        data-focused={false}
        onInput={(e) => handleInput(e, 5)}
        maxLength={6}
        className="multi-code-input small-input"
        type="tel"
        required
        onKeyDown={(e) => handleBackSpace(e, 5)}
        {...inputsProps}
      />
      <Input
        id="multi-code-6"
        data-focused={false}
        onInput={(e) => handleInput(e, 6)}
        maxLength={1}
        className="multi-code-input small-input"
        type="tel"
        required
        onKeyDown={(e) => handleBackSpace(e, 6)}
        {...inputsProps}
      />
    </div>
  );
};

export default MultiCodeInput;

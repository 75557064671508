import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CardHeader } from 'reactstrap';
import RemoveButton from '../removeButton';

interface UpdateAddHeaderProps {
  isNew: boolean;
  title: string;
  onClick?: VoidFunction;
}

const UpdateAddHeader: FC<UpdateAddHeaderProps> = (props) => {
  const { isNew, title, onClick } = props;
  const { t } = useTranslation();
  return (
    <CardHeader className="py-3">
      <div className="d-flex align-items-center justify-content-between">
        <h5>{t(title)}</h5>
        {!isNew && <RemoveButton onClick={onClick} />}
      </div>
    </CardHeader>
  );
};

export default UpdateAddHeader;

import type { Dayjs } from 'dayjs';

export function formatDate(value: Dayjs) {
  const parsedDate = value.format('YYYY-MM-DD') as string;
  const splitedDate = parsedDate.split('-');
  const year = parseInt(splitedDate[0]);
  const month = parseInt(splitedDate[1]);
  const day = parseInt(splitedDate[2]);
  return {
    year,
    month,
    day,
  };
}

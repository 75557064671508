import SubmitButton from 'components/buttons/submitButton';
import { useToggle } from 'hooks';
import PagesLayout from 'pages/components/layout';
import React, { useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { Add, Name } from 'tools/i18n/constants';
import {
  create,
  created,
  enter,
  name,
  please,
  software,
  softwares,
  successfully,
  update,
  updated,
} from 'tools/i18n/constants/i18n';
import { errorFinder, goBack } from 'tools/methods';
import { t_software } from './types';
import useSoftwareDetail from './useSoftwareDetail';

export default function SoftwareDetail() {
  const { selectedNode, createExecute, updateExecute, isNew } = useSoftwareDetail();
  const [loading, toggleLoading] = useToggle(false);
  const { t } = useTranslation();
  const DEFAULT_VALUES: t_software = useMemo(() => {
    return {
      _id: selectedNode?._id,
      name: selectedNode?.name,
    };
  }, [selectedNode]);
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<t_software>({
    defaultValues: DEFAULT_VALUES,
  });
  const onSubmit: SubmitHandler<t_software> = (data) => {
    console.log(data);
    toggleLoading(true);
    setValue('customError', '');
    createExecute({
      variables: {
        name: data.name,
      },
    })
      .then((res) => {
        if (res.data.employmentCreateSoftware) {
          toast.success(`${t(successfully)} ${t(created)}`);
          goBack();
        } else {
          setValue('customError', errorFinder(res?.errors?.[0]?.message));
          toggleLoading(false);
        }
      })
      .catch((err) => {
        setValue('customError', errorFinder(err));
        toggleLoading(false);
      });
  };
  const onUpdate: SubmitHandler<t_software> = (data) => {
    console.log(data);
    setValue('customError', '');
    toggleLoading(true);
    updateExecute({
      variables: {
        id: data._id,
        name: data.name,
      },
    })
      .then((res) => {
        if (res.data.employmentUpdateSoftware) {
          toast.success(`${t(successfully)} ${t(updated)}`);
          goBack();
        } else {
          setValue('customError', errorFinder(res?.errors?.[0]?.message));
          toggleLoading(false);
        }
      })
      .catch((err) => {
        setValue('customError', errorFinder(err));
        toggleLoading(false);
      });
  };

  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <PagesLayout backLinkTitle={t(softwares)}>
        <Card>
          <CardHeader className="py-3">
            <div className="d-flex align-items-center justify-content-between">
              <h5>{`${t(isNew ? Add : update)} ${t(software)}`}</h5>
              {/* <Button color="danger">{t(remove)}</Button> */}
            </div>
          </CardHeader>
          <CardBody>
            <Row className="mx-auto">
              <Col xxl="12" className="mx-auto">
                <Form onSubmit={handleSubmit(isNew ? onSubmit : onUpdate, console.error)}>
                  {!!getValues('customError') && (
                    <div className="rounded my-2 shadow-bg-danger p-2">{getValues('customError')}</div>
                  )}
                  <FormGroup>
                    <Label>{`${t(software)} ${t(name)}`}</Label>
                    <Controller
                      name="name"
                      control={control}
                      rules={{ required: `${t(please)} ${t(enter)} ${t(software)} ${t(Name)}` }}
                      defaultValue={DEFAULT_VALUES.name}
                      render={({ field: { onChange } }) => {
                        return (
                          <Input defaultValue={DEFAULT_VALUES.name} onChange={onChange} placeholder={'Adobe Acrobat'} />
                        );
                      }}
                    />
                    <FormFeedback>{errors?.name?.message}</FormFeedback>
                  </FormGroup>

                  <SubmitButton loading={loading}>{t(isNew ? create : update)}</SubmitButton>
                </Form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </PagesLayout>
    </Col>
  );
}

import { ApolloError } from '@apollo/client';
import { Loading } from 'components';
import { IconsName } from 'components/icons';
import DataTables, { TableSectionTitle } from 'components/tables/dataTable';
import { useGetStore, useUpdateEffect } from 'hooks';
import useQuery from 'hooks/useQuery';
import PagesLayout from 'pages/components/layout';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Button, Col } from 'reactstrap';
import { GET_ALL_ADMINS } from 'services/graphql/queries/users/users.geaphql';
import { APP_HELPER, ROLES } from 'tools/enums';
import {
  New,
  add,
  admin,
  detail,
  email,
  fullName,
  list,
  phoneNumber,
  role,
  school,
  superAdmin,
} from 'tools/i18n/constants/i18n';

type filterOptions = {
  admin: boolean;
  editor: boolean;
  employee: boolean;
  employer: boolean;
  active: boolean;
  banned: boolean;
  removed: boolean;
};

const INITIAL_FILTER: filterOptions = {
  admin: false,
  editor: false,
  employee: false,
  employer: false,
  banned: false,
  removed: false,
  active: false,
};

const AdminsTable = () => {
  const [param] = useSearchParams();
  const defaultPage = param.get('page') ? parseInt(param.get('page') as string) : APP_HELPER.tableDefaultPage;
  const defaultSize = param.get('size') ? parseInt(param.get('size') as string) : APP_HELPER.tableDefaultSize;
  const {
    error,
    data = { findAllAdmins: [] },
    refetch,
    fetchMore,
    loading,
  } = useQuery(GET_ALL_ADMINS, {
    defaultOptions: {
      variables: {
        pageNumber: defaultPage,
        size: defaultSize,
      },
    },
    fetchPolicy: 'no-cache',
  });
  return <Table error={error} fetchMore={fetchMore} refetch={refetch} loading={loading} data={data.findAllAdmins} />;
};

interface TableProps {
  fetchMore: any;
  refetch: any;
  error?: ApolloError;
  data: any[];
  loading: boolean;
}
type t_filterOption = {
  title: string;
  name: string;
  icon: IconsName;
  firstItem?: boolean;
};

const Table: FC<TableProps> = (props) => {
  const { refetch, error, data, loading, fetchMore } = props;
  const [realData, setRealData] = useState(data || []);
  const [currentPerRow, setCurrentPerRow] = useState(50);
  const [filter, setFilter] = useState<filterOptions>(INITIAL_FILTER);
  const { t } = useTranslation();
  const onFilter = useCallback(async (type: keyof filterOptions) => {
    let updatedValue: filterOptions = INITIAL_FILTER;
    setFilter((prev) => {
      updatedValue = {
        ...INITIAL_FILTER,
        [type]: !prev[type],
      };

      return updatedValue;
    });
  }, []);
  useUpdateEffect(() => {
    for (const i in filter) {
      if (filter[i]) {
        refetch({
          ['adminType']: ROLES[i],
        }).then((res: any) => {
          console.log(res);
          setRealData(res?.data?.findAllAdmins || []);
        });
      }
    }
  }, [filter]);

  /*const onSearch = async (key: string) => {
    /!*  console.log(key);
    const result = await refetch({
      query: key,
    });
    if (result.data) {
      setRealData(() => result.data.findAllAdmins);
      await Promise.resolve();
    } else {
      await Promise.reject();
    }*!/
  };*/

  const columnData = useMemo(() => {
    return [
      {
        name: <TableSectionTitle name="FiUser" title={t(fullName)} />,
        center: false,
        cell: (r: { firstName: any; lastName: any; id: any }) => {
          const fullName = `${r.firstName}  ${r.lastName}`;
          return <span className="text-black">{fullName}</span>;
        },
      },
      {
        name: <TableSectionTitle title={t(phoneNumber)} name="FiMail" />,
        center: false,
        selector: (r: { phoneNumber: string }) => r.phoneNumber,
      },
      {
        name: <TableSectionTitle title={t(email)} name="FiMail" />,
        center: false,
        selector: (r: { mail: string }) => r.mail,
      },
      {
        name: <TableSectionTitle title={t(role)} name="FiLayers" />,
        cell: (r: { roles?: any[] }) => r?.roles?.map((role: any) => role.title).join(','),
      },
      {
        name: '',
        cell: () => {
          return (
            <>
              {/*   <Link className="text-white ml-auto" to={'usersDetails'} param={(r?.id || '').toString()}>
              </Link>*/}
              <Button className="ml-auto" disabled color="primary">
                {t(detail)}
              </Button>
            </>
          );
        },
      },
    ];
  }, []);

  const buttonText = `${t(add)} ${t(New)} ${t(admin)}`;
  const filterOptions: t_filterOption[] = useMemo(() => {
    return [
      {
        title: `${t(admin)}`,
        name: ROLES.ADMIN,
        icon: 'FiUser',
      },
      {
        title: `${t(superAdmin)}`,
        name: ROLES.SUPER_ADMIN,
        icon: 'FiPenTool',
      },
      {
        title: `${t(school)} ${t(admin)}`,
        name: ROLES.SCHOOL_ADMIN,
        icon: 'FiPenTool',
      },
    ];
  }, []);
  const onPageChange = async (page: number) => {
    // toggler(true);
    const result = await refetch({
      pageNumber: page,
      size: currentPerRow,
    });
    if (result.data) {
      setRealData(() => result.data.findAllUsers);
      await Promise.resolve();
    } else {
      await Promise.reject();
    }
  };

  const onChangeRowsPerPage = async (currentPerRow: number, currentPage: number) => {
    setCurrentPerRow(currentPerRow);
    const result = await fetchMore({
      variables: {
        size: currentPerRow,
        page: currentPage,
      },
    });
    if (result.data) {
      setRealData(() => result.data.findAllUsers);
      await Promise.resolve();
    } else {
      await Promise.reject();
    }
  };

  useUpdateEffect(() => {
    setRealData(data);
  }, [data]);

  const userInfo = useGetStore().User.userInfo;

  const newSideText = `Logged in as: ${userInfo.firstName || ''} ${userInfo.lastName || ''} / ${
    userInfo.phoneNumber || ''
  }`;

  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <Loading loading={loading}>
        <PagesLayout
          newButtonSideText={newSideText}
          newSectionButtonLabel={buttonText}
          newSectionLink={'new'}
          dropDownOption={undefined}
        >
          <DataTables
            data={realData}
            paginationTotalRows={realData?.length}
            paginationPerPage={100}
            paginationRowsPerPageOptions={[100, 200, 300]}
            headerTitle={`${t(admin)} ${t(list)}`}
            filterable
            searchable
            filterOptions={filterOptions}
            columns={columnData}
            onFilter={onFilter}
            disabled={!!error}
            pagination
            onChangePage={onPageChange}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        </PagesLayout>
      </Loading>
    </Col>
  );
};

export default AdminsTable;

import useQuery from 'hooks/useQuery';
import { GET_SCHOOL } from 'services/graphql/queries/school';
import { useGetStore } from 'hooks';

export default function useSchoolDashboard() {
  const schoolId = useGetStore();
  console.log(schoolId);
  const { data, loading, error } = useQuery(GET_SCHOOL, {
    variables: {
      id: schoolId.User.userInfo?.schoolId,
    },
  });
  return { data: data?.EmploymentSchoolDetails, loading, error };
}

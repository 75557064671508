import SubmitButton from 'components/buttons/submitButton';
import TreeDataCheckbox from 'components/tree-select-checkbox';
import UploadProfileImage from 'components/uploadProfileImage';
import { useToggle } from 'hooks';
import PagesLayout from 'pages/components/layout';
import { t_jobCategory, t_jobSectionCategory } from 'pages/content-management/employment/jobCategories/types';
import React, { useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import FetchApi from 'services/api/axios';
import Styled from 'styled-components';
import { LANGUAGES, LANGUAGES_DROPDOWN } from 'tools/constants';
import { APP_HELPER } from 'tools/enums';
import { Category } from 'tools/i18n/constants';
import {
  add,
  category,
  create,
  created,
  enter,
  icon,
  job,
  jobCategories,
  language,
  name,
  please,
  successfully,
  translation,
  update,
  updated,
} from 'tools/i18n/constants/i18n';
import { errorFinder, goBack } from 'tools/methods';
import useJobCategoryDetail from './useJobCategoryDetail';

const StyledIcon = Styled(UploadProfileImage)`
  min-width : 80px;
  max-width : 80px;
`;

export default function JobCategoryDetail() {
  const { selectedNode, createExecute, updateExecute, createSectionExecute, updateSectionExecute, isNew, treeData } =
    useJobCategoryDetail();
  const [loading, toggleLoading] = useToggle(false);
  const { t } = useTranslation();
  const DEFAULT_VALUES: t_jobCategory & t_jobSectionCategory = useMemo(() => {
    return {
      _id: selectedNode?._id,
      imageUrl: selectedNode?.imageUrl === APP_HELPER.defaultBazaarAndJobIcon ? undefined : selectedNode?.imageUrl,
      originalName: selectedNode?.originalName,
      sections: selectedNode?.sections,
      translations: selectedNode?.translations?.map((item: any) => ({
        language: item.language,
        translate: item.translate,
      })),
      branchId: selectedNode?.branchId,
      sectionId: selectedNode?._id,
    };
  }, [selectedNode]);
  const {
    handleSubmit,
    control,
    setError,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<t_jobCategory & t_jobSectionCategory>({
    defaultValues: DEFAULT_VALUES,
  });

  const handleUploadIcon = (image: File, id: string) => {
    const formData = new FormData();
    formData.append('file', image);
    formData.append('branchId', id);
    FetchApi({
      url: 'JOB_CATEGORY_BRANCH',
      method: 'PATCH',
      data: formData,
    })
      .then((res) => {
        if (res.data) {
          toast.success(`${t(successfully)} ${t(isNew ? created : updated)}`);
          toggleLoading(false);
          goBack();
        }
        toggleLoading(false);
      })
      .catch((err) => {
        toggleLoading(false);
        setValue('customError', errorFinder(err.message));
      });
  };
  const onSubmit: SubmitHandler<t_jobCategory & t_jobSectionCategory> = (data) => {
    console.log(data);
    toggleLoading(true);
    setValue('customError', '');
    createExecute({
      variables: {
        translations: data.translations,
      },
    })
      .then((res) => {
        if (res.data.employmentCreateBranch) {
          if (data.imageUrl) {
            handleUploadIcon(data.imageUrl as File, res.data.employmentCreateBranch._id);
          } else {
            toast.success(`${t(successfully)} ${t(created)}`);
            goBack();
          }
        } else {
          setValue('customError', errorFinder(res?.errors?.[0]?.message));
          toggleLoading(false);
        }
      })
      .catch((err) => {
        setValue('customError', errorFinder(err));
        toggleLoading(false);
      });
  };
  const onUpdate: SubmitHandler<t_jobCategory & t_jobSectionCategory> = (data) => {
    console.log(data);
    setValue('customError', '');
    toggleLoading(true);
    updateExecute({
      variables: {
        branchId: data._id,
        translations: data.translations,
      },
    })
      .then((res) => {
        if (res.data.employmentUpdateBranch) {
          if (data.imageUrl && data.imageUrl !== DEFAULT_VALUES.imageUrl) {
            handleUploadIcon(data.imageUrl as File, data._id);
          } else {
            toast.success(`${t(successfully)} ${t(created)}`);
            goBack();
          }
        } else {
          setValue('customError', errorFinder(res?.errors?.[0]?.message));
          toggleLoading(false);
        }
      })
      .catch((err) => {
        setValue('customError', errorFinder(err));
        toggleLoading(false);
      });
  };

  const onSectionSubmit: SubmitHandler<t_jobSectionCategory> = (data) => {
    console.log(data);
    toggleLoading(true);
    createSectionExecute({
      variables: {
        translations: data.translations,
        branchId: data.branchId,
      },
    })
      .then((res) => {
        if (res.data.employmentCreateSection) {
          toast.success(`${t(successfully)} ${t(created)}`);
          goBack();
        } else {
          setValue('customError', errorFinder(res?.errors?.[0]?.message));
          toggleLoading(false);
        }
      })
      .catch((err) => {
        setValue('customError', errorFinder(err));
        toggleLoading(false);
      });
  };

  const onSectionUpdate: SubmitHandler<t_jobSectionCategory> = (data) => {
    console.log(data);
    toggleLoading(true);
    updateSectionExecute({
      variables: {
        translations: data.translations,
        sectionId: data.sectionId,
      },
    })
      .then((res) => {
        if (res.data.employmentUpdateSection) {
          toast.success(`${t(successfully)} ${t(updated)}`);
          goBack();
        } else {
          setValue('customError', errorFinder(res?.errors?.[0]?.message));
          toggleLoading(false);
        }
      })
      .catch((err) => {
        setValue('customError', errorFinder(err));
        toggleLoading(false);
      });
  };
  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <PagesLayout backLinkTitle={t(jobCategories)}>
        <Card>
          <CardHeader className="py-3">
            <div className="d-flex align-items-center justify-content-between">
              <h5>{`${t(isNew ? add : update)} ${t(job)} ${t(category)}`}</h5>
              {/* <Button color="danger">{t(remove)}</Button> */}
            </div>
          </CardHeader>
          <CardBody>
            <Row className="mx-auto">
              <Col xxl="12" className="mx-auto">
                <Form
                  onSubmit={handleSubmit(
                    isNew
                      ? watch('branchId')
                        ? onSectionSubmit
                        : onSubmit
                      : watch('branchId')
                      ? onSectionUpdate
                      : onUpdate,
                    console.error
                  )}
                >
                  {!!getValues('customError') && (
                    <div className="rounded my-2 shadow-bg-danger p-2">{getValues('customError')}</div>
                  )}
                  <div className="bg-gray p-3 d-flex align-items-center">
                    <FormGroup className="mr-5">
                      <Label className="whitespace-nowrap">{`${t(job)} ${t(Category)} ${t(icon)}`}</Label>
                      <Controller
                        control={control}
                        name="imageUrl"
                        render={({ field: { onChange } }) => {
                          return (
                            <StyledIcon
                              avatarSize="70px"
                              maxSize={512000}
                              defaultImage={DEFAULT_VALUES.imageUrl as string}
                              onSelect={(file: File) => {
                                onChange(file);
                              }}
                              onError={(e) => setError('imageUrl', { message: e })}
                            />
                          );
                        }}
                      />
                      <FormFeedback>{errors?.imageUrl?.message}</FormFeedback>
                    </FormGroup>
                    <FormGroup className="w-100">
                      <Controller
                        name="branchId"
                        control={control}
                        render={({ field: { onChange } }) => {
                          return (
                            <TreeDataCheckbox
                              onSelect={(node) => onChange(node.id)}
                              defaultExpandedIds={[DEFAULT_VALUES._id]}
                              checkbox
                              style={{ maxHeight: '400px', overflowY: 'auto' }}
                              data={treeData}
                            />
                          );
                        }}
                      />
                    </FormGroup>
                  </div>
                  <div className="d-flex align-items justify-content-between flex-wrap bg-gray p-3">
                    <FormGroup className={`mr-3 p-4 flex-grow-1 rounded bg-gray position-relative mb-2`}>
                      <Controller
                        control={control}
                        defaultValue={LANGUAGES[0].value}
                        name="translations.0.language"
                        render={() => {
                          return (
                            <FormGroup>
                              <Label>{t(language)}</Label>
                              <Select
                                options={LANGUAGES_DROPDOWN}
                                defaultValue={{ label: LANGUAGES[0].label, value: LANGUAGES[0].value }}
                                isDisabled
                              />
                            </FormGroup>
                          );
                        }}
                      />
                      <FormGroup>
                        <Label>{`${t(category)} ${t(name)}`}</Label>
                        <Controller
                          name="translations.0.translate"
                          defaultValue={DEFAULT_VALUES.translations?.[0]?.translate}
                          rules={{ required: `${t(please)} ${t(enter)} ${t(translation)}` }}
                          control={control}
                          render={({ field: { onChange } }) => {
                            return (
                              <Input
                                defaultValue={DEFAULT_VALUES.translations?.[0]?.translate}
                                onChange={onChange}
                                placeholder="Translate"
                              />
                            );
                          }}
                        />
                        <FormFeedback>{errors?.translations?.[0]?.translate?.message}</FormFeedback>
                      </FormGroup>
                    </FormGroup>

                    <FormGroup className={`mr-3 p-4 flex-grow-1 rounded bg-gray position-relative mb-2`}>
                      <Controller
                        control={control}
                        defaultValue={LANGUAGES[1].value}
                        name="translations.1.language"
                        render={() => {
                          return (
                            <FormGroup>
                              <Label>{t(language)}</Label>
                              <Select
                                options={LANGUAGES_DROPDOWN}
                                defaultValue={{ label: LANGUAGES[1].label, value: LANGUAGES[1].value }}
                                isDisabled
                              />
                            </FormGroup>
                          );
                        }}
                      />
                      <FormGroup>
                        <Label>{`${t(category)} ${t(name)}`}</Label>
                        <Controller
                          name="translations.1.translate"
                          rules={{ required: `${t(please)} ${t(enter)} ${t(translation)}` }}
                          control={control}
                          defaultValue={DEFAULT_VALUES.translations?.[1]?.translate}
                          render={({ field: { onChange } }) => {
                            return (
                              <Input
                                defaultValue={DEFAULT_VALUES.translations?.[1]?.translate}
                                onChange={onChange}
                                placeholder={'Translate'}
                              />
                            );
                          }}
                        />
                        <FormFeedback>{errors?.translations?.[1]?.translate?.message}</FormFeedback>
                      </FormGroup>
                    </FormGroup>
                  </div>
                  <SubmitButton loading={loading}>{t(isNew ? create : update)}</SubmitButton>
                </Form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </PagesLayout>
    </Col>
  );
}

import { Loading } from 'components';
import OnlyCharacter from 'components/inputs/onlyCharacter';
import OnlyNumberInput from 'components/inputs/onlyNumber';
import ShowError from 'components/showError';
import useMutation from 'hooks/useMutation';
import useQuery from 'hooks/useQuery';
import PagesLayout from 'pages/components/layout';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Col, Form, FormFeedback, FormGroup, Label } from 'reactstrap';
import { ADD_COMPANY_SIZE, GET_ONE_COMPANY_SIZE, UPDATE_COMPANY_SIZE } from 'services/graphql/queries/companySizes';
import {
  From,
  To,
  add,
  company,
  companySizes,
  created,
  range,
  record,
  save,
  size,
  successfully,
  title,
  update,
  updated,
} from 'tools/i18n/constants/i18n';
import { errorFinder, goBack, isNew } from 'tools/methods';

export default function CompanySizeDetail() {
  const { type } = useParams();
  const { t } = useTranslation();
  return (
    <Col xxl={8} xl={9} xs={12} className="mx-auto">
      <PagesLayout backLinkTitle={`${t(companySizes)}`} backLink="companySizes" backLinkParam="/">
        <Card>
          <CardHeader>
            <h4>{`${t(isNew(type) ? add : update)} ${t(company)} ${t(size)} ${t(range)}`}</h4>
          </CardHeader>
          <CardBody>
            {isNew(type) ? <NewCompanySize isNew={true} /> : <UpdateCompanySize isNew={false} id={type as string} />}
          </CardBody>
        </Card>
      </PagesLayout>
    </Col>
  );
}

interface AgeRangeCommonProps {
  isNew: boolean;
}

function NewCompanySize(props: AgeRangeCommonProps) {
  return <CompanySizeForm isNew={props.isNew} />;
}
interface UpdateAgeRangeProps extends AgeRangeCommonProps {
  id: string;
}

function UpdateCompanySize<T extends UpdateAgeRangeProps>({ id, isNew }: T) {
  const { loading, data } = useQuery(GET_ONE_COMPANY_SIZE, {
    variables: {
      id: parseInt(id as string),
    },
  });
  console.log(data);
  const realData = data?.getCompanySizeById;
  return (
    <Loading loading={loading}>
      <CompanySizeForm
        isNew={isNew}
        fromStaffs={realData?.fromStaffs}
        title={realData?.title}
        toStaffs={realData?.toStaffs}
        id={id}
      />
    </Loading>
  );
}

interface t_defaultValues {
  customError?: string;
  fromStaffs?: number;
  toStaffs?: number;
  title?: string;
  id?: string;
}

interface AgeRangeFormProps extends t_defaultValues {
  isNew: boolean;
}

type t_vars = {
  fromStaffs: number;
  toStaffs: number;
  title: string;
  id?: number;
};
function CompanySizeForm<T extends AgeRangeFormProps>(props: T) {
  const { t } = useTranslation();
  const { toStaffs: toStaffsValue, fromStaffs: fromStaffsValue, title: titleValue, id, isNew } = props;

  const [createExecute, { loading }] = useMutation<any, t_vars>(ADD_COMPANY_SIZE);
  const [updateExecute, { loading: loadingUpdate }] = useMutation<any, t_vars>(UPDATE_COMPANY_SIZE);
  const DEFAULT_VALUES: t_defaultValues = {
    fromStaffs: fromStaffsValue,
    toStaffs: toStaffsValue,
    title: titleValue,
    id,
  };
  console.log(DEFAULT_VALUES);
  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    setError,
    getValues,
    formState: { isDirty, errors },
  } = useForm<t_defaultValues>({
    defaultValues: DEFAULT_VALUES,
  });
  const onSubmit = (data: Omit<t_defaultValues, 'id'> = DEFAULT_VALUES) => {
    if (!StartRule(data) && !EndRule(data)) {
      createExecute({
        variables: {
          fromStaffs: data.fromStaffs as number,
          toStaffs: data.toStaffs as number,
          title: data.title as string,
        },
      })
        .then((res) => {
          if (res.data) {
            toast.success(`${t(record)} ${t(created)} ${t(successfully)}`);
            goBack();
          } else {
            setValue('customError', errorFinder(res.errors?.[0]?.message));
          }
        })
        .catch((err) => {
          console.error(errorFinder(err));
        });
    }
  };
  const onUpdate = (data: t_defaultValues = DEFAULT_VALUES) => {
    if (!StartRule(data) && !EndRule(data)) {
      updateExecute({
        variables: {
          fromStaffs: data.fromStaffs as number,
          toStaffs: data.toStaffs as number,
          title: data.title as string,
          id: parseInt(id as string),
        },
      })
        .then((res) => {
          if (res.data) {
            toast.success(`${t(record)} ${t(updated)} ${t(successfully)}`);
            goBack();
          } else {
            //@ts-ignore
            setValue('customError', res.errors?.message);
          }
        })
        .catch((err) => {
          console.error(err);
          setValue('customError', err?.message);
        });
    }
  };
  const StartRule = useCallback((data: t_defaultValues) => {
    const startVal = data.fromStaffs;
    const endVal = data.toStaffs;
    if (startVal && endVal && startVal > endVal) {
      setError('fromStaffs', {
        type: 'max',
        message: `Start Range Must be Lower Than End Range`,
      });
      return true;
    } else {
      clearErrors('fromStaffs');
      return false;
    }
  }, []);

  const EndRule = useCallback((data: t_defaultValues) => {
    const startVal = data.fromStaffs;
    const endVal = data.toStaffs;
    if (startVal && endVal && startVal > endVal) {
      setError('toStaffs', {
        type: 'min',
        message: `End Range Must be Higher Than Start Range`,
      });
      return true;
    } else {
      clearErrors('toStaffs');
      return false;
    }
  }, []);
  return (
    <Form onSubmit={handleSubmit(isNew ? onSubmit : onUpdate)}>
      <FormGroup>{!!getValues('customError') && <ShowError>{getValues('customError')}</ShowError>}</FormGroup>
      <FormGroup>
        <Label>{t(title)}</Label>
        <OnlyCharacter
          isEveryCharacterAllowed
          isNumberAllowed
          isRequired
          defaultValue={DEFAULT_VALUES?.title}
          control={control}
          name="title"
        />
      </FormGroup>
      <FormGroup>
        <Label>{t(From)}</Label>
        <OnlyNumberInput
          disabled={loading || loadingUpdate}
          isRequired
          makeFinalValueAsNumber={true}
          defaultValue={DEFAULT_VALUES.fromStaffs}
          control={control}
          name="fromStaffs"
        />
        <FormFeedback>{errors?.fromStaffs?.message}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label>{t(To)}</Label>
        <OnlyNumberInput
          disabled={loading || loadingUpdate}
          makeFinalValueAsNumber={true}
          defaultValue={DEFAULT_VALUES.toStaffs}
          control={control}
          isRequired
          name="toStaffs"
        />
        <FormFeedback>{errors?.toStaffs?.message}</FormFeedback>
      </FormGroup>
      <Button
        type="submit"
        disabled={loading || loadingUpdate || !isDirty}
        aria-disabled={loading || loadingUpdate || !isDirty}
        color="primary"
        className="w-100"
      >
        {t(isNew ? save : update)}
      </Button>
    </Form>
  );
}

import { useMemo } from 'react';
import { add, ageRange } from 'tools/i18n/constants/i18n';
import { useTranslation } from 'react-i18next';
import useQuery from 'hooks/useQuery';
import { APP_HELPER, CONTENT_MANAGEMENT_DETAIL_COMPONENT } from 'tools/enums';
import { GET_ALL_AGE_RANGE } from 'services/graphql/queries/ageRange';
import { useSearchParams } from 'react-router-dom';

export type t_ageRange = {
  _id: string;
  from: number;
  to: number;
};

export default function useAgeRange() {
  const [param] = useSearchParams();
  const defaultPage = param.get('page') ? parseInt(param.get('page') as string) : APP_HELPER.tableDefaultPage;
  const defaultSize = param.get('size') ? parseInt(param.get('size') as string) : APP_HELPER.treeMaxSize;
  const { t } = useTranslation();
  const { data, error, loading } = useQuery(GET_ALL_AGE_RANGE, {
    defaultOptions: {
      variables: {
        page: defaultPage,
        size: defaultSize,
      },
    },
    fetchPolicy: 'no-cache',
  });
  const detailType = `${CONTENT_MANAGEMENT_DETAIL_COMPONENT.Name}${CONTENT_MANAGEMENT_DETAIL_COMPONENT.Name}`;
  const isListEmpty: boolean = useMemo(() => !!(data ? data?.employmentAgeRangesList?.length : 0), [data]);
  const updatedData: t_ageRange[] | undefined = isListEmpty ? data.employmentAgeRangesList : [];

  const addNewTitle = `${t(add)} ${t(ageRange)}`;

  return { addNewTitle, isListEmpty, data: updatedData, detailType, loading, error };
}

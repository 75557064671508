import { useMemo } from 'react';
import { add, documentType, New } from 'tools/i18n/constants/i18n';
import { useTranslation } from 'react-i18next';
import useQuery from 'hooks/useQuery';
import { GET_ALL_DOCUMENT_TYPES } from 'services/graphql/queries/documentTypes';

export type t_companySize = {
  id: string;
  fromStaffs: number;
  toStaffs: number;
  title: string;
};

export default function useDocumentTypes() {
  const { t } = useTranslation();
  const { data, error, loading } = useQuery(GET_ALL_DOCUMENT_TYPES);
  const isListEmpty: boolean = useMemo(() => !!(data ? data?.getDocumentType?.length : 0), [data]);
  const updatedData: t_companySize[] | undefined = isListEmpty ? data.getDocumentType : [];

  const addNewTitle = `${t(add)} ${t(New)} ${t(documentType)}`;

  return { addNewTitle, isListEmpty, data: updatedData, loading, error };
}

//@ts-nocheck

import React, { Fragment, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../components/icons';
import { APP_ROUTE_NAME } from '../../tools/enums';
import { findSidebarRouteByRole, logout } from '../../tools/methods';
import { Link } from 'react-router-dom';
import L from '../../components/link';
import { useGetStore, useUpdateEffect } from '../../hooks';
import BULURO_LOGO from 'assets/images/buluro/logo/BLogo.svg';
import Styled from 'styled-components';

const iconSize = '15px';

const LogoContainer = Styled.img`
  min-width : 50px;
  max-width : 50px;
  min-height : 50px;
  max-height : 50px;
  margin : 0 auto;
`;

function Sidebar() {
  const currentUserRole = useGetStore().User.userInfo.role;
  const currentUserPermission = useGetStore().User.userInfo.permissions;

  const MENUITEMS = useMemo(() => findSidebarRouteByRole(currentUserRole[0], currentUserPermission), [currentUserRole]);
  const [mainMenu, setMainMenu] = useState({ mainMenu: MENUITEMS });
  const [margin, setMargin] = useState(0);
  const [width, setWidth] = useState(0);
  const { t } = useTranslation();
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const handleResize = () => {
    setWidth(window.innerWidth - 500);
  };

  const setNavActive = (item) => {
    MENUITEMS.map((menuItems) => {
      menuItems.items.filter((Items) => {
        if (Items.title !== item.title) {
          Items.active = false;
          document.querySelector('.bg-overlay1').classList.remove('active');
        }
        if (Items.children && Items.children.includes(item)) {
          Items.active = true;
        }
        if (Items.children) {
          Items.children?.filter((submenuItems) => {
            if (submenuItems.children && submenuItems.children.includes(item)) {
              Items.active = true;
              submenuItems.active = true;
              return true;
            } else {
              return false;
            }
          });
        }
        return Items;
      });
      return menuItems;
    });

    item.active = !item.active;
    setMainMenu({ mainMenu: MENUITEMS });
  };

  const toggleNavActive = (item) => {
    if (window.innerWidth <= 991) {
      document.querySelector('.page-header').className = 'page-header close_icon';
      document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper close_icon ';
      document.querySelector('.mega-menu-container').classList.remove('d-block');
      if (item.type === 'sub') {
        document.querySelector('.page-header').className = 'page-header ';
        document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper ';
      }
    }

    if (!item.active) {
      MENUITEMS?.map((a) => {
        a.items.filter((Items) => {
          if (a.items.includes(item)) Items.active = false;
          if (!Items.children) return false;
          Items.children.forEach((b) => {
            if (Items.children.includes(item)) {
              b.active = false;
            }
            if (!b.children) return;
            b.children.forEach((c) => {
              if (b.children.includes(item)) {
                c.active = false;
              }
            });
          });
          return Items;
        });
        return a;
      });
    }
    item.active = !item.active;
    setMainMenu({ mainMenu: MENUITEMS });
  };

  const scrollToRight = () => {
    if (margin <= -2598 || margin <= -2034) {
      if (width === 492) {
        setMargin(-3570);
      } else {
        setMargin(-3464);
      }
      // @ts-ignore
      document.querySelector('.right-arrow')?.classList.add('d-none');
      document.querySelector('.left-arrow')?.classList.remove('d-none');
    } else {
      setMargin((margin) => (margin += -width));
      document.querySelector('.left-arrow')?.classList.remove('d-none');
    }
  };

  const scrollToLeft = () => {
    if (margin >= -width) {
      setMargin(0);
      document.querySelector('.left-arrow')?.classList.add('d-none');
      document.querySelector('.right-arrow')?.classList.remove('d-none');
    } else {
      setMargin((margin) => (margin += width));
      document.querySelector('.right-arrow')?.classList.remove('d-none');
    }
  };

  const closeOverlay = () => {
    document.querySelector('.bg-overlay1')?.classList.remove('active');
    document.querySelector('.sidebar-link')?.classList.remove('active');
  };

  const activeClass = (menuActiveClass) => {
    if (menuActiveClass === true) {
      //  document.querySelector('.sidebar-link')?.classList.add('active');
      // document.querySelector('.bg-overlay1')?.classList.add('active');
    } else {
      document.querySelector('.sidebar-link')?.classList.remove('active');
      document.querySelector('.bg-overlay1')?.classList.remove('active');
    }
  };

  useUpdateEffect(() => {
    setMainMenu({ mainMenu: MENUITEMS });
  }, [MENUITEMS]);

  /* const openCloseSidebar = (toggle) => {
    if (toggle) {
      setSidebarToggle(!toggle);
      document.querySelector('.page-header').className = 'page-header close_icon';
      document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper close_icon ';
    } else {
      setSidebarToggle(!toggle);
      document.querySelector('.page-header').className = 'page-header';
      document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper ';
    }
  };
*/
  const responsiveSidebar = () => {
    document.querySelector('.page-header').className = 'page-header close_icon';
    document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper close_icon';
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /* const isMatch = (str = '') => {
    return pathname.includes(str);
  };

  useEffect(() => {
    document.querySelector('.left-arrow')?.classList.add('d-none');
    let isFinded = false;
    MENUITEMS?.map((items) => {
      items.items?.filter((Items) => {
        if (isMatch(Items.path) && !isFinded) {
          setNavActive(Items);
          isFinded = true;
        }
        if (!Items.children) return false;
        Items.children.filter((subItems) => {
          if (isMatch(subItems.path) && !isFinded) {
            setNavActive(subItems);
            isFinded = true;
          }
          if (!subItems.children) return false;
          subItems.children.filter((subSubItems) => {
            if (isMatch(subSubItems.path) && !isFinded) {
              setNavActive(subSubItems);
              isFinded = true;
              return true;
            }
            if (!subSubItems.children) return false;

            subSubItems.children.filter((subSubSubItems) => {
              if (isMatch(subSubSubItems.path) && !isFinded) {
                setNavActive(subSubSubItems);
                isFinded = true;
                return true;
              }
              if (!subSubSubItems.children) return false;

              subSubSubItems.children.filter((subSubSubSubItems) => {
                if (isMatch(subSubSubSubItems.path) && !isFinded) {
                  setNavActive(subSubSubSubItems);
                  isFinded = true;
                  return true;
                }
                if (!subSubSubSubItems.children) return false;
              });
            });
          });
          return subItems;
        });
        return Items;
      });
      return items;
    });
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
*/
  return (
    <Fragment>
      <div
        className={`bg-overlay1 `}
        onClick={() => {
          closeOverlay();
        }}
      />
      <div className="sidebar-wrapper d-flex flex-column justify-content-between " id="sidebar-wrapper">
        <div>
          <div className="logo-wrapper d-flex align-items-center justify-content-center">
            <L to={APP_ROUTE_NAME.dashboard} param="/">
              <LogoContainer className="img-fluid for-light" src={BULURO_LOGO} alt="bulur'o Logo" />
              <LogoContainer className="img-fluid for-dark" src={BULURO_LOGO} alt="bulur'o Logo" />
            </L>
            <div className="back-btn" onClick={() => responsiveSidebar()}>
              <Icon Name="FiArrowLeft" />
            </div>
            {/*<div className="toggle-sidebar" onClick={() => openCloseSidebar(sidebarToggle)}>
            <div className="status_toggle middle sidebar-toggle">
              <Icon Name="FiGrid" />
            </div>
          </div>*/}
          </div>

          <nav className="sidebar-main" id="sidebar-main">
            <div className="left-arrow" onClick={scrollToLeft}>
              <Icon Name="FaChevronLeft" size={iconSize} />
            </div>
            <div id="sidebar-menu" style={{ margin: '0px' }}>
              <ul className="sidebar-links custom-scrollbar">
                <li className="back-btn">
                  <div className="mobile-back text-end">
                    <span>{'Back'}</span>
                    <Icon Name="FaChevronRight" size={iconSize} />
                  </div>
                </li>
                {mainMenu.mainMenu?.map((Item, i) => {
                  return (
                    <Fragment key={i}>
                      {Item?.items
                        ?.filter((item) => !!item?.type)
                        .map((menuItem, i) => (
                          <li className={`sidebar-list ${menuItem.active ? 'active' : ''}`} key={i}>
                            {menuItem.type === 'sub' ? (
                              <a
                                role="button"
                                className={`sidebar-link sidebar-title ${menuItem.active ? 'active' : ''}`}
                                onClick={(event) => {
                                  event.preventDefault();
                                  setNavActive(menuItem);
                                  activeClass(menuItem.active);
                                }}
                              >
                                <Icon Name={menuItem.icon} />
                                <span>{t(menuItem.title)}</span>
                                <div className="according-menu">
                                  {menuItem.active ? (
                                    <Icon Name="FaChevronDown" size={iconSize} />
                                  ) : (
                                    <Icon size={iconSize} Name="FaChevronRight" />
                                  )}
                                </div>
                              </a>
                            ) : (
                              ''
                            )}

                            {menuItem.type === 'link' ? (
                              <Link
                                to={menuItem.path}
                                param="/"
                                className={`sidebar-link sidebar-title link-nav  ${menuItem.active ? 'active' : ''}`}
                                onClick={() => toggleNavActive(menuItem)}
                              >
                                <Icon Name={menuItem.icon} />
                                <span>{t(menuItem.title)}</span>
                              </Link>
                            ) : (
                              ''
                            )}

                            {menuItem.children ? (
                              <ul
                                className="sidebar-submenu"
                                style={
                                  menuItem.active
                                    ? sidebarToggle
                                      ? {
                                          opacity: 1,
                                          transition: 'opacity 500ms ease-in',
                                        }
                                      : { display: 'block' }
                                    : { display: 'none' }
                                }
                              >
                                {menuItem?.children
                                  ?.filter((i) => !!i?.type)
                                  .map((childrenItem, index) => {
                                    return (
                                      <li key={index}>
                                        {childrenItem.type === 'sub' ? (
                                          <a
                                            href=""
                                            className={`${childrenItem.active ? 'active' : ''}`}
                                            onClick={(event) => {
                                              event.preventDefault();
                                              toggleNavActive(childrenItem);
                                            }}
                                          >
                                            <span className="d-flex">
                                              {childrenItem?.icon ? <Icon Name={childrenItem.icon} /> : ''}
                                              <span className="mt-1">{t(childrenItem.title)}</span>
                                            </span>
                                            {/* <span className="sub-arrow">
                                        <Icon Name="FaChevronRight" size={iconSize} />
                                      </span>*/}
                                            <div className="according-menu mb-1">
                                              {childrenItem.active ? (
                                                <Icon size={iconSize} Name="FaChevronDown" />
                                              ) : (
                                                <Icon size={iconSize} Name="FaChevronRight" />
                                              )}
                                            </div>
                                          </a>
                                        ) : (
                                          ''
                                        )}

                                        {childrenItem.type === 'link' ? (
                                          <Link
                                            to={childrenItem.path}
                                            className={` ${childrenItem.active ? 'active' : ''}`}
                                            onClick={() => toggleNavActive(childrenItem)}
                                          >
                                            <Icon Name={childrenItem.icon} />
                                            {t(childrenItem.title)}
                                          </Link>
                                        ) : (
                                          ''
                                        )}
                                        {childrenItem?.children ? (
                                          <ul
                                            className="nav-sub-childmenu submenu-content"
                                            style={childrenItem.active ? { display: 'block' } : { display: 'none' }}
                                          >
                                            {childrenItem?.children
                                              ?.filter((i) => !!i?.type)
                                              .map((childrenSubItem, key) => (
                                                <li key={key}>
                                                  {childrenSubItem.type === 'link' ? (
                                                    <Link
                                                      to={childrenSubItem.path}
                                                      className={`whitespace-nowrap ${
                                                        childrenSubItem.active ? 'active' : ''
                                                      }`}
                                                      onClick={() => toggleNavActive(childrenSubItem)}
                                                    >
                                                      <Icon Name={childrenSubItem.icon} />
                                                      {t(childrenSubItem.title)}
                                                    </Link>
                                                  ) : (
                                                    ''
                                                  )}
                                                  {childrenSubItem?.type === 'sub' ? (
                                                    <a
                                                      href=""
                                                      className={`${childrenSubItem.active ? 'active' : ''}`}
                                                      onClick={(event) => {
                                                        event.preventDefault();
                                                        toggleNavActive(childrenSubItem);
                                                      }}
                                                    >
                                                      <span className="d-flex">
                                                        {childrenSubItem?.icon ? <Icon Name={childrenItem.icon} /> : ''}
                                                        <span className="mt-1">{t(childrenSubItem.title)}</span>
                                                      </span>
                                                      {/* <span className="sub-arrow">
                                                <Icon size={iconSize} Name="FaChevronRight" />
                                              </span>*/}
                                                      <div className="according-menu">
                                                        {childrenSubItem.active ? (
                                                          <Icon Name="FaChevronDown" size={iconSize} />
                                                        ) : (
                                                          <Icon size={iconSize} Name="FaChevronRight" />
                                                        )}
                                                      </div>
                                                    </a>
                                                  ) : (
                                                    ''
                                                  )}
                                                  {childrenSubItem?.children ? (
                                                    <ul
                                                      className="nav-sub-childmenu nav-sub-sub-childmenu submenu-content"
                                                      style={
                                                        childrenSubItem.active
                                                          ? { display: 'block' }
                                                          : { display: 'none' }
                                                      }
                                                    >
                                                      {childrenSubItem.children
                                                        ?.filter((i) => !!i?.type)
                                                        .map((childrenSubSubItem, subKey) => {
                                                          return (
                                                            <li key={subKey}>
                                                              {childrenSubSubItem.type === 'link' ? (
                                                                <Link
                                                                  to={childrenSubSubItem.path}
                                                                  className={`whitespace-nowrap ${
                                                                    childrenSubSubItem.active ? 'active' : ''
                                                                  }`}
                                                                  onClick={() => toggleNavActive(childrenSubSubItem)}
                                                                >
                                                                  <Icon Name={childrenSubSubItem.icon} />
                                                                  {t(childrenSubSubItem.title)}
                                                                </Link>
                                                              ) : (
                                                                ''
                                                              )}
                                                            </li>
                                                          );
                                                        })}
                                                    </ul>
                                                  ) : (
                                                    ''
                                                  )}
                                                </li>
                                              ))}
                                          </ul>
                                        ) : (
                                          ''
                                        )}
                                      </li>
                                    );
                                  })}
                              </ul>
                            ) : (
                              ''
                            )}
                            {menuItem.type === 'btn' && (
                              <Link
                                to={menuItem.path}
                                className={`sidebar-link sidebar-title link-nav  ${menuItem.active ? 'active' : ''}`}
                                onClick={logout}
                              >
                                <Icon Name={menuItem.icon} />
                                <span>{t(menuItem.title)}</span>
                              </Link>
                            )}
                          </li>
                        ))}
                    </Fragment>
                  );
                })}
              </ul>
            </div>
            <div className="right-arrow" onClick={scrollToRight}>
              <Icon Name="FaChevronRights" size={iconSize} />
            </div>
          </nav>
        </div>
        <div className="p-2 make-center text-white">{`version:  ${process.env.REACT_APP_VERSION}`}</div>
      </div>
    </Fragment>
  );
}

export default memo(Sidebar);

import { useMutation } from 'hooks';
import PagesLayout from 'pages/components/layout';
import useSliders from 'pages/content-management/slider/useSliders';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import sweetalert2 from 'sweetalert2';
import { errorFinder } from 'tools/methods';
import { Loading } from '../../../components';
import Icon from '../../../components/icons';
import Link from '../../../components/link';
import {
  ACTIVE_SLIDER,
  CHANGE_SLOT_ORDER,
  DE_ACTIVE_SLIDER,
  REFRESH_SLIDERS,
} from '../../../services/graphql/queries/sliders';
import { APP_HELPER } from '../../../tools/enums';
import SliderFilter from './components/filter';
import { isExpired } from './entity';
import { StyledSlide } from './styles';
import { sliderLocationEnum, sliderStatusEnum, sliderUserTypeEnum } from './types';
import LoadingIcon from 'assets/images/buluro/icon/loading-arrows.png';
import Modal from 'components/modal';
import SliderDefaultSettingForm from './components/defaultSettingForm';

export default function Sliders() {
  const {
    data,
    setSliderList,
    refetch,
    currentParams,
    statistics,
    filterData,
    loading,
    filterLoading,
    defaultLocation,
    currentLocation,
    toggleIsEmployer,
    isEmployer,
    onFilter,
    isSortChanged,
    toggleSortChanged,
    searchParams,
  } = useSliders();
  const [showDefaltSettingModal, setShowDefaltSettingModal] = useState(false);
  const [changeOrderExecute, { loading: sortLoading }] = useMutation(CHANGE_SLOT_ORDER);
  const [deactiveExecute] = useMutation(DE_ACTIVE_SLIDER);
  const [activeExecute] = useMutation(ACTIVE_SLIDER);
  const [refreshExecute, { loading: refreshLoading }] = useMutation(REFRESH_SLIDERS);
  const handleSaveSortChanges = async () => {
    sweetalert2.fire({
      title: 'Sorting ...',
      icon: 'warning',
      showConfirmButton: false,
      showCloseButton: false,
      showCancelButton: false,
      showDenyButton: false,
      backdrop: true,
    });
    changeOrderExecute({
      variables: {
        sort: data.result.map((item) => ({ id: item.id, sortIndex: item.sortIndex })),
      },
    })
      .then((result) => {
        toast.success('List Sorted Successfully');
      })
      .finally(() => {
        toggleSortChanged(false);
        sweetalert2.close();
      });
  };
  const handleToggleSliderActive = (id: number, isActive: boolean) => {
    sweetalert2
      .fire({
        title: `Are You Sure ${isActive ? 'to De Active' : 'to Active'}`,
        icon: 'warning',
        showConfirmButton: true,
        showCancelButton: true,
        showLoaderOnConfirm: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          sweetalert2.fire({
            title: 'Executing ...',
            icon: 'info',
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: false,
          });
          if (isActive) {
            deactiveExecute({
              variables: {
                sliderId: id,
              },
            })
              .then(() => {
                toast.success('Successfully Updated');
                refetch({
                  location: filterData.location as sliderLocationEnum,
                  sliderStatus: filterData.sliderStatus as sliderStatusEnum,
                  userType: isEmployer ? sliderUserTypeEnum.EMPLOYER : sliderUserTypeEnum.EMPLOYEE,
                })
                  .then((result: any) => {
                    if (result.data) {
                      setSliderList(result.data.getSliderDetailsForAdmin);
                    } else {
                      toast.error(errorFinder(result));
                    }
                  })
                  .catch((error: any) => {
                    toast.error(errorFinder(error));
                  });
              })
              .finally(() => {
                sweetalert2.close();
              });
          } else {
            activeExecute({
              variables: {
                sliderId: id,
              },
            })
              .then(() => {
                toast.success('Successfully Updated');
                refetch({
                  location: searchParams.get('location') as any,
                  sliderStatus: filterData.sliderStatus as sliderStatusEnum,
                  userType: searchParams.get('userType') as any,
                })
                  .then((result) => {
                    setSliderList(result.data.getSliderDetailsForAdmin);
                  })
                  .catch((error) => {
                    toast.success(error.message);
                  });
              })
              .finally(() => {
                sweetalert2.close();
              });
          }
        }
      });
  };
  const handleRefreshSlider = () => {
    if (refreshLoading) {
      return;
    }
    sweetalert2
      .fire({
        title: 'Are You Sure to Sync Sliders ?',
        icon: 'info',
        showCancelButton: true,
        showConfirmButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          sweetalert2.fire({
            title: 'Refreshing ...',
            icon: 'info',
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: false,
          });
          refreshExecute()
            .then((result) => {
              if (result.data?.syncSliders) {
                toast.success('Sliders Successfully Renewed');
              } else {
                toast.error('Sync Sliders Failed');
              }
            })
            .catch((error) => {
              toast.error(error?.message);
            })
            .finally(() => {
              sweetalert2.close();
            });
        }
      });
  };

  return (
    <Loading loading={loading}>
      <PagesLayout>
        <div className="d-flex flex-column ">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <Button
              color="warning"
              className="mr-auto  px-3 text-black"
              style={{ width: '450px' }}
              disabled={refreshLoading}
              onClick={handleRefreshSlider}
            >
              <div className={'d-flex align-items-center justify-content-center'}>
                <img width={18} height={18} alt="loading" src={LoadingIcon} />
                <span className="font-weight-normal ml-2">Uygulamalardaki Slaytları Senkronize Et</span>
              </div>
            </Button>

            <Button onClick={() => setShowDefaltSettingModal(true)} style={{ background: 'none', border: 'none' }}>
              Duration default Setting
            </Button>
          </div>
          <Card>
            <SliderFilter loading={filterLoading} onFilter={onFilter} defaultLocation={defaultLocation} />
            <CardHeader>
              <div className="d-flex align-items-center justify-content-between">
                <ButtonGroup className="w-100">
                  <Button
                    color={isEmployer ? '' : 'secondary'}
                    style={{
                      backgroundColor: isEmployer ? '#1674BA' : '#63C4EE',
                      color: isEmployer ? '#fff' : '#000',
                    }}
                    className="flex-grow-1"
                    // onClick={() => toggleIsEmployer(true)}
                    onClick={() =>
                      onFilter({ location: filterData.location, sliderStatus: filterData.sliderStatus }, true)
                    }
                    active={isEmployer}
                  >
                    Kurumsal (İşveren)
                  </Button>
                  <Button
                    className={`flex-grow-1`}
                    color={!isEmployer ? '' : 'secondary'}
                    style={{
                      backgroundColor: !isEmployer ? '#1674BA' : '#63C4EE',
                      color: !isEmployer ? '#fff' : '#000',
                    }}
                    // onClick={() => toggleIsEmployer(false)}
                    onClick={() =>
                      onFilter({ location: filterData.location, sliderStatus: filterData.sliderStatus }, false)
                    }
                    active={!isEmployer}
                  >
                    Bireysel (İşçi)
                  </Button>
                </ButtonGroup>
              </div>
            </CardHeader>
            <CardBody className="py-2 px-5">
              <Loading loading={filterLoading}>
                <div className="mt-3 d-flex align-items-center justify-content-between">
                  <div className=" d-flex align-items-center">
                    <div>{`All Slides: ${statistics.allSlider}`}</div>
                    <div className="mx-3">{`Active Slides: ${statistics.activeSlider}`}</div>
                    <div className="mr-3">{`DeActive Slides: ${statistics.deactivateSlider}`}</div>
                    <div>{`Expired Slides: ${statistics.expireSlider}`}</div>
                  </div>

                  <div className="ml-auto text-right">
                    <Link to="new" param={currentParams}>
                      <Button className="d-flex align-items-center" color="primary">
                        <Icon Name="FaPlus" />
                        <span className="ml-2">Create New Slide</span>
                      </Button>
                    </Link>
                  </div>
                </div>
                <div className="mr-5 mt-3">
                  {isSortChanged && (
                    <Button
                      className="mt-3"
                      color="primary"
                      type="button"
                      onClick={handleSaveSortChanges}
                      disabled={sortLoading}
                    >
                      Save Sort Changes
                    </Button>
                  )}

                  {(filterData.sliderStatus === 'ACTIVE' || (filterData.sliderStatus as any) === 'DEACTIVATE') && (
                    <span className="text-danger ">
                      The sorting feature is only available in the display mode of all slides.{' '}
                    </span>
                  )}
                </div>

                <DragDropContext
                  onDragEnd={(event) => {
                    const { type, destination, source } = event;
                    if (!destination) return;
                    if (destination?.index === source.index) {
                      return;
                    }
                    if (type === 'group') {
                      setSliderList((prevState) => {
                        const recordedState = prevState;
                        const sourceIndex = source.index;
                        const destinationIndex = destination.index;
                        const [removedItemItem] = recordedState.result.splice(sourceIndex, 1);
                        recordedState.result.splice(destinationIndex, 0, removedItemItem);
                        return {
                          statistics: prevState.statistics,
                          result: recordedState.result.map((item, index) => ({ ...item, sortIndex: index + 1 })),
                        };
                      });
                      toggleSortChanged(true);
                    }
                  }}
                >
                  <Droppable
                    droppableId="1"
                    type="group"
                    isDropDisabled={filterData.sliderStatus === 'ACTIVE' || filterData.sliderStatus === 'DEACTIVE'}
                  >
                    {(provided) => {
                      return (
                        <div
                          ref={provided.innerRef}
                          aria-disabled={filterData.sliderStatus === 'ACTIVE' || filterData.sliderStatus === 'DEACTIVE'}
                        >
                          <CardBody className="pt-3 px-0">
                            {data.result?.length === 0 && (
                              <h4 className="mx-auto d-block text-center"> List is Empty </h4>
                            )}
                            {data.result.map((slide, index) => {
                              const getStartDate = new Date(slide.slot.startTime).toISOString().split('T')[0];
                              const getEndDate = new Date(slide.slot.endTime).toISOString().split('T')[0];
                              const getStartTimeDate = new Date(slide.slot.startTime);
                              const getEndTimeDate = slide.slot.endTime ? new Date(slide.slot.endTime) : undefined;
                              const getStartTime = `${
                                getStartTimeDate.getUTCHours() < 10
                                  ? `0${getStartTimeDate.getUTCHours()}`
                                  : getStartTimeDate.getUTCHours()
                              }:${
                                getStartTimeDate.getUTCMinutes() < 10
                                  ? `0${getStartTimeDate.getUTCMinutes()}`
                                  : getStartTimeDate.getUTCMinutes()
                              }`;
                              const getEndTime = getEndTimeDate
                                ? `${
                                    getEndTimeDate.getUTCHours() < 10
                                      ? `0${getEndTimeDate.getUTCHours()}`
                                      : getEndTimeDate.getUTCHours()
                                  }:${
                                    getEndTimeDate.getUTCMinutes() < 10
                                      ? `0${getEndTimeDate.getUTCMinutes()}`
                                      : getEndTimeDate.getUTCMinutes()
                                  }`
                                : undefined;
                              const hasCategory =
                                !!slide.location.locationCategoryNumberId || !!slide.location.locationCategoryStringId;
                              return (
                                <Draggable key={slide.id} draggableId={slide.id.toString()} index={index}>
                                  {(provided) => {
                                    return (
                                      <div
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                      >
                                        <Card key={slide.id} className="border">
                                          <CardBody className="px-3 py-3">
                                            <StyledSlide
                                              to="sliderDetail"
                                              param={`${slide.id}${currentParams}`}
                                              className="d-flex h-100 justify-content-between"
                                            >
                                              <div className="d-flex h-100">
                                                <div className="align-self-center d-flex align-items-center mt-2">
                                                  <Icon Name="AiOutlineFullscreen" />
                                                  <b className="ml-3"> {slide.sortIndex} </b>
                                                </div>
                                                <div className="d-flex h-100 ml-3">
                                                  <img
                                                    onError={(event) => (event.currentTarget.src = '/default.svg')}
                                                    width={'100%'}
                                                    style={{ maxHeight: '90px' }}
                                                    className=" my-auto rounded"
                                                    // alt={slide?.translations?.find((l) => l?.language === 'tr')}
                                                    src={
                                                      slide?.children[0].childTranslations?.find(
                                                        (l) => l?.language === 'tr'
                                                      )?.image || '/default.svg'
                                                    }
                                                  />
                                                  <div className="ml-2 d-flex flex-column h-100">
                                                    <h6>
                                                      {slide?.translations?.find((l) => l?.language === 'tr')?.title}
                                                    </h6>
                                                    <p
                                                      style={{
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        maxHeight: '22px',
                                                        maxWidth: '80%',
                                                      }}
                                                    >
                                                      {
                                                        slide?.translations?.find((l) => l?.language === 'tr')
                                                          ?.description
                                                      }
                                                    </p>
                                                    <div className="d-flex align-items-center align-self-end mr-auto">
                                                      <Icon Name="AiOutlineCalendar" />
                                                      <div className="d-flex align-items-center ml-1">
                                                        <span>Start Date: </span>
                                                        <span className="ml-1">
                                                          {slide.slot.startTime
                                                            ? `${getStartDate}    ${getStartTime}`
                                                            : APP_HELPER.emptyDescription}
                                                        </span>
                                                      </div>
                                                      <b className="mx-2">-</b>
                                                      <div className="d-flex align-items-center">
                                                        <span>End Date: </span>
                                                        <span className="ml-1">
                                                          {slide.slot.endTime
                                                            ? `${getEndDate}  ${getEndTime}`
                                                            : APP_HELPER.emptyDescription}
                                                        </span>
                                                        {isExpired(slide.slot.endTime) && (
                                                          <Badge color="danger" className="ml-2">
                                                            Expired
                                                          </Badge>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="h-100 min-h-100 d-flex flex-column justify-content-between">
                                                <FormGroup switch className="d-flex align-items-center">
                                                  <Label
                                                    check
                                                    className="mr-5 mb-0 whitespace-nowrap"
                                                    role="button"
                                                    htmlFor="activeDeActive"
                                                    onClick={(event) => {
                                                      event.stopPropagation();
                                                    }}
                                                  >
                                                    {slide.isActive ? 'Active' : 'De Active'}
                                                  </Label>
                                                  <Input
                                                    role="button"
                                                    id="activeDeActive"
                                                    className=" mb-1"
                                                    style={{
                                                      padding: '10px',
                                                      width: '40px',
                                                    }}
                                                    type="switch"
                                                    size={64}
                                                    checked={slide.isActive}
                                                    onClick={(event) => {
                                                      event.stopPropagation();
                                                      handleToggleSliderActive(slide.id, slide.isActive);
                                                    }}
                                                  />
                                                </FormGroup>

                                                <div className="my-2 text-right">
                                                  {hasCategory && (
                                                    <Badge color="secondary" pill>
                                                      {` Kategori Slaytı `}
                                                    </Badge>
                                                  )}
                                                </div>
                                                <Link
                                                  to="sliderDetail"
                                                  param={`${slide.id}${currentParams}`}
                                                  className=" ml-auto"
                                                  style={{ marginTop: hasCategory ? '10px' : '18px' }}
                                                  onClick={(event) => {
                                                    event.stopPropagation();
                                                  }}
                                                >
                                                  Details
                                                </Link>
                                              </div>
                                            </StyledSlide>
                                          </CardBody>
                                        </Card>
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              );
                            })}
                          </CardBody>
                        </div>
                      );
                    }}
                  </Droppable>
                </DragDropContext>
              </Loading>
            </CardBody>

            <CardFooter className={filterLoading ? 'mt-5' : ''}>
              {isSortChanged && (
                <Button color="primary" type="button" onClick={handleSaveSortChanges} disabled={sortLoading}>
                  Save Sort Changes
                </Button>
              )}
            </CardFooter>
          </Card>
        </div>

        <Modal
          isOpen={showDefaltSettingModal}
          toggleOpen={() => setShowDefaltSettingModal((state) => !state)}
          title="Duration default Setting"
        >
          <SliderDefaultSettingForm toggleOpen={() => setShowDefaltSettingModal((state) => !state)} />
        </Modal>
      </PagesLayout>
    </Loading>
  );
}

import React, { FC, memo, ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { saved } from 'tools/i18n/constants/i18n';
import Styled from 'styled-components';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { useMutation, useToggle, useUpdateEffect } from 'hooks';
import SectionLayout from 'pages/ads/jobAds/components/layout/sectionLayout';
import { Control, Controller, useForm } from 'react-hook-form';
import { UPDATE_FILTERS_NOTIFICATION } from 'services/graphql/queries/notification';
import { toast } from 'react-toastify';
import ShowError from 'components/showError';
import { ICommonProps, t_filters, t_userRoleGuests, t_userRoles, t_userType } from 'pages/notification/types';
import { toUndefined } from 'tools/methods';

const StyledBox = Styled.div`
  height : 100px;
  background-color : #fff;
  border-radius : 10px;
  display : flex;
  align-items : center;
  justify-content: center;
  margin : 10px;
   max-width : 250px;
  min-width : 250px;
  align-items : center;
`;

const StyledOptionContainer = Styled.div`
  flex-grow : 1;
  width : 100%;
  padding : 0 15px;
`;

const OptionContainer: FC<{ children?: ReactNode }> = ({ children }) => (
  <StyledOptionContainer className=" rounded-md align-items-center d-flex m-2">{children}</StyledOptionContainer>
);

const Checkbox = Styled(Input)`
  width : 20px;
  height : 20px;
  border-radius : 25px;
  margin-bottom : 0;
  cursor : pointer;
`;

const DEFAULT_USER_ROLES: t_userRoles = {
  employee: false,
  employer: false,
};
export type FilterProps<N extends boolean> = ICommonProps<N>;

export function Filters<N extends boolean>(props: FilterProps<N>): JSX.Element {
  const { goNext, handleChangeSavedData, savedData } = props;
  const [execute, { loading }] = useMutation(UPDATE_FILTERS_NOTIFICATION);

  const { t } = useTranslation();
  const [sendToAll, toggleSendToAll] = useToggle(savedData?.filters?.sendToAll);
  const [userType, setUserType] = useState<t_userType>(savedData?.filters?.userType || 'APP_USER');
  const initialUserRole = useMemo(() => {
    return savedData?.filters?.userRoles?.employee && savedData?.filters?.userRoles?.employer
      ? 'all'
      : savedData?.filters?.userRoles?.employer
      ? 'employer'
      : savedData?.filters?.userRoles?.employee
      ? 'employee'
      : 'all';
  }, [savedData]);
  const [userRole, setUserRole] = useState<keyof t_userRoles>(initialUserRole);
  const INITIAL_STATE: t_filters = useMemo(
    () => ({
      sendToAll: savedData?.filters?.sendToAll || false,
      userType: savedData?.filters?.userType || 'APP_USER',
      userRoles: savedData?.filters?.userRoles || DEFAULT_USER_ROLES,
      hasBazaarAds: toUndefined(savedData?.filters?.hasBazaarAds),
      hasJobAds: toUndefined(savedData?.filters?.hasJobAds),
      isStudent: toUndefined(savedData?.filters?.isStudent),
      hasBlueTick: toUndefined(savedData?.filters?.hasBlueTick),
      isInGuide: toUndefined(savedData?.filters?.isInGuide),
      isStore: toUndefined(savedData?.filters?.isStore),
    }),
    [savedData]
  );
  const { control, handleSubmit, getValues, setValue, reset } = useForm<
    t_filters & { customError?: string | undefined }
  >();
  useUpdateEffect(() => {
    reset();
  }, [userRole, userType]);

  const handleUserRole = useCallback((role: keyof t_userRoles) => setUserRole(role), []);
  const onSubmit = async (data: t_filters) => {
    setValue('customError', '');

    data['userType'] = userType;

    if (userRole === 'all') {
      data['userRoles'] = Object.defineProperties(data.userRoles, {
        employer: { value: true, writable: false },
        employee: { value: true, writable: false },
        all: { value: true, writable: false },
      });
    } else {
      data.userRoles = {
        employer: false,
        employee: false,
        all: false,
        [userRole]: true,
      };
    }
    if (sendToAll) {
      data = { sendToAll };
    }
    const vars = { ...data };
    delete vars?.userRoles?.all;
    const response = await execute({
      variables: {
        isDraft: true,
        id: savedData?.id,
        filters: vars,
      },
    });
    if (response?.data?.updateCustomNotification) {
      toast.success(`${t(saved)}`);
      handleChangeSavedData({ filters: data });
      goNext(2);
    } else {
      // @ts-ignore
      setValue('customError', response?.errors?.[0]?.extensions?.exception?.response?.devMessage || 'Error Happened');
    }
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="bg-gray p-5 rounded-md">
      {!!getValues('customError') && (
        <FormGroup>
          <ShowError>{getValues('customError')}</ShowError>
        </FormGroup>
      )}

      <FormGroup className="d-flex align-items-center">
        <Controller
          defaultValue={INITIAL_STATE.sendToAll}
          control={control}
          name="sendToAll"
          render={({ field: { onChange } }) => {
            return (
              <Checkbox
                defaultChecked={INITIAL_STATE.sendToAll}
                onChange={(e: any) => {
                  toggleSendToAll();
                  onChange(e);
                }}
                type="checkbox"
              />
            );
          }}
        />

        <Label className="ml-2 mb-0 mt-1">Tüm kullanıcılara gönder</Label>
      </FormGroup>
      <SectionLayout
        lineHeight="1px"
        className="bg-secondary rounded flex-grow-1"
        title={`Kullanıcı tipi`}
        icon="FiType"
      >
        <FormGroup className="px-4">
          <div className="d-flex align-items-center">
            <Button
              color=""
              disabled={sendToAll}
              aria-disabled={sendToAll}
              onClick={() => setUserType('APP_USER')}
              className={` rounded-lg mr-2 p-2 ${userType === 'APP_USER' ? 'shadow-bg-active' : 'shadow-bg-notActive'}`}
            >
              {`Uygulama Kullanıcıları`}
            </Button>

            <Button
              disabled={sendToAll}
              color=""
              onClick={() => setUserType('GUEST')}
              className={` rounded-lg mr-2 p-2 ${userType === 'GUEST' ? 'shadow-bg-active' : 'shadow-bg-notActive'}`}
            >
              {`Giriş Yapmayanlar`}
            </Button>
          </div>
        </FormGroup>
      </SectionLayout>

      <FormGroup>
        <SectionLayout
          lineHeight="1px"
          className="bg-secondary rounded flex-grow-1"
          title={`Kullanıcı Rolü`}
          icon="FiLayout"
        >
          <RenderChildren
            defaultValues={INITIAL_STATE}
            disabled={sendToAll}
            setUserRole={handleUserRole}
            userRole={userRole}
            control={control}
            userType={userType}
          />
        </SectionLayout>
      </FormGroup>

      <Button disabled={loading} type="submit" color="primary" className="d-block rounded-md ml-auto">
        Sonraki
      </Button>
    </Form>
  );
}
export default Filters;

interface RenderChildrenProps {
  userType: t_userType;
  userRole: keyof t_userRoles;
  control: Control<t_filters>;
  setUserRole: (userRole: keyof t_userRoles) => void;
  disabled: boolean;
  defaultValues: t_filters;
}

type t_renderCheckboxKey = Record<t_userType, JSX.Element>;

type t_rolesKeys = keyof t_userRoleGuests;

type t_rolesOption = Record<t_rolesKeys, JSX.Element>;

// eslint-disable-next-line react/display-name
const RenderChildren = memo((props: RenderChildrenProps) => {
  const { userType, control, userRole, setUserRole, disabled, defaultValues } = props;
  const { t } = useTranslation();

  const RolesCheckBox: t_renderCheckboxKey = {
    APP_USER: (
      <div className="d-flex flex-column">
        <FormGroup className="d-flex">
          <Controller
            control={control}
            defaultValue={userRole === 'all'}
            name="userRoles.all"
            render={({ field: { onChange } }) => {
              return (
                <Checkbox
                  disabled={disabled}
                  defaultChecked={userRole === 'all'}
                  role="button"
                  id="all"
                  name="APP_USERS"
                  type="radio"
                  onChange={(e: any) => {
                    onChange(e);
                    setUserRole('all');
                  }}
                />
              );
            }}
          />
          <Label role="button" htmlFor="all" className="ml-2 mb-0 mt-1">
            Tüm
          </Label>
        </FormGroup>
        <FormGroup className="d-flex">
          <Controller
            defaultValue={userRole === 'employer'}
            control={control}
            name="userRoles.employer"
            render={({ field: { onChange } }) => {
              return (
                <Checkbox
                  disabled={disabled}
                  defaultChecked={userRole === 'employer'}
                  role="button"
                  id="employer"
                  name="APP_USERS"
                  type="radio"
                  onChange={(e: any) => {
                    onChange(e);
                    setUserRole('employer');
                  }}
                />
              );
            }}
          />

          <Label role="button" htmlFor="employer" className="ml-2 mb-0 mt-1">
            Kurumsal
          </Label>
        </FormGroup>
        <FormGroup className="d-flex">
          <Controller
            control={control}
            defaultValue={userRole === 'employee'}
            name="userRoles.employee"
            render={({ field: { onChange } }) => {
              return (
                <Checkbox
                  disabled={disabled}
                  defaultChecked={userRole === 'employee'}
                  role="button"
                  id="employee"
                  name="APP_USERS"
                  type="radio"
                  onChange={(e: any) => {
                    onChange(e);
                    setUserRole('employee');
                  }}
                />
              );
            }}
          />

          <Label role="button" htmlFor="employee" className="ml-2 mb-0 mt-1">
            Bireysel
          </Label>
        </FormGroup>
      </div>
    ),
    GUEST: (
      <div className="d-flex flex-column">
        <FormGroup className="d-flex">
          <Controller
            control={control}
            defaultValue={userRole === 'all'}
            name="userRoles.all"
            render={({ field: { onChange } }) => {
              return (
                <Checkbox
                  disabled={disabled}
                  defaultChecked={userRole === 'all'}
                  role="button"
                  id="all"
                  name="GUESTS"
                  type="radio"
                  onChange={(e: any) => {
                    onChange(e);
                    setUserRole('all');
                  }}
                />
              );
            }}
          />

          <Label role="button" htmlFor="all" className="ml-2 mb-0 mt-1">
            Tüm
          </Label>
        </FormGroup>
        <FormGroup className="d-flex">
          <Controller
            defaultValue={userRole === 'employer'}
            control={control}
            name="userRoles.employer"
            render={({ field: { onChange } }) => {
              return (
                <Checkbox
                  defaultChecked={userRole === 'employer'}
                  disabled={disabled}
                  role="button"
                  id="employer"
                  name="GUESTS"
                  type="radio"
                  onChange={(e: any) => {
                    onChange(e);
                    setUserRole('employer');
                  }}
                />
              );
            }}
          />

          <Label role="button" htmlFor="employer" className="ml-2 mb-0 mt-1">
            Kurumsal
          </Label>
        </FormGroup>
        <FormGroup className="d-flex">
          <Controller
            control={control}
            defaultValue={userRole === 'employee'}
            name="userRoles.employee"
            render={({ field: { onChange } }) => {
              return (
                <Checkbox
                  defaultChecked={userRole === 'employee'}
                  disabled={disabled}
                  role="button"
                  id="employee"
                  name="GUESTS"
                  type="radio"
                  onChange={(e: any) => {
                    onChange(e);
                    setUserRole('employee');
                  }}
                />
              );
            }}
          />

          <Label role="button" htmlFor="employee" className="ml-2 mb-0 mt-1">
            Bireysel
          </Label>
        </FormGroup>
      </div>
    ),
  };

  const Option: FC<{ label: string; inputName: string; label2: string }> = useCallback(
    ({ label, inputName, label2 }) => {
      const [positive, togglePositive] = useToggle(defaultValues[inputName]);
      const [negative, toggleNegative] = useToggle(defaultValues[inputName] === false);
      return (
        <>
          <OptionContainer>
            <Controller
              defaultValue={defaultValues[inputName]}
              control={control}
              name={inputName as any}
              render={({ field: { onChange } }) => {
                return (
                  <Checkbox
                    defaultChecked={defaultValues[inputName]}
                    disabled={disabled}
                    type="checkbox"
                    checked={positive}
                    onChange={() => {
                      if (!negative) {
                        togglePositive();
                      } else {
                        toggleNegative();
                        togglePositive();
                      }
                      const val = !positive ? true : undefined;
                      onChange(val);
                    }}
                    id={label.trim()}
                    name={inputName}
                  />
                );
              }}
            />

            <Label disabled={disabled} role="button" htmlFor={label.trim()} className="ml-3 mb-0 mt-1">
              {label}
            </Label>
          </OptionContainer>

          <OptionContainer>
            <Controller
              defaultValue={defaultValues[inputName]}
              control={control}
              name={inputName as any}
              render={({ field: { onChange } }) => {
                return (
                  <Checkbox
                    data-checked={false}
                    defaultChecked={defaultValues[inputName]}
                    disabled={disabled}
                    type="checkbox"
                    checked={negative}
                    onChange={() => {
                      if (!positive) {
                        toggleNegative();
                      } else {
                        togglePositive();
                        toggleNegative();
                      }
                      const val = !negative ? false : undefined;
                      onChange(val);
                    }}
                    id={label2.trim()}
                    name={inputName}
                  />
                );
              }}
            />

            <Label disabled={disabled} role="button" htmlFor={label2.trim()} className="ml-3 mb-0 mt-1">
              {label2}
            </Label>
          </OptionContainer>
        </>
      );
    },
    [defaultValues, disabled]
  );

  const RolesOptions: t_rolesOption = {
    employer: (
      <div className="mt-3">
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <StyledBox className="d-flex flex-column bg-white p-2">
              <Option label={'İş İlanı Var'} inputName="hasJobAds" label2={'İş İlanı Yok'} />
            </StyledBox>
            <StyledBox className="d-flex flex-column">
              <Option label={'Mavi Tiki Var'} inputName="hasBlueTick" label2="Mavi Tiki Yok" />
            </StyledBox>
          </div>

          <div className="d-flex align-items-center justify-content-between mb-2">
            <StyledBox className="d-flex flex-column">
              <Option label={'Ekipman İlanı Var'} inputName="hasBazaarAds" label2={'Ekipman İlanı Yok'} />
            </StyledBox>
            <StyledBox className="d-flex flex-column">
              <Option label={'Mağazadır'} inputName="isStore" label2={'Mağaza Değil'} />
            </StyledBox>
          </div>

          <div className="d-flex align-items-center justify-content-between ">
            <StyledBox className="d-flex flex-column">
              <Option label={'Rehberde'} inputName="isInGuide" label2={'Rehberde Değil'} />
            </StyledBox>
          </div>
        </div>
      </div>
    ),
    employee: (
      <div className="mt-3">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <StyledBox className="d-flex flex-column">
            <Option label={'Ekipman İlanı Var'} inputName="hasBazaarAds" label2={'Ekipman İlanı Yok'} />
          </StyledBox>

          <StyledBox className="d-flex flex-column">
            <Option label={'Öğrencidir'} inputName="isStudent" label2="Öğrenci Değil" />
          </StyledBox>
        </div>
      </div>
    ),
    all: (
      <div className="mt-3">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <StyledBox className="d-flex flex-column">
            <Option label={'Ekipman İlanı Var'} inputName="hasBazaarAds" label2={'Ekipman İlanı Yok'} />
          </StyledBox>
        </div>
      </div>
    ),
  };

  return (
    <>
      {RolesCheckBox[userType]}
      {userType !== 'GUEST' && RolesOptions[userRole]}
    </>
  );
});

import BazzarAdStatus from 'pages/ads/bazzarAds/filter/components/adsStatus';
import BazaarAdsCategories from 'pages/ads/bazzarAds/filter/components/categories';
import BazaarAdsDateOfPublish from 'pages/ads/bazzarAds/filter/components/dateOfPublish';
import BazaarAdsLocation from 'pages/ads/bazzarAds/filter/components/location';
import BazaarAdsModel from 'pages/ads/bazzarAds/filter/components/model';
import BazaarAdsFilterOnluWithPhoto from 'pages/ads/bazzarAds/filter/components/onlyWithPhoto';
import BazaarAdsPrice from 'pages/ads/bazzarAds/filter/components/price';
import BazaarAdsType from 'pages/ads/bazzarAds/filter/components/type';
import React from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { UseFormReset, UseFormResetField, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'reactstrap';
import styled from 'styled-components';
import { ADS_STATUS, bazaarAdsEnum } from 'tools/enums';
import { reset, results, show } from 'tools/i18n/constants/i18n';
import { t_errors } from 'tools/types';

const StyledContainer = styled.div`
  max-width: 500px;
`;

export interface t_defaultValues extends FieldValues {
  city: string[] | undefined;
  county: string[] | undefined;
  category: number | undefined;
  status: ADS_STATUS | undefined;
  priceMin: number | undefined;
  priceMax: number | undefined;
  unit: string | undefined;
  type: bazaarAdsEnum | undefined;
  model: string | undefined;
  entrance: string | Date | undefined;
  end: string | Date | undefined;
  onlyWithPictures: boolean | undefined;
  onlyLastDayAds: boolean | undefined;
  // onlyWithNoPrice: boolean | undefined;
}
export interface FilterAdsCommonProps {
  control: Control<t_defaultValues>;
  errors: t_errors<t_defaultValues>;
  setValue?: UseFormSetValue<t_defaultValues>;
  resetField?: UseFormResetField<t_defaultValues>;
  reset?: UseFormReset<t_defaultValues>;
}
interface FilterBazaarAdsProps {
  onFilter: (data: t_defaultValues | undefined) => void;
  loading: boolean;
}
export default function FilterBazaarAds({ onFilter, loading }: FilterBazaarAdsProps) {
  const DEFAULT_VALUES: t_defaultValues = {
    city: undefined,
    county: undefined,
    entrance: undefined,
    end: undefined,
    category: undefined,
    model: undefined,
    onlyLastDayAds: undefined,
    onlyWithPictures: undefined,
    priceMax: undefined,
    priceMin: undefined,
    status: undefined,
    type: undefined,
    unit: undefined,
    // onlyWithNoPrice: undefined,
  };
  const {
    handleSubmit,
    control,
    setValue,
    resetField,
    reset: resetFormInput,
    formState: { errors },
  } = useForm<t_defaultValues>({
    defaultValues: DEFAULT_VALUES,
  });
  const { t } = useTranslation();

  const onSubmit = (data: t_defaultValues) => {
    onFilter(data);
  };
  const handleResetForm = () => {
    resetFormInput();
    onFilter(undefined);
  };
  return (
    <StyledContainer>
      <Form onSubmit={handleSubmit(onSubmit)} className="pb-5">
        <BazaarAdsLocation setValue={setValue} control={control} errors={errors} />
        <BazaarAdsCategories control={control} errors={errors} setValue={setValue} />
        <BazzarAdStatus control={control} errors={errors} setValue={setValue} />
        <BazaarAdsPrice
          reset={resetFormInput}
          resetField={resetField}
          control={control}
          errors={errors}
          setValue={setValue}
        />
        <BazaarAdsType control={control} errors={errors} setValue={setValue} />
        <BazaarAdsModel control={control} errors={errors} setValue={setValue} />
        <BazaarAdsDateOfPublish control={control} errors={errors} setValue={setValue} />
        {/* <BazaarAdsFilterInlyLastDays control={control} errors={errors} />*/}
        <BazaarAdsFilterOnluWithPhoto errors={errors} control={control} setValue={setValue} />
        <Button disabled={loading} aria-disabled={loading} className="w-100" color="primary">
          {`${t(show)} ${t(results)}`}
        </Button>
        <Button
          type="button"
          onClick={handleResetForm}
          disabled={loading}
          aria-disabled={loading}
          className="w-100 mt-2"
          color=""
        >
          {t(reset)}
        </Button>
      </Form>
    </StyledContainer>
  );
}

import { gql } from '@apollo/client';

export const GET_OS_DETAILS = gql`
  query getLastAgentOSSetting {
    getLastAgentOSSetting {
      id
      lastVersionAndroid
      lastVersionIOS
      minForceAndroid
      minForceIOS
      IOSUpdateLink
      androidUpdateLink
    }
  }
`;

export const UPDATE_OS_DETAIL = gql`
  mutation UpdateOsDetail(
    $lastVersionAndroid: Float!
    $lastVersionIOS: Float!
    $minForceAndroid: Float!
    $minForceIOS: Float!
    $IOSUpdateLink: String!
    $androidUpdateLink: String!
  ) {
    updateAgentOSSetting(
      data: {
        lastVersionAndroid: $lastVersionAndroid
        lastVersionIOS: $lastVersionIOS
        minForceAndroid: $minForceAndroid
        minForceIOS: $minForceIOS
        IOSUpdateLink: $IOSUpdateLink
        androidUpdateLink: $androidUpdateLink
      }
    ) {
      id
      lastVersionAndroid
      lastVersionIOS
      minForceAndroid
      minForceIOS
      IOSUpdateLink
      androidUpdateLink
    }
  }
`;

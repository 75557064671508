import { useGetStore } from 'hooks';
import useQuery from 'hooks/useQuery';
import { useEffect, useMemo, useRef } from 'react';
import { GET_ADS_LIST } from 'services/graphql/queries/ads';
import { APP_HELPER } from 'tools/enums';
import { isTrophy } from 'tools/methods';
import { t_defaultValues } from './types';
import { useSearchParams } from 'react-router-dom';

export default function useJobAds() {
  const currentLang = useGetStore().App.lang;
  const isFirstTime = useRef(true);
  const [param] = useSearchParams();
  const defaultPage = param.get('page') ? parseInt(param.get('page') as string) : APP_HELPER.tableDefaultPage;
  const defaultSize = param.get('size') ? parseInt(param.get('size') as string) : APP_HELPER.tableDefaultSize;
  const {
    loading,
    data = { EmploymentAdsList: { data: [], count: 0 } },
    error,
    refetch,
    fetchMore,
  } = useQuery<any, t_defaultValues>(GET_ADS_LIST, {
    defaultOptions: {
      variables: {
        page: defaultPage,
        size: defaultSize,
        lang: currentLang,
      },
    },
  });
  useEffect(() => {
    if (data || error) {
      isFirstTime.current = false;
    }
  }, [data]);
  const isListEmpty = useMemo(() => !isTrophy(data.EmploymentAdsList.data), [data]);
  const updatedData = useMemo(() => (isListEmpty ? [] : data.EmploymentAdsList.data), [isListEmpty]);
  return { loading, data: updatedData, error, refetch, fetchMore, count: data.EmploymentAdsList.count };
}

import { gql } from '@apollo/client';

export const GET_ALL_EMLAK_ADS = gql`
  query GetAllEmlakAds(
    $statuses: [RealEstatePosterStatusEnum!]
    $categoryIds: [Int!]
    $categoryTypeIds: [Int!]
    $cityIds: [Int!]
    $townIds: [Int!]
    $neighborhoodIds: [Int!]
    $specialRegionIds: [Int!]
    $documentStatuses: [RealEstateDocumentStatusEnum!]
    $housingStatuses: [RealEstateHousingStatusEnum!]
    $prePaidPrice: RealEstatePosterPrePaidPriceFromToDto
    $fromWho: RealEstateFromWhoEnum
    $price: RealEstatePosterPriceFromToDto
    $chargePrice: RealEstatePosterChargePriceFromToDto
    $currencyIds: [Int!]
    $area: RealEstatePosterAreaFromToDto
    $builtUpArea: RealEstatePosterBuiltUpAreaFromToDto
    $roomIds: [Int!]
    $floorIds: [Int!]
    $isUnderGround: Boolean
    $buildingAgeIds: [Int!]
    $heatingSystemIds: [Int!]
    $publishDate: DateFromToDto
    $hasLoan: Boolean
    $onlyBoosted: Boolean
    $isEquipped: Boolean
    $onlyByPhotos: Boolean
    $sortBy: String
    $sort: String
    $mapBorderLocations: RealEstateMapBorderLocationsDto
    $keyword: String
    $pagination: PaginationDto!
  ) {
    RealEstatePostersList(
      realEstatePosterFilterDto: {
        statuses: $statuses
        categoryIds: $categoryIds
        categoryTypeIds: $categoryTypeIds
        cityIds: $cityIds
        townIds: $townIds
        neighborhoodIds: $neighborhoodIds
        specialRegionIds: $specialRegionIds
        documentStatuses: $documentStatuses
        housingStatuses: $housingStatuses
        prePaidPrice: $prePaidPrice
        fromWho: $fromWho
        price: $price
        chargePrice: $chargePrice
        currencyIds: $currencyIds
        area: $area
        builtUpArea: $builtUpArea
        roomIds: $roomIds
        floorIds: $floorIds
        isUnderGround: $isUnderGround
        buildingAgeIds: $buildingAgeIds
        heatingSystemIds: $heatingSystemIds
        publishDate: $publishDate
        hasLoan: $hasLoan
        onlyBoosted: $onlyBoosted
        isEquipped: $isEquipped
        onlyByPhotos: $onlyByPhotos
        sortBy: $sortBy
        sort: $sort
        mapBorderLocations: $mapBorderLocations
        keyword: $keyword
        pagination: $pagination
      }
    ) {
      count
      data {
        id
        hasLoan
        isEquipped
        price
        prePaidPrice
        chargePrice
        area
        builtUpArea
        description
        images
        title

        softDelete
        deletedAt
        expiresAt
        remainingDays
        isSponsored
        realSizeImages
        imagesFileName
        publishedAt
        sortDate
        documentStatus
        fromWho
        housingStatus

        ownerInfo {
          name
          picture
          blueMark
          isCompany
        }
        category {
          title
          status
          icon
          picture
        }
        categoryType {
          title
        }
        currency {
          name
          slug
          symbol
        }
        floor {
          title
          from
          to
          isUnderGround
        }
        room {
          from
          title
          to
        }
        buildingAge {
          title
          from
          to
        }
        heatingSystems {
          title
        }
        priceActivity {
          price
          prePaidPrice
          chargePrice
          posterStatus
          onPosterInitUpdate
        }
        statusActivity {
          description
          operator
          status
        }
        reportActivity {
          reason {
            reason
          }
        }
        statistics {
          contacts
          calls
          copiedThePhoneNumbers
          askedForNumbers
          bookmarks
          totalViews
          uniqueViews
          reports
          shares
        }
        chart {
          date
          totalViews
          uniqueViews
          isSponsored
        }
      }
    }
  }
`;

export const GET_EMLAK_AD = gql`
  query GetEmlakAd($id: Int!) {
    RealEstatePosterDetails(onlyIdDto: { id: $id }) {
      id
      hasLoan
      isEquipped
      price
      prePaidPrice
      chargePrice
      area
      builtUpArea
      description
      images
      title
      softDelete
      deletedAt
      expiresAt
      remainingDays
      isSponsored
      realSizeImages
      imagesFileName
      publishedAt
      sortDate
      documentStatus
      fromWho
      housingStatus
      status
      ownerInfo {
        name
        picture
        blueMark
        isCompany
      }
      category {
        title
        status
        icon
        picture
      }
      categoryType {
        title
      }
      currency {
        name
        slug
        symbol
      }
      floor {
        title
        from
        to
        isUnderGround
      }
      room {
        from
        title
        to
      }
      buildingAge {
        title
        from
        to
      }
      heatingSystems {
        title
      }
      priceActivity {
        price
        prePaidPrice
        chargePrice
        posterStatus
        onPosterInitUpdate
      }
      statusActivity {
        description
        operator
        status
      }
      reportActivity {
        reason {
          reason
        }
      }
      statistics {
        contacts
        calls
        copiedThePhoneNumbers
        askedForNumbers
        bookmarks
        totalViews
        uniqueViews
        reports
        shares
      }
      chart {
        date
        totalViews
        uniqueViews
        isSponsored
      }
    }
  }
`;

export const EMLAK_ADS_ADMIN_BAN = gql`
  mutation EmlakAdsAdminBan($id: Int!, $status: RealEstatePosterStatusEnum!, $description: String) {
    RealEstateChangePosterStatus(changePosterStatusDto: { id: $id, status: $status, description: $description }) {
      done
    }
  }
`;

import DefaultIcon from 'assets/images/buluro/dashboard/default-bazzar.svg';
import WalletIcon from 'assets/images/buluro/dashboard/empty-wallet.svg';
import { Loading } from 'components';
import Icon from 'components/icons';
import OnlyCharInput from 'components/inputs/onlyCharacter';
import Modal from 'components/modal';
import { t_toggleValueFunc, useToggle } from 'hooks';
import useMutation from 'hooks/useMutation';
import { t_dataTypes } from 'pages/ads/bazzarAds/useBazzarAds';
import useBazzarAdsDetail from 'pages/ads/bazzarAds/useBazzarAdsDetail';
import React, { FC, useMemo } from 'react';
import Avatar from 'react-avatar';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, CardHeader, Form, FormFeedback, FormGroup, Label } from 'reactstrap';
import { BAN_BAZAAR_AD, UNBAN_BAZAAR_AD } from 'services/graphql/queries/bazzarAds';
import { default as Styled, default as styled } from 'styled-components';
import { APP_HELPER, BAZAAR_ADS_STATUS } from 'tools/enums';
import defaultAvatar from 'assets/images/buluro/dashboard/user-default.svg';
import { History, Type } from 'tools/i18n/constants';
import {
  This,
  ad,
  admin,
  area,
  ban,
  cancel,
  date,
  description,
  enter,
  fullAddress,
  history,
  model,
  no,
  please,
  postalCode,
  price,
  publishedOn,
  reasons,
  reasonsFor,
  sponsored,
  title,
  unban,
  address,
} from 'tools/i18n/constants/i18n';
import { toDate, toUpperCase } from 'tools/methods';

export default function BazaarAdsDetail() {
  const { data, loading, refetch } = useBazzarAdsDetail();

  console.log(data);

  return (
    <Loading loading={loading}>
      <Card className="bg-transparent shadow-0">
        <Header data={data} refetch={refetch} />
        <Body data={data} />
      </Card>
    </Loading>
  );
}

interface HeaderProps {
  data: t_dataTypes;
  refetch: any;
}

const Header: FC<HeaderProps> = (props) => {
  const { t } = useTranslation();
  const { data, refetch } = props;
  const [modalOpen, toggleModalOpen] = useToggle(false);
  const [wantToBan, toggleWantToBan] = useToggle(false);
  const convertDate = useMemo(() => {
    const d = data?.publishedAt || '';
    return d ? toDate(data?.publishedAt as string) : '';
  }, [data?.publishedAt]);

  return (
    <CardHeader className="bg-transparent shadow-0 p-2">
      <BanModal refetch={refetch} data={data} wantToBan={wantToBan} isOpen={modalOpen} toggleOpen={toggleModalOpen} />
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <Icon Name="FiClock" />
          <span className="ml-2">{`${t(publishedOn)}: ${convertDate}`}</span>
        </div>
        <div>
          {data?.status === BAZAAR_ADS_STATUS.BAN && (
            <Button
              type="button"
              color="success"
              onClick={() => {
                toggleModalOpen();
                toggleWantToBan(false);
              }}
            >
              <div className="d-flex align-items-center">
                <Icon Name="FaCheck" size="15" />
                <span className="ml-2">{`${t(unban)} ${t(This)} ${t(ad)}`}</span>
              </div>
            </Button>
          )}
          {data?.status === BAZAAR_ADS_STATUS.ACTIVE && (
            <Button
              type="button"
              color="danger"
              onClick={() => {
                toggleModalOpen();
                toggleWantToBan(true);
              }}
            >
              <div className="d-flex align-items-center">
                <Icon Name="FaRegTrashAlt" size="15" />
                <span className="ml-2">{`${t(ban)} ${t(This)} ${t(ad)}`}</span>
              </div>
            </Button>
          )}
        </div>
      </div>
    </CardHeader>
  );
};

interface BodyProps {
  data: t_dataTypes;
}

const ImageContainer = Styled.div`
  width : 400px;
  height : 400px;
  img {
    width : 100%;
    height : 100%
   }
`;
const Body: FC<BodyProps> = (props) => {
  const { data } = props;
  const { t } = useTranslation();
  const adsStatusClassName =
    data?.status === BAZAAR_ADS_STATUS.BAN
      ? 'shadow-bg-danger'
      : data?.status === BAZAAR_ADS_STATUS.DE_ACTIVE || status === BAZAAR_ADS_STATUS.DRAFT
      ? 'shadow-bg-notActive'
      : data?.status === BAZAAR_ADS_STATUS.EXPIRED
      ? 'shadow-bg-warning'
      : 'shadow-bg-success';
  //@ts-ignore
  const fileUrl = (data?.pictures?.[0]?.fileUrl || undefined) as string | undefined;

  console.log(data);

  return (
    <>
      <CardBody className="bg-white rounded">
        <div className="d-flex flex-column">
          <div className="d-flex">
            <ImageContainer className="mr-3">
              <img alt={data?.title?.toString()} src={fileUrl || DefaultIcon} className="rounded border" />
            </ImageContainer>
            <div className="d-fle flex-column flex-grow-1">
              <div className="d-flex justify-content-between mb-3">
                <div className={`${adsStatusClassName} p-1 d-flex align-items-center rounded`}>
                  <Icon Name="FiActivity" />
                  <b className="ml-2">
                    <em>{data?.status ? BAZAAR_ADS_STATUS[data?.status] : ''}</em>
                  </b>
                </div>
                {!!data?.boost?.length && <div className="shadow-bg-warning p-1 rounded">{t(sponsored)}</div>}
              </div>
              <div className="p-3 rounded bg-gray rounded-lg">
                <div className="d-flex flex-column">
                  <div className="mb-3">
                    <div className="d-flex align-items-center">
                      <Avatar size={'42px'} src={data?.ownerInfo?.picture || defaultAvatar} />
                      <span className="ml-3">{toUpperCase(data?.ownerInfo.name)}</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <Icon Name="FaRegCircle" />
                    <div className="d-flex ml-2">
                      <b className="mr-2">{`${t(Type)}: `}</b>
                      <span>{toUpperCase(data?.type)}</span>
                    </div>
                  </div>

                  <div className="d-flex mb-3">
                    <Icon Name="FiGrid" />
                    <b className="mx-2">{`${t(title)}: `}</b>
                    <div className="d-flex ml-2 flex-grow-1">
                      <span>{data?.title || APP_HELPER.emptyDescription}</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <Icon Name="FiType" />
                    <div className="d-flex ml-2">
                      <b className="mr-2">{`${t(price)}: `}</b>
                      <span>
                        {data?.price
                          ? `${new Intl.NumberFormat(undefined, {
                              useGrouping: true,
                            }).format(data?.price)} ${data.currency.slug}`
                          : 'Unpriced'}
                      </span>
                    </div>
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <Icon Name="FiType" />
                    <div className="d-flex ml-2">
                      <b className="mr-2">{`${t(model)}: `}</b>
                      <span>{data?.model || APP_HELPER.emptyDescription}</span>
                    </div>
                  </div>

                  <div className="d-flex mb-3">
                    <Icon Name="AiOutlineFileText" />
                    <b className="mx-2">{`${t(description)}: `}</b>
                    <div className="d-flex ml-2">
                      <span>{data?.description || APP_HELPER.emptyDescription}</span>
                    </div>
                  </div>

                  <div className="d-flex align-items-center ">
                    <Icon Name="FaPhone" />
                    <b className="mx-2">{`${t('Contacts')}: `}</b>
                    <div className="d-flex ml-2">
                      {data?.contacts?.length ? (
                        data?.contacts?.map((item, index) => (
                          <span key={item.id}>
                            {item.phoneCode + item.phoneNumber}
                            {data?.contacts?.length === 2 && index === 0 ? ', ' : ''}
                          </span>
                        ))
                      ) : (
                        <div>
                          {data?.ownerInfo?.primaryPhone ? (
                            <span>
                              {data?.ownerInfo?.primaryPhoneCode + data?.ownerInfo?.primaryPhone}
                              {data?.ownerInfo?.secondaryPhone ? ', ' : ''}
                            </span>
                          ) : (
                            <></>
                          )}
                          {data?.ownerInfo?.secondaryPhone ? (
                            <span>{data?.ownerInfo?.secondaryPhoneCode + data?.ownerInfo?.secondaryPhone}</span>
                          ) : (
                            <></>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Card className="mt-3">
            <CardHeader>
              <div className="d-flex">
                <Icon Name="FaRegMap" />
                <h6 className="ml-2">{`${t(address)}`}</h6>
              </div>
            </CardHeader>
            <CardBody className="d-flex flex-wrap">
              {data?.addresses?.map((addres) => {
                return (
                  <div className="d-flex flex-column w-auto border m-2 p-2 rounded-md" key={addres.id}>
                    <div className="d-flex mb-3">
                      <Icon Name="FiMapPin" />
                      <div className="d-flex ml-2">
                        <b className="mr-2">{`${t(area)}: `}</b>
                        <span>{`${addres?.city} ${addres?.county ? ` / ${addres?.county}` : ''}`}</span>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <Icon Name="FaRegImage" />
                      <div className="d-flex ml-2">
                        <b className="mr-2 whitespace-nowrap">{`${t(fullAddress)}: `}</b>
                        <p>{addres?.fullAddress}</p>
                      </div>
                    </div>

                    <div className="d-flex">
                      <Icon Name="FaSearchLocation" />
                      <div className="d-flex ml-2">
                        <b className="mr-2">{`${t(postalCode)}: `}</b>
                        <span>{addres?.postalCode}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </CardBody>
          </Card>
        </div>
      </CardBody>
      <BanHistory data={data?.banActivity} />
    </>
  );
};

const StyledImageContainer = styled.div`
  width: 150px;
  min-width: 150px;
  height: 150px;
  min-height: 150px;
`;

interface BanHistoryProps {
  data: t_dataTypes['banActivity'];
}

function BanHistory<T extends BanHistoryProps>(props: T) {
  const { t } = useTranslation();
  const { data } = props;
  return (
    <Card className="mt-4">
      <CardHeader>
        <div className="d-flex">
          <Icon Name="FaRegClock" />
          <h6 className="ml-2">{`${t(ban)} ${t(History)}`}</h6>
        </div>
      </CardHeader>
      <CardBody className="p-2">
        <header className="border-bottom p-3">
          <div className="d-flex align-items-center">
            <div className="w-50 text-left">
              <div className="d-flex">
                <Icon Name="AiFillCalendar" />
                <span className="ml-2">{t(date)}</span>
              </div>
            </div>

            <div className="w-50 text-left">
              <div className="d-flex">
                <Icon Name="FaTextHeight" />
                <span className="ml-2">{t(description)}</span>
              </div>
            </div>
          </div>
        </header>
        <main className="mt-2">
          {data?.length ? (
            <>
              {data.map((item) => {
                return (
                  <div key={item.id} className="p-2 ml-2 border-bottom d-flex w-100">
                    <div className="w-50">{toDate(item.createdAt)}</div>
                    <div className="w-50">{item.description}</div>
                  </div>
                );
              })}
            </>
          ) : (
            <h6 className="mx-auto text-center mt-3">{`${t(no)} ${t(history)}`}</h6>
          )}
        </main>
      </CardBody>
    </Card>
  );
}

interface BanModalInterface {
  isOpen: boolean;
  toggleOpen: t_toggleValueFunc;
  data: t_dataTypes;
  wantToBan: boolean;
  refetch: any;
}
const iconSize = '18px';
const BanModal: FC<BanModalInterface> = (props) => {
  const { toggleOpen, isOpen, data, wantToBan, refetch } = props;
  const { t } = useTranslation();
  const DEFAULT_VALUE = {
    customError: '',
    description: null,
    posterId: data?.id,
  };
  const [banExecute, { loading: banLoading }] = useMutation(BAN_BAZAAR_AD);
  const [unbanExecute, { loading: unbanLoading }] = useMutation(UNBAN_BAZAAR_AD);

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: DEFAULT_VALUE,
  });
  const onSubmit = (data = DEFAULT_VALUE) => {
    if (wantToBan) {
      banExecute({
        variables: data,
      })
        .then((res) => {
          console.log(res);
          if (res.data?.banBazaarPoster) {
            refetch();
            toggleOpen();
          }
        })
        .catch((err) => {
          console.error(err);
          setValue('customError', `Error : ${err?.message || ''}`);
        });
    } else {
      unbanExecute({
        variables: data,
      })
        .then((res) => {
          console.log(res);
          if (res.data?.activeBazaarPoster) {
            refetch();
            toggleOpen();
          }
        })
        .catch((err) => {
          console.error(err);
          setValue('customError', `Error : ${err?.message || ''}`);
        });
    }
  };
  return (
    <Modal title={`${t(admin)} ${t(ban)}`} isOpen={isOpen} toggleOpen={toggleOpen} style={{ minWidth: '50vw' }}>
      <Card className="w-100">
        <CardBody className="p-0">
          {!!getValues('customError') && (
            <div className="w-100 rounded p-2 mb-3 shadow-bg-danger no-hover">{getValues('customError')}</div>
          )}
          <div className="bg-gray rounded mb-3 mt-1 p-3">
            <div className="d-flex mx-auto">
              <StyledImageContainer className="rounded-md mr-2">
                <img
                  //@ts-ignore
                  src={data?.pictures?.fileUrl || DefaultIcon}
                  alt={data?.title || undefined}
                  width="100%"
                  height="100%"
                  className="rounded-md"
                />
              </StyledImageContainer>
              <div className="d-flex flex-column justify-content-between">
                <div className="d-flex mb-2">
                  <Icon size={iconSize} Name="AiOutlineApartment" />
                  <span className="ml-2">{data?.title}</span>
                </div>
                <div className="d-flex mb-2">
                  <Icon size={iconSize} Name="FiGrid" />
                  <span className="ml-2">{data?.category?.title}</span>
                </div>
                <div className="d-flex mb-2">
                  <Icon size={iconSize} Name="FaRegCircle" />
                  <span className="ml-2">{data?.status}</span>
                </div>
                <div className="d-flex">
                  <img src={WalletIcon} alt={data?.id?.toString() || undefined} width="20px" height="20px" />
                  <span className="ml-2 whitespace-nowrap">
                    {data?.price ? `${data.price} ${data.currency.slug}` : 'Unpriced'}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <p className="bold">
              Please Enter the reasons for Ban so that the company will be informed through the notification
            </p>

            <FormGroup>
              <Label>{`${t(reasonsFor)} ${t(ban)}`}</Label>
              <OnlyCharInput
                isTextArea
                isNumberAllowed
                isEveryCharacterAllowed
                control={control}
                name="description"
                isRequired={wantToBan}
                requiredErrorText={`${t(please)} ${t(enter)} ${t(reasons)}`}
              />
              <FormFeedback>{errors?.description?.message}</FormFeedback>
            </FormGroup>
            <div className="d-flex flex-column">
              <Button
                disabled={!isDirty || banLoading || unbanLoading}
                aria-disabled={!isDirty || banLoading || unbanLoading}
                role="button"
                color=""
                className={`${wantToBan ? 'shadow-bg-danger' : 'shadow-bg-success'} border-0 mb-2`}
                type="submit"
              >
                {`${t(wantToBan ? ban : unban)} ${t(This)} ${t(ad)}`}
              </Button>
              <Button onClick={() => toggleOpen()} className="border" color="transparent" type="button">
                {t(cancel)}
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Modal>
  );
};

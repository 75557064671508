import React, { ChangeEvent, FC, memo, useRef, useState } from 'react';
import { checkSize, isImage } from 'tools/methods';
import { useTranslation } from 'react-i18next';
import { sizeExceeded, wrongFile } from 'tools/i18n/constants/i18n';
import Plus from 'assets/images/buluro/dashboard/icons8-plus-key-50.png';
import Icon from 'components/icons';
import Avatar from 'react-avatar';

export interface UploadImageProfileProps {
  maxSize?: number;
  onError?: (err: string) => void;
  onSelect?: (file: File) => void;
  previewMode?: boolean;
  defaultImage?: string;
  handleView?: VoidFunction;
  avatarSize?: string;
  className?: string;
}

const UploadProfileImage: FC<UploadImageProfileProps> = (props) => {
  const {
    className = '',
    maxSize = 1500000,
    avatarSize = '40',
    onError,
    onSelect,
    previewMode,
    defaultImage,
    handleView,
  } = props;
  const [src, setSrc] = useState<string>();

  const inp = useRef<any>();
  const { t } = useTranslation();
  const Errors = {
    sizeExceeded: t(sizeExceeded),
    wrongFile: t(wrongFile),
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.files) {
        if (isImage(e.target.files[0].type)) {
          checkSize(
            e.target.files[0].size,
            maxSize,
            () => {
              if (e.target.files) {
                onSelect?.(e.target.files[0]);
                setSrc(URL.createObjectURL(e.target.files[0]));
              }
            },
            () => {
              throw Error(Errors.sizeExceeded);
            }
          );
        } else {
          Error(Errors.wrongFile);
        }
      }
    } catch (error) {
      onError?.(error.message);
      console.error(error.message);
    }
  };
  return (
    <div role="button" className={`upload-profile-image ${className}`} onClick={() => inp.current.click()}>
      <button type="button">
        {previewMode ? (
          <Icon Name="FiTrash" color="#ffffff" style={{ width: '18px', height: '18px', stroke: '#fff' }} />
        ) : (
          <Icon Name="FiEdit" color="#ffffff" style={{ width: '18px', height: '18px', stroke: '#fff' }} />
        )}
      </button>
      {/* {!src && !defaultImage && <User color="#ffffff" style={{ width: '30px', height: '30px', stroke: '#fff' }} />}*/}
      <input disabled={previewMode} ref={inp} type="file" accept=".png, .jpg, .jpeg" onChange={handleChange} />

      <div role={previewMode ? undefined : 'button'} onClick={handleView}>
        {previewMode ? (
          <>
            {defaultImage ? (
              <Avatar round src={defaultImage} size={avatarSize} />
            ) : (
              <div className="d-flex align-items-center justify-content-center w-100 h-100">
                <Icon size="40px" Name={'FiImage'} />
              </div>
            )}
          </>
        ) : (
          <Avatar round src={src || defaultImage || Plus} size={src || defaultImage ? avatarSize : '40'} />
        )}
      </div>
    </div>
  );
};

export default memo(UploadProfileImage);

import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { t_timer } from 'tools/types';
import { again, didntGetCode, request } from 'tools/i18n/constants/i18n';
type initTimer = {
  second: number;
  min: string;
};

interface TimerProps {
  onRequestAgain?: VoidFunction;
  onFinishTimer?: VoidFunction;
  label?: string;
  requestAgainText?: string;
}

function Timer(props: TimerProps) {
  const { onRequestAgain, onFinishTimer, label, requestAgainText } = props;
  const INITIAL_TIMER: initTimer = {
    second: 59,
    min: '01',
  };
  const [start, setStartAgain] = useState(false);
  const [count, setCount] = useState(INITIAL_TIMER);
  const { t } = useTranslation();
  const handleRequestAgain = () => {
    setCount(INITIAL_TIMER);
    setStartAgain(false);
    onRequestAgain?.();
  };
  useEffect(() => {
    let interval: t_timer = undefined;
    if (!start) {
      interval = setInterval(() => {
        setCount((prev) => {
          if (prev.min === '00' && prev.second === 0) {
            clearInterval(interval);
            setStartAgain(true);
            onFinishTimer?.();
          }
          if (prev.second === 0) {
            return {
              second: 60,
              min: '00',
            };
          }
          return {
            second: (prev.second -= 1),
            min: prev.min,
          };
        });
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [start]);
  const reqText = requestAgainText || `${t(request)} ${t(again)} `;
  const labelText = label || `${t(didntGetCode)} ?`;
  return (
    <>
      {start ? (
        <span role="button" onClick={handleRequestAgain} className="underline">
          {reqText}
        </span>
      ) : (
        <span>{`${labelText} ${count.min} : ${count.second < 10 ? `0${count.second}` : `${count.second}`}`}</span>
      )}
    </>
  );
}

export default memo(Timer);

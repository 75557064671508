import React, { useCallback, useRef, useState } from 'react';
import { Col } from 'reactstrap';
import PagesLayout from 'pages/components/layout';
import {
  E_SENT_DATE,
  NotificationStatus,
  t_notification,
  t_onDateChange,
  t_onPageChange,
  t_onSearch,
  t_onSizeChange,
  t_onStatusChange,
  t_selectedDatesState,
} from 'pages/notification/types';
import NotificationTable from 'pages/notification/components/table/notificationsTable';
import useNotification from 'pages/notification/useNotification';
import { Loading } from 'components';
import { useToggle, useUpdateEffect } from 'hooks';
import { notification } from 'tools/i18n/constants/i18n';
import { Send } from 'tools/i18n/constants';
import { useTranslation } from 'react-i18next';

export default function Notification() {
  const { data, fetchMore, loading, count } = useNotification();
  const [disabled, toggleDisabled] = useToggle(false);
  const { t } = useTranslation();
  const [realData, setRealData] = useState<t_notification[]>(data);
  const [status, setStatus] = useState<NotificationStatus[]>([]);
  const dataCount = useRef<number>(count);
  const [selectedDates, setSelectedDates] = useState<t_selectedDatesState>({
    [E_SENT_DATE.START]: null,
    [E_SENT_DATE.END]: null,
  });

  const handleResponse = (response: {
    data: { findAllCustomNotifications: { data: t_notification[]; count: number } };
  }) => {
    dataCount.current = response?.data.findAllCustomNotifications?.count || 0;
    setRealData(response?.data?.findAllCustomNotifications?.data || []);
  };

  const onDateChange: t_onDateChange = (key, date) => {
    setSelectedDates((prev) => ({ ...prev, [key]: date }));
  };
  const onStatusChange: t_onStatusChange = useCallback((key) => {
    if (key === NotificationStatus.ALL) {
      setStatus((prev) => {
        if (prev?.includes(NotificationStatus.ALL)) {
          return [];
        } else {
          return [NotificationStatus.SENT, NotificationStatus.DRAFT, NotificationStatus.SENDING];
        }
      });
    } else {
      setStatus((prev) => {
        if (prev?.includes(key)) {
          return prev?.filter((s) => s !== key);
        } else {
          return [...prev, key];
        }
      });
    }
  }, []);

  const onPageChange: t_onPageChange = useCallback((page, size) => {
    fetchMore({
      variables: {
        page,
        size,
      },
    }).then(handleResponse);
  }, []);
  const onSizeChange: t_onSizeChange = useCallback((size, page) => {
    fetchMore({
      variables: {
        size,
        page,
      },
    }).then(handleResponse);
  }, []);
  const onSearch: t_onSearch = useCallback((keyword) => {
    fetchMore({
      variables: {
        ...(keyword ? { keyword } : {}),
      },
    }).then(handleResponse);
  }, []);
  const onSubmit = useCallback(() => {
    toggleDisabled(true);
    fetchMore({
      variables: {
        ...(status.length ? { status } : {}),
        ...(selectedDates[E_SENT_DATE.START] ? { from: selectedDates.START } : {}),
        ...(selectedDates[E_SENT_DATE.END] ? { to: selectedDates.END } : {}),
      },
    })
      .then((res) => {
        handleResponse(res);
        toggleDisabled(false);
      })
      .catch(() => {
        toggleDisabled(false);
      });
  }, [status, selectedDates]);
  useUpdateEffect(() => {
    setRealData(data);
  }, [data]);
  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <Loading loading={loading}>
        <PagesLayout newSectionButtonLabel={'Yeni Bildirim Gönder'} newSectionLink="new">
          <NotificationTable
            count={dataCount.current}
            disabled={disabled}
            currentActiveStatus={status}
            onSubmit={onSubmit}
            selectedDates={selectedDates}
            onSearch={onSearch}
            data={realData}
            onDateChange={onDateChange}
            onStatusChange={onStatusChange}
            onPageChange={onPageChange}
            onSizeChange={onSizeChange}
          />
        </PagesLayout>
      </Loading>
    </Col>
  );
}

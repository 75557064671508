import React, { FC } from 'react';
import { t_blueTickStatusUI } from 'tools/types';
import { BLUE_TICKET_STATUS_UI } from 'tools/enums';
import Icon from 'components/icons';

const ShowIndicator: FC<{ status: t_blueTickStatusUI }> = ({ status }) => {
  switch (status) {
    case BLUE_TICKET_STATUS_UI.ACCEPTED: {
      return (
        <div className="d-flex align-items-center shadow-bg-active ml-3 rounded-pill px-2  py-1">
          <Icon Name="FiCheck" />
          <span className="ml-2 ">{BLUE_TICKET_STATUS_UI.ACCEPTED}</span>
        </div>
      );
    }
    case BLUE_TICKET_STATUS_UI.CANCELED: {
      return (
        <div className="d-flex align-items-center shadow-bg-warning ml-3 rounded-pill px-2  py-1">
          <Icon Name="FiX" />
          <span className="ml-2">{BLUE_TICKET_STATUS_UI.CANCELED}</span>
        </div>
      );
    }
    case BLUE_TICKET_STATUS_UI.REJECTED: {
      return (
        <div className="d-flex align-items-center shadow-bg-danger ml-3 rounded-pill px-2  py-1">
          <Icon Name="FiAlertOctagon" />
          <span className="ml-2">{BLUE_TICKET_STATUS_UI.REJECTED}</span>
        </div>
      );
    }
    case BLUE_TICKET_STATUS_UI.NOT_REQUESTED: {
      return (
        <div className="d-flex align-items-center shadow-bg-notActive ml-3 rounded-pill px-2  py-1">
          <Icon Name="FiMinus" />
          <span className="ml-2">{BLUE_TICKET_STATUS_UI.NOT_REQUESTED}</span>
        </div>
      );
    }
    default:
      return (
        <div className="d-flex align-items-center shadow-bg-warning ml-3 rounded-pill px-2  py-1">
          <Icon Name="FiLoader" />
          <span className="ml-2">{BLUE_TICKET_STATUS_UI.IN_PROGRESS}</span>
        </div>
      );
  }
};

export default ShowIndicator;

import useQuery from 'hooks/useQuery';
import { GET_ALL_EMLAK_ADS } from 'services/graphql/queries/emlak/ads';
import { APP_HELPER } from 'tools/enums';
import { emlakAd } from './types';
import { useSearchParams } from 'react-router-dom';

export default function useEmlakAds() {
  const [param] = useSearchParams();
  const defaultPage = param.get('page') ? parseInt(param.get('page') as string) : APP_HELPER.tableDefaultPage;
  const defaultSize = param.get('size') ? parseInt(param.get('size') as string) : APP_HELPER.tableDefaultSize;
  const { data, loading, error, fetchMore } = useQuery<{
    RealEstatePostersList: { data: emlakAd[]; count: number };
  }>(GET_ALL_EMLAK_ADS, {
    defaultOptions: {
      variables: {
        pagination: {
          page: defaultPage,
          size: defaultSize,
        },
      },
    },
  });

  return {
    loading,
    error,
    fetchMore,
    data: data?.RealEstatePostersList || { data: [], count: 0 },
  };
}

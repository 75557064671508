import React from 'react';
import { TableSectionTitle } from 'components/tables';
import { T } from 'tools/i18n';
import { detail, hasLoan, title } from 'tools/i18n/constants/i18n';
import { t_Emlaktypes } from '../type';
import Link from 'components/link';
import { Button, Input } from 'reactstrap';
import { d_selectedNode } from 'store/tree/tree.dispatchs';
import ImgToBackGround from 'components/imgToBackground';
import Styled from 'styled-components';
import DEFAULT_ICON from 'assets/images/buluro/icon/wallet.svg';

const StyledImgContainer = Styled.div`
  min-width : 50px;
  width : 50px;
  height : 50px;
  min-height : 50px;
`;

export const EMLAK_TYPE_TABLE_COLUMNS = [
  {
    name: <TableSectionTitle title={`${T(title)}`} name="FiLayers" />,
    cell: (r: t_Emlaktypes) => (
      <div className="my-2 d-flex align-items-center text-left justify-content-start">
        {r.icon ? (
          <StyledImgContainer>
            <ImgToBackGround src={r?.icon} />
          </StyledImgContainer>
        ) : (
          <StyledImgContainer>
            <img className="mt-1" width="50px" height="40px" src={DEFAULT_ICON} />
          </StyledImgContainer>
        )}

        <span className="ml-3 whitespace-nowrap text-left">{r?.title}</span>
      </div>
    ),
  },
  {
    name: <TableSectionTitle title={`${T(hasLoan)}`} name="FiLayers" />,
    center: true,
    cell: (r: t_Emlaktypes) => (
      <Input
        style={{ width: '18px', height: '18px' }}
        type="checkbox"
        readOnly={true}
        aria-readonly={true}
        checked={!!r?.hasLoan}
      />
    ),
  },
  {
    name: '',
    cell: (r: t_Emlaktypes) => {
      return (
        <Link
          className="text-white ml-auto"
          to={'emlakTypesDetails'}
          param={r.id.toString()}
          onClick={() => {
            d_selectedNode(r);
          }}
        >
          <Button color="primary">{`${T(detail)}`}</Button>
        </Link>
      );
    },
  },
];

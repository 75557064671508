import { useEffect, useState } from 'react';
import { copyToClipboard } from 'tools/methods';
import { useTranslation } from 'react-i18next';
import { copied, copy } from 'tools/i18n/constants/i18n';
import { t_timer } from 'tools/types';

export type useCopyProps = {
  copyText?: string;
  afterCopyText?: string;
  delay?: number;
};

export type returnUseCopy = {
  isCopied: boolean;
  copyContent: (content: string | number) => Promise<void>;
  text: string;
};

export function useCopy(p?: useCopyProps): ReturnType<() => returnUseCopy> {
  const { t } = useTranslation();
  const copyText = p?.copyText || t(copy),
    afterCopyText = p?.afterCopyText || t(copied),
    delay = p?.delay || 5000;
  const [isCopied, setIsCopied] = useState(false);

  const copyContent = (content: string | number) => {
    setIsCopied((prev) => !prev);
    return copyToClipboard(content);
  };
  const callbackEffect = () => {
    let timeoutID: t_timer = undefined;
    if (isCopied) {
      const timeoutCb = () => {
        setIsCopied(false);
      };
      timeoutID = setTimeout(timeoutCb, delay);
    }
    return () => {
      clearTimeout(timeoutID);
    };
  };
  useEffect(callbackEffect, [isCopied]);
  return { isCopied, copyContent, text: (isCopied ? copyText : afterCopyText) as string };
}

export default useCopy;

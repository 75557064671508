import { dataToTree } from 'components/tree-select-checkbox/utils';
import { useUpdateEffect } from 'hooks';
import useQuery from 'hooks/useQuery';
import { useMemo } from 'react';
import { GET_ALL_JOB_REPORTS } from 'services/graphql/queries/ads';
import { d_getTreeData } from 'store/tree/tree.dispatchs';
import { isTrophy, toArray } from 'tools/methods';
import { t_jobAdsReport } from './types';

export default function useJobAdsReport() {
  const { data, loading, error } = useQuery<{ employmentAdReportReasonsList: t_jobAdsReport[] }>(GET_ALL_JOB_REPORTS);
  const isListEmpty: boolean = useMemo(() => isTrophy(data?.employmentAdReportReasonsList?.length), [data]);
  const updatedData = useMemo(
    () =>
      isListEmpty
        ? dataToTree(toArray(data?.employmentAdReportReasonsList), {
            id: '_id',
            labelKey: 'originalText',
            iconKey: 'imageUrl',
            childKey: 'sections',
          })
        : [],
    [data, isListEmpty]
  );

  useUpdateEffect(() => {
    if (isTrophy(updatedData)) {
      d_getTreeData(updatedData);
    }
  }, [updatedData]);
  return { isListEmpty, data: updatedData, error, loading };
}

import { gql } from '@apollo/client';

export const FORGET_PASSWORD = gql`
  mutation ForgetPasswordGetOTP($phoneCode: String!, $phoneNumber: String!) {
    forgetPasswordSendOtp(forgetPasswordSendOtpDto: { phoneCode: $phoneCode, phoneNumber: $phoneNumber }) {
      message
    }
  }
`;

export const FORGET_PASSWORD_CHANGE_PASSWORD = gql`
  mutation ForgetPasswordUpdatePassword(
    $code: String!
    $phoneCode: String!
    $phoneNumber: String!
    $password: String!
  ) {
    validateForgetPasswordCode(
      validateForgetPasswordOtpDto: {
        code: $code
        phoneCode: $phoneCode
        phoneNumber: $phoneNumber
        password: $password
      }
    ) {
      message
    }
  }
`;

//@ts-nocheck

import React from 'react';
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.error(error);
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    console.error(
      'ERROR Boundary &&&&&',
      error.message,
      error?.cause,
      error?.code,
      error?.stack,
      error?.framesToPop,
      errorInfo
    );
  }
  render() {
    if (this.state.hasError) {
      window.history.back();
    }
    return this.props.children;
  }
}

import { ApolloError } from '@apollo/client';
import { Loading } from 'components';
import SubmitButton from 'components/buttons/submitButton';
import Icon from 'components/icons';
import Link from 'components/link';
import Modal from 'components/modal';
import DataTable, { TableSectionTitle } from 'components/tables/dataTable';
import UploadProfileImage from 'components/uploadProfileImage';
import { useToggle, useUpdateEffect } from 'hooks';
import useMutation from 'hooks/useMutation';
import PagesLayout from 'pages/components/layout';
import UpdateAddHeader from 'pages/components/updateAddHeader';
import useMagazineDetail from 'pages/magazine/useMagazineDetails';
import React, { FC, useMemo } from 'react';
import { Image } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useDownloader from 'react-use-downloader';
import { Button, Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import FetchApi from 'services/api/axios';
import { CREATE_MAGAZINE, DELETE_MAGAZINE, UPDATE_MAGAZINE } from 'services/graphql/queries/magazine';
import { NON_EMPTY_VALUES } from 'tools/constants';
import { APP_ROUTE_NAME } from 'tools/enums';
import {
  Delete,
  Deleted,
  New,
  add,
  cancel,
  created,
  date,
  detail,
  download,
  editions,
  enter,
  file,
  image,
  information,
  logo,
  magazine,
  please,
  published,
  save,
  select,
  size,
  successfully,
  title,
  update,
  updated,
} from 'tools/i18n/constants/i18n';
import { isNew, toDate } from 'tools/methods';

type t_initialValues = {
  name?: string;
  logo?: string;
};

export default function MagazineDetail() {
  const { type } = useParams();

  return <>{isNew(type) ? <CreateNew /> : <UpdateNew />}</>;
}

function CreateNew() {
  return <Detail data={undefined} loading={false} />;
}

function UpdateNew() {
  const { data, loading, error } = useMagazineDetail();
  return <Detail data={data} loading={loading} error={error} />;
}

interface DetailsProps {
  data?: any;
  loading: boolean;
  error?: ApolloError;
}
function Detail(props: DetailsProps) {
  const { data, loading } = props;
  const { t } = useTranslation();
  const { type } = useParams();
  const navigate = useNavigate();
  const [createExecute] = useMutation(CREATE_MAGAZINE);
  const [updateExecute] = useMutation(UPDATE_MAGAZINE);
  const [realLoading, toggleRealLoading] = useToggle(loading);
  const DEFAULT_VALUE: t_initialValues = {
    logo: data?.logo,
    name: data?.name,
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<t_initialValues>({
    defaultValues: DEFAULT_VALUE,
  });
  const onSubmit = (data = DEFAULT_VALUE) => {
    toggleRealLoading(true);
    createExecute({
      variables: data,
    })
      .then((res) => {
        if (res.data.CreateMagazine) {
          if (data.logo) {
            const formData = new FormData();
            formData.append('image', data.logo);
            formData.append('id', res.data.CreateMagazine.id);
            FetchApi({
              url: 'MAGAZINE_LOGO',
              data: formData,
              method: 'PATCH',
            })
              .then((response: any) => {
                console.log(response);
                toast.success(`${t(magazine)} ${t(created)}`);
                navigate(`/${APP_ROUTE_NAME.dashboard}/${APP_ROUTE_NAME.magazine}`);
                toggleRealLoading(false);
              })
              .catch(console.error)
              .finally(() => {
                toggleRealLoading(false);
              });
          } else {
            toggleRealLoading(false);
            toast.success(`${t(magazine)} ${t(created)}`);
            navigate(`/${APP_ROUTE_NAME.dashboard}/${APP_ROUTE_NAME.magazine}`);
          }
        } else {
          toast.error('Request Failed');
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error('Request Failed');
      })
      .finally(() => {
        toggleRealLoading(false);
      });
  };

  const onUpdate = (data = DEFAULT_VALUE) => {
    toggleRealLoading(true);
    updateExecute({
      variables: { ...data, id: parseInt(type as string) },
    })
      .then(() => {
        if (data.logo && data.logo !== DEFAULT_VALUE.logo) {
          const formData = new FormData();
          formData.append('image', data.logo);
          formData.append('id', type as string);
          FetchApi({
            url: 'MAGAZINE_LOGO',
            data: formData,
            method: 'PATCH',
          })
            .then((response: any) => {
              console.log(response);
              toast.success(`${t(magazine)} ${t(updated)}`);
              navigate(`/${APP_ROUTE_NAME.dashboard}/${APP_ROUTE_NAME.magazine}`);
              toggleRealLoading(false);
            })
            .catch(console.error);
        } else {
          toast.success(`${t(magazine)} ${t(updated)}`);
          navigate(`/${APP_ROUTE_NAME.dashboard}/${APP_ROUTE_NAME.magazine}`);
          toggleRealLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const backLinkTitle = `${t(magazine)}`;
  const newSectionButtonLabel = `${t(add)} ${t(New)} ${t(editions)}`;
  const layoutParam = isNew(type) ? { backLinkTitle } : { backLinkTitle, newSectionButtonLabel };
  useUpdateEffect(() => {
    toggleRealLoading(loading);
  }, [loading]);
  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <Loading loading={loading}>
        <PagesLayout {...layoutParam} backLink="magazine" backLinkParam="/">
          <Card>
            <Header data={data} isNew={isNew(type)} />
            <CardBody>
              <Form onSubmit={handleSubmit(isNew(type) ? onSubmit : onUpdate)} className="bg-gray rounded p-5">
                <FormGroup>
                  <div className="d-flex align-items-center">
                    <Controller
                      name="logo"
                      defaultValue={data?.logo}
                      rules={{ required: `${t(please)} ${t(select)} ${t(image)}` }}
                      control={control}
                      render={({ field: { onChange } }) => {
                        return <UploadProfileImage avatarSize="75px" defaultImage={data?.logo} onSelect={onChange} />;
                      }}
                    />
                    <span className="ml-4">{` ${t(magazine)} ${t(logo)}`}</span>
                  </div>
                  <FormFeedback>{errors?.logo?.message}</FormFeedback>
                </FormGroup>
                <FormGroup className="mt-4">
                  <Label>{`${t(magazine)} ${t(title)}`}</Label>
                  <Controller
                    name="name"
                    rules={{
                      required: `${t(please)} ${t(enter)} ${t(title)}`,
                      pattern: { value: NON_EMPTY_VALUES, message: 'Please Enter Valid Title' },
                      maxLength: { value: 50, message: 'maximum 50 characters Allowed' },
                      minLength: { value: 1, message: 'At least one character Allowed' },
                    }}
                    control={control}
                    defaultValue={data?.name}
                    render={({ field: { onChange } }) => {
                      return (
                        <Input
                          defaultValue={data?.name}
                          onBlur={onChange}
                          placeholder={`${t(please)} ${t(enter)} ${t(magazine)} ${t(title)}`}
                        />
                      );
                    }}
                  />
                  <FormFeedback>{errors?.name?.message}</FormFeedback>
                </FormGroup>
                <SubmitButton loading={realLoading}>{t(isNew(type) ? save : update)}</SubmitButton>
              </Form>
              {!isNew(type) && <MagazineTable data={data?.edition} />}
            </CardBody>
          </Card>
        </PagesLayout>
      </Loading>
    </Col>
  );
}

interface HeaderProps {
  isNew: boolean;
  data?: any;
}

const Header: FC<HeaderProps> = ({ isNew, data }) => {
  const { t } = useTranslation();
  const title = `${t(magazine)} ${t(information)}`;
  const [openModal, toggleModal] = useToggle(false);
  const [execute, { loading }] = useMutation(DELETE_MAGAZINE);
  const { type } = useParams();
  const navigate = useNavigate();
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    execute({
      variables: {
        id: parseInt(type as string),
      },
    })
      .then((res) => {
        console.log(res);
        toast.success(`${t(magazine)} ${t(successfully)} ${t(Deleted)}`);
        navigate(`/${APP_ROUTE_NAME.dashboard}/${APP_ROUTE_NAME.magazine}`);
      })
      .catch(console.error);
  };
  return (
    <>
      <Modal size="lg" isOpen={openModal} toggleOpen={toggleModal} title={`${t(Delete)} ${t(magazine)}`}>
        <Form onSubmit={(e) => onSubmit(e)} className="d-flex flex-column">
          <div className="d-flex align-items-center">
            <div>
              <Image
                className="border rounded-circle mr-3"
                src={data?.logo}
                style={{ minWidth: '80px', minHeight: '80px', maxHeight: '80px', maxWidth: '80px' }}
              />
            </div>
            <h6>{data?.name}</h6>
          </div>
          <div className="d-flex flex-column mt-3">
            <Button disabled={loading} color="danger" type="submit">
              {t(Delete)}
            </Button>
            <Button onClick={() => toggleModal()} disabled={loading} type="button" className="mt-2">
              {t(cancel)}
            </Button>
          </div>
        </Form>
      </Modal>
      <UpdateAddHeader onClick={toggleModal} isNew={isNew} title={title} />
    </>
  );
};

interface MagazineTableProps {
  data?: unknown[];
}

const MagazineTable: FC<MagazineTableProps> = (props) => {
  const { data = [] } = props;
  const { download: downloader } = useDownloader();
  const { t } = useTranslation();
  const columnData = useMemo(() => {
    return [
      {
        name: <TableSectionTitle name="FiAperture" title={`${t(editions)}`} />,
        center: false,
        cell: (r: { title: string; id: number }) => {
          return (
            <Link className="text-black" to={'usersDetails'} param={(r?.id || '').toString()}>
              {r.title}
            </Link>
          );
        },
      },

      {
        name: <TableSectionTitle title={`${t(published)} ${t(date)}`} name="FaRegCalendar" />,
        selector: (r: { publishDate?: string }) => toDate(r.publishDate, false),
      },
      {
        name: (
          <TableSectionTitle
            title={
              <div className="d-flex flex-column">
                <span>{t(size)}</span>
                <span>{`(Mega Byte)`}</span>
              </div>
            }
            name="FaRegFileAlt"
          />
        ),
        selector: (r: { size?: number }) => (r?.size ? (r.size / Math.pow(1000, 2)).toFixed(4) : ''),
      },
      {
        name: <TableSectionTitle title={`${t(download)} ${t(file)}`} name="FiLayers" />,
        selector: () => '',
        center: true,
        cell: (r: { file: string; title: string }) => {
          return (
            <>
              {r.file && (
                <span role="button" onClick={() => downloader(r.file, r.file)}>
                  <Icon Name="FiDownloadCloud" />
                </span>
              )}
            </>
          );
        },
      },
      {
        name: '',
        cell: (r: { id: any }) => {
          return (
            <Link className="ml-auto text-white whitespace-nowrap" to={'magazineDetailEdition'} param={r.id.toString()}>
              <Button color="primary">{t(detail)}</Button>
            </Link>
          );
        },
      },
    ];
  }, []);
  return (
    <div className="mt-4">
      <DataTable data={data} columns={columnData} disabled={false} />
    </div>
  );
};

import { t_toggleValueFunc } from 'hooks';
import { UseFormHandleSubmit } from 'react-hook-form/dist/types/form';
import { Control, Controller } from 'react-hook-form';
import { t_errors } from 'tools/types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  addToRegisteredSchool,
  enter,
  grade,
  name,
  please,
  save,
  school,
  select,
  update,
} from 'tools/i18n/constants/i18n';
import { Form, FormFeedback, FormGroup, Label } from 'reactstrap';
import UploadProfileImage from 'components/uploadProfileImage';
import OnlyCharInput from 'components/inputs/onlyCharacter';
import Select from 'react-select';
import { EDUCATION_GRADE, EDUCATION_GRADE_TEXT } from 'tools/enums';
import { MAKE_GRADE_FILTER } from 'tools/methods';
import Icon from 'components/icons';
import { t_defaultValues, t_steps } from 'pages/school/schoolDetail';
import SubmitButton from 'components/buttons/submitButton';
import CityCounty from 'pages/school/components/cityCountry';

interface BasicFormProps {
  handleUploadIcon: (file: File) => void;
  steps: t_steps;
  toggleRegistered: t_toggleValueFunc;
  handleSubmit: UseFormHandleSubmit<t_defaultValues>;
  onSubmit: (data: t_defaultValues) => void;
  control: Control<t_defaultValues, any>;
  isRegistered: boolean;
  defaultValue?: t_defaultValues;
  loading: boolean;
  errors: t_errors<t_defaultValues>;
  isNew: boolean;
}
const BasicForm: FC<BasicFormProps> = (props) => {
  const {
    toggleRegistered,
    defaultValue,
    isRegistered,
    onSubmit,
    control,
    handleSubmit,
    steps,
    loading,
    handleUploadIcon,
    errors,
    isNew,
  } = props;
  const { t } = useTranslation();

  const ple = `${t(please)} ${t(enter)} ${t(school)}`;
  return (
    <div className="px-5 py-3">
      <div className="bg-gray rounded p-4">
        <Form onSubmit={handleSubmit(onSubmit, console.error)}>
          <FormGroup>
            <Controller
              name="logo"
              control={control}
              render={({ field: { onChange } }) => {
                return (
                  <UploadProfileImage
                    maxSize={512000}
                    avatarSize="70px"
                    onSelect={(file) => {
                      if (defaultValue) {
                        if (!isNew) {
                          handleUploadIcon(file);
                        }
                      }
                      onChange(file);
                    }}
                    defaultImage={defaultValue?.logo as string}
                  />
                );
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>{t(name)}</Label>
            <OnlyCharInput
              defaultValue={defaultValue?.name as string}
              placeholder={`${ple} ${t(name)}`}
              name="name"
              requiredErrorText={`${t(enter)} ${t(school)} ${t(name)}`}
              control={control}
              isNumberAllowed
              isEveryCharacterAllowed
              required
            />

            <FormFeedback>{errors?.name?.message}</FormFeedback>
          </FormGroup>
          <CityCounty errors={errors} defaultValue={defaultValue} control={control} />
          <FormGroup>
            <Label>{t(grade)}</Label>
            <Controller
              name="grade"
              control={control}
              rules={{ required: `${t(select)} ${t(school)} ${t(grade)}` }}
              render={({ field: { onChange } }) => {
                return (
                  <Select
                    isDisabled={isRegistered}
                    aria-disabled={isRegistered}
                    defaultValue={
                      defaultValue
                        ? {
                            label: EDUCATION_GRADE_TEXT[defaultValue?.grade as string],
                            value: defaultValue?.grade,
                          }
                        : undefined
                    }
                    name="grade"
                    options={MAKE_GRADE_FILTER()}
                    placeholder={`${ple} ${t(grade)}`}
                    onChange={(val) => onChange(val?.value)}
                  />
                );
              }}
            />
            <FormFeedback>{errors?.grade?.message}</FormFeedback>
          </FormGroup>
          {!isRegistered && <SubmitButton loading={loading}> {t(defaultValue?.name ? update : save)}</SubmitButton>}
          {steps !== 1 &&
            (defaultValue?.grade === EDUCATION_GRADE.ART_SCHOOL ||
              defaultValue?.grade === EDUCATION_GRADE.UNIVERSITY) &&
            !isRegistered && (
              <FormGroup className=" mt-3">
                <span role="button" className="d-flex align-items-center" onClick={() => toggleRegistered()}>
                  <Icon Name={isRegistered ? 'FiMinus' : 'FiPlusSquare'} />
                  <span className="ml-2">{t(addToRegisteredSchool)}</span>
                </span>
              </FormGroup>
            )}
        </Form>
      </div>
    </div>
  );
};

export default BasicForm;

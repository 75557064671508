import React, { ReactNode } from 'react';
import { Button, Spinner } from 'reactstrap';
import { ButtonProps } from 'react-bootstrap';

export interface SubmitButtonProps extends ButtonProps {
  loading: boolean;
  children?: ReactNode;
  color?: 'primary' | 'danger' | 'success' | 'warning' | 'transparent' | undefined;
}

export default function SubmitButton(props: SubmitButtonProps) {
  const { children, loading, color = 'primary', type = 'submit', className, ...rest } = props;
  return (
    <Button
      {...rest}
      disabled={loading}
      aria-disabled={loading}
      color={color}
      type={type}
      className={`d-block w-100 ${className}`}
    >
      {loading ? <Spinner size="sm" /> : children}
    </Button>
  );
}

import React, { ReactNode } from 'react';
import HorizontalLine, { HorizontalLineProps } from 'components/horizontalLine';
import Icon, { IconsName } from 'components/icons';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { agent, ban, results, show, type, user } from 'tools/i18n/constants/i18n';
import { useTranslation } from 'react-i18next';
import Styled from 'styled-components';
import SubmitButton from 'components/buttons/submitButton';

const FormContainer = Styled.div`
  width : 350px;
`;

const Checkbox = Styled(Input)`
  width : 20px;
  height : 20px;
  cursor : pointer;
`;

export type t_deletedTableFilter_defaultValues = {
  employer: boolean | undefined;
  employee: boolean | undefined;
  bannedByAdmin: boolean | undefined;
};

const DEFAULT_VALUES: t_deletedTableFilter_defaultValues = {
  employee: undefined,
  employer: undefined,
  bannedByAdmin: undefined,
};
interface IUsersTableCustomFilterProps {
  onFilter: (data: t_deletedTableFilter_defaultValues) => void;
  loading: boolean;
}

export default function DeletedUsersTableCustomFilter({ onFilter, loading }: IUsersTableCustomFilterProps) {
  const { control, handleSubmit } = useForm<t_deletedTableFilter_defaultValues>({
    defaultValues: DEFAULT_VALUES,
  });
  const { t } = useTranslation();
  const onSubmit: SubmitHandler<t_deletedTableFilter_defaultValues> = (data) => {
    console.log(data);
    onFilter(data);
  };
  return (
    <FormContainer>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <SectionLayout title={`${t(user)} ${t(type)}`} icon="FiType">
          <FormGroup className="d-flex align-items-center">
            <Controller
              name="employer"
              control={control}
              render={({ field: { onChange } }) => {
                return <Checkbox id="employer" type="checkbox" name="employer" onChange={onChange} />;
              }}
            />
            <Label role="button" htmlFor="employer" className="mb-0 ml-2">
              Kurumsal
            </Label>
          </FormGroup>
          <FormGroup className="d-flex align-items-center">
            <Controller
              name="employee"
              control={control}
              render={({ field: { onChange } }) => {
                return <Checkbox id="employee" type="checkbox" name="employee" onChange={onChange} />;
              }}
            />
            <Label role="button" htmlFor="employee" className="mb-0 ml-2">
              Bireysel
            </Label>
          </FormGroup>
        </SectionLayout>
        <SectionLayout title={`${t(ban)} ${t(agent)}`} icon="AiOutlineUser">
          <FormGroup className="d-flex align-items-center">
            <Controller
              name="bannedByAdmin"
              control={control}
              render={({ field: { onChange } }) => {
                return <Checkbox id="bannedByAdmin" type="checkbox" name="bannedByAdmin" onChange={onChange} />;
              }}
            />
            <Label role="button" htmlFor="bannedByAdmin" className="mb-0 ml-2">
              Deleted By Admin
            </Label>
          </FormGroup>
        </SectionLayout>

        <SubmitButton loading={loading} color="primary" className="d-block w-100">{`${t(show)} ${t(
          results
        )}`}</SubmitButton>
      </Form>
    </FormContainer>
  );
}
interface SectionLayoutProps extends HorizontalLineProps {
  title: string;
  icon: IconsName;
  children?: ReactNode;
}

function SectionLayout(props: SectionLayoutProps) {
  const { children, title, icon, className, lineHeight } = props;
  return (
    <div className="d-flex flex-column mb-3">
      <div className="d-flex align-items-center mb-2">
        <div className="d-flex align-items-center">
          <Icon Name={icon} size="18px" />
          <span className="h6 mx-2 mt-1">{title}</span>
        </div>
        <HorizontalLine className={className} lineHeight={lineHeight} />
      </div>
      {children}
    </div>
  );
}

import { gql } from '@apollo/client';

export const GET_EMPLOYEE_PROFILE = gql`
  query getStudentDetail($userId: Int!) {
    EmploymentGetEmployeeProfile(employeeProfileDto: { userId: $userId }) {
      userId
      bio
      firstName
      lastName
      profilePhoto
      address {
        title
        city
        county
        postalCode
        fullAddress
      }
      educationStatus {
        grade
        educationPlaceName
        educationSchool {
          _id
          name
          logo
          city
          county
          postalCode
          fullAddress
          grade
          websiteUrl
          bio
          contactPhoneCode
          contactPhoneNumber
          contactEmail
          uniqueCode
          studentCountCapacity
          isRegistered
          isVisible
          isActive
        }
        educationSection {
          _id
          originalName
          translations {
            language
            translate
          }
        }
        isLeaved
        dontHaveAnEducation
        level
        startAt
        endAt
      }
      speciality {
        branch {
          _id
          originalName
          imageUrl
          translations {
            language
            translate
          }
        }
        section {
          originalName
          translations {
            language
            translate
          }
          branchId
        }
      }
      dontHaveAWorkExperience
      workExperiences {
        _id
        speciality {
          createdAt
          updatedAt
          branch {
            originalName
            imageUrl
            translations {
              language
              translate
            }
          }
          section {
            originalName
            translations {
              language
              translate
            }
            branchId
          }
        }
        companyName
        company {
          _id
          companyId
          companyName
          logoUrl
          blueTick
        }
        startAt
        endAt
        description
        status
        rejectReasonCustomText
        referralName
        referralPhone
        changeStatusDate
        rejectReason {
          _id
          originalText
          translations {
            language
            translate
          }
        }
      }
      drivingLicense
      studentData {
        school {
          _id
          name
          logo
          city
          county
          postalCode
          fullAddress
          grade
          websiteUrl
          bio
          contactPhoneCode
          contactPhoneNumber
          contactEmail
          uniqueCode
          studentCountCapacity
          isRegistered
          isVisible
          isActive
        }
        startYear
        endYear
        level
        isSearchable
        status
      }
      hasRequirements
      birthdate
      nationality
      certificates
      dontHaveACertificate
      dontHaveASocialMedia
      socialMedias {
        name
        url
      }
      softwares {
        _id
        name
      }
      dontHaveASoftware
      maritalStatus
      languages {
        level
        language {
          _id
          name
        }
      }
      gender
      militaryStatus
    }
  }
`;

import { useMemo } from 'react';
import { add, New, size } from 'tools/i18n/constants/i18n';
import { useTranslation } from 'react-i18next';
import useQuery from 'hooks/useQuery';
import { GET_COMPANY_SIZES } from 'services/graphql/queries/App';

export type t_companySize = {
  id: string;
  fromStaffs: number;
  toStaffs: number;
  title: string;
};

export default function useCompanySizes() {
  const { t } = useTranslation();
  const { data, error, loading } = useQuery(GET_COMPANY_SIZES);
  const isListEmpty: boolean = useMemo(() => !!(data ? data?.getCompanySize?.length : 0), [data]);
  const updatedData: t_companySize[] | undefined = isListEmpty ? data.getCompanySize : [];

  const addNewTitle = `${t(add)} ${t(New)} ${t(size)}`;

  return { addNewTitle, isListEmpty, data: updatedData, loading, error };
}

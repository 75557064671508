import SubmitButton from 'components/buttons/submitButton';
import OnlyCharInput from 'components/inputs/onlyCharacter';
import ShowError from 'components/showError';
import useMutation from 'hooks/useMutation';
import { newComponentsProps } from 'pages/user-management/employer/index';
import React, { FC, memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Col, Form, FormFeedback, FormGroup, Label } from 'reactstrap';
import { ADD_COMPANY_DESCRIPTION } from 'services/graphql/queries/companies/createCompany.graphql';
import { company, description, input, name, save, update, updated } from 'tools/i18n/constants/i18n';
import { toNull } from 'tools/methods';
import { stringish } from 'tools/types';
import { formsNameEnum } from './types';

type CompanyAboutProps = newComponentsProps;

type t_defaultValues = {
  customError?: string;
  description: stringish;
  companyName: stringish;
};

const CompanyAbout: FC<CompanyAboutProps> = (props) => {
  const { isActive, componentsResponse, componentsValues, handleChangeComponentState, state } = props;
  const { t } = useTranslation();
  const [execute, { loading }] = useMutation(ADD_COMPANY_DESCRIPTION);
  const DEFAULT_VALUES: t_defaultValues = {
    //@ts-ignore
    companyName: toNull(componentsValues?.companyName),
    //@ts-ignore
    description: toNull(componentsValues?.description),
  };
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<t_defaultValues>({
    defaultValues: DEFAULT_VALUES,
  });
  const onSubmit = (data: t_defaultValues) => {
    setValue('customError', '');
    if (isActive) {
      console.log(data);
      execute({
        variables: {
          ...componentsResponse,
          ...data,
        },
      })
        .then((res) => {
          if (res.data?.mutationDescriptionLegalAccount) {
            if (state.isCompleted) {
              toast.success(`${t(updated)}`);
            }
            handleChangeComponentState(formsNameEnum.CompanyAbout, true);
          } else {
            //@ts-ignore
            setValue('customError', res.errors?.message);
          }
        })
        .catch((err) => {
          console.error(err);
          setValue('customError', err?.message);
        });
    }
  };

  useEffect(() => {
    if (componentsValues?.companyName && componentsValues?.description) {
      handleChangeComponentState(formsNameEnum.CompanyAbout, true);
    }
  }, [componentsValues?.companyName, componentsValues?.description]);

  return (
    <Col xxl={7} className="p-2 pb-4 mx-auto d-flex flex-column">
      <Form onSubmit={handleSubmit(onSubmit)} aria-disabled={!isActive}>
        <FormGroup>{!!getValues('customError') && <ShowError>{getValues('customError')}</ShowError>}</FormGroup>

        <FormGroup>
          <Label>{`${t(company)} ${t(name)}`}</Label>
          <OnlyCharInput
            defaultValue={DEFAULT_VALUES?.companyName}
            control={control}
            isEveryCharacterAllowed
            name="companyName"
            placeholder={`${t(company)} ${t(name)}`}
            disabled={!isActive}
            aria-disabled={!isActive}
            required
            rules={{
              minLength: {
                value: 3,
                message: `Company Name should be longer`,
              },
            }}
          />
          <FormFeedback>{errors?.companyName?.message}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>{`${t(company)} ${description}`}</Label>
          <OnlyCharInput
            isTextArea
            isRequired
            isEveryCharacterAllowed
            isNumberAllowed
            defaultValue={DEFAULT_VALUES.description}
            control={control}
            name="description"
            placeholder={t(description)}
            disabled={!isActive}
            aria-disabled={!isActive}
            rules={{
              minLength: {
                value: 3,
                message: `${t(input)} Should Be Longer`,
              },
            }}
          />
          <FormFeedback>{errors?.description?.message}</FormFeedback>
        </FormGroup>
        {isActive && (
          <SubmitButton className="w-100 mt-4" loading={loading}>
            {t(state.isCompleted ? update : save)}
          </SubmitButton>
        )}
      </Form>
    </Col>
  );
};
export default memo(CompanyAbout);

import useQuery from 'hooks/useQuery';
import { GET_STUDENT_DETAIL } from 'services/graphql/queries/studentManagement';
import { useParams } from 'react-router-dom';

export default function useStudentDetail() {
  const { type } = useParams();
  const { data, loading, error } = useQuery(GET_STUDENT_DETAIL, {
    variables: {
      studentId: type as string,
    },
  });

  return { data: data?.EmploymentSchoolStudentDetails, loading, error };
}

import { gql } from '@apollo/client';

export const GET_ALL_EMLAK_TYPE = gql`
  query getAllEmlakType($query: String) {
    realEstateFindAllCategoryTypes(realEstateCategoryTypeListDto: { query: $query }) {
      id
      title
      status
      icon
      hasLoan
      hasPrePaidPrice
      hasChargePrice
      hasDocumentStatus
      hasEquipped
      categoryTypeLanguage {
        translation
        language
      }
    }
  }
`;

export const CREATE_EMLAK_TYPE = gql`
  mutation createEmlakType(
    $language: [TranslationsDto!]!
    $hasLoan: Boolean
    $hasEquipped: Boolean
    $hasPrePaidPrice: Boolean
    $hasChargePrice: Boolean
    $hasDocumentStatus: Boolean
  ) {
    realEstateCreateCategoryType(
      realEstateCreateCategoryTypeDto: {
        hasLoan: $hasLoan
        translations: $language
        hasPrePaidPrice: $hasPrePaidPrice
        hasChargePrice: $hasChargePrice
        hasDocumentStatus: $hasDocumentStatus
        hasEquipped: $hasEquipped
      }
    ) {
      id
    }
  }
`;

export const UPDATE_EMLAK_TYPE = gql`
  mutation updateEmlakType(
    $language: [TranslationsDto!]
    $hasLoan: Boolean
    $hasEquipped: Boolean
    $categoryTypeId: Int!
    $hasPrePaidPrice: Boolean
    $hasChargePrice: Boolean
    $hasDocumentStatus: Boolean
  ) {
    realEstateUpdateCategoryType(
      realEstateUpdateCategoryTypeDto: {
        translations: $language
        hasLoan: $hasLoan
        categoryTypeId: $categoryTypeId
        hasPrePaidPrice: $hasPrePaidPrice
        hasChargePrice: $hasChargePrice
        hasDocumentStatus: $hasDocumentStatus
        hasEquipped: $hasEquipped
      }
    ) {
      id
    }
  }
`;

import React, { ReactNode } from 'react';
import HorizontalLine, { HorizontalLineProps } from 'components/horizontalLine';
import Icon, { IconsName } from 'components/icons';
import { useForm, Controller } from 'react-hook-form';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { activity, offline, online, results, show, status, type, user } from 'tools/i18n/constants/i18n';
import { useTranslation } from 'react-i18next';
import Styled from 'styled-components';
import SubmitButton from 'components/buttons/submitButton';

const FormContainer = Styled.div`
  width : 350px;
`;

const Checkbox = Styled(Input)`
  width : 20px;
  height : 20px;
  cursor : pointer;
`;

export type t_usersTableFilter_defaultValues = {
  employer: boolean | undefined;
  employee: boolean | undefined;
  isOnline: boolean | undefined;
  isOffline: boolean | undefined;
  banned: boolean | undefined;
  unbanned: boolean | undefined;
};

const DEFAULT_VALUES: t_usersTableFilter_defaultValues = {
  employee: undefined,
  employer: undefined,
  isOnline: undefined,
  isOffline: undefined,
  banned: undefined,
  unbanned: undefined,
};
interface IUsersTableCustomFilterProps {
  onFilter: (data: t_usersTableFilter_defaultValues) => void;
  loading: boolean;
}

export default function UsersTableCustomFilter(props: IUsersTableCustomFilterProps) {
  const { control, handleSubmit } = useForm<t_usersTableFilter_defaultValues>({
    defaultValues: DEFAULT_VALUES,
  });
  const { t } = useTranslation();
  const onSubmit = (data: t_usersTableFilter_defaultValues) => {
    props.onFilter(data);
  };
  return (
    <FormContainer>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <SectionLayout title={`${t(user)} ${t(type)}`} icon="FiType">
          <FormGroup className="d-flex align-items-center">
            <Controller
              name="employer"
              control={control}
              render={({ field: { onChange } }) => {
                return <Checkbox id="employer" type="checkbox" name="employer" onChange={onChange} />;
              }}
            />
            <Label role="button" htmlFor="employer" className="mb-0 ml-2">
              Kurumsal
            </Label>
          </FormGroup>
          <FormGroup className="d-flex align-items-center">
            <Controller
              name="employee"
              control={control}
              render={({ field: { onChange } }) => {
                return <Checkbox id="employee" type="checkbox" name="employee" onChange={onChange} />;
              }}
            />
            <Label role="button" htmlFor="employee" className="mb-0 ml-2">
              Bireysel
            </Label>
          </FormGroup>
        </SectionLayout>
        <SectionLayout title={`${t(user)} ${t(activity)}`} icon="FiActivity">
          <FormGroup className="d-flex align-items-center">
            <Controller
              name="isOnline"
              control={control}
              render={({ field: { onChange } }) => {
                return <Checkbox id="isOnline" type="checkbox" name="isOnline" onChange={onChange} />;
              }}
            />
            <Label role="button" htmlFor="isOnline" className="mb-0 ml-2">
              {t(online)}
            </Label>
          </FormGroup>
          <FormGroup className="d-flex align-items-center">
            <Controller
              name="isOffline"
              control={control}
              render={({ field: { onChange } }) => {
                return <Checkbox id="offline" type="checkbox" name="isOffline" onChange={onChange} />;
              }}
            />
            <Label role="button" htmlFor="offline" className="mb-0 ml-2">
              {t(offline)}
            </Label>
          </FormGroup>
        </SectionLayout>
        <SectionLayout title={`${t(user)} ${t(status)}`} icon="FaBan">
          <FormGroup className="d-flex align-items-center">
            <Controller
              name="banned"
              control={control}
              render={({ field: { onChange } }) => {
                return <Checkbox id="banned" type="checkbox" name="banned" onChange={onChange} />;
              }}
            />
            <Label role="button" htmlFor="banned" className="mb-0 ml-2">
              Blocked
            </Label>
          </FormGroup>
          <FormGroup className="d-flex align-items-center">
            <Controller
              name="unbanned"
              control={control}
              render={({ field: { onChange } }) => {
                return <Checkbox id="UnBanned" type="checkbox" name="unbanned" onChange={onChange} />;
              }}
            />
            <Label role="button" htmlFor="UnBanned" className="mb-0 ml-2">
              Active
            </Label>
          </FormGroup>
        </SectionLayout>
        <SubmitButton
          loading={props.loading}
          type="submit"
          color="primary"
          disabled={props.loading}
          className="d-block w-100"
        >{`${t(show)} ${t(results)}`}</SubmitButton>
      </Form>
    </FormContainer>
  );
}
interface SectionLayoutProps extends HorizontalLineProps {
  title: string;
  icon: IconsName;
  children?: ReactNode;
}

function SectionLayout(props: SectionLayoutProps) {
  const { children, title, icon, className, lineHeight } = props;
  return (
    <div className="d-flex flex-column mb-3">
      <div className="d-flex align-items-center mb-2">
        <div className="d-flex align-items-center">
          <Icon Name={icon} size="18px" />
          <span className="h6 mx-2 mt-1">{title}</span>
        </div>
        <HorizontalLine className={className} lineHeight={lineHeight} />
      </div>
      {children}
    </div>
  );
}

import { gql } from '@apollo/client';

export const INITIAL_JOB_ADS = gql`
  query GetInitAdsList($page: Int!, $size: Int!, $lang: AcceptedLanguageEnum) {
    EmploymentAdsList(adListDto: { pagination: { page: $page, size: $size }, lang: $lang }) {
      _id
      branch {
        _id
        originalName
        imageUrl
        translations {
          language
          translate
        }
      }
      section {
        _id
        originalName
        branchId
        translations {
          language
          translate
        }
      }
      address {
        _id
        title
        city
        county
        postalCode
        fullAddress
      }
      company {
        id
        name
        logUrl
        blueTick
        bio
      }
      releaseDate
      status
    }
  }
`;

export const GET_ADS_LIST = gql`
  query getAdsList(
    $page: Int!
    $size: Int!
    $lang: AcceptedLanguageEnum
    $speciality: AdListSpecialityFilterDto
    $experienceExpectations: [String!]
    $workingDays: AdListWorkingDaysFilterDto
    $workingHours: AdListWorkingHoursFilterDto
    $date: DateFromToDto
    $statusesMustBeShown: [AdStatusEnum!]
    $keyword: String
    $companyId: String
    $cities: [String!]
    $counties: [String!]
  ) {
    EmploymentAdsList(
      adListDto: {
        pagination: { page: $page, size: $size }
        lang: $lang
        filters: {
          speciality: $speciality
          experienceExpectations: $experienceExpectations
          workingDays: $workingDays
          workingHours: $workingHours
          date: $date
          cities: $cities
          counties: $counties
          keyword: $keyword
          statusesMustBeShown: $statusesMustBeShown
          companyId: $companyId
        }
      }
    ) {
      count
      data {
        _id
        branch {
          _id
          originalName
          imageUrl
          translations {
            language
            translate
          }
        }
        section {
          _id
          originalName
          branchId
          translations {
            language
            translate
          }
        }
        address {
          _id
          title
          city
          county
          postalCode
          fullAddress
        }
        company {
          id
          name
          logUrl
          blueTick
          bio
        }
        releaseDate
        status
      }
    }
  }
`;

export const GET_ADS_DETAILS = gql`
  query getAdsDetails($id: String!) {
    EmploymentAdDetails(adDetailsDto: { id: $id }) {
      _id
      description
      company {
        id
        name
        logUrl
        blueTick
        bio
      }
      branch {
        _id
        originalName
        imageUrl
        translations {
          language
          translate
        }
      }
      section {
        _id
        originalName
        translations {
          language
          translate
        }
        branchId
      }

      address {
        _id
        title
        city
        county
        postalCode
        fullAddress
      }
      releaseDate
      status
      workingDaysAndHours {
        sunday
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        startAt
        endAt
      }
      experienceExpectation {
        _id
        originalName
        translations {
          language
          translate
        }
      }
      sponsoredDate
      adminBanReason
      adminBanDate
    }
  }
`;

export const GET_ADS_STATISTICS = gql`
  query getStatistics($id: String!) {
    EmploymentAdStatistics(adStatisticsDto: { id: $id }) {
      _id
      applyStatistics {
        fitCount
        notFitCount
        notDecidedCount
        notReviewedCount
        reviewedCount
        hiredCount
      }
      sponsoredDate
      adminBanReason
      uniqueViewsCount
      allViewsCount
      expireDate
      releaseDate
      status
      views {
        date
        allViewsCount
        uniqueViewsCount
        sponsoredIn
      }
    }
  }
`;

export const GET_ADS_APPLY_LIST = gql`
  query getAdsList(
    $id: String!
    $page: Int!
    $size: Int!
    $speciality: AdListSpecialityFilterDto
    $fitStatus: AdAppliesFitStatusEnum
    $educationGrade: EducationGradeEnum
    $gender: GenderEnum
    $militaryStatus: MilitaryStatusEnum
    $maritalStatus: MaritalStatusEnum
    $cities: [String!]
    $counties: [String!]
    $ageRangeId: String
    $onlyStudents: Boolean
    $applyDataFrom: Timestamp
    $applyDataTo: Timestamp
    $keyword: String
  ) {
    EmploymentAdAppliesList(
      adAppliesListDto: {
        id: $id
        pagination: { page: $page, size: $size }
        filter: {
          fitStatus: $fitStatus
          speciality: $speciality
          educationGrade: $educationGrade
          gender: $gender
          militaryStatus: $militaryStatus
          maritalStatus: $maritalStatus
          applyData: { from: $applyDataFrom, to: $applyDataTo }
          cities: $cities
          keyword: $keyword
          onlyStudents: $onlyStudents
          ageRangeId: $ageRangeId
          counties: $counties
        }
      }
    ) {
      _id
      createdAt
      branches {
        _id
        originalName
        imageUrl
        translations {
          language
          translate
        }
      }
      sections {
        _id
        originalName
        translations {
          language
          translate
        }
        branchId
      }
      sender {
        name
        userId
        county
        city
        profilePhoto
      }
      status
      fitStatus
    }
  }
`;

export const GET_JOB_APPLY_DETAIL = gql`
  query getJobApplyDetail($id: String!) {
    EmploymentAdApplyDetails(adDetailsDto: { id: $id }) {
      createdAt
      updatedAt
      _id
      adId
      employeeUserId
      fitStatus
      status
      isContacted
      comments {
        createdAt
        updatedAt
        commentId
        text
        userFirstName
        userLastName
      }
      statistics {
        createdAt
        updatedAt
        userId
        type
      }
      employee {
        createdAt
        updatedAt
        _id
        userId
        bio

        address {
          _id
          title
          city
          county
          postalCode
          fullAddress
        }
        educationStatus {
          grade
          educationPlaceName
          educationSchool {
            createdAt
            updatedAt
            _id
            name
            logo
            city
            county
            postalCode
            fullAddress
            grade
            websiteUrl
            bio
            contactPhoneCode
            contactPhoneNumber
            contactEmail
            uniqueCode
            studentCountCapacity
            isRegistered
            isVisible
            isActive
          }
          educationSection {
            createdAt
            updatedAt
            _id
            originalName
            translations {
              language
              translate
            }
          }
          isLeaved
          dontHaveAnEducation
          level
          startAt
          endAt
        }
        speciality {
          createdAt
          updatedAt
          branch {
            _id
            originalName
            imageUrl
            translations {
              translate
              language
            }
          }
          section {
            _id
            originalName
            branchId
            translations {
              translate
              language
            }
          }
        }
        dontHaveAWorkExperience
        workExperiences {
          createdAt
          updatedAt
          _id
          speciality {
            createdAt
            updatedAt
            branch {
              _id
              originalName
              imageUrl
              translations {
                translate
                language
              }
            }
            section {
              _id
              originalName
              branchId
              translations {
                translate
                language
              }
            }
          }
          company {
            createdAt
            updatedAt
            _id
            companyId
            companyName
            logoUrl
            blueTick
            hasRequirements
            addresses {
              _id
              title
              city
              county
              postalCode
              fullAddress
            }
            specialities {
              createdAt
              updatedAt
              _id
              originalName
              imageUrl
              parentId
              translations {
                language
                translate
              }
              ancestors {
                createdAt
                updatedAt
                _id
                originalName
                imageUrl
                translations {
                  translate
                  language
                }
                parentId
              }
            }
            workingDaysAndHours {
              sunday
              monday
              tuesday
              wednesday
              thursday
              friday
              saturday
              startAt
              endAt
            }
          }
          startAt
          endAt
          description
          status
          rejectReasonCustomText
          referralName
          referralPhone
          changeStatusDate
          rejectReason {
            _id
            originalText
            translations {
              translate
              language
            }
          }
        }
        socialMedias {
          name
          url
        }
        softwares {
          _id
          name
        }
        languages {
          language {
            _id
            name
          }
          level
        }
        maritalStatus
        drivingLicense
        hasRequirements
        birthdate
        gender
        nationality
        certificates
        dontHaveACertificate
        dontHaveASoftware
        militaryStatus
      }
      statistics {
        userId
        type
      }
    }
  }
`;

export const ADMIN_BAN = gql`
  mutation adminBan($adId: String!, $reason: String!) {
    EmploymentAdminBanAd(adminBanAdDto: { adId: $adId, reason: $reason })
  }
`;

export const ADMIN_UNBAN = gql`
  mutation AdminUnban($adId: String!) {
    EmploymentAdminActiveAd(adminActiveAdDto: { adId: $adId })
  }
`;

export const GET_ALL_JOB_REPORTS = gql`
  query GetAllJobReports {
    employmentAdReportReasonsList {
      _id
      originalText
      translations {
        translate
        language
      }
    }
  }
`;

export const ADD_JOB_REPORT = gql`
  mutation AddJobReport($translations: [TranslationsDto!]) {
    employmentCreateAdReportReason(createAdReportReasonDto: { translations: $translations }) {
      _id
    }
  }
`;
export const UPDATE_JOB_REPORT = gql`
  mutation UpdateJobReport($id: String!, $translations: [TranslationsDto!]) {
    employmentUpdateAdReportReason(updateAdReportReasonDto: { translations: $translations, id: $id }) {
      _id
    }
  }
`;

export const GET_ALL_BAZAAR_ADS_REPORT = gql`
  query GetAllBazaarAdsReport {
    reportReasonList {
      id
      reason
      translations {
        language
        translation
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_ONE_BAZAAR_ADS_REPORT = gql`
  query GetOneBazaarAdsReport($id: Int!) {
    findOneReportReason(bazaarFindOneReportReasonDto: { id: $id }) {
      id
      reason
      translations {
        language
        translation
      }
      createdAt
      updatedAt
    }
  }
`;

export const ADD_BAZAAR_ADS_REPORT = gql`
  mutation AddBazaarAdsReport($translations: [TranslationsReportDto!]!) {
    createReportReason(bazaarCreatePosterReportReasonDto: { translations: $translations }) {
      id
    }
  }
`;

export const UPDATE_BAZAAR_ADS_REPORT = gql`
  mutation UpdateBazaarAdsReport($id: Int!, $translations: [TranslationsReportDto!]!) {
    updateReportReason(bazaarUpdatePosterReportReasonDto: { reasonId: $id, translations: $translations }) {
      id
    }
  }
`;

export const DELETE_BAZAAR_ADS_REPORT = gql`
  mutation DeleteBazaarAdsReport($id: Int!) {
    deleteReportReason(bazaarDeleteReportReason: { id: $id }) {
      message
    }
  }
`;

import { gql } from '@apollo/client';

export const GET_ALL_BAZZAR_ADS = gql`
  query GetAllBazzarAds(
    $skip: Int!
    $take: Int!
    $search: String
    $categoryId: Float
    $showAllResultInThisCategory: Boolean
    $currencySlug: String
    $priceFrom: Int
    $priceTo: Int
    $shopId: Int
    $city: [String!]
    $county: [String!]
    $posterType: PosterTypeEnum
    $status: PosterStatusEnum
    $model: String
    $isReviewed: Boolean
    $onlyWithPictures: Boolean
    $onlyBookmarks: Boolean
    $entrance: String
    $end: String
  ) {
    findAllBazaarPosters(
      findAllBazaarPostersDto: {
        skip: $skip
        take: $take
        search: $search
        categoryId: $categoryId
        showAllResultInThisCategory: $showAllResultInThisCategory
        currencySlug: $currencySlug
        priceFrom: $priceFrom
        priceTo: $priceTo
        shopId: $shopId
        city: $city
        counties: $county
        posterType: $posterType
        status: $status
        model: $model
        isReviewed: $isReviewed
        onlyWithPictures: $onlyWithPictures
        onlyBookmarks: $onlyBookmarks
        startDate: $entrance
        endDate: $end
      }
    ) {
      count
      data {
        id
        title
        status
        pictures {
          fileName
          fileUrl
        }
        model
        type
        publishedAt
        price
        category {
          id
          title
          status
          icon
          picture
          createdAt
          updatedAt
          requireModel
          language {
            language
            translation
          }
          child {
            id
            title
            status
            icon
            picture
            createdAt
            updatedAt
            requireModel
            language {
              language
              translation
            }
          }
        }
        currency {
          name
          slug
          symbol
        }
        addresses {
          title
          city
          county
        }
        ownerInfo {
          name
          blueMark
          picture
        }
      }
    }
  }
`;

export const FIND_ONE_BAZAAR_ADS = gql`
  query FindOneBazaarAd($id: Int!) {
    findOneBazaarPoster(findOneBazaarPosterDto: { id: $id }) {
      id
      title
      contacts {
        id
        phoneCode
        phoneNumber
      }
      description
      status
      pictures {
        fileName
        fileUrl
      }
      model
      type
      createdAt
      updatedAt
      publishedAt
      sortDate
      price
      category {
        id
        title
        status
        icon
        picture
        createdAt
        updatedAt
        requireModel
        language {
          language
          translation
        }
        child {
          id
          title
          status
          icon
          picture
          createdAt
          updatedAt
          requireModel
          language {
            language
            translation
          }
        }
      }
      currency {
        name
        slug
        symbol
      }
      addresses {
        id
        title
        city
        county
        postalCode
        fullAddress
      }
      banActivity {
        id
        description
        status
        operator
        createdAt
      }
      ownerInfo {
        id
        name
        blueMark
        isShop
        picture
        primaryPhone
        primaryPhoneCode
        secondaryPhone
        secondaryPhoneCode
      }
    }
  }
`;

export const GET_ONE_BAZAAR_ADS_STATISTICS = gql`
  query FindOneBazaarAd($id: Int!) {
    findOneBazaarPoster(findOneBazaarPosterDto: { id: $id }) {
      statistics {
        saved
        called
        copiedThePhoneNumber
        askedForNumber
        totalViews
        uniqueViews
      }
      statusActivity {
        id
        description
        status
        operator
        createdAt
      }
      activity {
        id
        userId
        askedForNumber
        called
        copiedThePhoneNumber
        isUnique
        bookmarked
      }
    }
  }
`;

export const BAN_BAZAAR_AD = gql`
  mutation BanBazaarAd($description: String!, $posterId: Int!) {
    banBazaarPoster(updateBazaarPosterStatusDto: { description: $description, posterId: $posterId }) {
      message
    }
  }
`;

export const UNBAN_BAZAAR_AD = gql`
  mutation UnbanBazaarAd($description: String!, $posterId: Int!) {
    activeBazaarPoster(updateBazaarPosterStatusDto: { description: $description, posterId: $posterId }) {
      message
    }
  }
`;

import { useEffect } from 'react';
import { decode, getCookie, getStorages, saveStorage } from 'tools/methods';
import { t_lang } from 'store/app/app.reducer';
import { DEFAULT_COUNTRY } from 'tools/constants';
import useLanguage from 'hooks/useLanguage';
import { d_loginFail, d_loginInfo, d_LoginSuccess } from 'store/user/user.dispatchs';

export default function useInitializing() {
  const defaultAppLang = process.env.REACT_APP_LANG || DEFAULT_COUNTRY.key;
  const { changeLanguage } = useLanguage();

  const checkAppLang = () => {
    try {
      const selectedLang = getStorages('i18nextLng');
      if (selectedLang) {
        if (selectedLang === 'en-US') {
          changeLanguage('en');
        } else if (selectedLang === 'tr-TR') {
          changeLanguage('tr');
        } else {
          changeLanguage('en');
        }
      } else {
        changeLanguage(defaultAppLang as t_lang);
        saveStorage('i18nextLng', defaultAppLang);
      }
    } catch (e) {
      console.error(e);
      saveStorage('i18nextLng', defaultAppLang);
    }
  };
  const checkAuth = () => {
    try {
      const token = getCookie('token');
      if (token) {
        d_loginInfo(decode(token));
        d_LoginSuccess();
      } else d_loginFail();
    } catch (e) {
      console.error(e);
      d_loginFail();
    }
  };
  const effectCallback = () => {
    checkAuth();
    checkAppLang();
  };
  useEffect(effectCallback, []);
}

import React, { useMemo, useRef, useState } from 'react';
import { Button, Form, FormFeedback, FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import {
  action,
  banner,
  enter,
  inputs,
  next,
  notification,
  please,
  saved,
  type,
  upload,
} from 'tools/i18n/constants/i18n';
import { useTranslation } from 'react-i18next';
import { ICommonProps, NotificationAction } from 'pages/notification/types';
import { toUpperCase } from 'tools/methods';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useToggle } from 'hooks';
import { UPDATE_ACTION_NOTIFICATION } from 'services/graphql/queries/notification';
import { toast } from 'react-toastify';
import ShowError from 'components/showError';
import { WebsiteInput } from 'components/inputs';
import UploadCover from 'components/inputs/uploadCover';
import FormActionLoading, { step } from 'components/formActionLoading';
import FetchApi from 'services/api/axios';
import { findAction } from 'pages/notification/entities';

export type ActionProps<N extends boolean> = ICommonProps<N>;

type t_OPTION = { label: string; value: NotificationAction };

type t_defaultValues = {
  customError?: string;
  actionType?: NotificationAction;
  url?: string;
  bannerUrl?: string;
  bannerName?: string;
};
type stepInfo = {
  index: number;
  pending: boolean;
  waiting: boolean;
  completed: boolean;
  error: boolean;
  fileName?: string;
  percent: number;
};

export function Action<N extends boolean>(props: ActionProps<N>): JSX.Element {
  const { goNext, handleChangeSavedData, savedData } = props;
  const { t } = useTranslation();
  const [openLoadingUi, toggleOpenUI] = useToggle(false);
  const lastSubmitedData = useRef<t_defaultValues>();
  const [updateExecute, { loading: updateLoading }] = useMutation(UPDATE_ACTION_NOTIFICATION);
  const [stepsInfo, setStepsInfo] = useState<stepInfo[]>([
    {
      index: 0,
      error: false,
      percent: 0,
      pending: false,
      completed: false,
      waiting: false,
    },
    {
      index: 1,
      error: false,
      fileName: 'Cover',
      percent: 0,
      pending: false,
      completed: false,
      waiting: false,
    },
  ]);
  const handleChangeStep = (step: 0 | 1, status: step['status']) => {
    const defaultStatus: Record<step['status'], boolean> = {
      WAITING: false,
      UPLOADING: false,
      FAILED: false,
      COMPLETED: false,
    };
    setStepsInfo((prev) => {
      return prev.map((item) => {
        if (item.index !== step) return item;
        return { ...item, ...defaultStatus, [status]: true };
      });
    });
  };
  const INITIAL_STATE: t_defaultValues = {
    actionType: savedData?.actionType || NotificationAction.OPEN_PROFILE,
    bannerUrl: savedData?.payload.bannerUrl,
    url: savedData?.payload?.url,
  };

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    getValues,
    watch,
    formState: { errors },
  } = useForm<t_defaultValues>({
    defaultValues: INITIAL_STATE,
  });
  const Steps: step[] = [
    {
      index: 0,
      title: `${t(upload)} ${t(banner)}`,
      status: stepsInfo[0].completed
        ? 'COMPLETED'
        : stepsInfo[0].error
        ? 'FAILED'
        : stepsInfo[0].waiting
        ? 'WAITING'
        : 'UPLOADING',
      progressConfig: {
        percent: stepsInfo[0].percent,
      },
      fileName: 'Banner',
      onRetry: () => onSubmit(lastSubmitedData.current as t_defaultValues),
    },
    {
      index: 1,
      title: `${t(upload)} ${t(inputs)}`,
      status: stepsInfo[1].completed
        ? 'COMPLETED'
        : stepsInfo[1].error
        ? 'FAILED'
        : stepsInfo[1].waiting
        ? 'WAITING'
        : 'UPLOADING',
      progressConfig: {
        percent: stepsInfo[1].percent,
      },
      fileName: `Languages ( English, Turkish ) ${getValues('url') ? ',Url' : ''}`,
      onRetry: () => onSubmit(lastSubmitedData.current as t_defaultValues),
    },
  ];

  const OPTIONS: t_OPTION[] = useMemo(() => {
    return findAction(!!savedData.filters?.sendToAll, savedData.filters?.userType, savedData.filters?.userRoles);
  }, [savedData]);
  const onSubmit = async (data: t_defaultValues) => {
    setValue('customError', '');
    lastSubmitedData.current = data;
    if (data.bannerUrl) {
      toggleOpenUI(true);
    }
    data['isDraft'] = true;
    const variables = {
      isDraft: true,
      id: savedData?.id,
      actionType: data.actionType,
    };
    if (data.url) {
      variables['payload'] = {
        ...savedData.payload,
        url: data.url,
      };
    }
    if (data.bannerUrl && data.bannerUrl !== INITIAL_STATE.bannerUrl) {
      handleChangeStep(0, 'UPLOADING');
      handleChangeStep(1, 'WAITING');
      const formData = new FormData();
      formData.append('file', data.bannerUrl);
      const uploadResponse = await FetchApi({
        url: 'CUSTOM_NOTIFICATION_BANNER',
        method: 'POST',
        data: formData,
        onUploadProgress: (progressEvent) => {
          setStepsInfo((prev) => {
            return prev.map((item) => {
              if (item.index !== 0) return item;
              return {
                ...item,
                pending: true,
                completed: false,
                error: false,
                percent: Math.round((progressEvent.loaded * 100) / progressEvent.total),
              };
            });
          });
        },
      });
      if (uploadResponse.data) {
        handleChangeStep(0, 'COMPLETED');
        data['bannerUrl'] = uploadResponse.data.data.bannerUrl;
        variables['payload'] = {
          ...savedData?.payload,
          url: data?.url,
          imageName: uploadResponse.data.data.imageName,
          bannerUrl: uploadResponse.data.data.bannerUrl,
        };
      } else {
        handleChangeStep(0, 'FAILED');
      }
    }
    handleChangeStep(1, 'UPLOADING');
    //@ts-ignore
    const payload = variables?.payload?.contents?.length
      ? {
          //@ts-ignore
          url: variables?.payload?.url,
          //@ts-ignore
          contents: variables?.payload?.contents,
          //@ts-ignore
          imageName: variables?.payload?.imageName,
        }
      : undefined;
    const response = await updateExecute({
      variables: {
        ...variables,
        payload,
      },
    });
    if (response?.data?.updateCustomNotification) {
      handleChangeStep(1, 'COMPLETED');
      handleChangeSavedData({
        actionType: data.actionType,
        payload: {
          ...savedData?.payload,
          //@ts-ignore
          ...variables?.payload,
          imageName: variables?.['payload']?.imageName,
        },
      });
      toast.success(`${t(saved)}`);
      goNext(3);
    } else {
      handleChangeStep(1, 'FAILED');
      // @ts-ignore
      setValue('customError', response?.errors?.[0]?.extensions?.exception?.response?.devMessage || 'Error Happened');
    }
  };
  return (
    <>
      <FormActionLoading open={openLoadingUi} onClose={toggleOpenUI} steps={Steps} />
      <Form onSubmit={handleSubmit(onSubmit)} className="p-3">
        {!!getValues('customError') && (
          <FormGroup>
            <ShowError>{getValues('customError')}</ShowError>
          </FormGroup>
        )}

        <FormGroup className="bg-gray p-5 rounded-md">
          <Label>Aksiyon</Label>
          <Controller
            control={control}
            defaultValue={INITIAL_STATE.actionType}
            rules={{ required: `${t(please)} ${t(enter)} ${t(action)} ${t(type)}` }}
            name="actionType"
            render={({ field: { onChange } }) => {
              return (
                <Select
                  defaultValue={
                    INITIAL_STATE.actionType
                      ? {
                          label: OPTIONS.find((o) => o.value === INITIAL_STATE.actionType)?.label,
                          value: INITIAL_STATE?.actionType,
                        }
                      : undefined
                  }
                  onChange={(val) => onChange(val?.value)}
                  options={OPTIONS}
                />
              );
            }}
          />
          <FormFeedback>{errors?.actionType?.message}</FormFeedback>
        </FormGroup>
        {watch('actionType') === NotificationAction.MESSAGE_DETAILS && (
          <FormGroup className="flex-grow-1 p-5 rounded-md bg-gray">
            <FormGroup>
              <Label>Url</Label>
              <WebsiteInput
                defaultValue={INITIAL_STATE.url}
                placeholder={`${t(please)} ${t(enter)} url`}
                control={control}
                name="url"
              />
              <FormFeedback>{errors?.url?.message}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label>{`${t(upload)} ${t(notification)} ${t(banner)}`}</Label>
              <Controller
                control={control}
                name="bannerUrl"
                defaultValue={INITIAL_STATE.bannerUrl}
                render={({ field: { onChange } }) => {
                  return (
                    <UploadCover
                      defaultImage={INITIAL_STATE.bannerUrl as string}
                      onSelect={(file) => {
                        if (file?.size || 0 <= 1024 * 1024) {
                          setValue('bannerName', file?.name);
                          onChange(file);
                        } else {
                          setError('bannerUrl', { message: 'Size Exceeds' });
                        }
                      }}
                    />
                  );
                }}
              />
              <FormFeedback>{errors?.bannerUrl?.message}</FormFeedback>
            </FormGroup>
          </FormGroup>
        )}
        <Button disabled={updateLoading} type="submit" color="primary" className="d-block rounded-md ml-auto">
          Sonraki
        </Button>
      </Form>
    </>
  );
}

export default Action;

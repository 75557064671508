import useQuery from 'hooks/useQuery';
import { GET_SCHOOL_LIST } from 'services/graphql/queries/school';
import { useMemo } from 'react';
import { isTrophy } from 'tools/methods';
import { APP_HELPER } from 'tools/enums';
import { useGetStore } from 'hooks';
import { useSearchParams } from 'react-router-dom';

export default function useSchool() {
  const currentLang = useGetStore().App.lang;
  const [param] = useSearchParams();
  const defaultPage = param.get('page') ? parseInt(param.get('page') as string) : APP_HELPER.tableDefaultPage;
  const defaultSize = param.get('size') ? parseInt(param.get('size') as string) : APP_HELPER.tableDefaultSize;
  const { loading, data, error, fetchMore } = useQuery(GET_SCHOOL_LIST, {
    defaultOptions: {
      variables: {
        page: defaultPage,
        size: defaultSize,
        lang: currentLang,
      },
    },
  });

  const isListEmpty = useMemo(() => !isTrophy(data?.EmploymentSchoolsList?.data), [data]);
  const updatedData = useMemo(() => (isListEmpty ? { count: 0, data: [] } : data.EmploymentSchoolsList), [isListEmpty]);
  return { loading, data: updatedData, error, fetchMore };
}

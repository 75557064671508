import React, { useRef, useState } from 'react';
import Icon from 'components/icons';
import { IMAGE_TYPES } from 'tools/constants';
import { image, please, select, valid } from 'tools/i18n/constants/i18n';
import { useTranslation } from 'react-i18next';
import ImgToBackGround from 'components/imgToBackground';

interface UploadCoverProps {
  onSelect?: (file?: File) => void;
  defaultImage?: string;
  className?: string;
  imageContainerClassName?: string;
}

export default function UploadCover(props: UploadCoverProps) {
  const { onSelect, defaultImage, className = '', imageContainerClassName = '' } = props;
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState('');
  const [src, setSrc] = useState(defaultImage);
  const handleFocus = () => {
    inputRef.current?.click();
  };
  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (IMAGE_TYPES.includes(e?.target?.files?.[0]?.type || '')) {
      if (containerRef.current) {
        setError('');
        // @ts-ignore
        setSrc(URL.createObjectURL(e.target?.files?.[0]));
        onSelect?.(e.target?.files?.[0]);
        containerRef.current.setAttribute('data-pdf', e.target?.files?.[0]?.name || '');
      }
    } else {
      setSrc(undefined);
      setError(`${t(please)} ${t(select)} ${t(valid)} ${t(image)}`);
    }
  };
  return (
    <div
      ref={containerRef}
      style={{ backgroundColor: src ? 'transparent' : undefined }}
      className={`upload-cover-container border ${className}`}
    >
      {!error && !!src && (
        <div className={`image-container ${imageContainerClassName}`}>
          <ImgToBackGround src={src} />
        </div>
      )}
      <input onChange={handleSelectFile} ref={inputRef} type="file" />
      {!src && (
        <div role={src ? '' : 'button'} onClick={src ? undefined : handleFocus} className="icon-container make-center">
          <Icon Name={error ? 'FiAlertOctagon' : 'FaRegImages'} color="#fff" size="30px" />
        </div>
      )}
      {(!!src || !!error) && (
        <span role="button" onClick={handleFocus}>
          <Icon Name="FaPencilAlt" size="16px" />
        </span>
      )}
      {!!error && (
        <div className="show-error d-inline-block position-absolute p-1 rounded mx-auto shadow-text-danger">
          {error}
        </div>
      )}
    </div>
  );
}

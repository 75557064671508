import { TypedDocumentNode, useMutation as useM } from '@apollo/client';
import { ApolloCache, DefaultContext, OperationVariables } from '@apollo/client/core';
import { FetchResult } from '@apollo/client/link/core';
import { MutationFunctionOptions, MutationHookOptions, MutationTuple } from '@apollo/client/react/types/types';
import { DocumentNode } from 'graphql/language';
import useToggle from 'hooks/useToggle';
import { mutation } from 'services/graphql';
import { GET_REFRESH_TOKEN } from 'services/graphql/queries/authentication';
import { getCookie, logout, setCookie } from 'tools/methods';

export type t_useMutation<TResponse = any, TMutationVars extends OperationVariables = OperationVariables> = <
  TData extends TResponse,
  TVariables extends TMutationVars,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables, TContext, TCache>
) => MutationTuple<TData, TVariables, TContext, TCache>;

export function useMutation<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables, TContext, TCache>
): MutationTuple<TData, TVariables, TContext, TCache> {
  const [exe, { client, loading, ...rest }] = useM(mutation, {
    ...options,
    fetchPolicy: 'no-cache',
  });
  const [realLoading, toggleRealLoading] = useToggle(loading);

  const handleExecute: (
    options?: MutationFunctionOptions<TData, TVariables, TContext, TCache>
  ) => Promise<FetchResult<TData>> = (option) => {
    return new Promise((resolve, reject) => {
      toggleRealLoading(true);
      exe(option)
        .then(async (result) => {
          // @ts-ignore
          if (result.errors?.message === 'jwt expired') {
            toggleRealLoading(true);
            getRefreshToken(() => {
              handleFetchAgain(option).then(resolve).catch(reject);
            });
          } else {
            toggleRealLoading(false);
            resolve(result);
          }
        })
        .catch((error: any) => {
          const status =
            error?.graphQLErrors[0].extensions.httpStatus ||
            error?.graphQLErrors[0]?.extensions?.exception?.status ||
            error?.graphQLErrors[0]?.extensions?.response?.statusCode;
          if (status === 401) {
            toggleRealLoading(true);
            getRefreshToken(() => {
              handleFetchAgain(option).then(resolve).catch(reject);
            });
          } else {
            reject(error);
            toggleRealLoading(false);
          }
        });
    });
  };

  function handleFetchAgain(option: any) {
    return handleExecute(option);
  }

  return [handleExecute, { client, loading: realLoading, ...rest }];
}
export default useMutation;

function getRefreshToken(cb?: VoidFunction) {
  mutation({
    mutation: GET_REFRESH_TOKEN,
    variables: {
      jwtRefreshToken: getCookie('refreshToken'),
    },
  })
    .then((r) => {
      console.log(r);
      setCookie({
        name: 'token',
        value: r?.data?.refreshToken.token,
      });
      cb?.();
    })
    .catch((e) => {
      logout();
      console.log(e);
    });
}

import Icon from 'components/icons';
import ShowError from 'components/showError';
import { useToggle, useUpdateEffect } from 'hooks';
import React, { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { retry } from 'tools/i18n/constants/i18n';

export interface LoadingSpin {
  children: ReactNode;
  loading: boolean;
  onFail?: VoidFunction;
  isFailed: boolean;
  onRetry: VoidFunction;
}

export function LoadingSpin({ children, isFailed, loading, onFail, onRetry }: LoadingSpin) {
  const { t } = useTranslation();
  const [toggleLoading, toggler] = useToggle(loading);
  const handleClickedRetry = () => {
    toggler(true);
    onRetry();
  };
  useUpdateEffect(() => {
    toggler(false);
  }, [isFailed, loading]);
  useEffect(() => {
    if (isFailed) onFail?.();
  }, [isFailed]);
  if (toggleLoading) {
    return <Spinner color="primary" size="sm" />;
  }
  if (isFailed) {
    return (
      <ShowError>
        <div role="button" onClick={handleClickedRetry} className="text-black d-flex align-item-center">
          <span>{t(retry)}</span>
          <div className="ml-2 make-center">
            <Icon Name="FaSpinner" />
          </div>
        </div>
      </ShowError>
    );
  }
  return <> {children} </>;
}

export default LoadingSpin;

import SubmitButton from 'components/buttons/submitButton';
import Icon from 'components/icons';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Select from 'react-select';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import Styled from 'styled-components';
import { EDUCATION_GRADE, EDUCATION_GRADE_TEXT } from 'tools/enums';

const StyledContainer = Styled.div`
  min-width: 300px;
`;

const StyledCheckbox = Styled(Input)`
  width: 25px;
  height: 25px;
`;

export type t_filter = {
  grade: EDUCATION_GRADE | undefined;
  isRegistered: boolean | undefined;
  isSimple: boolean | undefined;
  isActive: boolean | undefined;
  inActive: boolean | undefined;
  isVisible: boolean | undefined;
  inVisible: boolean | undefined;
};

interface FilterProps {
  onFilter: SubmitHandler<t_filter>;
  loading: boolean;
}

const DEFAULT_VALUES: t_filter = {
  grade: undefined,
  isActive: undefined,
  isSimple: undefined,
  inActive: undefined,
  isRegistered: undefined,
  isVisible: undefined,
  inVisible: undefined,
};

const GRADE_DROP_DOWN = [
  { label: 'Select Option', value: undefined },
  { label: EDUCATION_GRADE_TEXT.PRIMARY_SCHOOL, value: EDUCATION_GRADE.PRIMARY_SCHOOL },
  { label: EDUCATION_GRADE_TEXT.MIDDLE_SCHOOL, value: EDUCATION_GRADE.MIDDLE_SCHOOL },
  { label: EDUCATION_GRADE_TEXT.HIGH_SCHOOL, value: EDUCATION_GRADE.HIGH_SCHOOL },
  { label: EDUCATION_GRADE_TEXT.ART_SCHOOL, value: EDUCATION_GRADE.ART_SCHOOL },
];

export default function SchoolsFilter({ onFilter, loading }: FilterProps) {
  const { handleSubmit, control } = useForm<t_filter>({ defaultValues: DEFAULT_VALUES });
  const onSubmit: SubmitHandler<t_filter> = (data) => {
    console.log(data);

    if (data.isRegistered && data.isSimple) {
      data['isRegistered'] = undefined;
    } else if (data.isSimple) {
      data['isRegistered'] = false;
    } else if (data.isRegistered) {
      data['isRegistered'] = true;
    }
    if (data.isActive && data.inActive) {
      data['isActive'] = undefined;
    } else if (data.inActive) {
      data['isActive'] = false;
    } else if (data.isActive) {
      data['isActive'] = true;
    }

    if (data.isVisible && data.inVisible) {
      data['isVisible'] = undefined;
    } else if (data.inVisible) {
      data['isVisible'] = false;
    } else if (data.isVisible) {
      data['isVisible'] = true;
    }
    delete data.isSimple;
    delete data.inActive;
    delete data.inVisible;

    console.log(data);
    onFilter(data);
  };
  return (
    <StyledContainer>
      <Form aria-disabled={loading} onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label className="w-100">
            <div className="d-flex">
              <Icon Name="FaUserGraduate" />
              <span className="ml-2">Grade</span>
            </div>
            <hr />
          </Label>
          <Controller
            control={control}
            name="grade"
            render={({ field: { onChange } }) => {
              return (
                <Select
                  options={GRADE_DROP_DOWN}
                  onChange={(val) => {
                    if (val) {
                      onChange(val.value);
                    }
                  }}
                />
              );
            }}
          />
        </FormGroup>
        <FormGroup>
          <div className="d-flex align-items-center">
            <Label htmlFor="registeredSchool" className="w-100 mb-0">
              <div className="d-flex">
                <Icon Name="FaRegistered" />
                <span className="ml-2"> Registered School </span>
              </div>
            </Label>
            <Controller
              control={control}
              name="isRegistered"
              render={({ field: { name, onChange, ref } }) => {
                return (
                  <StyledCheckbox
                    id="registeredSchool"
                    className="mb-0"
                    type="checkbox"
                    name={name}
                    onChange={(event: any) => onChange(event.target.checked)}
                    ref={ref}
                  />
                );
              }}
            />
          </div>
          <hr />
        </FormGroup>
        <FormGroup>
          <div className="d-flex align-items-center">
            <Label htmlFor="simpleSchool" className="mb-0 w-100">
              <div className="d-flex">
                <Icon Name="FaSchool" />
                <span className="ml-2"> Simple School </span>
              </div>
            </Label>
            <Controller
              control={control}
              name="isSimple"
              render={({ field: { name, onChange, ref } }) => {
                return (
                  <StyledCheckbox
                    id="simpleSchool"
                    type="checkbox"
                    name={name}
                    onChange={(event: any) => onChange(event.target.checked)}
                    ref={ref}
                  />
                );
              }}
            />
          </div>
          <hr />
        </FormGroup>

        <FormGroup>
          <div className="d-flex align-items-center">
            <Label htmlFor="isActive" className="w-100 mb-0">
              <div className="d-flex">
                <Icon Name="FaGripLinesVertical" />
                <span className="ml-2"> Is Active </span>
              </div>
            </Label>
            <Controller
              control={control}
              name="isActive"
              render={({ field: { name, onChange, ref } }) => {
                return (
                  <StyledCheckbox
                    id="isActive"
                    type="checkbox"
                    name={name}
                    onChange={(event: any) => onChange(event.target.checked)}
                    ref={ref}
                  />
                );
              }}
            />
          </div>
          <hr />
        </FormGroup>

        <FormGroup>
          <div className="d-flex align-items-center">
            <Label htmlFor="inActive" className="w-100 mb-0">
              <div className="d-flex">
                <Icon Name="FaStopCircle" />
                <span className="ml-2"> In Active </span>
              </div>
            </Label>
            <Controller
              control={control}
              name="inActive"
              render={({ field: { name, onChange, ref } }) => {
                return (
                  <StyledCheckbox
                    type="checkbox"
                    id="inActive"
                    name={name}
                    onChange={(event: any) => onChange(event.target.checked)}
                    ref={ref}
                  />
                );
              }}
            />
          </div>
          <hr />
        </FormGroup>

        <FormGroup>
          <div className="d-flex align-items-center">
            <Label htmlFor="isVisible" className="mb-0 w-100">
              <div className="d-flex">
                <Icon Name="FaEye" />
                <span className="ml-2"> Is Visible </span>
              </div>
            </Label>
            <Controller
              control={control}
              name="isVisible"
              render={({ field: { name, onChange, ref } }) => {
                return (
                  <StyledCheckbox
                    id="isVisible"
                    type="checkbox"
                    name={name}
                    onChange={(event: any) => onChange(event.target.checked)}
                    ref={ref}
                  />
                );
              }}
            />
          </div>
          <hr />
        </FormGroup>

        <FormGroup>
          <div className="d-flex align-items-center">
            <Label htmlFor="inVisible" className="mb-0 w-100">
              <div className="d-flex">
                <Icon Name="FaEyeSlash" />
                <span className="ml-2"> In Visible </span>
              </div>
            </Label>
            <Controller
              control={control}
              name="inVisible"
              render={({ field: { name, onChange, ref } }) => {
                return (
                  <StyledCheckbox
                    id="inVisible"
                    type="checkbox"
                    name={name}
                    onChange={(event: any) => onChange(event.target.checked)}
                    ref={ref}
                  />
                );
              }}
            />
          </div>
          <hr />
        </FormGroup>
        <SubmitButton loading={loading}>Filter</SubmitButton>
      </Form>
    </StyledContainer>
  );
}

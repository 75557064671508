import useQuery from 'hooks/useQuery';
import { GET_ALL_DOCUMENT_TYPES } from '../../../../services/graphql/queries/documentTypes';
import { t_languageKeys } from '../../../../tools/types';
import { useMemo } from 'react';
import { toArray } from '../../../../tools/methods';

type documentTypeId = {
  name: string;
  id: number;
  isRequired: boolean;
  isHidden: boolean;
  language: {
    id: number;
    name: string;
    language: t_languageKeys;
  }[];
};

export default function useCompanyDocuments() {
  const { data, loading, refetch } = useQuery<{ getDocumentType: documentTypeId[] }>(GET_ALL_DOCUMENT_TYPES);
  console.log(data);
  const arrangedData = useMemo(() => {
    return data?.getDocumentType
      .filter((item) => !item.isHidden)
      .map((item) => ({
        id: item.id,
        isRequired: item.isRequired,
        name: item.language.find((l) => l.language === 'tr')?.name as string,
      }));
  }, [data]);

  return { data: toArray(arrangedData), loading, refetch };
}

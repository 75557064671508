import { gql } from '@apollo/client';

export const GET_ALL_DOCUMENT_REJECTION_REASONS = gql`
  query getAllDocumentRejectionReasons {
    getAllDocumentRejectReason {
      id
      needToInsertCustomText
      language {
        id
        language
        content
      }
    }
  }
`;

export const GET_DOCUMENT_REJECTION_BY_ID = gql`
  query getDocumentRejectionById($id: Float!) {
    getDocumentRejectReasonById(id: $id) {
      id
      needToInsertCustomText
      language {
        id
        language
        content
      }
    }
  }
`;

export const CREATE_DOCUMENT_REJECTION_REASON = gql`
  mutation CreateDocumentRejectionReason(
    $needToInsertCustomText: Boolean!
    $language: [CreateDocumentRejectReasonLanguageDto!]!
  ) {
    createDocumentRejectReason(data: { needToInsertCustomText: $needToInsertCustomText, language: $language })
  }
`;

export const UPDATE_DOCUMENT_REJECTION_REASON = gql`
  mutation UpdateDocumentRejectionReason(
    $needToInsertCustomText: Boolean!
    $language: [UpdateDocumentRejectReasonLanguageDto!]!
    $id: Int!
  ) {
    updateDocumentRejectReason(data: { needToInsertCustomText: $needToInsertCustomText, language: $language, id: $id })
  }
`;

export const CHANGE_DOCUMENT_STATUS = gql`
  mutation ChangeCompanyDocumentStatus($documentId: Int!, $reasonId: Int, $reason: String, $isAccepted: Boolean!) {
    changeCompanyDocumentStatus(
      data: { documentId: $documentId, reasonId: $reasonId, reason: $reason, isAccepted: $isAccepted }
    ) {
      id
      isAccepted
      rejectReason
    }
  }
`;

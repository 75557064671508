import { Loading } from 'components';
import { PasswordInput } from 'components/inputs';
import ShowError from 'components/showError';
import { useToggle } from 'hooks';
import useMutation from 'hooks/useMutation';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Form, FormFeedback, FormGroup, Label } from 'reactstrap';
import { UPDATE_PASSWORD } from 'services/graphql/queries/authentication';
import {
  New,
  password,
  passwordNotEqual,
  repeat,
  reset,
  resetPasswordSubTitle,
  submit,
} from 'tools/i18n/constants/i18n';
import { logout } from 'tools/methods';

const DEFAULT_VALUES = {
  customError: '',
  password: '',
  confirmPassword: '',
};

export default function ResetPassword() {
  const { t } = useTranslation();
  const { tk } = useParams();
  const navigate = useNavigate();
  const [updatePassword, { loading }] = useMutation(UPDATE_PASSWORD);
  const [isLoaded, toggleLoading] = useToggle(true);
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    getValues,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: DEFAULT_VALUES,
  });
  const onSubmit = async (data = DEFAULT_VALUES) => {
    setValue('customError', '');
    if (data.password !== data.confirmPassword) {
      setError('confirmPassword', { message: t(passwordNotEqual) });
    } else {
      try {
        const Response = await updatePassword({ variables: data });
        if (Response?.data?.updatePassword?.message) {
          logout();
          navigate('/');
        } else {
          await Promise.reject(Response);
        }
      } catch (e) {
        const errorBase =
          e?.errors?.graphQLErrors?.[0]?.extensions?.exception?.response ||
          e?.errors?.graphQLErrors?.[0]?.extensions?.response;
        if (errorBase?.status === 401) {
          toast.error('Invalid Token!');
          logout();
          window.history.pushState('', '', '/');
          window.location.reload();
        }
        setValue(
          'customError',
          errorBase?.devMessage ||
            (typeof errorBase?.message === 'string'
              ? errorBase?.message
              : errorBase?.message?.map((s: string) => s).join(', '))
        );
      }

      /* updatePassword({
        variables: data,
      })
        .then((res) => {
          console.log(res);
          if (res?.data?.updatePassword?.message) {
            console.log('*******');
            logout();
            navigate('/');
          } else {
            console.log(res?.errors?.graphQLErrors?.[0]?.extensions);
            setError('customError', res?.errors?.graphQLErrors?.[0]?.extensions?.exception?.response?.devMessage);
          }
        })
        .catch((err: ApolloError) => {
          console.log(err);
          setError('customError', {
            //@ts-ignore
            message: err.graphQLErrors[0].extensions?.response?.message?.[0] || 'Error Happened',
          });
        });*/
    }
  };
  useEffect(() => {
    if (tk) toggleLoading(false);
    else navigate('');
  }, []);
  return (
    <Loading loading={isLoaded}>
      <Col xs="12" md={8} xl={5} className="bg-white mx-auto p-5 rounded">
        {!!getValues('customError') && (
          <div className="mb-2">
            <ShowError>{getValues('customError')}</ShowError>
          </div>
        )}
        <div>
          <div className="d-flex flex-column">
            <h4>{`${t(reset)} ${t(password)}`}</h4>
            <span>{t(resetPasswordSubTitle)}</span>
          </div>
          <Form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label>{`${t(New)} ${t(password)}`}</Label>
              <Controller
                name="password"
                control={control}
                render={({ field: { onChange } }) => {
                  return (
                    <PasswordInput
                      needCheckBox
                      name="password"
                      onChange={(e) => {
                        clearErrors();
                        onChange(e);
                      }}
                    />
                  );
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label>{`${t(repeat)} ${t(password)}`}</Label>
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field: { onChange } }) => {
                  return (
                    <PasswordInput
                      name="confirmPassword"
                      onChange={(e) => {
                        clearErrors();
                        onChange(e);
                      }}
                    />
                  );
                }}
              />
            </FormGroup>
            <FormFeedback>{errors?.confirmPassword?.message || errors?.password?.message}</FormFeedback>
            <Button disabled={loading || !isDirty} className="w-100 mt-3" color="primary">
              {t(submit)}
            </Button>
          </Form>
        </div>
      </Col>
    </Loading>
  );
}

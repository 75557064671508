import React, { ReactNode, useEffect } from 'react';
import useInitializing from 'useInitializing';
import { Loading } from 'components';
import { useGetStore, useToggle } from 'hooks';

export default function Initial({ children }: { children: ReactNode }) {
  useInitializing();
  const isLogged = useGetStore().User.isLogged;
  const [loading, toggleLoading] = useToggle(true);
  useEffect(() => {
    toggleLoading(isLogged === null);
  }, [isLogged]);
  return (
    <Loading loading={loading} isStartPage>
      {children}
    </Loading>
  );
}

import React, { FC, useState } from 'react';
import SectionLayout from 'pages/ads/jobAds/components/layout/sectionLayout';
import { useTranslation } from 'react-i18next';
import { applies, status } from 'tools/i18n/constants/i18n';
import { APPLIES_STATUS, APPLIES_STATUS_TEXT } from 'tools/enums';
import Icon, { IconsName } from 'components/icons';
import { FormProps } from 'pages/ads/jobAds/index';

type AppliesStatusProps = FormProps;

export default function AppliesStatus(props: AppliesStatusProps) {
  const { t } = useTranslation();
  const { onChange } = props;
  const [selected, setSelected] = useState<APPLIES_STATUS>();
  const handleChange = (val: APPLIES_STATUS) => {
    onChange?.('appliesStatus', val);
    setSelected(val);
  };
  return (
    <SectionLayout title={`${t(applies)} ${t(status)}`} icon="FaTruckLoading">
      <div className="d-flex align-items-center flex-wrap justify-content-between">
        <Status
          isSelected={selected === APPLIES_STATUS.GOOD_FIT}
          setSelected={handleChange}
          status={APPLIES_STATUS.GOOD_FIT}
          icon="FaCheck"
        />
        <Status
          isSelected={selected === APPLIES_STATUS.NOT_FIT}
          setSelected={handleChange}
          status={APPLIES_STATUS.NOT_FIT}
          icon="FaExclamation"
        />
        <Status
          isSelected={selected === APPLIES_STATUS.PENDING}
          setSelected={handleChange}
          status={APPLIES_STATUS.PENDING}
          icon="FaCircleNotch"
        />
        <Status
          isSelected={selected === APPLIES_STATUS.HIRED}
          setSelected={handleChange}
          status={APPLIES_STATUS.HIRED}
          icon="FaUserCheck"
        />
      </div>
    </SectionLayout>
  );
}

interface StatusProps {
  status: APPLIES_STATUS;
  icon: IconsName;
  setSelected: (status: APPLIES_STATUS) => void;
  isSelected: boolean;
}
const Status: FC<StatusProps> = (props) => {
  const { status, icon, setSelected, isSelected } = props;
  return (
    <div
      onClick={() => setSelected(status)}
      className={` d-flex align-items-center rounded-pill p-2 ${
        !isSelected ? 'shadow-bg-notActive' : 'shadow-bg-active'
      }`}
    >
      <Icon Name={icon} />
      <span className="ml-1">{APPLIES_STATUS_TEXT[status]}</span>
    </div>
  );
};

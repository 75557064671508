import { areYouSure, level, no, section, student, yes } from 'tools/i18n/constants/i18n';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, CardHeader, Label } from 'reactstrap';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Loading } from 'components';
import useStudentDetail from 'pages/schoolDashboard/student-management/useStudentDetail';
import Logo from 'assets/images/buluro/dashboard/default.svg';
import Icon, { IconsName } from 'components/icons';
import { FormCheck } from 'react-bootstrap';
import Accordion from 'components/accordion';
import useMutation from 'hooks/useMutation';
import { UPDATE_STUDENT_SEARCHABLE, UPDATE_STUDENT_STATUS } from 'services/graphql/queries/studentManagement';
import { APP_HELPER, EDUCATION_GRADE, STUDENT_ACCEPTATION_STATUS } from 'tools/enums';
import { useParams } from 'react-router-dom';
import SweetAlert from 'sweetalert2';
import { concatSocialLink, isTrophy, toDate, toUpperCase } from 'tools/methods';
import { detailType } from 'tools/types/sectionTypes/student';
import { useGetStore } from '../../../hooks';

export default function StudentDetail() {
  const { t } = useTranslation();
  const { data, loading } = useStudentDetail();
  const schoolInformation = useGetStore()?.User?.userInfo?.schoolInformation;
  const studentData = data?.studentData;
  const [studentStatus, setStudentStatus] = useState<STUDENT_ACCEPTATION_STATUS>(studentData?.status);
  const [isSearchable, setIsSearchable] = useState<boolean>(studentData?.isSearchable);
  useEffect(() => {
    if (data) {
      setStudentStatus(studentData?.status);
      setIsSearchable(studentData.isSearchable);
    }
  }, [data]);
  console.log(data);

  return (
    <Loading loading={loading}>
      <Card className="bg-transparent border-0 shadow-0">
        <CardHeader className="bg-transparent pt-0">
          <div className="d-flex align-items-center">
            <img
              width="80px"
              height="80px"
              className="rounded-circle"
              alt={`${data?.firstName || ''} ${data?.lastName || ''}`}
              src={data?.profilePhoto || Logo}
            />
            <div className="flex-column ml-3">
              <div className="make-center">
                <div className="h6 m-0">{`${data?.firstName || ''} ${data?.lastName || ''}`}</div>
                <span className="ml-2">
                  <div
                    className={`${
                      studentStatus === 'PENDING'
                        ? 'shadow-bg-active'
                        : studentStatus === 'ACCEPTED'
                        ? 'shadow-bg-success'
                        : studentStatus === 'REJECTED'
                        ? 'shadow-bg-danger'
                        : 'shadow-bg-warning'
                    } px-2 py-1 rounded`}
                  >
                    {studentStatus}
                  </div>
                </span>
              </div>
              <span>{`${t(schoolInformation.grade === EDUCATION_GRADE.UNIVERSITY ? section : level)}: ${
                studentStatus === STUDENT_ACCEPTATION_STATUS.END_STUDENT
                  ? 'Graduated'
                  : studentData?.level || data?.educationStatus?.educationSection?.originalName || ''
              }`}</span>
            </div>
          </div>
        </CardHeader>
        <CardBody className="bg-transparent py-0">
          <StudentVerificationStatus setStudentStatus={setStudentStatus} defaultStatus={studentStatus} />
          {studentStatus !== STUDENT_ACCEPTATION_STATUS.PENDING && (
            <StudentSearchableStatus
              isActive={studentStatus === STUDENT_ACCEPTATION_STATUS.ACCEPTED}
              isSearchable={isSearchable}
              setStudentStatus={setIsSearchable}
            />
          )}

          <StudentProfile
            hasRequirements={data?.hasRequirements}
            data={{
              bio: data?.bio,
              address: data?.address,
              certificates: data?.certificates,
              workExperience: data?.workExperiences,
              speciality: data?.speciality,
              softwares: data?.softwares,
              socialMedia: data?.socialMedias,
              personalInformation: {
                militaryStatus: data?.militaryStatus,
                gender: data?.gender,
                birthdate: data?.birthdate,
                drivingLicense: data?.drivingLicense,
                maritalStatus: data?.maritalStatus,
                nationality: data?.nationality,
              },
              language: data?.languages,
              educationStatus: data?.educationStatus,
              dontHaveAWorkExperience: data?.dontHaveAWorkExperience,
              dontHaveASoftware: data?.dontHaveASoftware,
              dontHaveACertificate: data?.dontHaveACertificate,
            }}
          />
        </CardBody>
      </Card>
    </Loading>
  );
}

const styles = {
  maxHeight: '60px',
  minHeight: '60px',
};

interface StudentVerificationStatusProps {
  defaultStatus?: STUDENT_ACCEPTATION_STATUS;
  setStudentStatus: (status: STUDENT_ACCEPTATION_STATUS) => any;
}

const StudentVerificationStatus: FC<StudentVerificationStatusProps> = (props) => {
  const { defaultStatus = STUDENT_ACCEPTATION_STATUS.END_STUDENT, setStudentStatus } = props;
  const [execute, { loading }] = useMutation(UPDATE_STUDENT_STATUS);
  const { t } = useTranslation();
  const { type } = useParams();
  const onChange = (status: STUDENT_ACCEPTATION_STATUS) => {
    const data = {
      studentId: type as string,
      status,
    };
    SweetAlert.fire({
      title: t(areYouSure),
      showCancelButton: true,
      icon: 'question',
      confirmButtonText: t(yes),
      cancelButtonText: t(no),
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        execute({
          variables: data,
        })
          .then((res) => {
            console.log(res);
            if (res.data?.EmploymentUpdateMyStudentAcceptation) {
              setStudentStatus(status);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        SweetAlert.close();
      }
    });
  };

  const RenderButtons: Record<STUDENT_ACCEPTATION_STATUS, ReactNode> = {
    [STUDENT_ACCEPTATION_STATUS.PENDING]: (
      <>
        <Button
          disabled={loading}
          aria-disabled={loading}
          onClick={() => onChange(STUDENT_ACCEPTATION_STATUS.ACCEPTED)}
          color=""
          className="border rounded mr-2"
        >
          Verify
        </Button>
        <Button
          disabled={loading}
          aria-disabled={loading}
          onClick={() => onChange(STUDENT_ACCEPTATION_STATUS.REJECTED)}
          color=""
          className="border rounded"
        >
          Reject
        </Button>
      </>
    ),
    [STUDENT_ACCEPTATION_STATUS.REJECTED]: (
      <>
        <Button
          disabled={loading}
          onClick={() => onChange(STUDENT_ACCEPTATION_STATUS.ACCEPTED)}
          color=""
          className="border rounded mr-2"
        >
          Verify
        </Button>
        <span className="user-unselect p-2 shadow-bg-danger rounded">Rejected</span>
      </>
    ),
    [STUDENT_ACCEPTATION_STATUS.ACCEPTED]: (
      <>
        <Button
          onClick={() => onChange(STUDENT_ACCEPTATION_STATUS.REJECTED)}
          color=""
          disabled={loading}
          className="border rounded"
        >
          Reject
        </Button>
        <span className="user-unselect shadow-bg-success p-2 rounded ml-2">Verified</span>
      </>
    ),
    [STUDENT_ACCEPTATION_STATUS.END_STUDENT]: <>...</>,
  };

  return (
    <div className="bg-white d-flex align-items-center rounded px-3 py-2 mb-2" style={styles}>
      <div className="d-flex align-items-center justify-content-between flex-grow-1">
        <div className="d-flex align-items-center">
          <Icon Name="FaRegUserCircle" />
          <div className="h6 mb-0 ml-2">Student Verification Status</div>
        </div>
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center">{RenderButtons[defaultStatus]}</div>
        </div>
      </div>
    </div>
  );
};

interface StudentSearchableStatusProps {
  isSearchable?: boolean;
  isActive: boolean;
  setStudentStatus: (status: boolean) => any;
}

const StudentSearchableStatus: FC<StudentSearchableStatusProps> = ({ isSearchable, setStudentStatus, isActive }) => {
  const [execute, { loading }] = useMutation(UPDATE_STUDENT_SEARCHABLE);
  const { t } = useTranslation();
  const { type } = useParams();
  console.log(isActive);
  const onChange = () => {
    if (isActive) {
      SweetAlert.fire({
        title: t(areYouSure),
        showCancelButton: true,
        icon: 'question',
        confirmButtonText: t(yes),
        cancelButtonText: t(no),
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          execute({
            variables: {
              studentId: type as string,
              isSearchable: !isSearchable,
            },
          })
            .then((res) => {
              console.log(res);
              if (res.data.EmploymentUpdateMyStudentSearchable) {
                // @ts-ignore
                setStudentStatus((p) => !p);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          SweetAlert.close();
        }
      });
    }
  };
  return (
    <div className="bg-white rounded d-flex align-items-center px-3 py-2 mb-2" style={styles}>
      <div className="d-flex align-items-center justify-content-between flex-grow-1">
        <div className="d-flex align-items-center">
          <Icon Name="AiOutlineUserSwitch" />
          <div className="h6 mb-0 ml-2">Student Searchable Status</div>
        </div>
        <div
          aria-disabled={!isActive}
          onClick={onChange}
          className="have-cover d-flex align-items-center"
          role="button"
        >
          <Label htmlFor="searchable" className="mr-2 mb-0">
            This Student is Searchable
          </Label>
          <FormCheck
            readOnly
            aria-readonly
            disabled={!isActive || loading}
            aria-disabled={!isActive || loading}
            id="searchable"
            defaultChecked={isSearchable}
            checked={isSearchable}
          />
        </div>
      </div>
    </div>
  );
};
interface ProfileProps {
  bio: detailType['bio'];
  speciality: detailType['speciality'];
  workExperience: detailType['workExperiences'];
  address: detailType['address'];
  educationStatus: detailType['educationStatus'];
  personalInformation: {
    gender: detailType['gender'];
    birthdate: detailType['birthdate'];
    militaryStatus: detailType['militaryStatus'];
    nationality: detailType['nationality'];
    drivingLicense: detailType['drivingLicense'];
    maritalStatus: detailType['maritalStatus'];
  };
  language: detailType['languages'];
  softwares: detailType['softwares'];
  socialMedia: detailType['socialMedias'];
  certificates: detailType['certificates'];
  dontHaveAWorkExperience: detailType['dontHaveAWorkExperience'];
  dontHaveASoftware: detailType['dontHaveASoftware'];
  dontHaveACertificate: detailType['dontHaveACertificate'];
}
interface StudentProfileProps {
  hasRequirements?: boolean;
  data: ProfileProps;
}

const StudentProfile: FC<StudentProfileProps> = (props) => {
  const { hasRequirements } = props;
  const { t } = useTranslation();
  const Title = () => {
    return (
      <div className="d-flex align-items-center">
        <Icon Name="FaRegUser" />
        <div className="h6 mb-0 ml-2">{`${t(student)} Profile`}</div>
        <div className="ml-3">
          <div
            className={`d-flex align-items-center p-2 border rounded-md ${
              hasRequirements ? 'shadow-text-success' : 'shadow-text-warning'
            }`}
          >
            <Icon Name={hasRequirements ? 'FaCheck' : 'FaExclamation'} />
            <span className="ml-2">{`Profile is ${hasRequirements ? '' : 'Not'} Completed`}</span>
          </div>
        </div>
      </div>
    );
  };
  return <Accordion items={[{ title: <Title />, element: <Profile {...props.data} />, expanded: true }]} />;
};

const Profile: FC<ProfileProps> = (props) => {
  const {
    bio,
    certificates,
    educationStatus,
    language,
    personalInformation,
    socialMedia,
    softwares,
    speciality,
    workExperience,
    address,
    dontHaveAWorkExperience,
    dontHaveASoftware,
    dontHaveACertificate,
  } = props;
  console.log('softwares : ', softwares);
  return (
    <div className="d-flex flex-column px-4 pb-4">
      <div className="d-flex flex-column mb-3">
        <div className="d-flex mb-2">
          <Icon Name="FaRegUser" />
          <span className="ml-2">Bio</span>
        </div>
        <div className="ml-4">
          <p className="m-0">{bio || APP_HELPER.emptyDescription}</p>
        </div>
      </div>

      <div className="d-flex flex-column mb-3">
        <div className="d-flex mb-2">
          <Icon Name="FiGrid" />
          <span className="ml-2">Speciality</span>
        </div>
        <div className="ml-4">
          {isTrophy(speciality) ? (
            <div className="d-flex flex-column">
              {speciality.map((special) => {
                return (
                  <span key={special.branch._id} className="mb-1">
                    {`${special.branch.originalName}${
                      special?.section?.originalName ? `, ${special?.section?.originalName}` : ''
                    }`}
                  </span>
                );
              })}
            </div>
          ) : (
            APP_HELPER.emptyDescription
          )}
        </div>
      </div>

      <div className="d-flex flex-column mb-3">
        <div className="d-flex mb-2">
          <Icon Name="FiGrid" />
          <span className="ml-2">Work Experience</span>
        </div>
        <div className="ml-4">
          <div className="d-flex flex-column">
            {dontHaveAWorkExperience ? (
              `Doesn't Have Experiences`
            ) : isTrophy(workExperience) ? (
              <div className="d-flex flex-column">
                {workExperience.map((experience) => {
                  return (
                    <span key={experience._id}>
                      {`${
                        experience?.company?.companyName || experience?.companyName || APP_HELPER.emptyDescription
                      } , ${experience.speciality?.branch?.originalName || APP_HELPER.emptyDescription}${
                        experience.speciality?.section?.originalName
                          ? `, ${experience.speciality?.section?.originalName || APP_HELPER.emptyDescription}`
                          : ''
                      }, ${experience?.startAt} - ${experience?.endAt || APP_HELPER.emptyDescription}`}
                    </span>
                  );
                })}
              </div>
            ) : (
              APP_HELPER.emptyDescription
            )}
          </div>
        </div>
      </div>

      <div className="d-flex flex-column mb-3">
        <div className="d-flex mb-2">
          <Icon Name="FiMapPin" />
          <span className="ml-2">Address</span>
        </div>
        <div className="ml-4">
          <p className="m-0">
            {address ? (
              <>{`${address?.city || APP_HELPER.emptyDescription}, ${address.county || APP_HELPER.emptyDescription}, ${
                address?.fullAddress
              }, ${address?.postalCode || APP_HELPER.emptyDescription}`}</>
            ) : (
              APP_HELPER.emptyDescription
            )}
          </p>
        </div>
      </div>

      <div className="d-flex flex-column mb-3">
        <div className="d-flex mb-2">
          <Icon Name="FiBook" />
          <span className="ml-2">Education status</span>
        </div>
        <div className="ml-4">
          <p className="m-0">
            {educationStatus ? (
              <>
                {`${educationStatus?.educationPlaceName || APP_HELPER.emptyDescription}, ${
                  educationStatus?.educationSection?.originalName || APP_HELPER.emptyDescription
                } ${educationStatus?.educationSchool?.name || APP_HELPER.emptyDescription}, ${
                  educationStatus?.startAt || APP_HELPER.emptyDescription
                } ${educationStatus?.endAt || APP_HELPER.emptyDescription}`}
              </>
            ) : (
              APP_HELPER.emptyDescription
            )}
          </p>
        </div>
      </div>

      <div className="d-flex flex-column mb-3">
        <div className="d-flex mb-2">
          <Icon Name="FiUsers" />
          <span className="ml-2">Personal Information</span>
        </div>
        <div className="ml-4">
          <div className="d-flex flex-column">
            <div className="d-flex mb-2">
              <span>Birthday:</span>
              <span className="ml-2">
                {toDate(personalInformation?.birthdate as string, false) || APP_HELPER.emptyDescription}
              </span>
            </div>
            <div className="d-flex mb-2">
              <span>Gender:</span>
              <span className="ml-2">{toUpperCase(personalInformation?.gender) || APP_HELPER.emptyDescription}</span>
            </div>
            <div className="d-flex mb-2">
              <span>Military Status:</span>
              <span className="ml-2">
                {toUpperCase(personalInformation?.militaryStatus?.replace('_', ' ')) || APP_HELPER.emptyDescription}
              </span>
            </div>
            <div className="d-flex mb-2">
              <span>Marital Status:</span>
              <span className="ml-2">
                {toUpperCase(personalInformation?.maritalStatus as string) || APP_HELPER.emptyDescription}
              </span>
            </div>
            <div className="d-flex mb-2">
              <span>Nationality:</span>
              <span className="ml-2">{personalInformation?.nationality || APP_HELPER.emptyDescription}</span>
            </div>
            <div className="d-flex mb-2">
              <span>Driver License:</span>
              <span className="ml-2">{personalInformation?.drivingLicense ? 'Have Licence' : `Doesn't Have  `}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column mb-3">
        <div className="d-flex mb-2">
          <Icon Name="FaLanguage" />
          <span className="ml-2">Language</span>
        </div>
        <div className="ml-4">
          <p className="m-0">
            {isTrophy(language) ? (
              <>
                {language.map((lang) => (
                  <span className="ml-2" key={lang.language.name}>
                    {`${lang?.language?.name || APP_HELPER.emptyDescription} - ${
                      lang?.level || APP_HELPER.emptyDescription
                    }`}{' '}
                  </span>
                ))}
              </>
            ) : (
              APP_HELPER.emptyDescription
            )}
          </p>
        </div>
      </div>

      <div className="d-flex flex-column mb-3">
        <div className="d-flex mb-2">
          <Icon Name="FaLaptop" />
          <span className="ml-2">Software</span>
        </div>
        <div className="ml-4">
          <p className="m-0">
            {dontHaveASoftware ? (
              `Doesn't Know any Software`
            ) : isTrophy(softwares) ? (
              <>{softwares.map((software) => software.name).join(', ')}</>
            ) : (
              APP_HELPER.emptyDescription
            )}
          </p>
        </div>
      </div>

      <div className="d-flex flex-column mb-3">
        <div className="d-flex mb-2">
          <Icon Name="FiFacebook" />
          <span className="ml-2">Social Network</span>
        </div>
        <div className="ml-4">
          <div className="d-flex">
            {isTrophy(socialMedia) ? (
              <>
                {socialMedia.map((social) => {
                  const iconName = `Fa${toUpperCase(social.name as string)}` as IconsName;
                  const socialLink = concatSocialLink(social.name as string, social.url as string);
                  return (
                    <div key={social.name} className="ml-4">
                      <a href={socialLink} target="_blank" rel="noreferrer">
                        <Icon Name={iconName} size="25px" />
                      </a>
                    </div>
                  );
                })}
              </>
            ) : (
              APP_HELPER.emptyDescription
            )}
          </div>
        </div>
      </div>

      <div className="d-flex flex-column">
        <div className="d-flex mb-2">
          <Icon Name="AiOutlineSafetyCertificate" />
          <span className="ml-2">Certificates</span>
        </div>
        <div className="ml-4">
          <p className="m-0">
            {dontHaveACertificate
              ? `Doesn't Have`
              : isTrophy(certificates)
              ? certificates.join(', ')
              : APP_HELPER.emptyDescription}
          </p>
        </div>
      </div>
    </div>
  );
};

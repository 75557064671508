import Styled from 'styled-components';

export const TableCellContainer = Styled.div`
  min-width : 330px;
`;

export const OnlineStatusBadge = Styled.span`
  width: 10px;
  height : 10px;
  border-radius : 100%;
`;

export const ProfileImage = Styled<any>('div')`
  
  position: relative;
  width: 64px;
  height: 64px;
  border-radius : 100%;
  display: flex;
  align-items:center;
  justify-content: center;
  &:before {
    position : absolute;
    content : attr(data-percent);
    bottom : -16px;
    left : 27%;
    width : 30px;
    height : 30px;
    border-radius : 100%;
    border : 3px solid #fff;
    background-color : #ffffff;
    color : #000;
    text-align : center;
    white-space : nowrap;
    font-size: 10px;
    padding-top : 5px;
  }
`;

export const StyledBanReasonList = Styled.ul`
    list-style: none;
    padding: 1rem;
`;

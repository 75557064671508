import React from 'react';

export const BlueTickIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.9584 2.04258C9.5334 1.55091 10.4751 1.55091 11.0584 2.04258L12.3751 3.17591C12.6251 3.39258 13.0917 3.56758 13.4251 3.56758H14.8417C15.7251 3.56758 16.4501 4.29258 16.4501 5.17591V6.59258C16.4501 6.91758 16.6251 7.39258 16.8417 7.64258L17.9751 8.95925C18.4667 9.53425 18.4667 10.4759 17.9751 11.0592L16.8417 12.3759C16.6251 12.6259 16.4501 13.0926 16.4501 13.4259V14.8426C16.4501 15.7259 15.7251 16.4509 14.8417 16.4509H13.4251C13.1001 16.4509 12.6251 16.6259 12.3751 16.8426L11.0584 17.9759C10.4834 18.4676 9.54173 18.4676 8.9584 17.9759L7.64173 16.8426C7.39173 16.6259 6.92506 16.4509 6.59173 16.4509H5.15006C4.26673 16.4509 3.54173 15.7259 3.54173 14.8426V13.4176C3.54173 13.0926 3.36673 12.6259 3.1584 12.3759L2.0334 11.0509C1.55007 10.4759 1.55007 9.54258 2.0334 8.96758L3.1584 7.64258C3.36673 7.39258 3.54173 6.92591 3.54173 6.60091V5.16758C3.54173 4.28424 4.26673 3.55924 5.15006 3.55924H6.59173C6.91673 3.55924 7.39173 3.38424 7.64173 3.16758L8.9584 2.04258Z"
      fill="#1F71B8"
    />
    <path
      d="M8.99212 12.6421C8.82546 12.6421 8.66712 12.5754 8.55046 12.4587L6.53379 10.4421C6.29212 10.2004 6.29212 9.80039 6.53379 9.55872C6.77546 9.31706 7.17546 9.31706 7.41712 9.55872L8.99212 11.1337L12.5755 7.55039C12.8171 7.30872 13.2171 7.30872 13.4588 7.55039C13.7005 7.79206 13.7005 8.19206 13.4588 8.43372L9.43379 12.4587C9.31712 12.5754 9.15879 12.6421 8.99212 12.6421Z"
      fill="white"
    />
  </svg>
);

import { useParams } from 'react-router-dom';
import useQuery from 'hooks/useQuery';
import { GET_SCHOOL } from 'services/graphql/queries/school';

export default function useSchoolDetail() {
  const { type } = useParams();
  const { data, loading, refetch } = useQuery(GET_SCHOOL, {
    variables: {
      id: type as string,
    },
  });
  return { data: data?.EmploymentSchoolDetails, loading, refetch };
}

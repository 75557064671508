import { dataToTree } from 'components/tree-select-checkbox/utils';
import { useMutation, useQuery } from 'hooks';
import { t_companyCategory } from 'pages/content-management/company/companyCategories/types';
import { useMemo } from 'react';
import { ADD_OR_UPDATE_SPECIALITIES } from 'services/graphql/queries/companies/createCompany.graphql';
import { GET_COMPANY_CATEGORIES_TREE } from 'services/graphql/queries/companyCategories';
import { APP_HELPER } from 'tools/enums';
import { toArray } from 'tools/methods';
import { customError } from 'tools/types';
import { t_company } from 'tools/types/sectionTypes/company';

export type t_defaultValues = Record<'specialities', t_companyCategory['_id'][]> & customError;

function findSubCategoryId(item: t_company['specialities'][0]) {
  if (item.subCategories?.length) {
    if (item.subCategories[0]?.subCategories?.length) return findSubCategoryId(item.subCategories?.[0]);
    return item.subCategories?.[0]?._id;
  }
  return item._id;
}

export default function useCompanySpecialities(isActive: boolean, componentsValues: t_company | undefined) {
  const [execute] = useMutation(ADD_OR_UPDATE_SPECIALITIES);
  const DEFAULT_VALUES: t_defaultValues = useMemo(() => {
    return { specialities: toArray(componentsValues?.specialities?.map((s) => findSubCategoryId(s))) };
  }, [componentsValues]);
  const { data, loading, error, refetch } = useQuery<{ employmentEmployerCategoryList: t_companyCategory[] }>(
    GET_COMPANY_CATEGORIES_TREE,
    {
      skip: !isActive,
      variables: {
        page: APP_HELPER.treeDefaultPage,
        size: APP_HELPER.treeMaxSize,
      },
    }
  );

  const realData = dataToTree(toArray(data?.employmentEmployerCategoryList), {
    id: '_id',
    childKey: 'subCategories',
    iconKey: 'imageUrl',
    labelKey: 'originalName',
  });

  return { data: realData, loading, error, DEFAULT_VALUES, refetch, execute };
}

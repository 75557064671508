import { gql } from '@apollo/client';

export const GET_STUDENT_LIST = gql`
  query getStudentList($page: Int, $size: Int, $searchable: [Boolean!], $statuses: [StudentAcceptationStatusEnum!]) {
    EmploymentSchoolStudentList(
      mySchoolStudentListDto: { pagination: { page: $page, size: $size }, statuses: $statuses, searchable: $searchable }
    ) {
      count
      data {
        _id
        firstName
        lastName
        level
        status
        isSearchable
        section {
          _id
          originalName
          translations {
            language
            translate
          }
        }
      }
    }
  }
`;

export const GET_STUDENT_DETAIL = gql`
  query getStudentDetail($studentId: String!) {
    EmploymentSchoolStudentDetails(getMyStudentDetailsDto: { studentId: $studentId }) {
      createdAt
      updatedAt
      _id
      userId
      bio
      firstName
      lastName
      profilePhoto
      address {
        title
        city
        county
        postalCode
        fullAddress
      }
      educationStatus {
        grade
        educationPlaceName
        educationSchool {
          createdAt
          updatedAt
          _id
          name
          logo
          city
          county
          postalCode
          fullAddress
          grade
          websiteUrl
          bio
          contactPhoneCode
          contactPhoneNumber
          contactEmail
          uniqueCode
          studentCountCapacity
          isRegistered
          isVisible
          isActive
        }
        educationSection {
          createdAt
          updatedAt
          _id
          originalName
          translations {
            language
            translate
          }
        }
        isLeaved
        dontHaveAnEducation
        level
        startAt
        endAt
      }
      speciality {
        createdAt
        updatedAt
        branch {
          createdAt
          updatedAt
          _id
          originalName
          imageUrl
          translations {
            language
            translate
          }
        }
        section {
          createdAt
          updatedAt
          originalName
          translations {
            language
            translate
          }
          branchId
        }
      }
      dontHaveAWorkExperience
      workExperiences {
        createdAt
        updatedAt
        _id
        speciality {
          createdAt
          updatedAt
          branch {
            createdAt
            updatedAt
            originalName
            imageUrl
            translations {
              language
              translate
            }
          }
          section {
            createdAt
            updatedAt
            originalName
            translations {
              language
              translate
            }
            branchId
          }
        }
        companyName
        company {
          createdAt
          updatedAt
          _id
          companyId
          companyName
          logoUrl
          blueTick
        }
        startAt
        endAt
        description
        status
        rejectReasonCustomText
        referralName
        referralPhone
        changeStatusDate
        rejectReason {
          _id
          originalText
          translations {
            language
            translate
          }
        }
      }
      drivingLicense
      studentData {
        createdAt
        updatedAt
        school {
          _id
          name
          logo
          city
          county
          postalCode
          fullAddress
          grade
          websiteUrl
          bio
          contactPhoneCode
          contactPhoneNumber
          contactEmail
          uniqueCode
          studentCountCapacity
          isRegistered
          isVisible
          isActive
        }
        startYear
        endYear
        level
        isSearchable
        status
      }
      hasRequirements
      birthdate
      nationality
      certificates
      dontHaveACertificate
      socialMedias {
        createdAt
        updatedAt
        name
        url
      }
      softwares {
        createdAt
        updatedAt
        _id
        name
      }
      dontHaveASoftware
      maritalStatus
      languages {
        createdAt
        updatedAt
        level
        language {
          createdAt
          updatedAt
          _id
          name
        }
      }
      gender
      militaryStatus
    }
  }
`;

export const UPDATE_STUDENT_SEARCHABLE = gql`
  mutation updateSearchable($studentId: String!, $isSearchable: Boolean!) {
    EmploymentUpdateMyStudentSearchable(
      updateMyStudentSearchableDto: { studentId: $studentId, isSearchable: $isSearchable }
    )
  }
`;

export const UPDATE_STUDENT_STATUS = gql`
  mutation updateAccept($studentId: String!, $status: StudentAcceptationStatusEnum!) {
    EmploymentUpdateMyStudentAcceptation(updateMyStudentAcceptationDto: { studentId: $studentId, status: $status })
  }
`;

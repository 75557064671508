import { useQuery } from 'hooks';
import { GET_ALL_SPECIAL_REGIONS_DETAIL } from 'services/graphql/queries/emlak/specialRegions';
import { t_specialRegionDetail } from 'pages/content-management/emlak/specialLocation/types';

export default function useSpecialLocationDetail(id: number) {
  const { data, loading, error, refetch, fetchMore } = useQuery(GET_ALL_SPECIAL_REGIONS_DETAIL, {
    variables: { id },
  });
  // const realData = useMemo(() => {
  //   const TData = data?.realEstateFindSpecialRegionDetails || [];
  //   d_getTreeData(TData);
  //   return TData;
  // }, [data?.realEstateFindSpecialRegionDetails]);
  return {
    data: data?.realEstateFindSpecialRegionDetails as t_specialRegionDetail,
    error,
    refetch,
    fetchMore,
    loading,
  };
}

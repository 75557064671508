import { DocumentNode } from 'graphql/language';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { WebsiteInput } from '../../../../components/inputs';
import PayloadSelector from '../components/payloadSelector';
import { ACIONS_TYPE_OPTION, ACTION_DETAILS_LIST, LOCATION_ACTION_DETAILS_LIST } from '../entity';
import { ImageContainer } from '../styles';
import {
  SliderActionsEnum,
  actionTypeEnum,
  childrenType,
  sliderLocationEnum,
  sliderUserTypeEnum,
  t_actionDetailList,
  t_newSlider,
  t_updateSlider,
} from '../types';
import Icon from '../../../../components/icons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import FetchApi from 'services/api/axios';
import { IoIosClose } from 'react-icons/io';
import { ErrorMessage } from '@hookform/error-message';
import UploadSlideImage from './uploadImage';
import sweetalert2 from 'sweetalert2';

interface IProps {
  DEFAULT_VALUES: t_newSlider & t_updateSlider;
  control: any;
  setError: any;
  clearErrors: any;
  getValues: any;
  setValue: any;
  watch: any;
  setFiles: any;
  files: any;
  errors: any;
  childrenData: childrenType[] | [];
  enabelSort: boolean;
  setChildren: (children: childrenType[]) => void;
  setEnabelSort: (val: boolean) => void;
}

export default function SliderChildrenContent({
  DEFAULT_VALUES,
  control,
  setError,
  clearErrors,
  getValues,
  setValue,
  watch,
  setFiles,
  files,
  errors,
  childrenData,
  enabelSort,
  setChildren,
  setEnabelSort,
}: IProps) {
  const [uploadLoading, setUploadLoading] = useState(false);

  const ACTION_LIST_OPTION_BASED_USER_TYPE = useMemo(() => {
    return ACTION_DETAILS_LIST.filter((opt) => opt.is_internal_link)
      .filter((opt) => {
        return (
          opt.is_common ||
          (watch('slot.userType') === sliderUserTypeEnum.EMPLOYER && opt.is_employer) ||
          (watch('slot.userType') === sliderUserTypeEnum.EMPLOYEE && opt.is_employee)
        );
      })
      .map((opt) => ({ label: opt.description, value: opt.enum }));
  }, [watch('slot.userType'), watch('destination.linkType')]);

  const selectedAction = (data: childrenType): Required<t_actionDetailList> | undefined => {
    if (data.linkType === actionTypeEnum.NO_ACTION || data.linkType === actionTypeEnum.EXTERNAL_LINK) {
      return undefined;
    }
    const actionDetail = ACTION_DETAILS_LIST.find((act) => act.enum === data.action);
    if (!!actionDetail?.is_string_payload || actionDetail?.is_integer_payload) {
      return actionDetail as Required<t_actionDetailList>;
    }

    return undefined;
  };
  const findActionDetail = LOCATION_ACTION_DETAILS_LIST.find((item) => item.location === watch('location.location'));

  const handleDeleteSlide = (index: number) => {
    setChildren(
      childrenData
        .filter((item: childrenType) => item?.sortIndex !== index)
        .map((item: childrenType, index2: number) => ({
          ...item,
          sortIndex: index2,
          // action: item.action || undefined,
          // link: item.link || undefined,
          // linkType: item.linkType || undefined,
          // numberPayload: item.numberPayload || undefined,
          // stringPayload: item.stringPayload || undefined,
          // translations: item.translations.map((item) => ({
          //   image: item.image,
          //   language: item.language,
          // })),
        })) as childrenType[]
    );
  };

  const handleShowDelete = (index: number) => {
    sweetalert2
      .fire({
        title: `Are You Sure Delete Slide ${index + 1}`,
        icon: 'warning',
        showConfirmButton: true,
        showCancelButton: true,
        showLoaderOnConfirm: true,
      })
      .then((result) => {
        if (result.value) handleDeleteSlide(index);
      });
  };

  console.log(childrenData);
  console.log(watch('children'));
  console.log(errors);

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <FormGroup switch className="d-flex align-items-center mb-2" style={{ marginLeft: 'auto', width: '100%' }}>
        <Label check className="mr-5 mb-0" role="button" htmlFor="RandomSlider">
          Random Sıralama
        </Label>
        <Controller
          control={control}
          defaultValue={watch('isShuffledChildren')}
          name="isShuffledChildren"
          render={({ field: { onChange } }) => {
            return (
              <Input
                role="button"
                id="RandomSlider"
                className=" mb-1"
                style={{
                  padding: '10px',
                  width: '40px',
                }}
                type="switch"
                size={64}
                defaultChecked={DEFAULT_VALUES?.isShuffledChildren}
                onClick={(val) => onChange(!DEFAULT_VALUES?.isShuffledChildren)}
              />
            );
          }}
        />
      </FormGroup>
      <DragDropContext
        onDragEnd={(event) => {
          const { destination, source } = event;
          if (!destination) return;
          if (destination?.index === source.index) {
            return;
          }
          const recordedState = childrenData;
          const sourceIndex = source.index;
          const destinationIndex = destination.index;
          const [removedItemItem] = recordedState.splice(sourceIndex, 1);
          recordedState.splice(destinationIndex, 0, removedItemItem);

          const result: childrenType[] = recordedState.map((item: childrenType, index: number) => ({
            ...item,
            sortIndex: index,
          }));
          clearErrors('children');
          setChildren(result);
          setValue('children', result);
          return result;
        }}
      >
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              className="border p-4 rounded-4"
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                minHeight: 315,
              }}
            >
              <div className="mb-4 d-flex justify-between">
                <Button
                  className="d-flex align-items-center"
                  color="primary"
                  type="button"
                  disabled={childrenData.length ? false : true}
                  onClick={() => {
                    // if (childrenData.length) return;
                    // clearErrors('children');

                    setChildren([
                      ...childrenData,
                      {
                        // id: (childrenData.length += 1),
                        link: undefined,
                        action: undefined,
                        stringPayload: undefined,
                        numberPayload: undefined,
                        sortIndex: childrenData.length,
                        linkType: undefined,
                        translations: [
                          {
                            image: '',
                            language: 'en',
                            imageUrl: '',
                          },
                          {
                            image: '',
                            language: 'tr',
                            imageUrl: '',
                          },
                        ],
                      },
                    ]);
                  }}
                >
                  <Icon Name="FaPlus" />
                  <span className="ml-2">New Slide</span>
                </Button>

                <Button
                  className="d-flex align-items-center"
                  color={enabelSort ? 'primary' : 'secondary'}
                  onClick={() => {
                    setEnabelSort(!enabelSort);
                  }}
                  type="button"
                >
                  {enabelSort ? 'Disable Sort Mode' : 'Enable Sort Mode'}
                </Button>
              </div>

              {childrenData
                ?.sort((a, b) => a.sortIndex - b.sortIndex)
                .map((slide, index) => (
                  <Draggable key={index} draggableId={slide.sortIndex?.toString()} index={index}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <div
                          className="p-4 rounded-4 mt-4 bg-white"
                          style={{ boxShadow: '0 0 10px 5px #eee', position: 'relative' }}
                        >
                          {childrenData?.length !== 1 ? (
                            <button
                              type="button"
                              onClick={() => {
                                handleShowDelete(slide.sortIndex);
                              }}
                              style={{
                                position: 'absolute',
                                top: -5,
                                right: -5,
                                width: 20,
                                height: 20,
                                borderRadius: 20,
                                border: 'none',
                                background: '#e20000 ',
                                opacity: 0.8,
                              }}
                            >
                              <IoIosClose color="#fff" size={20} />
                            </button>
                          ) : (
                            <></>
                          )}

                          <FormGroup className="d-flex">
                            {enabelSort ? (
                              <div
                                {...provided.dragHandleProps}
                                style={{ padding: 5, marginRight: 10, height: '100%', margin: 'auto' }}
                              >
                                {/* <FaBars size={22} /> */}
                                <Icon Name="AiOutlineFullscreen" />
                              </div>
                            ) : (
                              <></>
                            )}
                            <UploadSlideImage
                              errors={errors}
                              control={control}
                              index={slide.sortIndex}
                              childrenData={childrenData || []}
                              setChildren={(children) => setChildren(children)}
                              setError={setError}
                              slide={slide}
                              watch={watch}
                              language="en"
                              setUploadLoading={(loading: boolean) => setUploadLoading(loading)}
                              uploadLoading={uploadLoading}
                            />

                            <UploadSlideImage
                              errors={errors}
                              control={control}
                              index={slide.sortIndex}
                              childrenData={childrenData || []}
                              setChildren={(children) => setChildren(children)}
                              setError={setError}
                              slide={slide}
                              watch={watch}
                              language="tr"
                              setUploadLoading={(loading: boolean) => setUploadLoading(loading)}
                              uploadLoading={uploadLoading}
                            />
                          </FormGroup>
                          {!enabelSort ? (
                            <FormGroup>
                              <Label className="mb-0" style={{ fontWeight: 'bold', fontSize: '18px' }}>
                                Slide Action
                              </Label>
                              <FormGroup className="mt-3">
                                <Label>Action Type</Label>
                                <Controller
                                  name={`children[${index}].linkType`}
                                  defaultValue={slide?.linkType}
                                  rules={{
                                    required: 'Please Specify Action Type',
                                  }}
                                  control={control}
                                  render={({ field: { onChange } }) => {
                                    return (
                                      <Select
                                        defaultValue={ACIONS_TYPE_OPTION.find((item) => item.value === slide?.linkType)}
                                        options={ACIONS_TYPE_OPTION}
                                        key={slide?.linkType}
                                        // className="z-index-top"
                                        isDisabled={uploadLoading}
                                        onChange={(event) => {
                                          if (event?.value) {
                                            onChange(event.value);
                                            setChildren(
                                              childrenData.map((item) => {
                                                return item.sortIndex === slide.sortIndex
                                                  ? {
                                                      ...item,
                                                      linkType: event.value,
                                                      action:
                                                        event.value === 'EXTERNAL_LINK'
                                                          ? SliderActionsEnum.EXTERNAL_LINK
                                                          : event.value === 'NO_ACTION'
                                                          ? SliderActionsEnum.NONE
                                                          : '',
                                                    }
                                                  : item;
                                              }) as childrenType[]
                                            );
                                          }
                                        }}
                                      />
                                    );
                                  }}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name={`children[${index}].linkType`}
                                  render={({ message }) => <FormFeedback>{message}</FormFeedback>}
                                />
                              </FormGroup>
                              {slide?.linkType === actionTypeEnum.INTERNAL_LINK ? (
                                <FormGroup className="my-3">
                                  <Label>Action</Label>
                                  <Controller
                                    name={`children[${index}].action`}
                                    defaultValue={slide?.action}
                                    rules={{
                                      required: 'Please Select an Action',
                                    }}
                                    control={control}
                                    render={({ field: { onChange } }) => {
                                      return (
                                        <Select
                                          // className="z-index-top"
                                          options={ACTION_LIST_OPTION_BASED_USER_TYPE}
                                          defaultValue={ACTION_LIST_OPTION_BASED_USER_TYPE.find(
                                            (item: { value: SliderActionsEnum | undefined }) =>
                                              item.value === slide?.action
                                          )}
                                          key={slide?.action}
                                          isDisabled={uploadLoading}
                                          onChange={(event) => {
                                            if (event?.value) {
                                              onChange(event.value);
                                              setChildren(
                                                childrenData.map((item) => {
                                                  return item.sortIndex === slide.sortIndex
                                                    ? {
                                                        ...item,
                                                        action: event.value,
                                                        stringPayload: null,
                                                        numberPayload: null,
                                                      }
                                                    : item;
                                                }) as childrenType[]
                                              );
                                            }
                                          }}
                                        />
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    errors={errors}
                                    name={`children[${index}].action`}
                                    render={({ message }) => <FormFeedback>{message}</FormFeedback>}
                                  />
                                </FormGroup>
                              ) : slide.linkType === actionTypeEnum.EXTERNAL_LINK ? (
                                <FormGroup className="my-3">
                                  <Label aria-required>External Link (Url Should Start With http or https)</Label>
                                  <WebsiteInput
                                    control={control}
                                    // withProtocol
                                    name={`children[${index}].link`}
                                    defaultValue={slide?.link}
                                    key={index}
                                    rules={{
                                      required: 'Please Enter External Link',
                                    }}
                                    disabled={uploadLoading}
                                    placeholder="Enter External Link"
                                    handleChange={(val) => {
                                      if (val)
                                        setChildren(
                                          childrenData.map((item) => {
                                            return item.sortIndex === slide.sortIndex
                                              ? {
                                                  ...item,
                                                  link: val,
                                                }
                                              : item;
                                          }) as childrenType[]
                                        );
                                    }}
                                  />
                                  <ErrorMessage
                                    errors={errors}
                                    name={`children[${index}].link`}
                                    render={({ message }) => <FormFeedback>{message}</FormFeedback>}
                                  />
                                </FormGroup>
                              ) : undefined}

                              {!!selectedAction(slide) && (
                                <FormGroup className="my-3">
                                  <Label>Payload</Label>
                                  <PayloadSelector
                                    showExpireMessage
                                    setError={setError}
                                    key={slide?.stringPayload || slide?.numberPayload}
                                    clearFormError={() => {
                                      clearErrors(
                                        `children[${index}].${
                                          selectedAction(slide)?.is_string_payload ? 'stringPayload' : 'numberPayload'
                                        }`
                                      );
                                    }}
                                    isDisabled={uploadLoading}
                                    clearFormState={() => {
                                      setValue(
                                        `children[${index}].${
                                          selectedAction(slide)?.is_string_payload ? 'stringPayload' : 'numberPayload'
                                        }`,
                                        undefined
                                      );
                                      setChildren(
                                        childrenData.map((item) => {
                                          return item.sortIndex === slide.sortIndex
                                            ? {
                                                ...item,
                                                [selectedAction(slide)?.is_string_payload
                                                  ? 'stringPayload'
                                                  : 'numberPayload']: undefined,
                                              }
                                            : item;
                                        }) as childrenType[]
                                      );
                                    }}
                                    query={selectedAction(slide)?.query as DocumentNode}
                                    control={control}
                                    isStringPayload={selectedAction(slide)?.is_string_payload as boolean}
                                    onDataLoad={selectedAction(slide)?.onDataLoad}
                                    variables={selectedAction(slide)?.vars}
                                    defaultValue={slide?.stringPayload || slide?.numberPayload}
                                    name={
                                      `children[${index}].${
                                        selectedAction(slide)?.is_string_payload ? 'stringPayload' : 'numberPayload'
                                      }` as string
                                    }
                                    handleChange={(value) => {
                                      setChildren(
                                        childrenData.map((item) => {
                                          return item.sortIndex === slide.sortIndex
                                            ? {
                                                ...item,
                                                [selectedAction(slide)?.is_string_payload
                                                  ? 'stringPayload'
                                                  : 'numberPayload']: value,
                                                [selectedAction(slide)?.is_string_payload
                                                  ? 'numberPayload'
                                                  : 'stringPayload']: null,
                                              }
                                            : item;
                                        }) as childrenType[]
                                      );
                                    }}
                                    rules={{
                                      required: 'Please Specify Payload',
                                    }}
                                    removeData={() => {
                                      if (findActionDetail?.is_integer_payload) {
                                        setValue(`children[${index}].stringPayload`, undefined);
                                        setChildren(
                                          childrenData.map((item) => {
                                            return item.sortIndex === slide.sortIndex
                                              ? {
                                                  ...item,
                                                  stringPayload: undefined,
                                                }
                                              : item;
                                          }) as childrenType[]
                                        );
                                      }
                                      if (findActionDetail?.is_string_payload) {
                                        setValue(`children[${index}].numberPayload`, undefined);
                                        setChildren(
                                          childrenData.map((item) => {
                                            return item.sortIndex === slide.sortIndex
                                              ? {
                                                  ...item,
                                                  numberPayload: undefined,
                                                }
                                              : item;
                                          }) as childrenType[]
                                        );
                                      }
                                    }}
                                  />
                                  <ErrorMessage
                                    errors={errors}
                                    name={`children[${index}].stringPayload`}
                                    render={({ message }) => <FormFeedback>{message}</FormFeedback>}
                                  />
                                  <ErrorMessage
                                    errors={errors}
                                    name={`children[${index}].numberPayload`}
                                    render={({ message }) => <FormFeedback>{message}</FormFeedback>}
                                  />
                                  {/* <FormFeedback>
          {errors?.destination?.stringPayload?.message ||
            errors?.destination?.numberPayload?.message}
        </FormFeedback> */}
                                </FormGroup>
                              )}
                            </FormGroup>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <ErrorMessage
        errors={errors}
        name={`children`}
        render={({ message }) => <FormFeedback>{message}</FormFeedback>}
      />
    </div>
  );
}

import { UseFormHandleSubmit } from 'react-hook-form/dist/types/form';
import { Control, Controller, useForm } from 'react-hook-form';
import { t_errors } from 'tools/types';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useMutation from 'hooks/useMutation';
import { ASSIGN_ADMIN, DELETE_ASSIGN_ADMIN } from 'services/graphql/queries/school';
import useDownloader from 'react-use-downloader';
import { useCopy } from 'hooks';
import { toast } from 'react-toastify';
import {
  admin,
  areYouSure,
  assign,
  assigned,
  code,
  copied,
  copy,
  download,
  no,
  qrCode,
  save,
  successfully,
  update,
  yes,
} from 'tools/i18n/constants/i18n';
import SweetAlert from 'sweetalert2';
import { Button, Form, FormGroup, Label } from 'reactstrap';
import AdminDropdown from 'components/dropdown/adminDropDown';
import Icon from 'components/icons';
import { QRCodeCanvas } from 'qrcode.react';
import { t_defaultValues, t_steps, user } from 'pages/school/schoolDetail';

interface AssignAdminProps {
  steps: t_steps;
  handleSubmit: UseFormHandleSubmit<t_defaultValues>;
  onSubmit: (data: t_defaultValues) => void;
  control: Control<t_defaultValues, any>;
  defaultValue?: t_defaultValues;
  errors: t_errors<t_defaultValues>;
}

type t_assignAdminDefaultValues = {
  schoolId: string | undefined | null;
  user: undefined | user;
};

export default function AssignAdmin(props: AssignAdminProps) {
  const { defaultValue, steps } = props;
  const isAssignAdminTurn = steps === 3;
  const { t } = useTranslation();
  const downloadLink = useRef();
  const haveUserByDefault = useRef<boolean>(!!defaultValue?.user?.id);
  const [execute, { loading }] = useMutation(ASSIGN_ADMIN);
  const [customError, setCustomError] = useState('');
  const [deleteExecute, { loading: deleteLoading }] = useMutation(DELETE_ASSIGN_ADMIN);
  const { download: downloader } = useDownloader();
  const { isCopied, copyContent } = useCopy();
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { isDirty },
  } = useForm<t_assignAdminDefaultValues>({
    defaultValues: {
      schoolId: defaultValue?._id,
      user: defaultValue?.user,
    },
  });
  const handleCopy = async (code: string) => {
    await copyContent(code);
  };
  /* const handleDownload = async (url: string, file: File) => {
    await downloader(url, 'qrCode');
  };*/
  const onSubmit = useCallback((data: t_assignAdminDefaultValues) => {
    if (isAssignAdminTurn && data?.user?.id) {
      execute({
        variables: {
          userId: data?.user?.id,
          schoolId: data?.schoolId,
        },
      })
        .then((res) => {
          if (res.data?.assignSchoolAdmin) {
            haveUserByDefault.current = true;
            toast.success(`${t(admin)} ${t(successfully)} ${t(assigned)}`);
          }
        })
        .catch(console.error);
    }
  }, []);
  const handleDeleteAssignAdmin = () => {
    if (getValues('schoolId') && getValues('user')?.id) {
      SweetAlert.fire({
        title: t(areYouSure),
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: t(yes),
        cancelButtonText: t(no),
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          deleteExecute({
            variables: {
              schoolId: defaultValue?._id,
              userId: getValues('user')?.id,
            },
          })
            .then((response) => {
              if (response.data?.removeSchoolAdmin) {
                setValue('user', undefined);
              }
            })
            .catch((err) => {
              console.error(err);
              setCustomError(err.message);
            });
        } else {
          SweetAlert.close();
        }
      });
    }
  };

  useEffect(() => {
    if (defaultValue?.uniqueCode) {
      const canvas = document.getElementById(defaultValue?.uniqueCode as string) as HTMLCanvasElement;
      // @ts-ignore
      downloadLink.current = canvas?.toDataURL('image/png');
    }
  }, [defaultValue]);

  const adminDefaultValues = getValues('user')?.id
    ? {
        ...getValues('user'),
        label: `${getValues('user')?.firstName}  ${getValues('user')?.lastName}`,
        value: getValues('user')?.id as string,
      }
    : undefined;
  return (
    <div className="px-5 py-3">
      <Form aria-disabled={!isAssignAdminTurn} onSubmit={handleSubmit(onSubmit)}>
        <FormGroup aria-disabled={!isAssignAdminTurn} className="rounded bg-gray p-4">
          <Label>{`${t(assign)} ${t(admin)}`}</Label>
          <div className="d-flex align-items-center flex-grow-1">
            <Controller
              control={control}
              name="user"
              defaultValue={adminDefaultValues as user}
              render={({ field: { onChange } }) => {
                return (
                  <AdminDropdown
                    disabled={!isAssignAdminTurn || deleteLoading || loading}
                    className="flex-grow-1"
                    isSchoolAdmin={true}
                    needClearValue={typeof adminDefaultValues === 'undefined'}
                    customError={customError}
                    defaultValue={adminDefaultValues}
                    placeholder={'Search For Admin School'}
                    onSelect={(val) => {
                      onChange(val);
                    }}
                  />
                );
              }}
            />
            {!!(getValues('user')?.id && defaultValue?._id && haveUserByDefault.current) && (
              <button
                type="button"
                onClick={handleDeleteAssignAdmin}
                disabled={deleteLoading}
                role="button"
                className="bg-transparent border-0 ml-2"
              >
                <Icon Name="FiTrash2" />
              </button>
            )}
          </div>
        </FormGroup>
        <FormGroup className="bg-gray rounded p-4">
          <div className="d-flex align-items-center">
            <div className="mr-3 rounded border-primary">
              <QRCodeCanvas
                aria-disabled={!defaultValue?.uniqueCode}
                id={defaultValue?.uniqueCode as string}
                size={130}
                value={defaultValue?.uniqueCode as string}
              />
            </div>
            <div className="d-flex flex-column">
              <Button
                disabled={!defaultValue?.uniqueCode}
                aria-disabled={!defaultValue?.uniqueCode}
                type="button"
                color={isCopied ? 'transparent' : 'primary'}
                onClick={() => handleCopy(defaultValue?.uniqueCode || '')}
                className={'mb-2'}
              >
                {isCopied ? `${t(code)} ${t(copied)}` : `${t(copy)} ${t(code)}`}
              </Button>

              <Button
                disabled={!defaultValue?.uniqueCode}
                aria-disabled={!defaultValue?.uniqueCode}
                type="button"
                onClick={() => {
                  // @ts-ignore
                  downloader(downloadLink.current, `${defaultValue?.uniqueCode}-qrCode.png`).then();
                }}
                color="primary"
                className=" rounded"
              >{`${t(download)} ${t(qrCode)}`}</Button>
            </div>
          </div>
        </FormGroup>
        <Button disabled={loading || !isDirty} color="primary" className="w-100">
          {t(defaultValue ? update : save)}
        </Button>
      </Form>
    </div>
  );
}

import { ApolloError } from '@apollo/client';
import SubmitButton from 'components/buttons/submitButton';
import { EmailInput } from 'components/inputs';
import MultiCode from 'components/inputs/multiCode';
import ShowError from 'components/showError';
import { useToggle } from 'hooks';
import useMutation from 'hooks/useMutation';
import { newComponentsProps } from 'pages/user-management/employer/index';
import React, { FC, memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { Send_OTP, Verify_OTP } from 'services/graphql/queries/companies/createCompany.graphql';
import {
  address,
  code,
  confirm,
  email,
  enter,
  fill,
  fullName,
  inputs,
  name,
  personal,
  personalInformation,
  please,
  requestOtp,
} from 'tools/i18n/constants/i18n';
import { formsNameEnum, steps } from './types';

type CompanyInformationProps = newComponentsProps;

type initialValues = {
  customError?: string;
  email: undefined | string;
  sentCode: undefined | string;
};

const EmailVerification: FC<CompanyInformationProps> = (props) => {
  const {
    goNext,
    isActive,
    handleChangeComponentState,
    handleChangeComponentResponse,
    handleChangeComponentValues,
    componentsValues,
  } = props;
  const { t } = useTranslation();
  const [emailExecute, { loading: emailLoading }] = useMutation(Send_OTP);
  const [otpExecute, { loading: otpLoading }] = useMutation(Verify_OTP);
  const [isSent, toggleSent] = useToggle(false);
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<initialValues>({
    defaultValues: {
      email: componentsValues?.email || undefined,
      sentCode: undefined,
    },
  });
  const onSubmit = (data: initialValues) => {
    setValue('customError', '');
    if (isActive) {
      if (!isSent) {
        emailExecute({
          variables: data,
        })
          .then((res) => {
            if (res.data?.sendOTP) {
              toggleSent(true);
            } else {
              //@ts-ignore
              setValue('customError', res.errors?.message);
            }
          })
          .catch((err) => {
            handleError(err);
            setValue('customError', err?.message);
          });
      } else {
        if (data.sentCode?.length === 6) {
          otpExecute({
            variables: data,
          })
            .then((res) => {
              if (res.data?.verifyOTP) {
                handleChangeComponentValues?.(data);
                handleChangeComponentState(formsNameEnum.EmailVerification, true);
                handleChangeComponentResponse?.({
                  companyId: res.data?.verifyOTP?.companyId || null,
                  userId: res.data?.verifyOTP?.userId || null,
                });
                goNext(steps.two);
              } else {
                //@ts-ignore
                setValue('customError', res.errors?.message);
              }
            })
            .catch((err) => {
              handleError(err);
              setValue('customError', err?.message);
            });
        } else {
          setValue('customError', `${t(please)} ${t(fill)} ${t(inputs)}`);
        }
      }
    }
  };

  const handleError = (err?: ApolloError) => {
    console.error(err);
    setValue('customError', err?.message || 'Error Happened');
  };
  useEffect(() => {
    if (componentsValues?.registrationEmail) {
      handleChangeComponentState(formsNameEnum.EmailVerification, true);
    }
  }, []);
  return (
    <Col xxl={7} className="p-2 pb-4 mx-auto">
      <Form onSubmit={handleSubmit(onSubmit)} aria-disabled={!isActive}>
        <FormGroup>
          <ShowError>{getValues('customError')}</ShowError>
        </FormGroup>

        {!!componentsValues?.fullName && (
          <FormGroup>
            <Label>{t(name)}</Label>
            <Input
              name="fullName"
              value={componentsValues?.fullName || undefined}
              placeholder="Full Name"
              disabled
              aria-disabled
              readOnly
            />
          </FormGroup>
        )}
        <FormGroup>
          <Label>{t(email)}</Label>
          <EmailInput
            name="email"
            defaultValue={componentsValues?.registrationEmail || undefined}
            placeholder="abc@gmail.com"
            control={control}
            disabled={isSent || !isActive}
            aria-disabled={isSent || !isActive}
            readOnly={!isActive}
            required
            requiredErrorText={`${t(please)} ${t(enter)} ${t(email)} ${t(address)}`}
          />
          <FormFeedback>{errors?.email?.message}</FormFeedback>
        </FormGroup>

        {isSent && (
          <FormGroup className="py-4">
            <MultiCode onFinish={(value) => setValue('sentCode', value)} />
          </FormGroup>
        )}
        {isActive && (
          <SubmitButton className="w-100" loading={emailLoading || otpLoading}>
            {isSent ? `${t(confirm)} ${t(code)}` : t(requestOtp)}
          </SubmitButton>
        )}
      </Form>
    </Col>
  );
};
export default memo(EmailVerification);

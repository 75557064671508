import React, { FC, useState } from 'react';
import SectionLayout from 'pages/ads/jobAds/components/layout/sectionLayout';
import { useTranslation } from 'react-i18next';
import { ad, status } from 'tools/i18n/constants/i18n';
import { ADS_STATUS, ADS_STATUS_TEXT } from 'tools/enums';
import Icon, { IconsName } from 'components/icons';
import { FormProps } from 'pages/ads/jobAds/index';

type AdStatusProps = FormProps;

export default function AdStatus(props: AdStatusProps) {
  const { t } = useTranslation();
  const { onChange } = props;
  const [currentActiveStatus, setCurrentActiveStatus] = useState<ADS_STATUS>();
  const handleChange = (val: ADS_STATUS) => {
    setCurrentActiveStatus(val);
    onChange?.('statusesMustBeShown', val);
  };
  return (
    <SectionLayout icon="FiActivity" title={t(`${t(ad)} ${t(status)}`)}>
      <div className="d-flex align-items-center flex-wrap">
        <Status
          dataContent={ADS_STATUS.ACTIVE}
          currentActiveStatus={currentActiveStatus}
          changeActiveStatus={handleChange}
          icon="FiActivity"
        />
        <Status
          dataContent={ADS_STATUS.EXPIRED}
          currentActiveStatus={currentActiveStatus}
          changeActiveStatus={handleChange}
          icon="FaExclamationTriangle"
        />
        <Status
          dataContent={ADS_STATUS.DE_ACTIVE}
          currentActiveStatus={currentActiveStatus}
          changeActiveStatus={handleChange}
          icon="FiX"
        />
        <Status
          dataContent={ADS_STATUS.ADMIN_BAN}
          currentActiveStatus={currentActiveStatus}
          changeActiveStatus={handleChange}
          icon="FaBan"
        />
        <Status
          dataContent={ADS_STATUS.DRAFT}
          currentActiveStatus={currentActiveStatus}
          changeActiveStatus={handleChange}
          icon="FaBan"
        />
      </div>
    </SectionLayout>
  );
}

interface StatusProps {
  currentActiveStatus?: ADS_STATUS;
  changeActiveStatus: (currentActiveStatus: ADS_STATUS) => void;
  dataContent: ADS_STATUS;
  icon: IconsName;
}
const Status: FC<StatusProps> = (props) => {
  const { currentActiveStatus, changeActiveStatus, dataContent, icon } = props;
  return (
    <div
      onClick={() => changeActiveStatus(dataContent)}
      className={`p-2 m-1 d-flex align-items-center rounded-pill ${
        currentActiveStatus === dataContent ? 'shadow-bg-active' : 'shadow-bg-notActive'
      }`}
    >
      <Icon Name={icon} />
      <span className="ml-1">{ADS_STATUS_TEXT[dataContent]}</span>
    </div>
  );
};

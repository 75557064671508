import SubmitButton from 'components/buttons/submitButton';
import ShowError from 'components/showError';
import TreeDataCheckbox from 'components/tree-select-checkbox';
import UploadProfileImage from 'components/uploadProfileImage';
import { useToggle } from 'hooks';
import PagesLayout from 'pages/components/layout';
import { t_bazaarCategory } from 'pages/content-management/bazzar/bazarCategories/type';
import React, { useMemo } from 'react';
import { FormCheck } from 'react-bootstrap';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import FetchApi from 'services/api/axios';
import Styled from 'styled-components';
import { LANGUAGES, LANGUAGES_DROPDOWN } from 'tools/constants';
import { APP_HELPER } from 'tools/enums';
import { Add } from 'tools/i18n/constants';
import {
  bazaarCategories,
  bazaarCategory,
  bazzar,
  category,
  create,
  created,
  enter,
  icon,
  language,
  name,
  parent,
  please,
  productModelIsRequired,
  successfully,
  translation,
  update,
  updated,
} from 'tools/i18n/constants/i18n';
import { errorFinder, goBack } from 'tools/methods';
import useBazaarDetailCategory from './useBazaarDetailCategory';

const StyledIcon = Styled(UploadProfileImage)`
  min-width : 80px;
  max-width : 80px;
`;

export default function BazaarCategoryDetail() {
  const { selectedNode, createExecute, updateExecute, isNew, treeData } = useBazaarDetailCategory();
  const [loading, toggleLoading] = useToggle(false);
  const { t } = useTranslation();
  const DEFAULT_VALUES: t_bazaarCategory = useMemo(() => {
    return {
      id: selectedNode?.id,
      icon: selectedNode?.icon === APP_HELPER.defaultBazaarAndJobIcon ? undefined : selectedNode?.icon,
      title: selectedNode?.title,
      child: selectedNode?.child,
      language: selectedNode?.language?.map((item: any) => ({
        language: item.language,
        translate: item.translation,
      })),
      requireModel: !!selectedNode?.requireModel,
      parentCategory: selectedNode?._parent,
      picture: undefined,
      status: undefined,
    };
  }, [selectedNode]);
  const {
    handleSubmit,
    control,
    setError,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<t_bazaarCategory>({
    defaultValues: DEFAULT_VALUES,
  });

  const handleUploadIcon = (image: File, id: number) => {
    const formData = new FormData();
    formData.append('file', image);
    formData.append('id', id.toString());
    formData.append('isIcon', 'true');
    FetchApi({
      url: 'CONTENT_MANAGEMENT_IMAGE',
      method: 'PATCH',
      data: formData,
    })
      .then((res: any) => {
        if (res.data) {
          toast.success(`${t(successfully)} ${t(isNew ? created : updated)}`);
          goBack();
        }
        toggleLoading(false);
      })
      .catch((err: any) => {
        console.error(err.message);
        setValue('customError', errorFinder(err.message));
        toggleLoading(false);
      });
  };
  const onSubmit: SubmitHandler<t_bazaarCategory> = (data) => {
    console.log(data);
    toggleLoading(true);
    setValue('customError', '');
    createExecute({
      variables: {
        translations: data.language,
        requireModel: data.requireModel,
        parentCategory: data.parentCategory,
      },
    })
      .then((res) => {
        if (res.data.createBazaarCategory) {
          if (data.icon) {
            handleUploadIcon(data.icon as File, res.data.createBazaarCategory.id);
          } else {
            toast.success(`${t(successfully)} ${t(created)}`);
            goBack();
          }
        } else {
          setValue('customError', errorFinder(res?.errors?.[0]?.message));
          toggleLoading(false);
        }
      })
      .catch((err) => {
        setValue('customError', errorFinder(err));
        toggleLoading(false);
      });
  };
  const onUpdate: SubmitHandler<t_bazaarCategory> = (data) => {
    console.log(data);
    setValue('customError', '');
    toggleLoading(true);
    updateExecute({
      variables: {
        parentCategory: data.parentCategory,
        translations: data.language,
        requireModel: data.requireModel,
        categoryId: data.id,
      },
    })
      .then((res) => {
        if (res.data.updateBazaarCategory) {
          if (data.icon && data.icon !== DEFAULT_VALUES.icon) {
            handleUploadIcon(data.icon as File, data.id as number);
          } else {
            toast.success(`${t(successfully)} ${t(created)}`);
            goBack();
          }
        } else {
          setValue('customError', errorFinder(res?.errors?.[0]?.message));
          toggleLoading(false);
        }
      })
      .catch((err) => {
        setValue('customError', errorFinder(err));
        toggleLoading(false);
      });
  };
  const defaultExpandedIds = useMemo(
    () => (DEFAULT_VALUES.parentCategory ? [DEFAULT_VALUES.parentCategory] : undefined),
    [DEFAULT_VALUES]
  );
  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <PagesLayout backLinkTitle={t(bazaarCategories)}>
        <Card>
          <CardHeader className="py-3">
            <div className="d-flex align-items-center justify-content-between">
              <h5>{`${t(isNew ? Add : update)} ${t(bazzar)} ${t(category)}`}</h5>
              {/* <Button color="danger">{t(remove)}</Button> */}
            </div>
          </CardHeader>
          <CardBody>
            <Row className="mx-auto">
              <Col xxl="12" className="mx-auto">
                <Form onSubmit={handleSubmit(isNew ? onSubmit : onUpdate, console.error)}>
                  {!!getValues('customError') && <ShowError>{getValues('customError')}</ShowError>}
                  <div className="bg-gray p-3 d-flex align-items-center">
                    <FormGroup className="mr-5">
                      <Label className="whitespace-nowrap">{`${t(bazaarCategory)} ${t(icon)}`}</Label>
                      <Controller
                        control={control}
                        name="icon"
                        render={({ field: { onChange } }) => {
                          return (
                            <StyledIcon
                              avatarSize="70px"
                              maxSize={512000}
                              defaultImage={DEFAULT_VALUES.icon as string}
                              onSelect={(file: File) => {
                                onChange(file);
                              }}
                              onError={(e) => setError('icon', { message: e })}
                            />
                          );
                        }}
                      />
                      <FormFeedback>{errors?.icon?.message}</FormFeedback>
                    </FormGroup>
                    <FormGroup className="w-100">
                      <Label>{`${t(parent)} ${t(category)}`}</Label>
                      <Controller
                        name="parentCategory"
                        defaultValue={DEFAULT_VALUES?.parentCategory}
                        control={control}
                        render={({ field: { onChange } }) => {
                          return (
                            <TreeDataCheckbox
                              onSelect={(node) => onChange(node.id as number)}
                              defaultExpandedIds={defaultExpandedIds}
                              unselectParentsCheckbox={false}
                              disableParentSelectWhileHasChild={false}
                              checkbox
                              style={{ maxHeight: '400px', overflowY: 'auto' }}
                              data={treeData}
                            />
                          );
                        }}
                      />
                    </FormGroup>
                  </div>
                  <div className="bg-gray p-3">
                    <div className="d-flex align-items justify-content-between flex-wrap">
                      <FormGroup className={`mr-3 p-4 flex-grow-1 rounded bg-gray position-relative mb-2`}>
                        <Controller
                          control={control}
                          defaultValue={LANGUAGES[0].value}
                          name="language.0.language"
                          render={() => {
                            return (
                              <FormGroup>
                                <Label>{t(language)}</Label>
                                <Select
                                  options={LANGUAGES_DROPDOWN}
                                  defaultValue={{ label: LANGUAGES[0].label, value: LANGUAGES[0].value }}
                                  isDisabled
                                />
                              </FormGroup>
                            );
                          }}
                        />
                        <FormGroup>
                          <Label>{`${t(category)} ${t(name)}`}</Label>
                          <Controller
                            name="language.0.translate"
                            defaultValue={DEFAULT_VALUES.language?.[0]?.translate}
                            rules={{ required: `${t(please)} ${t(enter)} ${t(translation)}` }}
                            control={control}
                            render={({ field: { onChange } }) => {
                              return (
                                <Input
                                  defaultValue={DEFAULT_VALUES.language?.[0]?.translate}
                                  onChange={onChange}
                                  placeholder="Translate"
                                />
                              );
                            }}
                          />
                          <FormFeedback>{errors?.language?.[0]?.translate?.message}</FormFeedback>
                        </FormGroup>
                      </FormGroup>

                      <FormGroup className={`mr-3 p-4 flex-grow-1 rounded bg-gray position-relative mb-2`}>
                        <Controller
                          control={control}
                          defaultValue={LANGUAGES[1].value}
                          name="language.1.language"
                          render={() => {
                            return (
                              <FormGroup>
                                <Label>{t(language)}</Label>
                                <Select
                                  options={LANGUAGES_DROPDOWN}
                                  defaultValue={{ label: LANGUAGES[1].label, value: LANGUAGES[1].value }}
                                  isDisabled
                                />
                              </FormGroup>
                            );
                          }}
                        />
                        <FormGroup>
                          <Label>{`${t(category)} ${t(name)}`}</Label>
                          <Controller
                            name="language.1.translate"
                            control={control}
                            rules={{ required: `${t(please)} ${t(enter)} ${t(translation)}` }}
                            defaultValue={DEFAULT_VALUES.language?.[1]?.translate}
                            render={({ field: { onChange } }) => {
                              return (
                                <Input
                                  defaultValue={DEFAULT_VALUES.language?.[1]?.translate}
                                  onChange={onChange}
                                  placeholder={'Translate'}
                                />
                              );
                            }}
                          />
                          <FormFeedback>{errors?.language?.[1]?.translate?.message}</FormFeedback>
                        </FormGroup>
                      </FormGroup>
                    </div>
                    <FormGroup className="d-inline-flex">
                      <Controller
                        control={control}
                        name="requireModel"
                        render={({ field: { onChange } }) => {
                          return (
                            <FormCheck
                              id="requireModel"
                              onChange={onChange}
                              name="requireModel"
                              defaultChecked={DEFAULT_VALUES.requireModel}
                            />
                          );
                        }}
                      />
                      <Label className="ml-2" htmlFor="requireModel">
                        {t(productModelIsRequired)}
                      </Label>
                    </FormGroup>
                  </div>
                  <SubmitButton loading={loading}>{t(isNew ? create : update)}</SubmitButton>
                </Form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </PagesLayout>
    </Col>
  );
}

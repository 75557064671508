import { useUpdateEffect } from 'hooks';
import useQuery from 'hooks/useQuery';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { GET_UNIVERSITY_SECTIONS } from 'services/graphql/queries/universitySections';
import { d_getTreeData } from 'store/tree/tree.dispatchs';
import { APP_HELPER } from 'tools/enums';
import { add, section, university } from 'tools/i18n/constants/i18n';
import { isTrophy, makeSubCategoryTree } from 'tools/methods';

export default function useUniversitySection() {
  const { t } = useTranslation();
  const [param] = useSearchParams();
  const defaultPage = param.get('page') ? parseInt(param.get('page') as string) : APP_HELPER.tableDefaultPage;
  const defaultSize = param.get('size') ? parseInt(param.get('size') as string) : APP_HELPER.tableDefaultSize;
  const { data, loading, error, fetchMore } = useQuery(GET_UNIVERSITY_SECTIONS, {
    fetchPolicy: 'no-cache',
    defaultOptions: {
      variables: {
        page: defaultPage,
        size: defaultSize,
      },
    },
  });
  const isListEmpty: boolean = useMemo(() => isTrophy(data?.employmentEducationSectionsList.data?.length), [data]);
  const updatedData = useMemo(
    () => (isListEmpty ? makeSubCategoryTree(data?.employmentEducationSectionsList?.data, APP_HELPER.defaultLogo) : []),
    [isListEmpty]
  );

  const addNewTitle = `${t(add)} ${t(university)} ${t(section)}`;

  useUpdateEffect(() => {
    if (isTrophy(updatedData)) {
      d_getTreeData(updatedData);
    }
  }, [updatedData]);
  return {
    addNewTitle,
    isListEmpty,
    updatedData,
    error,
    loading,
    fetchMore,
    count: data?.employmentEducationSectionsList?.count || 0,
  };
}

import React from 'react';
import { Button, Card, CardBody, CardHeader, Col, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import { enter, input, mobileAppVersions, please, update, updated } from 'tools/i18n/constants/i18n';
import { useTranslation } from 'react-i18next';
import useQuery from 'hooks/useQuery';
import { GET_OS_DETAILS, UPDATE_OS_DETAIL } from 'services/graphql/queries/mobileAppVersion';
import { Loading } from 'components';
import useMutation from 'hooks/useMutation';
import PagesLayout from 'pages/components/layout';
import ShowError from 'components/showError';
import { toast } from 'react-toastify';
import Icon from 'components/icons';
type t_defaultValues = {
  customError?: string;
  lastVersionAndroid: string | number;
  lastVersionIOS: string | number;
  minForceAndroid: string | number;
  minForceIOS: string | number;
  IOSUpdateLink: string;
  androidUpdateLink: string;
};
function MobileAppOsVersion() {
  const { data, loading, error } = useQuery(GET_OS_DETAILS);
  const { t } = useTranslation();
  console.log(data);
  return (
    <PagesLayout>
      <Col xs={12} xl={11} xxl={10} className="mx-auto">
        <Loading loading={loading} failed={!!error}>
          <Card>
            <CardHeader>
              <h4>{t(mobileAppVersions)}</h4>
            </CardHeader>
            <CardBody>
              <MobileAppOsForm defaultValues={data?.getLastAgentOSSetting} />
            </CardBody>
          </Card>
        </Loading>
      </Col>
    </PagesLayout>
  );
}
export default MobileAppOsVersion;

interface MobileAppOsFormProps {
  defaultValues: t_defaultValues;
}
function MobileAppOsForm<T extends MobileAppOsFormProps>({ defaultValues }: T): JSX.Element {
  const [updateExecute, { loading }] = useMutation<any, t_defaultValues>(UPDATE_OS_DETAIL);
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm<t_defaultValues>();
  const { t } = useTranslation();
  const pleaseEnterInput = `${t(please)} ${t(enter)} ${t(input)}`;
  const onSubmit = (data: t_defaultValues) => {
    updateExecute({
      variables: {
        lastVersionIOS: parseFloat(data.lastVersionIOS as string),
        lastVersionAndroid: parseFloat(data.lastVersionAndroid as string),
        minForceAndroid: parseFloat(data.minForceAndroid as string),
        minForceIOS: parseFloat(data.minForceIOS as string),
        androidUpdateLink: data.androidUpdateLink,
        IOSUpdateLink: data.IOSUpdateLink,
      },
    })
      .then((res) => {
        if (res.data) {
          toast.success(`${t(updated)}`);
        } else {
          //@ts-ignore
          setValue('customError', res.errors?.[0]?.extensions?.exceptions?.response.devMessage || res?.errors?.message);
        }
      })
      .catch((err) => {
        setValue('customError', err?.message);
        console.error(err);
      });
  };
  return (
    <Form aria-disabled={loading} onSubmit={handleSubmit(onSubmit, console.error)}>
      {!!getValues('customError') && (
        <FormGroup className="my-2">
          <ShowError>{getValues('customError')}</ShowError>
        </FormGroup>
      )}

      <FormGroup className="d-flex flex-column">
        <FormGroup className="d-flex mb-0">
          <FormGroup className="flex-grow-1 mr-2 mb-0">
            <Label className="d-flex align-items-center">
              <Icon Name="FcAndroidOs" />
              <span className="ml-2">Last Android Version</span>
            </Label>
            <Controller
              name="lastVersionAndroid"
              defaultValue={defaultValues?.lastVersionAndroid}
              rules={{
                required: pleaseEnterInput,
              }}
              control={control}
              render={({ field: { onChange } }) => {
                return (
                  <Input
                    onChange={onChange}
                    defaultValue={defaultValues?.lastVersionAndroid}
                    name="lastVersionAndroid"
                  />
                );
              }}
            />

            <FormFeedback>{errors?.lastVersionAndroid?.message}</FormFeedback>
          </FormGroup>

          <FormGroup className="flex-grow-1 mb-0">
            <Label className="d-flex align-items-center">
              <Icon Name="FcAndroidOs" />
              <span className="ml-2">minimum Android Force</span>
            </Label>

            <Controller
              name="minForceAndroid"
              defaultValue={defaultValues?.minForceAndroid}
              rules={{
                required: pleaseEnterInput,
              }}
              control={control}
              render={({ field: { onChange } }) => {
                return (
                  <Input name="minForceAndroid" defaultValue={defaultValues?.minForceAndroid} onChange={onChange} />
                );
              }}
            />
            <FormFeedback>{errors?.minForceAndroid?.message}</FormFeedback>
          </FormGroup>
        </FormGroup>
        <FormGroup>
          <Label className="d-flex align-items-center">
            <Icon Name="FcAndroidOs" />
            <span className="ml-2">Android Update Link</span>
          </Label>

          <Controller
            name="androidUpdateLink"
            defaultValue={defaultValues?.androidUpdateLink}
            rules={{
              required: pleaseEnterInput,
            }}
            control={control}
            render={({ field: { onChange } }) => {
              return (
                <Input name="androidUpdateLink" defaultValue={defaultValues?.androidUpdateLink} onChange={onChange} />
              );
            }}
          />

          <FormFeedback>{errors?.androidUpdateLink?.message}</FormFeedback>
        </FormGroup>
      </FormGroup>

      <FormGroup className="d-flex flex-column">
        <FormGroup className="d-flex mb-0">
          <FormGroup className="flex-grow-1 mr-2 mb-0">
            <Label className="d-flex align-items-center">
              <Icon Name="FcIphone" />
              <span className="ml-2">Last Ios Version</span>
            </Label>

            <Controller
              name="lastVersionIOS"
              defaultValue={defaultValues?.lastVersionIOS}
              rules={{
                required: pleaseEnterInput,
              }}
              control={control}
              render={({ field: { onChange } }) => {
                return <Input name="lastVersionIOS" defaultValue={defaultValues?.lastVersionIOS} onChange={onChange} />;
              }}
            />

            <FormFeedback>{errors?.lastVersionIOS?.message}</FormFeedback>
          </FormGroup>
          <FormGroup className="flex-grow-1 mb-0">
            <Label className="d-flex align-items-center">
              <Icon Name="FcIphone" />
              <span className="ml-2">minimum Ios Force</span>
            </Label>

            <Controller
              name="minForceIOS"
              defaultValue={defaultValues?.minForceIOS}
              rules={{
                required: pleaseEnterInput,
              }}
              control={control}
              render={({ field: { onChange } }) => {
                return <Input name="minForceIOS" defaultValue={defaultValues?.minForceIOS} onChange={onChange} />;
              }}
            />

            <FormFeedback>{errors?.minForceIOS?.message}</FormFeedback>
          </FormGroup>
        </FormGroup>
        <FormGroup>
          <Label className="d-flex align-items-center">
            <Icon Name="FcIphone" />
            <span className="ml-2">Ios Update Link</span>
          </Label>

          <Controller
            name="IOSUpdateLink"
            defaultValue={defaultValues?.IOSUpdateLink}
            rules={{
              required: pleaseEnterInput,
            }}
            control={control}
            render={({ field: { onChange } }) => {
              return <Input name="IOSUpdateLink" defaultValue={defaultValues?.IOSUpdateLink} onChange={onChange} />;
            }}
          />

          <FormFeedback>{errors?.IOSUpdateLink?.message}</FormFeedback>
        </FormGroup>
      </FormGroup>

      <Button disabled={!isDirty || loading} color="primary" className="w-100 mt-3">
        {t(update)}
      </Button>
    </Form>
  );
}

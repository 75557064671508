import Icon from 'components/icons';
import Link from 'components/link';
import React, { FC, Fragment } from 'react';
import { Link as L } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { DASHBOARD_APP_LINK, t_dashboardRouteKey } from 'route/appRoutes';
import { PAGE_DISPLAY_NAME } from 'tools/constants';
import { APP_ROUTE_NAME } from 'tools/enums';
import { toBreadCrumb } from 'tools/methods';
import { combineLinkKeys } from 'tools/types';

interface BreadcrumbProps {
  title?: string;
  crumbs?: string;
}

const Breadcrumbs: FC<BreadcrumbProps> = (props) => {
  const { crumbs } = props;
  const toCrumbArray = toBreadCrumb(crumbs || (window.location.pathname as string)) as combineLinkKeys[];
  const dashboard = APP_ROUTE_NAME.dashboard;

  return (
    <Fragment>
      <div className="d-inline page-title py-2">
        <Breadcrumb>
          {toCrumbArray.map((c, i) => {
            const crumb = c as t_dashboardRouteKey;
            const isActive = i === toCrumbArray.length - 1;
            const concatRest = toCrumbArray
              .filter((b, j) => j <= i)
              .filter((b) => b !== c)
              .join('/');
            const isValidCrumb = !!DASHBOARD_APP_LINK[c];
            return (
              <Fragment key={crumb}>
                {crumb === dashboard ? (
                  <BreadcrumbItem key={dashboard}>
                    <L to={`/${dashboard}`}>
                      <Icon Name="FiHome" />
                    </L>
                  </BreadcrumbItem>
                ) : (
                  <>
                    {isValidCrumb ? (
                      <BreadcrumbItem key={crumb} active={isActive}>
                        {isActive ? (
                          PAGE_DISPLAY_NAME[crumb]
                        ) : (
                          <Link to={crumb} param={`/${concatRest}/`}>
                            {PAGE_DISPLAY_NAME[crumb]}
                          </Link>
                        )}
                      </BreadcrumbItem>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </Fragment>
            );
          })}
        </Breadcrumb>
      </div>
    </Fragment>
  );
};

export default Breadcrumbs;

import React from 'react';
import Chart from 'pages/ads/jobAds/statistics/components/chart';
import Applies from 'pages/ads/jobAds/statistics/components/applies';
import AdHistory from 'pages/ads/jobAds/statistics/components/adHistory';
import useStatistics from 'pages/ads/jobAds/statistics/useStatistics';
import { Loading } from 'components';

export default function Statistics() {
  const { data, loading, error } = useStatistics();

  return (
    <Loading loading={loading}>
      <div className="d-flex flex-column">
        {!error && (
          <Chart
            viewsCount={data?.views}
            allViewsCount={data?.allViewsCount}
            uniqueViewsCount={data?.uniqueViewsCount}
          />
        )}
        <Applies
          totalCount={
            data?.applyStatistics
              ? data.applyStatistics.fitCount +
                data.applyStatistics.notFitCount +
                data.applyStatistics.reviewedCount +
                data.applyStatistics.hiredCount +
                data.applyStatistics.notDecidedCount +
                data.applyStatistics.notReviewedCount
              : 0
          }
          reviewedCount={data?.applyStatistics?.reviewedCount}
          notReviewedCount={data?.applyStatistics?.notReviewedCount}
        />
        <AdHistory />
      </div>
    </Loading>
  );
}

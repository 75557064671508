import { gql } from '@apollo/client';

export const GET_COUNTRY = gql`
  query getCountries {
    countryList {
      id
      name
      city {
        id
        name
      }
    }
  }
`;

export const GET_SINGLE_COUNTRY = gql`
  query getSingleCountry($id: Int!) {
    findOneCountry(findOneCountryDto: { id: $id }) {
      name
      id
      city {
        name
        id
      }
    }
  }
`;

export const CREATE_COUNTRY = gql`
  mutation createCountry($name: String!) {
    createCountry(createCountryDto: { name: $name }) {
      name
      id
      city {
        id
        name
      }
    }
  }
`;

export const GET_SINGLE_CITY = gql`
  query getSingleCity($id: Int!) {
    findOneCity(findOneCityDto: { id: $id }) {
      id
      name
    }
  }
`;

export const CREATE_CITY = gql`
  mutation createCity($name: String!, $countryId: Float!) {
    createCity(createCityDto: { name: $name, countryId: $countryId }) {
      id
      name
    }
  }
`;

export const UPDATE_COUNTRY = gql`
  mutation updateCountry($id: Int!, $name: String!) {
    updateCountry(updateCountryDto: { id: $id, name: $name }) {
      message
    }
  }
`;

export const UPDATE_CITY = gql`
  mutation updateCity($id: Int!, $name: String!) {
    updateCity(updateCityDto: { id: $id, name: $name }) {
      message
    }
  }
`;

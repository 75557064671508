import React from 'react';

export interface HorizontalLineProps {
  className?: string;
  lineHeight?: string;
}

export default function HorizontalLine(props: HorizontalLineProps) {
  const { className = 'rounded flex-grow-1 bg-gray', lineHeight = '5px' } = props;
  return <span style={{ height: lineHeight }} className={className} />;
}

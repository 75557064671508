export enum CONTENT_MANAGEMENT_DETAIL_TYPE_LETTER {
  U = 'Upload',
  N = 'Name',
  T = 'TreeSelect',
  D = 'DifferentLanguages',
  R = 'RequiredProductModel',
  C = 'City',
}
export enum CONTENT_MANAGEMENT_DETAIL_COMPONENT {
  Upload = 'U',
  Name = 'N',
  TreeSelect = 'T',
  DifferentLanguages = 'D',
  RequiredProductModel = 'R',
  City = 'C',
}
export enum CONTENT_MANAGEMENT_DETAIL_SECTION_NAME {
  jobCategories = 'jobCategories',
  bazzarCategories = 'bazzarCategories',
  experienceExpectation = 'experienceExpectation',
  softwares = 'softwares',
  universities = 'universities',
  universitySections = 'universitySections',
  school = 'school',
  companySizes = 'companySizes',
  companyCategories = 'companyCategories',
  companies = 'companies',
  companyLogos = 'companyLogos',
}
export enum UserTypeEnum {
  employee = 'EMPLOYEE',
  employer = 'EMPLOYER',
}

export enum ROUTE_PREFIXES {
  userManagement = 'userManagement',
  contentManagement = 'contentManagement',
  employment = 'employment',
  company = 'company',
  slider = 'slider',
  emlak = 'emlak',
}
export enum APP_ROUTE_NAME {
  dashboard = 'dashboard',

  // user management
  deletedUsers = 'deletedUsers',
  deleteReasons = 'deleteReasons',
  deleteReasonsDetail = 'deleteReasons',
  banReasons = 'banReasons',
  banReasonDetail = 'banReasons',
  usersDetails = 'usersDetails',
  employer = 'employer',
  employee = 'employee',
  employeeDetail = 'employeeDetail',
  admins = 'admins',
  adminDetail = 'admins',
  users = 'users',
  // end user management

  // content management

  // employment
  jobCategories = 'jobCategories',
  jobCategoryDetails = 'jobCategories',
  experienceExpectation = 'experienceExpectation',
  experienceExpectationDetails = 'experienceExpectation',
  workRejectionReasons = 'workRejectionReasons',
  workRejectionReasonsDetails = 'workRejectionReasons',
  softwares = 'softwares',
  softwaresDetails = 'softwares',
  universities = 'universities',
  universitiesDetails = 'universities',
  universitySections = 'universitySections',
  universitySectionsDetails = 'universitySections',
  ageRange = 'ageRange',
  ageRangeDetail = 'ageRange',
  languages = 'languages',
  languagesDetails = 'languages',
  // end employment

  // company
  companies = 'companies',
  companiesDetails = 'companies',

  companySizes = 'companySizes',
  companySizeDetail = 'companySizes',

  companyLogos = 'companyLogos',

  companyCategories = 'companyCategories',
  companyCategoriesV2 = 'companyCategoriesV2',
  companyCategoryDetail = 'companyCategories',
  companyCategoryDetailV2 = 'companyCategoriesV2',

  documentTypes = 'documentTypes',
  documentTypeDetail = 'documentTypes',

  documentRejectionReasons = 'documentRejectionReasons',
  documentRejectionReasonsDetail = 'documentRejectionReasons',

  companyLeaderReasons = 'companyLeaderReasons',
  companyLeaderReasonsDetail = 'companyLeaderReasons',
  // end company

  // bazaar
  bazaarCategories = 'bazzarCategories',
  bazaarCategoriesDetails = 'bazzarCategories',
  //end bazaar

  // slider
  slider = 'slider',
  sliderDetail = 'slider',
  // end slider

  // emlak
  specialLocation = 'specialLocation',
  specialLocationDetail = 'specialLocation',

  emlakTypes = 'emlakTypes',
  emlakTypesDetails = 'emlakTypes',

  emlakCategories = 'emlakCategories',
  emlakCategoryDetail = 'emlakCategories',

  emlakAds = 'emlakAds',
  emlakAdsDetail = 'emlakAds',

  emlakAdsReportReasons = 'emlakAdsReportReasons',
  emlakAdsReportReasonsDetail = 'emlakAdsReportReasons',
  // end emlak

  // end content management

  // fuar
  exhibition = 'exhibition',
  exhibitionDetails = 'exhibition',
  hotel = 'hotel',
  hotelDetail = 'hotel',
  location = 'location',
  locationDetail = 'location',
  locationCity = 'location',
  address = 'address',
  // end fuar

  // analytics
  analytics = 'analytics',
  //end analytics

  // school
  schools = 'schools',
  schoolDetail = 'schools',
  // end school

  // ads
  jobAds = 'jobAds',
  jobAdsDetail = 'jobAds',
  jobApplyListDetails = 'jobAds',
  jobAdsReport = 'jobAdsReport',
  jobAdsReportDetail = 'jobAdsReport',
  bazzarAds = 'bazzarAds',
  bazzarAdsDetail = 'bazzarAds',
  bazaarAdsReport = 'bazaarAdsReport',
  bazaarAdsReportDetail = 'bazaarAdsReport',
  bazzarAdsApplyList = 'bazzarAds',
  // end ads

  // magazine
  magazine = 'magazine',
  magazineDetail = 'magazine',
  magazineDetailEdition = 'magazine',
  // end magazine

  // notification
  notification = 'notification',
  notificationDetail = 'notification',
  // end notification

  // exchange
  holidyManagement = 'holidyManagement',
  // end exchange

  // setting
  setting = 'setting',
  mobileAppOsVersions = 'mobileAppOsVersions',
  // end setting

  logOut = 'logOut',
}

export enum SCHOOL_APP_ROUTE_NAME {
  schoolAdmin = 'schoolAdmin',
  studentManagement = 'studentManagement',
  studentDetail = 'studentDetail',
  analytics = 'analytics',
}

export enum SUPER_APP_ROUTE_NAME {
  dashboard = 'dashboard',
}

export enum APP_ROUTE_PARAM_NAME {
  type = 'type',
  id = 'id',
  new = 'new',
  panel = 'panel',
  cityType = 'cityType',
  detailType = 'detailType',
  section = 'section',
  delete = 'delete',
  all = '*',
}

export enum APP_LINK_KEY {
  new = 'new',
  delete = 'delete',
  edit = 'edit',
  school = 'school',
}

export enum BLUE_TICKET_STATUS {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  IN_PROGRESS = 'IN_PROGRESS',
  CANCELED = 'CANCELED',
  NOT_REQUESTED = 'NOT_REQUESTED',
}

export enum BLUE_TICKET_STATUS_UI {
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected By Admin',
  IN_PROGRESS = 'Pending',
  CANCELED = 'Canceled By Admin',
  NOT_REQUESTED = 'Not Requested',
}

export enum COMPANY_REHBER_STATUS {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  NONE = 'NONE',
  PENDING = 'PENDING',
}

export enum COMPANY_REHBER_STATUS_UI {
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
  NONE = 'None',
  PENDING = 'Pending',
}

export enum APP_HELPER {
  emptyDescription = '--',
  tableMaxPage = 1000000000000000000,
  treeMaxSize = 10000,
  treeDefaultPage = 1,
  tableDefaultPage = 1,
  tableDefaultSize = 50,
  gmailPlaceholder = 'username@dmail.com',
  websitePlaceholder = 'https://www.abc.com',
  defaultLogo = 'https://img.logoipsum.com/274.svg',
  defaultBazaarAndJobIcon = 'https://storage.buluro.com/assets/element-3.svg',
  defaultUniversityIcon = 'https://storage.buluro.com/assets/Group 48098103.svg',
  defaultUniversitySectionIcon = 'https://storage.buluro.com/assets/Section.svg',
  clientError = 400,
}
export enum EDUCATION_GRADE {
  PRIMARY_SCHOOL = 'PRIMARY_SCHOOL',
  MIDDLE_SCHOOL = 'MIDDLE_SCHOOL',
  HIGH_SCHOOL = 'HIGH_SCHOOL',
  UNIVERSITY = 'UNIVERSITY',
  MASTERS_DEGREE = 'MASTERS_DEGREE',
  ART_SCHOOL = 'ART_SCHOOL',
  VOCATIONAL_HIGH_SCHOOL = 'VOCATIONAL_HIGH_SCHOOL',
  NO_EDUCATED = 'NO_EDUCATED',
}
export enum EDUCATION_GRADE_TEXT {
  PRIMARY_SCHOOL = 'Primary School',
  MIDDLE_SCHOOL = 'Middle School',
  HIGH_SCHOOL = 'High School',
  UNIVERSITY = 'University',
  MASTERS_DEGREE = 'Master Degree',
  ART_SCHOOL = 'Art School',
  VOCATIONAL_HIGH_SCHOOL = 'Vocational High School',
  NO_EDUCATED = 'No Educated',
}

export enum ADS_STATUS {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  DE_ACTIVE = 'DE_ACTIVE',
  ARCHIVE = 'ARCHIVE',
  EXPIRED = 'EXPIRED',
  ADMIN_BAN = 'ADMIN_BAN',
}
export enum ADS_STATUS_TEXT {
  DRAFT = 'Draft',
  ACTIVE = 'Active',
  DE_ACTIVE = 'DeActive',
  ARCHIVE = 'Archive',
  EXPIRED = 'Expired',
  ADMIN_BAN = 'Admin Ban',
  UNREVIEWED = 'Unreviewed',
}

export enum BAZAAR_ADS_STATUS {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  DE_ACTIVE = 'DE_ACTIVE',
  ARCHIVE = 'ARCHIVE',
  EXPIRED = 'EXPIRED',
  BAN = 'BAN',
}
export enum BAZAAR_ADS_OPERATOR {
  BY_USER = 'BY_USER',
  BY_ADMIN = 'BY_ADMIN',
  BY_SYSTEM = 'BY_SYSTEM',
}

export enum APPLIES_STATUS {
  GOOD_FIT = 'GOOD_FIT',
  NOT_FIT = 'NOT_FIT',
  PENDING = 'PENDING',
  HIRED = 'HIRED',
  NOT_DECIDED = 'NOT_DECIDED',
}

export enum APPLIES_STATUS_TEXT {
  GOOD_FIT = 'Good Fit',
  NOT_FIT = 'Not Fit',
  PENDING = 'Pending',
  HIRED = 'Hired',
  NOT_DECIDED = 'Not Decided',
}

export enum EXPERIENCE_EXPECTATION {
  APPRENTICE = 'APPRENTICE',
  JOURNEYMAM = 'JOURNEYMAM',
  GOOD_LEVEL = 'GOOD_LEVEL',
  EXPERIENCED = 'EXPERIENCED',
  EXPERT = 'EXPERT',
}
export enum EXPERIENCE_EXPECTATION_TEXT {
  APPRENTICE = 'Apprentice',
  JOURNEYMAM = 'Journeyman',
  GOOD_LEVEL = 'Good Level',
  EXPERIENCED = 'Experienced',
  EXPERT = 'Expert',
}
export enum MILITARY_STATUS {
  NOT_DONE = 'NOT_DONE',
  DONE = 'DONE',
}

export enum MILITARY_STATUS_TEXT {
  NOT_DONE = 'Not Done',
  DONE = 'Done',
}
export enum LANGUAGE_LEVEL {
  STARTER = 'STARTER',
  BEGINNER = 'BEGINNER',
  INTERMEDIATE = 'INTERMEDIATE',
  ADVANCED = 'ADVANCED',
}

export enum LANGUAGE_LEVEL_TEXT {
  STARTER = 'Starter',
  BEGINNER = 'Beginner',
  INTERMEDIATE = 'Intermediate',
  ADVANCED = 'Advanced',
}

export enum Permission {
  CREATE_NEW_ADMIN = 'CREATE_NEW_ADMIN',
  READ_ADMINS_LIST = 'READ_ADMINS_LIST',
  READ_ADMIN_DETAILS = 'READ_ADMIN_DETAILS',
  CREATE_NEW_LEGAL_USER = 'CREATE_NEW_LEGAL_USER',
  READ_USERS_LIST = 'READ_USERS_LIST',
  READ_USER_DETAILS = 'READ_USER_DETAILS',
  UPDATE_PASSWORD = 'UPDATE_PASSWORD',
  /*********************START EMPLOYMENT**********************/
  EMPLOYMENT_READ_EMPLOYERS_LOGO_LIST = 'EMPLOYMENT_READ_EMPLOYERS_LOGO_LIST',
  EMPLOYMENT_READ_AD_LIST = 'EMPLOYMENT_READ_AD_LIST',
  EMPLOYMENT_READ_APPLY_LIST = 'EMPLOYMENT_READ_APPLY_LIST',
  EMPLOYMENT_READ_AD_DETAILS = 'EMPLOYMENT_READ_AD_DETAILS',
  EMPLOYMENT_READ_APPLY_DETAILS = 'EMPLOYMENT_READ_APPLY_DETAILS',
  EMPLOYMENT_READ_AD_STATISTICS = 'EMPLOYMENT_READ_AD_STATISTICS',
  EMPLOYMENT_READ_SCHOOL_LIST = 'EMPLOYMENT_READ_SCHOOL_LIST',
  EMPLOYMENT_READ_SCHOOL_DETAILS = 'EMPLOYMENT_READ_SCHOOL_DETAILS',
  EMPLOYMENT_READ_SCHOOL_STUDENT_LIST = 'EMPLOYMENT_READ_SCHOOL_STUDENT_LIST',
  EMPLOYMENT_READ_SCHOOL_STUDENT_DETAILS = 'EMPLOYMENT_READ_SCHOOL_STUDENT_DETAILS',
  EMPLOYMENT_READ_EMPLOYEE_PROFILE = 'EMPLOYMENT_READ_EMPLOYEE_PROFILE',
  EMPLOYMENT_DELETE_EMPLOYER_LOGO = 'EMPLOYMENT_DELETE_EMPLOYER_LOGO',
  EMPLOYMENT_UPDATE_EMPLOYER_LOGO = 'EMPLOYMENT_UPDATE_EMPLOYER_LOGO',
  EMPLOYMENT_CREATE_SCHOOL = 'EMPLOYMENT_CREATE_SCHOOL',
  EMPLOYMENT_UPDATE_SCHOOL = 'EMPLOYMENT_UPDATE_SCHOOL',
  EMPLOYMENT_UPDATE_STUDENT_SEARCHABLE = 'EMPLOYMENT_UPDATE_STUDENT_SEARCHABLE',
  EMPLOYMENT_UPDATE_STUDENT_ACCEPTATION = 'EMPLOYMENT_UPDATE_STUDENT_ACCEPTATION',
  EMPLOYMENT_BAN_AD = 'EMPLOYMENT_BAN_AD',
  EMPLOYMENT_ACTIVE_BANNED_AD = 'EMPLOYMENT_ACTIVE_BANNED_AD',
  EMPLOYMENT_UPDATE_EMPLOYER_SPECIALITIES = 'EMPLOYMENT_UPDATE_EMPLOYER_SPECIALITIES',
  EMPLOYMENT_UPDATE_EMPLOYER_WORKING_HOURS = 'EMPLOYMENT_UPDATE_EMPLOYER_WORKING_HOURS',
  EMPLOYMENT_ADD_EMPLOYER_ADDRESS = 'EMPLOYMENT_ADD_EMPLOYER_ADDRESS',
  EMPLOYMENT_DELETE_EMPLOYER_ADDRESS = 'EMPLOYMENT_DELETE_EMPLOYER_ADDRESS',
  EMPLOYMENT_READ_EMPLOYER_CATEGORY_LIST = 'EMPLOYMENT_READ_EMPLOYER_CATEGORY_LIST',
  EMPLOYMENT_READ_EMPLOYEE_CATEGORY_LIST = 'EMPLOYMENT_READ_EMPLOYEE_CATEGORY_LIST',
  EMPLOYMENT_READ_EXPERIENCE_EXPECTATION_LIST = 'EMPLOYMENT_READ_EXPERIENCE_EXPECTATION_LIST',
  EMPLOYMENT_READ_CITY_AND_COUNTY_LIST = 'EMPLOYMENT_READ_CITY_AND_COUNTY_LIST',
  EMPLOYMENT_READ_EDUCATION_SECTION_LIST = 'EMPLOYMENT_READ_EDUCATION_SECTION_LIST',
  EMPLOYMENT_READ_LANGUAGE_LIST = 'EMPLOYMENT_READ_LANGUAGE_LIST',
  EMPLOYMENT_READ_SOFTWARE_LIST = 'EMPLOYMENT_READ_SOFTWARE_LIST',
  EMPLOYMENT_READ_AGE_RANGE_LIST = 'EMPLOYMENT_READ_AGE_RANGE_LIST',
  EMPLOYMENT_READ_AGE_RANGE_DETAILS = 'EMPLOYMENT_READ_AGE_RANGE_DETAILS',
  EMPLOYMENT_READ_UNIVERSITY_LIST = 'EMPLOYMENT_READ_UNIVERSITY_LIST',
  EMPLOYMENT_READ_WORK_EXPERIENCE_REJECT_REASON_LIST = 'EMPLOYMENT_READ_WORK_EXPERIENCE_REJECT_REASON_LIST',
  EMPLOYMENT_READ_AD_REPORT_REASON_LIST = 'EMPLOYMENT_READ_AD_REPORT_REASON_LIST',
  EMPLOYMENT_CREATE_EMPLOYER_CATEGORY = 'EMPLOYMENT_CREATE_EMPLOYER_CATEGORY',
  EMPLOYMENT_UPDATE_EMPLOYER_CATEGORY = 'EMPLOYMENT_UPDATE_EMPLOYER_CATEGORY',
  EMPLOYMENT_CREATE_BRANCH = 'EMPLOYMENT_CREATE_BRANCH',
  EMPLOYMENT_UPDATE_BRANCH = 'EMPLOYMENT_UPDATE_BRANCH',
  EMPLOYMENT_CREATE_SECTION = 'EMPLOYMENT_CREATE_SECTION',
  EMPLOYMENT_UPDATE_SECTION = 'EMPLOYMENT_UPDATE_SECTION',
  EMPLOYMENT_CREATE_EXPERIENCE_EXPECTATION = 'EMPLOYMENT_CREATE_EXPERIENCE_EXPECTATION',
  EMPLOYMENT_UPDATE_EXPERIENCE_EXPECTATION = 'EMPLOYMENT_UPDATE_EXPERIENCE_EXPECTATION',
  EMPLOYMENT_CREATE_EDUCATION_SECTION = 'EMPLOYMENT_CREATE_EDUCATION_SECTION',
  EMPLOYMENT_UPDATE_EDUCATION_SECTION = 'EMPLOYMENT_UPDATE_EDUCATION_SECTION',
  EMPLOYMENT_CREATE_LANGUAGE = 'EMPLOYMENT_CREATE_LANGUAGE',
  EMPLOYMENT_UPDATE_LANGUAGE = 'EMPLOYMENT_UPDATE_LANGUAGE',
  EMPLOYMENT_CREATE_SOFTWARE = 'EMPLOYMENT_CREATE_SOFTWARE',
  EMPLOYMENT_UPDATE_SOFTWARE = 'EMPLOYMENT_UPDATE_SOFTWARE',
  EMPLOYMENT_CREATE_AGE_RANGE = 'EMPLOYMENT_CREATE_AGE_RANGE',
  EMPLOYMENT_UPDATE_AGE_RANGE = 'EMPLOYMENT_UPDATE_AGE_RANGE',
  EMPLOYMENT_CREATE_UNIVERSITY = 'EMPLOYMENT_CREATE_UNIVERSITY',
  EMPLOYMENT_UPDATE_UNIVERSITY = 'EMPLOYMENT_UPDATE_UNIVERSITY',
  EMPLOYMENT_CREATE_WORK_EXPERIENCE_REJECT_REASON = 'EMPLOYMENT_CREATE_WORK_EXPERIENCE_REJECT_REASON',
  EMPLOYMENT_UPDATE_WORK_EXPERIENCE_REJECT_REASON = 'EMPLOYMENT_UPDATE_WORK_EXPERIENCE_REJECT_REASON',
  EMPLOYMENT_CREATE_AD_REPORT_REASON = 'EMPLOYMENT_CREATE_AD_REPORT_REASON',
  EMPLOYMENT_UPDATE_AD_REPORT_REASON = 'EMPLOYMENT_UPDATE_AD_REPORT_REASON',
  /*********************END EMPLOYMENT**********************/
  /*********************START MESSAGING**********************/
  MESSAGING_READ_CUSTOM_NOTIFICATION_LIST = 'MESSAGING_READ_CUSTOM_NOTIFICATION_LIST',
  MESSAGING_READ_CUSTOM_NOTIFICATION_DETAILS = 'MESSAGING_READ_CUSTOM_NOTIFICATION_DETAILS',
  MESSAGING_CREATE_CUSTOM_NOTIFICATION = 'MESSAGING_CREATE_CUSTOM_NOTIFICATION',
  MESSAGING_UPDATE_CUSTOM_NOTIFICATION = 'MESSAGING_UPDATE_CUSTOM_NOTIFICATION',
  /*********************END MESSAGING**********************/
  //********************* authentication microservice /*********************/
  AUTHENTICATION_CREATE_COMPANY_SIZE = 'AUTHENTICATION_CREATE_COMPANY_SIZE',
  AUTHENTICATION_UPDATE_COMPANY_SIZE = 'AUTHENTICATION_UPDATE_COMPANY_SIZE',
  AUTHENTICATION_GET_COMPANY_SIZE = 'AUTHENTICATION_GET_COMPANY_SIZE',
  AUTHENTICATION_GET_COMPANY_SIZE_BY_ID = 'AUTHENTICATION_GET_COMPANY_SIZE_BY_ID ',
  AUTHENTICATION_GET_COMPANY_LIST_AND_DETAILS = 'AUTHENTICATION_GET_COMPANY_LIST_AND_DETAILS ',
  AUTHENTICATION_GET_COMPANY_DETAILS_BY_USER_ID = 'AUTHENTICATION_GET_COMPANY_DETAILS_BY_USER_ID ',
  AUTHENTICATION_CHANGE_BLUE_MARK_STATUS = 'AUTHENTICATION_CHANGE_BLUE_MARK_STATUS ',
  AUTHENTICATION_SEND_EMAIL_OTP = 'AUTHENTICATION_SEND_EMAIL_OTP ',
  AUTHENTICATION_VERIFY_EMAIL_OTP = 'AUTHENTICATION_VERIFY_EMAIL_OTP ',
  AUTHENTICATION_CREATE_LEGAL_ACCOUNT = 'AUTHENTICATION_CREATE_LEGAL_ACCOUNT ',
  AUTHENTICATION_MUTATION_DESCRIPTION_LEGAL_ACCOUNT = 'AUTHENTICATION_MUTATION_DESCRIPTION_LEGAL_ACCOUNT ',
  AUTHENTICATION_MUTATION_CONTACTS_LEGAL_ACCOUNT = 'AUTHENTICATION_MUTATION_CONTACTS_LEGAL_ACCOUNT ',
  AUTHENTICATION_MUTATION_SOCIAL_MEDIA_LEGAL_ACCOUNT = 'AUTHENTICATION_MUTATION_SOCIAL_MEDIA_LEGAL_ACCOUNT ',
  AUTHENTICATION_MUTATION_DETAILS_LEGAL_ACCOUNT = 'AUTHENTICATION_MUTATION_DETAILS_LEGAL_ACCOUNT ',
  AUTHENTICATION_GET_DOCUMENT_TYPE_LIST = 'AUTHENTICATION_GET_DOCUMENT_TYPE_LIST ',
  AUTHENTICATION_CREATE_DOCUMENT_TYPE = 'AUTHENTICATION_CREATE_DOCUMENT_TYPE ',
  AUTHENTICATION_UPDATE_DOCUMENT_TYPE = 'AUTHENTICATION_UPDATE_DOCUMENT_TYPE ',
  AUTHENTICATION_GET_DOCUMENT_TYPE_BY_ID = 'AUTHENTICATION_GET_DOCUMENT_TYPE_BY_ID ',
  AUTHENTICATION_GET_DELETE_REASON_LIST = 'AUTHENTICATION_GET_DELETE_REASON_LIST ',
  AUTHENTICATION_GET_DELETE_REASON_BY_ID = 'AUTHENTICATION_GET_DELETE_REASON_BY_ID ',
  AUTHENTICATION_CREATE_DELETE_REASON = 'AUTHENTICATION_CREATE_DELETE_REASON ',
  AUTHENTICATION_UPDATE_DELETE_REASON = 'AUTHENTICATION_UPDATE_DELETE_REASON ',
  AUTHENTICATION_GET_ALL_AGENT_OS = 'AUTHENTICATION_GET_ALL_AGENT_OS ',
  AUTHENTICATION_GET_LAST_AGENT_OS = 'AUTHENTICATION_GET_LAST_AGENT_OS ',
  AUTHENTICATION_UPDATE_AGENT_OS = 'AUTHENTICATION_UPDATE_AGENT_OS ',
  AUTHENTICATION_UPDATE_REHBER_STATUS = 'AUTHENTICATION_UPDATE_REHBER_STATUS ',
  AUTHENTICATION_UPDATE_DOCUMENTS_LEGAL_ACCOUNT = 'AUTHENTICATION_UPDATE_DOCUMENTS_LEGAL_ACCOUNT ',
  AUTHENTICATION_DELETE_DOCUMENTS_LEGAL_ACCOUNT = 'AUTHENTICATION_DELETE_DOCUMENTS_LEGAL_ACCOUNT ',
  AUTHENTICATION_SET_USER_BAN = 'AUTHENTICATION_SET_USER_BAN ',
  AUTHENTICATION_SET_USER_UNBAN = 'AUTHENTICATION_SET_USER_UNBAN ',
  AUTHENTICATION_DELETE_USER_ACCOUNT = 'AUTHENTICATION_DELETE_USER_ACCOUNT ',
  AUTHENTICATION_GET_BAN_REASON_LIST = 'AUTHENTICATION_GET_BAN_REASON_LIST ',
  AUTHENTICATION_GET_BAN_REASON_BY_ID = 'AUTHENTICATION_GET_BAN_REASON_BY_ID ',
  AUTHENTICATION_CREATE_BAN_REASON = 'AUTHENTICATION_CREATE_BAN_REASON ',
  AUTHENTICATION_UPDATE_BAN_REASON = 'AUTHENTICATION_UPDATE_BAN_REASON ',
  //********************* end authentication microservice /*********************/
  //********************* payment microservice /*********************/
  PAYMENT_CREATE_DISCOUNT = 'PAYMENT_CREATE_DISCOUNT ',
  PAYMENT_UPDATE_DISCOUNT = 'PAYMENT_UPDATE_DISCOUNT ',
  PAYMENT_DEACTIVE_DISCOUNT = 'PAYMENT_DEACTIVE_DISCOUNT ',
  PAYMENT_INSERT_PACKET = 'PAYMENT_INSERT_PACKET ',
  PAYMENT_UPDATE_PACKET = 'PAYMENT_UPDATE_PACKET ',
  PAYMENT_GET_ALL_PACKET = 'PAYMENT_GET_ALL_PACKET ',
  PAYMENT_GET_PACKET_BY_ID = 'PAYMENT_GET_PACKET_BY_ID ',
  //***************  Bazaar Permissions  ****************/
  BAZAAR_READ_POSTER = 'BAZAAR_READ_POSTER',
  BAZAAR_READ_CATEGORY = 'BAZAAR_READ_CATEGORY',
  BAZAAR_CREATE_CATEGORY = 'BAZAAR_CREATE_CATEGORY',
  BAZAAR_UPDATE_CATEGORY = 'BAZAAR_UPDATE_CATEGORY',
  BAZAAR_READ_REPORT_REASON = 'BAZAAR_READ_REPORT_REASON',
  BAZAAR_CREATE_REPORT_REASON = 'BAZAAR_CREATE_REPORT_REASON',
  BAZAAR_UPDATE_REPORT_REASON = 'BAZAAR_UPDATE_REPORT_REASON',
  BAZAAR_DELETE_REPORT_REASON = 'BAZAAR_DELETE_REPORT_REASON',
  BAZAAR_SHOP = 'BAZAAR_SHOP',
  BAZAAR_BAN_POSTER = 'BAZAAR_BAN_POSTER',
  BAZAAR_ACTIVE_POSTER = 'BAZAAR_ACTIVE_POSTER',
  BAZAAR_UPLOAD_CATEGORY_ICON = 'BAZAAR_UPLOAD_CATEGORY_ICON',
  //****************************** Side Services Permissions ************************/
  SIDE_SERVICES_READ_COUNTRY = 'SIDE_SERVICES_READ_COUNTRY',
  SIDE_SERVICES_CREATE_COUNTRY = 'SIDE_SERVICES_CREATE_COUNTRY',
  SIDE_SERVICES_UPDATE_COUNTRY = 'SIDE_SERVICES_UPDATE_COUNTRY',
  SIDE_SERVICES_READ_CITY = 'SIDE_SERVICES_READ_CITY',
  SIDE_SERVICES_CREATE_CITY = 'SIDE_SERVICES_CREATE_CITY',
  SIDE_SERVICES_UPDATE_CITY = 'SIDE_SERVICES_UPDATE_CITY',
  SIDE_SERVICES_READ_HOTEL = 'SIDE_SERVICES_READ_HOTEL',
  SIDE_SERVICES_CREATE_HOTEL = 'SIDE_SERVICES_CREATE_HOTEL',
  SIDE_SERVICES_UPDATE_HOTEL = 'SIDE_SERVICES_UPDATE_HOTEL',
  SIDE_SERVICES_READ_EXHIBITION = 'SIDE_SERVICES_READ_EXHIBITION',
  SIDE_SERVICES_CREATE_EXHIBITION = 'SIDE_SERVICES_CREATE_EXHIBITION',
  SIDE_SERVICES_UPDATE_EXHIBITION = 'SIDE_SERVICES_UPDATE_EXHIBITION',
  SIDE_SERVICES_DELETE_EXHIBITION = 'SIDE_SERVICES_UPDATE_EXHIBITION',
  SIDE_SERVICES_MAGAZINE_READ = 'SIDE_SERVICES_MAGAZINE_READ',
  SIDE_SERVICES_MAGAZINE_CREATE = 'SIDE_SERVICES_MAGAZINE_CREATE',
  SIDE_SERVICES_MAGAZINE_UPDATE = 'SIDE_SERVICES_MAGAZINE_UPDATE',
  SIDE_SERVICES_MAGAZINE_DELETE = 'SIDE_SERVICES_MAGAZINE_DELETE',
}

export enum ROLES_BACKEND_MATCH {
  admin = 'admin',
  'super-admin' = 'super-admin',
  'school-admin' = 'school-admin',
}

export enum ROLES_BASED_BACKEND {
  'admin' = 'ADMIN',
  'super-admin' = 'SUPER_ADMIN',
  'school-admin' = 'SCHOOL_ADMIN',
}
export enum ROLES {
  ADMIN = 'ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
  SCHOOL_ADMIN = 'SCHOOL_ADMIN',
}

export enum APP_ROUTE_SECTION_NAME {
  ADMIN_DASHBOARD_ROUTES = 'ADMIN',
  SUPERADMIN_DASHBOARD_ROUTES = 'SUPER_ADMIN',
  SCHOOLADMIN_DASHBOARD_ROUTES = 'SCHOOL_ADMIN',
}
export enum STUDENT_ACCEPTATION_STATUS {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  END_STUDENT = 'END_STUDENT',
}
export enum socialMediaName {
  'Youtube channel' = 'Youtube channel',
  Instagram = 'Instagram',
  Twitter = 'Twitter',
  Facebook = 'Facebook',
  Whatsapp = 'Whatsapp',
  LinkedIn = 'LinkedIn',
}

export enum bazaarAdsEnum {
  New = 'New',
  used = 'Used',
}

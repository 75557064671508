import { useGetStore } from 'hooks';
import Header from 'layout/header';
import Sidebar from 'layout/sidebar';
import DashboardSchoolLayout from 'pages/schoolDashboard/layout';
import React, { ElementType, FC, Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import { ROLES_BACKEND_MATCH, ROLES_BASED_BACKEND } from 'tools/enums';
import Footer from 'layout/footer';

const AppLayout: FC = () => {
  const user = useGetStore().User;
  const currentUserRole = user.userInfo.role?.[0] as ROLES_BACKEND_MATCH;
  const DashboardDynamicLayout = (
    ROLES_BASED_BACKEND[currentUserRole] === ROLES_BASED_BACKEND['school-admin'] ? DashboardSchoolLayout : Fragment
  ) as ElementType;
  return (
    <>
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <Header />
        <div className="page-body-wrapper">
          <Sidebar />
          <div className="page-body">
            <DashboardDynamicLayout>
              <Outlet />
            </DashboardDynamicLayout>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default AppLayout;

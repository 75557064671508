import React, { ReactNode } from 'react';

export type errorCase = {
  danger: 'danger';
  warning: 'warning';
  success: 'success';
  active: 'active';
  notActive: 'notActive';
};

interface ShowErrorInterface {
  children?: ReactNode;
  type?: keyof errorCase;
}

export default function ShowError(props: ShowErrorInterface) {
  const { children, type = 'danger' } = props;
  if (children) {
    return <div className={`p-2 rounded-md shadow-bg-${type}`}>{children}</div>;
  } else return <></>;
}

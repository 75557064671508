import TreeSelectCheckbox from 'components/tree-select-checkbox';
import { t_requiredFields } from 'components/tree-select-checkbox/type';
import { useUpdateEffect } from 'hooks';
import { FilterAdsCommonProps } from 'pages/ads/bazzarAds/filter/index';
import SectionLayout from 'pages/ads/jobAds/components/layout/sectionLayout';
import useBazaarCategories from 'pages/content-management/bazzar/bazarCategories/useBazarCategories';
import { t_jobCategory } from 'pages/content-management/employment/jobCategories/types';
import React, { FC, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { categories } from 'tools/i18n/constants/i18n';
type BazaarAdsCategoryProps = FilterAdsCommonProps;

function BazaarAdsCategories(props: BazaarAdsCategoryProps) {
  const { t } = useTranslation();
  const { loading, data } = useBazaarCategories();

  const [selectedNodes, setSelectedNodes] = useState<number | undefined>(undefined);
  const handleSelectNodes = useCallback((node: any) => {
    setSelectedNodes((prev) => {
      if (prev === node.id) return undefined;
      return node.id;
    });
  }, []);
  useUpdateEffect(() => {
    props.setValue?.('category', selectedNodes);
  }, [selectedNodes]);
  return (
    <SectionLayout title={t(categories)} icon="FiGrid">
      <Categories
        handleSelectNodes={handleSelectNodes}
        data={data}
        loading={loading}
        control={props.control}
        errors={props.errors}
      />
    </SectionLayout>
  );
}
export default memo(BazaarAdsCategories);
interface CategoriesProps extends BazaarAdsCategoryProps {
  data: t_requiredFields<t_jobCategory>[];
  loading: boolean;
  handleSelectNodes: (node: t_requiredFields<t_jobCategory>) => void;
}
function Categories(props: CategoriesProps) {
  return (
    <div className="d-flex flex-column">
      <TreeSelectComponent data={props.data} onChange={props.handleSelectNodes} />
    </div>
  );
}

interface TreeSelectComponentProps {
  data: t_requiredFields<t_jobCategory>[];
  onChange: (node: t_requiredFields<t_jobCategory>) => void;
}

// eslint-disable-next-line react/display-name
const TreeSelectComponent: FC<TreeSelectComponentProps> = memo(({ data, onChange }) => {
  return (
    <TreeSelectCheckbox
      style={{ maxHeight: '400px', overflowY: 'auto', zIndex: '1' }}
      checkbox
      data={data}
      onSelect={(node) => onChange(node)}
    />
  );
});

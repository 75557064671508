import { Loading } from 'components';
import Icon from 'components/icons';
import { BlueTickIcon } from 'components/icons/customeIcon';
import Modal from 'components/modal';
import ShowError from 'components/showError';
import { t_toggleValueFunc, useToggle } from 'hooks';
import useMutation from 'hooks/useMutation';
import useAdsDetails from 'pages/ads/jobAds/useAdsDetail';
import React, { FC, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, CardHeader, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { ADMIN_BAN, ADMIN_UNBAN } from 'services/graphql/queries/ads';
import SweetAlert from 'sweetalert2';
import companyDefaut from 'assets/images/buluro/dashboard/Company default.svg';
import { ADS_STATUS, ADS_STATUS_TEXT, APP_HELPER } from 'tools/enums';
import {
  For,
  This,
  ad,
  admin,
  areYouSure,
  area,
  ban,
  cancel,
  enter,
  experienceExpectation,
  fullAddress,
  please,
  postalCode,
  publishedOn,
  reasons,
  reasonsFor,
  sponsored,
  unban,
  workingDays,
  workingHours,
  yes,
} from 'tools/i18n/constants/i18n';
import { toDate, toUpperCase } from 'tools/methods';

type t_workingDaysAndHours = {
  sunday: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
};

export default function AdsDetail() {
  const { data, loading, refetch } = useAdsDetails();
  const [allowToBan, toggleAllowToBan] = useToggle(
    data?.status === ADS_STATUS.ACTIVE || data?.status === ADS_STATUS.DE_ACTIVE
  );
  const [allowToUnban, toggleAllowToUnban] = useToggle(data?.status === ADS_STATUS.ADMIN_BAN);
  const branchSection = data?.branch
    ? `${data?.branch.originalName || ''} ${data?.section?.originalName ? `/${data?.section?.originalName}` : ''}`
    : '';
  const companyName = data?.company?.name;
  const description = data?.description;
  const logoUrl = data?.company?.logUrl;
  const cityCounty = data?.address?.city
    ? `${toUpperCase(data.address.city)},${toUpperCase(data?.address?.county)}`
    : '';
  const handleToggle = async () => {
    await refetch();
    toggleAllowToBan();
  };
  useEffect(() => {
    if (data) {
      toggleAllowToBan(data?.status === ADS_STATUS.ACTIVE || data?.status === ADS_STATUS.DE_ACTIVE);
    }
  }, [data]);
  useEffect(() => {
    if (data) {
      toggleAllowToUnban(data?.status === ADS_STATUS.ADMIN_BAN);
    }
  }, [data]);
  return (
    <Loading loading={loading}>
      <Card className="bg-transparent shadow-0">
        <Header
          refetch={refetch}
          allowToBan={allowToBan}
          toggleAllowToBan={handleToggle}
          allowToUnban={allowToUnban}
          data={{
            status: data?.status,
            adId: data?._id,
            blueTick: data?.company?.blueTick,
            branchName: branchSection,
            companyName: data?.company?.name,
            logoUrl: logoUrl,
          }}
          releaseDate={data?.releaseDate}
        />
        <Body
          status={data?.status}
          isSponsored={!!data?.sponsoredDate?.length}
          categoryAndSubCategory={branchSection}
          description={description}
          companyName={companyName}
          companyLogo={logoUrl}
          cityCounty={cityCounty}
          address={data?.address?.fullAddress || ''}
          postal={data?.address?.postalCode || ''}
          experience={data?.experienceExpectation?.originalName || ''}
          days={data?.workingDaysAndHours}
          hours={
            data?.workingDaysAndHours ? `${data?.workingDaysAndHours.startAt} - ${data?.workingDaysAndHours.endAt}` : ''
          }
        />
      </Card>
    </Loading>
  );
}

interface HeaderProps {
  releaseDate?: string;
  toggleAllowToBan: t_toggleValueFunc;
  allowToBan: boolean;
  allowToUnban: boolean;
  refetch: any;
  data: {
    status?: ADS_STATUS;
    adId?: string;
    branchName?: string;
    companyName?: string;
    blueTick?: boolean;
    logoUrl?: string;
  };
}

const Header: FC<HeaderProps> = (props) => {
  const { releaseDate, data, toggleAllowToBan, allowToBan, allowToUnban, refetch } = props;
  const { t } = useTranslation();
  const [unbanExecute, { loading, error }] = useMutation(ADMIN_UNBAN);
  const [modalOpen, toggleModalOpen] = useToggle(false);
  const convertDate = useMemo(() => {
    const d = releaseDate || '';
    return d ? toDate(releaseDate as string) : '';
  }, [releaseDate]);

  const handleUnban = () => {
    SweetAlert.fire({
      title: `${t(areYouSure)} ${t(For)} ${t(unban)}`,
      icon: 'question',
      confirmButtonText: t(yes),
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed && !loading) {
        unbanExecute({
          variables: {
            adId: data.adId,
          },
        }).then((res) => {
          if (res.data) {
            refetch();
            SweetAlert.close();
          }
        });
      }
    });
  };
  return (
    <CardHeader className="bg-transparent shadow-0 p-2">
      <BanModal toggleBanned={toggleAllowToBan} data={data} isOpen={modalOpen} toggleOpen={toggleModalOpen} />
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <Icon Name="FiClock" />
          <span className="ml-2">{`${t(publishedOn)}: ${convertDate}`}</span>
        </div>
        <div>
          {allowToUnban && (
            <div className="d-flex">
              <div className="mr-2">{error && <ShowError>{error?.message}</ShowError>}</div>
              <Button disabled={loading} type="button" color="success" onClick={handleUnban}>
                <div className="d-flex align-items-center">
                  <Icon Name="FaCheck" size="15" />
                  <span className="ml-2">{`${t(unban)} ${t(This)} ${t(ad)}`}</span>
                </div>
              </Button>
            </div>
          )}
          {allowToBan && (
            <Button type="button" color="danger" onClick={() => toggleModalOpen()}>
              <div className="d-flex align-items-center">
                <Icon Name="FaRegTrashAlt" size="15" />
                <span className="ml-2">{`${t(ban)} ${t(This)} ${t(ad)}`}</span>
              </div>
            </Button>
          )}
        </div>
      </div>
    </CardHeader>
  );
};

interface BodyProps {
  status?: ADS_STATUS;
  isSponsored?: boolean;
  categoryAndSubCategory?: string;
  companyName?: string;
  companyLogo?: string;
  cityCounty?: string;
  address?: string;
  postal?: string;
  experience?: string;
  days?: t_workingDaysAndHours;
  description: string;
  hours?: string;
}
const Body: FC<BodyProps> = (props) => {
  const { t } = useTranslation();
  const {
    isSponsored,
    status,
    days,
    hours,
    categoryAndSubCategory,
    companyName,
    companyLogo,
    cityCounty,
    postal,
    address,
    description,
    experience,
  } = props;
  const convertedDays = useMemo(() => {
    let finalDays = '';
    if (days) {
      const keys = Object.keys(days);
      for (const i of keys) {
        if (typeof days[i as keyof t_workingDaysAndHours] === 'boolean' && days[i as keyof t_workingDaysAndHours]) {
          const letters = `${i[0].toUpperCase()}${i[1]}${i[2]}`;
          finalDays = finalDays.concat(`${letters}, `);
        }
      }
    }
    return finalDays.substring(0, finalDays.length - 2);
  }, [days]);
  const adsStatusClassName =
    status === ADS_STATUS.ADMIN_BAN
      ? 'shadow-bg-danger'
      : status === ADS_STATUS.DE_ACTIVE || status === ADS_STATUS.DRAFT
      ? 'shadow-bg-notActive'
      : status === ADS_STATUS.EXPIRED
      ? 'shadow-bg-warning'
      : 'shadow-bg-success';
  return (
    <CardBody className="bg-white rounded">
      <div className="d-flex flex-column">
        <div className="mb-3 d-flex align-items-center justify-content-between">
          <div>
            <div className={`${adsStatusClassName} p-1 d-flex align-items-center rounded`}>
              <Icon Name="FiActivity" />
              <b className="ml-2">
                <em>{status ? ADS_STATUS_TEXT[status] : ''}</em>
              </b>
            </div>
          </div>
          <div>
            {isSponsored && (
              <div className="shadow-bg-warning p-1 rounded">
                <b>
                  <em> {t(sponsored)} </em>
                </b>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex flex-column">
          <div className="bg-gray rounded p-3 mb-2">
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center mb-3">
                <Icon Name="FiGrid" />
                <h6 className="ml-2 mb-0">{categoryAndSubCategory}</h6>
              </div>
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center">
                  <img
                    alt={companyName}
                    src={companyLogo || companyDefaut}
                    width="25px"
                    height="25px"
                    className="rounded-circle"
                  />
                  <span className="ml-2">{companyName}</span>
                </div>
                <div className="d-flex align-items-center mt-2">
                  <div className="d-flex align-items-center">
                    <span>
                      <Icon Name="FaRegComment" />
                    </span>
                    <span className="ml-2">Description: </span>
                  </div>
                  <p className="ml-2 d-block">{description || APP_HELPER.emptyDescription}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-gray rounded p-3 mb-2">
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center mb-3">
                <Icon Name="FiMapPin" />
                <div className="d-flex ml-2 align-items-center">
                  <b>{`${t(area)}: `}</b>
                  <span className="ml-1"> {cityCounty} </span>
                </div>
              </div>
              <div className="d-flex mb-3">
                <div>
                  <Icon Name="FaRegImage" size="18px" />
                </div>
                <div className="d-flex ml-2">
                  <b className="whitespace-nowrap"> {`${t(fullAddress)}:`}</b>
                  <p className="ml-1">{address}</p>
                </div>
              </div>
              <div className="d-flex align-items-center mb-2">
                <Icon Name="FaSearchLocation" />
                <div className="d-flex ml-2 align-items-center">
                  <b>{`${t(postalCode)}: `}</b>
                  <span className="ml-1">{postal}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-gray rounded p-3">
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center mb-3">
                <Icon Name="FiBarChart" />
                <div className="d-flex ml-2 align-items-center">
                  <b>{`${t(experienceExpectation)}: `}</b>
                  <span className="ml-1">{experience}</span>
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <Icon Name="FaRegCalendar" />
                <div className="ml-2 d-flex align-items-center">
                  <b>{`${t(workingDays)}: `}</b>
                  <span className="ml-1">{convertedDays}</span>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <Icon Name="FiClock" />
                <div className="ml-2 d-flex align-items-center">
                  <b>{`${t(workingHours)}: `}</b>
                  <span className="ml-1">{hours}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CardBody>
  );
};

interface BanModalInterface {
  isOpen: boolean;
  toggleOpen: t_toggleValueFunc;
  toggleBanned: t_toggleValueFunc;
  data: {
    adId?: string;
    branchName?: string;
    companyName?: string;
    blueTick?: boolean;
    logoUrl?: string;
  };
}
const BanModal: FC<BanModalInterface> = (props) => {
  const { toggleOpen, isOpen, data, toggleBanned } = props;
  const { t } = useTranslation();
  const [deleteExecute, { loading }] = useMutation(ADMIN_BAN);
  const DEFAULT_VALUE = {
    customError: '',
    reason: '',
    adId: data?.adId,
  };
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    clearErrors,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: DEFAULT_VALUE,
  });
  const onSubmit = (submitData = DEFAULT_VALUE) => {
    console.log(submitData, data);
    clearErrors();
    setValue('customError', '');
    deleteExecute({
      variables: submitData,
    })
      .then((res) => {
        console.log(res);
        if (res.data) {
          toggleBanned();
          toggleOpen();
        } else {
          // @ts-ignore
          setValue('customError', `Error : ${res?.errors?.message as string}`);
        }
      })
      .catch((err) => {
        console.error(err);
        setValue('customError', `Error : ${err.message}`);
      });
  };
  return (
    <Modal title={`${t(admin)} ${t(ban)}`} isOpen={isOpen} toggleOpen={toggleOpen} style={{ minWidth: '50vw' }}>
      <Card className="w-100">
        <CardBody className="p-0">
          <div className="mx-auto my-3">
            {!!getValues('customError') && (
              <div className="w-100 rounded p-2 mb-3 shadow-bg-danger no-hover">{getValues('customError')}</div>
            )}
            <div className="make-center mx-auto">
              <img
                src={data.logoUrl}
                alt={data.companyName}
                width="50px"
                height="50px"
                className="rounded-circle mr-2"
              />
              <div className="d-flex flex-column">
                <h6>{data.branchName}</h6>
                <div className="d-flex">
                  <span className="mr-2">{data.companyName}</span>
                  {data.blueTick && <BlueTickIcon />}
                </div>
              </div>
            </div>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <p className="bold">
              Please Enter the reasons for Ban so that the company will be informed through the notification
            </p>

            <FormGroup>
              <Label>{`${t(reasonsFor)} ${t(ban)}`}</Label>
              <Controller
                name="reason"
                control={control}
                rules={{ required: `${t(please)} ${t(enter)} ${t(reasons)}` }}
                render={({ field: { onChange } }) => {
                  return <Input type="textarea" name="reason" onChange={onChange} />;
                }}
              />
              <FormFeedback>{errors?.reason?.message}</FormFeedback>
            </FormGroup>
            <div className="d-flex flex-column">
              <Button
                disabled={!isDirty || loading}
                role="button"
                color=""
                className="shadow-bg-danger border-0 mb-2"
                type="submit"
              >
                {`${t(ban)} ${t(This)} ${t(ad)}`}
              </Button>
              <Button onClick={() => toggleOpen()} className="border" color="transparent" type="button">
                {t(cancel)}
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Modal>
  );
};

import { Loading } from 'components';
import OnlyNumberInput from 'components/inputs/onlyNumber';
import ShowError from 'components/showError';
import useMutation from 'hooks/useMutation';
import useQuery from 'hooks/useQuery';
import PagesLayout from 'pages/components/layout';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Col, Form, FormFeedback, FormGroup, Label } from 'reactstrap';
import { ADD_AGE_RANGE, GET_SINGLE_AGE_RANGE, UPDATE_AGE_RANGE } from 'services/graphql/queries/ageRange';
import { Add } from 'tools/i18n/constants';
import {
  ageRange,
  ageRangeOfEmployees,
  created,
  end,
  record,
  save,
  start,
  successfully,
  update,
  updated,
} from 'tools/i18n/constants/i18n';
import { goBack, isNew } from 'tools/methods';

export default function AgeRangeDetail() {
  const { type } = useParams();
  const { t } = useTranslation();
  return (
    <Col xxl={8} xl={9} xs={12} className="mx-auto">
      <PagesLayout backLinkTitle={t(ageRangeOfEmployees)} backLink="ageRange" backLinkParam="/">
        <Card>
          <CardHeader className="py-3">
            <div className="d-flex align-items-center justify-content-between">
              <h5>{`${t(isNew(type) ? Add : update)} ${t(ageRange)}`}</h5>
              {/* <Button color="danger">{t(remove)}</Button> */}
            </div>
          </CardHeader>
          <CardBody>
            {isNew(type) ? <NewAgeRange isNew={true} /> : <UpdateAgeRange isNew={false} id={type as string} />}
          </CardBody>
        </Card>
      </PagesLayout>
    </Col>
  );
}

interface AgeRangeCommonProps {
  isNew: boolean;
}

function NewAgeRange(props: AgeRangeCommonProps) {
  return <AgeRangeForm isNew={props.isNew} />;
}
interface UpdateAgeRangeProps extends AgeRangeCommonProps {
  id: string;
}

function UpdateAgeRange({ id, isNew }: UpdateAgeRangeProps) {
  const { loading, data } = useQuery(GET_SINGLE_AGE_RANGE, {
    variables: {
      id,
    },
  });
  const realData = data?.employmentAgeRangeDetails;
  return (
    <Loading loading={loading}>
      <AgeRangeForm isNew={isNew} start={realData?.from} end={realData?.to} id={id} />
    </Loading>
  );
}

interface t_defaultValues {
  customError?: string;
  start?: number;
  end?: number;
  id?: string;
}

interface AgeRangeFormProps extends t_defaultValues {
  isNew: boolean;
}

function AgeRangeForm<T extends AgeRangeFormProps>(props: T) {
  const { t } = useTranslation();
  const { end: endAge, start: startAge, id, isNew } = props;

  const [createExecute, { loading }] = useMutation(ADD_AGE_RANGE);
  const [updateExecute, { loading: loadingUpdate }] = useMutation(UPDATE_AGE_RANGE);
  const DEFAULT_VALUES: t_defaultValues = {
    start: startAge,
    end: endAge,
    id,
  };
  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    setError,
    getValues,
    formState: { isDirty, errors },
  } = useForm<t_defaultValues>({
    defaultValues: DEFAULT_VALUES,
  });
  const onSubmit = (data: Omit<t_defaultValues, 'id'>) => {
    if (!StartRule(data) && !EndRule(data)) {
      createExecute({
        variables: {
          from: data.start,
          to: data.end,
        },
      })
        .then((res) => {
          if (res.data) {
            toast.success(`${t(record)} ${t(created)} ${t(successfully)}`);
            goBack();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  const onUpdate = (data: t_defaultValues) => {
    if (!StartRule(data) && !EndRule(data)) {
      updateExecute({
        variables: {
          from: data.start,
          to: data.end,
          id,
        },
      })
        .then((res) => {
          if (res.data) {
            toast.success(`${t(record)} ${t(updated)} ${t(successfully)}`);
            goBack();
          } else {
            //@ts-ignore
            setValue('customError', res.errors?.message);
          }
        })
        .catch((err) => {
          console.error(err);
          setValue('customError', err?.message);
        });
    }
  };
  const StartRule = useCallback((data: t_defaultValues) => {
    const startVal = data.start;
    const endVal = data.end;
    if (startVal && endVal && startVal > endVal) {
      setError('start', {
        type: 'max',
        message: `Start Range Must be Lower Than End Range`,
      });
      return true;
    } else {
      clearErrors('start');
      return false;
    }
  }, []);

  const EndRule = useCallback((data: t_defaultValues) => {
    const startVal = data.start;
    const endVal = data.end;
    if (startVal && endVal && startVal > endVal) {
      setError('end', {
        type: 'min',
        message: `End Range Must be Higher Than Start Range`,
      });
      return true;
    } else {
      clearErrors('end');
      return false;
    }
  }, []);
  return (
    <Form onSubmit={handleSubmit(isNew ? onSubmit : onUpdate)}>
      <FormGroup>{!!getValues('customError') && <ShowError>{getValues('customError')}</ShowError>}</FormGroup>
      <FormGroup>
        <Label>{t(start)}</Label>
        <OnlyNumberInput
          disabled={loading || loadingUpdate}
          isRequired
          makeFinalValueAsNumber={true}
          defaultValue={DEFAULT_VALUES.start}
          control={control}
          name="start"
        />
        <FormFeedback>{errors?.start?.message}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label>{t(end)}</Label>
        <OnlyNumberInput
          disabled={loading || loadingUpdate}
          makeFinalValueAsNumber={true}
          defaultValue={DEFAULT_VALUES.end}
          control={control}
          name="end"
        />
        <FormFeedback>{errors?.end?.message}</FormFeedback>
      </FormGroup>
      <Button
        type="submit"
        disabled={loading || loadingUpdate || !isDirty}
        aria-disabled={loading || loadingUpdate || !isDirty}
        color="primary"
        className="w-100"
      >
        {t(isNew ? save : update)}
      </Button>
    </Form>
  );
}

import Datepicker from 'components/forms/form-widget/datepicker';
import SectionLayout from 'pages/ads/jobAds/components/layout/sectionLayout';
import { FormProps } from 'pages/ads/jobAds/index';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { dateOfPublish, end, entrance } from 'tools/i18n/constants/i18n';

type DateOfPublishProps = FormProps;

export default function DateOfPublish(props: DateOfPublishProps) {
  const { t } = useTranslation();
  const { control } = props;
  return (
    <SectionLayout title={t(dateOfPublish)} icon="FiClock">
      <div className="p-2">
        <div className="d-flex">
          <div className="flex-grow-1 mr-1">
            <Controller
              control={control}
              name="date.from"
              render={({ field: { onChange } }) => {
                return (
                  <Datepicker
                    onSelect={(date: string) => {
                      onChange(date.split('-').reverse().join('-'));
                    }}
                    placeholder={t(entrance)}
                  />
                );
              }}
            />
          </div>
          <div className="flex-grow-1">
            <Controller
              control={control}
              name="date.to"
              render={({ field: { onChange } }) => {
                return (
                  <Datepicker
                    onSelect={(date: string) => {
                      onChange(date.split('-').reverse().join('-'));
                    }}
                    placeholder={t(end)}
                  />
                );
              }}
            />
          </div>
        </div>
      </div>
    </SectionLayout>
  );
}

import React, { memo } from 'react';
import DataTable from 'components/tables/dataTable';
import { emlakType } from 'tools/i18n/constants/i18n';
import { EMLAK_TYPE_TABLE_COLUMNS } from 'pages/content-management/emlak/types/entity';
import { useTranslation } from 'react-i18next';
import { t_Emlaktypes } from 'pages/content-management/emlak/types/type';

interface EmlakTypesTableProps {
  data: t_Emlaktypes[];
  queryLoading: boolean;
  onSearch: (key: string) => void;
}

function EmlakTypesTable(props: EmlakTypesTableProps) {
  const { data, queryLoading, onSearch } = props;
  const { t } = useTranslation();
  return (
    <DataTable
      headerTitle={`${t(emlakType)}`}
      columns={EMLAK_TYPE_TABLE_COLUMNS}
      searchable
      onSearch={onSearch}
      disabled={queryLoading}
      data={data}
    />
  );
}

export default memo(EmlakTypesTable);

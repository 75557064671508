import { DrawingManager, GoogleMap, Polygon, useLoadScript } from '@react-google-maps/api';
import { Loading } from 'components';
import SubmitButton from 'components/buttons/submitButton';
import FormActionLoading, { step } from 'components/formActionLoading';
import UploadCover from 'components/inputs/uploadCover';
import PolylineMap from 'components/polylineMap';
import { useGetStore, useMutation, useQuery, useToggle, useUpdateEffect } from 'hooks';
import PagesLayout from 'pages/components/layout';
import {
  t_specialLang,
  t_specialLocation,
  t_specialRegionCity,
  t_town,
} from 'pages/content-management/emlak/specialLocation/types';
import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Control, Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Col, Form, FormFeedback, FormGroup, Label } from 'reactstrap';
import FetchApi from 'services/api/axios';
import {
  CREATE_SPECIAL_REGIONS,
  FIND_ALL_EMLAK_CITIES,
  FIND_ALL_EMLAK_TOWN_CITIES,
  UPDATE_SPECIAL_REGIONS,
} from 'services/graphql/queries/emlak/specialRegions';
import { default as Styled, default as styled } from 'styled-components';
import { LANGUAGES } from 'tools/constants';
import { TownCity } from 'tools/i18n/constants';
import {
  add,
  body,
  city,
  created,
  description,
  enter,
  list,
  name,
  please,
  region,
  save,
  select,
  sizeExceeded,
  specialLocation,
  successfully,
  unableToUploadIcon,
  update,
  updated,
} from 'tools/i18n/constants/i18n';
import { goBack, isNew } from 'tools/methods';
import { t_errors } from 'tools/types';
import useSpecialLocationDetail from './useSpecialLocationDetail';

const BannerContainer = Styled(UploadCover)`
  width : 400px;
  min-height : 100px !important;
  max-height : 100px !important;
 
`;

type stepInfo = {
  index: number;
  pending: boolean;
  completed: boolean;
  error: boolean;
  fileName?: string;
  percent: number;
};
export default function SpecialLocationDetails() {
  const { t } = useTranslation();
  const { type } = useParams();
  const isNewType = isNew(type);
  const formRef = useRef<any>();
  const selectedNode = useGetStore().Tree.selectedNode;
  const { data } = useSpecialLocationDetail(Number(type));
  const [createExecute] = useMutation<any, Omit<t_specialLocation, 'picture'>>(CREATE_SPECIAL_REGIONS);
  const [updateExecute] = useMutation<any, Omit<t_specialLocation, 'picture'>>(UPDATE_SPECIAL_REGIONS);
  const [loading, toggleLoading] = useToggle(false);
  const [openUI, toggleUI] = useToggle(false);
  const DEFAULT_VALUES: t_specialLocation = {
    specialRegionId: data?.id,
    picture: data?.picture,
    language: data?.specialRegionLanguage
      ?.sort((a: t_specialLang) => (a.language === 'tr' ? -1 : 1))
      .map?.((item: t_specialLang) => ({
        language: item.language,
        translation: item.translation,
        descriptionTranslation: data?.description?.find((l: any) => l.language === item.language)?.translation,
      })),
    region: data?.region?.coordinates?.[0] as any,
    cityId: data?.city?.id,
    townId: data?.town?.id,
  };
  const {
    control,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    reset,
    register,
    formState: { errors },
  } = useForm<t_specialLocation>({
    defaultValues: DEFAULT_VALUES,
  });

  useEffect(() => {
    DEFAULT_VALUES.specialRegionId = data?.id;
    DEFAULT_VALUES.picture = data?.picture;
    DEFAULT_VALUES.language = data?.specialRegionLanguage
      ?.sort((a: t_specialLang) => (a.language === 'tr' ? -1 : 1))
      .map?.((item: t_specialLang) => ({
        language: item.language,
        translation: item.translation,
        descriptionTranslation: data?.description?.find((l: any) => l.language === item.language)?.translation,
      }));
    DEFAULT_VALUES.region = data?.region?.coordinates?.[0] as any;
    DEFAULT_VALUES.cityId = data?.city?.id;
    DEFAULT_VALUES.townId = data?.town?.id;
    reset({ ...DEFAULT_VALUES });
  }, [data]);

  console.log(DEFAULT_VALUES);

  const [stepsInfo, setStepsInfo] = useState<stepInfo[]>([
    {
      index: 1,
      error: false,
      percent: 0,
      pending: false,
      completed: false,
    },
    {
      index: 2,
      error: false,
      fileName: 'Cover',
      percent: 0,
      pending: false,
      completed: false,
    },
    {
      index: 3,
      error: false,
      fileName: 'PDF',
      percent: 0,
      pending: false,
      completed: false,
    },
  ]);

  const handleChangeStepInfo = useCallback((step: 1 | 2, changeKey: Partial<Record<keyof stepInfo, any>>) => {
    setStepsInfo((prev) => {
      return prev.map((info) => {
        if (info.index !== step) return info;
        return {
          ...info,
          ...changeKey,
        };
      });
    });
  }, []);
  const steps: step[] = [
    {
      index: 1,
      status: stepsInfo[0].completed
        ? 'COMPLETED'
        : stepsInfo[0].pending
        ? 'UPLOADING'
        : stepsInfo[0].error
        ? 'FAILED'
        : 'WAITING',
      title: 'Uploading Form Inputs',
      fileName: Object.keys(DEFAULT_VALUES).join(', '),
      onRetry: () => formRef.current?.props.onSubmit(),
    },
    {
      index: 2,
      title: 'Uploading Icon',
      status: stepsInfo[1].completed
        ? 'COMPLETED'
        : stepsInfo[1].pending
        ? 'UPLOADING'
        : stepsInfo[1].error
        ? 'FAILED'
        : 'WAITING',
      fileName: 'icon',
      onRetry: () => formRef.current?.props.onSubmit(),
      progressConfig: {
        percent: stepsInfo[1].percent,
      },
    },
  ];

  const onSubmit: SubmitHandler<t_specialLocation> = (data) => {
    if (!data.region || data.region?.length === 0) {
      setError('region', { message: `${t(please)} ${t(select)} ${t(region)}` });
      return;
    }
    toggleLoading(true);
    if (data.picture && data.picture !== DEFAULT_VALUES?.picture) {
      toggleUI(true);
      handleChangeStepInfo(1, { error: false, completed: false, pending: true });
    }
    const descriptionTranslations = data.language.map((item) => ({
      language: item.language,
      translate: item?.descriptionTranslation,
    }));
    Object.defineProperty(data, 'descriptionTranslations', {
      value: descriptionTranslations,
      writable: true,
      enumerable: true,
      configurable: true,
    });
    data.language = data.language.map((item) => ({
      language: item.language,
      translate: item.translation,
    }));
    createExecute({
      variables: data,
    })
      .then((res) => {
        if (res?.data?.realEstateCreateSpecialRegion) {
          handleChangeStepInfo(1, { pending: false, completed: true });
          if (data.picture && data.picture !== DEFAULT_VALUES?.picture) {
            handleChangeStepInfo(2, { error: false, completed: false, pending: true });
            const formData = new FormData();
            formData.append('file', data.picture);
            formData.append('id', res.data.realEstateCreateSpecialRegion.id);
            FetchApi({
              url: 'EMLAK_SPECIAL_REGION_PICTURE',
              method: 'PATCH',
              data: formData,
              onUploadProgress: (progressEvent) => {
                handleChangeStepInfo(2, { percent: Math.round((progressEvent.loaded * 100) / progressEvent.total) });
              },
            })
              .then((response) => {
                if (response.data) {
                  handleChangeStepInfo(2, { error: false, completed: true, pending: false });
                  toast.success(`${t(specialLocation)} ${t(successfully)} ${t(created)}`);
                  toggleLoading(false);
                  goBack();
                } else {
                  setValue('customError', t(unableToUploadIcon));
                  toggleLoading(false);
                  handleChangeStepInfo(2, { error: true, pending: false, completed: false });
                }
              })
              .catch((err) => {
                handleChangeStepInfo(2, { error: true, pending: false, completed: false });
                console.error(err);
                toggleLoading(false);
                setValue('customError', t(unableToUploadIcon));
              });
          } else {
            toast.success(`${t(specialLocation)} ${t(successfully)} ${t(created)}`);
            toggleLoading(false);
            goBack();
          }
        } else {
          handleChangeStepInfo(1, { error: true, pending: false, completed: false });
          toggleLoading(false);
          // @ts-ignore
          setValue('customError', res.errors?.message);
        }
      })
      .catch((err) => {
        handleChangeStepInfo(1, { error: true, pending: false, completed: false });
        toggleLoading(false);
        console.error(err);
        setValue('customError', err.message);
      });
  };

  const onUpdate: SubmitHandler<t_specialLocation> = (data) => {
    console.log(data);

    if (!data.region || data.region?.length === 0) {
      setError('region', { message: `${t(please)} ${t(select)} ${t(region)}` });
      return;
    }
    toggleLoading(true);
    if (data.picture && data.picture !== DEFAULT_VALUES.picture) {
      toggleUI(true);
      handleChangeStepInfo(1, { completed: false, error: false, pending: true });
    }
    const descriptionTranslations = data.language.map((item) => ({
      language: item.language,
      translate: item?.descriptionTranslation,
    }));
    Object.defineProperty(data, 'descriptionTranslations', {
      value: descriptionTranslations,
      writable: true,
      enumerable: true,
      configurable: true,
    });
    data.language = data.language.map((item) => ({
      language: item.language,
      translate: item.translation,
    }));
    updateExecute({
      variables: data,
    })
      .then((res) => {
        if (res?.data?.realEstateUpdateSpecialRegion) {
          handleChangeStepInfo(1, { completed: true, pending: false });
          if (data.picture !== DEFAULT_VALUES?.picture) {
            handleChangeStepInfo(2, { completed: false, error: false, pending: true });

            const formData = new FormData();
            formData.append('file', data.picture as string);
            formData.append('id', res.data.realEstateUpdateSpecialRegion.id);
            FetchApi({
              url: 'EMLAK_SPECIAL_REGION_PICTURE',
              method: 'PATCH',
              data: formData,
              onUploadProgress: (progressEvent) => {
                handleChangeStepInfo(2, { percent: Math.round((progressEvent.loaded * 100) / progressEvent.total) });
              },
            })
              .then((response) => {
                if (response.data) {
                  handleChangeStepInfo(2, { completed: true, error: false, pending: false });

                  toast.success(`${t(specialLocation)} ${t(successfully)} ${t(updated)}`);
                  toggleLoading(false);
                  goBack();
                } else {
                  handleChangeStepInfo(2, { error: true, pending: false, completed: false });
                  setValue('customError', response?.data?.message);
                  toggleLoading(false);
                }
              })
              .catch((err) => {
                handleChangeStepInfo(2, { error: true, pending: false, completed: false });
                console.error(err);
                setValue('customError', t(unableToUploadIcon));
                toggleLoading(false);
              });
          } else {
            toast.success(`${t(specialLocation)} ${t(successfully)} ${t(updated)}`);
            toggleLoading(false);
            goBack();
          }
        } else {
          if (data.picture && data.picture !== DEFAULT_VALUES?.picture) {
            handleChangeStepInfo(1, { error: true, pending: false, completed: false });
          }
          // @ts-ignore
          setValue('customError', res?.errors?.message);
          toggleLoading(false);
        }
      })
      .catch((err) => {
        handleChangeStepInfo(1, { error: true, pending: false, completed: false });
        toggleLoading(false);
        console.error(err);
        setValue('customError', err.message);
      });
  };

  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <PagesLayout backLinkTitle={`${t(specialLocation)} ${t(list)}`} backLink="specialLocation" backLinkParam="/">
        <FormActionLoading onClose={toggleUI} open={openUI} steps={steps} />
        <Card>
          <CardHeader>
            <h4>{`${t(isNewType ? add : update)} ${t(specialLocation)}`}</h4>
          </CardHeader>
          <CardBody>
            <Form ref={formRef} onSubmit={handleSubmit(isNewType ? onSubmit : onUpdate, console.error)}>
              <div className="d-flex">
                <div className="bg-gray flex-grow-1 mr-2">
                  <FormGroup className="flex-grow-1 bg-gray px-3 py-2 rounded-md">
                    <Controller
                      control={control}
                      defaultValue={LANGUAGES[0].value}
                      name="language.0.language"
                      render={() => (
                        <Select value={{ label: LANGUAGES[0].label, value: LANGUAGES[0].value }} isDisabled />
                      )}
                    />
                    <FormGroup className="mb-0 mt-4">
                      <Label>{`${t(specialLocation)} ${t(name)} *`}</Label>
                      <Controller
                        defaultValue={DEFAULT_VALUES?.language?.[0]?.translation}
                        rules={{
                          required: `${t(please)} ${t(enter)} ${t(name)}`,
                        }}
                        control={control}
                        name="language.0.translation"
                        render={({ field: { onChange } }) => {
                          return (
                            <input
                              {...register('language.0.translation')}
                              // defaultValue={DEFAULT_VALUES?.language?.[0]?.translation}
                              // onChange={onChange}
                              className="form-control"
                              type="text"
                              placeholder={`${t(specialLocation)} ${t(name)}`}
                            />
                          );
                        }}
                      />
                      <FormFeedback>{errors?.language?.[0]?.translation?.message}</FormFeedback>
                    </FormGroup>
                    <FormGroup className="mb-0">
                      <Label className="d-flex flex-grow-1 align-items-center justify-content-between">
                        {`${t(specialLocation)} ${t(description)} *`}
                      </Label>

                      <Controller
                        defaultValue={DEFAULT_VALUES?.language?.[0]?.descriptionTranslation}
                        rules={{
                          required: `${t(please)} ${t(enter)} ${t(body)}`,
                        }}
                        control={control}
                        name="language.0.descriptionTranslation"
                        render={({ field: { onChange } }) => {
                          return (
                            <textarea
                              defaultValue={DEFAULT_VALUES?.language?.[0]?.descriptionTranslation}
                              className="form-control"
                              placeholder={`${t(specialLocation)} ${t(description)}`}
                              onChange={onChange}
                            />
                          );
                        }}
                      />
                    </FormGroup>
                    <FormFeedback>{errors?.language?.[0]?.descriptionTranslation?.message}</FormFeedback>
                  </FormGroup>
                </div>

                <div className="bg-gray flex-grow-1 mr-2">
                  <FormGroup className="flex-grow-1 bg-gray px-3 py-2 rounded-md">
                    <Controller
                      control={control}
                      defaultValue={LANGUAGES[1].value}
                      name="language.1.language"
                      render={() => (
                        <Select value={{ label: LANGUAGES[1].label, value: LANGUAGES[1].value }} isDisabled />
                      )}
                    />
                    <FormGroup className="mb-0 mt-4">
                      <Label>{`${t(specialLocation)} ${t(name)} *`}</Label>
                      <Controller
                        defaultValue={DEFAULT_VALUES?.language?.[1]?.translation}
                        rules={{
                          required: `${t(please)} ${t(enter)} ${t(name)}`,
                        }}
                        control={control}
                        name="language.1.translation"
                        render={({ field: { onChange } }) => {
                          return (
                            <input
                              onChange={onChange}
                              className="form-control"
                              defaultValue={DEFAULT_VALUES?.language?.[1]?.translation}
                              type="text"
                              placeholder={`${t(specialLocation)} ${t(name)}`}
                            />
                          );
                        }}
                      />
                      <FormFeedback>{errors?.language?.[1]?.translation?.message}</FormFeedback>
                    </FormGroup>
                    <FormGroup className="mb-0">
                      <Label className="d-flex flex-grow-1 align-items-center justify-content-between">
                        {`${t(specialLocation)} ${t(description)} *`}
                      </Label>

                      <Controller
                        rules={{
                          required: `${t(please)} ${t(enter)} ${t(body)}`,
                        }}
                        control={control}
                        name="language.1.descriptionTranslation"
                        defaultValue={DEFAULT_VALUES?.language?.[1]?.descriptionTranslation}
                        render={({ field: { onChange } }) => {
                          return (
                            <textarea
                              defaultValue={DEFAULT_VALUES?.language?.[1]?.descriptionTranslation}
                              className="form-control"
                              placeholder={`${t(specialLocation)} ${t(description)}`}
                              onChange={onChange}
                            />
                          );
                        }}
                      />
                    </FormGroup>
                    <FormFeedback>{errors?.language?.[1]?.descriptionTranslation?.message}</FormFeedback>
                  </FormGroup>
                </div>
              </div>

              <FormGroup className="flex-grow-1 bg-gray px-3 py-2 mt-2 rounded-md">
                <CityCounty defaultCityId={data?.city} errors={errors} defaultTownId={data?.town} control={control} />
              </FormGroup>
              <FormGroup>
                <Label>Upload an image with dimensions of 1 by 5.3</Label>
                <Controller
                  control={control}
                  defaultValue={DEFAULT_VALUES?.picture}
                  name="picture"
                  render={({ field: { onChange } }) => {
                    return (
                      <BannerContainer
                        className="make-center"
                        imageContainerClassName="make-center"
                        defaultImage={DEFAULT_VALUES?.picture as string}
                        key={DEFAULT_VALUES?.picture as string}
                        onSelect={(file) => {
                          if ((file?.size as number) > 500000) {
                            setError('picture', { message: t(sizeExceeded) });
                          } else {
                            clearErrors('picture');
                            onChange(file);
                          }
                        }}
                      />
                    );
                  }}
                />
                <FormFeedback>{errors?.picture?.message}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Map
                  selectedRegions={data?.region?.coordinates}
                  onSelect={(val) => {
                    clearErrors('region');
                    setValue('region', val);
                  }}
                />
                <FormFeedback className="mt-3">{errors?.region?.message}</FormFeedback>
              </FormGroup>
              <FormGroup className="w-100">
                <SubmitButton loading={loading}>{`${t(isNewType ? save : update)}`}</SubmitButton>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      </PagesLayout>
    </Col>
  );
}

interface CityCountyProps {
  defaultCityId?: t_town;
  defaultTownId?: t_specialRegionCity;
  control: Control<t_specialLocation>;
  errors: t_errors<t_specialLocation>;
}

const CityCounty: FC<CityCountyProps> = (props) => {
  const { defaultTownId, defaultCityId, control, errors } = props;
  console.log(defaultTownId, defaultCityId);

  const [selectedCity, setSelectedCity] = useState<number | undefined>(defaultCityId?.id);
  const { t } = useTranslation();
  const { data, loading } = useQuery(FIND_ALL_EMLAK_CITIES);
  const { data: townsData, loading: townsLoading } = useQuery(FIND_ALL_EMLAK_TOWN_CITIES, {
    skip: !selectedCity,
    variables: {
      id: selectedCity,
    },
  });
  const CityOptions = useMemo(() => {
    return data?.realEstateFindAllCitiesOfCountry?.map((item: any) => {
      return {
        ...item,
        label: (item.name as string)?.toLowerCase(),
        value: item.id,
      };
    });
  }, [data]);
  const defaultCity = useMemo(() => {
    return { ...defaultCityId, label: defaultCityId?.name?.toLowerCase(), value: defaultCityId?.name };
  }, [CityOptions, defaultCityId]);
  const TownsOptions = useMemo(() => {
    return townsData?.realEstateFindAllTownsOfCity?.map((item: any) => {
      return {
        ...item,
        label: (item.name as string)?.toLowerCase(),
        value: item.id,
      };
    });
  }, [townsData, townsLoading]);
  const defaultTown = useMemo(() => {
    return { ...defaultTownId, label: defaultTownId?.name?.toLowerCase(), value: defaultTownId?.id };
  }, [TownsOptions, defaultTownId]);
  useUpdateEffect(() => {
    setSelectedCity(defaultCityId?.id);
  }, [defaultCityId]);
  return (
    <div className="d-flex align-items-center">
      <FormGroup className="flex-grow-1 mr-2 mb-0">
        <Controller
          control={control}
          rules={{
            required: `${t(please)} ${t(select)} ${t(city)}`,
          }}
          name="cityId"
          render={({ field: { onChange } }) => {
            return (
              <Select
                isSearchable
                isDisabled={loading}
                defaultValue={defaultCityId ? defaultCityId : undefined}
                placeholder={`${t(select)} ${t(city)}`}
                className="w-100 mx-2"
                options={CityOptions}
                onChange={(val: any) => {
                  setSelectedCity(val?.value as number);
                  onChange(val.value);
                }}
              />
            );
          }}
        />
        <FormFeedback>{errors?.cityId?.message}</FormFeedback>
      </FormGroup>
      <FormGroup className=" flex-grow-1 mb-0">
        <Controller
          control={control}
          rules={{
            required: `${t(please)} ${t(select)} ${t(TownCity)}`,
          }}
          // @ts-ignore
          defaultValue={defaultTownId}
          name="townId"
          render={({ field: { onChange } }) => {
            return (
              <Select
                isDisabled={!selectedCity}
                isLoading={townsLoading}
                defaultValue={defaultTownId ? defaultTown : undefined}
                placeholder={`${t(select)} ${t(city)}`}
                className="w-100 mx-2"
                options={TownsOptions}
                onChange={(val) => onChange(val?.value as unknown as string)}
              />
            );
          }}
        />
        <FormFeedback>{errors?.townId?.message}</FormFeedback>
      </FormGroup>
    </div>
  );
};

const MapContainer = styled.div`
  height: 800px;
  width: 100%;
`;

interface MapProps {
  onSelect: (number: any) => void;
  selectedRegions?: any;
}

// eslint-disable-next-line react/display-name
const Map: FC<MapProps> = memo(({ onSelect, selectedRegions }) => {
  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAP as string,
    libraries: ['drawing'],
  });
  const setMap = useState(null);
  const setDrawingManager = useState<any>(null);
  const [drawingShape, setDrawingShape] = useState<any>([]);
  const [center, setCenter] = useState({ lat: 41.0082, lng: 28.9784 });
  const [zoom, setZoom] = useState(10);
  const handleDrawingManagerLoad = (manager: any) => {
    setDrawingManager[1](manager);
  };

  const handleOverlayComplete = (e: any) => {
    // Clear the previously drawn shape
    if (Array.isArray(drawingShape)) {
      setDrawingShape(null);
    } else if (drawingShape) {
      drawingShape.setMap(null);
    }
    // Store the newly drawn shape
    setDrawingShape(e.overlay);
  };

  const clearShape = () => {
    // Clear the current shape
    setDrawingShape([]);
    onSelect(undefined);
  };
  const onLoad = useCallback(function callback(map: any) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map?.fitBounds?.(bounds);
    setMap[1](map);
  }, []);
  const onUnmount = useCallback(function callback() {
    setMap[1](null);
  }, []);

  const onPolygonComplete = (polygon: any) => {
    const coordinate: any = [];
    // const path = polygon.getPath();
    // iterate over each point in the path

    polygon.forEach(function (point: any, index: any) {
      // get the latitude and longitude of the point
      // const lat = point.lat;
      // const lng = point.lng;
      coordinate.push([point.lng, point.lat]);
      // do something with the latitude and longitude
      // console.log('Vertex ' + index + ': (' + lat + ', ' + lng + ')');
    });
    // setDrawingShape(coordinate);
    onSelect(coordinate);
  };
  const options = {
    fillColor: 'rgba(0,0,0,0.5)',
    fillOpacity: 1,
    strokeColor: '#e20000',
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1,
  };
  /*const makeDefaultLatLon = useMemo(() => {
    const coordinates: { lat: number; lng: number }[] = [];
    if (selectedRegions) {
      selectedRegions?.[0]?.forEach((item) => {
        coordinates.push({ lat: item[0], lng: item[1] });
      });
    }
    return coordinates;
  }, [selectedRegions]);*/
  useEffect(() => {
    if (selectedRegions) {
      const coordinates: number[][] = [];
      selectedRegions?.[0]?.forEach((item: any) => {
        coordinates.push([item[1], item[0]]);
      });
      setDrawingShape(coordinates);
    }
  }, [selectedRegions]);
  useEffect(() => {
    if (selectedRegions && drawingShape?.length) {
      setTimeout(() => {
        if (isLoaded) {
          setCenter(drawingShape?.[0]);
          // setZoom(18);
        }
      }, 1000);
    }
  }, [selectedRegions, drawingShape, isLoaded]);

  return (
    <Loading loading={!isLoaded}>
      <div className="d-flex flex-column">
        {/* <div className="ml-auto">
          <Button color="primary" className="w-auto d-block mb-3" onClick={clearShape}>
            Clear Shapes
          </Button>
        </div> */}

        <PolylineMap
          center={center}
          zoom={zoom}
          drawingShape={drawingShape}
          onCreated={(value) => onPolygonComplete(value)}
          key={drawingShape}
          onDeleted={() => clearShape()}
        />

        {/* <MapContainer>
          <GoogleMap
            mapContainerStyle={{ height: '800px', width: '100%' }}
            mapContainerClassName="map-container"
            center={center}
            onLoad={onLoad}
            onUnmount={onUnmount}
            zoom={zoom}
          >
            {!!drawingShape?.length && <Polygon path={drawingShape} options={options} />}
            <DrawingManager
              onLoad={handleDrawingManagerLoad}
              onOverlayComplete={handleOverlayComplete}
              options={{
                drawingControl: true,
                drawingControlOptions: {
                  // @ts-ignore
                  drawingModes: ['polygon'],
                },
              }}
              onPolygonComplete={onPolygonComplete}
            />
          </GoogleMap>
        </MapContainer> */}
      </div>
    </Loading>
  );
});

import { ApolloError, useMutation } from '@apollo/client';
import { PasswordInput, PhoneNumberInput } from 'components/inputs';
import MultiCodeInput from 'components/inputs/multiCode';
import ShowError from 'components/showError';
import Timer from 'components/timer';
import { useUpdateEffect } from 'hooks';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Col, Form, FormFeedback, FormGroup, TabContent } from 'reactstrap';
import { Q_LOGIN_STEP_1, Q_LOGIN_STEP_2 } from 'services/graphql/queries/signin/signin.graphql';
import { d_loginFail } from 'store/user/user.dispatchs';
import { DEFAULT_COUNTRY } from 'tools/constants';
import {
  enterPhoneNumber,
  forgotPassword,
  loading as loadingtxt,
  login,
  loginSubTitle,
  otpCode,
  password,
  phoneNumber,
  pleaseCheckPassword,
  requestSent,
  restore,
  sendOtpRequest,
  verificationCode,
  welcomeBack,
} from 'tools/i18n/constants/i18n';
import { errorFinder, logout, setCookie } from 'tools/methods';
import { t_country } from 'tools/types';

interface defaultValues {
  customError?: string;
  phoneNumber: string;
  country: t_country;
  password: string;
  otpCode: string;
}

export default function Signing() {
  const { t } = useTranslation();
  const [loginStep, setLoginStep] = useState(1);
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<defaultValues>({
    defaultValues: {
      phoneNumber: '',
      country: DEFAULT_COUNTRY,
      password: '',
      otpCode: '',
    },
  });
  const [execute, { data, loading }] = useMutation(Q_LOGIN_STEP_1);
  const [execute2, { data: data2, loading: loading2 }] = useMutation(Q_LOGIN_STEP_2);
  const [req, setReq] = useState(false);
  const navigate = useNavigate();
  const onSubmit = (data: defaultValues) => {
    setValue('customError', '');
    if (loginStep === 1) {
      execute({
        variables: {
          pc: data.country.value,
          pn: data.phoneNumber.toString(),
          p: data.password,
        },
      })
        .then()
        .catch(handleError);
    } else {
      execute2({
        variables: {
          pc: data.country.value,
          pn: data.phoneNumber.toString(),
          c: data.otpCode,
        },
      })
        .then()
        .catch(handleError);
    }
  };

  const handleError = (err?: ApolloError) => {
    clearErrors();
    setValue('customError', errorFinder(err));
    d_loginFail();
  };

  const onInvalid = (err: any) => {
    clearErrors();
    console.log(err);
  };
  const isFirst = loginStep === 1;
  const handleReq = () => {
    setReq((prev) => !prev);
  };

  useUpdateEffect(() => {
    if (data && loginStep === 1) {
      console.log(data);
      if (data.login.token) {
        setCookie({ name: 'token', value: data.login.token });
        navigate(`reset-password/tk=${data.login.token}`);
      } else {
        setLoginStep(2);
      }
    } else if (data2?.validateCode) {
      logout();
      const v = data2?.validateCode;
      if (v.message === 'successfully_done') {
        setCookie({
          name: 'token',
          value: v.token,
        });
        setCookie({
          name: 'refreshToken',
          value: v.refreshToken,
        });
        window.location.reload();
      }
    }
  }, [data, data2, req]);
  return (
    <Col xs="12">
      <div className="login-card">
        <div>
          <div className="login-main login-tab">
            <TabContent className={`content-login h-100`}>
              <Form
                onSubmit={handleSubmit(onSubmit, onInvalid)}
                className="d-flex flex-column justify-content-between h-100 theme-form d-block"
              >
                <div>
                  {!!getValues('customError') && (
                    <FormGroup>
                      <ShowError>{getValues('customError')}</ShowError>
                    </FormGroup>
                  )}
                  <h4>{isFirst ? t(welcomeBack) : t(verificationCode)}</h4>
                  <p>
                    {isFirst
                      ? t(loginSubTitle)
                      : `${t(requestSent)} ${getValues('country').value}${getValues('phoneNumber')}`}
                  </p>

                  {isFirst ? (
                    <>
                      <div className="mb-3">
                        <FormGroup>
                          <PhoneNumberInput
                            onPhoneNumberChange={(val) => setValue('phoneNumber', val)}
                            onCountryChange={(val) => setValue('country', val)}
                            placeholder={t(enterPhoneNumber)}
                            name={phoneNumber}
                            errorMessage={errors[phoneNumber]?.message}
                            label={phoneNumber}
                          />
                        </FormGroup>
                      </div>

                      <FormGroup>
                        <Controller
                          name={password}
                          control={control}
                          rules={{ required: t(pleaseCheckPassword) }}
                          render={({ field: { onChange } }) => (
                            <PasswordInput
                              name={password}
                              onChange={onChange}
                              errorMessage={errors[password]?.message}
                            />
                          )}
                        />
                      </FormGroup>
                    </>
                  ) : (
                    <>
                      <div className="mb-3">
                        <FormGroup>
                          <Controller
                            name={otpCode}
                            control={control}
                            rules={{ required: 'Please Enter OTP code' }}
                            render={({ field: { onChange } }) => {
                              return <MultiCodeInput onFinish={(val) => onChange(val)} />;
                            }}
                          />
                          <div className="mt-5 mx-auto text-center flex-grow-1">
                            <Timer onRequestAgain={handleReq} />
                          </div>
                          <FormFeedback
                            className={`${errors[otpCode]?.message ? 'd-block' : ''} m-2`}
                            aria-invalid={!!errors[otpCode]?.message}
                          >
                            {errors[otpCode]?.message}
                          </FormFeedback>
                        </FormGroup>
                      </div>
                    </>
                  )}
                  {isFirst && (
                    <div className="login-btn position-relative mb-0">
                      <div className="text-left">
                        <span>{`${t(forgotPassword)} ?`}</span>
                        <Link className="link text-decoration-underline top-0 mr-auto" to="/forget-password">
                          {t(restore)}
                        </Link>
                      </div>
                    </div>
                  )}
                </div>

                <Button
                  type="submit"
                  className={`login-btn mt-auto d-block ${isFirst ? 'w-100' : ''}`}
                  color="primary"
                  disabled={loginStep === 1 ? loading : loading2}
                >
                  {loading ? `${t(loadingtxt)}...` : isFirst ? t(sendOtpRequest) : t(login)}
                </Button>
              </Form>
            </TabContent>
          </div>
        </div>
      </div>
    </Col>
  );
}

import { useToggle, useUpdateEffect } from '../../../../hooks';
import { TimePickerContainer } from '../styles';
import Timepicker from '../../../../components/forms/form-widget/timepickerComponent/timepicker';
import React, { useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';

interface PickerProps {
  onChange: (value: string) => void;
  isDateSelected: boolean;
  defaultTime: string;
  isStart: boolean;
  isDateChanged: string | undefined;
}

export default function Picker({ onChange, isDateSelected, defaultTime, isStart, isDateChanged }: PickerProps) {
  const [open, toggleOpen] = useToggle(false);
  const [openPopover, toggleOpenPopover] = useToggle(false);
  const [value, setValue] = useState<string | undefined>(undefined);
  const clockWrapperRef = useRef<HTMLDivElement | null>(null);
  const popoverRef = useRef<any | null>(null);
  const pickerId = `picker-${isStart ? 'start' : 'end'}`;
  const handleClickOutside = () => {
    toggleOpen(false);
  };
  const handleChange = (value: string) => {
    setValue(value);
    onChange(value);
  };
  const handleOpenPicker = () => {
    if (isDateSelected) {
      toggleOpen(true);
    }
  };
  const handleClosePopOver = () => {
    toggleOpenPopover(false);
  };
  useUpdateEffect(() => {
    if (!value && isDateSelected) {
      setValue(defaultTime);
      onChange(defaultTime);
    }
  }, [isDateSelected]);
  useEffect(() => {
    if (isDateSelected && defaultTime) {
      setValue(defaultTime);
      onChange(defaultTime);
    }
  }, [defaultTime, isDateSelected]);
  useUpdateEffect(() => {
    if (!isDateSelected) {
      setValue(undefined);
      toggleOpenPopover(true);
    }
  }, [isDateSelected]);
  useUpdateEffect(() => {
    if (value) {
      toggleOpenPopover(false);
    }
  }, [value]);
  useUpdateEffect(() => {
    if (value) {
      onChange(value);
    }
  }, [isDateChanged]);
  useOnClickOutside(clockWrapperRef, handleClickOutside);
  useOnClickOutside(popoverRef, handleClosePopOver);
  return (
    <TimePickerContainer onClick={handleOpenPicker}>
      {open && (
        <div id="clock-wrapper" ref={clockWrapperRef}>
          <Timepicker onChange={handleChange} defaultTime={defaultTime} />
        </div>
      )}
      {value && <span className="d-block"> {value} </span>}
      {!isDateSelected && (
        <Popover flip isOpen={openPopover} placement="bottom" target={pickerId} trigger="click" ref={popoverRef}>
          <PopoverHeader>Select a Date!</PopoverHeader>
          <PopoverBody>You Have To Select a Date First</PopoverBody>
        </Popover>
      )}
      {(!value || !isDateSelected) && (
        <div id={pickerId} role="button" className="my-auto px-3" onClick={() => toggleOpenPopover()}>
          ----
        </div>
      )}
    </TimePickerContainer>
  );
}

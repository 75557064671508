import React, { FC, useMemo, useState } from 'react';
import PagesLayout from 'pages/components/layout';
import { ads, bazzarAds, detail, statistics } from 'tools/i18n/constants/i18n';
import { useTranslation } from 'react-i18next';
import { Button, Col } from 'reactstrap';
import BazaarAdsDetail from 'pages/ads/bazzarAds/bazzarAdsDetail';
import BazaarAdsStatistics from 'pages/ads/bazzarAds/bazzarAdsStatistics';
import { goBack } from 'tools/methods';

type sections = 1 | 2;
export default function BazzarAdsController() {
  const { t } = useTranslation();
  const [currentSection, setCurrentSection] = useState<sections>(1);
  const Components: Record<sections, { Element: JSX.Element }> = useMemo(() => {
    return {
      '1': {
        Element: <BazaarAdsDetail />,
      },
      '2': {
        Element: <BazaarAdsStatistics />,
      },
    };
  }, [currentSection]);
  return (
    <PagesLayout
      backLinkTitle={t(bazzarAds)}
      onBackBtn={() => {
        goBack();
      }}
    >
      <div className="d-flex flex-column">
        <Header currentSection={currentSection} setCurrentSection={setCurrentSection} />
      </div>
      <main>
        <Col xs={12} xxl={11} className="mx-auto">
          {Components[currentSection].Element}
        </Col>
      </main>
    </PagesLayout>
  );
}
interface HeaderProps {
  currentSection: sections;
  setCurrentSection: React.Dispatch<React.SetStateAction<sections>>;
}
const Header: FC<HeaderProps> = ({ currentSection, setCurrentSection }) => {
  const { t } = useTranslation();
  return (
    <header className="mb-5">
      <div className="d-flex">
        <Button
          className="mr-1"
          onClick={() => setCurrentSection(1)}
          color={currentSection === 1 ? 'primary' : 'secondary'}
        >
          {`${t(ads)} ${t(detail)}`}
        </Button>
        <Button
          className="mr-1"
          onClick={() => setCurrentSection(2)}
          color={currentSection === 2 ? 'primary' : 'secondary'}
        >
          {t(statistics)}
        </Button>
      </div>
    </header>
  );
};

import { gql } from '@apollo/client';

export const GET_EXPERIENCE_EXPECTATION = gql`
  query getExperienceExpectation($page: Int!, $size: Int!) {
    employmentExperienceExpectationsList(paginationDto: { page: $page, size: $size }) {
      createdAt
      updatedAt
      _id
      originalName
      translations {
        language
        translate
      }
    }
  }
`;

export const CREATE_EXPERIENCE_EXPECTATION = gql`
  mutation createExperienceExpectation($translations: [TranslationsDto!]) {
    employmentCreateExperienceExpectation(originalNameTranslationsDto: { translations: $translations }) {
      _id
    }
  }
`;

export const UPDATE_EXPERIENCE_EXPECTATION = gql`
  mutation updateExperienceExpectation($translations: [TranslationsDto!], $id: String!) {
    employmentUpdateExperienceExpectation(idOriginalNameTranslationsDto: { translations: $translations, id: $id }) {
      _id
    }
  }
`;

export const REJECT_EXPERIENCE_EXPECTATION = gql`
  mutation rejectExperienceExpectation($translations: [TranslationsDto!], $originalText: String!) {
    employmentCreateWorkExperienceRejectReason(
      employmentCreateWorkExperienceRejectReasonDto: { originalText: $originalText, translations: $translations }
    ) {
      _id
    }
  }
`;

import useQuery from 'hooks/useQuery';
import { GET_SINGLE_MAGAZINE_EDITION } from 'services/graphql/queries/magazine';
import { useParams } from 'react-router-dom';

export default function useMagazineEditionDetail() {
  const { id } = useParams();
  const { data, loading, error } = useQuery(GET_SINGLE_MAGAZINE_EDITION, {
    variables: {
      id: parseInt(id as string),
    },
  });

  return { data: data?.FindOneMagazineEdition, loading, error };
}

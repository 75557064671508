import OnlyNumberInput from 'components/inputs/onlyNumber';
import { useToggle } from 'hooks';
import { FilterAdsCommonProps } from 'pages/ads/bazzarAds/filter/index';
import SectionLayout from 'pages/ads/jobAds/components/layout/sectionLayout';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Styled from 'styled-components';
import { maximum, minimum, price } from 'tools/i18n/constants/i18n';

enum priceNameEnum {
  TL = 'Tl',
  EUR = 'EUR',
  USD = 'USD',
}
/* const StyledCheckbox = Styled(Input)`
  width: 20px;
  height: 20px;
`; */

const StyledUnitWrapper = Styled.div`
  width: 80px;
  height: 50px
`;

type BazaarAdsPriceProps = FilterAdsCommonProps;

export default function BazaarAdsPrice(props: BazaarAdsPriceProps) {
  const { t } = useTranslation();
  const { control, setValue } = props;
  const [disablePrice] = useToggle(false);
  const [selectedPrice, setSelectedPrice] = useState<priceNameEnum | undefined>(undefined);
  const handleClick = (key: priceNameEnum) => {
    if (!disablePrice) {
      setSelectedPrice((prev) => {
        if (prev === key) {
          setValue?.('unit', undefined);
          return undefined;
        } else {
          setValue?.('unit', key.toLowerCase());
          return key;
        }
      });
    }
  };
  /*  const handleToggleNoPrice = () => {
    toggleDisable();
    if (!disablePrice) setValue?.('onlyWithNoPrice', !disablePrice);
    else {
      setValue?.('onlyWithNoPrice', undefined);
      setSelectedPrice(undefined);
    }
    resetField?.('priceMax');
    resetField?.('priceMin');
    resetField?.('unit');
  }; */
  return (
    <SectionLayout title={t(price)} icon="AiOutlineMoneyCollect">
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center justify-content-between">
          <div className="flex-grow-1 mr-2">
            <OnlyNumberInput
              disabled={disablePrice}
              placeholder={t(minimum)}
              defaultValue={undefined}
              makeFinalValueAsNumber
              control={control}
              name="priceMin"
              value={disablePrice ? '' : undefined}
            />
          </div>
          <div className="flex-grow-1">
            <OnlyNumberInput
              disabled={disablePrice}
              defaultValue={undefined}
              placeholder={t(maximum)}
              makeFinalValueAsNumber
              control={control}
              name="priceMax"
              value={disablePrice ? '' : undefined}
            />
          </div>
        </div>
        <div className="mt-2">
          <div className="d-flex">
            <StyledUnitWrapper
              onClick={() => handleClick(priceNameEnum.TL)}
              role="button"
              className={`mr-2 rounded-md p-3 text-center ${
                disablePrice
                  ? 'bg-gray border'
                  : selectedPrice === priceNameEnum.TL
                  ? 'shadow-bg-active border-primary'
                  : 'border-light-gray txt-dark'
              } `}
            >
              TL ₺
            </StyledUnitWrapper>
            <StyledUnitWrapper
              onClick={() => handleClick(priceNameEnum.EUR)}
              role="button"
              className={`mr-2 rounded-md p-3 text-center  ${
                disablePrice
                  ? 'bg-gray border'
                  : selectedPrice === priceNameEnum.EUR
                  ? 'shadow-bg-active border-primary'
                  : 'border-light-gray txt-dark'
              } `}
            >
              EUR €
            </StyledUnitWrapper>
            <StyledUnitWrapper
              onClick={() => handleClick(priceNameEnum.USD)}
              role="button"
              className={`mr-2 rounded-md p-3 text-center  ${
                disablePrice
                  ? 'bg-gray border'
                  : selectedPrice === priceNameEnum.USD
                  ? 'shadow-bg-active border-primary'
                  : 'border-light-gray txt-dark'
              } `}
            >
              USD $
            </StyledUnitWrapper>
          </div>
        </div>
        {/*   <FormGroup className="mt-3">
          <StyledCheckbox
            type="checkbox"
            onChange={() => {
              handleToggleNoPrice();
            }}
          />

          <Label className="mb-0 ml-2">No Price</Label>
        </FormGroup>  <FormGroup className="mt-3">
          <StyledCheckbox
            type="checkbox"
            onChange={() => {
              handleToggleNoPrice();
            }}
          />

          <Label className="mb-0 ml-2">No Price</Label>
        </FormGroup> */}
      </div>
    </SectionLayout>
  );
}

import React, { ReactNode } from 'react';
import Icon, { IconsName } from 'components/icons';
import HorizontalLine, { HorizontalLineProps } from 'components/horizontalLine';

interface SectionLayoutProps extends HorizontalLineProps {
  title: string;
  icon: IconsName;
  children?: ReactNode;
}

export default function SectionLayout(props: SectionLayoutProps) {
  const { children, title, icon, className, lineHeight } = props;
  return (
    <div className="d-flex flex-column mb-3">
      <div className="d-flex align-items-center mb-2">
        <div className="d-flex align-items-center">
          <Icon Name={icon} size="18px" />
          <span className="h6 mx-2 mt-1">{title}</span>
        </div>
        <HorizontalLine className={className} lineHeight={lineHeight} />
      </div>
      {children}
    </div>
  );
}

import { gql } from '@apollo/client';

export const GET_BAN_REASONS_LIST = gql`
  query GetBanReasonList {
    getAllBanReason {
      id
      needToInsertCustomText
      language {
        id
        language
        content
      }
    }
  }
`;

export const GET_ONE_BAN_REASON = gql`
  query GetOneDeleteReasons($id: Float!) {
    getBanReasonById(id: $id) {
      id
      needToInsertCustomText
      language {
        id
        language
        content
      }
    }
  }
`;

export const ADD_BAN_REASON = gql`
  mutation AddBanReasons($needToInsertCustomText: Boolean!, $language: [CreateBanReasonLanguageDto!]!) {
    createBanReason(data: { needToInsertCustomText: $needToInsertCustomText, language: $language })
  }
`;

export const UPDATE_BAN_REASON = gql`
  mutation UpdateBanReasons($needToInsertCustomText: Boolean!, $id: Int!, $language: [UpdateBanReasonLanguageDto!]!) {
    updateBanReason(data: { id: $id, needToInsertCustomText: $needToInsertCustomText, language: $language })
  }
`;

import DataTables, { TableSectionTitle } from 'components/tables/dataTable';
import { history } from 'tools/i18n/constants/i18n';
import React from 'react';
import { t_user } from 'pages/user-management/users/type';
import { toDate } from 'tools/methods';
import { APP_HELPER } from 'tools/enums';
import { useQuery } from 'hooks';
import { BAN_HISTORY } from 'services/graphql/queries/users/users.geaphql';
import { Loading } from 'components';
import { useTranslation } from 'react-i18next';
import { t_languageKeys } from 'tools/types';
import useLanguage from 'hooks/useLanguage';

interface BanHistoryProps {
  open: boolean;
  onClose: VoidFunction;
  user: t_user;
}

export enum UserBanType {
  BAN = 'BAN',
  UN_BAN = 'UN_BAN',
  DELETED_BY_ADMIN = 'DELETED_BY_ADMIN',
}

type banHistory = {
  id: number;
  status: UserBanType;
  adminId: number;
  createdAt: string;
  insertedReason: string;
  adminName: string;
  banReason: {
    id: number;
    needToInsertCustomText: boolean;
    language: {
      language: t_languageKeys;
      content: string;
    }[];
  };
};

export default function BanHistoryModal({ user }: BanHistoryProps) {
  const { t } = useTranslation();
  const { currentLanguage } = useLanguage();
  const { loading, data, error } = useQuery(BAN_HISTORY, {
    skip: !user,
    variables: {
      id: user?.userId,
    },
  });
  const columnsData = [
    {
      name: <TableSectionTitle name="FiUser" title="Operator" />,
      cell: (r: banHistory) => <span title={r.adminName}>{r.adminName}</span>,
    },
    {
      name: <TableSectionTitle name="AiOutlineDeleteRow" title="Deleted Date" />,
      cell: (r: banHistory) => <span>{toDate(r.createdAt)}</span>,
    },
    {
      name: <TableSectionTitle name="AiOutlineBlock" title="Type" />,
      cell: (r: banHistory) =>
        r.status === UserBanType.DELETED_BY_ADMIN
          ? 'Deleted By Admin'
          : r.status === UserBanType.BAN
          ? 'Blocked By Admin'
          : 'UnBlocked By Admin',
    },
    {
      name: <TableSectionTitle name="AiFillFileText" title="Reason" />,
      minWidth: '200px',
      cell: (r: banHistory) => {
        if (r.insertedReason) {
          return <p className="max-w-100">{r.insertedReason}</p>;
        } else if (r.banReason?.language?.find((item) => item.language === currentLanguage)?.content) {
          return <p>{r.banReason?.language?.find((item) => item.language === currentLanguage)?.content}</p>;
        } else return APP_HELPER.emptyDescription;
      },
    },
  ];
  return (
    <Loading loading={loading}>
      <DataTables
        data={data?.findUserBanHistory || []}
        headerTitle={`${user?.companyName || user?.fullName} Block ${t(history)}`}
        columns={columnsData}
        pagination={false}
        disabled={loading || !!error}
      />
    </Loading>
  );
}

import React, { useCallback, useMemo } from 'react';
import { Button, Form, FormFeedback, FormGroup, Label, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { EmailInput } from 'components/inputs';
import SubmitButton from 'components/buttons/submitButton';
import { useToggle } from 'hooks';
import Counter from './counter';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { CHANGE_USER_EMAIL } from 'services/graphql/queries/users/users.geaphql';
import { errorFinder, toArray } from 'tools/methods';
import { t_company } from 'tools/types/sectionTypes/company';

interface ChangeInfoModalProps {
  open: boolean;
  user: t_company;
  adminId: number;
  onClose: VoidFunction;
  refetch: any;
  setCount: (value: number) => void;
  setData: (data: unknown[]) => void;
}

export default function ChangeInfoModal({ onClose, refetch, user, open, setData, setCount }: ChangeInfoModalProps) {
  console.log(user);
  const [changeEmailExecute, { loading: emailLoading }] = useMutation(CHANGE_USER_EMAIL);
  const INITIAL_VALUES: { email: string | undefined; phoneNumber: string | undefined; phoneCode: string | undefined } =
    {
      email: undefined,
      phoneNumber: undefined,
      phoneCode: undefined,
    };
  const [isCounterOpen, toggleCounterOpen] = useToggle(false);
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<typeof INITIAL_VALUES>({
    defaultValues: INITIAL_VALUES,
  });
  const onSubmit = useCallback(() => {
    let submitData: typeof INITIAL_VALUES | undefined = undefined;
    function saveInfo(data: typeof INITIAL_VALUES) {
      if (data.phoneNumber?.startsWith('0')) {
        setError('phoneNumber', { message: 'Phone Number Cannot Start with 0' });
        return;
      }
      submitData = data;
      toggleCounterOpen(true);
    }
    function finalSubmit() {
      changeEmailExecute({
        variables: {
          email: submitData?.email,
          userId: user?.userId?.[0],
        },
      })
        .then((result) => {
          console.log(result);
          if (result.data) {
            refetch().then((res: any) => {
              console.log(res);
              setData(toArray(res?.data.getCompanyListAndDetails?.listResponse?.data));
              setCount(res?.data.getCompanyListAndDetails?.listResponse?.count);
            });
            toast.success('Updated Successfully');
            onClose();
          } else {
            toast.error(errorFinder(result?.errors?.[0].message));
          }
        })
        .catch((error) => {
          toast.error(errorFinder(error));
        });
    }
    return {
      saveInfo,
      finalSubmit,
    };
  }, []);
  const submitRef = useMemo(() => onSubmit(), []);
  return (
    <>
      <Counter
        saveOnCounter
        onFinishCounter={submitRef.finalSubmit}
        isOpen={isCounterOpen}
        toggleOpen={toggleCounterOpen}
      />
      <ModalHeader>
        <h5 className="mb-0">{`Edit ${user?.companyName || user?.fullName}'s Login Email`}</h5>
        <h6 className="mt-4">
          {`By changing user login Email, user will not be able to login with the old Email (${user?.registrationEmail}) anymore and all the active sessions of this user will be logged out.`}
        </h6>
      </ModalHeader>

      <Form onSubmit={handleSubmit(submitRef.saveInfo)}>
        <ModalBody>
          <FormGroup>
            <Label htmlFor="Email">Email</Label>
            <EmailInput
              required
              id="email"
              placeholder="Email"
              control={control}
              defaultValue={user?.registrationEmail || undefined}
              name="email"
            />
            <FormFeedback>{errors?.email?.message}</FormFeedback>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex align-items-center">
            <SubmitButton loading={emailLoading} className="mr-1">
              Save Changes
            </SubmitButton>
            <Button type="button" onClick={onClose} className="ml-1">
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </>
  );
}

import { useUpdateEffect } from 'hooks';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { city, please, select } from 'tools/i18n/constants/i18n';
import { t_city, t_select } from 'tools/types';

export default function CountyDropdown(props: t_select<string>) {
  const { onSelect, selectedCity, defaultValue, placeholder, className } = props;
  const { t } = useTranslation();
  const selectRef = useRef<any>();

  const makeCountyOption = useMemo(() => {
    const OPTIONS =
      typeof selectedCity === 'string'
        ? typeof defaultValue === 'string'
          ? [{ label: defaultValue?.toUpperCase(), value: defaultValue }]
          : (defaultValue as string[])?.map((c) => ({ label: c.toUpperCase(), value: c }))
        : (selectedCity as t_city)?.counties.map((c) => ({
            label: c.toUpperCase(),
            value: c,
          }));
    return OPTIONS?.map((item) => ({ ...item, label: item?.label?.replace('_', '') }));
  }, [selectedCity, defaultValue]);

  useUpdateEffect(() => {
    if (selectedCity && selectRef.current) {
      selectRef.current.clearValue();
      onSelect?.(undefined);
    }
  }, [selectedCity]);

  return (
    <Select
      className={className}
      isDisabled={!selectedCity}
      ref={selectRef}
      onChange={(val) => {
        onSelect?.(val?.value as string);
      }}
      defaultValue={defaultValue ? { label: (defaultValue as string)?.toUpperCase(), value: defaultValue } : undefined}
      name="county"
      options={makeCountyOption}
      placeholder={selectedCity ? placeholder : `${t(please)} ${select} ${t(city)} `}
    />
  );
}

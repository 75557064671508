import useQuery from 'hooks/useQuery';
import { GET_BLUE_MARK_BY_USER_ID, GET_BLUE_TICK } from 'services/graphql/queries/companies';
import { useParams } from 'react-router-dom';
import { realId } from 'pages/content-management/company/companies/useDetails';
import { t_company } from 'tools/types/sectionTypes/company';

export default function useBlueTickRequest() {
  const { type } = useParams();
  const isRealId = !!realId(type)?.id;
  console.log('isRealId : ', isRealId);
  const { error, loading, data, refetch } = useQuery(isRealId ? GET_BLUE_TICK : GET_BLUE_MARK_BY_USER_ID, {
    variables: {
      ...realId(type),
    },
  });
  return {
    data: (data?.getCompanyListAndDetails?.singleResponse || data?.getCompanyDetailsByUserId) as t_company,
    error,
    loading,
    refetch,
  };
}

import { gql } from '@apollo/client';

export const Q_LOGIN_STEP_1 = gql`
  mutation loginHandler(
    $pn: String!
    $pc: String!
    $p: String!
  ) {
    login(
       loginDto : {
        phoneNumber: $pn,
        phoneCode: $pc,
        password: $p
       }
    ) {
      message,
      refreshToken,
      token
    }
  }
`;

export const Q_LOGIN_STEP_2 = gql`
  mutation loginHandler2(
    $pc : String!
    $pn : String!
    $c : String!
  ) {
    validateCode (
      OtpDto : {
        phoneCode : $pc
        phoneNumber : $pn
        code : $c
      }
    ) {
      token,
      refreshToken,
      message
    }
  }
`
import { dataToTree } from 'components/tree-select-checkbox/utils';
import { useUpdateEffect } from 'hooks';
import useQuery from 'hooks/useQuery';
import { useMemo } from 'react';
import { GET_LANGUAGES } from 'services/graphql/queries/languages';
import { d_getTreeData } from 'store/tree/tree.dispatchs';
import { APP_HELPER } from 'tools/enums';
import { isTrophy, toArray } from 'tools/methods';
import { t_language } from './types';

export default function useLanguages() {
  const { data, loading, error } = useQuery<{ employmentLanguagesList: t_language[] }>(GET_LANGUAGES, {
    variables: {
      page: 1,
      size: APP_HELPER.treeMaxSize,
    },
  });
  const isListEmpty: boolean = useMemo(() => isTrophy(data?.employmentLanguagesList?.length), [data]);
  const updatedData = useMemo(
    () =>
      isListEmpty
        ? dataToTree(toArray(data?.employmentLanguagesList), {
            id: '_id',
            labelKey: 'name',
            iconKey: 'imageUrl',
            childKey: 'sections',
          })
        : [],
    [data, isListEmpty]
  );

  useUpdateEffect(() => {
    if (isTrophy(updatedData)) {
      d_getTreeData(updatedData);
    }
  }, [updatedData]);
  return { isListEmpty, data: updatedData, error, loading };
}

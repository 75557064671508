import SubmitButton from 'components/buttons/submitButton';
import Icon from 'components/icons';
import OnlyCharInput from 'components/inputs/onlyCharacter';
import ShowError from 'components/showError';
import useMutation from 'hooks/useMutation';
import { newComponentsProps } from 'pages/user-management/employer/index';
import React, { FC, memo, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Col, Form, FormGroup, Input, Label } from 'reactstrap';
import { ADD_SOCIAL_MEDIA } from 'services/graphql/queries/companies/createCompany.graphql';
import { socialMediaName } from 'tools/enums';
import { save, update, updated } from 'tools/i18n/constants/i18n';
import { customError, numberish, stringish } from 'tools/types';
import { formsNameEnum } from './types';

type socialMedia = { id: numberish; name: stringish; url: stringish };

type dontHaveSocialMedia = {
  dontHaveASocialMedia: boolean | undefined;
};

type t_defaultValues = Record<keyof typeof socialMediaName, socialMedia | undefined> &
  customError &
  dontHaveSocialMedia;

const CompanySocialMedia: FC<newComponentsProps> = (props) => {
  const { isActive, componentsValues, handleChangeComponentState, componentsResponse, state } = props;
  type inputs = {
    name: socialMediaName;
    label: string;
    placeholder: string;
    icon: JSX.Element;
  };
  const { t } = useTranslation();
  const DEFAULT_VALUES: t_defaultValues = {
    Facebook: componentsValues?.socialMedia?.find((s) => s.name === socialMediaName.Facebook),
    'Youtube channel': componentsValues?.socialMedia?.find((s) => s.name === socialMediaName['Youtube channel']),
    Instagram: componentsValues?.socialMedia?.find((s) => s.name === socialMediaName.Instagram),
    LinkedIn: componentsValues?.socialMedia?.find((s) => s.name === socialMediaName.LinkedIn),
    Twitter: componentsValues?.socialMedia?.find((s) => s.name === socialMediaName.Twitter),
    Whatsapp: componentsValues?.socialMedia?.find((s) => s.name === socialMediaName.Whatsapp),
    dontHaveASocialMedia: componentsValues?.dontHaveASocialMedia,
  };

  const [execute, { loading }] = useMutation(ADD_SOCIAL_MEDIA);
  const { watch, control, handleSubmit, setValue, getValues } = useForm<t_defaultValues>({
    defaultValues: DEFAULT_VALUES,
  });
  const inputs: inputs[] = [
    {
      name: socialMediaName['Youtube channel'],
      label: 'Youtube Channel',
      placeholder: '@Youtube Username',
      icon: <Icon Name="FiYoutube" />,
    },
    {
      name: socialMediaName.Instagram,
      label: 'Instagram',
      placeholder: '@Instagram Username',
      icon: <Icon Name="FiInstagram" />,
    },
    {
      name: socialMediaName.Twitter,
      label: 'Twitter',
      placeholder: '@Twitter Username',
      icon: <Icon Name="FiTwitter" />,
    },
    {
      name: socialMediaName.Facebook,
      label: 'Facebook',
      placeholder: '@Facebook Username',
      icon: <Icon Name="FiFacebook" />,
    },
    {
      name: socialMediaName.Whatsapp,
      label: 'Whatsapp',
      placeholder: '@Whatsapp Username',
      icon: <Icon Name="FaWhatsapp" />,
    },
    {
      name: socialMediaName.LinkedIn,
      label: 'Linkedin',
      placeholder: '@Linkedin Username',
      icon: <Icon Name="FaLinkedin" />,
    },
  ];
  const onSubmit = (data: t_defaultValues) => {
    setValue('customError', '');
    if (isActive) {
      delete data?.['customError'];
      const keys = Object.keys(data).filter((key) => key !== 'dontHaveASocialMedia');
      keys.forEach((key) => {
        if (!data[key]) delete data[key];
        else
          data[key] = {
            name: key === socialMediaName['Youtube channel'] ? 'Youtube' : key,
            url: data[key]?.name ? data[key]?.url : data[key],
          };
      });
      const finalValue = [];

      for (const i in data) {
        //@ts-ignore
        if (data[i]) {
          finalValue.push(data[i]);
        }
      }
      const socialMediaValues = {
        dontHaveASocialMedia: data.dontHaveASocialMedia,
        socialMedia: finalValue.filter((item) => typeof item !== 'boolean'),
      };
      if (data.dontHaveASocialMedia) {
        socialMediaValues['socialMedia'] = [];
      }
      execute({
        variables: {
          ...componentsResponse,
          ...socialMediaValues,
        },
      })
        .then((res) => {
          if (res.data?.mutationSocialMediaLegalAccount) {
            if (state.isCompleted) {
              toast.success(`${t(updated)}`);
            }
            handleChangeComponentState(formsNameEnum.CompanySocialMedia, true);
          } else {
            //@ts-ignore
            setValue('customError', res?.errors?.message);
          }
        })
        .catch((err) => {
          console.error(err);
          setValue('customError', err.message);
        });
    }
  };
  useEffect(() => {
    if (componentsValues?.socialMedia?.length && componentsValues?.socialMedia?.some((social) => social?.url)) {
      handleChangeComponentState(formsNameEnum.CompanySocialMedia, true);
    }
    if (componentsValues?.dontHaveASocialMedia) {
      handleChangeComponentState(formsNameEnum.CompanySocialMedia, true);
    }
  }, [componentsValues]);
  return (
    <Col xs={12} xl={11} xxl={7} className="p-2 pb-4 mx-auto d-flex flex-column">
      <Form onSubmit={handleSubmit(onSubmit)} aria-disabled={!isActive}>
        <FormGroup>{!!getValues('customError') && <ShowError>{getValues('customError')}</ShowError>}</FormGroup>

        {inputs.map((inp) => {
          return (
            <FormGroup key={inp.name}>
              <Label className="d-flex">
                <span>{inp.icon}</span>
                <span className="ml-2">{inp.label}</span>`
              </Label>
              <OnlyCharInput
                isEveryCharacterAllowed
                defaultValue={(DEFAULT_VALUES[inp.name] as socialMedia)?.url}
                name={inp.name as keyof t_defaultValues}
                control={control}
                placeholder={inp.placeholder}
                aria-disabled={!isActive}
                disabled={!isActive || watch('dontHaveASocialMedia')}
              />
            </FormGroup>
          );
        })}

        <FormGroup>
          <Controller
            control={control}
            name="dontHaveASocialMedia"
            defaultValue={DEFAULT_VALUES.dontHaveASocialMedia}
            render={({ field: { onChange } }) => {
              return (
                <Input
                  id="dontHaveCompany"
                  defaultChecked={DEFAULT_VALUES.dontHaveASocialMedia}
                  onChange={onChange}
                  role="button"
                  style={{ width: '20px', height: '20px' }}
                  type="checkbox"
                />
              );
            }}
          />
          <Label role="button" htmlFor="dontHaveCompany" className="mb-0 ml-2 mt-1">
            Doesn't Have Social Media
          </Label>
        </FormGroup>

        {isActive && (
          <SubmitButton className="w-100 mt-4" loading={loading}>
            {t(state.isCompleted ? update : save)}
          </SubmitButton>
        )}
      </Form>
    </Col>
  );
};
export default memo(CompanySocialMedia);

import React from 'react';
import { Outlet } from 'react-router-dom';
import { useGetStore } from 'hooks';
import AuthRoutes from 'route/AuthRoutes';

const Callback = () => {
  const isLogged = useGetStore().User.isLogged;
  return isLogged === false ? <AuthRoutes isLogged={isLogged} /> : <Outlet />;
};

export default Callback;

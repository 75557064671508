import useQuery from 'hooks/useQuery';
import { GET_SINGLE_CITY } from 'services/graphql/queries/location';
import { useParams } from 'react-router-dom';

export default function useCity() {
  const { cityType } = useParams();
  const { data, loading, error } = useQuery(GET_SINGLE_CITY, {
    variables: {
      id: parseInt(cityType as string),
    },
  });

  return { data: data?.findOneCity || [], loading, error };
}

import { gql } from '@apollo/client';

export const GET_SOFTWARES = gql`
  query getSoftwares($page: Int!, $size: Int!) {
    employmentSoftwaresList(paginationDto: { page: $page, size: $size }) {
      createdAt
      updatedAt
      _id
      name
    }
  }
`;
export const CREATE_SOFTWARE = gql`
  mutation createSoftware($name: String!) {
    employmentCreateSoftware(employmentCreateSoftwareDto: { softwareName: $name }) {
      _id
    }
  }
`;
export const UPDATE_SOFTWARE = gql`
  mutation updateSoftware($id: String!, $name: String!) {
    employmentUpdateSoftware(employmentUpdateSoftwareDto: { id: $id, softwareName: $name }) {
      _id
    }
  }
`;

import { Loading } from 'components';
import SubmitButton from 'components/buttons/submitButton';
import Icon from 'components/icons';
import ShowError from 'components/showError';
import { useToggle, useUpdateEffect } from 'hooks';
import useCompanyDocuments from 'pages/user-management/employer/companyDocuments/useComapnyDocuments';
import { newComponentsProps } from '../index';
import React, { FC, Fragment, memo, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, FormGroup, Modal } from 'reactstrap';
import FetchApi from 'services/api/axios';
import { save, update } from 'tools/i18n/constants/i18n';
import { toNull } from 'tools/methods';
import { stringish } from 'tools/types';
import BlockReasonsListModal from './components/blockReasonsListModal';
import { documentStatus, progressConfig, selectedFile, t_defaultValues } from './entity/types';
import sweetAlert from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useMutation } from '@apollo/client';
import { CHANGE_DOCUMENT_STATUS } from 'services/graphql/queries/documentRejectionReasons';
import { toast } from 'react-toastify';
import UploadDocumentFile from './components/uploadDocumentFile';
import { formsNameEnum } from '../types';

const Swal = withReactContent(sweetAlert);

type CompanyDocumentsProps = newComponentsProps;

const CompanyDocuments: FC<CompanyDocumentsProps> = (props) => {
  const { isActive, componentsResponse, componentsValues, state, handleChangeComponentState } = props;
  const { t } = useTranslation();
  const [companyDocuments, setCompanyDocuments] = useState(componentsValues?.documents);
  const { data, loading } = useCompanyDocuments();
  const [changeLoading, toggleChangeLoading] = useToggle(false);
  const [reasonsListModal, toggleReaasonsListModal] = useToggle(false);
  const modalData = useRef<
    | {
        documentId: number;
        documentName: string;
      }
    | undefined
  >(undefined);
  const renewList = (documentId: number, reasonId: number, rejectedReason: string) => {
    setCompanyDocuments((prev) => {
      return prev?.map((item) => {
        if (item.id !== documentId) return item;
        return {
          ...item,
          reasonId,
          rejectedReason,
          isAccepted: false,
        };
      });
    });
  };
  const [changeStatusExecute] = useMutation<
    any,
    {
      documentId: number;
      reasonId?: string | undefined;
      reason?: string | undefined;
      isAccepted: boolean | null;
    }
  >(CHANGE_DOCUMENT_STATUS);
  const INITIAL_DATA: progressConfig[] = useMemo(() => {
    return data.map((key) => {
      const findItem = companyDocuments?.find((doc) => doc?.documentType?.id === key?.id);
      return {
        id: key.id,
        documentId: findItem?.id,
        name: key.name,
        isStarted: false,
        rejectedReason: findItem?.rejectedReason,
        isCompleted: !!findItem?.isAccepted,
        progress: 0,
        error: typeof findItem?.isAccepted !== 'undefined' && findItem.isAccepted === false,
        pending: typeof findItem?.isAccepted !== 'undefined' && findItem.isAccepted === null,
      };
    });
  }, [companyDocuments, data]);
  const [uploadProgress, setUploadProgress] = useState<progressConfig[]>(INITIAL_DATA);
  const [uploadLoading, toggleLoading] = useToggle(false);

  function next(index: number | undefined, uploadData: t_defaultValues): () => undefined | selectedFile {
    const currentId: number | undefined = index;
    let nextId: selectedFile | undefined;
    return () => {
      const values = Object.entries(uploadData);
      values.forEach((val, index) => {
        const value = val[1] as selectedFile;
        if (value?.id === currentId) {
          if (values[index + 1]) nextId = values[index + 1][1] as selectedFile;
          else nextId = undefined;
        }
      });
      return nextId;
    };
  }
  useEffect(() => {
    if (data || componentsValues?.documents?.length) setUploadProgress(INITIAL_DATA);
  }, [INITIAL_DATA]);

  const DEFAULT_VALUES: t_defaultValues = {};

  useUpdateEffect(() => {
    setCompanyDocuments(componentsValues?.documents);
  }, [componentsValues?.documents]);

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: DEFAULT_VALUES,
  });
  const handleUpload = async (upload: t_defaultValues, index: number | undefined) => {
    try {
      const isCompleted = uploadProgress.find((item) => item.id === index)?.isCompleted;
      if (index && upload?.[index] && !isCompleted) {
        toggleLoading(true);
        const formData = new FormData();
        //@ts-ignore
        formData.append('userId', toNull(componentsResponse?.userId));
        //@ts-ignore
        formData.append('companyId', toNull(componentsResponse?.companyId));
        //@ts-ignore
        formData.append('documentTypeId', (upload[index] as selectedFile).id);
        formData.append('document', (upload[index] as selectedFile).file);
        const handleProgress = (progressEvent: any) => {
          setUploadProgress((prev) => {
            return prev.map((item) => {
              if (item.id !== index) {
                return item;
              }
              return { ...item, error: false, isStarted: true, isCompleted: false, progress: progressEvent.loaded };
            });
          });
        };
        const res = await FetchApi({
          url: 'COMPANY_PDF',
          method: 'PATCH',
          data: formData,
          onUploadProgress: handleProgress,
        });

        if (res.data) {
          setUploadProgress((prev) => {
            return prev.map((item) => {
              if (item.id !== index) {
                return item;
              }
              return { ...item, error: false, isCompleted: false, pending: true, isStarted: false };
            });
          });
          // @ts-ignore
          setValue(index.toString(), undefined);
          window.location.reload();
        } else {
          toggleLoading(false);
          setUploadProgress((prev) => {
            return prev.map((item) => {
              if (item.id !== index) {
                return item;
              }
              return { ...item, isCompleted: true, error: true, isStarted: false };
            });
          });
        }
        if (next(index, upload)()?.id) {
          await handleUpload(upload, next(index, upload)()?.id);
        } else {
          toggleLoading(false);
          // handleChangeComponentState(formsNameEnum.CompanyDocuments, true);
        }
      } else {
        if (next(index, upload)()?.id) {
          await handleUpload(upload, next(index, upload)()?.id);
        } else {
          toggleLoading(false);
        }
      }
    } catch (e) {
      console.error(e);
      toggleLoading(false);
    }
  };
  const onSubmit = async (upload: t_defaultValues) => {
    let selectedFirstItem: undefined | number;
    //@ts-ignore
    const sorted = Object.entries(upload).sort((a, b) => parseInt(a[1]?.id) - parseInt(b[1]?.id));
    // eslint-disable-next-line prefer-const
    selectedFirstItem = sorted.find((item) => {
      console.log(item[1]);
      return typeof item[1] !== 'undefined';
      //@ts-ignore
    })?.[1]?.id;
    if (isActive && selectedFirstItem) {
      await handleUpload(upload, selectedFirstItem);
    }
  };

  const handleSelect = (id: number) => {
    setUploadProgress((prev) =>
      prev.map((item) => {
        if (item.id !== id) return item;
        return { ...item, isCompleted: false };
      })
    );
  };

  function documentActions(
    currentStatus: documentStatus,
    documentId: number,
    documentName: string,
    rejectedReason?: stringish | undefined
  ) {
    modalData.current = {
      documentId,
      documentName,
    };
    switch (currentStatus) {
      case 'ACCEPTED':
        {
          Swal.fire({
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: false,
            title: (
              <div className="d-flex align-items-center justify-content-center w-auto">
                <span
                  style={{ width: '35px', height: '35px' }}
                  className="p-2 mb-1 make-center border-success rounded-circle border-light-gray"
                >
                  <Icon Name="FaCheck" size="35px" color="#51bb25" />
                </span>
                <h6 className="ml-3 text-md whitespace-nowrap">{`"${documentName}" is in accepted status`}</h6>
              </div>
            ),

            footer: (
              <div className="d-flex align-items-center justify-content-between">
                <Button
                  color="danger"
                  onClick={() => {
                    Swal.close();
                    toggleReaasonsListModal(true);
                  }}
                >
                  Reject
                </Button>
                <Button
                  color="secondary"
                  onClick={() => {
                    Swal.close();
                  }}
                >
                  Close
                </Button>
              </div>
            ),
          });
        }
        break;
      case 'REJECTED':
        {
          Swal.fire({
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: false,
            title: (
              <div className="d-flex align-items-center justify-content-center w-auto">
                <span
                  style={{ width: '35px', height: '35px' }}
                  className="p-2 mb-1 make-center border-danger rounded-circle border-light-gray"
                >
                  <Icon Name="FiX" size="25px" color="#EE5873" />
                </span>
                <h6 className="ml-3 whitespace-nowrap">{`"${documentName}" is in Rejected status`}</h6>
              </div>
            ),
            text: rejectedReason || undefined,
            footer: (
              <div className="d-flex align-items-center justify-content-between">
                <Button
                  color="primary"
                  onClick={() => {
                    toggleChangeLoading(true);
                    Swal.fire({
                      icon: 'info',
                      titleText: 'Executing ...',
                      showCloseButton: false,
                      showConfirmButton: false,
                    });
                    changeStatusExecute({
                      variables: {
                        documentId,
                        isAccepted: true,
                        reasonId: undefined,
                        reason: undefined,
                      },
                    })
                      .then((response) => {
                        if (response.data) {
                          setCompanyDocuments((prev) => {
                            return prev?.map((item) => {
                              if (item.id !== documentId) return item;
                              return {
                                ...item,
                                isAccepted: true,
                              };
                            });
                          });
                          toast.success('Document Successfully Accepted');
                        }
                      })
                      .catch((error) => {
                        toast.error(error?.message);
                      })
                      .finally(() => {
                        Swal.close();
                        toggleChangeLoading(false);
                      });
                  }}
                >
                  Accept
                </Button>
                <Button color="secondary" onClick={() => Swal.close()}>
                  Close
                </Button>
              </div>
            ),
          });
        }
        break;
      case 'PENDING':
        {
          Swal.fire({
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: false,
            title: (
              <div className="d-flex align-items-center justify-content-center w-auto">
                <span
                  style={{ width: '35px', height: '35px' }}
                  className="p-2 make-center border-secondary rounded-circle border-light-gray"
                >
                  <Icon Name="FaRegPauseCircle" size="25px" color="#323232" />
                </span>
                <h6 className="ml-2 text-md whitespace-nowrap">{`"${documentName}" is in Pending status`}</h6>
              </div>
            ),
            footer: (
              <div className="d-flex align-items-center justify-content-center">
                <Button
                  color="primary"
                  onClick={() => {
                    toggleChangeLoading(true);
                    Swal.fire({
                      showCancelButton: false,
                      showCloseButton: false,
                      showConfirmButton: false,
                      icon: 'info',
                      titleText: 'Executing ...',
                    });
                    changeStatusExecute({
                      variables: {
                        documentId,
                        isAccepted: true,
                        reasonId: undefined,
                        reason: undefined,
                      },
                    })
                      .then((response) => {
                        if (response.data) {
                          setCompanyDocuments((prev) => {
                            return prev?.map((item) => {
                              if (item.id !== documentId) return item;
                              return {
                                ...item,
                                isAccepted: true,
                              };
                            });
                          });
                          toast.success('Document Successfully Accepted');
                        }
                      })
                      .catch((error) => {
                        toast.error(error?.message);
                      })
                      .finally(() => {
                        Swal.close();
                        toggleChangeLoading(false);
                      });
                  }}
                >
                  Accept
                </Button>
                <Button
                  color="danger"
                  className="mx-2"
                  onClick={() => {
                    Swal.close();
                    toggleReaasonsListModal(true);
                  }}
                >
                  Reject
                </Button>
                <Button
                  color="secondary"
                  onClick={() => {
                    Swal.close();
                  }}
                >
                  Close
                </Button>
              </div>
            ),
          }).then((result) => {
            if (result.isConfirmed) {
              console.log('CONFIRMED');
            }
          });
        }
        break;
      default:
        break;
    }
  }

  // document type all should be required should be uploaded and accepted
  useUpdateEffect(() => {
    const requiredDocs = data.filter((item) => item.isRequired);
    const nonRequiredDocs = data.filter((item) => !item.isRequired);
    const matchedDoc = INITIAL_DATA.filter((item) => {
      return requiredDocs.find((file) => file.id === item.id);
    });
    const matchedNonRequiredDocs = INITIAL_DATA.filter((item) => {
      return nonRequiredDocs.find((file) => file.id === item.id);
    });
    if (
      matchedDoc.length &&
      matchedDoc.every((item) => item.isCompleted) &&
      matchedNonRequiredDocs.length &&
      matchedNonRequiredDocs.some((item) => item.isCompleted)
    ) {
      handleChangeComponentState(formsNameEnum.CompanyDocuments, true);
    }
  }, [componentsValues?.documents, data, INITIAL_DATA]);

  return (
    <Loading loading={loading}>
      <Modal isOpen={reasonsListModal} unmountOnClose style={{ minWidth: '50%' }}>
        <BlockReasonsListModal
          refetch={renewList}
          documentId={modalData.current?.documentId as number}
          documentName={modalData.current?.documentName as string}
          onClose={() => toggleReaasonsListModal(false)}
        />
      </Modal>
      <Col xs={12} xl={10} xxl={8} className="p-2 pb-4 mx-auto d-flex flex-column">
        <Form onSubmit={handleSubmit(onSubmit, console.error)} aria-disabled={!isActive}>
          <FormGroup aria-disabled={!isActive}>
            <div className="d-flex flex-wrap pb-3">
              {data.map((doc) => {
                const matchDoc = INITIAL_DATA.find((item) => item.id === doc.id);
                return (
                  <Fragment key={doc.id}>
                    <Controller
                      control={control}
                      name={doc.id.toString()}
                      render={({ field: { onChange } }) => {
                        const url = componentsValues?.documents?.find(
                          (item) => item?.documentType?.id === doc?.id
                        )?.url;
                        return (
                          <div
                            style={{ maxWidth: '100px' }}
                            className="d-flex flex-column justify-content-between mt-2 mr-4 mb-4"
                          >
                            <div
                              className="d-flex flex-column justify-content-between  position-relative"
                              style={{ maxWidth: '100px', height: 'fit-content' }}
                            >
                              <UploadDocumentFile
                                id={matchDoc?.documentId}
                                reason={matchDoc?.rejectedReason}
                                indexName={doc.name}
                                setError={setError}
                                defaultFile={url}
                                onAction={documentActions}
                                onSelect={(file) => {
                                  clearErrors(doc.name.toString());
                                  setCompanyDocuments((prev) => {
                                    return prev?.map((item) => {
                                      if (item.id !== matchDoc?.documentId) return item;
                                      return {
                                        ...item,
                                        isAccepted: undefined,
                                      };
                                    });
                                  });
                                  handleSelect(doc.id);
                                  onChange({
                                    file,
                                    ...doc,
                                  });
                                }}
                                progressConfig={uploadProgress.find((item) => item.id === doc.id) as progressConfig}
                                isActive={!isActive}
                              />
                              <span className="mb-2 overflow-hidden text-ellipsis">{`${doc.name} ${
                                doc.isRequired ? '(Zorunlu)' : ''
                              }`}</span>
                            </div>
                            {errors?.[doc.name]?.message && (
                              <div className="mt-3">
                                <ShowError>{errors?.[doc.name]?.message}</ShowError>
                              </div>
                            )}
                          </div>
                        );
                      }}
                    />
                  </Fragment>
                );
              })}
            </div>
          </FormGroup>
          {isActive && (
            <SubmitButton
              color="primary"
              type="submit"
              className="w-100 mt-4"
              loading={changeLoading || uploadLoading || loading}
            >
              {t(state.isCompleted ? update : save)}
            </SubmitButton>
          )}
        </Form>
      </Col>
    </Loading>
  );
};
export default memo(CompanyDocuments);

import { ErrorMessage } from '@hookform/error-message';
import TextArea from 'antd/es/input/TextArea';
import SubmitButton from 'components/buttons/submitButton';
import Icon from 'components/icons';
import { useMutation } from 'hooks';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-multilevel-dropdown';
import { toast } from 'react-toastify';
import { Button, FormFeedback, FormGroup, Label, Modal, ModalHeader } from 'reactstrap';
import { DELETE_COMPANY_NOTE, UPDATE_COMPANY_NOTE } from 'services/graphql/queries/companies';
import SweetAlert from 'sweetalert2';
import { areYouSure, no, yes } from 'tools/i18n/constants/i18n';
import { toDate } from 'tools/methods';

interface IProps {
  data: {
    text: string;
    id: number;
    creator: {
      firstName: string;
      lastName: string;
    };
    createdAt: number;
    isMyNote: boolean;
  };
  id: number;
  companyId?: number | null;
  refetch: () => void;
}
export default function NoteItem({ data, companyId, id, refetch }: IProps) {
  const [enabelEdit, setEnabelEdit] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [setExecute, { loading }] = useMutation(UPDATE_COMPANY_NOTE);
  const [deleteExecute] = useMutation(DELETE_COMPANY_NOTE);
  const { t } = useTranslation();

  const DEFAULT_VALUES = {
    text: data?.text,
  };
  const {
    handleSubmit,
    formState: { errors },
    control,
    setError,
  } = useForm({
    defaultValues: DEFAULT_VALUES,
  });

  // useEffect(() => {
  //   setValue('duration', data?.getSlidersLocationDuration.duration);
  // }, [data?.getSlidersLocationDuration.duration]);

  const onSubmit = (data: { text: string }) => {
    if (!data.text) {
      setError('text', { message: 'Please Enter Note' });
      return;
    }

    setExecute({
      variables: { ...data, id },
    })
      .then((res) => {
        console.log(res);
        setEnabelEdit(false);
        toast.success('Note Updated Successfully');
        refetch();
      })
      .catch((e) => {
        console.log(e);
        setEnabelEdit(false);
      });
  };

  const handleShowDelete = () => {
    SweetAlert.fire({
      title: t(areYouSure),
      showCancelButton: true,
      icon: 'question',
      confirmButtonText: t(yes),
      cancelButtonText: t(no),
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        SweetAlert.fire({
          title: 'Progressing ...',
          backdrop: true,
          icon: 'info',
          timerProgressBar: true,
          showCancelButton: false,
          showCloseButton: false,
          showDenyButton: false,
          showConfirmButton: false,
        });
        deleteExecute({
          variables: {
            id,
          },
        })
          .then((res) => {
            SweetAlert.close();
            toast.success(res.data?.deleteAdminNoteOnCompany?.message);
            refetch();
          })
          .catch((err) => {
            SweetAlert.close();
            console.error(err);
            toast.error(err.message);
          });
      } else {
        SweetAlert.close();
      }
    });
  };

  const handleCloseInfoModal = () => {
    setShowInfoModal(false);
  };

  return (
    <div className="border-bottom py-2">
      {!enabelEdit ? (
        <div className="d-flex justify-content-between relative align-items-start">
          <div className="m-0 d-flex flex-col gap-1 cursor-pointer" onClick={() => setShowInfoModal(true)}>
            <span className="font-weight-semibold">{data?.creator?.firstName + ' ' + data?.creator?.lastName}</span>
            <div className="">
              {data?.text?.length > 100 ? (
                <div>
                  {data?.text?.substring(0, 100)}
                  <span
                    style={{ letterSpacing: 1 }}
                    // onClick={() => setShowInfoModal(true)}
                    className="border-none bg-none font-weight-bold"
                  >
                    ...
                  </span>
                </div>
              ) : (
                data?.text
              )}
            </div>
            <span style={{ color: '#818181' }}>{toDate(new Date(data?.createdAt))}</span>
          </div>
          {data?.isMyNote ? (
            <Dropdown
              position="left"
              title={<Icon Name="AiOutlineMore" size="24px" />}
              buttonClassName="bg-transparent pointer border-0 shadow-0"
            >
              <Dropdown.Item onClick={() => setEnabelEdit(true)}>
                <Icon Name="FaPen" />
                <span className="ml-2">Edit Note</span>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleShowDelete()}>
                <Icon Name="FaTrashAlt" />
                <span className="ml-2">Delete Note</span>
              </Dropdown.Item>
            </Dropdown>
          ) : (
            <div className="p-2"></div>
          )}
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="my-1">
          <FormGroup className="d-flex flex-column" style={{ marginBottom: '4px' }}>
            <Label check className="mb-0" role="button">
              Note
            </Label>
            <Controller
              control={control}
              defaultValue={DEFAULT_VALUES?.text}
              name="text"
              rules={{
                required: 'Please Enter Note',
                maxLength: {
                  value: 5000,
                  message: 'Note Cannot be greater than 5000',
                },
                minLength: {
                  value: 3,
                  message: 'Note Cannot be less than 3',
                },
              }}
              render={({ field: { onChange } }) => {
                return (
                  <TextArea
                    role="button"
                    id="activeDeActive"
                    className=" mb-1"
                    name="text"
                    style={{
                      padding: '10px',
                    }}
                    onChange={({ target: { value } }) => onChange(value)}
                    defaultValue={data?.text}
                  />
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name={`text`}
              render={({ message }) => <FormFeedback>{message}</FormFeedback>}
            />
          </FormGroup>
          <div className="d-flex align-items-center gap-2">
            <SubmitButton
              //  loading={loading || createMeta.loading}
              loading={loading}
              className="w-100"
            >
              {t('Save Change')}
            </SubmitButton>
            <Button className="w-100" onClick={() => setEnabelEdit(false)}>
              {t('Cancel')}
            </Button>
          </div>
        </form>
      )}

      <Modal
        unmountOnClose
        style={{ width: '500px', maxWidth: '50vw' }}
        isOpen={showInfoModal}
        toggle={handleCloseInfoModal}
        onClosed={handleCloseInfoModal}
        title={data?.creator?.firstName + ' ' + data?.creator?.lastName}
      >
        <ModalHeader toggle={handleCloseInfoModal}>
          {data?.creator?.firstName + ' ' + data?.creator?.lastName}
        </ModalHeader>
        <div className="p-3">
          <p>{data?.text}</p>
          <span style={{ color: '#818181' }}>{toDate(new Date(data?.createdAt))}</span>
        </div>
      </Modal>
    </div>
  );
}

import { Alert, Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useMutation, useQuery, useToggle } from 'hooks';
import { StyledBanReasonList } from 'pages/user-management/users/style';
import { Loading } from 'components';
import React, { useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { t_deleteReason } from 'pages/user-management/deleteReasons/useDeleteReasons';
import useLanguage from 'hooks/useLanguage';
import { toast } from 'react-toastify';
import SubmitButton from 'components/buttons/submitButton';
import { GET_BAN_REASONS_LIST } from 'services/graphql/queries/banReasons';
import { DELETE_USER } from 'services/graphql/queries/users/users.geaphql';
import { t_company } from '../../../../../tools/types/sectionTypes/company';
import { toArray } from 'tools/methods';

interface DeleteModalProps {
  open: boolean;
  user: t_company;
  adminId: number;
  onClose: VoidFunction;
  refetch: any;
  setData: (data: unknown[]) => void;
  setCount: (value: number) => void;
}
type deleteReasonDefaultValues = {
  reasonId: number | undefined;
  reason: string | undefined;
};
type queryVariable = {
  adminId: number;
  userId: number;
  reasonId: number;
  reason?: string | undefined;
};
export default function DeleteModal({
  open,
  user,
  onClose,
  adminId,
  refetch: rl,
  setData,
  setCount,
}: DeleteModalProps) {
  const { error, loading, refetch, data } = useQuery<{ getAllBanReason: t_deleteReason[] }>(GET_BAN_REASONS_LIST);
  const [deleteExecute] = useMutation<string, queryVariable>(DELETE_USER);
  const {
    handleSubmit,
    setError,
    setValue,
    reset,
    register,
    clearErrors,
    formState: { errors },
  } = useForm<deleteReasonDefaultValues>();
  const [openConfirm, toggleOpenConfirm] = useToggle(false);
  const [executeLoading, toggleLoading] = useToggle(false);
  const [needCustomText, toggleNeedCustomText] = useToggle(false);
  const currentLang = useLanguage().currentLanguage;
  const submittedDataRef = useRef<deleteReasonDefaultValues>();
  const onSubmit: SubmitHandler<deleteReasonDefaultValues> = (data) => {
    if (!data.reasonId) {
      setError('reasonId', { message: 'Select an option' });
      return;
    }
    submittedDataRef.current = data;
    toggleOpenConfirm(true);
  };
  const onConfirm = () => {
    toggleLoading(true);
    const variables: queryVariable = {
      adminId,
      userId: user?.userId?.[0] as number,
      reasonId: submittedDataRef.current?.reasonId as number,
    };
    if (needCustomText) {
      variables['reason'] = submittedDataRef.current?.reason;
    } else {
      variables['reason'] = undefined;
    }

    deleteExecute({
      variables,
    })
      .then(() => {
        setValue('reason', undefined);
        setValue('reasonId', undefined);
        toast.success('Account Successfully Deleted');
        rl().then((res: any) => {
          setData(toArray(res?.data.getCompanyListAndDetails?.listResponse?.data));
          setCount(res?.data.getCompanyListAndDetails?.listResponse?.count);
        });
        reset();
        toggleNeedCustomText(false);
        toggleOpenConfirm(false);
        onClose();
      })
      .catch((error) => {
        toast.error(error?.message);
      })
      .finally(() => {
        toggleLoading(false);
      });
  };
  const handleClose = () => {
    reset();
    setValue('reason', undefined);
    setValue('reasonId', undefined);
    toggleNeedCustomText(false);
    toggleOpenConfirm(false);
    onClose();
  };
  const deleteReasons = data?.getAllBanReason || [];
  return (
    <>
      <ConfirmModal
        onConfirm={onConfirm}
        loading={executeLoading}
        user={user}
        open={openConfirm}
        onCancel={() => toggleOpenConfirm(false)}
      />
      <Modal unmountOnClose toggle={onClose} isOpen={open} style={{ maxWidth: '50vw', width: '50vw' }}>
        <Loading loading={loading} failed={!!error} onRetry={refetch}>
          <ModalHeader>Delete Account Reasons</ModalHeader>
          <Form onSubmit={handleSubmit(onSubmit, console.error)}>
            <ModalBody>
              <StyledBanReasonList>
                {deleteReasons.map((reason) => {
                  return (
                    <li key={reason.id} className="mb-3">
                      <div className="d-flex">
                        <Input
                          onChange={() => {
                            setValue('reasonId', reason.id);
                            clearErrors('reasonId');
                          }}
                          id={reason.id?.toString()}
                          onFocus={() => {
                            if (reason.needToInsertCustomText) {
                              toggleNeedCustomText(true);
                            } else {
                              toggleNeedCustomText(false);
                            }
                          }}
                          name="reasonId"
                          type="radio"
                          className="mr-2 pointer"
                        />
                        <label htmlFor={reason.id?.toString()} className="mb-0" role="button">
                          {reason.language?.find((l) => l.language === currentLang)?.content}
                        </label>
                      </div>
                    </li>
                  );
                })}
                <small className="shadow-text-danger">{errors?.reasonId?.message}</small>
              </StyledBanReasonList>
              {needCustomText && (
                <FormGroup className="px-3">
                  <Label>Ban Reason</Label>
                  <Input
                    type="textarea"
                    {...register('reason', {
                      onChange: (e: any) => {
                        setValue('reason', e.target.value);
                        clearErrors('reason');
                      },
                      required: 'Please Enter Reason Message',
                      minLength: {
                        value: 10,
                        message: 'Reason Should be at least 10 charachter',
                      },
                    })}
                  />
                  <small className="shadow-text-danger">{errors?.reason?.message}</small>
                </FormGroup>
              )}
            </ModalBody>
            <ModalFooter>
              <div className="d-flex">
                <Button type="button" className="mr-2" onClick={handleClose} disabled={executeLoading} color="">
                  Cancel
                </Button>
                <SubmitButton loading={executeLoading}>{`Delete "${
                  user?.companyName || user?.fullName
                }"`}</SubmitButton>
              </div>
            </ModalFooter>
          </Form>
        </Loading>
        {loading && <span className="p-3" />}
      </Modal>
    </>
  );
}

interface ConfirmModalProps {
  open: boolean;
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
  user: t_company;
  loading: boolean;
}

function ConfirmModal({ user, onConfirm, open, onCancel, loading }: ConfirmModalProps) {
  return (
    <Modal unmountOnClose color="danger" isOpen={open}>
      <ModalBody>
        <Alert color="danger" className="rounded">
          Warning!
          <p>
            {`By Deleting "${
              user?.companyName || user?.fullName
            }" all of data that belongs to this user will be deleted and he/she will not be able to create new account with this phone number or email address`}
          </p>
        </Alert>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex align-items-center">
          <Button color="" type="button" className="mr-2" onClick={onCancel}>
            Cancel
          </Button>
          <SubmitButton loading={loading} onClick={onConfirm}>{`Delete ${
            user?.companyName || user?.fullName
          }`}</SubmitButton>
        </div>
      </ModalFooter>
    </Modal>
  );
}

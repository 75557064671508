import logo from 'assets/images/buluro/logo/Logo003.png';
import React from 'react';
import { Outlet } from 'react-router-dom';

const ProtectedLayout = () => {
  // const { changeLanguage, currentLanguage } = useLanguage();
  /*  const handleLangChange = (val: any) => {
    changeLanguage(val.value as t_lang);
  };
  const defaultSelectedLang = LANGUAGES.find((c) => c.language == currentLanguage); */
  return (
    <section className="protected-layout-container">
      <header className="mb-0">
        {/*  <nav>
          <Col lg="3" className="ml-auto bg-primary rounded">
            <Select
              className={'text-center text-black'}
              options={LANGUAGES}
              onChange={(val) => handleLangChange(val)}
              defaultValue={defaultSelectedLang}
            />
          </Col>
        </nav> */}
        <div className="logo">
          <img alt="logo" src={logo} />
        </div>
      </header>
      <main>
        <Outlet />
      </main>
    </section>
  );
};

export default ProtectedLayout;

import useQuery from 'hooks/useQuery';
import { useParams } from 'react-router-dom';
import { GET_ONE_BAZAAR_ADS_STATISTICS } from 'services/graphql/queries/bazzarAds';
import { t_dataTypes } from 'pages/ads/bazzarAds/useBazzarAds';

export default function useBazaarAdsStatistics() {
  const { type } = useParams();
  const { data, loading, error } = useQuery(GET_ONE_BAZAAR_ADS_STATISTICS, {
    variables: {
      id: parseInt(type as string),
    },
  });
  type returnDataType = { statistics: t_dataTypes['statistics'] } & { statusActivity: t_dataTypes['statusActivity'] };
  return { data: data?.findOneBazaarPoster as returnDataType, loading, error };
}

import React, { FC } from 'react';
import DataTable from 'components/tables/dataTable';
import { SPECIAL_LOCATION_TABLE_COLUMNS } from 'pages/content-management/emlak/specialLocation/entity';
import { t_specialRegion } from 'pages/content-management/emlak/specialLocation/types';
import { useTranslation } from 'react-i18next';
import { list, specialLocation } from 'tools/i18n/constants/i18n';

interface SpecialLocationTableProps {
  data: t_specialRegion[];
  queryLoading: boolean;
  onSearch: (key: string) => void;
}

const SpecialLocationTable: FC<SpecialLocationTableProps> = (props) => {
  const { data, queryLoading, onSearch } = props;
  const { t } = useTranslation();
  return (
    <DataTable
      columns={SPECIAL_LOCATION_TABLE_COLUMNS}
      headerTitle={`${t(specialLocation)} ${t(list)}`}
      searchable
      onSearch={onSearch}
      data={data}
      disabled={queryLoading}
    />
  );
};

export default SpecialLocationTable;

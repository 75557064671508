import Icon from 'components/icons';
import Modal from 'components/modal';
import { useToggle } from 'hooks';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactImageMagnify from 'react-image-magnify';
import useDownloader from 'react-use-downloader';
import { Button } from 'reactstrap';
import Styled from 'styled-components';
import { download, file, please, select, sizeExceeded, upload, valid } from 'tools/i18n/constants/i18n';
import { stringish } from 'tools/types';

const ModalImage = Styled.div`
  width : 75vw;
  height : 72vh;
`;

interface UploadPdfProps {
  defaultPdf?: string | null;
  onSelect?: (file?: File) => void;
  textWrap?: boolean;
  label?: string;
  withLabel?: boolean;
  defaultPdfName?: string;
  pdfLink?: string;
  isAcceptImage?: boolean;
  showFileName?: boolean;
  onError?: (err: string) => void;
  maxSize?: number;
  openImageByClick?: boolean;
}

const VALID_FILES = ['application/pdf'];
const VALID_IMAGES = ['image/jpg', 'image/jpeg', 'image/png'];

export default function UploadPdf(props: UploadPdfProps) {
  const { t } = useTranslation();
  const {
    defaultPdf,
    onSelect,
    textWrap,
    withLabel = true,
    label = `${t(upload)} pdf ${t(file)}`,
    defaultPdfName,
    isAcceptImage,
    onError,
    showFileName = true,
    maxSize,
    pdfLink,
    openImageByClick,
  } = props;
  const isPdf = defaultPdf?.endsWith('.pdf');
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [open, toggleOpen] = useToggle(false);
  const [havePdf, toggleHavePdf] = useToggle(!!defaultPdf);
  const containerRef = useRef<HTMLDivElement>(null);
  const { download: downloadFile } = useDownloader();
  const [src, setSrc] = useState<stringish | undefined>(isPdf ? undefined : defaultPdf);
  const [error, setError] = useState('');
  const handleFocus = () => {
    inputFileRef.current?.click();
  };
  const InnerPdf = ({ children, ...rest }: any) =>
    isPdf ? <a {...rest}> {children} </a> : <div {...rest}> {children} </div>;
  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (containerRef.current) {
      const VALIDATION = isAcceptImage ? VALID_FILES.concat(VALID_IMAGES) : VALID_FILES;
      if (VALIDATION.includes(e.target?.files?.[0].type as string)) {
        if (maxSize && (e.target?.files?.[0].size as number) > maxSize) {
          const errText = `${t(sizeExceeded)}`;
          onError ? onError(errText) : setError(errText);
        } else {
          setError('');
          onSelect?.(e.target?.files?.[0]);
          containerRef.current.setAttribute('data-pdf', e.target?.files?.[0]?.name || '');
          toggleHavePdf(true);
        }

        if (VALID_IMAGES.includes(e.target?.files?.[0].type as string)) {
          setSrc(URL.createObjectURL(e.target?.files?.[0] as File));
        } else {
          setSrc(undefined);
        }
      } else {
        const errText = `${t(please)} ${t(select)} ${t(valid)} ${t(file)}`;
        containerRef.current.setAttribute('data-pdf', '');
        onError ? onError(errText) : setError(errText);
      }
    }
  };
  useEffect(() => {
    if (defaultPdfName && containerRef.current) {
      containerRef.current.setAttribute('data-pdf', defaultPdfName);
    }
  }, [defaultPdf, defaultPdfName]);

  return (
    <>
      {!isPdf && src && (
        <Modal isOpen={open} toggleOpen={toggleOpen} style={{ minWidth: '80vw', maxWidth: '80vw', height: '75vh' }}>
          <ModalImage>
            <ReactImageMagnify
              enlargedImagePosition={'over'}
              className="w-100 h-100 make-center p-4"
              imageStyle={{
                objectFit: 'contain',
                maxWidth: '100%',
                maxHeight: '100%',
                margin: 'auto',
                minWidth: 'auto',
              }}
              smallImage={{
                alt: src,
                isFluidWidth: false,
                width: window.innerWidth - 400,
                height: window.innerHeight - 300,
                src: src,
              }}
              largeImage={{
                src: src,
                width: 1426,
                height: 2000,
              }}
              lensStyle={{ backgroundColor: 'rgba(0,0,0,.6)' }}
            />
            {/*   <ImgToBackGround src={src} />*/}
          </ModalImage>

          <Button
            color="primary"
            type="button"
            onClick={(e) => {
              e.currentTarget['disabled'] = true;
              downloadFile(src, src).then(() => {
                e.currentTarget['disabled'] = false;
              });
            }}
            className="d-block mt-5 ml-auto"
          >
            {t(download)}
          </Button>
        </Modal>
      )}

      <div
        data-label={withLabel ? label : ''}
        className={`upload-pdf-container ${!showFileName ? 'hide-filename' : ''} ${textWrap ? 'textWrap' : ''} ${
          src ? 'bg-transparent' : ''
        }`}
        ref={containerRef}
      >
        <InnerPdf
          {...(isPdf
            ? {
                target: '_blank',
                href: pdfLink,
              }
            : {})}
          className="inner-pdf make-center"
          role={havePdf ? undefined : 'button'}
          onClick={havePdf ? undefined : handleFocus}
        >
          <input onChange={(e) => handleSelectFile(e)} id=" pdf-upload-input" type="file" ref={inputFileRef} />
          {src ? (
            <img
              role={openImageByClick ? 'button' : undefined}
              onClick={() => (openImageByClick && !isPdf ? toggleOpen() : undefined)}
              src={src}
              className="rounded-md border-light-gray w-100 h-100"
            />
          ) : (
            <Icon color="#fff" Name={havePdf ? 'AiFillFilePdf' : 'FiFilePlus'} size="35px" />
          )}
        </InnerPdf>
        {havePdf && (
          <span role="button" onClick={handleFocus}>
            <Icon Name="FaPencilAlt" size="16px" />
          </span>
        )}
        {error && (
          <div className="pdf-error shadow-bg-danger p-1 rounded-md whitespace-nowrap flex-grow-1">{error}</div>
        )}
      </div>
    </>
  );
}

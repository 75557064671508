import Icon from 'components/icons';
import IncreaseAble from 'components/increaseAble';
import LoadingSpin from 'components/loadingSpin';
import { useQuery } from 'hooks';
import { TFunction } from 'i18next';
import { FilterAdsCommonProps } from 'pages/ads/bazzarAds/filter/index';
import SectionLayout from 'pages/ads/jobAds/components/layout/sectionLayout';
import React, { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { MultiValue, SingleValue } from 'react-select';
import { GET_CITY_COUNTIES } from 'services/graphql/queries/App';
import { Location } from 'tools/i18n/constants';
import { add, city, select } from 'tools/i18n/constants/i18n';

type BazaarAdsLocationProps = FilterAdsCommonProps;

type city = {
  city: string;
  counties: string[];
};
type dropdownCity = {
  label: string;
  value: string;
  counties: string[];
  isDisabled: boolean;
};

export default function BazaarAdsLocation({ setValue }: BazaarAdsLocationProps) {
  const { t } = useTranslation();
  const { data, loading, error, refetch } = useQuery<{ employmentCitiesAndCountiesList: city[] }>(GET_CITY_COUNTIES);
  const [selectedCities, setSelectedCities] = useState<string[]>([]);
  const [selectedCounties, setSelectedCounties] = useState<string[]>([]);
  const CITY_OPTIONS: dropdownCity[] = useMemo(() => {
    if (data?.employmentCitiesAndCountiesList) {
      return data.employmentCitiesAndCountiesList.map((city) => {
        return {
          label: city.city,
          value: city.city,
          counties: city.counties,
          isDisabled: city.city ? selectedCities.includes(city.city) : false,
        };
      });
    } else return [];
  }, [data?.employmentCitiesAndCountiesList, selectedCities]);

  const handleSelectCity = useCallback(
    (city: dropdownCity) => {
      const updatedCityList = [...new Set([...selectedCities, city.value])];
      setValue?.('city', updatedCityList);
      setSelectedCities(updatedCityList);
    },
    [selectedCities]
  );
  const handleSelectCounty = useCallback(
    (county: string[]) => {
      const countyList = [...new Set([...selectedCounties, ...county])];
      console.log(countyList);
      setValue?.('county', countyList);
      setSelectedCounties(countyList);
    },
    [selectedCounties]
  );

  return (
    <SectionLayout icon="FiMapPin" title={t(Location)}>
      <LoadingSpin loading={loading} isFailed={!!error} onRetry={refetch}>
        <div className="d-flex flex-column-reverse">
          <IncreaseAble
            rootElement={CityCounty}
            render={({ increaseElement, currentElementsLength, index }) => {
              return (
                <Fragment key={index}>
                  <CityCounty
                    index={index + 1}
                    loading={loading}
                    cityOptions={CITY_OPTIONS}
                    t={t}
                    handleSelectCity={handleSelectCity}
                    handleSelectCounty={handleSelectCounty}
                  />
                  {currentElementsLength === index + 1 && (
                    <div className="mb-1 mr-auto">
                      <div className="make-center text-primary" onClick={increaseElement} role="button">
                        <Icon Name="FiPlus" />
                        <span>{`${t(add)} ${t(Location)}`}</span>
                      </div>
                    </div>
                  )}
                </Fragment>
              );
            }}
          />
        </div>
      </LoadingSpin>
    </SectionLayout>
  );
}

interface DropdownsProps {
  t: TFunction;
  handleSelectCity: (city: dropdownCity) => void;
  handleSelectCounty: (city: string[]) => void;
  loading: boolean;
  cityOptions: dropdownCity[];
  index: number;
}

type countyDropDown = { label: string; value: string };

const CityCounty: FC<DropdownsProps> = (props) => {
  const { handleSelectCounty, handleSelectCity, loading, t, cityOptions, index } = props;
  const [selectedCity, setSelectedCity] = useState<dropdownCity | undefined>(undefined);
  const handleAddtCity = (city: NonNullable<SingleValue<dropdownCity>>) => {
    setSelectedCity(city);
    handleSelectCity(city);
  };
  const COUNTY_OPTIONS: countyDropDown[] = useMemo(() => {
    if (selectedCity?.value) {
      return selectedCity.counties.map((county) => ({
        label: county.includes('_') ? county.split('_')[1] : county,
        value: county,
      }));
    } else return [];
  }, [selectedCity]);
  const handleAddCounty = (county: MultiValue<countyDropDown>) => {
    handleSelectCounty(county.map((c) => c.value));
  };
  return (
    <div className="border rounded-md p-2  mb-2">
      <h6>{`${index}:`}</h6>
      <div className="d-flex flex-wrap">
        <div className="mr-2 mb-2 flex-grow-1 z-index-top">
          <Select
            options={cityOptions}
            isLoading={loading}
            placeholder={`${t(select)} ${t(city)} `}
            onChange={(city) => {
              if (city) {
                handleAddtCity(city);
              }
            }}
          />
        </div>
        <div className="flex-grow-1">
          <Select
            isDisabled={!selectedCity}
            isLoading={loading}
            className="z-index-top"
            options={COUNTY_OPTIONS}
            isMulti
            onChange={(value) => {
              if (value) {
                handleAddCounty(value);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

import SubmitButton from 'components/buttons/submitButton';
import FormActionLoading, { step } from 'components/formActionLoading';
import Link from 'components/link';
import ShowError from 'components/showError';
import UploadProfileImage from 'components/uploadProfileImage';
import { useGetStore, useMutation, useQuery, useToggle } from 'hooks';
import PagesLayout from 'pages/components/layout';
import EmlakCategoryTree from 'pages/content-management/emlak/categories/components/EmlakCategoryTree';
import { t_Emlaktypes } from 'pages/content-management/emlak/types/type';
import React, { CSSProperties, useCallback, useMemo, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Col, Form, FormFeedback, FormGroup, Label } from 'reactstrap';
import FetchApi from 'services/api/axios';
import { CREATE_EMLAK_CATEGORY, UPDATE_EMLAK_CATEGORY } from 'services/graphql/queries/emlak/categories';
import { GET_ALL_EMLAK_TYPE } from 'services/graphql/queries/emlak/types';
import { LANGUAGES } from 'tools/constants';
import { Add, Type } from 'tools/i18n/constants';
import {
  an,
  category,
  created,
  emlak,
  emlakCategories,
  emlakType,
  enter,
  label,
  name,
  option,
  parent,
  please,
  remove,
  save,
  select,
  successfully,
  unableToUploadIcon,
  update,
  updated,
} from 'tools/i18n/constants/i18n';
import { goBack, isNew } from 'tools/methods';
import { t_defaultValues, t_emlakCategory } from './type';
type stepInfo = {
  index: number;
  pending: boolean;
  completed: boolean;
  error: boolean;
  fileName?: string;
  percent?: number;
};
const treeStyle: CSSProperties = { maxHeight: '400px', overflowY: 'auto', width: '100%' };
export default function EmlakCategoryDetail() {
  const { type } = useParams();
  const { loading: emlakTypeLoading, data } = useQuery(GET_ALL_EMLAK_TYPE);
  const realData = data?.realEstateFindAllCategoryTypes;
  const isNewCategory = isNew(type);
  const [loading, toggleLoading] = useToggle(false);
  const [openUI, toggleUI] = useToggle(false);
  const formRef = useRef<any>();
  const [createExecute] = useMutation<any, Omit<t_defaultValues, 'icon'>>(CREATE_EMLAK_CATEGORY);
  const [updateExecute] = useMutation<any, Omit<t_defaultValues, 'icon'>>(UPDATE_EMLAK_CATEGORY);
  const [stepsInfo, setStepsInfo] = useState<stepInfo[]>([
    {
      index: 1,
      error: false,
      percent: 0,
      pending: false,
      completed: false,
    },
    {
      index: 2,
      error: false,
      fileName: 'Cover',
      percent: 0,
      pending: false,
      completed: false,
    },
    {
      index: 3,
      error: false,
      fileName: 'PDF',
      percent: 0,
      pending: false,
      completed: false,
    },
  ]);

  const node = useGetStore().Tree.selectedNode;
  const treeData = useGetStore().Tree.treeData;
  const emlakCategoryTree = useMemo(() => treeData, []);
  const selectedNode = useMemo(() => node, []);
  const DEFAULT_VALUES: t_defaultValues = {
    id: selectedNode?.id,
    categoryId: selectedNode?.id,
    icon: selectedNode?.icon,
    translations: selectedNode?.categoryLanguage
      ?.sort((a: any) => (a.language === 'tr' ? -1 : 1))
      .map?.((item: any) => ({ language: item.language, translation: item.translation })),
    categoryTypes: selectedNode?.categoryTypes?.map((item: any) => item.id),
    parentCategory: selectedNode?.parentCategory || selectedNode?.parentId,
  };
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<t_defaultValues>({
    defaultValues: DEFAULT_VALUES,
  });
  const { t } = useTranslation();

  const handleSelectPArentCategory = useCallback((node: t_emlakCategory) => {
    console.log(node);
    if (node.id === DEFAULT_VALUES?.id) {
      setValue('parentCategory', undefined);
      setError('parentCategory', { message: 'Nodes Cannot be their parents' });
    } else {
      clearErrors('parentCategory');
      setValue('parentCategory', node.id === getValues('parentCategory') ? undefined : node.id);
    }
  }, []);
  const onSubmit: SubmitHandler<t_defaultValues> = async (data) => {
    if (!data.categoryTypes || data.categoryTypes?.length === 0) {
      setError('categoryTypes', { message: `${t(please)} ${t(select)} ${t(an)} ${t(option)}` });
      return;
    }
    try {
      toggleLoading(true);
      if (data.icon && data.icon !== DEFAULT_VALUES?.icon) {
        toggleUI(true);
        handleChangeStepInfo(1, { pending: true, error: false, completed: false });
        handleChangeStepInfo(2, { completed: false, error: false, pending: false });
      }
      data.translations = data.translations.map((item) => ({
        language: item.language,
        translate: item.translation,
      }));
      const response = await createExecute({
        variables: data,
      });
      if (response?.data?.realEstateCreateCategory) {
        if (data.icon && data.icon !== DEFAULT_VALUES?.icon) {
          handleChangeStepInfo(1, { pending: false, error: false, completed: true });
          handleChangeStepInfo(2, { pending: true, error: false, completed: false });
          const formData = new FormData();
          formData.append('file', data.icon);
          formData.append('id', response?.data?.realEstateCreateCategory?.id);
          const uploadResponse = await FetchApi({
            url: 'EMLAK_CATEGORY_ICON',
            method: 'PATCH',
            data: formData,
          });
          if (uploadResponse.data) {
            handleChangeStepInfo(2, { pending: false, error: false, completed: true });
            toast.success(`${t(emlak)} ${t(category)} ${t(successfully)} ${t(created)}`);
            goBack();
            toggleLoading(false);
          } else {
            handleChangeStepInfo(2, { pending: false, error: true, completed: false });
            throw Error(t(unableToUploadIcon));
          }
        } else {
          toast.success(`${t(emlak)} ${t(category)} ${t(successfully)} ${t(created)}`);
          goBack();
          toggleLoading(false);
        }
        toggleLoading(false);
      } else {
        handleChangeStepInfo(1, { pending: false, error: true, completed: false });
        //@ts-ignore
        throw Error(response?.errors?.message as string);
      }
    } catch (error: any) {
      setValue('customError', error || 'ERROR');
      toggleLoading(false);
    }
  };
  const onUpdate: SubmitHandler<t_defaultValues> = async (data) => {
    if (!data.categoryTypes || data.categoryTypes?.length === 0) {
      setError('categoryTypes', { message: `${t(please)} ${t(select)} ${t(an)} ${t(option)}` });
      return;
    }
    try {
      toggleLoading(true);
      if (data.icon && data.icon !== DEFAULT_VALUES?.icon) {
        toggleUI(true);
        handleChangeStepInfo(1, { pending: true, error: false });
        handleChangeStepInfo(2, { completed: false, error: false, pending: false });
      }
      data.translations = data.translations.map((item) => ({
        language: item.language,
        translate: item.translation,
      }));
      if (!data.parentCategory) {
        data['setAsRootCategory'] = true;
      }
      const response = await updateExecute({
        variables: data,
      });
      if (response?.data?.realEstateUpdateCategory) {
        if (data.icon && data.icon !== DEFAULT_VALUES?.icon)
          handleChangeStepInfo(1, { completed: true, pending: false });
        if (data.icon && data.icon !== DEFAULT_VALUES?.icon) {
          handleChangeStepInfo(2, { pending: true, error: false });
          const formData = new FormData();
          formData.append('file', data.icon);
          formData.append('id', response?.data?.realEstateUpdateCategory?.id);
          const uploadResponse = await FetchApi({
            url: 'EMLAK_CATEGORY_ICON',
            method: 'PATCH',
            data: formData,
          });
          if (uploadResponse?.data) {
            if (data.icon && data.icon !== DEFAULT_VALUES?.icon)
              handleChangeStepInfo(2, { completed: true, pending: false });
            toast.success(`${t(emlak)} ${t(category)} ${t(successfully)} ${t(updated)}`);
            goBack();
            toggleLoading(false);
          } else {
            handleChangeStepInfo(2, { completed: false, pending: false, error: true });
            throw Error(t(unableToUploadIcon));
          }
        } else {
          toast.success(`${t(emlak)} ${t(category)} ${t(successfully)} ${t(updated)}`);
          goBack();
          toggleLoading(false);
        }
        toggleLoading(false);
      } else {
        if (data.icon && data.icon !== DEFAULT_VALUES?.icon)
          handleChangeStepInfo(1, { completed: false, pending: false, error: true });
        //@ts-ignore
        throw Error(response?.errors?.message as string);
      }
    } catch (error: any) {
      setValue('customError', error?.message || 'ERROR');
      toggleLoading(false);
    }
  };
  const handleChangeStepInfo = useCallback((step: 1 | 2, changeKey: Partial<Record<keyof stepInfo, any>>) => {
    setStepsInfo((prev) => {
      return prev.map((info) => {
        if (info.index !== step) return info;
        return {
          ...info,
          ...changeKey,
        };
      });
    });
  }, []);
  const steps: step[] = [
    {
      index: 1,
      status: stepsInfo[0].completed
        ? 'COMPLETED'
        : stepsInfo[0].pending
        ? 'UPLOADING'
        : stepsInfo[0].error
        ? 'FAILED'
        : 'WAITING',
      title: 'Uploading Form Inputs',
      fileName: Object.keys(DEFAULT_VALUES).join(', '),
      onRetry: () => formRef.current?.props.onSubmit(),
    },
    {
      index: 2,
      title: 'Uploading Icon',
      status: stepsInfo[1].completed
        ? 'COMPLETED'
        : stepsInfo[1].pending
        ? 'UPLOADING'
        : stepsInfo[1].error
        ? 'FAILED'
        : 'WAITING',
      fileName: 'icon',
      onRetry: () => formRef.current?.props.onSubmit(),
    },
  ];
  const emlakTypeOptions = useMemo(() => {
    if (realData?.length) {
      return (realData as t_Emlaktypes[]).map((type) => ({
        ...type,
        label: type.title,
        value: type.id,
      }));
    } else return [];
  }, [realData]);
  const TYPE_DEFAULT_VALUE = useMemo(() => {
    return selectedNode?.categoryTypes?.map((item: any) => {
      return {
        label: item.title,
        value: item.id,
      };
    });
  }, [selectedNode?.categoryTypes]);

  const defaultExpandedIds = useMemo(() => {
    const idArray = [];
    if (DEFAULT_VALUES?.parentCategory) {
      //@ts-ignore
      idArray.push(DEFAULT_VALUES?.parentCategory);
    }
    return idArray;
  }, [DEFAULT_VALUES?.parentCategory]);

  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <PagesLayout backLinkTitle={t(emlakCategories)} backLink="emlakCategories" backLinkParam="/">
        <FormActionLoading onClose={toggleUI} open={openUI} steps={steps} />
        <Card>
          <CardHeader>
            <div className="d-flex align-items-center w-100 justify-content-between">
              <h4>{`${t(isNewCategory ? Add : update)} ${t(category)}`}</h4>
              {!isNewCategory && <Button color="danger">{t(remove)}</Button>}
            </div>
          </CardHeader>
          <CardBody>
            {!!getValues('customError') && (
              <FormGroup>
                <ShowError>{getValues('customError')}</ShowError>
              </FormGroup>
            )}
            <Form ref={formRef} onSubmit={handleSubmit(isNewCategory ? onSubmit : onUpdate, console.error)}>
              <div className="bg-gray d-flex align-items-start justify-content-between flex-wrap">
                <FormGroup className="bg-gray px-3 mr-3 py-2 rounded-md">
                  <Controller
                    control={control}
                    defaultValue={DEFAULT_VALUES.icon}
                    name="icon"
                    render={({ field: { onChange } }) => {
                      return (
                        <UploadProfileImage
                          defaultImage={DEFAULT_VALUES.icon as string}
                          onSelect={(file) => {
                            onChange(file);
                            clearErrors('icon');
                          }}
                          avatarSize="70px"
                          maxSize={500000}
                          onError={(message) => setError('icon', { message })}
                        />
                      );
                    }}
                  />
                  <FormFeedback>{errors?.icon?.message}</FormFeedback>
                </FormGroup>
                <FormGroup className="flex-grow-1 mx-2 mt-2">
                  <Label>{`${t(parent)} ${t(category)}`}</Label>
                  <EmlakCategoryTree
                    isMultiSelect={false}
                    defaultExpandedIds={defaultExpandedIds?.length ? defaultExpandedIds : undefined}
                    onSelect={handleSelectPArentCategory}
                    data={emlakCategoryTree}
                    style={treeStyle}
                    checkbox
                  />
                  <FormFeedback>{errors?.parentCategory?.message}</FormFeedback>
                </FormGroup>
              </div>
              <div className="d-flex">
                <div className="bg-gray flex-grow-1">
                  <FormGroup className="flex-grow-1 bg-gray px-3 py-2 rounded-md">
                    <Controller
                      control={control}
                      defaultValue={LANGUAGES[0].value}
                      name="translations.0.language"
                      render={() => (
                        <Select value={{ label: LANGUAGES[0].label, value: LANGUAGES[0].value }} isDisabled />
                      )}
                    />
                    <FormGroup className="mb-0 mt-4">
                      <Label>{`${t(Type)} ${t(label)} *`}</Label>
                      <Controller
                        rules={{
                          required: `${t(please)} ${t(enter)} ${t(name)}`,
                        }}
                        control={control}
                        name="translations.0.translation"
                        defaultValue={DEFAULT_VALUES.translations?.[0]?.translation}
                        render={({ field: { onChange } }) => {
                          return (
                            <input
                              defaultValue={DEFAULT_VALUES.translations?.[0]?.translation}
                              onChange={onChange}
                              className="form-control"
                              type="text"
                              placeholder={`${t(emlakType)} ${t(label)}`}
                            />
                          );
                        }}
                      />
                      <FormFeedback>{errors?.translations?.[0]?.translation?.message}</FormFeedback>
                    </FormGroup>
                  </FormGroup>
                </div>

                <div className="bg-gray flex-grow-1">
                  <FormGroup className="flex-grow-1 bg-gray px-3 py-2 rounded-md">
                    <Controller
                      control={control}
                      defaultValue={LANGUAGES[1].value}
                      name="translations.1.language"
                      render={() => (
                        <Select value={{ label: LANGUAGES[1].label, value: LANGUAGES[1].value }} isDisabled />
                      )}
                    />
                    <FormGroup className="mb-0 mt-4">
                      <Label>{`${t(emlakType)} ${t(label)} *`}</Label>
                      <Controller
                        rules={{
                          required: `${t(please)} ${t(enter)} ${t(name)}`,
                        }}
                        control={control}
                        defaultValue={DEFAULT_VALUES.translations?.[1]?.translation}
                        name="translations.1.translation"
                        render={({ field: { onChange } }) => {
                          return (
                            <input
                              defaultValue={DEFAULT_VALUES.translations?.[1]?.translation}
                              className="form-control"
                              onChange={onChange}
                              type="text"
                              placeholder={`${t(emlakType)} ${t(label)}`}
                            />
                          );
                        }}
                      />
                      <FormFeedback>{errors?.translations?.[1]?.translation?.message}</FormFeedback>
                    </FormGroup>
                  </FormGroup>
                </div>
              </div>
              <div className="bg-gray">
                <FormGroup className="flex-grow-1 bg-gray px-3 py-2 rounded-md">
                  <Label>Assign Emlak AD Type</Label>
                  {realData?.length === 0 ? (
                    <div className="d-flex">
                      <span>
                        Emlak Type is Empty You Can <Link to="emlakTypes"> Add </Link> Emlak Type
                      </span>
                    </div>
                  ) : (
                    <>
                      <Select
                        options={emlakTypeOptions}
                        isMulti
                        defaultValue={TYPE_DEFAULT_VALUE}
                        isLoading={emlakTypeLoading}
                        name="categoryTypes"
                        onChange={(type: t_Emlaktypes[]) => {
                          if (type?.length) {
                            clearErrors('categoryTypes');
                          }
                          setValue(
                            'categoryTypes',
                            //@ts-ignore
                            type.map((item) => item?.id || item.value)
                          );
                        }}
                      />
                    </>
                  )}
                  <FormFeedback>{errors?.categoryTypes?.message}</FormFeedback>
                </FormGroup>
              </div>

              <FormGroup className="w-100">
                <SubmitButton loading={loading}>{`${t(isNewCategory ? save : update)}`}</SubmitButton>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      </PagesLayout>
    </Col>
  );
}

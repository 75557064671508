import { Loading } from 'components';
import Icon, { IconsName } from 'components/icons';
import UploadProfileImage from 'components/uploadProfileImage';
import useApplyDetail from 'pages/ads/jobAds/useApplyDetail';
import PagesLayout from 'pages/components/layout';
import React from 'react';
import Avatar from 'react-avatar';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  APPLIES_STATUS,
  APPLIES_STATUS_TEXT,
  APP_HELPER,
  EDUCATION_GRADE_TEXT,
  LANGUAGE_LEVEL_TEXT,
  MILITARY_STATUS_TEXT,
} from 'tools/enums';
import {
  address,
  ads,
  apply,
  bio,
  birthdays,
  driver,
  education,
  experience,
  gender,
  information,
  job,
  language,
  licence,
  military,
  nationality,
  personal,
  socialMedia,
  softwares,
  speciality,
  status,
  time,
  work,
} from 'tools/i18n/constants/i18n';
import { toDate, toString } from 'tools/methods';

export default function JobApplyDetail() {
  const { t } = useTranslation();
  const { loading, error, data } = useApplyDetail();
  const fitStatusClassName = (): string => {
    switch (data?.fitStatus as APPLIES_STATUS) {
      case APPLIES_STATUS.NOT_FIT:
        return 'shadow-bg-warning';
      case APPLIES_STATUS.HIRED:
        return 'shadow-bg-active';
      case APPLIES_STATUS.GOOD_FIT:
        return 'shadow-bg-success';
      case APPLIES_STATUS.PENDING:
        return 'shadow-bg-warning';
      default:
        return 'shadow-bg-notActive';
    }
  };
  return (
    <Loading loading={loading}>
      <PagesLayout backLinkTitle={`${t(job)} ${t(ads)}`}>
        <Col xl={10} className="mx-auto">
          <div className="d-flex flex-column">
            <div className="py-2">
              <div className="d-flex align-items-center mb-2">
                <Icon Name="FiClock" />
                <div className="ml-2">
                  <div className="d-flex align-items-center">
                    <span>{`${t(apply)} ${t(time)} : `}</span>
                    <span className="ml-2">{toDate(data?.createdAt)}</span>
                  </div>
                </div>
              </div>
              <div className="bg-white p-3 rounded">
                <div className={`mb-3 p-1 d-inline-block rounded ${fitStatusClassName()}`}>
                  {APPLIES_STATUS_TEXT[data?.fitStatus as keyof typeof APPLIES_STATUS_TEXT]}
                </div>
                <div className="bg-gray rounded p-3">
                  <div className="d-flex flex-column align-items-start">
                    {data?.employee?.speciality ? (
                      <div className="d-flex">
                        <Icon Name="FiGrid" />
                        <h6 className="ml-2">
                          {`${data?.employee?.speciality[0]?.branch?.originalName}${
                            data?.employee?.speciality[0]?.section?.originalName
                              ? `/ ${data?.employee?.speciality[0]?.section?.originalName}`
                              : ''
                          }`}
                        </h6>
                      </div>
                    ) : (
                      APP_HELPER.emptyDescription
                    )}

                    {data?.workExperiences?.company ? (
                      <div className="d-flex">
                        <img alt="logo" src={data?.workExperiences?.company?.logoUrl} width="20px" height="20px" />
                        <div className="ml-2">{data?.workExperiences?.company?.companyName}</div>
                      </div>
                    ) : (
                      APP_HELPER.emptyDescription
                    )}
                  </div>
                </div>
                <div className="bg-gray rounded mt-2 p-3">
                  <div className="p-2">
                    <div className="d-flex align-items-center">
                      <Avatar size={'64px'} round={'50px'} src={data?.employee?.profilePhoto} />
                      <div className="d-flex ml-3">
                        <span className="mr-1">{`${data?.employee?.firstName || APP_HELPER.emptyDescription}`}</span>
                        <span>{`${data?.employee?.lastName || APP_HELPER.emptyDescription}`}</span>
                      </div>
                    </div>
                    <div className="d-flex flex-column mt-4 ml-2">
                      <div className="mb-3">
                        <div className="d-flex flex-column border-bottom">
                          <div className="d-flex">
                            <Icon Name="FiUser" />
                            <h6 className="ml-2">{t(bio)}</h6>
                          </div>
                          {data?.employee?.bio ? (
                            <div className="mt-2 ml-3">
                              <p className="m-0">{data?.employee?.bio}</p>
                            </div>
                          ) : (
                            APP_HELPER.emptyDescription
                          )}
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="d-flex flex-column border-bottom">
                          <div className="d-flex">
                            <Icon Name="FiGrid" />
                            <h6 className="ml-2">{t(speciality)}</h6>
                          </div>
                          {data?.employee?.speciality?.length ? (
                            <div className="d-flex flex-column ml-4">
                              {data?.employee?.speciality.map((speciality: any) => (
                                <>
                                  <span className="mb-1 d-inline-block">
                                    {`${toString(speciality?.branch?.originalName)} / ${
                                      toString(speciality?.section?.originalName) || ''
                                    }`}
                                  </span>
                                </>
                              ))}
                            </div>
                          ) : (
                            APP_HELPER.emptyDescription
                          )}
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="d-flex flex-column border-bottom">
                          <div className="d-flex">
                            <Icon Name="FiCommand" />
                            <h6 className="ml-2">{`${t(work)} ${t(experience)}`}</h6>
                          </div>
                          {data?.employee.workExperiences?.length ? (
                            <div className="d-flex flex-column ml-4">
                              {data?.employee.workExperiences.map((experience: any, index: number) => {
                                return <span key={index}>{experience?.description}</span>;
                              })}
                            </div>
                          ) : (
                            APP_HELPER.emptyDescription
                          )}
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="d-flex flex-column border-bottom">
                          <div className="d-flex">
                            <Icon Name="FiMapPin" />
                            <h6 className="ml-2">{t(address)}</h6>
                          </div>
                          {data?.employee?.address ? (
                            <div className="ml-4">
                              <p className="m-0">
                                {`${data?.employee?.address?.city}, ${data?.employee?.address?.county}, ${data?.employee?.address?.fullAddress}`}
                              </p>
                            </div>
                          ) : (
                            APP_HELPER.emptyDescription
                          )}
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="d-flex flex-column border-bottom">
                          <div className="d-flex">
                            <Icon Name="FiBook" />
                            <h6 className="ml-2">{`${t(education)} ${t(status)}`}</h6>
                          </div>
                          {data?.employee?.educationStatus?.educationSchool?.name ? (
                            <div className="ml-4">
                              <p className="m-0">{`${data?.employee?.educationStatus?.educationSchool?.name || ''}, ${
                                EDUCATION_GRADE_TEXT[
                                  data?.employee?.educationStatus?.educationSchool
                                    ?.grade as keyof typeof EDUCATION_GRADE_TEXT
                                ] || ''
                              }, ${toDate(data?.employee?.educationStatus?.educationSchool?.createdAt) || ''}`}</p>
                            </div>
                          ) : (
                            APP_HELPER.emptyDescription
                          )}
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="d-flex flex-column border-bottom">
                          <div className="d-flex">
                            <Icon Name="FiUser" />
                            <h6 className="ml-2">{`${t(personal)} ${t(information)}`}</h6>
                          </div>
                          <div className="ml-4">
                            <div className="d-flex flex-column">
                              <span className="mb-1">{`${t(birthdays)}: ${
                                data?.employee?.birthdate
                                  ? toDate(data?.employee?.birthdate, false)
                                  : // ?
                                    //   `${new Date(data?.employee?.birthdate).getFullYear()}.${new Date(
                                    //     data?.employee?.birthdate
                                    //   )
                                    //     .toISOString()
                                    //     ?.substring(5, 7)}.${new Date(data?.employee?.birthdate).getDate()} ${new Date(
                                    //     data?.employee?.birthdate
                                    //   ).getHours()}:${new Date(data?.employee?.birthdate).getMinutes()}`
                                    APP_HELPER.emptyDescription
                              }`}</span>
                              <span className="mb-1">{`${t(gender)} : ${
                                data?.employee?.gender ? data?.employee?.gender : APP_HELPER.emptyDescription
                              }`}</span>
                              <span className="mb-1">
                                {`${t(military)} ${t(status)}: ${
                                  data?.employee?.militaryStatus
                                    ? MILITARY_STATUS_TEXT[
                                        data?.employee?.militaryStatus as keyof typeof MILITARY_STATUS_TEXT
                                      ]
                                    : APP_HELPER.emptyDescription
                                }`}
                              </span>

                              <span className="mb-1">{`${t(nationality)} : ${
                                data?.employee?.nationality ? data?.employee?.nationality : APP_HELPER.emptyDescription
                              }`}</span>
                              <span className="mb-1">{`${t(driver)} ${t(licence)}: ${
                                data?.employee?.driverLicense
                                  ? data?.employee?.driverLicense
                                  : APP_HELPER.emptyDescription
                              }`}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="d-flex flex-column border-bottom">
                          <div className="d-flex">
                            <Icon Name="FaLanguage" />
                            <h6 className="ml-2">{t(language)}</h6>
                          </div>
                          {data?.employee?.languages?.length ? (
                            <div className="ml-4">
                              {data?.employee?.languages?.map((lang: any) => {
                                return (
                                  <span key={lang.language.name}>{`${lang?.language?.name}: ${
                                    LANGUAGE_LEVEL_TEXT[lang?.level as keyof typeof LANGUAGE_LEVEL_TEXT] || ''
                                  }`}</span>
                                );
                              })}
                            </div>
                          ) : (
                            APP_HELPER.emptyDescription
                          )}
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="d-flex flex-column border-bottom">
                          <div className="d-flex">
                            <Icon Name="FaTv" />
                            <h6 className="ml-2">{t(softwares)}</h6>
                          </div>
                          {data?.employee?.softwares?.length ? (
                            <div className="ml-4">
                              <p className="m-0">
                                {data?.employee?.softwares?.map((software: any) => `${software?.name || ''}, `)}
                              </p>
                            </div>
                          ) : (
                            APP_HELPER.emptyDescription
                          )}
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="d-flex flex-column border-bottom">
                          <div className="d-flex">
                            <Icon Name="FaFacebook" />
                            <h6 className="ml-2">{`${t(socialMedia)}`}</h6>
                          </div>
                          {data?.employee?.socialMedias?.length ? (
                            <div className="ml-4">
                              <div className="d-flex flex-wrap">
                                {data?.employee?.socialMedias.map((socialMedia: any) => {
                                  const iconName = `Fa${socialMedia.name}` as IconsName;
                                  console.log('iconName : ', iconName);
                                  return (
                                    <a key={socialMedia.name} href={socialMedia?.url} className="mr-2">
                                      <Icon Name={iconName} />
                                    </a>
                                  );
                                })}
                              </div>
                            </div>
                          ) : (
                            APP_HELPER.emptyDescription
                          )}
                        </div>
                      </div>
                      <div className="">
                        <div className="d-flex flex-column border-bottom">
                          <div className="d-flex">
                            <Icon Name="FaCertificate" />
                            <h6 className="ml-2">Certificates</h6>
                          </div>
                          {data?.employee?.certificates?.length ? (
                            <div className="ml-4">
                              <p className="m-0">
                                {data?.employee?.certificates.map((certificate: any) => {
                                  return certificate;
                                })}
                              </p>
                            </div>
                          ) : (
                            APP_HELPER.emptyDescription
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray mt-2 p-3 rounded">
                  <div className="d-flex flex-column">
                    <div className="d-flex">
                      <Icon Name="FaComments" />
                      <h6 className="ml-2">Comments</h6>
                    </div>
                    {data?.comments?.length ? (
                      <div className="d-flex flex-column">
                        {data.comments.map((comment: any) => {
                          return (
                            <div key={comment.commentId} className="d-flex flex-column mb-3 ml-4">
                              <b>
                                <em>{`${comment.firstName} ${comment.lastName}, ${toDate(comment.createdAt)}`}</em>
                              </b>
                              <p className="mb-0 mt-2 ">{comment.text}</p>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      APP_HELPER.emptyDescription
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </PagesLayout>
    </Loading>
  );
}

import React, { CSSProperties, FC, ReactNode, useRef } from 'react';
import { BeatLoader, PropagateLoader } from 'react-spinners';
import { t_refetch, useToggle, useUpdateEffect } from 'hooks';
import { Button, Col } from 'reactstrap';
import Icon from 'components/icons';

interface LoadingProps {
  loading: boolean;
  failed?: boolean;
  onFail?: () => void;
  onRetry?: t_refetch;
  failUI?: ReactNode;
  children?: ReactNode;
  isStartPage?: boolean;
  onlyShowLoadingOnce?: boolean;
  color?: string;
}

const override: CSSProperties = {
  backgroundColor: 'transparent',
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 'calc(100vh - 500px)',
  marginTop: '10vh',
};

const startPageOverride: CSSProperties = {
  backgroundColor: 'transparent',
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};

export const Loading: FC<LoadingProps> = (props) => {
  const { loading, children, failed, onFail, failUI, onRetry, isStartPage, onlyShowLoadingOnce } = props;
  const showCount = useRef(loading ? 1 : 0);
  const [realLoading, toggleRealLoading] = useToggle(loading);
  useUpdateEffect(() => {
    if (failed) onFail?.();
  }, [failed]);
  useUpdateEffect(() => {
    if (loading) {
      showCount.current = showCount.current + 1;
    }
    if (!(onlyShowLoadingOnce && showCount.current > 1)) {
      toggleRealLoading(loading);
    }
  }, [loading]);
  return (
    <>
      {realLoading ? (
        <>
          {isStartPage ? (
            <PropagateLoader size="30px" color="#7366ff" cssOverride={startPageOverride} />
          ) : (
            <BeatLoader className="h-100" cssOverride={override} color={props.color} />
          )}
        </>
      ) : failed ? (
        failUI || <FailUI onRetry={onRetry} />
      ) : (
        children
      )}
    </>
  );
};

const FailUI: FC<{ onRetry?: LoadingProps['onRetry'] }> = ({ onRetry }) => {
  return (
    <Col xl={2} className="mx-auto">
      <Button className="mx-auto w-100" color="transparent" onClick={() => onRetry?.()}>
        <Icon Name="FiRepeat" size="30px" />
      </Button>
    </Col>
  );
};

export default Loading;

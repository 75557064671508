import { Button, Modal, ModalBody } from 'reactstrap';
import React from 'react';
import { useMutation, useToggle } from 'hooks';
import { UNBAN_USER } from 'services/graphql/queries/users/users.geaphql';
import SubmitButton from 'components/buttons/submitButton';
import { toast } from 'react-toastify';
import { t_company } from 'tools/types/sectionTypes/company';
import { toArray } from 'tools/methods';

interface UnbanModalProps {
  isOpen: boolean;
  user: t_company;
  adminId: number;
  onClose: VoidFunction;
  refetch: any;
  setData: (data: unknown[]) => void;
  setCount: (value: number) => void;
}

export default function UnbanModal({ isOpen, user, onClose, adminId, refetch, setData, setCount }: UnbanModalProps) {
  const [execute, { error }] = useMutation<string, { adminId: number; userId: number }>(UNBAN_USER);
  const [loading, toggleLoading] = useToggle(false);
  const handleUnban = () => {
    toggleLoading(true);
    execute({
      variables: {
        adminId,
        userId: user?.userId?.[0],
      },
    })
      .then(() => {
        onClose();
        refetch().then((res: any) => {
          setData(toArray(res?.data.getCompanyListAndDetails?.listResponse?.data));
          setCount(res?.data.getCompanyListAndDetails?.listResponse?.count);
        });
        toast.success('Successfully Unbanned');
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        toggleLoading(false);
      });
  };
  console.log(error);
  return (
    <Modal
      unmountOnClose
      toggle={onClose}
      onClosed={onClose}
      title={`Unban ${user?.companyName || user?.fullName}`}
      isOpen={isOpen}
    >
      <ModalBody>
        <h6>{`Are You Sure to UnBlock ${user?.companyName || user?.fullName}`}</h6>
        <div className="mt-5">
          <div className="d-flex align-items-center">
            <Button color="" disabled={loading} onClick={onClose}>
              Cancel
            </Button>
            <SubmitButton onClick={handleUnban} type="button" loading={loading} color="primary">
              Yes
            </SubmitButton>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

import useQuery from 'hooks/useQuery';
import { GET_STUDENT_LIST } from 'services/graphql/queries/studentManagement';

export default function useStudent() {
  const { error, data, refetch, fetchMore, loading } = useQuery(GET_STUDENT_LIST, {
    variables: {
      page: 1,
      size: 50,
    },
  });
  return { data: data?.EmploymentSchoolStudentList || { count: 0, data: [] }, loading, error, refetch, fetchMore };
}

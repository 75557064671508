import { dataToTree } from 'components/tree-select-checkbox/utils';
import { useGetStore, useMutation } from 'hooks';
import { useParams } from 'react-router-dom';
import { CREATE_LANGUAGE, UPDATE_LANGUAGE } from 'services/graphql/queries/languages';
import { isNew } from 'tools/methods';

export default function useLanguagesDetails() {
  const { type } = useParams();
  const [createExecute] = useMutation(CREATE_LANGUAGE);
  const [updateExecute] = useMutation(UPDATE_LANGUAGE);
  const selectedNode = useGetStore().Tree.selectedNode;
  const treeData = useGetStore().Tree.treeData;
  return {
    treeData: dataToTree(treeData, {
      childKey: 'sections',
      iconKey: 'imageUrl',
      id: '_id',
      labelKey: 'name',
    }),
    selectedNode,
    isNew: isNew(type),
    createExecute,
    updateExecute,
  };
}

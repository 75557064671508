import React, { FC, useMemo } from 'react';
import PagesLayout from 'pages/components/layout';
import { useTranslation } from 'react-i18next';
import { add, detail, hotel, list, Location, name, New, website } from 'tools/i18n/constants/i18n';
import { TableSectionTitle } from 'components/tables';
import Link from 'components/link';
import { Button, Col } from 'reactstrap';
import DataTable from 'components/tables/dataTable';
import useHotel from 'pages/fuar/hotel/useHotel';
import { Loading } from 'components';

export default function Hotel() {
  const { t } = useTranslation();
  const { loading, data } = useHotel();
  const addNewTitle = `${t(add)} ${t(New)} ${t(hotel)}`;
  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <Loading loading={loading}>
        <PagesLayout newSectionButtonLabel={addNewTitle}>
          <MagazineListHeader />
          <HotelTable data={data} />
        </PagesLayout>
      </Loading>
    </Col>
  );
}

const MagazineListHeader = () => {
  return <header></header>;
};

interface MagazineTableProps {
  data: unknown[];
}

const HotelTable: FC<MagazineTableProps> = (props) => {
  const { data } = props;
  const { t } = useTranslation();
  const title = `${t(hotel)} ${t(list)}`;

  const columnData = useMemo(() => {
    return [
      {
        name: <TableSectionTitle name="FiAperture" title={`${t(hotel)} ${t(name)}`} />,
        center: false,
        cell: (r: { name: string; id: string }) => (
          <Link role="button" className="text-black" to={'hotelDetail'} param={r.id.toString()}>
            {r.name}
          </Link>
        ),
      },

      {
        name: <TableSectionTitle title={`${t(Location)}`} name="FiLayers" />,
        selector: (r: { location: string }) => r.location,
      },
      {
        name: <TableSectionTitle title={`${t(website)}`} name="FiActivity" />,
        selector: (r: { webSite?: any }) => r?.webSite || '',
      },
      {
        name: '',
        cell: (r: { id: any }) => {
          return (
            <Link className="text-white ml-auto" to={'hotelDetail'} param={r.id.toString()}>
              <Button color="primary">{t(detail)}</Button>
            </Link>
          );
        },
      },
    ];
  }, []);
  return <DataTable disabled={false} searchable headerTitle={title} columns={columnData} data={data} />;
};

import { gql } from '@apollo/client';

export const GET_ALL_HOLIDAYS = gql`
  query GetAllHolidays($year: Int) {
    getYearHolidays(data: { year: $year }) {
      month
      holidays {
        day
        dayContent {
          id
          isStatic
          title
          isActive
        }
      }
    }
  }
`;

export const CREATE_HOLIDAY = gql`
  mutation CreateHoliday($title: String!, $date: String!, $isStatic: Boolean!) {
    createHoliday(data: { title: $title, date: $date, isStatic: $isStatic })
  }
`;

export const UPDATE_HOLIDAY = gql`
  mutation UpdateHoliday($id: Int!, $title: String, $isActive: Boolean) {
    updateHoliday(data: { id: $id, title: $title, isActive: $isActive })
  }
`;

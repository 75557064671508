import { useGetStore, useMutation } from 'hooks';
import {
  CREATE_BRANCH_JOB_CATEGORY,
  CREATE_SECTION_JOB_CATEGORY,
  UPDATE_BRANCH_JOB_CATEGORY,
  UPDATE_SECTION_JOB_CATEGORY,
} from 'services/graphql/queries/jobCategory';
import { useParams } from 'react-router-dom';
import { isNew } from 'tools/methods';
import { dataToTree } from 'components/tree-select-checkbox/utils';

export default function useJobCategoryDetail() {
  const { type } = useParams();
  const [createExecute] = useMutation(CREATE_BRANCH_JOB_CATEGORY);
  const [updateExecute] = useMutation(UPDATE_BRANCH_JOB_CATEGORY);
  const [createSectionExecute] = useMutation(CREATE_SECTION_JOB_CATEGORY);
  const [updateSectionExecute] = useMutation(UPDATE_SECTION_JOB_CATEGORY);
  const selectedNode = useGetStore().Tree.selectedNode;
  const treeData = useGetStore().Tree.treeData;
  return {
    treeData: dataToTree(treeData, {
      childKey: 'sections',
      iconKey: 'imageUrl',
      id: '_id',
      labelKey: 'originalName',
    }),
    selectedNode,
    isNew: isNew(type),
    createExecute,
    updateExecute,
    createSectionExecute,
    updateSectionExecute,
  };
}

import useQuery from 'hooks/useQuery';
import { GET_ADS_STATISTICS } from 'services/graphql/queries/ads';
import { useParams } from 'react-router-dom';

export default function useStatistics() {
  const { type } = useParams();
  const { data, loading, error } = useQuery(GET_ADS_STATISTICS, {
    variables: {
      id: type,
    },
  });
  console.log(data);
  return { data: data?.EmploymentAdStatistics, loading, error };
}

import React, { useRef } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import { LOCATION_OPTIONS, SLIDER_STATUS } from '../entity';
import { sliderLocationEnum, sliderStatusEnum, t_sliderFilter } from '../types';

interface SliderFilterProps {
  loading: boolean;
  onFilter: (data: t_sliderFilter) => void;
  defaultLocation: string | undefined;
}

export default function SliderFilter({ onFilter, defaultLocation }: SliderFilterProps) {
  console.log(defaultLocation);
  const { handleSubmit, control } = useForm<t_sliderFilter>({
    defaultValues: {
      location: defaultLocation as keyof typeof sliderLocationEnum,
      sliderStatus: 'ALL',
    },
  });
  const formRef = useRef<HTMLButtonElement | null>(null);
  const onSubmit: SubmitHandler<t_sliderFilter> = (data) => {
    onFilter(data);
  };
  const findDefaultOption = LOCATION_OPTIONS.find((item) => item.value === defaultLocation);
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="px-5 mb-1 pt-5 d-flex">
      <FormGroup className="flex-grow mx-3">
        <Label>Location</Label>
        <Controller
          defaultValue={defaultLocation as keyof typeof sliderLocationEnum}
          render={({ field: { onChange } }) => {
            return (
              <Select
                className="z-index-top"
                options={LOCATION_OPTIONS}
                defaultValue={findDefaultOption || LOCATION_OPTIONS[0]}
                onChange={(event) => {
                  if (event?.value) {
                    onChange(event.value);
                    formRef.current?.click();
                  }
                }}
              />
            );
          }}
          name="location"
          control={control}
        />
      </FormGroup>
      <FormGroup className="flex-grow mx-3">
        <Label>Slide Status</Label>
        <Controller
          control={control}
          defaultValue={sliderStatusEnum.ALL}
          name="sliderStatus"
          render={({ field: { onChange } }) => {
            return (
              <Select
                className="z-index-top"
                options={SLIDER_STATUS}
                defaultValue={SLIDER_STATUS[0]}
                onChange={(event) => {
                  if (event?.value) {
                    onChange(event.value);
                    formRef.current?.click();
                  }
                }}
              />
            );
          }}
        />
      </FormGroup>
      <button className="d-none" ref={formRef} type="submit">
        save
      </button>
    </form>
  );
}

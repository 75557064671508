import { gql } from '@apollo/client';

export const GET_ALL_WORK_EXPERIENCE_REJECTION = gql`
  query GetAllWorkExperienceRejection {
    employmentWorkExperienceRejectReasonsList {
      _id
      originalText
      translations {
        language
        translate
      }
    }
  }
`;

export const ADD_WORK_EXPERIENCE_REJECTION = gql`
  mutation AddWorkExperienceRejection($translations: [TranslationsDto!]) {
    employmentCreateWorkExperienceRejectReason(
      employmentCreateWorkExperienceRejectReasonDto: { translations: $translations }
    ) {
      _id
    }
  }
`;

export const UPDATE_WORK_EXPERIENCE_REJECTION = gql`
  mutation UpdateWorkExperienceRejection($id: String!, $translations: [TranslationsDto!]) {
    employmentUpdateWorkExperienceRejectReason(
      employmentUpdateWorkExperienceRejectReasonDto: { id: $id, translations: $translations }
    ) {
      _id
    }
  }
`;

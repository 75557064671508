import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col } from 'reactstrap';
import { Loading } from 'components';
import PagesLayout from 'pages/components/layout';
import useSpecialLocation from 'pages/content-management/emlak/specialLocation/useSpecialLocation';
import { add, emlakType, emptyList, specialLocation, type, useAddToCategory } from 'tools/i18n/constants/i18n';
import SpecialLocationTable from './components/table';
import YourListIsEmpty from 'pages/components/emptyList';
import { useToggle, useUpdateEffect } from 'hooks';
import { t_specialRegion } from 'pages/content-management/emlak/specialLocation/types';

export default function SpecialLocationsList() {
  const { t } = useTranslation();
  const { loading, data, isEmptyList, error, refetch, fetchMore } = useSpecialLocation();
  const [realData, setRealData] = useState<t_specialRegion[]>(data);
  const [queryLoading, toggleQueryLoading] = useToggle(false);
  const handleSearch = (searchKeyword: string) => {
    toggleQueryLoading(true);
    fetchMore({
      variables: {
        query: searchKeyword,
      },
    })
      .then((res) => {
        toggleQueryLoading(false);
        if (res.data?.realEstateFindAllSpecialRegions) {
          setRealData(res.data.realEstateFindAllSpecialRegions);
        }
        toggleQueryLoading(false);
      })
      .catch((err) => {
        console.error(err);
        toggleQueryLoading(false);
      });
  };
  const handleRefetch = async () => {
    toggleQueryLoading(true);
    await refetch();
    toggleQueryLoading(false);
  };
  useUpdateEffect(() => {
    setRealData(data);
  }, [data]);
  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <Loading loading={loading} failed={!!error} onFail={handleRefetch}>
        <PagesLayout newSectionButtonLabel={`${t(add)} ${t(specialLocation)}`}>
          {isEmptyList ? (
            <Card>
              <CardBody>
                <YourListIsEmpty
                  title={`${t(emptyList)}, ${t(useAddToCategory)} ${t(type)}`}
                  addButtonTitle={`${t(add)} ${t(emlakType)}`}
                  addNewButton
                />
              </CardBody>
            </Card>
          ) : (
            <SpecialLocationTable onSearch={handleSearch} queryLoading={queryLoading} data={realData} />
          )}
        </PagesLayout>
      </Loading>
    </Col>
  );
}

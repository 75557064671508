import React, { FC, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

// Authentication
import ProtectedLayout from 'layout/Protected';
import { publicRoutes } from 'route/publicRoute';

const AuthRoutes: FC<{ isLogged: boolean }> = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const pathName = window.location.pathname;
    const findMatchPath = publicRoutes.every((path) => path.path !== pathName);
    if (findMatchPath) {
      navigate('/');
    }
  }, []);
  return (
    <Routes>
      <Route path="*" element={<ProtectedLayout />}>
        {publicRoutes.map((route) => {
          return <Route key={route.path} path={route.path} element={route.element} />;
        })}
      </Route>
    </Routes>
  );
};

export default AuthRoutes;

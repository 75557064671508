import { numberish, stringish } from 'tools/types';
import { ReactNode } from 'react';
import { IconsName } from 'components/icons';
import { t_contentLanguages } from 'pages/notification/components';

export interface ICommonProps<N extends boolean> {
  goNext: (step: t_activeStep, force?: boolean) => void;
  handleChangeSavedData: <T extends keyof SEND_ONE_NOTIFICATION<N>>(
    arg: Record<T, SEND_ONE_NOTIFICATION<N>[T]>
  ) => void;
  savedData: SEND_ONE_NOTIFICATION<N>;
  previewMode?: boolean;
}
export type t_userType = 'APP_USER' | 'GUEST';

export type t_userRoleAppUsers = 'ALL' | 'EMPLOYER' | 'EMPLOYEE';

export type t_userRoleEmployee = {
  isStudent: boolean;
  isNotStudent: boolean;
  hasBazaarAd: boolean;
  hasNotBazaarAd: boolean;
};

export type t_userRoleEmployer = {
  hasJobAd: boolean;
  hasNotJobAd: boolean;
  hasBlueTick: boolean;
  hasNotBlueTick: boolean;
  hasBazaarAd: boolean;
  hasNotBazaarAd: boolean;
  isStore: boolean;
  isNotStore: boolean;
  isInGuide: boolean;
  isNotInGuide: boolean;
};

export type t_userRoles = {
  all?: boolean;
  employer: boolean;
  employee: boolean;
};

export type t_userRoleGuests = {
  all?: boolean;
  employer: boolean;
  employee: boolean;
};

export type t_savedData = {
  contents: t_contentLanguages[] | undefined;
  banner: string | undefined;
  bannerName: string | undefined;
  id?: string | undefined;
  url: string | undefined;
  actionType: NotificationAction | undefined;
  receiverCount: number | undefined;
  userType: t_userType | undefined;
  userRole: t_userRoleAppUsers[] | undefined;
  appUsersUserRoleAll?: boolean | undefined;
} & t_filter_defaultValues;

export type GET_ONE_DATA = {
  receiverCount: numberish;
  message: {
    id: numberish;
    failError: stringish;
    bannerUrl: stringish;
    imageName: stringish;
    url: stringish;
    action: NotificationAction | null;
    isSilent: boolean;
    sendAt: stringish;
    sentAt: stringish;
    status: NotificationStatus | null;
    deletedAt: stringish;
    translates: t_contentLanguages[];
    filter: {
      sendToAll: boolean;
      userType: t_userType;
      employers: boolean;
      employees: boolean;
      isStudent: boolean;
      hasJobAds: boolean;
      hasBlueTick: boolean;
      isInGuide: boolean;
      isStore: boolean;
      hasBazaarAds: boolean;
      id: number;
    };
  };
};

type t_notifPayload = {
  contents: t_contentLanguages[];
  url?: string;
  imageName?: string;
  bannerUrl?: string;
};
export type t_filters = {
  sendToAll: boolean | undefined;
  userType?: t_userType | undefined;
  userRoles?: t_userRoles | undefined;
  isStudent?: boolean | undefined;
  hasJobAds?: boolean | undefined;
  hasBlueTick?: boolean | undefined;
  isInGuide?: boolean | undefined;
  isStore?: boolean | undefined;
  hasBazaarAds?: boolean | undefined;
};

export type SEND_ONE_NOTIFICATION<isNew extends boolean> = {
  payload: t_notifPayload;
  filters?: t_filters;
  actionType?: NotificationAction;
  isSilent?: boolean;
  isDraft: boolean;
  id: isNew extends true ? undefined : number;
  receiverCount?: numberish;
  failError: stringish;
};

export type t_dataToSend = {
  id: number;
};

export enum NotificationStatus {
  DRAFT = 'DRAFT',
  SENDING = 'SENDING',
  SENT = 'SENT',
  ALL = 'ALL',
  FAILED = 'FAILED',
}

export enum NotificationAction {
  OPEN_PROFILE = 'OPEN_PROFILE',
  OPEN_BLUETICK_PAGE = 'OPEN_BLUETICK_PAGE',
  OPEN_EMPLOYMENT_SECTION = 'OPEN_EMPLOYMENT_SECTION',
  OPEN_BAZAAR_SECTION = 'OPEN_BAZAAR_SECTION',
  OPEN_EXCHANGE_SECTION = 'OPEN_EXCHANGE_SECTION',
  OPEN_EXHIBITION_SECTION = 'OPEN_EXHIBITION_SECTION',
  OPEN_MAGAZINE_SECTION = 'OPEN_MAGAZINE_SECTION',
  OPEN_GUIDE_SECTION = 'OPEN_GUIDE_SECTION',
  EMPLOYMENT_CREATE_NEW_AD = 'EMPLOYMENT_CREATE_NEW_AD',
  BAZAAR_CREATE_NEW_AD = 'BAZAAR_CREATE_NEW_AD',
  MESSAGE_DETAILS = 'MESSAGE_DETAILS',
}

export type t_notification = {
  status: NotificationStatus;
  id: number;
  title: numberish;
  createdAt: stringish;
  sentAt: stringish;
};

export interface NotificationTableProps {
  data: t_notification[];
  onPageChange: t_onPageChange;
  onSizeChange: t_onSizeChange;
  onSearch: t_onSearch;
  count: number;
  disabled: boolean;
}

export interface NotificationFiltersProps {
  selectedDates: {
    [E_SENT_DATE.START]: Date | null;
    [E_SENT_DATE.END]: Date | null;
  };
  currentActiveStatus: NotificationStatus[];
  onDateChange: t_onDateChange;
  onStatusChange: t_onStatusChange;
  onSubmit: t_onSubmit;
  disabled: boolean;
}
export enum E_SENT_DATE {
  START = 'START',
  END = 'END',
}

export enum E_FILTER_NAME {
  STATUS = 'STATUS',
  SENT_DATE = 'SENT_DATE',
}

export type t_filterOptions = {
  [E_FILTER_NAME.STATUS]: NotificationStatus;
  [E_FILTER_NAME.SENT_DATE]: E_SENT_DATE;
};

export type t_filter = {
  [x in E_FILTER_NAME]: {
    title: string;
    icon: IconsName;
    options: {
      name: NotificationStatus | E_SENT_DATE;
      active: boolean;
    }[];
  };
};

export type t_onPageChange = (page: number, size: number) => void;
export type t_onSizeChange = (size: number, page: number) => void;
export type t_onSearch = (keyword: string) => void;
export type t_onFilter = (filter: t_filterOptions) => void;

export type t_tableColumns = {
  name: ReactNode;
  center: boolean;
  cell: (r: t_notification) => JSX.Element;
};

export type t_onDateChange = (key: E_SENT_DATE, date: Date | null) => void;
export type t_onStatusChange = (status: NotificationStatus) => void;
export type t_selectedDatesState = {
  [E_SENT_DATE.START]: Date | null;
  [E_SENT_DATE.END]: Date | null;
};

export type t_filterDefaultValues = {
  status: NotificationStatus | undefined;
  sentDate: E_SENT_DATE | undefined;
};

export type t_onSubmit = () => void;

// details

export type t_activeStep = 0 | 1 | 2 | 3;

export type t_filter_defaultValues = {
  customError?: string;
  sendToAll: boolean;
  userType: t_userType;
  userRoles: t_userRoles;
  all: boolean;
  isStudent: boolean;
  hasJobAds: boolean;
  hasBlueTick: boolean;
  isInGuide: boolean;
  isStore: boolean;
  hasBazaarAds: boolean;
};

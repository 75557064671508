import React, { ChangeEvent, InputHTMLAttributes, LegacyRef, forwardRef, useCallback, useMemo } from 'react';
import { Control, Controller, FieldValues, Path, PathValue } from 'react-hook-form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { useTranslation } from 'react-i18next';
import { Input } from 'reactstrap';
import {
  ALL_EXCEPT_NUMBER_SPECIAL_CHARACTER,
  CHARACTER_NUMBER_REGEXP,
  EXCEPT_NUMBERS,
  NUMBER_AS_STRING_REGEXP,
  NUMBER_REGEXP,
  ONLY_CHARACTERS_SPACES,
  ONLY_NUMBERS_AND_SPECIAL_CHARACTER,
  ONLY_NUMBERS_CHARACTER_SPACE,
  SPECIAL_CHARACTER_REGEXP,
} from 'tools/constants';
import { enter, input, please, valid } from 'tools/i18n/constants/i18n';

export interface OnlyNumberInputProps<T extends FieldValues> extends InputHTMLAttributes<HTMLInputElement> {
  control: Control<T, any>;
  name: Path<T>;
  rules?: Omit<RegisterOptions<any, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'> | undefined;
  isRequired?: boolean;
  regExpErrorText?: string;
  requiredErrorText?: string;
  defaultValue?: PathValue<T, Path<T>> | undefined;
  isCharAllowed?: boolean;
  validNumberAsString?: boolean;
  makeFinalValueAsNumber?: boolean;
  isSpecialCharacterAllowed?: boolean;
}

function OnlyNumberInput<T extends FieldValues>(props: OnlyNumberInputProps<T>, ref: LegacyRef<Input> | undefined) {
  const { t } = useTranslation();
  const {
    control,
    name,
    rules,
    isRequired,
    regExpErrorText,
    requiredErrorText,
    defaultValue,
    isCharAllowed,
    validNumberAsString,
    makeFinalValueAsNumber,
    isSpecialCharacterAllowed,
    ...rest
  } = props;
  const defaultValidErrorText = `${t(please)} ${t(enter)} ${t(valid)} ${t(input)}`;
  const defaultRequiredErrorText = `${t(please)} ${t(enter)} ${t(input)}`;

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, cb: any) => {
      let value = e.target.value;
      if (isSpecialCharacterAllowed) {
        value = value.replace(ONLY_CHARACTERS_SPACES, '');
      } else if (isCharAllowed) {
        value = value.replace(ONLY_NUMBERS_CHARACTER_SPACE, '');
      } else {
        value = value.replace(EXCEPT_NUMBERS, '');
      }
      e.target.value = value;
      cb?.(makeFinalValueAsNumber ? (value ? parseInt(value) : null) : value);
    },
    [isCharAllowed, isSpecialCharacterAllowed]
  );
  const patternRegExp = useMemo(() => {
    if (isSpecialCharacterAllowed) {
      return ONLY_NUMBERS_AND_SPECIAL_CHARACTER;
    } else if (isCharAllowed) {
      return CHARACTER_NUMBER_REGEXP;
    } else if (validNumberAsString) {
      return NUMBER_AS_STRING_REGEXP;
    } else return NUMBER_REGEXP;
  }, [isCharAllowed, validNumberAsString, isSpecialCharacterAllowed]);

  const DEFAULT_VALUE = useMemo(() => {
    let val: string | undefined = undefined;
    if (defaultValue) {
      const strigify = defaultValue.toString();
      console.log(strigify);
      if (isSpecialCharacterAllowed) {
        val = strigify?.replace?.(ALL_EXCEPT_NUMBER_SPECIAL_CHARACTER, '');
      } else if (isCharAllowed) {
        val = strigify?.replace?.(SPECIAL_CHARACTER_REGEXP, '');
      } else {
        val = strigify?.replace?.(EXCEPT_NUMBERS, '');
      }
    } else val = undefined;
    return val;
  }, [defaultValue, isCharAllowed, isSpecialCharacterAllowed]);
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={DEFAULT_VALUE as any}
      rules={{
        ...rules,
        required: {
          value: !!isRequired,
          message: requiredErrorText || defaultRequiredErrorText,
        },
        pattern: {
          value: patternRegExp,
          message: regExpErrorText || defaultValidErrorText,
        },
      }}
      render={({ field: { name, onChange } }) => {
        return (
          <Input
            {...(rest as InputHTMLAttributes<HTMLInputElement>)}
            ref={ref}
            defaultValue={DEFAULT_VALUE as any}
            name={name as string}
            onChange={(e) => {
              handleChange(e, onChange);
            }}
            type="tel"
          />
        );
      }}
    />
  );
}

export default forwardRef(OnlyNumberInput);

import useQuery from 'hooks/useQuery';
import { GET_ALL_DELETE_REASONS } from 'services/graphql/queries/deleteReasons';
import { add, deleteReason, deleteReasons, New } from 'tools/i18n/constants/i18n';
import { useTranslation } from 'react-i18next';
import { t_languageKeys } from 'tools/types';

export type t_deleteReasonLanguage = {
  id?: number;
  language: t_languageKeys;
  content: string;
};

export type t_deleteReason = {
  id?: number;
  needToInsertCustomText?: boolean;
  language?: t_deleteReasonLanguage[];
};

export default function useDeleteReasons() {
  const { data, error, loading, refetch, fetchMore } = useQuery(GET_ALL_DELETE_REASONS);
  const { t } = useTranslation();
  const realData = data?.getAllDeleteReason || [];
  const addButtonText = `${t(add)} ${t(New)} ${deleteReason}`;
  const tableTitle = t(deleteReasons);

  return { data: realData as t_deleteReason[], error, loading, refetch, fetchMore, addButtonText, tableTitle };
}

import A from 'store/tree/tree.actionTypes';
import { TreeData, TreeNodeProps } from 'react-dropdown-tree-select';
import { ObjType, t_detailSectionName } from 'tools/types';
import { dispatch } from 'store/index';

export const d_getTreeData = (payload: TreeData) => dispatch({ type: A.GET_TREE_DATA, payload });
export const d_selectedNode = (payload: ObjType<unknown> | TreeNodeProps) =>
  dispatch({ type: A.SELECTED_NODE, payload });
export const d_sectionName = (payload: t_detailSectionName) => {
  dispatch({ type: A.SECTION_NAME, payload: payload });
};
export const d_clearTreeData = () => dispatch({ type: A.CLEAR_TREE_DATA });

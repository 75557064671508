import React from 'react';
import { TableSectionTitle } from 'components/tables';
import { detail, label, specialLocation } from 'tools/i18n/constants/i18n';
import { T } from 'tools/i18n';
import { t_specialRegion } from 'pages/content-management/emlak/specialLocation/types';
import Link from 'components/link';
import { Button } from 'reactstrap';
import Styled from 'styled-components';
import ImgToBackGround from 'components/imgToBackground';
import { d_selectedNode } from 'store/tree/tree.dispatchs';
import Icon from 'components/icons';

const TitleContainer = Styled.div`
  height : 70px;
  width : calc(10vw + 50px);
`;

const ImageContainer = Styled.div`
display : flex;
align-items : center;
justify-content : center;
  width : 50px;
  min-width : 50px;
  max-width : 50px;
  height : 100%;
`;

export const SPECIAL_LOCATION_TABLE_COLUMNS = [
  {
    name: <TableSectionTitle title={`${T(specialLocation)} ${T(label)}`} name="FiLayers" />,
    cell: (r: t_specialRegion) => (
      <TitleContainer className="d-flex align-items-center">
        {r.picture ? (
          <ImageContainer>
            <ImgToBackGround src={r.picture} />
          </ImageContainer>
        ) : (
          <ImageContainer>
            <Icon Name="FaRegMap" size="40px" />
          </ImageContainer>
        )}

        <span className="ml-3">{r?.title}</span>
      </TitleContainer>
    ),
  },
  {
    name: '',
    cell: (r: t_specialRegion) => {
      return (
        <Link
          className="text-white ml-auto"
          onClick={() => {
            d_selectedNode(r);
          }}
          to={'specialLocationDetail'}
          param={r.id.toString()}
        >
          <Button color="primary">{`${T(detail)}`}</Button>
        </Link>
      );
    },
  },
];

import { Button, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import React from 'react';
import { banReason, t_user } from 'pages/user-management/users/type';
import { useMutation, useQuery, useToggle } from 'hooks';
import { BAN_USER, GET_BAN_REASONS_LIST } from 'services/graphql/queries/users/users.geaphql';
import { Loading } from 'components';
import { StyledBanReasonList } from 'pages/user-management/users/style';
import useLanguage from 'hooks/useLanguage';
import SubmitButton from 'components/buttons/submitButton';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

interface BanModalProps {
  open: boolean;
  user: t_user;
  adminId: number;
  onClose: VoidFunction;
  refetch: any;
  handleChangeRef: VoidFunction;
}

type banReasonDefaultValues = {
  reasonId: number | undefined;
  reason: string | undefined;
};

type queryVariable = { adminId: number; userId: number; reason?: string | undefined; reasonId: number };

export default function BanModal({ adminId, user, onClose, refetch: rl, handleChangeRef }: BanModalProps) {
  const { data, loading, error, refetch } = useQuery<{ getAllBanReason: banReason[] }>(GET_BAN_REASONS_LIST);
  const [execute] = useMutation<string, queryVariable>(BAN_USER);
  const {
    formState: { errors, isDirty },
    register,
    setError,
    setValue,
    reset,
    resetField,
    clearErrors,
    handleSubmit,
  } = useForm<banReasonDefaultValues>({
    defaultValues: {
      reason: undefined,
      reasonId: undefined,
    },
  });
  const [needCustomText, toggleNeedCustomText] = useToggle(false);
  const banReasons = data?.getAllBanReason || [];
  const [executeLoading, toggleLoading] = useToggle(false);
  const currentLang = useLanguage().currentLanguage;
  const onSubmit: SubmitHandler<banReasonDefaultValues> = (data) => {
    if (!data.reasonId) {
      setError('reasonId', { message: 'Select an Option' });
      return;
    }
    if (needCustomText && !data.reason) {
      setError('reason', { message: 'Please Enter Reason' });
      return;
    }
    if (needCustomText && data.reason && data.reason?.length < 10) {
      setError('reason', { message: 'Reason length should be at least 10 character' });
      return;
    }
    clearErrors();
    toggleLoading(true);
    const variables: queryVariable = {
      adminId,
      userId: user.userId,
      reasonId: data.reasonId,
    };
    if (needCustomText) {
      variables['reason'] = data.reason;
    } else {
      variables['reason'] = undefined;
    }
    execute({
      variables,
    })
      .then(() => {
        handleChangeRef();
        reset();
        resetField('reason');
        resetField('reasonId');
        toast.success('Account Successfully Banned');
        rl();
        handleClose();
      })
      .catch((error) => {
        toast.error(error?.message);
      })
      .finally(() => {
        toggleLoading(false);
      });
  };
  const handleClose = () => {
    reset();
    setValue('reason', undefined);
    setValue('reasonId', undefined);
    toggleNeedCustomText(false);
    onClose();
  };
  return (
    <>
      <ModalHeader>
        <h5 className="mb-0">Reasons List</h5>
      </ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody className={loading ? 'pb-5' : ''}>
          <Loading loading={loading} failed={!!error} onRetry={refetch}>
            <StyledBanReasonList>
              {banReasons.map((reason) => {
                return (
                  <li key={reason.id} className="mb-3">
                    <div className="d-flex">
                      <Input
                        onChange={() => {
                          setValue('reasonId', reason.id);
                        }}
                        id={reason.id.toString()}
                        onFocus={() => {
                          if (reason.needToInsertCustomText) {
                            toggleNeedCustomText(true);
                          } else {
                            toggleNeedCustomText(false);
                          }
                        }}
                        name="reasonId"
                        type="radio"
                        className="mr-2"
                      />
                      <label htmlFor={reason.id.toString()} className="mb-0" role="button">
                        {reason.language.find((l) => l.language === currentLang)?.content}
                      </label>
                    </div>
                  </li>
                );
              })}
              <small className="shadow-text-danger">{errors?.reasonId?.message}</small>
            </StyledBanReasonList>
          </Loading>
          {needCustomText && (
            <FormGroup className="px-3">
              <Label>Ban Reason</Label>
              <Input
                type="textarea"
                {...register('reason', {
                  onChange: (e: any) => {
                    setValue('reason', e.target.value);
                  },
                })}
              />
              <small className="shadow-text-danger">{errors?.reason?.message}</small>
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter>
          <div className="d-flex">
            <Button className="mr-2" type="button" onClick={handleClose} disabled={executeLoading} color="">
              Cancel
            </Button>
            <SubmitButton loading={executeLoading} disabled={!isDirty}>
              Block
            </SubmitButton>
          </div>
        </ModalFooter>
      </Form>
    </>
  );
}

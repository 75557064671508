import DEFAULT_UNI_LOGO from 'assets/images/buluro/dashboard/university.svg';
import { Loading } from 'components';
import ImgToBackGround from 'components/imgToBackground';
import Link from 'components/link';
import DataTable, { TableSectionTitle } from 'components/tables/dataTable';
import { useUpdateEffect } from 'hooks';
import YourListIsEmpty from 'pages/components/emptyList';
import PagesLayout from 'pages/components/layout';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Col } from 'reactstrap';
import { d_selectedNode } from 'store/tree/tree.dispatchs';
import Styled from 'styled-components';
import { add, detail, emptyList, name, university, useToAdd } from 'tools/i18n/constants/i18n';
import { makeTreeName } from 'tools/methods';
import useUniversity from './useUniversity';
const ImgContainer = Styled.div`
  width : 40px;
  height : 40px;
  border-radius : 10px;
`;

export default function Universities() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isListEmpty, addNewTitle, updatedData, loading, fetchMore, count } = useUniversity();
  const [realData, setRealData] = useState(updatedData);
  const dataCount = useRef(count);
  useUpdateEffect(() => {
    if (updatedData) {
      setRealData(updatedData);
      dataCount.current = count;
    }
  }, [updatedData]);
  const nodeChange = (e: any) => {
    console.log(e);
    const { id } = e;
    d_selectedNode(e);
    navigate(`${id}`);
  };
  const columns = [
    {
      name: <TableSectionTitle name={'FiBookOpen'} title={`${t(university)} ${t(name)}`} />,
      center: false,
      cell: (r: any) => {
        return (
          <div className="p-3 d-flex align-items-center">
            <ImgContainer>
              <ImgToBackGround src={r?.logo || DEFAULT_UNI_LOGO} />
            </ImgContainer>
            <span className="ml-3" role="button" onClick={() => nodeChange(r)}>
              {r?.label}
            </span>
          </div>
        );
      },
    },
    {
      name: '',
      center: false,
      cell: (r: any) => {
        return (
          <Link onClick={() => nodeChange(r)} className="text-white ml-auto" to={'universities'} param={`${r._id}`}>
            <Button color="primary">{t(detail)}</Button>
          </Link>
        );
      },
    },
  ];
  const onSearch = (keyword: string) => {
    fetchMore({
      variables: {
        keyword,
      },
    }).then((result) => {
      setRealData(makeTreeName(result.data?.employmentUniversitiesList?.data));
      dataCount.current = result?.data?.employmentUniversitiesList?.count || 0;
    });
  };

  const onPageChange = (page: number, size: number) => {
    fetchMore({
      variables: {
        page,
        size,
      },
    }).then((result) => {
      setRealData(makeTreeName(result.data?.employmentUniversitiesList?.data));
      dataCount.current = result?.data?.employmentUniversitiesList?.count || 0;
    });
  };
  const onSizeChange = (size: number, page: number) => {
    fetchMore({
      variables: {
        size,
        page,
      },
    }).then((result) => {
      setRealData(makeTreeName(result.data?.employmentUniversitiesList?.data));
      dataCount.current = result?.data?.employmentUniversitiesList?.count || 0;
    });
  };
  return (
    <Col xxl={11} xl={11} md={12} className="mx-auto">
      <Loading loading={loading}>
        <PagesLayout newSectionButtonLabel={addNewTitle}>
          {isListEmpty ? (
            <div className="d-flex flex-column w-100">
              <div className="w-100 py-4">
                <DataTable
                  searchable
                  pagination
                  paginationTotalRows={dataCount.current}
                  onChangePage={onPageChange}
                  onChangeRowsPerPage={onSizeChange}
                  onSearch={onSearch}
                  headerTitle={`${t(university)} ${t(name)}`}
                  columns={columns}
                  data={realData as any[]}
                />
              </div>
            </div>
          ) : (
            <div className="py-3">
              <YourListIsEmpty
                addNewButton
                addButtonTitle={`${t(add)} ${t(university)}`}
                title={`${t(emptyList)}, ${t(useToAdd)} ${t(university)}`}
              />
            </div>
          )}
        </PagesLayout>
      </Loading>
    </Col>
  );
}

import React, { Fragment } from 'react';
import { Row } from 'reactstrap';
import LeftHeader from 'layout/header/leftbar';
import RightHeader from 'layout/header/rightbar';

const Header = () => {
  return (
    <Fragment>
      <div className="page-header">
        <Row className="header-wrapper h-100 justify-content-between m-0">
          <LeftHeader />
          <RightHeader />
        </Row>
      </div>
    </Fragment>
  );
};

export default Header;

import { GET_JOB_APPLY_DETAIL } from 'services/graphql/queries/ads';
import { useParams } from 'react-router-dom';
import useQuery from 'hooks/useQuery';

export default function useApplyDetail() {
  const { id } = useParams();

  const { data, error, loading } = useQuery(GET_JOB_APPLY_DETAIL, {
    variables: {
      id,
    },
  });
  return { data: data?.EmploymentAdApplyDetails, error, loading };
}

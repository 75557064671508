import { Loading } from 'components';
import { Accordion, AccordionTitle } from 'components/accordion';
import Icon from 'components/icons';
import ShowError from 'components/showError';
import { useToggle, useUpdateEffect } from 'hooks';
import useQuery from 'hooks/useQuery';
import { TFunction } from 'i18next';
import PagesLayout from 'pages/components/layout';
import CompanyAboutForm from 'pages/user-management/employer/companyAbout.form';
import CompanyAddress from 'pages/user-management/employer/companyAddress';
import CompanyContactsForm from 'pages/user-management/employer/companyContacts.form';
import CompanyDocumentsForm from 'pages/user-management/employer/companyDocuments';
import CompanyLogoForm from 'pages/user-management/employer/companyLogo.form';
import CompanySizeEstablishment from 'pages/user-management/employer/companySizeAndEstablish.form';
import CompanySocialMedia from 'pages/user-management/employer/companySpcialMedia.form';
import CompanySpecialties from 'pages/user-management/employer/companySpecialities';
import CompanyWorkTimeForm from 'pages/user-management/employer/companyWorkTime.form';
import CreateCompanyForm from 'pages/user-management/employer/createCompany.form';
import EmailVerification from 'pages/user-management/employer/emailVerification';
import React, { FC, Fragment, useCallback, useEffect, useMemo, useState, useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { BeatLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { Col } from 'reactstrap';
import { GET_COMPANY } from 'services/graphql/queries/companies';
import {
  address,
  blue,
  company,
  companyAbout,
  companyContacts,
  companyLogo,
  companySizeEstablishment,
  companySocialMedia,
  companySpecialties,
  companyWorkTime,
  completed,
  create,
  documents,
  email,
  info,
  tick,
  user,
  verification,
} from 'tools/i18n/constants/i18n';
import { t_company } from 'tools/types/sectionTypes/company';
import {
  componentsType,
  formsNameEnum,
  steps,
  t_componentRegisterInfo,
  t_defaultComponentsResponse,
  t_defaultComponentsState,
  t_handleChangeComponentResponse,
  t_handleChangeComponentState,
  t_handleChangeComponentValues,
  t_nextStepFunc,
} from './types';

export interface newComponentsProps extends Omit<componentsType, 'element' | 'title' | 'name' | 'state'> {
  goNext: t_nextStepFunc;
  isActive: boolean;
  componentsValues: t_company | undefined;
  handleChangeComponentState: t_handleChangeComponentState;
  handleChangeComponentResponse?: t_handleChangeComponentResponse;
  handleChangeComponentValues?: t_handleChangeComponentValues;
  componentsResponse: t_defaultComponentsResponse;
  state: t_componentRegisterInfo;
}
interface CompaniesDetailProps {
  id?: string | number;
  provideData?: t_company;
  isNew?: boolean;
}
// this component is called at component detail at content-management/ company / companies / details.tsx
export default function CompaniesDetail(props: CompaniesDetailProps): JSX.Element {
  const { provideData = undefined } = props;

  const Container = !provideData ? PagesLayout : Fragment;
  const InnerContainer = !provideData ? Col : Fragment;
  return (
    <InnerContainer xs={12} xl={10} xxl={10} className="mx-auto d-flex flex-column">
      <Container>
        <CompanyForm isRenderForCompanyDetail={!!provideData} values={provideData} />
      </Container>
    </InnerContainer>
  );
}

const SectionTitle: FC<{
  title: string;
  isCompleted: boolean;
  isRequired?: boolean;
  t: TFunction;
  loading: boolean;
}> = ({ isRequired, title, isCompleted, t, loading }) => (
  <AccordionTitle
    isRequired={isRequired}
    rightChildren={
      loading ? (
        <div className="mr-4">
          <BeatLoader size="10px" />
        </div>
      ) : undefined
    }
  >
    <div className="d-flex w-100 flex-grow-1 align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <span>{title}</span>
        {isCompleted && (
          <div className="ml-4">
            <ShowError type={'success'}>
              <div className="d-flex align-items-center">
                <Icon Name="FaUserCheck" />
                <span className="ml-2">
                  <>{t(completed)} </>
                </span>
              </div>
            </ShowError>
          </div>
        )}
      </div>
    </div>
  </AccordionTitle>
);

interface CompanyFormProps {
  values?: t_company;
  isRenderForCompanyDetail?: boolean;
}

function CompanyForm<T extends CompanyFormProps>(props: T): JSX.Element {
  const { values, isRenderForCompanyDetail = false } = props;
  const { t } = useTranslation();
  const [loading, toggleLoading] = useToggle(false);
  const [isPending, strtTransition] = useTransition();
  const [isPendingResponse, strtTransitionResponse] = useTransition();
  const [isPendingValues, strtTransitionValues] = useTransition();

  const firstStep: steps = isRenderForCompanyDetail ? steps.three : steps.one;
  const DEFAULT_COMPONENTS_STATE: t_defaultComponentsState = useMemo(() => {
    const map = new Map<'componentsState', Partial<t_defaultComponentsState>>();
    map.set('componentsState', {
      EmailVerification: {
        isCompleted: false,
        step: steps.one,
      },
      ...(isRenderForCompanyDetail
        ? {
            CreateCompany: {
              isCompleted: false,
              step: steps.two,
            },
          }
        : undefined),
      CompanyAbout: {
        isCompleted: false,
        step: steps.three,
      },
      CompanyAddress: {
        isCompleted: false,
        step: steps.three,
      },
      CompanyContacts: {
        isCompleted: false,
        step: steps.three,
      },
      CompanyDocuments: {
        isCompleted: false,
        step: steps.three,
      },
      CompanyLogo: {
        isCompleted: false,
        step: steps.three,
      },
      CompanySizeEstablishment: {
        isCompleted: false,
        step: steps.three,
      },
      CompanySocialMedia: {
        isCompleted: false,
        step: steps.three,
      },
      CompanySpecialties: {
        isCompleted: false,
        step: steps.three,
      },
      CompanyWorkTime: {
        isCompleted: false,
        step: steps.three,
      },
    });
    return map.get('componentsState') as t_defaultComponentsState;
  }, [isRenderForCompanyDetail]);

  const DEFAULT_COMPONENTS_RESPONSE: t_defaultComponentsResponse = useMemo(() => {
    const map = new Map<'componentsResponse', t_defaultComponentsResponse>();
    map.set('componentsResponse', {
      companyId: undefined,
      sentCode: undefined,
      userId: undefined,
    });
    return map.get('componentsResponse') as t_defaultComponentsResponse;
  }, []);
  const [currentStep, setCurrentStep] = useState<steps>(firstStep);
  const [componentsState, setComponentsState] = useState<t_defaultComponentsState>(DEFAULT_COMPONENTS_STATE);
  const [componentsValues, setComponentsValues] = useState<t_company | undefined>(values);
  const [componentsResponse, setComponentsResponse] =
    useState<t_defaultComponentsResponse>(DEFAULT_COMPONENTS_RESPONSE);

  useUpdateEffect(() => {
    setComponentsValues(values);
  }, [values]);
  const handleChangeComponentValues: t_handleChangeComponentValues = useCallback(
    (record) => {
      strtTransitionValues(() => {
        //@ts-ignore
        setComponentsValues((prev) => ({ ...prev, ...record }));
      });
    },
    [isPendingValues]
  );
  const handleChangeComponentState: t_handleChangeComponentState = useCallback(
    (componentsKey, isCompleted) => {
      strtTransition(() => {
        setComponentsState((prev) => ({ ...prev, [componentsKey]: { ...prev[componentsKey], isCompleted } }));
      });
    },
    [isPending]
  );
  const handleChangeComponentResponse: t_handleChangeComponentResponse = useCallback(
    (record) => {
      strtTransitionResponse(() => {
        setComponentsResponse((prev) => ({ ...prev, ...record }));
      });
    },
    [isPendingResponse]
  );
  const goNext: t_nextStepFunc = useCallback((nextStep) => setCurrentStep(nextStep), []);

  const handleExpanded = useCallback(
    (section: formsNameEnum) => {
      const isCompleted = componentsState[section].isCompleted;
      if (isCompleted || loading) {
        return false;
      } else return componentsState[section].step === currentStep;
    },
    [componentsState, loading]
  );

  const componentSpecification = useCallback(
    (section: formsNameEnum) => {
      const isActive = componentsState[section].step === currentStep;
      const expanded = handleExpanded(section);
      const isCompleted = componentsState[section].isCompleted;
      const state = componentsState[section];
      return { isActive, expanded, isCompleted, state };
    },
    [componentsState, currentStep]
  );

  const components: Partial<componentsType[]> = useMemo(() => {
    const componentsObj: Partial<componentsType[]> = [
      {
        isActive: componentSpecification(formsNameEnum.EmailVerification).isActive,
        expanded: componentSpecification(formsNameEnum.EmailVerification).expanded,
        name: formsNameEnum.EmailVerification,
        state: formsNameEnum.EmailVerification,
        required: true,
        step: steps.one,
        title: (
          <SectionTitle
            loading={loading}
            isRequired={!componentsState[formsNameEnum.EmailVerification].isCompleted}
            title={`${t(user)} ${t(info)}`}
            t={t}
            isCompleted={componentSpecification(formsNameEnum.EmailVerification).isCompleted}
          />
        ),
        element: (
          <EmailVerification
            handleChangeComponentValues={handleChangeComponentValues}
            handleChangeComponentResponse={handleChangeComponentResponse}
            componentsResponse={componentsResponse}
            componentsValues={componentsValues}
            handleChangeComponentState={handleChangeComponentState}
            isActive={componentSpecification(formsNameEnum.EmailVerification).isActive}
            expanded={componentSpecification(formsNameEnum.EmailVerification).expanded}
            required
            step={steps.one}
            goNext={goNext}
            state={componentSpecification(formsNameEnum.EmailVerification).state}
          />
        ),
      },
      {
        isActive: componentSpecification(formsNameEnum.CompanySpecialties).isActive,
        name: formsNameEnum.CompanySpecialties,
        state: formsNameEnum.CompanySpecialties,
        required: false,
        step: steps.three,
        expanded: componentSpecification(formsNameEnum.CompanySpecialties).expanded,

        title: (
          <SectionTitle
            loading={loading}
            title={t(companySpecialties)}
            t={t}
            isCompleted={componentSpecification(formsNameEnum.CompanySpecialties).isCompleted}
          />
        ),
        element: (
          <Loading loading={loading}>
            <CompanySpecialties
              componentsResponse={componentsResponse}
              handleChangeComponentResponse={undefined}
              handleChangeComponentValues={undefined}
              componentsValues={componentsValues}
              handleChangeComponentState={handleChangeComponentState}
              isActive={componentSpecification(formsNameEnum.CompanySpecialties).isActive}
              goNext={goNext}
              required={false}
              step={steps.three}
              expanded={componentSpecification(formsNameEnum.CompanySpecialties).expanded}
              state={componentSpecification(formsNameEnum.CompanySpecialties).state}
            />
          </Loading>
        ),
      },
      {
        isActive: componentSpecification(formsNameEnum.CompanySizeEstablishment).isActive,
        expanded: componentSpecification(formsNameEnum.CompanySizeEstablishment).expanded,
        name: formsNameEnum.CompanySizeEstablishment,
        state: formsNameEnum.CompanySizeEstablishment,
        required: false,
        step: steps.three,
        title: (
          <SectionTitle
            loading={loading}
            title={t(companySizeEstablishment)}
            t={t}
            isCompleted={componentSpecification(formsNameEnum.CompanySizeEstablishment).isCompleted}
          />
        ),
        element: (
          <Loading loading={loading}>
            <CompanySizeEstablishment
              componentsResponse={componentsResponse}
              handleChangeComponentResponse={undefined}
              handleChangeComponentValues={undefined}
              componentsValues={componentsValues}
              handleChangeComponentState={handleChangeComponentState}
              isActive={componentSpecification(formsNameEnum.CompanySizeEstablishment).isActive}
              expanded={componentSpecification(formsNameEnum.CompanySizeEstablishment).expanded}
              goNext={goNext}
              required
              step={steps.three}
              state={componentSpecification(formsNameEnum.CompanySizeEstablishment).state}
            />
          </Loading>
        ),
      },
      {
        isActive: componentSpecification(formsNameEnum.CompanyContacts).isActive,
        expanded: componentSpecification(formsNameEnum.CompanyContacts).expanded,
        name: formsNameEnum.CompanyContacts,
        state: formsNameEnum.CompanyContacts,
        required: false,
        step: steps.three,
        title: (
          <SectionTitle
            loading={loading}
            title={t(companyContacts)}
            t={t}
            isCompleted={componentSpecification(formsNameEnum.CompanyContacts).isCompleted}
          />
        ),
        element: (
          <Loading loading={loading}>
            <CompanyContactsForm
              componentsResponse={componentsResponse}
              handleChangeComponentValues={undefined}
              handleChangeComponentResponse={undefined}
              componentsValues={componentsValues}
              handleChangeComponentState={handleChangeComponentState}
              isActive={componentSpecification(formsNameEnum.CompanyContacts).isActive}
              expanded={componentSpecification(formsNameEnum.CompanyContacts).expanded}
              required
              goNext={goNext}
              step={steps.three}
              state={componentSpecification(formsNameEnum.CompanyContacts).state}
            />
          </Loading>
        ),
      },
      {
        isActive: componentSpecification(formsNameEnum.CompanyAddress).isActive,
        expanded: componentSpecification(formsNameEnum.CompanyAddress).expanded,
        name: formsNameEnum.CompanyAddress,
        state: formsNameEnum.CompanyAddress,
        required: false,
        step: steps.three,

        title: (
          <SectionTitle
            loading={loading}
            title={`${t(company)} ${t(address)}`}
            t={t}
            isCompleted={componentSpecification(formsNameEnum.CompanyAddress).isCompleted}
          />
        ),
        element: (
          <Loading loading={loading}>
            <CompanyAddress
              componentsResponse={componentsResponse}
              handleChangeComponentResponse={undefined}
              componentsValues={componentsValues}
              handleChangeComponentState={handleChangeComponentState}
              handleChangeComponentValues={handleChangeComponentValues}
              isActive={componentSpecification(formsNameEnum.CompanyAddress).isActive}
              expanded={componentSpecification(formsNameEnum.CompanyAddress).expanded}
              required
              goNext={goNext}
              step={steps.three}
              state={componentSpecification(formsNameEnum.CompanyAddress).state}
            />
          </Loading>
        ),
      },
      {
        isActive: componentSpecification(formsNameEnum.CompanyWorkTime).isActive,
        name: formsNameEnum.CompanyWorkTime,
        state: formsNameEnum.CompanyWorkTime,
        required: true,
        step: steps.three,
        expanded: componentSpecification(formsNameEnum.CompanyWorkTime).expanded,

        title: (
          <SectionTitle
            loading={loading}
            title={t(companyWorkTime)}
            t={t}
            isCompleted={componentSpecification(formsNameEnum.CompanyWorkTime).isCompleted}
          />
        ),
        element: (
          <Loading loading={loading}>
            <CompanyWorkTimeForm
              componentsResponse={componentsResponse}
              handleChangeComponentResponse={undefined}
              componentsValues={componentsValues}
              handleChangeComponentValues={undefined}
              handleChangeComponentState={handleChangeComponentState}
              isActive={componentSpecification(formsNameEnum.CompanyWorkTime).isActive}
              required
              goNext={goNext}
              step={steps.three}
              expanded={componentSpecification(formsNameEnum.CompanyWorkTime).expanded}
              state={componentSpecification(formsNameEnum.CompanyWorkTime).state}
            />
          </Loading>
        ),
      },
      {
        isActive: componentSpecification(formsNameEnum.CompanyAbout).isActive,
        expanded: componentSpecification(formsNameEnum.CompanyAbout).expanded,
        name: formsNameEnum.CompanyAbout,
        state: formsNameEnum.CompanyAbout,
        required: true,
        step: steps.three,

        title: (
          <SectionTitle
            loading={loading}
            title={t(companyAbout)}
            t={t}
            isCompleted={componentSpecification(formsNameEnum.CompanyAbout).isCompleted}
          />
        ),
        element: (
          <Loading loading={loading}>
            <CompanyAboutForm
              componentsResponse={componentsResponse}
              handleChangeComponentResponse={undefined}
              componentsValues={componentsValues}
              handleChangeComponentState={handleChangeComponentState}
              goNext={goNext}
              handleChangeComponentValues={undefined}
              isActive={componentSpecification(formsNameEnum.CompanyAbout).isActive}
              required
              step={steps.three}
              expanded={componentSpecification(formsNameEnum.CompanyAbout).expanded}
              state={componentSpecification(formsNameEnum.CompanyAbout).state}
            />
          </Loading>
        ),
      },
      {
        isActive: componentSpecification(formsNameEnum.CompanySocialMedia).isActive,
        expanded: componentSpecification(formsNameEnum.CompanySocialMedia).expanded,
        name: formsNameEnum.CompanySocialMedia,
        state: formsNameEnum.CompanySocialMedia,
        required: false,
        step: steps.three,

        title: (
          <SectionTitle
            loading={loading}
            title={t(companySocialMedia)}
            t={t}
            isCompleted={componentSpecification(formsNameEnum.CompanySocialMedia).isCompleted}
          />
        ),
        element: (
          <Loading loading={loading}>
            <CompanySocialMedia
              componentsResponse={componentsResponse}
              handleChangeComponentResponse={undefined}
              componentsValues={componentsValues}
              handleChangeComponentState={handleChangeComponentState}
              isActive={componentSpecification(formsNameEnum.CompanySocialMedia).isActive}
              goNext={goNext}
              handleChangeComponentValues={undefined}
              required={false}
              step={steps.three}
              expanded={componentSpecification(formsNameEnum.CompanySocialMedia).expanded}
              state={componentSpecification(formsNameEnum.CompanySocialMedia).state}
            />
          </Loading>
        ),
      },
      {
        isActive: componentSpecification(formsNameEnum.CompanyLogo).isActive,
        expanded: componentSpecification(formsNameEnum.CompanyLogo).expanded,
        name: formsNameEnum.CompanyLogo,
        required: false,
        step: steps.three,

        title: (
          <SectionTitle
            loading={loading}
            title={t(companyLogo)}
            t={t}
            isCompleted={componentSpecification(formsNameEnum.CompanyLogo).isCompleted}
          />
        ),
        element: (
          <Loading loading={loading}>
            <CompanyLogoForm
              componentsResponse={componentsResponse}
              handleChangeComponentResponse={undefined}
              handleChangeComponentValues={undefined}
              componentsValues={componentsValues}
              handleChangeComponentState={handleChangeComponentState}
              isActive={componentSpecification(formsNameEnum.CompanyLogo).isActive}
              required={false}
              goNext={goNext}
              step={steps.three}
              expanded={componentSpecification(formsNameEnum.CompanyLogo).expanded}
              state={componentSpecification(formsNameEnum.CompanyLogo).state}
            />
          </Loading>
        ),
      },
      {
        isActive: componentSpecification(formsNameEnum.CompanyDocuments).isActive,
        expanded: componentSpecification(formsNameEnum.CompanyDocuments).expanded,
        name: formsNameEnum.CompanyDocuments,
        state: formsNameEnum.CompanyDocuments,
        required: false,
        step: steps.three,
        title: (
          <SectionTitle
            loading={loading}
            title={`${t(company)} ${t(blue)} ${t(tick)} ${t(documents)}`}
            t={t}
            isCompleted={componentSpecification(formsNameEnum.CompanyDocuments).isCompleted}
          />
        ),
        element: (
          <Loading loading={loading}>
            <CompanyDocumentsForm
              componentsResponse={componentsResponse}
              handleChangeComponentValues={undefined}
              handleChangeComponentResponse={undefined}
              componentsValues={componentsValues}
              handleChangeComponentState={handleChangeComponentState}
              isActive={componentSpecification(formsNameEnum.CompanyDocuments).isActive}
              required={false}
              step={steps.three}
              goNext={goNext}
              expanded
              state={componentSpecification(formsNameEnum.CompanyDocuments).state}
            />
          </Loading>
        ),
      },
    ];
    if (!isRenderForCompanyDetail) {
      const createCompanyObj = {
        isActive: currentStep === steps.two,
        expanded: currentStep === steps.two,
        name: formsNameEnum.CreateCompany,
        state: formsNameEnum.CreateCompany,
        required: true,
        step: steps.two,

        title: (
          <SectionTitle
            loading={loading}
            isRequired={!componentsState[formsNameEnum.CreateCompany]?.isCompleted || false}
            title={`${t(create)} ${t(company)}`}
            t={t}
            isCompleted={componentsState[formsNameEnum.CreateCompany]?.isCompleted || false}
          />
        ),
        element: (
          <Loading loading={loading}>
            <CreateCompanyForm
              handleChangeComponentValues={handleChangeComponentValues}
              componentsResponse={componentsResponse}
              handleChangeComponentResponse={handleChangeComponentResponse}
              componentsValues={componentsValues}
              handleChangeComponentState={handleChangeComponentState}
              isActive={currentStep === steps.two && !loading}
              expanded={currentStep === steps.two}
              goNext={goNext}
              required
              step={steps.two}
              state={componentsState[formsNameEnum.CreateCompany]}
            />
          </Loading>
        ),
      };
      componentsObj.splice(1, 0, createCompanyObj);
    }
    return componentsObj;
  }, [componentsState, componentsResponse, componentsValues, currentStep, loading, isRenderForCompanyDetail]);
  useUpdateEffect(() => {
    const keys = Object.keys(componentsState);
    const isCompleted = keys.every((key) => componentsState[key].isCompleted);
    if (isCompleted) {
      toast.success(`${t(company)} ${t(completed)}`);
    }
  }, [componentsState]);
  useEffect(() => {
    if (isRenderForCompanyDetail && values?.userId) {
      handleChangeComponentResponse({ userId: values?.userId, companyId: values?.companyId });
    }
  }, [isRenderForCompanyDetail]);

  const { fetchMore } = useQuery(GET_COMPANY, {
    skip: true,
  });
  useUpdateEffect(() => {
    if (componentsResponse.companyId && !values) {
      toggleLoading(true);

      fetchMore({
        variables: {
          id: componentsResponse.companyId,
        },
      })
        .then((res) => {
          if (res.data?.getCompanyListAndDetails?.singleResponse) {
            handleChangeComponentValues(res.data.getCompanyListAndDetails.singleResponse);
          }
          toggleLoading(false);
        })
        .catch((err) => {
          toggleLoading(false);
          console.error(err);
        });
    }
  }, [componentsResponse, values]);

  return (
    <div className="employer-form">
      <Accordion items={components as componentsType[]} />
    </div>
  );
}

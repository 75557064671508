import React, { useRef } from 'react';
import { Button, Form, FormFeedback, FormGroup, Label } from 'reactstrap';
import { t_errors, t_handleSubmit, t_languageKeys, t_setError } from 'tools/types';
import { Control, Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  audience,
  body,
  created,
  enter,
  name,
  next,
  notification,
  please,
  title,
  updated,
} from 'tools/i18n/constants/i18n';
import Select from 'react-select';
import { LANGUAGES } from 'tools/constants';
import { useMutation, useToggle } from 'hooks';
import { CREATE_CONTENT_NOTIFICATION, UPDATE_CONTENT_NOTIFICATION } from 'services/graphql/queries/notification';
import { toast } from 'react-toastify';
import ShowError from 'components/showError';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { useParams } from 'react-router-dom';
import { isNew } from 'tools/methods';
import { ICommonProps, SEND_ONE_NOTIFICATION } from 'pages/notification/types';

type IContentProps<N extends boolean> = ICommonProps<N>;

export type t_contentLanguages = {
  language: t_languageKeys;
  title: string;
  body: string;
  id?: number;
};

type t_defaultValues = {
  id?: number;
  isDraft: boolean;
  customError?: string;
  contents: t_contentLanguages[];
  payload: {
    contents: t_contentLanguages[];
  };
};

export default function Content<N extends boolean>(props: IContentProps<N>): JSX.Element {
  const { goNext, handleChangeSavedData, savedData } = props;
  const { t } = useTranslation();
  const { type } = useParams();
  const [realLoading, toggleLoading] = useToggle(false);
  const [execute, { loading }] = useMutation(CREATE_CONTENT_NOTIFICATION);
  const [updateExecute, { loading: updateLoading }] = useMutation(UPDATE_CONTENT_NOTIFICATION);

  const CONTENT_INITIAL_STATE: t_defaultValues = {
    id: savedData?.id,
    payload: {
      contents: [],
    },
    isDraft: true,
    contents: savedData?.payload?.contents?.length
      ? savedData?.payload?.contents
      : [
          {
            language: 'tr',
            title: '',
            body: '',
          },
          {
            language: 'en',
            title: '',
            body: '',
          },
        ],
  };
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useForm<t_defaultValues>({
    defaultValues: CONTENT_INITIAL_STATE,
  });

  async function onSubmit(data: t_defaultValues) {
    try {
      toggleLoading(true);
      data['isDraft'] = true;
      data['payload']['contents'] = data?.contents?.map((item: any) => ({
        title: item.title,
        body: item.body,
        language: item.language,
      }));
      const EXE = !isNew(type) || savedData?.id ? updateExecute : execute;
      const response = await EXE({
        variables: {
          id: savedData?.id,
          payload: {
            contents: data.contents,
          },
          isDraft: data.isDraft,
        },
      });
      const responseData = response.data?.updateCustomNotification || response.data?.createCustomNotification;
      if (responseData) {
        handleChangeSavedData({
          payload: {
            ...savedData.payload,
            ...(data['payload'] as SEND_ONE_NOTIFICATION<false>['payload']),
          },
          id: responseData.id,
        });
        toast.success(isNew(type) ? 'Bildirim oluşturuldu' : 'Bildirim güncellendi');
        goNext(1, true);
      } else {
        // @ts-ignore
        setValue('customError', response?.errors?.[0]?.extensions?.exception?.response?.devMessage || 'ERROR HAPPENED');
        throw Error('1');
      }
      toggleLoading(false);
    } catch (e) {
      console.error(e);
      toggleLoading(false);
    }
  }
  return (
    <>
      <FormGroup>{!!getValues('customError') && <ShowError>{getValues('customError')}</ShowError>}</FormGroup>
      <ContentForm
        defaultValues={CONTENT_INITIAL_STATE}
        setValue={setValue}
        errors={errors}
        goNext={goNext}
        disabled={loading || realLoading || updateLoading}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        control={control}
        setError={setError}
      />
    </>
  );
}

interface ContentFormProps<N extends boolean> {
  defaultValues: t_defaultValues;
  control: Control<t_defaultValues>;
  setValue: UseFormSetValue<t_defaultValues>;
  onSubmit: (data: t_defaultValues) => void;
  handleSubmit: t_handleSubmit<t_defaultValues>;
  disabled: boolean;
  goNext: ICommonProps<N>['goNext'];
  errors: t_errors<t_defaultValues>;
  setError: t_setError<t_defaultValues>;
}
function ContentForm<N extends boolean, T extends ContentFormProps<N>>(props: T): JSX.Element {
  const { errors, handleSubmit, defaultValues, setValue, onSubmit, control, disabled } = props;
  const { t } = useTranslation();
  const trInput = useRef<any>();
  const trBody = useRef<any>();
  const enInput = useRef<any>();
  const enBody = useRef<any>();

  const handleAddAudienceName = (from: 'tr' | 'en') => {
    const AUDIENCE_NAME = '${AUDIENCE_NAME}';
    if (from === 'tr') {
      if (trInput.current) {
        const concatedVale = trInput.current.value.concat(AUDIENCE_NAME);
        trInput.current.value = concatedVale;
        setValue('contents.0.title', concatedVale);
      }
    } else {
      if (enInput.current) {
        const concatedVale = enInput.current.value.concat(AUDIENCE_NAME);
        enInput.current.value = concatedVale;
        setValue('contents.1.title', concatedVale);
      }
    }
  };
  const handleAddBodyAudience = (from: 'tr' | 'en') => {
    const AUDIENCE_NAME = '${AUDIENCE_NAME}';
    if (from === 'tr') {
      if (trBody.current) {
        const concatedValue = trBody.current.value.concat(AUDIENCE_NAME);
        trBody.current.value = concatedValue;
        setValue('contents.0.body', concatedValue);
      }
    } else {
      if (enBody.current) {
        const concatedValue = enBody.current.value.concat(AUDIENCE_NAME);
        enBody.current.value = concatedValue;
        setValue('contents.1.body', concatedValue);
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup className="d-flex flex-column">
        <FormGroup className="flex-grow-1 bg-gray p-5 rounded-md">
          <Controller
            control={control}
            defaultValue={LANGUAGES[0].value}
            name="contents.0.language"
            render={() => <Select value={{ label: LANGUAGES[0].label, value: LANGUAGES[0].value }} isDisabled />}
          />
          <FormGroup className="mb-0 mt-4">
            <Label className="d-flex flex-grow-1 align-items-center justify-content-between">
              <span>{`Türkçe Başlık *`}</span>
              <div
                role="button"
                onClick={() => handleAddAudienceName('tr')}
                className="p-2 bg-primary rounded-md btn btn-sm"
              >
                {`Kullanıcı Ad ve Soyadı`}
              </div>
            </Label>
            <Controller
              rules={{
                required: `Lütfen bir başlık giriniz`,
              }}
              control={control}
              name="contents.0.title"
              defaultValue={defaultValues.contents?.[0]?.title}
              render={({ field: { onChange } }) => {
                return (
                  <input
                    defaultValue={defaultValues.contents?.[0]?.title}
                    className="form-control"
                    type="text"
                    placeholder="Türkçe Başlık"
                    ref={trInput}
                    onInput={onChange}
                  />
                );
              }}
            />
            <FormFeedback>{errors?.contents?.[0]?.title?.message}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label className="d-flex flex-grow-1 align-items-center justify-content-between">
              <span>{`Türkçe Açıklama *`}</span>
              <div
                role="button"
                onClick={() => handleAddBodyAudience('tr')}
                className="p-2 bg-primary rounded-md btn btn-sm"
              >
                {`Kullanıcı Ad ve Soyadı`}
              </div>
            </Label>

            <Controller
              rules={{
                required: `Lütfen bir Açıklama giriniz`,
              }}
              control={control}
              name="contents.0.body"
              defaultValue={defaultValues.contents?.[0]?.body}
              render={({ field: { onChange } }) => {
                return (
                  <textarea
                    className="form-control"
                    defaultValue={defaultValues.contents?.[0]?.body}
                    placeholder="Türkçe Açıklama"
                    onInput={onChange}
                    ref={trBody}
                  />
                );
              }}
            />
          </FormGroup>
          <FormFeedback>{errors?.contents?.[0]?.body?.message}</FormFeedback>
        </FormGroup>

        <FormGroup className="flex-grow-1 bg-gray p-5 rounded-md">
          <Controller
            control={control}
            defaultValue={LANGUAGES[1].value}
            name="contents.0.language"
            render={() => <Select value={{ label: LANGUAGES[1].label, value: LANGUAGES[1].value }} isDisabled />}
          />

          <FormGroup className="mb-0 mt-4">
            <Label className="d-flex flex-grow-1 align-items-center justify-content-between">
              <span>{`İngilizce Başlık *`}</span>
              <div
                role="button"
                onClick={() => handleAddAudienceName('en')}
                className="p-2 bg-primary rounded-md btn btn-sm"
              >
                {`Kullanıcı Ad ve Soyadı`}
              </div>
            </Label>

            <Controller
              rules={{
                required: `Lütfen bir başlık giriniz`,
              }}
              control={control}
              name="contents.1.title"
              defaultValue={defaultValues.contents?.[1]?.title}
              render={({ field: { onChange } }) => {
                return (
                  <input
                    defaultValue={defaultValues.contents?.[1]?.title}
                    className="form-control"
                    type="textarea"
                    placeholder="İngilizce Başlık"
                    ref={enInput}
                    onInput={onChange}
                  />
                );
              }}
            />

            <FormFeedback>{errors?.contents?.[1]?.title?.message}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label className="d-flex flex-grow-1 align-items-center justify-content-between">
              <span>{`İngilizce Açıklama *`}</span>
              <div
                role="button"
                onClick={() => handleAddBodyAudience('en')}
                className="p-2 bg-primary rounded-md btn btn-sm"
              >
                {`Kullanıcı Ad ve Soyadı`}
              </div>
            </Label>

            <Controller
              rules={{
                required: `Lütfen bir Açıklama giriniz`,
              }}
              defaultValue={defaultValues.contents?.[1]?.body}
              control={control}
              name="contents.1.body"
              render={({ field: { onChange } }) => {
                return (
                  <textarea
                    ref={enBody}
                    className="form-control"
                    defaultValue={defaultValues.contents?.[1]?.body}
                    placeholder="İngilizce Açıklama"
                    onChange={onChange}
                  />
                );
              }}
            />
            <FormFeedback>{errors?.contents?.[1]?.body?.message}</FormFeedback>
          </FormGroup>
        </FormGroup>
      </FormGroup>
      <Button disabled={disabled} type="submit" color="primary" className="d-block rounded-md ml-auto">
        {'Sonraki'}
      </Button>
    </Form>
  );
}

import React from 'react';
import { type RouteObject } from 'react-router-dom';

// Dashboard
import { Default } from 'components/dashboard';
import BazzarAds from 'pages/ads/bazzarAds';
import BazzarAdsController from 'pages/ads/bazzarAds/bazzarAdsController';
import BazaarAdsReport from 'pages/ads/bazzarAds/report';
import BazaarAdsReportDetail from 'pages/ads/bazzarAds/report/details';
import JobAds from 'pages/ads/jobAds';
import JobAdsDetail from 'pages/ads/jobAds/adsController';
import JobApplyDetail from 'pages/ads/jobAds/applyDetail';
import JobAdsReport from 'pages/ads/jobAds/report';
import JobAdsReportDetail from 'pages/ads/jobAds/report/details';
import Analytics from 'pages/analytics';
import BazzarCategories from 'pages/content-management/bazzar/bazarCategories';
import BazaarCategoryDetail from 'pages/content-management/bazzar/bazarCategories/details';
import Companies from 'pages/content-management/company/companies';
import CompaniesDetail from 'pages/content-management/company/companies/details';
import CompanyCategories from 'pages/content-management/company/companyCategories';
import CompanyCategoryDetail from 'pages/content-management/company/companyCategories/details';
import CompanyLogos from 'pages/content-management/company/companyLogos';
import CompanySizes from 'pages/content-management/company/companySizes';
import CompanySizeDetail from 'pages/content-management/company/companySizes/details';
import DocumentTypes from 'pages/content-management/company/documentTyps';
import DocumentTypeDetail from 'pages/content-management/company/documentTyps/documentTypeDetail';
import EmlakAds from 'pages/content-management/emlak/ads';
import EmlakAdsDetail from 'pages/content-management/emlak/ads/detail';
import EmlakAdsReportReasons from 'pages/content-management/emlak/adsReportReasons';
import EmlakAdsReportReasonsDetail from 'pages/content-management/emlak/adsReportReasons/detail';
import EmlakCategoriesList from 'pages/content-management/emlak/categories';
import EmlakCategoryDetail from 'pages/content-management/emlak/categories/details';
import SpecialLocationsList from 'pages/content-management/emlak/specialLocation';
import SpecialLocationDetails from 'pages/content-management/emlak/specialLocation/detail';
import EmlakTypesList from 'pages/content-management/emlak/types';
import EmlakTypeDetail from 'pages/content-management/emlak/types/details';
import AgeRanges from 'pages/content-management/employment/ageRange';
import AgeRangeDetail from 'pages/content-management/employment/ageRange/ageRangeDetail';
import ExperienceExpectation from 'pages/content-management/employment/experienceExpectation';
import ExperienceExpectationDetail from 'pages/content-management/employment/experienceExpectation/details';
import JobCategories from 'pages/content-management/employment/jobCategories';
import JobCategoryDetail from 'pages/content-management/employment/jobCategories/detail';
import Languages from 'pages/content-management/employment/languages';
import LanguageDetail from 'pages/content-management/employment/languages/details';
import Softwares from 'pages/content-management/employment/softwares';
import SoftwareDetail from 'pages/content-management/employment/softwares/details';
import Universities from 'pages/content-management/employment/university';
import UniversityDetail from 'pages/content-management/employment/university/details';
import UniversitySection from 'pages/content-management/employment/universitySections';
import UniversitySectionDetail from 'pages/content-management/employment/universitySections/details';
import WorkRejectionReasons from 'pages/content-management/employment/workRejectionReason';
import WorkRejectionDetail from 'pages/content-management/employment/workRejectionReason/details';
import Slider from 'pages/content-management/slider';
import SliderDetails from 'pages/content-management/slider/details';
import Fuar from 'pages/fuar/fuar';
import FuarDetail from 'pages/fuar/fuar/detail';
import Hotel from 'pages/fuar/hotel';
import HotelDetail from 'pages/fuar/hotel/detail';
import Location from 'pages/fuar/location';
import CityDetail from 'pages/fuar/location/cityDetail';
import LocationDetail from 'pages/fuar/location/details';
import Magazine from 'pages/magazine';
import MagazineDetail from 'pages/magazine/detail';
import EditionMagazineDetail from 'pages/magazine/editionDetail';
import Notification from 'pages/notification';
import NotificationDetails from 'pages/notification/detail';
import Schools from 'pages/school';
import SchoolsDetails from 'pages/school/schoolDetail';
import StudentDashboard from 'pages/schoolDashboard';
import SchoolAnalytics from 'pages/schoolDashboard/analytics';
import StudentManagement from 'pages/schoolDashboard/student-management';
import StudentDetail from 'pages/schoolDashboard/student-management/detail';
import Setting from 'pages/setting';
import MobileAppOsVersion from 'pages/setting/mobileAppOsVersion';
import AdminsTable from 'pages/user-management/admins';
import DeleteReasons from 'pages/user-management/deleteReasons';
import DeleteReasonDetail from 'pages/user-management/deleteReasons/detail';
import AddNewEmployee from 'pages/user-management/employee';
import EmployeeDetails from 'pages/user-management/employee/detail';
import AddNewEmployer from 'pages/user-management/employer';
import NewAdmin from 'pages/user-management/pages/editInfo';
import UserDetails from 'pages/user-management/userDetails';
import UsersTable from 'pages/user-management/users';
import { ROUTE_CONFIG } from 'route/config';
import { APP_ROUTE_SECTION_NAME, Permission } from 'tools/enums';
import { dashboardAppRouteKeyType, schoolDashboardAppRouteKeyType, superDashboardAppRouteKeyType } from 'tools/types';
import { SCHOOL_ADMIN_APP_ROUTES } from './appRoutes';
import BanReasons from 'pages/user-management/banReasons';
import BanReasonDetail from 'pages/user-management/banReasons/detail';
import DeletedUsers from 'pages/user-management/users/deletedUsers';
import DocumentRejectionReasons from 'pages/content-management/company/documentRejectionReasons';
import DocumentRejectionReasonDetail from 'pages/content-management/company/documentRejectionReasons/detail';
import HolydayManagement from 'pages/exchange/holydayManagement';
import CompanyCategoriesV2 from 'pages/content-management/company/companyCategoriesV2/index';
import CompanyCategoryDetailV2 from 'pages/content-management/company/companyCategoriesV2/details';
import CompanyLeaderReasons from 'pages/content-management/company/leaderReasons';
import CompanyLeaderReasonsDetail from 'pages/content-management/company/leaderReasons/detail';

type adminPermissionRouteObject = (Omit<RouteObject, 'path'> & {
  permissionKeys?: Permission[];
  path: dashboardAppRouteKeyType | string;
})[];
type schoolPermissionRouteObject = (Omit<RouteObject, 'path'> & {
  permissionKeys?: Permission[];
  path: schoolDashboardAppRouteKeyType | string;
})[];
type superPermissionRouteObject = (Omit<RouteObject, 'path'> & {
  permissionKeys?: Permission[];
  path: superDashboardAppRouteKeyType | string;
})[];

const ADMIN_DASHBOARD_ROUTES: adminPermissionRouteObject = [
  {
    path: ROUTE_CONFIG.dashboard.path,
    element: <Default />,
    permissionKeys: ROUTE_CONFIG.dashboard.permissionKeys,
  },
  // user management
  {
    path: ROUTE_CONFIG.admins.path,
    element: <AdminsTable />,
    permissionKeys: ROUTE_CONFIG.admins.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.adminDetail.path,
    element: <NewAdmin />,
    permissionKeys: ROUTE_CONFIG.adminDetail.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.users.path,
    element: <UsersTable />,
    permissionKeys: ROUTE_CONFIG.users.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.deletedUsers.path,
    element: <DeletedUsers />,
    permissionKeys: ROUTE_CONFIG.users.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.deleteReasons.path,
    element: <DeleteReasons />,
    permissionKeys: ROUTE_CONFIG.deleteReasons.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.deleteReasonsDetail.path,
    element: <DeleteReasonDetail />,
    permissionKeys: ROUTE_CONFIG.deleteReasonsDetail.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.banReasons.path,
    element: <BanReasons />,
    permissionKeys: ROUTE_CONFIG.banReasons.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.banReasonDetail.path,
    element: <BanReasonDetail />,
    permissionKeys: ROUTE_CONFIG.banReasonDetail.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.employer.path,
    element: <AddNewEmployer />,
    permissionKeys: ROUTE_CONFIG.employer.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.employee.path,
    element: <AddNewEmployee />,
    permissionKeys: ROUTE_CONFIG.employee.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.employeeDetail.path,
    element: <EmployeeDetails />,
    permissionKeys: ROUTE_CONFIG.employeeDetail.permissionKeys,
  },

  {
    path: ROUTE_CONFIG.usersDetails.path,
    element: <UserDetails />,
    permissionKeys: ROUTE_CONFIG.usersDetails.permissionKeys,
  },
  // end user management

  // content management

  // employment
  {
    path: ROUTE_CONFIG.jobCategories.path,
    element: <JobCategories />,
    permissionKeys: ROUTE_CONFIG.jobCategories.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.jobCategoryDetails.path,
    element: <JobCategoryDetail />,
    permissionKeys: ROUTE_CONFIG.jobCategoryDetails.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.experienceExpectation.path,
    element: <ExperienceExpectation />,
    permissionKeys: ROUTE_CONFIG.experienceExpectation.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.experienceExpectationDetails.path,
    element: <ExperienceExpectationDetail />,
    permissionKeys: ROUTE_CONFIG.experienceExpectationDetails.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.workRejectionReasons.path,
    element: <WorkRejectionReasons />,
    permissionKeys: ROUTE_CONFIG.workRejectionReasons.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.workRejectionReasonsDetails.path,
    element: <WorkRejectionDetail />,
    permissionKeys: ROUTE_CONFIG.workRejectionReasonsDetails.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.ageRange.path,
    element: <AgeRanges />,
    permissionKeys: ROUTE_CONFIG.ageRange.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.ageRangeDetail.path,
    element: <AgeRangeDetail />,
    permissionKeys: ROUTE_CONFIG.ageRangeDetail.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.universities.path,
    element: <Universities />,
    permissionKeys: ROUTE_CONFIG.universities.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.universitiesDetails.path,
    element: <UniversityDetail />,
    permissionKeys: ROUTE_CONFIG.universitiesDetails.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.universitySections.path,
    element: <UniversitySection />,
    permissionKeys: ROUTE_CONFIG.universitySections.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.universitySectionsDetails.path,
    element: <UniversitySectionDetail />,
    permissionKeys: ROUTE_CONFIG.universitySectionsDetails.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.softwares.path,
    element: <Softwares />,
    permissionKeys: ROUTE_CONFIG.softwares.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.softwaresDetails.path,
    element: <SoftwareDetail />,
    permissionKeys: ROUTE_CONFIG.softwaresDetails.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.languages.path,
    element: <Languages />,
    permissionKeys: ROUTE_CONFIG.languages.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.languagesDetails.path,
    element: <LanguageDetail />,
    permissionKeys: ROUTE_CONFIG.languagesDetails.permissionKeys,
  },

  // end employment

  // companies
  {
    path: ROUTE_CONFIG.companies.path,
    element: <Companies />,
    permissionKeys: ROUTE_CONFIG.companies.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.companiesDetails.path,
    element: <CompaniesDetail />,
    permissionKeys: ROUTE_CONFIG.companiesDetails.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.companyCategories.path,
    element: <CompanyCategories />,
    permissionKeys: ROUTE_CONFIG.companyCategories.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.companyCategoriesV2.path,
    element: <CompanyCategoriesV2 />,
    permissionKeys: ROUTE_CONFIG.companyCategoriesV2.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.companyCategoryDetail.path,
    element: <CompanyCategoryDetail />,
    permissionKeys: ROUTE_CONFIG.companyCategoryDetail.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.companyCategoryDetailV2.path,
    element: <CompanyCategoryDetailV2 />,
    permissionKeys: ROUTE_CONFIG.companyCategoryDetailV2.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.companySizes.path,
    element: <CompanySizes />,
    permissionKeys: ROUTE_CONFIG.companySizes.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.companySizeDetail.path,
    element: <CompanySizeDetail />,
    permissionKeys: ROUTE_CONFIG.companySizeDetail.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.companyLogos.path,
    element: <CompanyLogos />,
    permissionKeys: ROUTE_CONFIG.companyLogos.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.documentTypes.path,
    element: <DocumentTypes />,
    permissionKeys: ROUTE_CONFIG.documentTypes.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.documentTypeDetail.path,
    element: <DocumentTypeDetail />,
    permissionKeys: ROUTE_CONFIG.documentTypeDetail.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.documentRejectionReasons.path,
    element: <DocumentRejectionReasons />,
    permissionKeys: ROUTE_CONFIG.documentRejectionReasons.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.documentRejectionReasonsDetail.path,
    element: <DocumentRejectionReasonDetail />,
    permissionKeys: ROUTE_CONFIG.documentRejectionReasonsDetail.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.companyLeaderReasons.path,
    element: <CompanyLeaderReasons />,
    permissionKeys: ROUTE_CONFIG.companyLeaderReasons.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.companyLeaderReasonsDetail.path,
    element: <CompanyLeaderReasonsDetail />,
    permissionKeys: ROUTE_CONFIG.companyLeaderReasonsDetail.permissionKeys,
  },
  // end companies

  // bazaar
  {
    path: ROUTE_CONFIG.bazaarCategories.path,
    element: <BazzarCategories />,
    permissionKeys: ROUTE_CONFIG.bazaarCategories.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.bazaarCategoriesDetails.path,
    element: <BazaarCategoryDetail />,
    permissionKeys: ROUTE_CONFIG.bazaarCategoriesDetails.permissionKeys,
  },
  // end bazaar

  // slider
  {
    path: ROUTE_CONFIG.slider.path,
    element: <Slider />,
    permissionKeys: ROUTE_CONFIG.slider.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.sliderDetail.path,
    element: <SliderDetails />,
    permissionKeys: ROUTE_CONFIG.sliderDetail.permissionKeys,
  },
  // end slider

  // emlak
  {
    path: ROUTE_CONFIG.specialLocation.path,
    element: <SpecialLocationsList />,
    permissionKeys: ROUTE_CONFIG.specialLocation.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.specialLocationDetail.path,
    element: <SpecialLocationDetails />,
    permissionKeys: ROUTE_CONFIG.specialLocation.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.emlakTypes.path,
    element: <EmlakTypesList />,
    permissionKeys: ROUTE_CONFIG.emlakTypes.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.emlakTypesDetails.path,
    element: <EmlakTypeDetail />,
    permissionKeys: ROUTE_CONFIG.emlakTypesDetails.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.emlakCategories.path,
    element: <EmlakCategoriesList />,
    permissionKeys: ROUTE_CONFIG.emlakCategories.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.emlakCategoryDetail.path,
    element: <EmlakCategoryDetail />,
    permissionKeys: ROUTE_CONFIG.emlakCategoryDetail.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.emlakAds.path,
    element: <EmlakAds />,
    permissionKeys: ROUTE_CONFIG.emlakAds.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.emlakAdsDetail.path,
    element: <EmlakAdsDetail />,
    permissionKeys: ROUTE_CONFIG.emlakAdsDetail.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.emlakAdsReportReasons.path,
    element: <EmlakAdsReportReasons />,
    permissionKeys: ROUTE_CONFIG.emlakAdsReportReasons.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.emlakAdsReportReasonsDetail.path,
    element: <EmlakAdsReportReasonsDetail />,
    permissionKeys: ROUTE_CONFIG.emlakAdsReportReasonsDetail.permissionKeys,
  },
  // end emlak

  // end content management

  // fuar
  {
    path: ROUTE_CONFIG.exhibition.path,
    element: <Fuar />,
    permissionKeys: ROUTE_CONFIG.exhibition.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.exhibitionDetails.path,
    element: <FuarDetail />,
    permissionKeys: ROUTE_CONFIG.exhibitionDetails.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.hotel.path,
    element: <Hotel />,
    permissionKeys: ROUTE_CONFIG.hotel.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.hotelDetail.path,
    element: <HotelDetail />,
    permissionKeys: ROUTE_CONFIG.hotelDetail.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.location.path,
    element: <Location />,
    permissionKeys: ROUTE_CONFIG.location.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.locationDetail.path,
    element: <LocationDetail />,
    permissionKeys: ROUTE_CONFIG.locationDetail.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.locationCity.path,
    element: <CityDetail />,
    permissionKeys: ROUTE_CONFIG.locationCity.permissionKeys,
  },
  // end fuar

  // analytics
  {
    path: ROUTE_CONFIG.analytics.path,
    element: <Analytics />,
    permissionKeys: ROUTE_CONFIG.analytics.permissionKeys,
  },
  // analytics

  // end schools

  // schools
  {
    path: ROUTE_CONFIG.schools.path,
    element: <Schools />,
    permissionKeys: ROUTE_CONFIG.schools.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.schoolDetail.path,
    element: <SchoolsDetails />,
    permissionKeys: ROUTE_CONFIG.schoolDetail.permissionKeys,
  },
  // end school

  // magazine
  {
    path: ROUTE_CONFIG.magazine.path,
    element: <Magazine />,
    permissionKeys: ROUTE_CONFIG.magazine.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.magazineDetail.path,
    element: <MagazineDetail />,
    permissionKeys: ROUTE_CONFIG.magazineDetail.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.magazineDetailEdition.path,
    element: <EditionMagazineDetail />,
    permissionKeys: ROUTE_CONFIG.magazineDetailEdition.permissionKeys,
  },
  // end magazine

  // ads
  {
    path: ROUTE_CONFIG.jobAdsReport.path,
    element: <JobAdsReport />,
    permissionKeys: ROUTE_CONFIG.jobAdsReport.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.jobAdsReportDetail.path,
    element: <JobAdsReportDetail />,
    permissionKeys: ROUTE_CONFIG.jobAdsReportDetail.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.bazaarAdsReportDetail.path,
    element: <BazaarAdsReportDetail />,
    permissionKeys: ROUTE_CONFIG.bazaarAdsReportDetail.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.jobAds.path,
    element: <JobAds />,
    permissionKeys: [Permission.EMPLOYMENT_READ_AD_LIST],
  },
  {
    path: ROUTE_CONFIG.bazaarAdsReport.path,
    element: <BazaarAdsReport />,
    permissionKeys: ROUTE_CONFIG.bazaarAdsReport.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.jobAdsDetail.path,
    element: <JobAdsDetail />,
    permissionKeys: ROUTE_CONFIG.jobAdsDetail.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.jobApplyListDetails.path,
    element: <JobApplyDetail />,
    permissionKeys: ROUTE_CONFIG.jobApplyListDetails.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.bazzarAds.path,
    element: <BazzarAds />,
    permissionKeys: ROUTE_CONFIG.bazzarAds.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.bazzarAdsDetail.path,
    element: <BazzarAdsController />,
    permissionKeys: ROUTE_CONFIG.bazzarAdsDetail.permissionKeys,
  },
  // end ads

  // notification

  {
    path: ROUTE_CONFIG.notification.path,
    element: <Notification />,
    permissionKeys: ROUTE_CONFIG.notification.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.notificationDetail.path,
    element: <NotificationDetails />,
    permissionKeys: ROUTE_CONFIG.notificationDetail.permissionKeys,
  },
  // end notification

  // exchange
  {
    path: ROUTE_CONFIG.holidyManagement.path,
    element: <HolydayManagement />,
    permissionKeys: ROUTE_CONFIG.holidyManagement.permissionKeys,
  },
  // end exchange

  // setting
  {
    path: ROUTE_CONFIG.setting.path,
    element: <Setting />,
    permissionKeys: ROUTE_CONFIG.setting.permissionKeys,
  },
  {
    path: ROUTE_CONFIG.mobileAppOsVersions.path,
    element: <MobileAppOsVersion />,
    permissionKeys: ROUTE_CONFIG.mobileAppOsVersions.permissionKeys,
  },
  // end setting
];

export const SCHOOLADMIN_DASHBOARD_ROUTES: schoolPermissionRouteObject = [
  {
    path: SCHOOL_ADMIN_APP_ROUTES.schoolAdmin,
    element: <StudentDashboard />,
    permissionKeys: [Permission.EMPLOYMENT_READ_SCHOOL_STUDENT_LIST],
  },
  {
    path: SCHOOL_ADMIN_APP_ROUTES.studentManagement,
    element: <StudentManagement />,
    permissionKeys: [Permission.EMPLOYMENT_READ_SCHOOL_STUDENT_LIST],
  },
  {
    path: SCHOOL_ADMIN_APP_ROUTES.studentDetail,
    element: <StudentDetail />,
    permissionKeys: [Permission.EMPLOYMENT_READ_SCHOOL_STUDENT_DETAILS],
  },
  {
    path: SCHOOL_ADMIN_APP_ROUTES.analytics,
    element: <SchoolAnalytics />,
  },
];

export const SUPERADMIN_DASHBOARD_ROUTES: superPermissionRouteObject = ADMIN_DASHBOARD_ROUTES;

export default {
  [APP_ROUTE_SECTION_NAME.ADMIN_DASHBOARD_ROUTES]: ADMIN_DASHBOARD_ROUTES,
  [APP_ROUTE_SECTION_NAME.SCHOOLADMIN_DASHBOARD_ROUTES]: SCHOOLADMIN_DASHBOARD_ROUTES,
  [APP_ROUTE_SECTION_NAME.SUPERADMIN_DASHBOARD_ROUTES]: SUPERADMIN_DASHBOARD_ROUTES,
};

import SubmitButton from 'components/buttons/submitButton';
import ShowError from 'components/showError';
import useMutation from 'hooks/useMutation';
import { newComponentsProps } from 'pages/user-management/employer/index';
import React, { FC, memo, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Col, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { AddOrUpdateWorkingDays } from 'services/graphql/queries/companies/createCompany.graphql';
import {
  end,
  entrance,
  please,
  save,
  select,
  time,
  update,
  updated,
  workingDays,
  workingHours,
} from 'tools/i18n/constants/i18n';
import { toNull, toUpperCase } from 'tools/methods';
import { stringish } from 'tools/types';
import { formsNameEnum } from './types';

type CompanyWorkTimeProps = newComponentsProps;

type t_defaultValue = {
  customError?: string;
  sunday: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  startAt: stringish;
  endAt: stringish;
};

const CompanyWorkTime: FC<CompanyWorkTimeProps> = (props) => {
  const { isActive, componentsResponse, componentsValues, handleChangeComponentState, state } = props;
  const { t } = useTranslation();
  const [execute, { loading }] = useMutation(AddOrUpdateWorkingDays);
  const DEFAULT_VALUES: t_defaultValue = {
    endAt: toNull(componentsValues?.workingDaysAndHours?.endAt) as string | null,
    startAt: toNull(componentsValues?.workingDaysAndHours?.startAt) as string | null,
    friday: !!componentsValues?.workingDaysAndHours?.friday,
    monday: !!componentsValues?.workingDaysAndHours?.monday,
    saturday: !!componentsValues?.workingDaysAndHours?.saturday,
    sunday: !!componentsValues?.workingDaysAndHours?.sunday,
    thursday: !!componentsValues?.workingDaysAndHours?.thursday,
    tuesday: !!componentsValues?.workingDaysAndHours?.tuesday,
    wednesday: !!componentsValues?.workingDaysAndHours?.wednesday,
  };
  const newDate = new Date();
  const splitedStartDate = DEFAULT_VALUES.startAt ? DEFAULT_VALUES.startAt.split(':') : undefined;
  if (splitedStartDate) {
    newDate.setHours(parseInt(splitedStartDate[0], 10));
    newDate.setMinutes(parseInt(splitedStartDate[1], 10));
  }
  const startToday = newDate;
  const splitedEndDate = DEFAULT_VALUES.endAt ? DEFAULT_VALUES.endAt.split(':') : undefined;
  const endDate = new Date();
  if (splitedEndDate) {
    endDate.setHours(parseInt(splitedEndDate[0], 10));
    endDate.setMinutes(parseInt(splitedEndDate[1], 10));
  }
  const endToday = endDate;

  const [selectedStartTime, setSelectedStartTime] = useState(DEFAULT_VALUES?.startAt ? startToday : new Date());
  const [selectedEndTime, setSelectedEndTime] = useState(DEFAULT_VALUES?.endAt ? endToday : new Date());
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<t_defaultValue>({
    defaultValues: DEFAULT_VALUES,
  });

  const onSubmit = (data: t_defaultValue) => {
    setValue('customError', '');
    if (isActive) {
      execute({
        variables: {
          ...componentsResponse,
          ...data,
        },
      })
        .then((res) => {
          console.log(res);
          if (res.data?.EmploymentAddOrUpdateEmployerWorkingDaysAndHours) {
            if (state.isCompleted) {
              toast.success(`${t(updated)}`);
            }
            handleChangeComponentState(formsNameEnum.CompanyWorkTime, true);
          } else {
            //@ts-ignore
            setValue('customError', res.errors?.message);
          }
        })
        .catch((err) => {
          console.error(err);
          //@ts-ignore
          setValue('customError', errors?.message);
        });
    }
  };
  useEffect(() => {
    if (componentsValues?.workingDaysAndHours?.startAt && componentsValues?.workingDaysAndHours?.endAt) {
      handleChangeComponentState(formsNameEnum.CompanyWorkTime, true);
    }
  }, [componentsValues]);

  return (
    <Col xxl={7} className="p-2 pb-4 mx-auto d-flex flex-column">
      <Form onSubmit={handleSubmit(onSubmit)} aria-disabled={!isActive}>
        {!!getValues('customError') && (
          <FormGroup>
            <ShowError>{getValues('customError')}</ShowError>
          </FormGroup>
        )}
        <Col xl={12} xxl={12}>
          <FormGroup>
            <h6 className="mb-3">{`${t(workingDays)}`}</h6>
            <WeekDaySelection defaultValues={DEFAULT_VALUES} setValue={setValue} isActive={isActive} />
          </FormGroup>
          <FormGroup>
            <Label>{t(workingHours)}</Label>
            <div className="d-flex">
              <div className="mr-2 flex-grow-1">
                <Controller
                  control={control}
                  name="startAt"
                  defaultValue={DEFAULT_VALUES.startAt}
                  rules={{ required: `${t(please)} ${t(select)} ${t(time)}` }}
                  render={({ field: { onChange } }) => {
                    return (
                      <DatePicker
                        onChange={(date) => {
                          if (date) {
                            setSelectedStartTime(date);
                            const concatTime = `${date?.getHours() < 10 ? `0${date?.getHours()}` : date?.getHours()}:${
                              date?.getMinutes() < 10 ? `0${date?.getMinutes()}` : date?.getMinutes()
                            }`;
                            onChange(concatTime);
                          }
                        }}
                        showTimeSelectOnly
                        selected={selectedStartTime}
                        dateFormat="H:mm"
                        showTimeSelect
                        customInput={
                          <Input
                            defaultValue={DEFAULT_VALUES?.startAt || undefined}
                            name={entrance}
                            placeholder={t(entrance)}
                            disabled={loading || !isActive}
                            aria-disabled={loading || !isActive}
                          />
                        }
                        name={'startAt'}
                      />
                    );
                  }}
                />
                <FormFeedback>{errors?.startAt?.message}</FormFeedback>
              </div>
              <div className="flex-grow-1">
                <Controller
                  control={control}
                  name="endAt"
                  defaultValue={DEFAULT_VALUES.endAt}
                  rules={{ required: `${t(please)} ${t(select)} ${t(time)}` }}
                  render={({ field: { onChange } }) => {
                    return (
                      <DatePicker
                        onChange={(date) => {
                          if (date) {
                            setSelectedEndTime(date);
                            const concatTime = `${date?.getHours() < 10 ? `0${date?.getHours()}` : date?.getHours()}:${
                              date?.getMinutes() < 10 ? `0${date?.getMinutes()}` : date?.getMinutes()
                            }`;
                            onChange(concatTime);
                          }
                        }}
                        showTimeSelectOnly
                        selected={selectedEndTime}
                        dateFormat="H:mm"
                        showTimeSelect
                        customInput={
                          <Input
                            defaultValue={DEFAULT_VALUES?.endAt || undefined}
                            name={end}
                            placeholder={t(end)}
                            disabled={loading || !isActive}
                            aria-disabled={loading || !isActive}
                          />
                        }
                        name={'endAt'}
                      />
                    );
                  }}
                />
                <FormFeedback>{errors?.endAt?.message}</FormFeedback>
              </div>
            </div>
          </FormGroup>
        </Col>
        {isActive && (
          <SubmitButton className="w-100 mt-4" loading={loading}>
            {t(state.isCompleted ? update : save)}
          </SubmitButton>
        )}
      </Form>
    </Col>
  );
};
export default memo(CompanyWorkTime);

interface WeekDaySelectionProps {
  isActive: boolean;
  setValue: UseFormSetValue<t_defaultValue>;
  defaultValues: t_defaultValue;
}
type weekDay = {
  label: keyof Omit<t_defaultValue, 'startAt' | 'endAt'>;
  value: 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';
};

const WeekDaySelection: FC<WeekDaySelectionProps> = ({ isActive, setValue, defaultValues }) => {
  const [selected, setSelected] = useState<weekDay[]>([]);

  const WEEK_DAY = [
    { label: 'monday', value: 'monday' },
    { label: 'tuesday', value: 'tuesday' },
    { label: 'wednesday', value: 'wednesday' },
    { label: 'thursday', value: 'thursday' },
    { label: 'friday', value: 'friday' },
    { label: 'saturday', value: 'saturday' },
    { label: 'sunday', value: 'sunday' },
  ];
  const handleSelect = (key: weekDay) => {
    console.log(key);
    if (isActive) {
      setSelected((prev) => {
        const findMatch = prev?.find((day) => day.label === key.label);
        setValue(key.label, !findMatch);
        if (findMatch) {
          return prev?.filter((day) => day.label !== key.label);
        } else return [...(prev || []), key];
      });
    }
  };
  useEffect(() => {
    const keys = Object.keys(defaultValues);
    const selectedDays: weekDay[] = [];
    keys.forEach((key) => {
      if (typeof defaultValues[key] === 'boolean' && defaultValues[key]) {
        selectedDays.push({ label: key, value: key } as weekDay);
      }
    });
    setSelected(selectedDays);
  }, []);
  return (
    <div className={`weekday-selection `} aria-disabled={!isActive}>
      {WEEK_DAY.map((day: weekDay) => {
        const ariaSelected: boolean = !!selected?.find((weekDay) => weekDay.value === day.value);
        return (
          <div key={day.value} role={'button'} onClick={() => handleSelect(day)} aria-selected={ariaSelected}>
            {toUpperCase(`${day.value[0]}${day.value[1]}${day.value[2]}`)}
          </div>
        );
      })}
    </div>
  );
};

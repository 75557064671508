import { gql } from '@apollo/client';

export const GET_BAZZAR_CATEGORIES = gql`
  query getBazzarCategories {
    findAllBazaarCategories(bazaarCategoryListDto: { query: "" }) {
      id
      title
      status
      icon
      picture
      requireModel

      language {
        language
        translation
      }
      child {
        id
        language {
          language
          translation
        }

        title
        status
        icon
        status
        picture
        requireModel
        child {
          language {
            language
            translation
          }
          id
          title
          status
          icon
          status
          picture
          requireModel
          child {
            language {
              language
              translation
            }
            id
            title
            status
            icon
            status
            picture
            requireModel
            createdAt
            updatedAt
            child {
              language {
                language
                translation
              }
              id
              title
              status
              icon
              status
              picture
              requireModel
              createdAt
              updatedAt
              child {
                language {
                  language
                  translation
                }
                id
                title
                status
                icon
                status
                picture
                requireModel
                createdAt
                updatedAt
                child {
                  language {
                    language
                    translation
                  }
                  id
                  title
                  status
                  icon
                  status
                  picture
                  requireModel
                  createdAt
                  updatedAt
                  child {
                    language {
                      language
                      translation
                    }
                    id
                    title
                    status
                    icon
                    status
                    picture
                    requireModel
                    createdAt
                    updatedAt
                    child {
                      language {
                        language
                        translation
                      }
                      id
                      title
                      status
                      icon
                      status
                      picture
                      requireModel
                      createdAt
                      updatedAt
                      child {
                        language {
                          language
                          translation
                        }
                        id
                        title
                        status
                        icon
                        status
                        picture
                        requireModel
                        createdAt
                        updatedAt
                        child {
                          language {
                            language
                            translation
                          }
                          id
                          title
                          status
                          icon
                          status
                          picture
                          requireModel
                          createdAt
                          updatedAt
                          child {
                            language {
                              language
                              translation
                            }
                            id
                            title
                            status
                            icon
                            status
                            picture
                            requireModel
                            createdAt
                            updatedAt
                            child {
                              language {
                                language
                                translation
                              }
                              id
                              title
                              status
                              icon
                              status
                              picture
                              requireModel
                              createdAt
                              updatedAt
                              child {
                                language {
                                  language
                                  translation
                                }
                                id
                                title
                                status
                                icon
                                status
                                picture
                                requireModel
                                createdAt
                                updatedAt
                                child {
                                  language {
                                    language
                                    translation
                                  }
                                  id
                                  title
                                  status
                                  icon
                                  status
                                  picture
                                  requireModel
                                  createdAt
                                  updatedAt
                                  child {
                                    language {
                                      language
                                      translation
                                    }
                                    id
                                    title
                                    status
                                    icon
                                    status
                                    picture
                                    requireModel
                                    createdAt
                                    updatedAt
                                    child {
                                      language {
                                        language
                                        translation
                                      }
                                      id
                                      title
                                      status
                                      icon
                                      status
                                      picture
                                      requireModel
                                      createdAt
                                      updatedAt
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_BAZZAR_CATEGORIES_SLIDER = gql`
  query getBazzarCategories {
    findAllBazaarCategories(bazaarCategoryListDto: { query: "" }) {
      id
      title
      status
      icon
      picture
      requireModel
      child {
        id
      }
      language {
        language
        translation
      }
    }
  }
`;

export const CREATE_BAZZAR_CATEGORY = gql`
  mutation bazzarCategory($parentCategory: Int, $requireModel: Boolean!, $translations: [TranslationsDto!]!) {
    createBazaarCategory(
      bazaarCreateCategoryDto: {
        parentCategory: $parentCategory
        requireModel: $requireModel
        translations: $translations
      }
    ) {
      id
      status
      icon
      picture
      requireModel
      createdAt
      updatedAt
      language {
        language
        translation
      }
    }
  }
`;

export const MUTATE_BAZZAR_CATEGORIES = gql`
  mutation updateBazzarCategory(
    $parentCategory: Int
    $categoryId: Int!
    $requireModel: Boolean!
    $translations: [TranslationsDto!]!
  ) {
    updateBazaarCategory(
      bazaarUpdateCategoryDto: {
        parentCategory: $parentCategory
        requireModel: $requireModel
        translations: $translations
        categoryId: $categoryId
      }
    ) {
      id
      title
      status
      icon
      picture
      requireModel
      createdAt
      updatedAt
      language {
        language
        translation
      }
      child {
        id
        title
        status
        icon
        picture
        requireModel
        createdAt
        updatedAt
      }
    }
  }
`;

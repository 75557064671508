import { useGetStore } from 'hooks';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTE_NAME, ROLES_BACKEND_MATCH, ROUTE_PREFIXES } from 'tools/enums';
import { welcomeToAppDashboard } from 'tools/i18n/constants/i18n';

export const Default = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const role = useGetStore().User.userInfo.role?.[0];
  useEffect(() => {
    if (role && role === ROLES_BACKEND_MATCH['super-admin']) {
      navigate(`/${APP_ROUTE_NAME.dashboard}/${ROUTE_PREFIXES.userManagement}/${APP_ROUTE_NAME.admins}`);
    } else if (role && role === ROLES_BACKEND_MATCH['admin']) {
      navigate(
        `/${APP_ROUTE_NAME.dashboard}/${ROUTE_PREFIXES.contentManagement}/${ROUTE_PREFIXES.employment}/${APP_ROUTE_NAME.jobCategories}`
      );
    }
  }, [role]);
  return <div className="make-center">{`${t(welcomeToAppDashboard)}`}</div>;
};
export default Default;

export enum DOCUMENT_STATUS {
  CONDOMINIUM_OWNERSHIP = 'CONDOMINIUM_OWNERSHIP',
  CO_OWNERSHIP_OF_FLOORS = 'CO_OWNERSHIP_OF_FLOORS',
  JOINT_OWNERSHIP_WITH_TITLE_DEED = 'JOINT_OWNERSHIP_WITH_TITLE_DEED',
  INDEPENDENT_OWNERSHIP_WITH_TITLE_DEED = 'INDEPENDENT_OWNERSHIP_WITH_TITLE_DEED',
  LAND_OWNERSHIP_WITH_TITLE_DEED = 'LAND_OWNERSHIP_WITH_TITLE_DEED',
  UNKNOWN = 'UNKNOWN',
}

export enum FROM_WHO {
  PERSONAL = 'PERSONAL',
  REAL_ESTATE = 'REAL_ESTATE',
}

export enum HOUSING_STATUS {
  OCCUPANCY_BY_OWNER = 'OCCUPANCY_BY_OWNER',
  OCCUPANCY_BY_TENANT = 'OCCUPANCY_BY_TENANT',
  EMPTY_AND_READY_TO_DELIVERY = 'EMPTY_AND_READY_TO_DELIVERY',
}

export enum STATUS {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  DE_ACTIVE = 'DE_ACTIVE',
  ARCHIVE = 'ARCHIVE',
  EXPIRED = 'EXPIRED',
  BAN = 'BAN',
}

export enum POSTER_INITI_UPDATE {
  INIT = 'INIT',
  UPDATE = 'UPDATE',
}
export enum OPERATOR {
  BY_USER = 'BY_USER',
  BY_ADMIN = 'BY_ADMIN',
  BY_SYSTEM = 'BY_SYSTEM',
}

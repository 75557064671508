import { ApolloError } from '@apollo/client';
import { Loading } from 'components';
import { SearchForm } from 'components/forms';
import Icon from 'components/icons';
import { BlueTickIcon } from 'components/icons/customeIcon';
import Modal, { t_modal_action } from 'components/modal';
import { t_toggleValueFunc, useToggle, useUpdateEffect } from 'hooks';
import useMutation from 'hooks/useMutation';
import PagesLayout from 'pages/components/layout';
import useLogos from 'pages/content-management/company/companyLogos/useCompanyLogos';
import React, { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Button, Card, CardBody, CardHeader, Col } from 'reactstrap';
import { DELETE_COMPANY_LOGOS } from 'services/graphql/queries/companyLogos';
import styled from 'styled-components';
import { This, areYouSureToRemove, cancel, company, logo, remove, search } from 'tools/i18n/constants/i18n';

export default function CompanyLogos() {
  const { data, loading, error, fetchMore, count: c } = useLogos();
  const [refetchLoading, toggleRefetchLoading] = useToggle(false);
  const [searchLoading, toggleSearchLoading] = useToggle(false);
  const [realData, setRealData] = useState<t_logo[]>(data);
  const [count, setCount] = useState(c);
  const currentPage = useRef(1);
  const currentKeyword = useRef<string | undefined>(undefined);
  const hasMore = count > realData?.length;
  const handleNext = () => {
    toggleRefetchLoading();
    console.log('##########');
    fetchMore({
      variables: {
        page: currentPage.current + 1,
        keyword: currentKeyword.current,
      },
    })
      .then((result: any) => {
        setRealData((prev) => [...prev, ...result.data.EmploymentEmployersLogoList.data]);
        setCount(result.data.EmploymentEmployersLogoList.count);
        currentPage.current += 1;
      })
      .finally(() => {
        toggleRefetchLoading();
      });
  };
  const handleSearch = (keyword: string) => {
    if (keyword) {
      currentKeyword.current = keyword;
    } else {
      currentKeyword.current = undefined;
    }
    toggleSearchLoading();
    fetchMore({
      variables: {
        page: 1,
        keyword,
      },
    })
      .then((result: any) => {
        console.log(result);
        setRealData((prev) => result.data.EmploymentEmployersLogoList.data);
        setCount(result.data.EmploymentEmployersLogoList.count);
        currentPage.current += 1;
      })
      .finally(() => {
        toggleSearchLoading();
      });
  };
  useUpdateEffect(() => {
    if (currentPage.current === 1) {
      setRealData(data);
      setCount(c);
    }
  }, [data]);
  return (
    <PagesLayout>
      <Card className="pb-2">
        <Header onSearch={handleSearch} />
        <Logos
          onNext={handleNext}
          hasMore={hasMore}
          data={realData}
          setData={setRealData}
          loading={loading || searchLoading}
          error={error}
          fetchMore={fetchMore}
          count={count}
        />
        <div className="my-2">
          <Loading loading={refetchLoading} />
        </div>
      </Card>
    </PagesLayout>
  );
}

interface HeaderProps {
  onSearch: (keyword: string) => void;
}

const Header: FC<HeaderProps> = ({ onSearch }) => {
  const { t } = useTranslation();
  return (
    <CardHeader className="py-2 d-flex justify-content-between align-items-center">
      <div className="d-flex">
        <Icon Name="FiAperture" />
        <span className="h6 ml-2">{'Company Logos List'}</span>
      </div>
      <Col xl={6}>
        <SearchForm placeholder={`${t(search)}`} onChange={onSearch} />
      </Col>
    </CardHeader>
  );
};

type t_logo = {
  logo: string;
  companyId: string;
  companyName: string;
  handleDelete: any;
  blueTick?: boolean;
  loading: boolean;
  error?: ApolloError;
};

interface LogosProps {
  data: any[];
  loading: boolean;
  error: any;
  setData: any;
  fetchMore: any;
  onNext: () => void;
  hasMore: boolean;
  count: number;
}
const Logos: FC<LogosProps> = ({ data, loading, error, fetchMore, setData, onNext, hasMore, count }) => {
  const [execute, { loading: loading2, error: error2 }] = useMutation(DELETE_COMPANY_LOGOS);

  const handleDelete = ({ companyId, togglerOpen, togglerOpenConfirm }: any) => {
    execute({
      variables: {
        companyId,
      },
    }).then(() => {
      togglerOpen?.();
      togglerOpenConfirm?.();
      setData((prev: any) => prev.filter((item) => item.companyId !== companyId));
    });
  };

  return (
    <Loading loading={loading} failed={!!error}>
      <CardBody id="scrollableDiv" className="p-0 mt-3 overflow-auto" style={{ height: window.innerHeight - 200 }}>
        <InfiniteScroll
          loader={''}
          style={{ display: 'flex', flexDirection: 'column' }}
          next={() => {
            onNext();
          }}
          hasMore={hasMore}
          dataLength={count}
          scrollableTarget="scrollableDiv"
        >
          <div className="d-flex flex-wrap justify-content-center px-2">
            {data?.map((logo) => {
              return (
                <Logo {...logo} key={logo.companyId} handleDelete={handleDelete} loading={loading2} error={error2} />
              );
            })}
          </div>
        </InfiniteScroll>
      </CardBody>
    </Loading>
  );
};

const Logo: FC<t_logo> = (props) => {
  const { logo, companyId, companyName, handleDelete, loading, blueTick } = props;
  const [open, togglerOpen] = useToggle(false);
  const [openConfirm, togglerOpenConfirm] = useToggle(false);
  const actions: t_modal_action[] = [
    {
      text: 'Delete Logo',
      status: 'danger',
      className: 'shadow-bg-danger border-0',
      onClick: togglerOpenConfirm,
      disabled: loading,
    },
  ];
  const handleDeleteLogo = () => {
    handleDelete({ companyId, togglerOpen, togglerOpenConfirm });
  };
  return (
    <div className="gallery-cart p-2 mb-4 mr-3 shadow rounded">
      <div className="d-flex flex-column align-items-center justify-content-between">
        <div
          style={{ width: '140px', height: '140px', maxHeight: '140px', maxWidth: '140px' }}
          className="rounded-circle position-relative"
        >
          <img
            style={{ width: '140px', height: '140px', maxHeight: '140px' }}
            className="rounded-circle w-100 h-100"
            onClick={() => togglerOpen()}
            alt={companyName}
            src={logo}
          />
          <span
            role="button"
            onClick={() => togglerOpenConfirm()}
            className="make-center position-absolute bg-gray rounded-circle"
            style={{ width: '35px', height: '35px', right: '0', top: '0' }}
          >
            <Icon Name="FiTrash" />
          </span>
        </div>
        <div className="d-flex align-items-center mt-2">
          <div className="make-center">
            <p className="mr-2 mb-0"> {companyName} </p>
            {blueTick && <BlueTickIcon />}
          </div>
        </div>
      </div>
      <ConfirmDelete
        data={props}
        toggleOpen={togglerOpenConfirm}
        isOpen={openConfirm}
        handleDelete={handleDeleteLogo}
        loading={loading}
      />
      {open && (
        <Modal
          style={{ width: '40vw', maxWidth: '50vw', height: '500px', maxHeight: '500px', minHeight: '500px' }}
          actions={actions}
          isOpen={open}
          toggleOpen={togglerOpen}
          data={props}
          footerBorderLess
        >
          <LogoView src={logo} />
        </Modal>
      )}
    </div>
  );
};

const StyledLogo = styled.img`
  width: 40vw;
  max-width: 50vw;
  height: 500px;
  max-height: '500px';
  min-height: '500px';
`;

const LogoView: FC<{ src: string }> = ({ src }) => (
  <StyledLogo
    alt={src}
    style={{ width: '40vw', maxWidth: '50vw', height: '500px', maxHeight: '500px', minHeight: '500px' }}
    src={src}
    loading="lazy"
    className="w-100 h-75 mx-auto rounded"
  />
);

interface ConfirmDeleteProps {
  isOpen: boolean;
  toggleOpen: t_toggleValueFunc;
  handleDelete: VoidFunction;
  data: t_logo;
  loading: boolean;
}
const ConfirmDelete: FC<ConfirmDeleteProps> = ({ isOpen, toggleOpen, handleDelete, data, loading }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} toggleOpen={toggleOpen} style={{ width: '40vw', maxWidth: '40vw' }}>
      <div className="d-flex flex-column mx-auto">
        <div className="d-flex align-items-center mx-auto">
          <img className="rounded-circle" width="100px" height="100px" src={data.logo} alt={data.companyName} />
          <div className="ml-2 d-flex flex-column">
            <h6>{data.companyName}</h6>
          </div>
        </div>
        <h6 className="my-4 mx-auto">{`${t(areYouSureToRemove)} ${t(This)} ${t(company)} ${t(logo)} ?`}</h6>
        <div className="d-flex flex-column">
          <Button
            disabled={loading}
            onClick={handleDelete}
            color="danger"
            className="shadow-bg-danger border-0 make-center"
          >
            <Icon Name="FiTrash" />
            <span className="ml-2">{`${t(remove)} ${t(logo)}`}</span>
          </Button>
          <Button onClick={() => toggleOpen()} color="transparent" className="border rounded mt-2">
            {t(cancel)}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

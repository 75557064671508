import React, { FC } from 'react';
import { Link as L, LinkProps as LProps } from 'react-router-dom';
import { getDashboardLink, t_dashboardLinkKey } from 'route/appRoutes';

interface LinkProps {
  to: t_dashboardLinkKey;
  param?: string;
}

export const Link: FC<Omit<LProps, 'to'> & LinkProps> = (props) => (
  <L {...props} to={getDashboardLink(props.to, props.param)}>
    {props.children}
  </L>
);

export default Link;

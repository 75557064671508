import { Permission } from 'tools/enums';
import { dashboardAppRouteKeyType } from 'tools/types';
import { DASHBOARD_APP_ROUTES } from './appRoutes';

type t_routes_config = {
  [R in dashboardAppRouteKeyType]: {
    key: R;
    path: string;
    permissionKeys: Permission[];
  };
};

export const ROUTE_CONFIG: t_routes_config = {
  dashboard: {
    key: 'dashboard',
    path: DASHBOARD_APP_ROUTES['dashboard'],
    permissionKeys: [],
  },

  // user management
  admins: {
    key: 'admins',
    path: DASHBOARD_APP_ROUTES['admins'],
    permissionKeys: [Permission.READ_ADMINS_LIST],
  },
  adminDetail: {
    key: 'adminDetail',
    path: DASHBOARD_APP_ROUTES['adminDetail'],
    permissionKeys: [Permission.CREATE_NEW_ADMIN, Permission.READ_ADMIN_DETAILS],
  },
  users: {
    key: 'users',
    path: DASHBOARD_APP_ROUTES['users'],
    permissionKeys: [
      Permission.READ_USERS_LIST,
      Permission.READ_USER_DETAILS,
      Permission.AUTHENTICATION_SET_USER_BAN,
      Permission.AUTHENTICATION_SET_USER_UNBAN,
      Permission.AUTHENTICATION_DELETE_USER_ACCOUNT,
    ],
  },
  deletedUsers: {
    key: 'deletedUsers',
    path: DASHBOARD_APP_ROUTES.deletedUsers,
    permissionKeys: [Permission.READ_USERS_LIST, Permission.READ_USER_DETAILS],
  },
  deleteReasons: {
    key: 'deleteReasons',
    path: DASHBOARD_APP_ROUTES['deleteReasons'],
    permissionKeys: [
      Permission.AUTHENTICATION_GET_DELETE_REASON_LIST,
      Permission.AUTHENTICATION_CREATE_DELETE_REASON,
      Permission.AUTHENTICATION_UPDATE_DELETE_REASON,
    ],
  },

  deleteReasonsDetail: {
    key: 'deleteReasonsDetail',
    path: DASHBOARD_APP_ROUTES['deleteReasonsDetail'],
    permissionKeys: [
      Permission.AUTHENTICATION_GET_DELETE_REASON_BY_ID,
      Permission.AUTHENTICATION_CREATE_DELETE_REASON,
      Permission.AUTHENTICATION_UPDATE_DELETE_REASON,
    ],
  },
  banReasons: {
    key: 'banReasons',
    path: DASHBOARD_APP_ROUTES['banReasons'],
    permissionKeys: [
      Permission.AUTHENTICATION_GET_BAN_REASON_LIST,
      Permission.AUTHENTICATION_CREATE_BAN_REASON,
      Permission.AUTHENTICATION_GET_BAN_REASON_BY_ID,
      Permission.AUTHENTICATION_UPDATE_BAN_REASON,
    ],
  },
  banReasonDetail: {
    key: 'banReasonDetail',
    path: DASHBOARD_APP_ROUTES['banReasonDetail'],
    permissionKeys: [
      Permission.AUTHENTICATION_CREATE_BAN_REASON,
      Permission.AUTHENTICATION_GET_BAN_REASON_BY_ID,
      Permission.AUTHENTICATION_UPDATE_BAN_REASON,
    ],
  },
  employer: {
    key: 'employer',
    path: DASHBOARD_APP_ROUTES['employer'],
    permissionKeys: [
      Permission.EMPLOYMENT_UPDATE_EMPLOYER_SPECIALITIES,
      Permission.EMPLOYMENT_UPDATE_EMPLOYER_WORKING_HOURS,
      Permission.EMPLOYMENT_ADD_EMPLOYER_ADDRESS,
      Permission.EMPLOYMENT_READ_EMPLOYER_CATEGORY_LIST,
      Permission.EMPLOYMENT_READ_CITY_AND_COUNTY_LIST,
      Permission.EMPLOYMENT_UPDATE_EMPLOYER_LOGO,
      Permission.AUTHENTICATION_MUTATION_CONTACTS_LEGAL_ACCOUNT,
      Permission.AUTHENTICATION_MUTATION_DETAILS_LEGAL_ACCOUNT,
      Permission.AUTHENTICATION_DELETE_DOCUMENTS_LEGAL_ACCOUNT,
      Permission.AUTHENTICATION_MUTATION_SOCIAL_MEDIA_LEGAL_ACCOUNT,
      Permission.AUTHENTICATION_MUTATION_DESCRIPTION_LEGAL_ACCOUNT,
      Permission.AUTHENTICATION_GET_DOCUMENT_TYPE_LIST,
      Permission.AUTHENTICATION_UPDATE_DOCUMENTS_LEGAL_ACCOUNT,
    ],
  },
  employee: {
    key: 'employee',
    path: DASHBOARD_APP_ROUTES['employee'],
    permissionKeys: [],
  },
  employeeDetail: {
    key: 'employeeDetail',
    path: DASHBOARD_APP_ROUTES['employeeDetail'],
    permissionKeys: [],
  },
  usersDetails: {
    key: 'usersDetails',
    path: DASHBOARD_APP_ROUTES['usersDetails'],
    permissionKeys: [],
  },
  // end user management

  // content management

  // employment
  jobCategories: {
    key: 'jobCategories',
    path: DASHBOARD_APP_ROUTES['jobCategories'],
    permissionKeys: [
      Permission.EMPLOYMENT_READ_EMPLOYER_CATEGORY_LIST,
      Permission.EMPLOYMENT_CREATE_EMPLOYER_CATEGORY,
      Permission.EMPLOYMENT_UPDATE_EMPLOYER_CATEGORY,
    ],
  },
  jobCategoryDetails: {
    key: 'jobCategoryDetails',
    path: DASHBOARD_APP_ROUTES['jobCategoryDetails'],
    permissionKeys: [Permission.EMPLOYMENT_CREATE_EMPLOYER_CATEGORY, Permission.EMPLOYMENT_UPDATE_EMPLOYER_CATEGORY],
  },
  experienceExpectation: {
    key: 'experienceExpectation',
    path: DASHBOARD_APP_ROUTES['experienceExpectation'],
    permissionKeys: [
      Permission.EMPLOYMENT_READ_EXPERIENCE_EXPECTATION_LIST,
      Permission.EMPLOYMENT_CREATE_EXPERIENCE_EXPECTATION,
      Permission.EMPLOYMENT_UPDATE_EXPERIENCE_EXPECTATION,
    ],
  },
  experienceExpectationDetails: {
    key: 'experienceExpectationDetails',
    path: DASHBOARD_APP_ROUTES.experienceExpectationDetails,
    permissionKeys: [
      Permission.EMPLOYMENT_CREATE_EXPERIENCE_EXPECTATION,
      Permission.EMPLOYMENT_UPDATE_EXPERIENCE_EXPECTATION,
    ],
  },
  workRejectionReasons: {
    key: 'workRejectionReasons',
    path: DASHBOARD_APP_ROUTES['workRejectionReasons'],
    permissionKeys: [
      Permission.EMPLOYMENT_READ_WORK_EXPERIENCE_REJECT_REASON_LIST,
      Permission.EMPLOYMENT_CREATE_WORK_EXPERIENCE_REJECT_REASON,
      Permission.EMPLOYMENT_UPDATE_WORK_EXPERIENCE_REJECT_REASON,
    ],
  },
  workRejectionReasonsDetails: {
    key: 'workRejectionReasonsDetails',
    path: DASHBOARD_APP_ROUTES['workRejectionReasonsDetails'],
    permissionKeys: [
      Permission.EMPLOYMENT_CREATE_WORK_EXPERIENCE_REJECT_REASON,
      Permission.EMPLOYMENT_UPDATE_WORK_EXPERIENCE_REJECT_REASON,
    ],
  },
  ageRange: {
    key: 'ageRange',
    path: DASHBOARD_APP_ROUTES['ageRange'],
    permissionKeys: [
      Permission.EMPLOYMENT_READ_AGE_RANGE_LIST,
      Permission.EMPLOYMENT_CREATE_AGE_RANGE,
      Permission.EMPLOYMENT_UPDATE_AGE_RANGE,
    ],
  },
  ageRangeDetail: {
    key: 'ageRangeDetail',
    path: DASHBOARD_APP_ROUTES['ageRangeDetail'],
    permissionKeys: [
      Permission.EMPLOYMENT_CREATE_AGE_RANGE,
      Permission.EMPLOYMENT_UPDATE_AGE_RANGE,
      Permission.EMPLOYMENT_READ_AGE_RANGE_DETAILS,
    ],
  },
  universities: {
    key: 'universities',
    path: DASHBOARD_APP_ROUTES['universities'],
    permissionKeys: [
      Permission.EMPLOYMENT_READ_UNIVERSITY_LIST,
      Permission.EMPLOYMENT_CREATE_UNIVERSITY,
      Permission.EMPLOYMENT_UPDATE_UNIVERSITY,
    ],
  },
  universitiesDetails: {
    key: 'universitiesDetails',
    path: DASHBOARD_APP_ROUTES['universitiesDetails'],
    permissionKeys: [Permission.EMPLOYMENT_UPDATE_UNIVERSITY, Permission.EMPLOYMENT_CREATE_UNIVERSITY],
  },
  universitySections: {
    key: 'universitySections',
    path: DASHBOARD_APP_ROUTES['universitySections'],
    permissionKeys: [
      Permission.EMPLOYMENT_READ_EDUCATION_SECTION_LIST,
      Permission.EMPLOYMENT_CREATE_EDUCATION_SECTION,
      Permission.EMPLOYMENT_UPDATE_EDUCATION_SECTION,
    ],
  },
  universitySectionsDetails: {
    key: 'universitySectionsDetails',
    path: DASHBOARD_APP_ROUTES['universitySectionsDetails'],
    permissionKeys: [Permission.EMPLOYMENT_UPDATE_UNIVERSITY, Permission.EMPLOYMENT_CREATE_UNIVERSITY],
  },
  softwares: {
    key: 'softwares',
    path: DASHBOARD_APP_ROUTES['softwares'],
    permissionKeys: [
      Permission.EMPLOYMENT_READ_SOFTWARE_LIST,
      Permission.EMPLOYMENT_CREATE_SOFTWARE,
      Permission.EMPLOYMENT_UPDATE_SOFTWARE,
    ],
  },
  softwaresDetails: {
    key: 'softwaresDetails',
    path: DASHBOARD_APP_ROUTES['softwaresDetails'],
    permissionKeys: [Permission.EMPLOYMENT_CREATE_SOFTWARE, Permission.EMPLOYMENT_UPDATE_SOFTWARE],
  },
  languages: {
    key: 'languages',
    path: DASHBOARD_APP_ROUTES['languages'],
    permissionKeys: [
      Permission.EMPLOYMENT_READ_LANGUAGE_LIST,
      Permission.EMPLOYMENT_CREATE_LANGUAGE,
      Permission.EMPLOYMENT_UPDATE_LANGUAGE,
    ],
  },
  languagesDetails: {
    key: 'languagesDetails',
    path: DASHBOARD_APP_ROUTES['languagesDetails'],
    permissionKeys: [Permission.EMPLOYMENT_CREATE_LANGUAGE, Permission.EMPLOYMENT_UPDATE_LANGUAGE],
  },
  // end employment

  // companies
  companies: {
    key: 'companies',
    path: DASHBOARD_APP_ROUTES['companies'],
    permissionKeys: [Permission.AUTHENTICATION_GET_COMPANY_LIST_AND_DETAILS],
  },
  companiesDetails: {
    key: 'companiesDetails',
    path: DASHBOARD_APP_ROUTES['companiesDetails'],
    permissionKeys: [
      Permission.EMPLOYMENT_UPDATE_EMPLOYER_SPECIALITIES,
      Permission.EMPLOYMENT_UPDATE_EMPLOYER_WORKING_HOURS,
      Permission.EMPLOYMENT_ADD_EMPLOYER_ADDRESS,
      Permission.EMPLOYMENT_READ_EMPLOYER_CATEGORY_LIST,
      Permission.EMPLOYMENT_READ_EMPLOYEE_CATEGORY_LIST,
      Permission.EMPLOYMENT_READ_EXPERIENCE_EXPECTATION_LIST,
      Permission.EMPLOYMENT_READ_CITY_AND_COUNTY_LIST,
      Permission.EMPLOYMENT_UPDATE_EMPLOYER_LOGO,
      Permission.AUTHENTICATION_MUTATION_CONTACTS_LEGAL_ACCOUNT,
      Permission.AUTHENTICATION_MUTATION_DETAILS_LEGAL_ACCOUNT,
      Permission.AUTHENTICATION_DELETE_DOCUMENTS_LEGAL_ACCOUNT,
      Permission.AUTHENTICATION_MUTATION_SOCIAL_MEDIA_LEGAL_ACCOUNT,
      Permission.AUTHENTICATION_MUTATION_DESCRIPTION_LEGAL_ACCOUNT,
      Permission.AUTHENTICATION_GET_DOCUMENT_TYPE_LIST,
      Permission.AUTHENTICATION_UPDATE_DOCUMENTS_LEGAL_ACCOUNT,
    ],
  },
  companyCategories: {
    key: 'companyCategories',
    path: DASHBOARD_APP_ROUTES['companyCategories'],
    permissionKeys: [
      Permission.EMPLOYMENT_READ_EMPLOYER_CATEGORY_LIST,
      Permission.EMPLOYMENT_CREATE_EMPLOYER_CATEGORY,
      Permission.EMPLOYMENT_UPDATE_EMPLOYER_CATEGORY,
    ],
  },
  companyCategoriesV2: {
    key: 'companyCategoriesV2',
    path: DASHBOARD_APP_ROUTES['companyCategoriesV2'],
    permissionKeys: [
      Permission.EMPLOYMENT_READ_EMPLOYER_CATEGORY_LIST,
      Permission.EMPLOYMENT_CREATE_EMPLOYER_CATEGORY,
      Permission.EMPLOYMENT_UPDATE_EMPLOYER_CATEGORY,
    ],
  },
  companyCategoryDetail: {
    key: 'companyCategoryDetail',
    path: DASHBOARD_APP_ROUTES['companyCategoryDetail'],
    permissionKeys: [Permission.EMPLOYMENT_UPDATE_EMPLOYER_CATEGORY, Permission.EMPLOYMENT_CREATE_EMPLOYER_CATEGORY],
  },
  companyCategoryDetailV2: {
    key: 'companyCategoryDetailV2',
    path: DASHBOARD_APP_ROUTES['companyCategoryDetailV2'],
    permissionKeys: [Permission.EMPLOYMENT_UPDATE_EMPLOYER_CATEGORY, Permission.EMPLOYMENT_CREATE_EMPLOYER_CATEGORY],
  },
  companySizes: {
    key: 'companySizes',
    path: DASHBOARD_APP_ROUTES['companySizes'],
    permissionKeys: [
      Permission.AUTHENTICATION_GET_COMPANY_SIZE,
      Permission.AUTHENTICATION_CREATE_COMPANY_SIZE,
      Permission.AUTHENTICATION_UPDATE_COMPANY_SIZE,
    ],
  },
  companySizeDetail: {
    key: 'companySizeDetail',
    path: DASHBOARD_APP_ROUTES['companySizeDetail'],
    permissionKeys: [
      Permission.AUTHENTICATION_CREATE_COMPANY_SIZE,
      Permission.AUTHENTICATION_UPDATE_COMPANY_SIZE,
      Permission.AUTHENTICATION_GET_COMPANY_SIZE_BY_ID,
    ],
  },
  companyLogos: {
    key: 'companyLogos',
    path: DASHBOARD_APP_ROUTES['companyLogos'],
    permissionKeys: [Permission.EMPLOYMENT_READ_EMPLOYERS_LOGO_LIST, Permission.EMPLOYMENT_DELETE_EMPLOYER_LOGO],
  },
  documentTypes: {
    key: 'documentTypes',
    path: DASHBOARD_APP_ROUTES['documentTypes'],
    permissionKeys: [Permission.AUTHENTICATION_GET_DOCUMENT_TYPE_LIST],
  },
  documentTypeDetail: {
    key: 'documentTypeDetail',
    path: DASHBOARD_APP_ROUTES['documentTypeDetail'],
    permissionKeys: [
      Permission.AUTHENTICATION_GET_DOCUMENT_TYPE_BY_ID,
      Permission.AUTHENTICATION_CREATE_DOCUMENT_TYPE,
      Permission.AUTHENTICATION_UPDATE_DOCUMENT_TYPE,
    ],
  },
  documentRejectionReasons: {
    key: 'documentRejectionReasons',
    path: DASHBOARD_APP_ROUTES['documentRejectionReasons'],
    permissionKeys: [Permission.AUTHENTICATION_GET_DOCUMENT_TYPE_LIST],
  },
  documentRejectionReasonsDetail: {
    key: 'documentRejectionReasonsDetail',
    path: DASHBOARD_APP_ROUTES['documentRejectionReasonsDetail'],
    permissionKeys: [
      Permission.AUTHENTICATION_GET_DOCUMENT_TYPE_BY_ID,
      Permission.AUTHENTICATION_CREATE_DOCUMENT_TYPE,
      Permission.AUTHENTICATION_UPDATE_DOCUMENT_TYPE,
    ],
  },
  companyLeaderReasons: {
    key: 'companyLeaderReasons',
    path: DASHBOARD_APP_ROUTES['companyLeaderReasons'],
    permissionKeys: [Permission.AUTHENTICATION_GET_DOCUMENT_TYPE_LIST],
  },
  companyLeaderReasonsDetail: {
    key: 'companyLeaderReasonsDetail',
    path: DASHBOARD_APP_ROUTES['companyLeaderReasonsDetail'],
    permissionKeys: [
      Permission.AUTHENTICATION_GET_DOCUMENT_TYPE_BY_ID,
      Permission.AUTHENTICATION_CREATE_DOCUMENT_TYPE,
      Permission.AUTHENTICATION_UPDATE_DOCUMENT_TYPE,
    ],
  },
  // end companies

  // bazaar
  bazaarCategories: {
    key: 'bazaarCategories',
    path: DASHBOARD_APP_ROUTES.bazaarCategories,
    permissionKeys: [Permission.BAZAAR_READ_CATEGORY],
  },
  bazaarCategoriesDetails: {
    key: 'bazaarCategoriesDetails',
    path: DASHBOARD_APP_ROUTES.bazaarCategoriesDetails,
    permissionKeys: [Permission.BAZAAR_CREATE_CATEGORY, Permission.BAZAAR_UPDATE_CATEGORY],
  },
  // end bazaar

  // slider
  slider: {
    key: 'slider',
    path: DASHBOARD_APP_ROUTES.slider,
    permissionKeys: [],
  },
  sliderDetail: {
    key: 'sliderDetail',
    path: DASHBOARD_APP_ROUTES.sliderDetail,
    permissionKeys: [],
  },
  // end slider

  // emlak
  specialLocation: {
    key: 'specialLocation',
    path: DASHBOARD_APP_ROUTES.specialLocation,
    permissionKeys: [],
  },
  specialLocationDetail: {
    key: 'specialLocationDetail',
    path: DASHBOARD_APP_ROUTES.specialLocationDetail,
    permissionKeys: [],
  },
  emlakTypes: {
    key: 'emlakTypes',
    path: DASHBOARD_APP_ROUTES.emlakTypes,
    permissionKeys: [],
  },
  emlakTypesDetails: {
    key: 'emlakTypesDetails',
    path: DASHBOARD_APP_ROUTES.emlakTypesDetails,
    permissionKeys: [],
  },
  emlakCategories: {
    key: 'emlakCategories',
    path: DASHBOARD_APP_ROUTES.emlakCategories,
    permissionKeys: [],
  },
  emlakCategoryDetail: {
    key: 'emlakCategoryDetail',
    path: DASHBOARD_APP_ROUTES.emlakCategoryDetail,
    permissionKeys: [],
  },
  emlakAds: {
    key: 'emlakAds',
    path: DASHBOARD_APP_ROUTES.emlakAds,
    permissionKeys: [],
  },
  emlakAdsDetail: {
    key: 'emlakAdsDetail',
    path: DASHBOARD_APP_ROUTES.emlakAdsDetail,
    permissionKeys: [],
  },
  emlakAdsReportReasons: {
    key: 'emlakAdsReportReasons',
    path: DASHBOARD_APP_ROUTES.emlakAdsReportReasons,
    permissionKeys: [],
  },
  emlakAdsReportReasonsDetail: {
    key: 'emlakAdsReportReasonsDetail',
    path: DASHBOARD_APP_ROUTES.emlakAdsReportReasonsDetail,
    permissionKeys: [],
  },
  // end emlak

  // end content management

  // fuar
  exhibition: {
    key: 'exhibition',
    path: DASHBOARD_APP_ROUTES.exhibition,
    permissionKeys: [
      Permission.SIDE_SERVICES_READ_EXHIBITION,
      Permission.SIDE_SERVICES_CREATE_EXHIBITION,
      Permission.SIDE_SERVICES_UPDATE_EXHIBITION,
    ],
  },
  exhibitionDetails: {
    key: 'exhibitionDetails',
    path: DASHBOARD_APP_ROUTES.exhibitionDetails,
    permissionKeys: [
      Permission.SIDE_SERVICES_READ_EXHIBITION,
      Permission.SIDE_SERVICES_CREATE_EXHIBITION,
      Permission.SIDE_SERVICES_UPDATE_EXHIBITION,
      Permission.SIDE_SERVICES_DELETE_EXHIBITION,
    ],
  },

  hotel: {
    key: 'hotel',
    path: DASHBOARD_APP_ROUTES['hotel'],
    permissionKeys: [
      Permission.SIDE_SERVICES_READ_HOTEL,
      Permission.SIDE_SERVICES_CREATE_HOTEL,
      Permission.SIDE_SERVICES_UPDATE_HOTEL,
    ],
  },
  hotelDetail: {
    key: 'hotelDetail',
    path: DASHBOARD_APP_ROUTES['hotelDetail'],
    permissionKeys: [Permission.SIDE_SERVICES_CREATE_HOTEL, Permission.SIDE_SERVICES_UPDATE_HOTEL],
  },
  location: {
    key: 'location',
    path: DASHBOARD_APP_ROUTES['location'],
    permissionKeys: [
      Permission.SIDE_SERVICES_READ_COUNTRY,
      Permission.SIDE_SERVICES_CREATE_COUNTRY,
      Permission.SIDE_SERVICES_UPDATE_COUNTRY,
    ],
  },
  locationDetail: {
    key: 'locationDetail',
    path: DASHBOARD_APP_ROUTES['locationDetail'],
    permissionKeys: [Permission.SIDE_SERVICES_CREATE_COUNTRY, Permission.SIDE_SERVICES_UPDATE_COUNTRY],
  },
  locationCity: {
    key: 'locationCity',
    path: DASHBOARD_APP_ROUTES['locationCity'],
    permissionKeys: [Permission.SIDE_SERVICES_CREATE_COUNTRY, Permission.SIDE_SERVICES_UPDATE_COUNTRY],
  },
  address: {
    key: 'address',
    path: DASHBOARD_APP_ROUTES['address'],
    permissionKeys: [],
  },
  // end fuar

  // analytics
  analytics: {
    key: 'analytics',
    path: DASHBOARD_APP_ROUTES['analytics'],
    permissionKeys: [],
  },
  // end analytics

  // schools
  schools: {
    key: 'schools',
    path: DASHBOARD_APP_ROUTES['schools'],
    permissionKeys: [Permission.EMPLOYMENT_READ_SCHOOL_LIST],
  },
  schoolDetail: {
    key: 'schoolDetail',
    path: DASHBOARD_APP_ROUTES['schoolDetail'],
    permissionKeys: [
      Permission.EMPLOYMENT_CREATE_SCHOOL,
      Permission.EMPLOYMENT_UPDATE_SCHOOL,
      Permission.EMPLOYMENT_READ_SCHOOL_DETAILS,
    ],
  },
  // end schools

  // ads
  jobAdsReport: {
    key: 'jobAdsReport',
    path: DASHBOARD_APP_ROUTES.jobAdsReport,
    permissionKeys: [Permission.EMPLOYMENT_READ_AD_REPORT_REASON_LIST],
  },
  jobAdsReportDetail: {
    key: 'jobAdsReportDetail',
    path: DASHBOARD_APP_ROUTES.jobAdsReportDetail,
    permissionKeys: [Permission.EMPLOYMENT_CREATE_AD_REPORT_REASON, Permission.EMPLOYMENT_UPDATE_AD_REPORT_REASON],
  },
  jobAds: {
    key: 'jobAds',
    path: DASHBOARD_APP_ROUTES.jobAds,
    permissionKeys: [Permission.EMPLOYMENT_READ_AD_LIST],
  },
  jobAdsDetail: {
    key: 'jobAdsDetail',
    path: DASHBOARD_APP_ROUTES.jobAdsDetail,
    permissionKeys: [Permission.EMPLOYMENT_ACTIVE_BANNED_AD, Permission.EMPLOYMENT_BAN_AD],
  },
  jobApplyListDetails: {
    key: 'jobApplyListDetails',
    path: DASHBOARD_APP_ROUTES.jobApplyListDetails,
    permissionKeys: [],
  },
  bazaarAdsReport: {
    key: 'bazaarAdsReport',
    path: DASHBOARD_APP_ROUTES.bazaarAdsReport,
    permissionKeys: [Permission.BAZAAR_READ_REPORT_REASON],
  },
  bazaarAdsReportDetail: {
    key: 'bazaarAdsReportDetail',
    path: DASHBOARD_APP_ROUTES.bazaarAdsReportDetail,
    permissionKeys: [Permission.BAZAAR_CREATE_REPORT_REASON, Permission.BAZAAR_UPDATE_REPORT_REASON],
  },
  bazzarAds: {
    key: 'bazzarAds',
    path: DASHBOARD_APP_ROUTES['bazzarAds'],
    permissionKeys: [Permission.BAZAAR_READ_POSTER, Permission.BAZAAR_ACTIVE_POSTER, Permission.BAZAAR_BAN_POSTER],
  },
  bazzarAdsDetail: {
    key: 'bazzarAdsDetail',
    path: DASHBOARD_APP_ROUTES['bazzarAdsDetail'],
    permissionKeys: [Permission.BAZAAR_READ_POSTER, Permission.BAZAAR_ACTIVE_POSTER, Permission.BAZAAR_BAN_POSTER],
  },
  bazzarAdsApplyList: {
    key: 'bazzarAdsApplyList',
    path: DASHBOARD_APP_ROUTES['bazzarAdsApplyList'],
    permissionKeys: [],
  },

  // end ads

  // magazine
  magazine: {
    key: 'magazine',
    path: DASHBOARD_APP_ROUTES['magazine'],
    permissionKeys: [
      Permission.SIDE_SERVICES_MAGAZINE_READ,
      Permission.SIDE_SERVICES_MAGAZINE_CREATE,
      Permission.SIDE_SERVICES_MAGAZINE_UPDATE,
    ],
  },
  magazineDetail: {
    key: 'magazineDetail',
    path: DASHBOARD_APP_ROUTES['magazineDetail'],
    permissionKeys: [
      Permission.SIDE_SERVICES_MAGAZINE_READ,
      Permission.SIDE_SERVICES_MAGAZINE_CREATE,
      Permission.SIDE_SERVICES_MAGAZINE_UPDATE,
    ],
  },
  magazineDetailEdition: {
    key: 'magazineDetailEdition',
    path: DASHBOARD_APP_ROUTES['magazineDetailEdition'],
    permissionKeys: [
      Permission.SIDE_SERVICES_MAGAZINE_CREATE,
      Permission.SIDE_SERVICES_MAGAZINE_UPDATE,
      Permission.SIDE_SERVICES_MAGAZINE_DELETE,
    ],
  },
  // end magazine

  // notification
  notification: {
    key: 'notification',
    path: DASHBOARD_APP_ROUTES['notification'],
    permissionKeys: [
      Permission.MESSAGING_READ_CUSTOM_NOTIFICATION_LIST,
      Permission.MESSAGING_READ_CUSTOM_NOTIFICATION_DETAILS,
      Permission.MESSAGING_CREATE_CUSTOM_NOTIFICATION,
      Permission.MESSAGING_UPDATE_CUSTOM_NOTIFICATION,
    ],
  },
  notificationDetail: {
    key: 'notificationDetail',
    path: DASHBOARD_APP_ROUTES['notificationDetail'],
    permissionKeys: [
      Permission.MESSAGING_READ_CUSTOM_NOTIFICATION_DETAILS,
      Permission.MESSAGING_CREATE_CUSTOM_NOTIFICATION,
      Permission.MESSAGING_UPDATE_CUSTOM_NOTIFICATION,
    ],
  },
  // end notification

  // exchange
  holidyManagement: {
    key: 'holidyManagement',
    path: DASHBOARD_APP_ROUTES['holidyManagement'],
    permissionKeys: [],
  },
  // end exchange

  // setting
  setting: {
    key: 'setting',
    path: DASHBOARD_APP_ROUTES['setting'],
    permissionKeys: [],
  },
  mobileAppOsVersions: {
    key: 'mobileAppOsVersions',
    path: DASHBOARD_APP_ROUTES['mobileAppOsVersions'],
    permissionKeys: [
      Permission.AUTHENTICATION_GET_ALL_AGENT_OS,
      Permission.AUTHENTICATION_UPDATE_AGENT_OS,
      Permission.AUTHENTICATION_GET_LAST_AGENT_OS,
    ],
  },
  // end setting

  logOut: {
    key: 'logOut',
    path: DASHBOARD_APP_ROUTES['logOut'],
    permissionKeys: [],
  },
};

import { t_redux_action } from 'tools/types';
import AppActionType from 'store/app/app.actionType';

export type t_theme = 'default' | 'primary';
export type t_lang = 'en' | 'tr';
export type t_actionType = keyof typeof AppActionType;

export type t_appInitialState = {
  theme: t_theme;
  lang: t_lang;
};

export const APP_INITIAL_STATE: t_appInitialState = {
  theme: 'default',
  lang: 'tr',
};

function AppReducer(state = APP_INITIAL_STATE, action: t_redux_action<t_actionType, t_theme | t_lang>) {
  switch (action.type) {
    case 'LANG':
      return { ...state, lang: action.payload as t_lang };
    case 'THEME':
      return { ...state, theme: action.payload as t_theme };
    default:
      return state;
  }
}

export default AppReducer;

import Icon from 'components/icons';
import { useCopy, useToggle } from 'hooks';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import PasswordChecklist from 'react-password-checklist';
import { Input } from 'reactstrap';

export interface PasswordInputProps {
  errorMessage?: string;
  onChange?: (value: string) => void;
  onFocus?: (value: string) => void;
  onBlur?: (value: string) => void;
  placeholder?: string;
  name: string;
  defaultValue?: string;
  disabled?: boolean;
  copyable?: boolean;
  needCheckBox?: boolean;
}

export const PasswordInput: FC<PasswordInputProps> = (props) => {
  const {
    errorMessage,
    name,
    defaultValue,
    disabled,
    copyable,
    onChange,
    onFocus,
    onBlur,
    needCheckBox = false,
    placeholder,
  } = props;
  const [togglePassword, toggler] = useToggle(false);
  const [isValidPass, toggleIsValidPass] = useToggle(false);
  const [realValue, setRealValue] = useState('');
  const { copyContent, isCopied } = useCopy();

  const handleCopy = () => {
    copyContent(realValue).then();
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRealValue(e.target.value);
  };
  useEffect(() => {
    if (!needCheckBox) {
      (onChange || onBlur || onFocus)?.(realValue);
    } else {
      if (isValidPass) {
        (onChange || onBlur || onFocus)?.(realValue);
      }
    }
  }, [realValue, isValidPass]);
  return (
    <div className="d-flex flex-column">
      <div className="input-container border rounded py-1 position-relative">
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center">
            <Input
              onChange={handleChange}
              invalid={!!errorMessage}
              aria-invalid={!!errorMessage}
              className="border-0 transparent w-100 h-100"
              type={togglePassword ? 'text' : 'password'}
              name={name}
              defaultValue={defaultValue}
              disabled={disabled}
              aria-disabled={disabled}
              autoComplete="password"
              placeholder={placeholder}
            />

            <div className="show-hide d-flex align-items-center" role="button" aria-invalid={`${!!errorMessage}`}>
              <span role="button" onClick={() => toggler()}>
                {togglePassword ? (
                  <Icon Name="FiEye" color="gray" size={'18px'} />
                ) : (
                  <Icon Name="FiEyeOff" color="gray" size={'18px'} />
                )}
              </span>
              {copyable && (
                <span role="button" onClick={handleCopy} className="ml-3">
                  {isCopied ? 'copied' : <Icon Name="FiClipboard" color={disabled ? '#ccc' : 'gray'} />}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      {needCheckBox && (
        <PasswordChecklist
          className="mt-3"
          rules={['minLength', 'specialChar', 'capital', 'lowercase', 'number']}
          minLength={8}
          value={realValue}
          onChange={toggleIsValidPass}
        />
      )}
    </div>
  );
};

export default PasswordInput;

import Icon from 'components/icons';
import Link from 'components/link';
import { useToggle } from 'hooks';
import React from 'react';
import { Button } from 'reactstrap';
import { t_dashboardLinkKey } from 'route/appRoutes';

interface NewSectionButtonProps {
  label: string;
  param?: string;
  link?: t_dashboardLinkKey;
  dropDownOption?: dropDownOption[];
}

export type dropDownOption = {
  label: string;
  value: string;
  link: t_dashboardLinkKey;
};

export default function NewSectionButton({ label, param, dropDownOption, link }: NewSectionButtonProps) {
  const [openDropDown, toggleDropDown] = useToggle(false);
  return (
    <Link
      data-testid={label.replace(/\s/g, '')}
      to={link || 'new'}
      param={param}
      onClick={dropDownOption ? () => toggleDropDown : undefined}
      className="d-inline w-fit position-relative"
    >
      <Button color="primary" className="d-initial w-auto">
        <span className={`d-flex align-items-center ${dropDownOption ? 'justify-content-between' : ''}`}>
          <div className="d-flex">
            <Icon Name="FiPlusSquare" />
            <span className="ml-2"> {label} </span>
          </div>
          {!!dropDownOption && (
            <>
              <div className="ml-2 my-auto d-flex align-items-center">
                <Icon Name="FiChevronDown" />
              </div>
              {openDropDown && (
                <div
                  className="p-2 border-top bg-primary position-absolute d-flex flex-column w-100"
                  style={{
                    minHeight: '50px',
                    zIndex: '99',
                    top: '35px',
                    left: 0,
                    borderBottomLeftRadius: '5px',
                    borderBottomRightRadius: '5px',
                  }}
                >
                  {dropDownOption.map((opt, index) => {
                    return (
                      <Link
                        to={opt.link}
                        key={opt.value}
                        className={`p-2 text-white text-left ${
                          index + 1 === dropDownOption?.length ? '' : ' border-bottom'
                        }`}
                      >
                        <span>{opt.label}</span>
                      </Link>
                    );
                  })}
                </div>
              )}
            </>
          )}
        </span>
      </Button>
    </Link>
  );
}

import DEFAULT_ICON from 'assets/images/buluro/icon/wallet.svg';
import Icon from 'components/icons';
import { t_TreeProps, t_requiredFields } from 'components/tree-select-checkbox/type';
import { t_toggleValueFunc, useToggle, useUpdateEffect } from 'hooks';
import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import { FormCheck } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Button, Label } from 'reactstrap';
import Styled from 'styled-components';
import { detail } from 'tools/i18n/constants/i18n';

const TreeContainer = Styled.div``;

const StyledIconContainer = Styled.div`
  min-width : 15px;
  max-width : 15px;
`;

function TreeDataCheckbox<T extends t_requiredFields<T>>(props: t_TreeProps<T, false>) {
  const {
    data,
    onSelect,
    defaultExpandedIds,
    isMultiSelect,
    disableParentSelectWhileHasChild,
    unselectParentsCheckbox,
    style,
    checkbox,
    onDetailButton,
    detailButton,
  } = props;
  const [focus] = useToggle(true);
  const [selectedNode, setSelectedNode] = useState<T | undefined>(undefined);
  /* const handleFocus = () => {
      toggleFocus(true);
    };*/

  const handleSelect = (node: T) => {
    if (!isMultiSelect) {
      setSelectedNode(node);
      onSelect?.(node);
    } else {
      onSelect?.(node);
    }
  };
  return (
    <TreeContainer style={style}>
      {/* <div>
        <Input disabled={disabled} aria-disabled={disabled} onFocus={handleFocus} />
      </div>*/}
      {focus && (
        <div className="px-3 bg-white rounded-md py-2 mt-2">
          {data?.map((tree) => {
            return (
              <Fragment key={tree.id as string | number}>
                <TreeItemContainer
                  selectedNode={selectedNode}
                  onSelect={handleSelect}
                  onDetailButton={onDetailButton}
                  disableParentSelectWhileHasChild={disableParentSelectWhileHasChild}
                  checkbox={checkbox}
                  detailButton={detailButton}
                  isMultiSelect={isMultiSelect}
                  item={tree}
                  defaultExpandedIds={defaultExpandedIds}
                  unselectParentsCheckbox={unselectParentsCheckbox}
                />
              </Fragment>
            );
          })}
        </div>
      )}
    </TreeContainer>
  );
}

export default memo(TreeDataCheckbox);

const StyledTreeItemContainer = Styled.div<{
  isExpanded: boolean;
  itemHeight?: string;
  bordered?: boolean;
  hasChild: boolean;
}>`
  max-height : ${(p) => (p.isExpanded ? 'auto' : p.itemHeight || '35px')}
  height : ${(p) => p?.itemHeight};
  > div.tree-label {
    margin-left: ${(p) => (p.hasChild ? '' : '22px')} 
  }
`;
const StyledCheckbox = Styled(FormCheck)`
  input {
    margin-top : 0;
    margin-bottom: 0;
    margin-right : 10px;
    width : 20px;
    height : 20px;  
  }
`;

type TreeItemContainerProps<T extends t_requiredFields<T>> = t_TreeProps<T, true> & {
  item: T;
  selectedNode: T | undefined;
};
function TreeItemContainer<T extends t_requiredFields<T>>(props: TreeItemContainerProps<T>) {
  const {
    item,
    itemHeight,
    defaultExpandedIds,
    onSelect,
    isMultiSelect,
    selectedNode,
    checkbox,
    onDetailButton,
    disableParentSelectWhileHasChild,
    detailButton,
    unselectParentsCheckbox,
  } = props;
  const { t } = useTranslation();
  const [expanded, toggleExpanded] = useToggle(false);
  const [checked, toggleChecked] = useToggle(false);
  const hasChild = !!item?.child?.length;
  const handleToggleExpanded = useCallback(() => {
    toggleExpanded();
  }, []);
  const handleClick = useCallback(() => {
    if (unselectParentsCheckbox && hasChild) return;
    onSelect?.(item);
    toggleChecked((p) => !p);
  }, [unselectParentsCheckbox, hasChild]);
  const handleFindIncludedChild = useCallback(
    (type: T) => {
      type.child?.forEach((i) => {
        if (defaultExpandedIds?.includes(i.id)) toggleExpanded(true);
        else if (i.child) handleFindIncludedChild(i);
      });
    },
    [defaultExpandedIds]
  );
  useEffect(() => {
    if (defaultExpandedIds) {
      const isIncludes = defaultExpandedIds.includes(item.id);
      if (isIncludes) {
        toggleExpanded(true);
        toggleChecked(true);
      } else {
        if (item.child) {
          item.child.forEach((i) => {
            if (defaultExpandedIds.includes(i.id)) toggleExpanded(true);
            else if (i.child) handleFindIncludedChild(i);
          });
        }
      }
    }
  }, [defaultExpandedIds]);

  useUpdateEffect(() => {
    if (!isMultiSelect) {
      if (selectedNode?.id !== item?.id) {
        toggleChecked(false);
      }
    }
  }, [selectedNode?.id]);
  const isCheckbox = hasChild && disableParentSelectWhileHasChild;
  return (
    <StyledTreeItemContainer
      className={`p-2 ${checkbox ? '' : 'border-bottom border-gray'}`}
      isExpanded={expanded}
      itemHeight={itemHeight}
      hasChild={hasChild}
    >
      <div className={`tree-label d-flex align-items-center`}>
        {hasChild && (
          <StyledIconContainer className="mr-2" role="button" onClick={handleToggleExpanded}>
            <Icon Name={expanded ? 'FiChevronDown' : 'FiChevronRight'} size="15px" />
          </StyledIconContainer>
        )}

        <div
          role="button"
          className="mr-3 have-cover make-center position-relative d-flex align-items-center"
          onClick={handleClick}
        >
          <>
            {checkbox && !isCheckbox && (
              <StyledCheckbox
                disabled={hasChild && unselectParentsCheckbox}
                className="user-unselect m-0"
                readOnly
                aria-readonly
                checked={checked}
                defaultChecked={checked}
                role="button"
                id={(item.id as string | number).toString()}
              />
            )}
            {item?.icon && (
              <img
                src={(item?.icon as string) || DEFAULT_ICON}
                alt={item?.label as string | undefined}
                width="30px"
                height="30px"
              />
            )}
          </>
        </div>
        <div
          className={`${checkbox ? '' : 'flex-grow-1 w-100'} ${
            detailButton ? 'd-flex align-items-center justify-content-between' : ''
          }`}
        >
          <Label className="mb-0" htmlFor={(item.id as string | number).toString()}>
            {(item?.label as string) || 'label'}
          </Label>
          {detailButton && (
            <Button className="px-3 py-1" color="primary" onClick={() => onDetailButton?.(item)}>
              {t(detail)}
            </Button>
          )}
        </div>
      </div>
      {hasChild && (
        <div className={expanded ? 'd-block' : 'd-none'}>
          {item.child?.map((child: T, index: number) => {
            return (
              <Fragment key={child.id as string | number}>
                <TreeItemChildContainer
                  parentExpanded={expanded}
                  disableParentSelectWhileHasChild={disableParentSelectWhileHasChild}
                  unselectParentsCheckbox={unselectParentsCheckbox}
                  parentToggleExpand={toggleExpanded}
                  onSelect={onSelect}
                  item={{ ...child, _parent: item.id }}
                  index={index}
                  defaultExpandedIds={defaultExpandedIds}
                  isMultiSelect={isMultiSelect}
                  selectedNode={selectedNode}
                  checkbox={checkbox}
                  onDetailButton={onDetailButton}
                  detailButton={detailButton}
                  itemHeight={itemHeight}
                />
              </Fragment>
            );
          })}
        </div>
      )}
    </StyledTreeItemContainer>
  );
}

type TreeItemChildContainerProps<T extends t_requiredFields<T>> = t_TreeProps<T, true> & {
  item: T;
  index: number;
  parentToggleExpand: t_toggleValueFunc;
  selectedNode: T | undefined;
  parentExpanded: boolean;
};

const StyledChildContainer = Styled.div<{ childIndex: number }>`
  margin-left : ${(p) => `${p.childIndex + 25}px`};
  margin-top : 15px;
`;

function TreeItemChildContainer<T extends t_requiredFields<T>>(props: TreeItemChildContainerProps<T>) {
  const {
    item,
    index,
    defaultExpandedIds,
    parentToggleExpand,
    onSelect,
    selectedNode,
    isMultiSelect,
    checkbox,
    onDetailButton,
    detailButton,
    disableParentSelectWhileHasChild,
    unselectParentsCheckbox,
  } = props;
  const { t } = useTranslation();
  const [expanded, toggleExpanded] = useToggle(false);
  const [checked, toggleChecked] = useToggle(false);
  const hasChild = !!item.child?.length;

  const handleToggleExpanded = useCallback(() => {
    toggleExpanded();
  }, []);

  const handleSelectNode = useCallback(() => {
    if (hasChild && unselectParentsCheckbox) {
      return;
    }
    onSelect?.(item);
    toggleChecked((prev) => !prev);
  }, [unselectParentsCheckbox, hasChild]);

  const handleFindIncludedChild = useCallback(
    (type: T) => {
      type.child?.forEach((i) => {
        if (defaultExpandedIds?.includes(i.id)) toggleExpanded(true);
        else if (i.child) handleFindIncludedChild(i);
      });
    },
    [defaultExpandedIds]
  );
  useEffect(() => {
    if (defaultExpandedIds) {
      const isIncludes = defaultExpandedIds.includes(item.id);
      if (isIncludes) {
        toggleExpanded(true);
        toggleChecked(true);
      } else {
        if (item.child) {
          item.child.forEach((i) => {
            if (defaultExpandedIds.includes(i.id)) toggleExpanded(true);
            else if (i.child) handleFindIncludedChild(i);
          });
        }
      }
    }
  }, [defaultExpandedIds]);
  useEffect(() => {
    if (defaultExpandedIds) {
      const isIncludes = defaultExpandedIds.includes(item.id);
      if (isIncludes) {
        parentToggleExpand(true);
        toggleExpanded(true);
        toggleChecked(true);
      }
    }
  }, [defaultExpandedIds]);
  useEffect(() => {
    if (expanded) {
      parentToggleExpand(true);
    }
  }, [expanded]);
  useUpdateEffect(() => {
    if (!isMultiSelect) {
      if (selectedNode?.id !== item?.id) {
        toggleChecked(false);
      } else {
        toggleChecked(true);
      }
    }
  }, [selectedNode?.id]);
  const isCheckbox = hasChild && disableParentSelectWhileHasChild;

  return (
    <StyledChildContainer childIndex={index}>
      <div className="d-flex align-items-center">
        {hasChild && (
          <StyledIconContainer className="mr-2" role="button" onClick={handleToggleExpanded}>
            <Icon Name={expanded ? 'FiChevronDown' : 'FiChevronRight'} size="15px" />
          </StyledIconContainer>
        )}

        <div
          role="button"
          className="mr-3 have-cover make-center position-relative d-flex align-items-center"
          onClick={handleSelectNode}
        >
          {checkbox && !isCheckbox ? (
            <StyledCheckbox
              disable={hasChild && unselectParentsCheckbox}
              className="user-unselect m-0"
              readOnly
              aria-readonly
              checked={checked}
              defaultChecked={checked}
              role="button"
              id={item.id.toString()}
            />
          ) : (
            <img src={(item?.icon as string) || DEFAULT_ICON} alt={item.label as string} width="30px" height="30px" />
          )}
        </div>
        <div
          className={`${checkbox ? '' : 'flex-grow-1 w-100'} ${
            detailButton ? 'd-flex align-items-center justify-content-between' : ''
          }`}
        >
          <Label className="mb-0" htmlFor={item.id.toString()}>
            {(item?.label as string) || 'label'}
          </Label>
          {detailButton && (
            <Button color="primary" className="px-3 py-1" onClick={() => onDetailButton?.(item)}>
              {t(detail)}
            </Button>
          )}
        </div>
      </div>
      {hasChild && (
        <div className={expanded ? 'd-block' : 'd-none'}>
          {item.child?.map((child, index) => {
            return (
              <Fragment key={child.id}>
                <TreeItemChildContainer
                  parentExpanded={expanded}
                  parentToggleExpand={toggleExpanded}
                  onSelect={onSelect}
                  item={{ ...child, _parent: item.id }}
                  disableParentSelectWhileHasChild={disableParentSelectWhileHasChild}
                  index={index}
                  selectedNode={selectedNode}
                  isMultiSelect={isMultiSelect}
                  defaultExpandedIds={defaultExpandedIds}
                  detailButton={detailButton}
                  onDetailButton={onDetailButton}
                  checkbox={checkbox}
                  unselectParentsCheckbox={unselectParentsCheckbox}
                />
              </Fragment>
            );
          })}
        </div>
      )}
    </StyledChildContainer>
  );
}
